import React, { useState } from "react";
import { payForRent, payForRentWithSpecta } from "#/store/actions/payments.action";
import PaymentComponent from "./components/PaymentComponent";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

const PaymentPage = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const itemsToPay = localStorage.getItem("PAY_RENT_AND_CHARGES");
    const items = JSON.parse(itemsToPay);
    const total = items?.items?.map((item) => Number(item.price)).reduce((a, b) => a + b, 0);

    const [selectedMethod, setSelectedMethod] = React.useState(null);
    const [amount, setAmount] = useState(total || 0);

    if (!itemsToPay) {
        history.push("/dashboard");
        return null;
    }

    const onSubmit = () => {
        if (selectedMethod === "izypay") {
            return izyPay();
        }
        payWithSpecta();
    };

    const payWithSpecta = () => {
        const rentPayload = { apartment_id: items.apartment_id };
        dispatch(payForRentWithSpecta({ amount }, rentPayload, history));
    };

    const izyPay = () => {
        const rentPayload = { apartment_id: items.apartment_id };
        dispatch(payForRent(rentPayload, history));
    };

    return (
        <PaymentComponent
            amountReadonly={true}
            spectaAvailable={true}
            fullAmount={total}
            amount={amount}
            setAmount={setAmount}
            items={items}
            paymentMethod={selectedMethod}
            setPaymentMethod={setSelectedMethod}
            onSubmit={onSubmit}
        />
    );
};

export default PaymentPage;
