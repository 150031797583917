import React from "react";
import avatar from "#/assets/propertyPage/avatar.png";

import { styled } from "@mui/material";

const Wrapper = styled("div")`
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 15px;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 5px 15px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const ProfilePictureWrapper = ({ src, alt }) => {
    return (
        <Wrapper>
            <img src={src ? src : avatar} alt={alt} />
        </Wrapper>
    );
};

export default ProfilePictureWrapper;
