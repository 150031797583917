import { styled } from "@mui/material";
import React from "react";

const ToggleSwitchWithLabel = ({ label, color, checked, onChange }) => {
  return (
    <Wrapper color={color}>
      <input type="checkbox" name="toggle" defaultChecked={checked} onChange={onChange} />
      <label>
        <span>{label}</span>
      </label>
    </Wrapper>
  );
};

export default ToggleSwitchWithLabel;

const Wrapper = styled("div")`
  input {
    opacity: 0;
    position: absolute;
    left: -9999px;
  }
  input + label {
    -webkit-user-select: none;
    cursor: pointer;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: 0.2s ease;
    display: inline-block;
    height: 22px;
    width: 37px;
    position: relative;
    box-shadow: inset 0 0 0px 2px #e4e4e4;
    border-radius: 60px;
    /* White toggle */
  }
  input + label:before {
    content: "";
    position: absolute;
    display: block;
    height: 22px;
    width: 22px;
    top: 0;
    left: 0;
    border-radius: 11px;
    background: rgba(76, 217, 100, 0);
    transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  }
  input + label:after {
    content: "";
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    top: 50%;
    margin-top: -10.5px;
    left: 1px;
    border-radius: 60px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  }
  input + label span {
    white-space: nowrap;
    height: 22px;
    line-height: 22px;
    margin-left: 28px;
    font-size: 1rem;
    font-weight: bold;
    padding-left: 16px;
  }
  input:checked + label:before {
    width: 37px;
    background: ${(props) => (props.color ? props.color : "#4cd964")};
    transition: width 0.2s cubic-bezier(0, 0, 0, 0.1) !important;
  }
  input:checked + label:after {
    left: 16px;
  }
  input:checked + label {
    box-shadow: inset 0 0 0px 25px #e4e4e4;
    transition: box-shadow 2.5s cubic-bezier(0, 1.2, 0.94, 0.95);
  }
  label span {
    cursor: default;
  }
`;
