import IzyPayWallet from "#/components/wallets/izyPay/IzyPayWallet";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { styled } from "@mui/material";
import React from "react";
import { APP_ROUTES } from "#/constants/routes";

import { IoIosArrowRoundForward } from "react-icons/io";

const IzypayIzyinvestSection = () => {
  const history = useHistory();
  const goToIzyinvest = () => {
    history.push(APP_ROUTES.izyInvest.main);
  };
  return (
    <CustomFlexBetween sx={{ alignItems: "end" }}>
      <IzyPayWallet />
      <div className="tw-flex tw-gap-3 tw-items-center">
        <CustomBtn onClick={goToIzyinvest}>Go To IzyInvest</CustomBtn>
        <Icon>
          <IoIosArrowRoundForward color="#10609F" className="tw-font-bold" size={23} />
        </Icon>
      </div>
    </CustomFlexBetween>
  );
};

export default IzypayIzyinvestSection;

const CustomFlexBetween = styled("div")`
  display: flex;
  gap: 2rem;
  @media (max-width: 750px) {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }
`;
const CustomBtn = styled("div")`
  background: #10609f;
  color: white;
  font-size: 15px;
  padding: 13px 3rem;
  border-radius: 10px;

  @media (max-width: 500px) {
    padding: 5px;
    background: transparent;
    color: #10609f;
    font-size: 1rem;
    font-weight: 700;
    text-decoration: underline;
    margin: 1rem 0;
  }
`;
const Icon = styled("div")`
  display: none;
  @media (max-width: 500px) {
    display: block;
  }
`;
