import React from "react";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

const PlanInitiateComponent = ({ displayModal }) => {
    return (
        <div className="plan-initiate-btn">
            {/*<Fab color="secondary" aria-label="edit">
                <RemoveIcon />
            </Fab>*/}
            <Fab color="primary" aria-label="add" onClick={() => displayModal(true)}>
                <AddIcon />
            </Fab>
            <span>create plan</span>
        </div>
    );
};

export default PlanInitiateComponent;
