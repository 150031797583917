import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLipmanReminder } from "./lipmanSubscription.styles";
import ViewLiveizyPlan from "../viewLiveizyPlan";
import { propertyServiceApi } from "#/apis/propertyServiceApi";
import Back from "../../../../../components/propertycomponents/back-component";

const LipmanSubscription = () => {
    const classes = useLipmanReminder();
    const user = useSelector((state) => state.auth.user);

    const [planProps, setPlanProps] = useState([]);
    const [total, setTotal] = useState(null);
    const [active, setActive] = useState(null);
    const [inactive, setInactive] = useState(null);
    const [approved, setApproved] = useState(null);

    useEffect(() => {
        fetchPropAptsWithPlans();
    }, []);

    const fetchPropAptsWithPlans = () => {
        propertyServiceApi()
            .get(`/property/ownerPropAptsWithPlans/${user.liveizy_id}`)
            .then((data) => {
                const filtered = data.data.payload.filter((item) => item.apartment[0]);
                setPlanProps(filtered);
                const apartments = filtered.map((item) => item.apartment).flat();
                const plans = apartments.filter((item) => item.plan?.plan_type === "LiPMaN").map((item) => item.plan);
                setTotal(plans.length);
                let temp1, temp2, temp3;
                plans.forEach((item) => {
                    if (item.plan_type === "LiPMaN" && item.status === "active") temp1 = (temp1 || 0) + 1;
                    if (item.plan_type === "LiPMaN" && item.status === "inactive") temp2 = (temp2 || 0) + 1;
                    if (item.plan_type === "LiPMaN" && item.status === "approved") temp3 = (temp3 || 0) + 1;
                });
                setActive(temp1);
                setInactive(temp2);
                setApproved(temp3);
            })
            .catch((err) => {
                // console.log(err);
            });
    };

    return (
        <div className={classes.wrapper}>
            <div className="back-comp">
                <Back redirectUrl="/property/plan/lipman" />
            </div>
            <header className={classes.lipmanHeader}>
                <h1>Liveizy Property Manager (LiPMaN)</h1>
            </header>
            <main className="main-body">
                <section>
                    <div className="header">
                        <h4>CREATE LiPMAN</h4>
                        <div></div>
                    </div>

                    <section className="first-section">
                        <div className="first-section__col">
                            <h5>Number of apartment </h5>
                            <p>
                                Total ({total}): Active {active}; Approved {approved}; InActive {inactive}
                            </p>
                        </div>
                        <div className="first-section__col">
                            <h5>Commission</h5>
                            <p>12%</p>
                        </div>
                    </section>
                    <section className="second-section">
                        <h5 className="second-section__title">Lipman Apartments</h5>
                        <ViewLiveizyPlan planProps={planProps} plan="LiPMaN" />
                    </section>
                </section>
            </main>
        </div>
    );
};

export default LipmanSubscription;
