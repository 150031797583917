import formModel from "./formModel";
const {
  formField: {
    businessName,
    businessPhone,
    businessRegistrationAddress,
    businessRegistrationNumber,
    companyEmail,
    lga,
    state
  }
} = formModel;

export default function (businessInformation) {
  const initialValues = {
    // [logo.name]: businessInformation?.logo || "",
    [businessName.name]: businessInformation?.business_name || "",
    [businessPhone.name]: businessInformation?.business_phone || "",
    [companyEmail.name]: businessInformation?.company_email || "",
    [businessRegistrationAddress.name]:
      businessInformation?.business_registration_address || "",
    [businessRegistrationNumber.name]:
      businessInformation?.business_registration_number || "",
    [lga.name]: businessInformation?.lga || "",
    [state.name]: businessInformation?.state || ""
  };

  return initialValues;
}
