import { Box, styled } from "@mui/material";

const BlueGradientJumbotron = styled(Box)`
  background: linear-gradient(
    47.51deg,
    #052137 0%,
    rgba(13, 46, 72, 0.88) 53.57%,
    rgba(5, 33, 55, 0.78) 99.68%,
    rgba(7, 36, 59, 0.827247) 100%
  );
  width: 100%;
  height: auto;
  padding: 25px 20px;
  border-radius: 12px;
  color: white;
`;

export default BlueGradientJumbotron;
