import { authServiceApi } from "#/apis/authServiceApi";
import CustomBtn from "#/components/buttons/CustomBtn";
import { CardWrapper, ProfilePictureAvatar, SubtitleSubtext } from "#/shared/components";
import { alertError } from "#/store/actions/notification.actions";
import { styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const NOTFOUND = "not found";
const tempEmailRegex = /@liveizytemp\.com$/;

const TenantProfileCard = ({ tenantLid, handleRemoveTenantModal }) => {
  const dispatch = useDispatch();
  const [tenantDetails, setTenantDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUserFromLiveizyId = async () => {
      setLoading(true);
      try {
        await authServiceApi()
          .get(`/user/get/details/${tenantLid}`)
          .then((res) => {
            setTenantDetails(res.data.data.profile);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        if (error.response?.status === 404) {
          setTenantDetails(NOTFOUND);
          return dispatch(alertError(`Liveizy Id ${tenantLid} not found.`));
        }
        dispatch(
          alertError(
            error?.response?.data?.message ||
              `Error validating Liveizy ID attached to apartment.`
          )
        );
      }
    };

    setTenantDetails(null);
    fetchUserFromLiveizyId();
  }, [tenantLid]);

  if (tenantDetails === NOTFOUND) {
    return (
      <p>
        Liveizy ID <b>{tenantLid}</b> not found.
      </p>
    );
  }
  return (
    <Wrapper>
      <ProfilePictureAvatar src={tenantDetails?.profile_image} />
      {!tenantDetails?.is_email_verified ? (
        <NotActiveText>
          Not Active <span>(Unverified Account)</span>
        </NotActiveText>
      ) : null}
      <TenantDetails>
        <SubtitleSubtext
          loading={loading}
          subtitle={"Tenant's Full Name"}
          subtext={`${tenantDetails?.first_name} ${tenantDetails?.last_name}`}
        />
        <SubtitleSubtext
          loading={loading}
          subtitle={"Tenant's Email"}
          subtext={
            tempEmailRegex.test(tenantDetails?.email) ? "None" : tenantDetails?.email
          }
        />
        <SubtitleSubtext
          loading={loading}
          subtitle={"Tenant's Phone Number"}
          subtext={tenantDetails?.phone}
        />
        <RemoveTenantBtn onClick={handleRemoveTenantModal}>Remove Tenant</RemoveTenantBtn>
      </TenantDetails>
    </Wrapper>
  );
};

export default TenantProfileCard;

const Wrapper = styled(CardWrapper)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  padding: 2rem 0.75rem;
`;

const TenantDetails = styled("div")`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 0.25rem;
    text-align: center;
  }
`;

const RemoveTenantBtn = styled(CustomBtn)`
  width: 60%;
  margin-top: 1rem;
  @media (min-width: 600px) {
    display: none;
  }
`;

const NotActiveText = styled("p")`
  color: #c8202d;
  font-weight: 600;
  font-size: 0.85rem;
  margin-top: -12px;
  margin-bottom: 0;
  span {
    font-style: italic;
    font-weight: normal;
    color: black;
  }
`;
