import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Header from '../common/Header';
import IncomeTable from './IncomeTable';
import AccountSummary from './AccountSummary'


const styles = {
    root: {
        // marginLeft: "230px",
    },
};

const ListPropertyManagerIncome = (props) => {
    return <div className={props.classes.root}>
                <Header title="Income" export="Export" newIncome="New Income"/>
                <Grid container spacing={2} className={props.classes.grid}>
                    <Grid item xs={12} md={10}><IncomeTable /></Grid>
                    <Grid item xs={12} md={2}><AccountSummary /></Grid>
                </Grid>
            </div>
}

export default withStyles(styles)(ListPropertyManagerIncome);
