import * as actionTypes from "../constants";

const {
  GET_APARTMENT_TENANTS_FAIL,
  GET_APARTMENT_TENANTS_PENDING,
  GET_APARTMENT_TENANTS_SUCCESS,

  GET_TENANTS_UNDER_PM_FAIL,
  GET_TENANTS_UNDER_PM_SUCCESS,
  GET_TENANTS_UNDER_PM_PENDING,

  CREATE_TENANT_BY_LID_FAIL,
  CREATE_TENANT_BY_LID_PENDING,
  CREATE_TENANT_BY_LID_SUCCESS,

  CREATE_TENANT_FAIL,
  CREATE_TENANT_PENDING,
  CREATE_TENANT_SUCCESS,

  CLEAR_CREATED_TENANT,

  GET_TENANTS_APARTMENT_FAIL,
  GET_TENANTS_APARTMENT_PENDING,
  GET_TENANTS_APARTMENT_SUCCESS,
  GET_MONTHLY_RENT_DETAILS_SUCCESS,
  GET_TENANT_RECORD_PENDING,
  GET_TENANT_RECORD_SUCCESS,
  GET_TENANT_RECORD_FAIL,

  GET_TENANT_BILLS,
  GET_TENANT_BILLS_FAILED,
  GET_TENANT_BILLS_SUCCESS,

  GET_TENANT_INVOICES,
  GET_TENANT_INVOICES_FAILED,
  GET_TENANT_INVOICES_SUCCESS
} = actionTypes.TENANTS_CONSTANTS;

const initialState = {
  creatingTenants: false,
  gettingTenants: false,
  gettingTenantRecord: false,
  tenantsUnderPm: [],
  apartmentTenant: null,
  tenantsApartment: null,
  tenantRecord: [],
  monthlyRent: [],
  bills: [],
  invoices: [],
  loadingBills: false,
  loadingInvoices: false
};

const tenantReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_TENANT_PENDING:
      return {
        ...state,
        creatingTenants: true
      };
    case CREATE_TENANT_SUCCESS:
      return {
        ...state,
        tenant: payload,
        creatingTenants: false,
        apartmentTenant: payload
      };

    case CREATE_TENANT_FAIL:
      return {
        ...state,
        creatingTenants: false,
        apartmentTenant: null
      };
    case CREATE_TENANT_BY_LID_PENDING:
      return {
        ...state,
        creatingTenants: true
      };
    case CREATE_TENANT_BY_LID_SUCCESS:
      return {
        ...state,
        tenant: payload,
        creatingTenants: false,
        apartmentTenant: payload
      };
    case CREATE_TENANT_BY_LID_FAIL:
      return {
        ...state,
        creatingTenants: false,
        apartmentTenant: null
      };
    case GET_APARTMENT_TENANTS_PENDING:
      return {
        ...state,
        gettingTenants: true
      };
    case GET_APARTMENT_TENANTS_SUCCESS:
      return {
        ...state,
        gettingTenants: false,
        apartmentTenant: payload
      };
    case GET_APARTMENT_TENANTS_FAIL:
      return {
        ...state,
        gettingTenants: false,
        apartmentTenant: []
      };
    case GET_TENANTS_UNDER_PM_PENDING:
      return {
        ...state
      };
    case GET_TENANTS_UNDER_PM_SUCCESS:
      return {
        ...state,
        tenantsUnderPm: payload
      };
    case GET_TENANTS_UNDER_PM_FAIL:
      return {
        ...state
      };
    case CLEAR_CREATED_TENANT:
      return {
        ...state,
        apartmentTenant: null,
        tenantRecord: []
      };
    case GET_TENANTS_APARTMENT_PENDING:
      return {
        ...state
      };
    case GET_TENANTS_APARTMENT_SUCCESS:
      return {
        ...state,
        tenantsApartment: payload
      };
    case GET_TENANTS_APARTMENT_FAIL:
      return {
        ...state,
        tenantsApartment: null
      };
    case GET_TENANT_RECORD_PENDING:
      return {
        ...state,
        gettingTenantRecord: true
      };
    case GET_TENANT_RECORD_SUCCESS:
      return {
        ...state,
        gettingTenantRecord: false,
        tenantRecord: payload
      };
    case GET_TENANT_RECORD_FAIL:
      return {
        ...state,
        gettingTenantRecord: false,
        tenantRecord: []
      };
    case GET_MONTHLY_RENT_DETAILS_SUCCESS:
      return {
        ...state,
        monthlyRent: payload
      };
    case GET_TENANT_BILLS:
      return {
        ...state,
        loadingBills: true
      };
    case GET_TENANT_BILLS_SUCCESS:
      return {
        ...state,
        loadingBills: false,
        bills: payload
      };
    case GET_TENANT_BILLS_FAILED:
      return {
        ...state,
        loadingBills: false
      };

    case GET_TENANT_INVOICES:
      return {
        ...state,
        loadingInvoices: true
      };
    case GET_TENANT_INVOICES_SUCCESS:
      return {
        ...state,
        loadingInvoices: false,
        invoices: payload
      };
    case GET_TENANT_INVOICES_FAILED:
      return {
        ...state,
        loadingInvoices: false
      };
    default:
      return state;
  }
};

export default tenantReducer;
