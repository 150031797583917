import { Button, styled, Typography } from "@mui/material";
import { useField, useFormikContext } from "formik";
import React, { useRef } from "react";
import { BsFillCloudUploadFill } from "react-icons/bs";

const UploadButton = styled(Button)`
  margin: 15px auto;
  padding: 15px;
  box-sizing: border-box;
  width: 250px;
  height: 150px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(#ffff, #ffff) padding-box,
    linear-gradient(to right, #e1f2ff, #10609f) border-box;
  border: 1px solid transparent;
  border-radius: 8px;
  gap: 10px;
  color: #10609f;
`;

const DocumentUploadField = ({ name, label, ...otherProps }) => {
  const [field, meta] = useField(name);
  const { setFieldValue, touched, setTouched, values } = useFormikContext();
  let document;
  if (name === "accountStatementDoc") {
    const { accountStatementDoc } = values;
    document = accountStatementDoc;
  } else if (name === "agreementDocument") {
    const { agreementDocument } = values;
    document = agreementDocument;
  }
  const fileRef = useRef(null);

  const handleOnChange = (event) => {
    // The idea of this logic is that, when `upload` button is clicked and the user tries to cancel
    // since the input has been clicked, the event returns and empty FILE object
    // because of the cancel.
    // this logic would update the formik state only if the input event returns a file object === 1
    if (document && event.target.files.length === 1) {
      setFieldValue(name, event.target.files[0]);
    } else if (!document && event.target.files.length === 1) {
      setFieldValue(name, event.target.files[0]);
    }
  };
  const configDocumentUploadField = {
    ...otherProps,
    accept: ".pdf"
  };

  return (
    <div className="position-center-column">
      <input
        hidden
        ref={fileRef}
        name={name}
        type={"file"}
        onChange={handleOnChange}
        {...configDocumentUploadField}
      />
      <UploadButton
        type="button"
        variant="contained"
        onClick={() => {
          setTouched({ ...touched, [name]: true });
          fileRef.current.click();
        }}
        color="secondary">
        <BsFillCloudUploadFill fontSize={"26px"} />
        <Typography sx={{ fontSize: "13px", color: "black", marginTop: "10px" }}>
          {label.toUpperCase()}
        </Typography>
      </UploadButton>
      <small
        style={{
          color: "#C8202D",
          textAlign: "center",
          marginTop: "-10px",
          maxWidth: "300px"
        }}>
        Not less than 6 months for Salary earners and not less than 9 months for self
        employed.
      </small>
      {meta && meta.touched && meta.error && (
        <span style={{ color: "red", fontSize: "14px", fontWeight: "bold" }}>
          {meta.error}
        </span>
      )}
    </div>
  );
};

export default DocumentUploadField;
