import * as React from "react";

function SvgUser(props) {
  return (
    <svg width={29.081} height={29.081} {...props}>
      <path
        d="M29.062 28.329l-.727-2.908a4.223 4.223 0 00-2.769-3l-5.331-1.781c-1.3-.542-1.9-2.635-2.024-3.43a5.038 5.038 0 001.781-3.275.988.988 0 01.147-.624.606.606 0 00.416-.364 9.056 9.056 0 00.65-2.648.582.582 0 00-.018-.147 1.558 1.558 0 00-.588-.882V6.059c0-1.952-.6-2.753-1.224-3.217C19.255 1.9 18.248 0 14.54 0a6.318 6.318 0 00-6.059 6.059v3.212a1.558 1.558 0 00-.588.882.583.583 0 00-.018.147 9.057 9.057 0 00.65 2.649.515.515 0 00.367.351c.068.034.2.21.2.639a5.037 5.037 0 001.791 3.281c-.127.794-.719 2.886-1.982 3.415l-5.388 1.782a4.223 4.223 0 00-2.768 3l-.727 2.91a.606.606 0 00.586.755h27.87a.606.606 0 00.588-.753z"
        fill="#043b5c"
      />
    </svg>
  );
}

export default SvgUser;
