import React from "react";
import "./income.scss";
import Container from "../../../components/layout/container";

const Income = () => {
  return (
    <div className="container-fluid">
      <div className="mb-4">
        <div>
          <span className="head-text">Income</span>
          <div className="buttons-right">
            <button className="btn btn-export">Export</button>

            <button className="btn btn-primary btn-invoice">New Invoice</button>
          </div>
        </div>
      </div>

      <div className="card cardtop">
        <div className="card-body">
          <div className="container-fluid">
            <div className="row">
              <div className=" col-md-2">
                <p className="font-text">Status</p>
                <button className="btn btn-success btn-text">Paid</button>
                <br />
                <button className="btn btn-danger btn-text mt-4">Over due</button>
              </div>
              <div className=" col-md-4 r">
                <p className="font-text">Due on</p>
              </div>
              <div className="col-md-6">
                <h6>Income</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Income;
