import { Button } from "@mui/material";
import { useField, useFormikContext } from "formik";
import React, { useRef } from "react";

const ImageUploadField = ({ name, ...otherProps }) => {
  const [field, meta] = useField(name);
  const { setFieldValue, touched, setTouched, values } = useFormikContext();
  const { profileImage } = values;
  const fileRef = useRef(null);

  const handleOnChange = (event) => {
    // The idea of this logic is that, when `upload` button is clicked and the user tries to cancel
    // since the input has been clicked, the event returns and empty FILE object
    // because of the cancel.
    // this logic would update the formik state only if the input event returns a file object === 1
    if (profileImage && event.target.files.length === 1) {
      setFieldValue(name, event.target.files[0]);
    } else if (!profileImage && event.target.files.length === 1) {
      setFieldValue(name, event.target.files[0]);
    }
  };
  const configImageUploadField = {
    ...otherProps,
    accept: ".jpg, .png, .jpeg"
  };

  return (
    <div className="position-center-column">
      <input
        hidden
        ref={fileRef}
        name={name}
        type={"file"}
        onChange={handleOnChange}
        {...configImageUploadField}
      />
      <Button
        type="button"
        variant="contained"
        onClick={() => {
          setTouched({ ...touched, [name]: true });
          fileRef.current.click();
        }}
        color="info">
        Upload Image
      </Button>
      {meta && meta.touched && meta.error && (
        <span
          style={{
            color: "red",
            fontSize: "14px",
            marginTop: "10px",
            fontWeight: "bold"
          }}>
          {meta.error}
        </span>
      )}
    </div>
  );
};

export default ImageUploadField;
