import React from "react";
import Button from "#/components/ui-components/button.component";
import { CircularProgress } from "@material-ui/core";
import {
  setToLocalStorage,
  getFromLocalStorage,
  toCurrency
} from "#/components/utils/util";

function WalletCreditCard({ balance, virtualAccount, makePayment, infoDisplay }) {
  return (
    <>
      <div className="w-full sm:my-3 sm:px-3 sm:w-1/2 md:my-3 md:px-3 md:w-1/2 lg:my-3 lg:px-3 lg:w-1/2 xl:my-3 xl:px-3 xl:w-1/2 m-auto">
        <div className="">
          <div className="mx-auto flex justify-center ">
            <div className="flex flex-col max-w-xl my-2">
              {/* card */}
              {!virtualAccount ? null : (
                <div
                  style={{
                    backgroundImage: 'url("/assets/dashboard/Theme.svg")',
                    paddingRight: "0px",
                    paddingLeft: "15px"
                  }}
                  className="bg-gradient-to-tl from-gray-900 to-gray-800 text-white md:h-56 md:w-96 p-6 rounded-xl shadow-md">
                  <div className="h-full flex flex-col justify-between">
                    <div className="flex items-start justify-between">
                      <div className=" text-xl font-semibold tracking-tigh">
                        IzyPay Wallet
                      </div>
                      <div className="inline-flex flex-col items-center justify-center">
                        <div className="text-white mr-6">
                          <div className="font-bold text-right text-grey text-sm">
                            Credit IzyPay
                          </div>
                          <div className=" flex  flex-col text-right text-white text-sm">
                            <span className="font-semibold">Bank</span>

                            <p>{virtualAccount?.bank_name}</p>
                          </div>
                          <div className="flex  flex-col text-right text-white text-sm">
                            <span className="font-semibold">Account Number</span>{" "}
                            <p>
                              {virtualAccount?.account_number
                                ? virtualAccount?.account_number
                                : null}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="text-xs font-semibold tracking-tight">Balance</div>
                      <div className="text-2xl font-bold text-green my-2">
                        {toCurrency(balance)}
                      </div>
                    </div>

                    {!infoDisplay && (
                      <Button
                        style={{ fontSize: "10px" }}
                        buttonText={makePayment === true ? "Make Payment" : "Send Money"}
                        // onClick={handleSetModalVisibility}
                        className={"bg-white text-blue-900 w-2/6 text-sm "}
                        // disabled={!rentPaymentWindow && !validCharges[0]}
                      />
                    )}
                  </div>
                </div>
              )}
              {/* notification */}
              {false && (
                <div className="relative">
                  <div className="absolute right-10 flex space-x-2 items-start bg-white text-gray-900 border-gray-200 shadow-2xl -mt-16 w-72 px-2 py-3 rounded-lg">
                    <div className="flex-initial">
                      <div className="inline-flex items-center justify-center rounded-lg bg-gradient-tl from-green-400 via-green-400 bg-green-300">
                        <div className="p-2">
                          <svg
                            className="h-4 w-4 text-white opacity-90"
                            width={24}
                            height={24}
                            strokeWidth="1.5"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M15 8.5C14.315 7.81501 13.1087 7.33855 12 7.30872M9 15C9.64448 15.8593 10.8428 16.3494 12 16.391M12 7.30872C10.6809 7.27322 9.5 7.86998 9.5 9.50001C9.5 12.5 15 11 15 14C15 15.711 13.5362 16.4462 12 16.391M12 7.30872V5.5M12 16.391V18.5"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="flex-1 inline-flex items-start justify-between">
                      <div>
                        <h2 className="text-xs font-semibold tracking-tight">
                          Loyalty program
                        </h2>
                        <p className="text-xs text-gray-500 font-light">
                          You received $5 credit
                        </p>
                      </div>
                      <div className="text-xs text-gray-400">17:15</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WalletCreditCard;
