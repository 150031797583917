import React, { useState } from "react";

import { useFormikContext } from "formik";
import { styled } from "@mui/material";

const DLink = styled("a")`
    margin: 20px 10px;
    background: #10609f;
    border-radius: 8px;
    padding: 10px 15px;
    color: white;
    text-transform: capitalize;

    &:hover {
        background: #0e4d7d;
        color: #f7eeee;
    }
`;

const DocumentPreview = () => {
    const { values } = useFormikContext();
    const { accountStatementDoc } = values;
    let documentPreview;
    if (accountStatementDoc) {
        if (typeof accountStatementDoc !== "string") {
            const file = URL.createObjectURL(accountStatementDoc);
            documentPreview = file;
        }
    }

    return (
        <div className="position-center">
            <DLink href={documentPreview ? documentPreview : accountStatementDoc} target="_blank">
                {documentPreview
                    ? accountStatementDoc.name
                    : accountStatementDoc
                    ? "Click to View Uploaded document"
                    : ""}
            </DLink>
        </div>
    );
};

export default DocumentPreview;
