import axios from "axios";
import { showNotifications } from "#/store/actions/notification.actions";
import * as actionTypes from "../constants";
import { propertyServiceApi } from "#/apis/propertyServiceApi";

const {
  GET_APARTMENT_TENANTS_FAIL,
  GET_APARTMENT_TENANTS_PENDING,
  GET_APARTMENT_TENANTS_SUCCESS,

  GET_TENANTS_UNDER_PM_FAIL,
  GET_TENANTS_UNDER_PM_SUCCESS,
  GET_TENANTS_UNDER_PM_PENDING,

  CREATE_TENANT_BY_LID_FAIL,
  CREATE_TENANT_BY_LID_PENDING,
  CREATE_TENANT_BY_LID_SUCCESS,

  CLEAR_CREATED_TENANT,

  GET_TENANTS_APARTMENT_FAIL,
  GET_TENANTS_APARTMENT_PENDING,
  GET_TENANTS_APARTMENT_SUCCESS,
  GET_MONTHLY_RENT_DETAILS_SUCCESS,
  GET_MONTHLY_RENT_DETAILS_FAILED,
  GET_TENANT_RECORD_PENDING,
  GET_TENANT_RECORD_SUCCESS,
  GET_TENANT_RECORD_FAIL,

  GET_TENANT_BILLS,
  GET_TENANT_BILLS_FAILED,
  GET_TENANT_BILLS_SUCCESS,

  GET_TENANT_INVOICES,
  GET_TENANT_INVOICES_FAILED,
  GET_TENANT_INVOICES_SUCCESS
} = actionTypes.TENANTS_CONSTANTS;

const BASE_URL = process.env.REACT_APP_PROPERTY_SERVICE_BASE_URL;

//------------------------------------------------------------------
//Creating Schedules
//------------------------------------------------------------------
export const createTenants = (payload, cb) => async (dispatch) => {
  dispatch({ type: actionTypes.TENANTS_CONSTANTS.CREATE_TENANT_PENDING });

  try {
    const response = await axios.post(`${BASE_URL}/tenant/store`, payload);
    if ([200, 201].includes(response.status)) {
      dispatch({
        type: actionTypes.TENANTS_CONSTANTS.CREATE_TENANT_SUCCESS,
        payload: response.data.payload
      });
      dispatch(showNotifications("Tenant Invitation Sent", "success"));
      cb();
    }
  } catch (error) {
    dispatch({ type: actionTypes.TENANTS_CONSTANTS.CREATE_TENANT_FAIL, error });
    dispatch(showNotifications(`${error.response.data.error}`, "error"));
  }
};

export const addTenantByBioData = (payload, cb) => async (dispatch) => {
  dispatch({ type: actionTypes.TENANTS_CONSTANTS.CREATE_TENANT_PENDING });

  try {
    const response = await axios.post(
      `${BASE_URL}/tenant/store/bio-data?type=tenant`,
      payload
    );
    if ([200, 201].includes(response.status)) {
      dispatch({
        type: actionTypes.TENANTS_CONSTANTS.CREATE_TENANT_SUCCESS,
        payload: { ...response.data.payload, ...response.data.tenantDetails }
      });
      dispatch(showNotifications("Tenant Created & Added to Apartment", "success"));
      cb();
    }
  } catch (error) {
    dispatch({ type: actionTypes.TENANTS_CONSTANTS.CREATE_TENANT_FAIL, error });
    dispatch(showNotifications(`${error.response.data.error.message}`, "error"));
  }
};

export const createTenantByLid = (payload, cb) => async (dispatch, getState) => {
  const { userId, apartment_id, rentDate, email, phone_number, fullname } = payload;
  // const { email, name, phone, liveizy_id } = getState().auth.liveIzyUser;
  const formData = new FormData();

  formData.append("apartment_id", apartment_id);
  formData.append("method_of_invitation", "liveizy-id");
  formData.append("user_id", userId);
  formData.append("email", email);
  formData.append("phone_number", phone_number);
  formData.append("fullname", fullname);
  formData.append("rent_payment_date", rentDate);

  dispatch({ type: CREATE_TENANT_BY_LID_PENDING });

  try {
    const response = await axios.post(`${BASE_URL}/tenant/store?type=tenant`, formData);
    if ([200, 201].includes(response.status)) {
      dispatch({
        type: CREATE_TENANT_BY_LID_SUCCESS,
        payload: response.data.payload
      });
      dispatch(showNotifications("Tenant Invitation Sent", "success"));
      cb();
    }
  } catch (error) {
    dispatch({ type: CREATE_TENANT_BY_LID_FAIL, error });
    dispatch(showNotifications(`${error.response.data.message}`, "error"));
  }
};

//------------------------------------------------------------------
//Get Tenants for an apartment
//------------------------------------------------------------------
export const getTenant = (id) => async (dispatch) => {
  dispatch({ type: GET_APARTMENT_TENANTS_PENDING });

  try {
    const response = await axios.get(`${BASE_URL}/tenant/index/apartment/${id}`);

    if ([200, 201].includes(response.status)) {
      dispatch({
        type: GET_APARTMENT_TENANTS_SUCCESS,
        payload: response.data.payload[0]
      });
    }
  } catch (error) {
    dispatch({ type: GET_APARTMENT_TENANTS_FAIL, error });
  }
};

//------------------------------------------------------------------
//Get Tenants under property manager
//------------------------------------------------------------------
export const getTenantsUnderPropertyManager = (id) => async (dispatch) => {
  dispatch({ type: GET_TENANTS_UNDER_PM_PENDING });

  try {
    const response = await axios.get(
      `${BASE_URL}/tenant/index/property-manager/${id}?skip=0&limit=100`,
      {
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "1"
        }
      }
    );

    if ([200, 201].includes(response.status)) {
      dispatch({
        type: GET_TENANTS_UNDER_PM_SUCCESS,
        payload: response.data.payload
      });
    }
  } catch (error) {
    dispatch({ type: GET_TENANTS_UNDER_PM_FAIL, error });
  }
};

export const clearCreatedTenant = () => (dispatch) => {
  dispatch({ type: CLEAR_CREATED_TENANT });
};

export const getTenantsApartment = (id) => async (dispatch) => {
  dispatch({ type: GET_TENANTS_APARTMENT_PENDING });
  dispatch({ type: GET_TENANT_RECORD_PENDING });

  try {
    const response = await axios.get(
      `${BASE_URL}/tenant/lid/${id}?status=resident&populate1=apartment_id`,
      {
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "1"
        }
      }
    );

    if ([200, 201].includes(response.status)) {
      dispatch({
        type: GET_TENANTS_APARTMENT_SUCCESS,
        payload: response.data.payload[0]
      });
      dispatch({
        type: GET_TENANT_RECORD_SUCCESS,
        payload: response.data.payload
      });
    }
  } catch (error) {
    dispatch({ type: GET_TENANTS_APARTMENT_FAIL, error });
    dispatch({ type: GET_TENANT_RECORD_FAIL, error });
  }
};

export const linkTenantLid = (id, payload, cb) => async (dispatch) => {
  try {
    await propertyServiceApi().patch(`/tenants/lid-link-up/${id}`, payload);
    dispatch(showNotifications("Tenant Liveizy Id Linked", "success"));
    cb(true);
  } catch (error) {
    cb(false);
    dispatch(
      showNotifications(
        error.response?.data?.error || "Error occured. Try again later",
        "error",
        6000
      )
    );
  }
};

export const getMonthlyRentDetails = (id) => async (dispatch) => {
  dispatch({ type: GET_TENANTS_UNDER_PM_PENDING });

  try {
    const response = await axios.get(`${BASE_URL}/application/${id}`, {
      headers: {
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "1"
      }
    });

    if ([200, 201].includes(response.status)) {
      dispatch({
        type: GET_MONTHLY_RENT_DETAILS_SUCCESS,
        payload: response.data.payload
      });
    }
  } catch (error) {
    // dispatch({ type: GET_MONTHLY_RENT_DETAILS_FAILED, error });
  }
};

export const getTenantBills = (apartmentId) => async (dispatch) => {
  dispatch({ type: GET_TENANT_BILLS });

  try {
    const response = await axios.get(
      `${BASE_URL}/bill/get_apartment_bills/${apartmentId}`,
      {
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "1"
        }
      }
    );

    const charges = await response.data.data;
    const bills = [...charges];

    dispatch({
      type: GET_TENANT_BILLS_SUCCESS,
      payload: bills
    });
    return bills;
  } catch (error) {
    dispatch({ type: GET_TENANT_BILLS_FAILED });
    throw error;
  }
};

export const getTenantInvoices = (apartmentId) => async (dispatch) => {
  dispatch({ type: GET_TENANT_INVOICES });

  try {
    const response = await axios.get(
      `${BASE_URL}/invoice/get_apartment_invoice/${apartmentId}`,
      {
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "1"
        }
      }
    );

    const invoices = await response.data.data;
    // const bills = [...invoices];

    dispatch({
      type: GET_TENANT_INVOICES_SUCCESS,
      payload: invoices
    });
    return invoices;
  } catch (error) {
    dispatch({ type: GET_TENANT_INVOICES_FAILED });
    throw error;
  }
};

// FOR BILLS
export const selectTenantBills = (state) => {
  return state.tenant.bills;
};
export const selectLoadingTenantBills = (state) => state.tenant.loadingBills;

//FOR INVOICES
export const selectTenantInvoices = (state) => {
  return state.tenant.invoices;
};

export const selectLoadingTenantInvoices = (state) => state.tenant.loadingInvoices;
// if (response.status === 200) {
//   // const rent = response.data.payload[0].normal_rent;
//   const charges = response.data.data;
//   // const charges = response.data.payload[0].charges;
//   const bills = [...charges];
//   dispatch({
//     type: GET_TENANT_BILLS_SUCCESS,
//     payload: bills
//   });

//   return bills;
// }
// return response.data.data;
// const rent = response.data.payload[0].normal_rent;

// // Example getTenantBills action
// export const getTenantBills = (apartmentId) => {
//   return async (dispatch) => {
//     try {
//       // Perform async operation (e.g., API call)
//       const response = await fetch(`/api/bills/${apartmentId}`);
//       const data = await response.json();

//       // Dispatch an action with the fetched data
//       dispatch({ type: 'GET_TENANT_BILLS_SUCCESS', payload: data });

//       // Return the fetched data
//       return data;
//     } catch (error) {
//       dispatch({ type: 'GET_TENANT_BILLS_FAILURE', payload: error.message });
//       throw error; // Re-throw the error to be caught in the component
//     }
//   };
// };

// export const getTenantBills = (apartmentId) => async (dispatch) => {
//   dispatch({ type: GET_TENANT_BILLS });

//   try {
//     const response = await axios.get(
//       `${BASE_URL}/user-bill/${apartmentId}?populate1=charges&populate2=normal_rent&populate3=rent_loan`,
//       {
//         headers: {
//           "Content-Type": "application/json"
//         }
//       }
//     );

//     const rent = response.data.payload[0].normal_rent;
//     const charges = response.data.payload[0].charges;
//     const bills = [rent, ...charges];
//     dispatch({
//       type: GET_TENANT_BILLS_SUCCESS,
//       payload: bills
//     });
//   } catch (error) {
//     dispatch({ type: GET_TENANT_BILLS_FAILED });
//   }
// };
