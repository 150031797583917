import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: "flex",
        justifyContent: "space-between",
        border: "1px solid #45B29D",
        borderRadius: "3px",
        backgroundColor: "#74D5C226",
        padding: "10px 50px 10px 50px",
        marginBottom: "30px",
        fontSize: "17px",
        fontWeight: "bold",

        [theme.breakpoints.down("sm")]: {
            padding: "10px 10px 10px 10px"
        },

        "& .text-one": {
            color: "#043B5C",

            [theme.breakpoints.down("sm")]: {
                fontSize: "13px"
            },

            "& .sub-text": {
                color: "#E41A1A",
                textDecoration: "underline",
                cursor: "pointer"
            },
            "& .sub-text2": {
                color: "green",
                textDecoration: "underline",
                cursor: "pointer"
            }
        },

        "& .text-two": {
            color: "#043B5C",
            fontSize: "15px",
            [theme.breakpoints.down("sm")]: {
                fontSize: "13px"
            }
        },

        "& .spinner": {
            margin: "0 auto"
        },

        "& .btn-wrapper": {
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "0px 20px"
        }
    },

    closeBtnTransfer: {
        backgroundColor: "white",
        color: "#043B5C",
        textTransform: "none",
        borderRadius: "5px",
        border: "1px solid #043B5C91",
        padding: "7px 25px",
        fontSize: "13px",
        fontFamily: "Open Sans, sans-serif",
        padding: "13px 13%",
        marginRight: "20px",
        fontFamily: "Open Sans-bold , san-serif",
        fontWeight: "bold",
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        }
    },

    activateBtn: {
        backgroundColor: "#4786FF",
        color: "#E8F0F9",
        textTransform: "none",
        borderRadius: "5px",
        color: "#F4F5F7",
        border: "1px solid #45B29D",
        border: "none",
        padding: "7px 25px",
        fontSize: "13px",
        padding: "14px 15%",
        fontFamily: "Open Sans-bold , san-serif",
        fontWeight: "bold",

        "&:hover": {
            color: "#E8F0F9",
            backgroundColor: "#4786FF"
        },

        [theme.breakpoints.down("sm")]: {
            marginTop: "10px",
            width: "100%"
        }
    },

    btnBlue: {
        backgroundColor: "#4786FF",
        textTransform: "none",
        borderRadius: "5px",
        color: "#F4F5F7",
        border: "1px solid #4786FF",
        fontFamily: "Open Sans, sans-serif",
        padding: "7px 20px",
        fontSize: "13px",

        [theme.breakpoints.down("sm")]: {
            padding: "7px 15px",
            fontSize: "11px"
        }
    },

    btnOutlined: {
        backgroundColor: "#fff",
        border: "1px solid #043B5C91",
        color: "#043B5C91"
    }
}));
