import { Box, styled } from "@mui/material";
// import React from "react";

const PaperContainer = styled(Box)`
  border-radius: 1.21238rem;
  background: #fff;
  box-shadow: 0px 4px 29px -9px rgba(0, 0, 0, 0.25);
  padding: 1rem;
`;

// const PaperContainer = () => {
//   return <Container>PaperContainer</Container>;
// };

export default PaperContainer;
