import React, { useEffect, useState } from "react";
import CustomInputAndLabelField from "#/components/customInputFields/CustomInputAndLabelField";
import CustomSelectField from "#/components/customInputFields/customSelectField/CustomSelectField";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import CustomMuiBtn from "../CustomMUIBtn";
import { useFormik } from "formik";
import { FormHelperText } from "@mui/material";
import { propertyServiceApi } from "#/apis/propertyServiceApi";
import { showNotifications } from "#/store/actions/notification.actions";
import { walletServiceApi } from "#/apis/izyPayApi";

export default function BankDetailsForm({ role, setState }) {
  const dispatch = useDispatch();
  const [validating, setValidating] = useState(false);
  const bankList = useSelector((state) => state.paystack?.banksInNigeria);
  const user = useSelector((state) => state.auth.user);
  const bankOptions = bankList.map((bank) => ({
    label: bank.name,
    value: bank.id
  }));

  const formik = useFormik({
    initialValues: {
      accName: "",
      accNumber: "",
      bank: ""
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      addBank(values, resetForm);
    }
  });

  const addBank = (values, resetForm) => {
    const userRole = role === "landlord" ? "property-owner" : "property-manager";
    const payload = {
      code: bankList.find(({ id }) => id == values.bank.value).code,
      id: values.bank.value,
      name: values.bank.label,
      account_number: values.accNumber,
      account_name: values.accName
    };
    propertyServiceApi()
      .patch(`/${userRole}/bank/${user.liveizy_id}`, payload)
      .then((data) => {
        dispatch(showNotifications("Bank Account added", "success"));
        resetForm();
        setState(data.data.payload);
      })
      .catch(({ response }) => {
        dispatch(showNotifications(response?.data?.error, "error"));
      });
  };

  useEffect(() => {
    const { bank, accNumber } = formik.values;
    if (bank && accNumber.length === 10) validateBankAccount(bank.value, accNumber);
  }, [formik.values.bank, formik.values.accNumber]);

  const validateBankAccount = (bank_id, account_number) => {
    setValidating(true);
    walletServiceApi()
      .get(`/user/validate/nuban/${account_number}/bank/${bank_id}`)
      .then(({ data }) => {
        formik.setFieldValue("accName", data.data.accountName);
        setValidating(false);
      })
      .catch((err) => {
        dispatch(showNotifications("Could not resolve account name", "error"));
        setValidating(false);
      });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <CustomSelectField
            label={"Bank Name"}
            placeholder={"Select Bank..."}
            options={bankOptions}
            isLoading={bankList.length === 0}
            name="bank"
            onChange={(selectedOption) => {
              const event = {
                target: {
                  name: "bank",
                  value: selectedOption
                }
              };
              formik.handleChange(event);
            }}
            defaultValue={formik.values.bank}
          />
          {formik.errors.bank ? (
            <FormHelperText sx={{ marginTop: "-15px" }} error={true} margin="dense">
              {formik.errors.bank}
            </FormHelperText>
          ) : null}
        </Grid>

        <Grid item xs={12} sm={6} md={5}>
          <CustomInputAndLabelField
            label={"Account Number"}
            type={"text"}
            placeholder={"Enter Account Number"}
            name="accNumber"
            onChange={formik.handleChange}
            max={10}
            value={formik.values.accNumber}
          />
          {formik.errors.accNumber ? (
            <>
              <FormHelperText sx={{ marginTop: "-15px" }} error={true} margin="dense">
                {formik.errors.accNumber}
              </FormHelperText>
            </>
          ) : null}
          {validating && (
            <FormHelperText sx={{ marginTop: "-15px", color: "#10609f" }} margin="dense">
              Validating...
            </FormHelperText>
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <CustomInputAndLabelField
            label={"Account Name"}
            type={"text"}
            placeholder={"Enter Account Name"}
            name="accName"
            disabled
            value={formik.values.accName}
          />
          {formik.errors.accName ? (
            <FormHelperText sx={{ marginTop: "-15px" }} error={true} margin="dense">
              {formik.errors.accName}
            </FormHelperText>
          ) : null}
        </Grid>
      </Grid>
      <Box textAlign={"center"}>
        <CustomMuiBtn type="submit" sx={{ margin: "0 auto" }}>
          Submit
        </CustomMuiBtn>
      </Box>
    </form>
  );
}

const validate = (values) => {
  const errors = {};
  if (!values.accName) {
    errors.accName = "This field is Required";
  }

  if (!values.accNumber) {
    errors.accNumber = "This field is Required";
  } else if (values.accNumber.length !== 10 || !/^[0-9]+$/.test(values.accNumber)) {
    errors.accNumber = "Must be 10 digits";
  }

  if (!values.bank) {
    errors.bank = "Please select Bank";
  }

  return errors;
};
