import { styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import noImageAvatar from "#/assets/images/no-image.jpg";

const ImagePreview = styled("div")`
    margin: 15px auto;
    box-sizing: border-box;
    width: 250px;
    height: 150px;
    background: linear-gradient(#ffff, #ffff) padding-box, linear-gradient(to right, #e1f2ff, #10609f) border-box;
    border: 1px solid transparent;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 8px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const ImageRectPreviwer = ({ src, alt = "Image" }) => {
    const [preview, setPreview] = useState(noImageAvatar);

    useEffect(() => {
        if (src) {
            if (typeof src === "string") {
                setPreview(src);
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(src);
                reader.onload = () => {
                    setPreview(reader.result);
                };
            }
        }
    }, [src]);
    return (
        <ImagePreview>
            <img src={preview} alt={alt} />
        </ImagePreview>
    );
};

export default ImageRectPreviwer;
