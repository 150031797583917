import React, { useState, useEffect, useCallback } from "react";

import Card from "@material-ui/core/Card";
import { Grid, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Backdrop from "@material-ui/core/Backdrop";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";

import SvgMoney from "./icons/Money";
import SvgTeam from "./icons/Team";
import SvgShop from "./icons/Shop";
import SvgUser from "./icons/User";
import rentIcon from "./icons/rent.svg";

import { usePopupStyles } from "./style";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  closeServicePopup,
  assignRole,
  assignGeneralRole
} from "../../store/actions/role.actions";
import { CircularProgress } from "@material-ui/core";
import { authServiceApi } from "#/apis/authServiceApi";
import { showNotifications } from "#/store/actions/notification.actions";

// Interests
const IZYINVEST = "izyInvest";
const MONTHLY_RENT = "monthlyRent";

// Roles
const AGENT = "Agent";
const LANDLORD = "Landlord";
const GENERAL_USER = "general-user";

const Popup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = usePopupStyles();

  //----------------------------------------------------------------
  //STORE
  //----------------------------------------------------------------
  const store = useSelector((state) => state.roles);

  //----------------------------------------------------------------
  //STATE
  //----------------------------------------------------------------
  const [open, setOpen] = useState(store.popup);

  // Interests
  const [interests, setInterests] = useState([]);
  const [interestsList, setInterestsLists] = useState({
    [IZYINVEST]: false,
    [MONTHLY_RENT]: false
  });

  const handleInterest = (interest) => {
    let item = interest;
    if (item === MONTHLY_RENT) {
      item = "monthly rent";
    }
    if (interests.includes(item)) {
      setInterests((prev) => prev.filter((interestt) => interestt !== item));
      setInterestsLists((prevState) => ({ ...prevState, [interest]: false }));
    } else {
      setInterests((prev) => [...prev, item]);
      setInterestsLists((prevState) => ({ ...prevState, [interest]: true }));
    }
  };

  // Roles
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [roles, setRoles] = useState({
    [AGENT]: false,
    [LANDLORD]: false
  });

  const handleRoles = (role) => {
    if (selectedRoles.includes(role)) {
      setSelectedRoles((prevState) => prevState.filter((eachRole) => eachRole !== role));
      setRoles((prevState) => ({ ...prevState, [role]: false }));
    } else {
      setSelectedRoles((prevState) => [...prevState, role]);
      setRoles((prevState) => ({ ...prevState, [role]: true }));
    }
  };

  //----------------------------------------------------------------
  //HELPER FUNCTIONS
  //----------------------------------------------------------------

  const handleSubmit = async () => {
    // handle Interests
    if (interestsList) {
      const interestData = {
        interests
      };
      await authServiceApi().put("user/other-information", interestData);
    }
    if (selectedRoles) {
      selectedRoles.forEach((role) => {
        const roleData = {
          role
        };
        dispatch(assignRole(roleData, () => {}));
      });
    } else {
      dispatch(assignGeneralRole(GENERAL_USER));
      dispatch(closeServicePopup());
    }
    return dispatch(closeServicePopup());
  };

  //----------------------------------------------------------------
  //SIDE EFFECTS
  //----------------------------------------------------------------
  useEffect(() => {
    setOpen(store.popup);
  }, [open, store.popup]);

  return (
    <div>
      <Backdrop className={classes.backdrop} open={open}>
        <div className={classes.container}>
          <Card className={classes.root}>
            <div className={classes.cardheader}>
              <Typography variant="h6" component="h6">
                What are you looking for?
              </Typography>
              <Typography component="p">
                Knowing the feature that matter most to you helps us improve Liveizy and
                build you a better experience
              </Typography>
            </div>
            <div className={classes.cardbody}>
              <section className={classes.cardGrid}>
                <p className={classes.sectionTitle}>
                  Select the Service(s) you are interested in
                </p>
                <Grid container spacing={3} direction="row" justifyContent="center">
                  <Grid item xs={6} md={6}>
                    <Button
                      name={IZYINVEST}
                      variant="contained"
                      className={classes.btn}
                      onClick={() => handleInterest(IZYINVEST)}>
                      <SvgMoney width="40px" height="40px" />
                      izyinvest
                      {interestsList.izyInvest && (
                        <DoneOutlineIcon className={classes.check}></DoneOutlineIcon>
                      )}
                    </Button>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Button
                      name={MONTHLY_RENT}
                      variant="contained"
                      sx={{ display: "flex", padding: 0 }}
                      className={classes.btn}
                      onClick={() => handleInterest(MONTHLY_RENT)}>
                      <img
                        src={rentIcon}
                        width="50px"
                        height={"40px"}
                        style={{
                          filter:
                            "invert(18%) sepia(13%) saturate(6905%) hue-rotate(175deg) brightness(93%) contrast(97%)"
                        }}
                        alt="Rent Icon"
                      />
                      Monthly Rent
                      {interestsList.monthlyRent && (
                        <DoneOutlineIcon className={classes.check}></DoneOutlineIcon>
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </section>
              <section className={classes.cardGrid}>
                <p className={classes.sectionTitle}>Select Role(s) applicable to you</p>
                <Grid container spacing={3} direction="row" justifyContent="center">
                  <Grid item xs={6} md={6}>
                    <Button
                      variant="contained"
                      className={classes.btn}
                      onClick={() => handleRoles(AGENT)}>
                      <SvgTeam width="40px" height="40px" />
                      Property manager
                      {/* {selectedRoles.includes("Landlord") ? (
                                            <DoneOutlineIcon className={classes.check}></DoneOutlineIcon>
                                        ) : null} */}
                      {roles.Agent && (
                        <DoneOutlineIcon className={classes.check}></DoneOutlineIcon>
                      )}
                    </Button>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Button
                      variant="contained"
                      className={classes.btn}
                      onClick={() => handleRoles(LANDLORD)}>
                      <SvgUser width="40px" height="40px" />
                      Property Owner
                      {roles.Landlord && (
                        <DoneOutlineIcon className={classes.check}></DoneOutlineIcon>
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </section>

              <Typography component="p" className={classes.textPrimary}>
                These are just some of our features - Liveizy can do a whole lot more!
              </Typography>
              <div className={classes.buttonX}>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  className={classes.button}
                  disabled={store.loadingRole}
                  onClick={handleSubmit}>
                  {store.loadingRole ? (
                    <CircularProgress size={16} color="inherit" />
                  ) : (
                    "continue"
                  )}
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </Backdrop>
    </div>
  );
};

export default Popup;
