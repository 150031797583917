import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import avatar from "#/assets/images/tenant/default-avatar.png";

const useRowStyles = makeStyles({
    root: {
        cursor: "pointer",
        borderBottom: "2px solid #d2e4f7",
        "& .th-text": {
            fontSize: 13,
            color: "#043B5C"
        },
        "& img": {
            width: "40px",
            borderRadius: "100%"
        },
        "& .picture-container": {
            display: "flex",
            "& div:last-child": {
                marginLeft: 10,
                textAlign: "center",
                marginTop: 10
            }
        }
    }
});

const Row = (props) => {
    const { row } = props;
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell className="picture-container" component="th" scope="row">
                    <div>
                        <span>
                            <img src={avatar} alt="tenants" />
                        </span>
                    </div>
                    <div className="th-text">
                        <Link to="/tenant/detail">{row.name}</Link>
                    </div>
                </TableCell>
                <TableCell className="th-text" align="center">
                    {row.property}
                </TableCell>
                <TableCell className="th-text" align="center">
                    {row.address}
                </TableCell>
                <TableCell className="th-text" align="center">
                    {row.accountStatus}
                </TableCell>
                <TableCell className="th-text" align="center">
                    {row.status}
                </TableCell>
                <TableCell className="th-text" align="center">
                    &#x20A6; {row.rent}
                </TableCell>
                <TableCell className="th-text" align="center">
                    {row.roommates}
                </TableCell>
                <TableCell>
                    <NavigateNextIcon className="th-text" />
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

export default Row;
