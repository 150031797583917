import React, { useEffect, useState } from "react";
import { renewTenantRent, payForRentWithSpecta } from "#/store/actions/payments.action";
import PaymentComponent from "./components/PaymentComponent";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { propertyServiceApi } from "#/apis/propertyServiceApi";
import { showNotifications } from "#/store/actions/notification.actions";

const PaymentPageRenewRent = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const itemsToPay = localStorage.getItem("RENEW_RENT");
    const items = JSON.parse(itemsToPay);
    const total = items?.items[0]?.price;

    const [selectedMethod, setSelectedMethod] = React.useState(null);
    const [amount, setAmount] = useState(0);
    const [monthsLeft, setMonthsLeft] = useState(0);
    const [months, setMonths] = useState(0);

    useEffect(() => {
        fetchPaymentRecord();
    }, []);

    if (!itemsToPay) {
        history.push("/dashboard");
        return null;
    }

    const onSubmit = () => {
        if (selectedMethod === "izypay") {
            return izyPay();
        }
        payWithSpecta();
    };

    const payWithSpecta = () => {
        const rentPayload = { apartment_id: items.apartment_id };
        dispatch(payForRentWithSpecta({ amount }, rentPayload, history));
    };

    const izyPay = () => {
        const rentPayload = {
            months: Number(months),
            apartment_id: items.apartment_id,
            context: Number(months) === 12 ? "full-payment" : "part-payment"
        };
        dispatch(
            renewTenantRent(rentPayload, (bool) => {
                if (bool) return history.push("/dashboard");
            })
        );
    };

    const fetchPaymentRecord = async () => {
        try {
            if (!items) return;
            const { apartment_id, year } = items;
            const data = await propertyServiceApi().get(`/rent-record/${apartment_id}/${year}`);
            const prev = data.data.payload;
            const latestPayment = prev[prev.length - 1];
            if (!prev.length || !latestPayment) {
                setAmount(total);
                setMonthsLeft(12);
                setMonths(12);
                return;
            }
            setAmount((latestPayment.months_left * total) / 12);
            setMonthsLeft(latestPayment.months_left);
            setMonths(latestPayment.months_left);
        } catch (error) {
            dispatch(showNotifications("Error occured with payment service. Try again later", "error"));
        }
    };

    return (
        <PaymentComponent
            amountReadonly={true}
            spectaAvailable={true}
            fullAmount={total}
            amount={amount}
            setAmount={setAmount}
            monthsLeft={monthsLeft}
            setMonths={setMonths}
            items={items}
            paymentMethod={selectedMethod}
            setPaymentMethod={setSelectedMethod}
            onSubmit={onSubmit}
            payOptions
        />
    );
};

export default PaymentPageRenewRent;
