import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { loginStyles } from "../login/login.styles";
import { CustomInput } from "../../../components/ui-components/input.component";
import { useForm } from "react-hook-form";
import Logo1 from "../../../assets/images/liveizy-logo.png";
import CustomButton from "../../../components/ui-components/button.component";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "./../../../store/actions/auth.actions";
import { ErrorDiv } from "../../../components/errorDiv";
import AuthPageContainer from "../AuthPageContainer";

const LoginForm = (props) => {
    const classes = loginStyles();
    const dispatch = useDispatch();
    const [typing, setTyping] = useState(false);
    const history = useHistory();
    const [submitting, setSubmitting] = useState(false);
    const { register, watch, handleSubmit, setValue, errors, reset } = useForm({
        mode: "onBlur"
    });
    const messages = useSelector((store) => store.notifications.msg);
    const values = watch();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValue(name, value);

        //to show the visibilty toggle on the password input
        if (name === "password" || name === "password_confirmation") {
            setTimeout(() => {
                setTyping(true);
            }, 2000);
        }
    };

    //hook to query string from URL
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    const onFormSubmit = (vals) => {
        if (submitting) return;
        setSubmitting(true);
        const { ...rest } = vals;
        const token = query.get("token");
        const data = { ...rest, token };
        dispatch(
            resetPassword(data, history, () => {
                setSubmitting(false);
            })
        );
    };

    //validating password
    const validatePassword = (value) => {
        if (value !== values.password) {
            return "The passwords do not match";
        }
    };

    return (
        <Grid container className={classes.formContainer}>
            <form className={classes.form} onSubmit={handleSubmit(onFormSubmit)}>
                <Grid
                    item
                    xs={12}
                    style={{
                        marginBottom: "2.5rem"
                    }}>
                    <div>
                        <a href="https://liveizy.com/">
                            <img
                                src={Logo1}
                                alt="logo"
                                style={{
                                    width: "6rem"
                                }}
                            />
                        </a>
                    </div>
                </Grid>
                <Grid
                    item
                    xs={12}
                    style={{
                        marginBottom: "1.5rem"
                    }}>
                    <span
                        style={{
                            color: "#043B5C",
                            fontSize: "1.2rem",
                            fontWeight: "bold"
                        }}>
                        RESET PASSWORD
                    </span>
                </Grid>
                <Grid item xs={12}>
                    <CustomInput
                        label="Email address"
                        name="email"
                        error={errors.email && errors.email.message}
                        customRef={register({
                            required: "This field is required",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "invalid email address"
                            }
                        })}
                        value={values.email || ""}
                        inputWidth="379px"
                        inputHeight="50px"
                        type="email"
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomInput
                        label="Password"
                        name="password"
                        error={errors.password && errors.password.message}
                        customRef={register({
                            required: "This field is required",
                            minLength: {
                                value: 6,
                                message: "Password must contain above 6 characters"
                            }
                        })}
                        value={values.password}
                        inputWidth="379px"
                        inputHeight="50px"
                        type="password"
                        onChange={handleInputChange}
                        typing={typing}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomInput
                        label="Confirm Password"
                        name="password_confirmation"
                        error={errors.password_confirmation && errors.password_confirmation.message}
                        customRef={register({
                            required: "This field is required",
                            validate: validatePassword
                        })}
                        value={values.password_confirmation}
                        inputWidth="379px"
                        inputHeight="50px"
                        type="password"
                        onChange={handleInputChange}
                        typing={typing}
                    />
                </Grid>
                <Grid item xs={12}>
                    {messages !== "" && <ErrorDiv>{messages}</ErrorDiv>}
                </Grid>
                <Grid item xs={12}>
                    <CustomButton
                        buttonText="Reset"
                        isLoading={submitting}
                        style={{
                            backgroundColor: "#4786FF",
                            color: "#FFFFFF",
                            width: "8rem",
                            textTransform: "none",
                            marginTop: "1.5rem",
                            marginBottom: "1rem"
                        }}
                    />
                </Grid>
            </form>
        </Grid>
    );
};

const ResetPassword = () => {
    return (
        <AuthPageContainer>
            <LoginForm />
        </AuthPageContainer>
    );
};

export default ResetPassword;
