import { walletServiceApi } from "#/apis/izyPayApi";
import BankAccountForm from "#/components/bankAccountForm/BankAccountForm";
import { showNotifications } from "#/store/actions/notification.actions";
import { ListOfBanksInNigeria } from "#/constants";
// import Modal from "#/components/modal/modal.component";
import { Button, CircularProgress, Container, Modal, Stack, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SubtitleAndSubtext from "#/pages/RentLoanNew/UIcomponents/others/SubtitleAndSubtext";
import DetailsCardContainer, {
  Details
} from "#/pages/RentLoanNew/UIcomponents/cards/DetailsCardContainer";
import BankSelectField from "./bankSelect/BankSelectField";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: 400,
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 6
};

const Header = styled("div")`
  font-weight: bold;
  margin-bottom: 20px;
  h4 {
    color: #10609f;
    font-size: 18px;
  }
  h6 {
    font-size: 17px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 8px;
  }
  p {
    font-size: 15px;
    text-align: center;
  }
`;

const CardContainer = styled(Container)`
  background: white;
  padding: 20px 10px;
  border-radius: 12px;

  @media (max-width: 400px) {
    width: 300px;
  }
`;

const BankAccountModal = ({
  attachedBankDetails,
  headerName,
  headerId,
  itemId,
  showBankModal,
  handleShowBankModal,
  accountName,
  setAccountName,
  bankDetails,
  setBankDetails,
  handleSubmit,
  setShowBankModal,
  owner,
  loadingBankSubmit
}) => {
  const assignedBank = attachedBankDetails && attachedBankDetails[0];
  const bankName = attachedBankDetails && attachedBankDetails[0]?.name;
  const accNumber = attachedBankDetails && attachedBankDetails[0]?.account_number;
  const accName = attachedBankDetails && attachedBankDetails[0]?.account_name;
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const [showForm, setShowForm] = useState(false);

  // useEffect(() => {
  //     if (assignedBank && attachedBankDetails[0]) {
  //         const { bank_code, account_number } = attachedBankDetails[0];
  //         walletServiceApi()
  //             .post("/user/validate/nuban", { bank_code, account_number })
  //             .then((data) => {
  //                 setAccName(data.data.data.account_name);
  //                 setAccNumber(data.data.data.account_number);
  //                 let nameOfBank = ListOfBanksInNigeria.find((bank) => bank.code === bank_code);
  //                 setBankName(nameOfBank.name);
  //             })
  //             .catch((err) => {
  //                 dispatch(showNotifications("Could not resolve Account Name", "error"));
  //             });
  //     }
  // }, []);
  return (
    <Modal
      open={showBankModal}
      onClose={handleShowBankModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description">
      <div style={style}>
        <CardContainer>
          <Header>
            <h4>{open ? "Attach Bank Account" : "Assigned Bank Account"}</h4>
            <h6>{headerName}</h6>
            <p>{headerId}</p>
          </Header>
          <>
            {!open && (
              <div>
                {assignedBank && attachedBankDetails[0] ? (
                  <Details>
                    <SubtitleAndSubtext subtitle={"Account Number"} subtext={accNumber} />
                    <SubtitleAndSubtext subtitle={"Bank"} subtext={bankName} />
                    <SubtitleAndSubtext
                      style={{ width: "auto" }}
                      subtitle={"Account Name"}
                      subtext={accName}
                    />
                  </Details>
                ) : (
                  <>
                    <p
                      style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        marginTop: "-20px"
                      }}>
                      <em>No Bank Account Attached Yet</em>
                    </p>
                    <BankSelectField
                      headerName={headerName}
                      setShowBankModal={setShowBankModal}
                      owner={owner}
                      itemId={itemId}
                    />
                  </>
                )}
                {assignedBank && attachedBankDetails[0] ? (
                  <Button onClick={() => setOpen(!open)} variant="contained">
                    Change
                  </Button>
                ) : (
                  ""
                )}
              </div>
            )}

            {open && (
              <>
                {!showForm && (
                  <>
                    <p>
                      <b>Select From {headerName} Owner's List</b>
                    </p>
                    <BankSelectField
                      headerName={headerName}
                      setShowBankModal={setShowBankModal}
                      owner={owner}
                      itemId={itemId}
                    />
                    <div style={{ margin: "10px auto", textAlign: "center" }}>
                      <p>
                        <b>Or</b>
                      </p>
                      <p
                        onClick={() => setShowForm(!showForm)}
                        style={{
                          color: "crimson",
                          fontWeight: "bold",
                          textDecoration: "underline",
                          cursor: "pointer"
                        }}>
                        Click to fill in Bank details
                      </p>
                    </div>
                  </>
                )}
                {showForm && (
                  <>
                    <BankAccountForm
                      accountName={accountName}
                      setAccountName={setAccountName}
                      bankDetails={bankDetails}
                      setBankDetails={setBankDetails}
                      handleSubmit={handleSubmit}
                    />
                    <Stack direction="row" spacing={2}>
                      <Button
                        style={{ width: "100%", maxWidth: 100, marginTop: 10 }}
                        variant="outlined"
                        onClick={handleShowBankModal}>
                        Cancel
                      </Button>
                      <Button
                        onClick={handleSubmit}
                        disabled={
                          !bankDetails.accountNumber.validation ||
                          accountName === false ||
                          !bankDetails.bank.value ||
                          !bankDetails.accountNumber.value ||
                          loadingBankSubmit
                        }
                        // className={classes.btn_save}
                        variant="contained"
                        color="primary"
                        style={{ width: "100%", maxWidth: 250, marginTop: 10 }}>
                        {loadingBankSubmit ? (
                          <CircularProgress size={20} />
                        ) : (
                          "Add bank account"
                        )}
                      </Button>
                    </Stack>
                  </>
                )}
              </>
            )}
          </>
        </CardContainer>
      </div>
    </Modal>
  );
};

export default BankAccountModal;
