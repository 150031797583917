import React, { memo } from "react";
import { CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import MultipleImagesUploader from "#/components/multipleImagesUploader/multipleImagesUploader.component";

import { usePropertyFormStyles } from "./propertyForm.styles";

import AddressDropdown from "./subComponents/addressDropdown/addressDropdown";
import ArrowDropdown from "../customDropdowns/arrowDropdown/arrowDropdown";

const PropertyForm = ({
  propertyId,
  handleSetCoverImage,
  propertyCoverImagePreview,
  propertyCoverImage,
  propertyName,
  handleSetPropertyName,
  addressDropdownHelpers,
  multipleImagesHelpers,
  propertyTypeHelpers,
  creatingProperty,
  handleSubmitProperty,
  errors,
  isForEdit = false,
  previouslyUploadedImages,
  handleRemovePreviouslyUploadedImage,
  styles = {},
  createPropertyAs,
  createPropertyFor,
  companyCreatedFor
}) => {
  const classes = usePropertyFormStyles();
  const role = useSelector((state) => state.auth.user.roles);

  const {
    dropdownOptions: ptDo,
    selectedOption: propertyType,
    handleSetSelectedOption: ptHsso
  } = propertyTypeHelpers;

  const {
    dropdownOptions: cpDO,
    selectedOption: cpSO,
    handleSetSelectedOption: cpHsso
  } = createPropertyAs;

  const {
    dropdownOptions: cFO,
    selectedOption: cFSPO,
    handleSetSelectedOption: CFHSO
  } = createPropertyFor;

  const {
    dropdownOptions: cFOc,
    selectedOption: cFSPOc,
    handleSetSelectedOption: CFHSOc
  } = companyCreatedFor;

  const renderCreatedBy = () => {
    const isLandlordOrAgent = role.includes("Landlord") || role.includes("Agent");
    const isPersonalProperty = createPropertyFor.selectedOption.mainValue === "personal";

    if (isPersonalProperty && isLandlordOrAgent) {
      return (
        <ArrowDropdown
          name="createdBy"
          label="Create property as"
          placeholder="Select Type"
          options={cpDO}
          selectedOption={cpSO}
          handleSetSelectedOption={cpHsso}
          styles={{ marginBottom: "30px" }}
          error={errors.createPropertyAs}
        />
      );
    }

    return null;
  };

  return (
    <div className={classes.formFields} style={styles}>
      <section className="first-section">
        <h3 className="first-section__title" style={{ fontWeight: "800" }}>
          {isForEdit ? "Edit Property" : "Create Property"}
        </h3>

        {/* Cover image */}
        <div className={classes.imageContainer}>
          <div
            className="image"
            style={{ backgroundImage: `url(${propertyCoverImagePreview})` }}>
            <input
              name="propertyCoverImage"
              type="file"
              accept=".jpg, .jpeg, .png"
              onChange={handleSetCoverImage}
            />
          </div>
          {isForEdit ? (
            <p>Edit cover image</p>
          ) : (
            <p>{propertyCoverImage ? "Edit cover image" : "Add cover image"}</p>
          )}
        </div>

        {/* Property ID */}
        <div className={classes.idContainer}>
          <h3>
            Property ID: <span>{propertyId}</span>
          </h3>
        </div>
        {errors.propertyCoverImage && (
          <div className={classes.errorMessage}>Field is required</div>
        )}
      </section>

      <section className="second-section">
        {/* Property Name */}
        <div className={classes.inputField}>
          <label htmlFor="propertyName">Property Name</label>
          <input
            name="propertyName"
            type="text"
            placeholder="Enter a name"
            onChange={handleSetPropertyName}
            value={propertyName}
          />
          {errors.propertyName && (
            <div className={classes.errorMessage}>Field is required</div>
          )}
        </div>

        {/* Property Address */}
        <AddressDropdown
          name="address"
          label="Address"
          placeholder="Enter a location"
          err={errors.propertyAddress}
          addressDropdownHelpers={addressDropdownHelpers}
        />

        {/* Property Type */}
        <ArrowDropdown
          name="propertyType"
          label="Property Type"
          placeholder="Select Type"
          options={ptDo}
          selectedOption={propertyType}
          handleSetSelectedOption={ptHsso}
          error={errors.propertyType}
          styles={{ marginBottom: "30px" }}
        />

        {/* Property Images */}
        <div style={{ marginBottom: "30px" }}>
          <MultipleImagesUploader
            multipleImagesHelpers={multipleImagesHelpers}
            previouslyUploadedImages={previouslyUploadedImages}
            isForEdit={true}
            handleRemovePreviouslyUploadedImage={handleRemovePreviouslyUploadedImage}
          />
        </div>

        <ArrowDropdown
          name="createdFor"
          label="Create property For"
          placeholder="Select"
          options={cFO}
          selectedOption={cFSPO}
          handleSetSelectedOption={CFHSO}
          styles={{ marginBottom: "30px" }}
          error={errors.createPropertyFor}
        />

        {createPropertyFor.selectedOption.mainValue === "company" && (
          <ArrowDropdown
            name="companyId"
            label="Company"
            placeholder="Select Company"
            options={cFOc}
            selectedOption={cFSPOc}
            handleSetSelectedOption={CFHSOc}
            styles={{ marginBottom: "30px" }}
            error={errors.companyCreatedFor}
          />
        )}

        {renderCreatedBy()}
      </section>
      <div className={classes.buttonContainer}>
        {isForEdit ? (
          <button
            type="button"
            onClick={(e) => handleSubmitProperty(e, true, propertyId)}
            className={classes.btnBlue}>
            {creatingProperty ? <CircularProgress size={16} color="inherit" /> : "Update"}
          </button>
        ) : (
          <button
            type="button"
            onClick={handleSubmitProperty}
            className={classes.btnBlue}>
            {creatingProperty ? <CircularProgress size={16} color="inherit" /> : "Next"}
          </button>
        )}
      </div>
    </div>
  );
};

PropertyForm.propTypes = {
  propertyId: PropTypes.string,
  handleSetCoverImage: PropTypes.func,
  propertyCoverImagePreview: PropTypes.string,
  propertyName: PropTypes.string,
  handleSetPropertyName: PropTypes.func,
  creatingProperty: PropTypes.bool,
  handleSubmitProperty: PropTypes.func,
  handleUpdateProperty: PropTypes.func,
  errors: PropTypes.shape({
    propertyCoverImage: PropTypes.bool,
    propertyName: PropTypes.bool,
    propertyAddress: PropTypes.bool,
    propertyType: PropTypes.bool
  }),
  propertyTypeHelpers: PropTypes.any,
  addressDropdownHelpers: PropTypes.any,
  propertyCoverImage: PropTypes.any
};

export default memo(PropertyForm);
