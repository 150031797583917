import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 30,
    },
    title: {
        textTransform: 'uppercase',
        color: '#043B5C',
        fontSize: 28,
        [theme.breakpoints.down('640')]: {
            fontSize: 18,
        },
    },
    link: {
        '& a': {
            textDecoration: 'none',
            padding: '8px 20px',
            borderRadius: 5,
            fontSize: 13,
            [theme.breakpoints.down('640')]: {
                fontSize: 10,
            },
        },
        '& a:nth-child(1)': {
            border: '1px solid #4786FF',
            color: '#4786FF',
            backgroundColor: '#fff',
            marginRight: 17,
        },
        '& a:nth-child(2)': {
            color: '#F4F5F7',
            border: 0,
            backgroundColor: '#4786FF',
        },
    }
});

const Header = (props) => (
    <header className={props.classes.header}>
        <div>
            <Typography className={props.classes.title} variant="h5" component="h4">
                <b>{props.title}</b>
            </Typography>
        </div>
        <div className={props.classes.link}>
            <a href="www.google.com">{props.export}</a>
            <a href="www.google.com">{props.newIncome} </a>
        </div>
    </header>
)

export default withStyles(styles)(Header);
