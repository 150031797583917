import * as React from "react";

function SvgTeam(props) {
  return (
    <svg width={33.905} height={28.761} {...props}>
      <defs>
        <style>{".team_svg__a{fill:#043b5c}"}</style>
      </defs>
      <path
        className="team_svg__a"
        d="M29.011 15.528h-2.622a6.821 6.821 0 01.413 2.344v9.91a2.921 2.921 0 01-.168.979h4.335a2.94 2.94 0 002.937-2.937v-5.4a4.9 4.9 0 00-4.895-4.896zM7.1 17.872a6.82 6.82 0 01.413-2.344H4.894A4.9 4.9 0 000 20.422v5.4a2.94 2.94 0 002.937 2.937h4.335a2.923 2.923 0 01-.168-.979zM19.95 12.978h-5.994a4.9 4.9 0 00-4.894 4.894v9.91a.979.979 0 00.979.979h13.826a.979.979 0 00.979-.979v-9.91a4.9 4.9 0 00-4.896-4.894zM16.953 0a5.885 5.885 0 105.886 5.886A5.893 5.893 0 0016.953 0zM6.617 5.486a4.4 4.4 0 104.4 4.4 4.407 4.407 0 00-4.4-4.4zM27.289 5.486a4.4 4.4 0 104.4 4.4 4.407 4.407 0 00-4.4-4.4z"
      />
    </svg>
  );
}

export default SvgTeam;
