import CustomMuiBtn from "#/association/components/CustomMUIBtn";
import SubHeadingUnderline from "#/association/components/SubHeadingUnderline";
import CustomInputAndLabelField from "#/components/customInputFields/CustomInputAndLabelField";
import { DeleteOutlineOutlined } from "@material-ui/icons";
import { Box } from "@mui/material";
import React from "react";
import SingleAccordion from "../components/accordion/SingleAccordion";

const ManageRoles = () => {
  return (
    <>
      <Box padding={"0 8%"}>
        <SubHeadingUnderline text={"Add Role"} />
        <Box textAlign={"center"} width="250px" margin={"30px auto"}>
          <CustomInputAndLabelField label={"Role Tile"} placeholder="e.g Chairman" />
          <CustomMuiBtn>Add Role</CustomMuiBtn>
        </Box>

        <Box>
          <SubHeadingUnderline
            text={"Roles And Permissions"}
            icon={<DeleteOutlineOutlined />}
          />
          <Box marginTop={"20px"}>
            <SingleAccordion />
            <SingleAccordion />
            <SingleAccordion />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ManageRoles;
