import React from "react";
import { usePropertyDetailsStyles } from "../propertyDetails.style";

import locationIcon from "#/assets/listingPage/location.svg";
import bedIcon from "#/assets/listingPage/bed.svg";
import toiletIcon from "#/assets/listingPage/bath.svg";

const ListingDetails = () => {
  const classes = usePropertyDetailsStyles();

  return (
    <div className={classes.details}>
      <h1>₦20,000.00</h1>
      <h4>Chevron Drive Estate</h4>
      <div className={classes.location}>
        <img src={locationIcon} width="20px"></img>
        <p>132 Chevron Drive, New York Nigeria</p>
      </div>
      <div className={classes.features}>
        <div className={classes.feature}>
          <h5 className="facility">Bedrooms</h5>
          <div className="facility-count">
            <h4>4</h4>
            <div className="img-wrapper">
              <img src={bedIcon} alt="bed" width="31px" height="31px" />
            </div>
          </div>
        </div>
        <div className={classes.feature}>
          <h5 className="facility">Bathrooms</h5>
          <div className="facility-count">
            <h4>2</h4>
            <div className="img-wrapper">
              <img src={toiletIcon} alt="bed" width="31px" height="31px" />
            </div>
          </div>
        </div>
      </div>
      <button className={classes.btnMore}>more</button>
      <div className={classes.description}>
        <h3>Description</h3>
        <p>
          I was a part of the team that was entrusted to find the problem areas and redesign the experience for the users of the most popular flagship
          product from Taazaa Inc
        </p>
      </div>
      <button className={classes.button}>Edit</button>
    </div>
  );
};

export default ListingDetails;
