import React from "react";
// import PaperContainer from "../components/paperContainer/Index";
// import FlexBetween from "#/components/flexboxes/FlexBetween";
import { styled } from "@mui/material";
import MonthlyRentBlock from "../components/monthlyrent/MonthlyRentBlock";
import SubHeadingUnderline from "#/association/components/SubHeadingUnderline";
import { useSelector } from "react-redux";
import ShowCalculator from "#/pages/Dashboard/components/dashboard-showcase/components/monthlyRateModal/ShowCalculator";

const GeneralUserDashboard = ({ view }) => {
  const monthlyRentDetail = useSelector((store) => store.tenant.monthlyRent);

  return (
    <Container>
      <ShowCalculator isTenant={false} />
      <div>
        <SubHeadingUnderline text="Monthly Rent" />
        <MonthlyRentBlock
          view={view}
          details={monthlyRentDetail[0]}
          monthlyRentDetails={monthlyRentDetail}
        />
      </div>
    </Container>
  );
};

export default GeneralUserDashboard;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 2.8rem;
  @media (max-width: 420px) {
    padding: 0;
  }
`;

// const CustomFlexBetween = styled(FlexBetween)`
//   @media (max-width: 750px) {
//     flex-direction: column;
//     justify-content: space-between;
//     align-items: center;
//     gap: 1rem;
//   }
// `;

// const VerifyTenantInfoBlock = styled(PaperContainer)`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
//   max-width: 500px;
//   width: 100%;
//   padding: 1.6rem 0;
//   p {
//     text-align: center;
//     font-size: 0.8rem;
//     font-weight: bold;
//     /* max-width: 100px; */
//     width: 80%;
//     word-wrap: break-word;
//   }
// `;

// const Icon = styled("a")`
//   cursor: pointer;
// `;
