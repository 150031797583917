import { makeStyles } from "@material-ui/core/styles";

export const referralSectionStyles = makeStyles((theme) => ({
  root: {},

  card: {
    height: "475px",
    [theme.breakpoints.down("sm")]: {
      // marginBottom: "30px",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "100%",

    },
  },
  refBox: {
    marginTop: "70px",
  },

  cardhead: {
    padding: "20px 15px 20px 20px",
    marginTop: "0px",
    backgroundColor: "#E8F0F9",
    opacity: "1",

    "& span": {
      fontSize: "20px",
      fontWeight: "bold",
      color: "#043B5C",
    },
  },

  cardtext: {
    fontSize: "15px",
    textAlign: "left",
    color: "#043B5C",
    lineHeight: "1.5",
    marginBottom: "30px",
    fontWeight: "300",
    opacity: "0.9",
  },
  refbalance: {
    width: "275px",
    boxShadow: "0px 3px 6px #F4F5F7",
    borderWidth: "2px",
    borderColor: "#F4F5F7",
    marginBottom: " 10px",

    "& p": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#043B5C",
    },
  },

  svgRoot: {
    color: "#C8222C",
  },

  balancebox: {
    display: "flex",
  },

  balanceVal: {
    fontSize: "20px !important",
    fontWeight: "bold",
    color: "#043B5C",
    paddingTop: "7px",
    paddingLeft: "10px",
  },

  refLink: {
    fontSize: "13px",
    color: "#043B5C",
    marginBottom: "5px",
    marginTop: "20px",
  },

  Linkbox: {
    // width: "250px",
  },

  root: {
    backgroundColor: "#E8F0F9",
    width: "350px",
    [theme.breakpoints.down("md")]: {
      width: "300px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "400px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "190px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: "300px",
    },

    "& input": {
      height: "15px",
      color: "rgba(4, 59, 92, 0.8)",
    },

    "& .MuiOutlinedInput-root": {
      borderTopLeftRadius: "7px",
      borderBottomLeftRadius: "7px",
      borderTopRightRadius: "0px",
      borderBottomRightRadius: "0px",
      borderRight: "none",
    },

    "& fieldset": {
      borderRight: "none",

      "& legend": {
        lineHeight: "12px",
      },
    },
  },

  btn: {
    padding: "10px 40px 15px 40px",
    [theme.breakpoints.down("xs")]: {
      width: "30px",
    },
    [theme.breakpoints.down("md")]: {
      width: "30px",
    },

    fontSize: "15px",
    textTransform: "lowercase",
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    borderTopRightRadius: "7px",
    borderBottomRightRadius: "7px",
    borderLeft: "0px",
    borderColor: "#4786FF",
    borderWidth: "1px",
    color: "#F4F5F7",
    backgroundColor: "#4786FF",

    "&:hover": {
      color: "#F4F5F7",
      backgroundColor: "#4786FF",
    },

    "&:focus": {
      outline: "none",
    },

    "& .MuiButton-label": {
      "&:hover": {},
    },

    "& .MuiButton-root:hover ": {},
  },
}));
