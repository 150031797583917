import { makeStyles } from "@material-ui/core/styles";

export const useApartDetailsDropdownStyles = makeStyles((theme) => ({
    inputField: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginBottom: "30px",
        minWidth: "260px",

        "& label": {
            marginBottom: "10px",
            fontSize: "14px",
            fontWeight: "700",
            whiteSpace: "nowrap"
        },

        "& .input-wrapper": {
            width: "100%",
            height: "100%",
            position: "relative",

            "&__input": {
                borderRadius: "5px",
                border: "1px solid #043B5C69",
                padding: "10px 50px 10px 25px",
                width: "100%",
                backgroundColor: "#fff",
                cursor: "pointer"
            },

            "&__details": {
                borderRadius: "5px",
                border: "1px solid #043B5C69",
                width: "100%",
                backgroundColor: "#fff",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                minHeight: "46px",

                "& span": {
                    marginRight: "5px"
                }
            },

            "&__menu": {
                position: "absolute",
                width: "20px",
                height: "2px",
                backgroundColor: "#043B5C",
                alignSelf: "center",
                right: "22px",
                cursor: "pointer",
                top: 23,
                transform: "translateY(-50%)",

                "&__click-box": {
                    width: "20px",
                    height: "20px",
                    display: "inline-block",
                    position: "absolute",
                    transform: "translateY(-9px)"
                },

                "&:before": {
                    content: '""',
                    width: "20px",
                    height: "2px",
                    backgroundColor: "inherit",
                    position: "absolute",
                    top: "-6px"
                },

                "&:after": {
                    content: '""',
                    width: "20px",
                    height: "2px",
                    backgroundColor: "inherit",
                    position: "absolute",
                    top: "6px"
                }
            }
        }
    },
    apartmentDetailsDropdown: {
        backgroundColor: "#F4F5F7",
        zIndex: 10,
        width: "100%",
        padding: (showDropDown) => (showDropDown === true ? "20px 20px 10px" : "0"),
        height: (showDropDown) => (showDropDown === true ? "auto" : "0"),
        overflow: (showDropDown) => (showDropDown === true ? "visible" : "hidden"),

        "& .section": {
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "5%",
            marginBottom: "15px",

            [theme.breakpoints.down("sm")]: {
                display: "block"
            },

            "&__title": {
                display: "inline-block",
                marginBottom: "10px"
            },

            "&__input": {
                width: "100%",
                border: "none",
                borderRadius: "5px",
                fontSize: "15px",
                padding: "10px"
            }
        },

        "& ul": {
            listStyle: "none",
            paddingLeft: "0px",

            "& .checkbox": {
                marginBottom: "5px",

                "&__label": {
                    fontWeight: 400
                },
                "&__input": {
                    marginRight: "10px"
                }
            }
        }
    },
    errorMessage: {
        color: "red",
        fontSize: "14px",
        marginTop: "5px"
    }
}));
