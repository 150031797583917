import React, { useState } from "react";
import CustomButton from "#/components/ui-components/button.component";
import { makeStyles } from "@material-ui/core/styles";
import CustomInputField from "#/components/ui-components/customInput.component";
import { updateProfileInformation } from "#/store/actions/auth.actions";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { getIzyPayBalance } from "#/store/actions/izypay.actions";

const ActivateBVN = ({ handleClose }) => {
    const Styles = makeStyles((theme) => ({
        closeBtn: {
            padding: "11px 12%",
            backgroundColor: "inherit !important",
            border: "1px solid #043B5C91",
            borderRadius: "10px",
            color: "#043B5C",
            fontWeight: "bold",
            fontSize: 10,
            "& .MuiButton-label": {
                fontWeight: "bold"
            },
            [theme.breakpoints.down("md")]: {
                padding: "11px 10%",
                fontSize: 10
            }
        },

        activateBtn: {
            padding: "11px 13%",
            width: "53%",
            marginLeft: "5%",
            background: "#4786FF 0% 0% no-repeat padding-box !important",
            borderRadius: "10px",
            opacity: 1,
            color: "#F4F5F7",
            float: "right",
            cursor: "pointer !important",
            position: "relative",
            fontSize: 12,
            // marginTop: '-17%',
            border: "none",
            fontWeight: "bold",
            "& .MuiButton-label": {
                fontWeight: "bold"
            },
            [theme.breakpoints.down("xs")]: {
                padding: "11px 22%"
            },
            [theme.breakpoints.down("md")]: {
                padding: "11px 12%"
            },
            [theme.breakpoints.down("sm")]: {
                padding: "11px 15%"
            }
            // marginLeft:'7%'
        }
    }));

    const { register, handleSubmit, errors, setValue } = useForm({ mode: "onBlur" });
    const dispatch = useDispatch();
    const { updatingInfo } = useSelector((state) => state.auth);

    const classes = Styles();

    const onFormSubmit = (val) => {
        dispatch(
            updateProfileInformation(val, () => {
                handleClose && handleClose();
                dispatch(getIzyPayBalance());
            })
        );
    };

    const validate = (value) => {
        if (value.length !== 11) return "Please enter a 11 digits";
    };

    return (
        <>
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <CustomInputField
                    name="bvn"
                    placeholder="Enter Your BVN"
                    type="number"
                    boxClasses={classes.inputLabel}
                    errors={errors.bvn && errors.bvn.message}
                    customRef={register({
                        required: "This field is required",
                        validate: validate
                    })}
                />
                <div className="">
                    <CustomButton
                        variant="outlined"
                        className={classes.closeBtn}
                        onClick={handleClose}
                        buttonText="CLOSE"
                    />
                    <CustomButton
                        variant="outlined"
                        isLoading={updatingInfo}
                        className={classes.activateBtn}
                        buttonText="SUBMIT"
                    />
                </div>
            </form>
        </>
    );
};

export default ActivateBVN;
