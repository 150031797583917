import { styled } from "@mui/material";
import React from "react";
import { SkeletonTypography } from "#/shared/components";

const Container = styled("div")`
  font-weight: 700;
  overflow-wrap: break-word;
`;

const Subtitle = styled("p")`
  color: #052137b2;
  font-size: 1rem;
`;

const Subtext = styled("p")`
  color: #1f364e;
  font-size: 0.75rem;
`;

const SubtitleSubtext = ({ loading, subtitle, subtext, style }) => {
  return (
    <>
      <Container style={style}>
        <Subtitle>{subtitle}</Subtitle>
        <SkeletonTypography isLoading={loading}>
          <Subtext>{subtext && subtext !== "₦NaN" ? subtext : <em>Nil</em>}</Subtext>
        </SkeletonTypography>
      </Container>
    </>
  );
};

export default SubtitleSubtext;
