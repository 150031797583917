import { Typography } from "@material-ui/core";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  styled,
  TextField
} from "@mui/material";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import React, { useEffect, useState } from "react";
import { toCurrency } from "#/components/utils/util";
import WalletCreditCard from "#/pages/Dashboard/components/WalletCreditCard";
import { useSelector } from "react-redux";
import { renewTenantRent } from "#/store/actions/payments.action";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LoadingModal from "#/components/loadingModal/LoadingModal";

const PaymentCard = styled(Paper)(({ theme }) => ({
  //   ...theme.typography.body2,
  textAlign: "center",
  //   color: theme.palette.text.secondary,
  //   height: 60
  //   lineHeight: "60px"
  padding: "20px",
  maxWidth: "600px",
  borderRadius: "12px"
  //   fontFamily: "Segoe UI"
}));

const StyledInput = styled("input")({
  color: "#000",
  padding: "2px",
  borderBottom: "2px solid #10609F",
  maxWidth: "120px",
  "&:focus": {
    outline: "none",
    borderBottom: "2px solid #36f"
  }
});

const PayRent = () => {
  const rentDetails = JSON.parse(localStorage.getItem("RENT_DETAILS"));
  const dispatch = useDispatch();
  const history = useHistory();
  const [months, setMonths] = useState("");
  const [monthsError, setMonthsError] = useState("");
  const [rentAmount, setRentAmount] = useState(0);
  //   const [enterAmountCheck, setEnterAmountCheck] = useState(false);
  const [payWithIzyinvest, setPayWithIzyinvest] = useState(false);
  //   const [amountInput, setAmountInput] = useState("");
  const izyPayBalance = useSelector((state) => state.izypay);
  const paying = useSelector((state) => state.payments.paying);

  const {
    balance: { balance, virtual_account },
    loading
  } = izyPayBalance;

  //   const handleAmountInput = (event) => {
  //     setAmountInput(event.target.value);
  //     setRentAmount(event.target.value);
  //   };

  //   const handleEnterAmountCheck = (event) => {
  //     if (event.target.checked) {
  //       setMonths("");
  //       setRentAmount(0);
  //     }
  //     setEnterAmountCheck(event.target.checked);
  //   };

  const handlePayWithIzyinvest = (event) => {
    setPayWithIzyinvest(event.target.checked);
  };

  const handleMonthChange = (event) => {
    if (event.target.value) {
      setMonthsError("");
    } else {
      setMonthsError("Select months.");
    }
    setRentAmount((event.target.value * rentDetails.price) / 12); // calculate the rent to be paid according to months selected
    setMonths(event.target.value);
    // setAmountInput("");
  };

  const handlePaymentClick = () => {
    if (!months) {
      setMonthsError("Select months.");
      return;
    }
    if (payWithIzyinvest) {
      // logic to pay with izyInvest
      return;
    }
    izyPay();
  };

  const izyPay = () => {
    const rentPayload = {
      months: Number(months),
      apartment_id: rentDetails.apartment_id
    };
    dispatch(
      renewTenantRent(rentPayload, (bool) => {
        if (bool) return history.push("/dashboard");
      })
    );
  };

  useEffect(() => {
    return () => localStorage.removeItem("RENT_DETAILS");
  }, []);
  return (
    <Box>
      <Box
        justifyContent={"center"}
        alignItems={"center"}
        display="flex"
        flexDirection={"column"}>
        <p
          style={{
            color: "#052137",
            fontSize: "20px",
            fontWeight: "bold",
            marginBottom: "15px"
          }}>
          Make Payment
        </p>
        <PaymentCard elevation={3} sx={{ width: { xs: "330px", md: "100%" } }}>
          <p style={{ fontWeight: "bold", color: "#052137CC" }}>
            Apartment ID: LAID: {rentDetails.apartment_id}{" "}
          </p>
          <Box color={"#D32F2F"}>
            <PlaceOutlinedIcon fontSize="small" color="error" />
            <small>{`${rentDetails?.house_number}, ${rentDetails?.street}, ${rentDetails?.other_address}, ${rentDetails?.lga}, ${rentDetails?.state}, ${rentDetails?.country}`}</small>
          </Box>
          <p style={{ color: "#052137CC", margin: "15px auto" }}>
            Rent Price: <b>{toCurrency(rentDetails.price)}</b>
          </p>
          <Box justifyContent={"center"} alignItems={"center"} display="flex">
            <TextField
              fullWidth
              sx={{ padding: "10px 0px", maxWidth: 150 }}
              select
              value={months}
              label="Month(s)"
              type={"number"}
              error={monthsError}
              helperText={monthsError}
              //   disabled={enterAmountCheck}
              onChange={handleMonthChange}>
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {Array.from({ length: 12 }, (_, i) => i + 1)
                .filter((item) => item <= 12)
                .map((number) => (
                  <MenuItem value={number}>{number}</MenuItem>
                ))}
            </TextField>
          </Box>
          {/* <Box
            my={2}
            justifyContent={"center"}
            alignItems={"center"}
            display="flex"
            gap={2}>
            <Checkbox
              //   sx={{ mb: -4 }}
              checked={enterAmountCheck}
              onChange={handleEnterAmountCheck}
              inputProps={{ "aria-label": "controlled" }}
            />
            <TextField
              disabled={!enterAmountCheck}
              type={"number"}
              label="Enter Amount"
              variant="standard"
              sx={{ width: 120 }}
              size="small"
              value={amountInput}
              onChange={handleAmountInput}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            />
          </Box> */}
          <small>
            Total Payment <b>{toCurrency(rentAmount)}</b>
          </small>
          {/* <Box my={2} color={"#D32F2F"}>
            <Box justifyContent={"center"} alignItems={"center"} display="flex">
              <Checkbox
                size="small"
                color="error"
                checked={payWithIzyinvest}
                onChange={handlePayWithIzyinvest}
                inputProps={{ "aria-label": "controlled" }}
              />
              <p style={{ fontSize: "14px" }}>Pay with izyInvest</p>
            </Box>
            <p>
              <b>{toCurrency(rentDetails.price)}</b>
            </p>
          </Box> */}
        </PaymentCard>

        <Box my={3}>
          <WalletCreditCard
            virtualAccount={virtual_account}
            balance={balance}
            infoDisplay
          />
        </Box>

        <Stack direction={"row"} gap={2}>
          <Button onClick={() => history.push("/dashboard")} variant="outlined">
            Back
          </Button>
          <Button
            onClick={handlePaymentClick}
            sx={{ background: "#10609f" }}
            variant="contained">
            Confirm Payment
          </Button>
        </Stack>
      </Box>
      {paying && <LoadingModal />}
    </Box>
  );
};

export default PayRent;
