import { makeStyles } from "@material-ui/core/styles";

export const useLiremReminder = makeStyles((theme) => {
    return {
        wrapper: {
            minHeight: "60vh",
            backgroundColor: "#fff",

            [theme.breakpoints.between("0", "768")]: {
                backgroundColor: "transparent"
            },

            "& .back-comp": {
                display: "none",
                [theme.breakpoints.up(700)]: {
                    display: "block",
                    marginBottom: 20
                }
            },

            "& .main-body": {
                backgroundColor: "#fff",

                [theme.breakpoints.between("0", "768")]: {
                    padding: "20px",
                    borderRadius: "10px",
                    marginBottom: "50px"
                },

                //----------------
                //FONT OVERRIDES
                "& h3": {
                    fontSize: 18,
                    fontWeight: 800,
                    color: "#043B5C",

                    [theme.breakpoints.between("0", "768")]: {
                        fontSize: 16
                    }
                },

                "& h4": {
                    fontSize: 16,
                    fontWeight: 800,
                    color: "#043B5C",

                    [theme.breakpoints.between("0", "768")]: {
                        fontSize: 14
                    }
                },

                "& h5": {
                    fontSize: 14,
                    fontWeight: 800,
                    color: "#043B5C",

                    [theme.breakpoints.between("0", "768")]: {
                        fontSize: 12
                    }
                },

                "& p": {
                    fontSize: 14,
                    color: "#043B5C",

                    [theme.breakpoints.between("0", "768")]: {
                        fontSize: 12
                    }
                },
                //----------------
                //----------------

                "& .header": {
                    borderBottom: "1px solid #043B5C",
                    paddingBottom: "10px",
                    marginBottom: "20px",

                    "& h4": {
                        fontFamily: "Open Sans-bold"
                    }
                },

                "& .first-section": {
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gridGap: "30px",
                    marginBottom: "40px",

                    [theme.breakpoints.between("0", "768")]: {
                        gridTemplateColumns: "1fr"
                    },

                    "&__col": {
                        "& h5": {
                            marginBottom: "20px"
                        },

                        "& p": {
                            backgroundColor: "#EEF4FB",
                            borderRadius: "5px",
                            padding: "10px 20px"
                        }
                    }
                },

                "& .second-section": {
                    marginBottom: "40px",

                    "&__title": {
                        marginBottom: "20px"
                    },

                    "&__box-wrapper": {
                        border: "1px solid #06223968",
                        padding: "15px 30px",
                        borderRadius: "5px",
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "15px",

                        "&__box": {
                            backgroundColor: "#4786FF",
                            color: "#fff",
                            borderRadius: "7px",
                            padding: "15px",
                            width: "156px",
                            flexShrink: 0,
                            marginRight: "20px",

                            "& h6": {
                                fontSize: "12px",
                                fontFamily: "Open Sans-bold"
                            },

                            "& p": {
                                fontSize: "12px",
                                lineHeight: "16px",
                                color: "#fff"
                            }
                        }
                    }
                },

                "& .third-section": {
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gridGap: "30px 20px",

                    [theme.breakpoints.between("0", "768")]: {
                        gridTemplateColumns: "1fr"
                    },

                    "&__form-field": {
                        "& label": {
                            fontSize: 14,
                            fontWeight: 800,
                            color: "#043B5C",
                            marginBottom: "10px",
                            display: "block",

                            [theme.breakpoints.between("0", "768")]: {
                                fontSize: 12
                            }
                        },

                        "& .input-icon-wrapper": {
                            width: "100%",
                            borderRadius: "5px",
                            border: "1px solid #06223968",
                            height: "40px",
                            display: "flex",
                            overflow: "hidden",

                            "&__icon": {
                                backgroundColor: "#EEF4FB",
                                width: "40px",
                                flexShrink: "0px"
                            },

                            "& input": {
                                border: "0px",
                                outline: "none",
                                flex: "1",
                                paddingLeft: "15px",
                                fontSize: "14px",
                                color: "#043B5C"
                            }
                        }
                    }
                }
            }
        },

        liremHeader: {
            backgroundColor: "#EEF4FB",
            borderRadius: "10px",
            height: "200px",
            display: "flex",
            alignItems: "center",
            paddingLeft: "80px",
            marginBottom: "30px",

            [theme.breakpoints.between("0", "768")]: {
                paddingLeft: "0px",
                textAlign: "center",
                justifyContent: "center"
            }
        }
    };
});
