import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Modal from "#/components/modal/modal.component";
import CustomButton from "#/components/ui-components/button.component";
import { useStyles } from "./payForApartment.styles";
import clsx from "clsx";

const PayForApartment = ({ handleSetModalVisibility, apartmentDetails, apartmentCharges }) => {
    const classes = useStyles();
    const muiClasses = useMuiStyles();
    const history = useHistory();

    const buttonClassname = clsx({
        [classes.buttonSuccess]: true
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        localStorage.setItem("PAY_RENT_AND_CHARGES", JSON.stringify(paymentDetails));
        history.push("/payments");
    };

    const totalPrice = (() => {
        const total = apartmentCharges.reduce((total, a) => {
            return total + parseFloat(a.amount);
        }, 0);

        return Number(total + apartmentDetails.price);
    })();

    const paymentDetails = {
        apartment_id: apartmentDetails._id,
        items: [
            {
                title: "Rent",
                price: apartmentDetails.price
            },
            ...apartmentCharges.map((item) => {
                return { title: item.name, price: item.amount };
            })
        ]
    };

    return (
        <form className={classes.wrapper} noValidate>
            <Modal
                isModalOpen={true}
                handleCloseModal={handleSetModalVisibility}
                hasHeader={false}
                style={{ padding: "30px", borderRadius: "10px" }}>
                <h2 className="title">Select Payment</h2>

                <section className={classes.paymentList}>
                    {paymentDetails.items.map((item) => {
                        return (
                            <div className="payment" key={item.title}>
                                <section>
                                    <input type="checkbox" checked />
                                    <p>{item.title}</p>
                                </section>
                                <p>&#8358; {item.price}</p>
                            </div>
                        );
                    })}
                </section>

                <section className={classes.paymentTotal}>
                    TOTAL: <span style={{ color: "#45B29D" }}>&#8358; {totalPrice}</span>
                </section>
                <div className="btn-wrapper">
                    <CustomButton
                        type="button"
                        buttonText="Close"
                        onClick={() => handleSetModalVisibility(false)}
                        className={clsx(classes.btnBlue, classes.btnOutlined)}
                    />
                    <div className={muiClasses.wrapper}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={buttonClassname}
                            fullWidth
                            onClick={handleSubmit}>
                            Pay
                        </Button>
                    </div>
                </div>
            </Modal>
        </form>
    );
};

export default PayForApartment;

const useMuiStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center"
    },
    wrapper: {
        position: "relative"
    },
    buttonSuccess: {
        "&:hover": {
            backgroundColor: "darkblue"
        }
    },
    buttonProgress: {
        color: "#3f51b5",
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
}));
