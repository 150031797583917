import { currentTenancyInfo, employmentStatusModel, meansOfIdModel } from "./formModel";

const {
    formField: {
        employmentStatus,
        nameOfEmployer,
        officeAddress,
        monthlyIncome,
        nameOfOfficeContactPerson,
        officeContactPersonNumber
    }
} = employmentStatusModel;

export function employmentStatusInitialValues(otherInformation) {
    const initialValues = {
        [employmentStatus.name]: otherInformation?.employment_status || "",
        [nameOfEmployer.name]: otherInformation?.employer_name || "",
        [officeAddress.name]: otherInformation?.office_address || "",
        [monthlyIncome.name]: otherInformation?.monthly_income || "",
        [nameOfOfficeContactPerson.name]: otherInformation?.office_contact_person_name || "",
        [officeContactPersonNumber.name]: otherInformation?.office_contact_person_phone_number || ""
    };

    return initialValues;
}

const {
    formField: {
        currentHomeAddress,
        currentRentValue,
        nameOfManager,
        contactOfManager,
        nameOfLandlord,
        contactOfLandlord
    }
} = currentTenancyInfo;

export function currentTenancyInitialValues(otherInformation) {
    const initialValues = {
        [currentHomeAddress.name]: otherInformation?.current_home_address || "",
        [currentRentValue.name]: otherInformation?.current_rent_value || "",
        [nameOfManager.name]: otherInformation?.manager_name || "",
        [contactOfManager.name]: otherInformation?.manager_contact || "",
        [nameOfLandlord.name]: otherInformation?.landlord_name || "",
        [contactOfLandlord.name]: otherInformation?.landlord_contact || ""
    };

    return initialValues;
}

const {
    formField: { meansOfId, meansOfIdFile, nameOfNextOfKin, contactOfNextOfKin }
} = meansOfIdModel;

export function meansOfIdInitialValues(otherInformation) {
    const initialValues = {
        [meansOfId.name]: otherInformation?.means_of_id || "",
        [meansOfIdFile.name]: otherInformation?.means_of_id_file || null,
        [nameOfNextOfKin.name]: otherInformation?.name_of_next_of_kin || "",
        [contactOfNextOfKin.name]: otherInformation?.contact_of_next_kin || ""
    };

    return initialValues;
}
