import React, { useState } from "react";
import { roleStyles, CustomRadio } from "./styles";
import Button from "#/components/ui-components/button.component";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { assignRole } from "#/store/actions/role.actions";

const ManageRole = () => {
  //State
  const [selectedoption, setSelectedoption] = useState("");

  //Event handler
  const handleChange = (event) => {
    setSelectedoption(event.target.value);
  };

  //Redux
  const roles = useSelector((state) => state.roles);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  //Hook
  const history = useHistory();

  //submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedoption === "") return;
    const formData = {
      role: selectedoption
    };
    dispatch(
      assignRole(formData, () => {
        history.push("/dashboard");
      })
    );
  };

  const classes = roleStyles();
  const { roles: role } = user;
  return (
    <div className={classes.root}>
      <section className={classes.header}>
        <div className="header-content">
          <h3>Manage your roles</h3>
          <p>
            This section allows you to manage the roles you play in the Liveizy community.
            A member can be a general member, Property manager, Landlord, estate member,
            estate manager, association member
          </p>
        </div>
      </section>

      <section className={classes.content}>
        <h6>
          Some of the roles are added by default of being a member of a community or
          group, while you will add others.
        </h6>
        <form onSubmit={handleSubmit}>
          <div className="content-container">
            <div className="rolesBox" style={{ backgroundColor: "#F2F8FC" }}>
              <CustomRadio value="generalUser" name="role" checked={true} disabled />
              <label>General User (default)</label>
            </div>
            <div className="rolesBox" style={{ border: "1px solid #043B5C" }}>
              <CustomRadio
                id="agent"
                value="Agent"
                name="role"
                checked={selectedoption === "Agent" || role.includes("Agent")}
                onChange={handleChange}
                disabled={role.includes("Agent")}
              />
              <label htmlFor="agent" style={{ cursor: "pointer" }}>
                Property Manager
              </label>
            </div>
            <div className="rolesBox" style={{ border: "1px solid #043B5C" }}>
              <CustomRadio
                id="landlord"
                value="Landlord"
                name="role"
                checked={selectedoption === "Landlord" || role.includes("Landlord")}
                onChange={handleChange}
                disabled={role.includes("Landlord")}
              />
              <label htmlFor="landlord" style={{ cursor: "pointer" }}>
                LandLord
              </label>
            </div>
          </div>
          <Button
            type="submit"
            buttonText="Save Changes"
            isLoading={roles.loadingRole}
            className="btn"
            style={{}}
          />
        </form>
      </section>
    </div>
  );
};

export default ManageRole;
