import { makeStyles } from "@material-ui/core/styles";

export const useAddressDropdownStyles = makeStyles((theme) => ({
    inputField: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginBottom: "30px",

        "& label": {
            marginBottom: "10px",
            fontSize: "14px",
            fontWeight: "700"
        },

        "& .input-wrapper": {
            width: "100%",
            height: "100%",
            position: "relative",

            "&__input": {
                borderRadius: "5px",
                border: "1px solid #043B5C69",
                padding: "10px 50px 10px 25px",
                width: "100%",
                backgroundColor: "#fff",
                cursor: "pointer"
            },

            "&__details": {
                borderRadius: "5px",
                border: "1px solid #043B5C69",
                width: "100%",
                backgroundColor: "#fff",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                minHeight: "46px",

                "& span": {
                    marginRight: "5px"
                }
            },

            "&__menu": {
                position: "absolute",
                width: "20px",
                height: "2px",
                backgroundColor: "#043B5C",
                alignSelf: "center",
                right: "22px",
                cursor: "pointer",
                top: 23,
                transform: "translateY(-50%)",

                "&__click-box": {
                    width: "20px",
                    height: "20px",
                    display: "inline-block",
                    position: "absolute",
                    transform: "translateY(-9px)"
                },

                "&:before": {
                    content: '""',
                    width: "20px",
                    height: "2px",
                    backgroundColor: "inherit",
                    position: "absolute",
                    top: "-6px"
                },

                "&:after": {
                    content: '""',
                    width: "20px",
                    height: "2px",
                    backgroundColor: "inherit",
                    position: "absolute",
                    top: "6px"
                }
            }
        }
    },
    addressDropdown: {
        backgroundColor: "#F4F5F7",
        zIndex: 10,
        //position: "absolute",
        width: "100%",
        padding: (showDropDown) => (showDropDown === true ? "20px 20px 10px" : "0"),
        height: (showDropDown) => (showDropDown === true ? "auto" : "0"),
        overflow: (showDropDown) => (showDropDown === true ? "visible" : "hidden"),

        "& .section": {
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "5%",

            [theme.breakpoints.down("sm")]: {
                display: "block"
            }
        },

        "& .address-input-field": {
            display: "flex",
            flexDirection: "column",
            marginBottom: "15px",

            "& label": {
                fontSize: "10px",
                marginBottom: "7px"
            },

            "& input": {
                border: "none",
                fontSize: "10px",
                padding: "7px",
                width: "100%",
                height: "30px"
            },

            "& select": {
                border: "0px",
                padding: "2px 0px",
                fontSize: "12px",
                height: "30px"
            }
        },

        "& .btn-finish": {
            backgroundColor: "#4786FF",
            border: "0px",
            fontSize: "12px",
            color: "#fff",
            padding: "3px 15px",
            borderRadius: "3px"
        }
    },
    errorMessage: {
        color: "red",
        fontSize: "14px",
        marginTop: "5px"
    }
}));
