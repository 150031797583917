import React from "react";

import { styled } from "@mui/material";
// import SubtitleAndSubtext from "../others/SubtitleAndSubtext";
// import DetailsCardContainer, { Details } from "./DetailsCardContainer";
import ProfilePicFilePreview from "../others/ProfilePicFilePreview";
import ProfilePicLinkPreview from "../others/ProfilePicLinkPreview";
import ImageRectPreviwer from "#/components/ui-components/ImageRectPreviwer";
import DetailsCardContainer, { DetailsContainer } from "#/components/cards/DetailsCardContainer.component";
import SubtitleAndSubtext from "#/components/ui-components/SubtitleAndSubtext.component";

const ProfilePx = styled("div")`
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;

const PersonalDetails = ({
    surname,
    firstName,
    otherName,
    gender,
    dateOfBirth,
    stateOfOrigin,
    lga,
    email,
    altEmail,
    mobile,
    altMobile,
    profileImage,
    bvn,
    meansOfId,
    meansOfIdFile
}) => {
    return (
        <DetailsCardContainer>
            {/* Profile pic and BVN */}
            <div className="position-center-column">
                {typeof profileImage === "string" ? (
                    <ProfilePicLinkPreview profileImage={profileImage} />
                ) : (
                    <ProfilePicFilePreview profileImage={profileImage} />
                )}
            </div>
            {/* Details */}
            <DetailsContainer>
                <SubtitleAndSubtext subtitle={"BVN"} subtext={bvn} />
                <SubtitleAndSubtext subtitle={"Surname"} subtext={surname} />
                <SubtitleAndSubtext subtitle={"Date Of Birth"} subtext={dateOfBirth} />
                <SubtitleAndSubtext subtitle={"Alternate Email Address"} subtext={altEmail} />

                <SubtitleAndSubtext subtitle={"First Name"} subtext={firstName} />
                <SubtitleAndSubtext subtitle={"State Of Origin"} subtext={stateOfOrigin} />
                <SubtitleAndSubtext subtitle={"Mobile Number"} subtext={mobile} />

                <SubtitleAndSubtext subtitle={"Other Name"} subtext={otherName} />
                <SubtitleAndSubtext subtitle={"Local Government Area"} subtext={lga} />
                <SubtitleAndSubtext subtitle={"Alternate Mobile Number"} subtext={altMobile} />

                <SubtitleAndSubtext subtitle={"Gender"} subtext={gender} />
                <SubtitleAndSubtext subtitle={"Email"} subtext={email} />
                <SubtitleAndSubtext subtitle={"Means Of ID"} subtext={meansOfId} />
            </DetailsContainer>
            <div className="position-center-column">
                <ImageRectPreviwer src={meansOfIdFile} alt={"Means of Id Upload"} />
            </div>
        </DetailsCardContainer>
    );
};

export default PersonalDetails;
