import React, { useEffect, useState } from "react";
import { Stepper, Step, StepLabel, Button, Box } from "@mui/material";
import { Formik, Form } from "formik";
import "./index.css";

// Forms Component import
import PersonalDetailsForm from "./FormsComponents/PersonalDetailsForm";
import JobDetailsForm from "./FormsComponents/JobDetailsForm";
import CurrentAddressDetailsForm from "./FormsComponents/CurrentAddressDetailsForm";
import ReviewForm from "./FormsComponents/ReviewForm";

// Formik props value imports
import validationSchema from "./FormModel/validationSchema";
import rentLoanFormModel from "./FormModel/rentLoanFormModel";
import getFormInitialValues from "./FormModel/formInitialValues";
import { submitStageOne } from "./utils";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getOtherInformation } from "#/store/actions/auth.actions";
import { propertyServiceApi } from "#/apis/propertyServiceApi";
import LoadingModal from "#/components/loadingModal/LoadingModal";
import ReferenceForm from "./FormsComponents/ReferenceForm";
import { alertError } from "#/store/actions/notification.actions";

const steps = [
  "Personal Details",
  "Job details",
  "Residential Details",
  "Next of kin & Reference",
  "Review Form"
];
const { formField } = rentLoanFormModel;

function renderStepContent(step, setActiveStep, tenantDetails) {
  switch (step) {
    case 0:
      return <PersonalDetailsForm fields={formField} />;
    case 1:
      return <JobDetailsForm fields={formField} />;
    case 2:
      return (
        <CurrentAddressDetailsForm fields={formField} tenantDetails={tenantDetails} />
      );
    case 3:
      return <ReferenceForm fields={formField} />;
    case 4:
      return <ReviewForm setActiveStep={setActiveStep} />;
    default:
      return <div>Not Found</div>;
  }
}

const RentLoanForm = () => {
  const dispatch = useDispatch();
  const monthlyRentDetail = useSelector((store) => store.tenant.monthlyRent);
  const isReview = monthlyRentDetail[0]?.monthly_rent_status_stage_1 === "review";
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep]; // this would equal the validation schema for the currentlly rendered form
  const isLastStep = activeStep === steps.length - 1;

  const { user } = useSelector((state) => state.auth);
  const [tenantDetails, setTenantDetails] = useState({
    record: null,
    landlord: null,
    manager: null
  });

  useEffect(() => {
    // if (!apartment_id || apartment_id === undefined || apartment_id === null) // use stage 1 status
    //   return history.push("/dashboard");

    const getTenantDetails = () => {
      propertyServiceApi()
        .get(`/tenant/lid/${user.liveizy_id}?status=resident&populate1=apartment_id`)
        .then((res) => {
          if (res.data.payload.length > 0) {
            setTenantDetails((prev) => ({ ...prev, record: res.data.payload[0] }));

            // get landlord and manager's details of apartment
            if (res.data.payload[0].apartment_id.owner) {
              propertyServiceApi()
                .get(`/property-owner/${res.data.payload[0].apartment_id.owner}`)
                .then((res) =>
                  setTenantDetails((prev) => ({ ...prev, landlord: res.data.payload }))
                );
            }
            if (res.data.payload[0].apartment_id.property_manager) {
              propertyServiceApi()
                .get(
                  `/property-manager/${res.data.payload[0].apartment_id.property_manager}`
                )
                .then((res) =>
                  setTenantDetails((prev) => ({ ...prev, manager: res.data.payload }))
                );
            }
          }
        })
        .catch();
    };

    dispatch(getOtherInformation());
    getTenantDetails();
  }, [dispatch, user.liveizy_id]);

  const { otherInformation } = useSelector((state) => state.auth);

  const formInitialValues = getFormInitialValues(user, otherInformation, tenantDetails);

  async function submitForm(values, actions) {
    // post form data
    await submitStageOne(values, dispatch, actions, history, isReview, user.liveizy_id);
  }

  const handleErrors = async (values, validateForm) => {
    if (isLastStep) {
      const errors = await validateForm(values);
      if (errors && Object.keys(errors).length === 0 && errors.constructor === Object)
        return;
      dispatch(alertError("Please provide the required fields"));
    }
  };

  function handleSubmit(values, actions) {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function handleBack() {
    setActiveStep(activeStep - 1);
  }
  return (
    <React.Fragment>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        sx={{ maxWidth: "700px", width: "100%", margin: "0px auto" }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box className="position-center-column" sx={{ padding: "10px 20px" }}>
        <React.Fragment>
          <Formik
            initialValues={formInitialValues}
            enableReinitialize={true}
            validationSchema={currentValidationSchema}
            onSubmit={handleSubmit}>
            {({ isSubmitting, values, validateForm }) => (
              <Form
                className="position-center-column"
                style={{ maxWidth: "900px", width: "100%", padding: "15px" }}>
                {isSubmitting && <LoadingModal />}
                <div
                  className="position-center-column"
                  style={{ maxWidth: "600px", width: "100%", margin: "10px auto" }}>
                  <h3
                    style={{ fontWeight: "bold", textAlign: "center", fontSize: "20px" }}>
                    Switch To Monthly Rent Payment
                  </h3>
                  <br />
                  <p style={{ color: "#b83b3b", textAlign: "center" }}>
                    {isLastStep
                      ? "Verify the information below before clicking the Submit button."
                      : "Ensure all information provided are accurate and true"}
                  </p>
                </div>
                {/* Render steps here */}
                {renderStepContent(activeStep, setActiveStep, tenantDetails)}

                <div style={{ margin: "30px 10px auto" }} className="position-center">
                  {activeStep !== 0 && (
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{ marginRight: "30px" }}
                      onClick={handleBack}>
                      Back
                    </Button>
                  )}
                  <div>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      onClick={() => handleErrors(values, validateForm)}
                      color="primary">
                      {isLastStep ? "Submit Form" : "Next"}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </React.Fragment>
        <div style={{ margin: "10px auto" }}>
          <img src="/assets/dashboard/vfd.svg" alt="vfd logo" />
        </div>
      </Box>
    </React.Fragment>
  );
};

export default RentLoanForm;
