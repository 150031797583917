import React from "react";
import { Button } from "@material-ui/core";

const content = {
    lipman: {
        title: "LiPMaN Plan (12% commission)",
        description: [
            <p className="pim-cbd-p">
                This plan carries all the benefit of the <b>LiReM</b> plan, and includes a repair cover benefit for all
                your properties on the plan. These are the extra benefit you stand to get on this plan:
            </p>,
            <p className="pim-cbd-p">
                <b>1.</b> A repair cost cover within rent tenure valued at 20% the rent value in the plan (that means if
                the total rent in the plan is valued at N1,000,000, the Landlord is entitled to a repair cover cost of
                N200,000)
            </p>,
            <p className="pim-cbd-p">
                <b>2.</b> Comes with a paid property manager assigned to the plan.
            </p>,
            <p className="pim-cbd-p">
                <b>3.</b> Where tenant default on rent, Landlord get paid within 48 hours of rent payment date.
            </p>,
            <p className="pim-cbd-p">
                <b>4.</b> Liveizy charges 12% of rent as fee for this plan, which is deducted at the point of rent
                payment.
            </p>
        ],
        btn1: { text: "close", link: "/property/list", color: "secondary", variant: "outlined" },
        btn2: { text: "Get Started", link: "/property/plan/lipman", color: "primary", variant: "contained" }
    },
    lirem: {
        title: "LiReM Plan (6% commission)",
        description: [
            <p className="pim-cbd-p">
                <b>1.</b> Guarantee rent payment; where tenants default, Landlord gets paid within 30 days of rent
                payment date
            </p>,
            <p className="pim-cbd-p">
                <b>2.</b> Record keeping and analysis of all rent paid
            </p>,
            <p className="pim-cbd-p">
                <b>3.</b> Free rent reminders for tenants
            </p>,
            <p className="pim-cbd-p">
                <b>4.</b> Free vacant apartment advertising and renters' screening
            </p>,
            <p className="pim-cbd-p">
                <b>5.</b> Tenancy agreement served to all tenants on the plan.
            </p>,
            <p className="pim-cbd-p">
                <b>6.</b> Tenants will have access to rent loans and rent savings.
            </p>,
            <p className="pim-cbd-p">
                <b>7.</b> Tenants must pay their rent directly to Liveizy to keep this plan active every year.
            </p>,
            <p className="pim-cbd-p">
                <b>8.</b> Any apartment where rent is not paid directly through Liveizy, will become in-active on the
                plan.
            </p>,
            <p className="pim-cbd-p">
                <b>9.</b> Where Landlord collects rent directly from tenant for any reason, apartments affected will
                become in-active on the plan.
            </p>
        ],
        btn1: { text: "close", link: "/property/list", color: "secondary", variant: "outlined" },
        btn2: { text: "Get Started", link: "/property/plan/lirem", color: "primary", variant: "contained" }
    },
    bothPlans: {
        title: "Choose a Plan",
        /*
        description: [
            <p className="pim-cbd-p"><b>How to activate plan</b></p>,
            <p className="pim-cbd-p"><b>1.</b> List all your rental properties on the Liveizy platform</p>,
            <p className="pim-cbd-p"><b>2.</b> Add tenants to apartments</p>,
            <p className="pim-cbd-p"><b>3.</b> Click create plan and select the plan of your choice</p>,
            <p className="pim-cbd-p"><b>4.</b> Select and Add apartment to the plan</p>,
            <p className="pim-cbd-p"><b>5.</b> Liveizy will inspect property to certify it for plan and Landlord will sign Agreement.</p>,
            <p className="pim-cbd-p"><b>6.</b> Once plan is certified and Plan for apartment become active once first payment is made by tenant. </p>,
            <p className="pim-cbd-p">Chat with a Liveizy Representative on <a target="_blank" href="https://wa.me/message/WEOMAM7ZDWO5E1">WhatsApp</a></p>,
        ],
        */
        btn1: { text: "LiPMaN", link: "/property/plan/lipman", color: "primary", variant: "contained" },
        btn2: { text: "LiReM", link: "/property/plan/lirem", color: "primary", variant: "contained" }
    }
};

const PlansInfoModal = ({ handleModal, currentModal = "bothPlans" }) => {
    const { title, description, btn1, btn2 } = content[currentModal];
    return (
        <div className="plans-info-modal" onClick={(e) => handleModal(false)}>
            <div className="pim-content-div" onClick={(e) => e.stopPropagation()}>
                <div className="pim-content-header-div">{title}</div>
                <div className="pim-content-bottom-div">
                    <div>{description && description.map((item) => item)}</div>
                    <div className="pim-cbd-div">
                        <Button className="pim-cbd-div-btn" variant={btn1.variant} color={btn1.color} href={btn1.link}>
                            {btn1.text}
                        </Button>
                        <Button className="pim-cbd-div-btn" variant={btn2.variant} color={btn2.color} href={btn2.link}>
                            {btn2.text}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlansInfoModal;
