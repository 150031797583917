import React, { useState, useEffect } from "react";
import avatar from "#/assets/images/tenant/default-avatar.png";
import logoavatar from "#/assets/images/logo-avatar.png";
import Button from "#/components/ui-components/button.component";
import { businessStyles } from "./businessStyle";
import CustomInputField from "#/components/ui-components/customInput.component";
import { useForm } from "react-hook-form";
import NaijaStates from "naija-state-local-government";
import { useDispatch, useSelector } from "react-redux";
import CustomArrowDropdown from "#/components/ui-components/customDropdown.component";
import useCustomDropdown from "#/hooks/ui-components-hooks/useCustomDropdown";
import {
  getBusinessInformation,
  updateBusinessInformation
} from "#/store/actions/auth.actions";
import AgentBankDetails from "./agent-bank-details";
import clsx from "clsx";
import ProfilePicAndButton from "#/components/ui-components/ProfilePicAndButton";
import TitleUnderline from "#/components/ui-components/TitleUnderline.component";
import DetailsCardContainer, {
  Details,
  DetailsContainer
} from "#/components/cards/DetailsCardContainer.component";
import SubtitleAndSubtext from "#/components/ui-components/SubtitleAndSubtext.component";
import { CircularProgress, Grid, Stack } from "@mui/material";
import formModel from "./formModel";
import { Form, Formik } from "formik";
import FormikCustomInputField from "#/components/formik/formfields/FormikCustomInputField";
import formInitialValues from "./formInitialValues";
import validationSchema from "./validationSchema";
import ProfilePicUpload from "#/components/formik/formfields/ProfilePicUpload";
import ProfilePictureWrapper from "#/components/ui-components/ProfilePictureWrapper";
import { submitForm } from "./utils";
import { propertyServiceApi } from "#/apis/propertyServiceApi";
import { showNotifications } from "#/store/actions/notification.actions";

const {
  formField: {
    businessName,
    businessPhone,
    businessRegistrationAddress,
    businessRegistrationNumber,
    companyEmail,
    lga: businessLga,
    state
  }
} = formModel;

const BusinessInformation = () => {
  //State
  const [stateError, setStateError] = useState("");
  const [lgaError, setLgaError] = useState("");

  const [lga, setChosenStateLgas] = useState([]);

  //Redux store
  const { updatingInfo, businessInformation, user } = useSelector((state) => state.auth);

  // Formik
  const initialValues = formInitialValues(businessInformation);

  //Helpers
  // const { register, handleSubmit, errors, setValue } = useForm({ mode: "onBlur" });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBusinessInformation());
  }, []);

  // //Hook for LGA Dropdown
  // const {
  //     open,
  //     selectedOption,
  //     handleDropdownChange,
  //     handleAction,
  //     setSelectedOption,
  //     handleClose,
  //     anchorRef
  // } = useCustomDropdown();

  // //Create states dropdown
  // let ALL_STATES = [];
  // NaijaStates.states().forEach((state) => {
  //     const data = {
  //         name: state,
  //         id: state
  //     };
  //     ALL_STATES.push(data);
  // });

  // //Hook for State Dropdown
  // const {
  //     open: stateopen,
  //     selectedOption: stateselectedOption,
  //     handleDropdownChange: statehandleDropdownChange,
  //     handleAction: statehandleAction,
  //     setSelectedOption: stateSetSelectedOption,
  //     handleClose: statehandleClose,
  //     anchorRef: stateanchorRef
  // } = useCustomDropdown();

  // useEffect(() => {
  //     if (stateselectedOption) {
  //         const lgasInState = NaijaStates.lgas(stateselectedOption);

  //         const lgaList = [];
  //         if (lgasInState?.lgas !== undefined) {
  //             lgasInState.lgas.forEach((lgas) => {
  //                 const data = {
  //                     name: lgas,
  //                     id: lgas
  //                 };

  //                 lgaList.push(data);
  //             });
  //         }
  //         setChosenStateLgas(lgaList);
  //     }
  // }, [stateselectedOption]);

  //Setting Forms with Data
  // useEffect(() => {
  //     if (businessInformation !== null) {
  //         setValue("business_name", businessInformation?.business_name);
  //         setValue("business_phone", businessInformation?.business_phone);
  //         setValue("business_registration_number", businessInformation?.business_registration_number);
  //         setValue("business_registration_address", businessInformation?.business_registration_address);
  //         setValue("company_email", businessInformation?.company_email);
  //         stateSetSelectedOption(businessInformation?.state);
  //         setSelectedOption(businessInformation?.lga);
  //     }
  // }, [businessInformation]);

  // //Validating Phone Number
  // const validatePhoneNo = (value) => {
  //     // let values = value.replace(/[^0-9]*/g, "");
  //     // if (value.length > 11 || value !== values) return "Should be a phone number";

  //     value = value.match(/\d/g) || []; //allow only digits, NOTE: match could return null hence the empty array second option
  //     value = value.join("");
  //     if (value.length > 11) return "Phone Number should be 11 digits";
  //     if (value.length < 11) return "Please enter a 11 digits";
  // };

  const onFormSubmit = async (values, actions) => {
    if (updatingInfo) return;
    await submitForm(values, dispatch, setEditMode);
    actions.setSubmitting(false);
  };

  // useEffect(() => {
  //     if (selectedOption) {
  //         setLgaError("");
  //     }
  //     if (stateselectedOption) {
  //         setStateError("");
  //     }
  // }, [stateselectedOption, selectedOption]);
  const [agent, setAgent] = useState(null);
  const [loadingAgentInfo, setLoadingAgentInfo] = useState(false);
  const fetchAgentInfo = () => {
    setLoadingAgentInfo(true);
    propertyServiceApi()
      .get(`/property-manager/${user.liveizy_id}`)
      .then((data) => {
        setAgent(data.data.payload);
        setLoadingAgentInfo(false);
      })
      .catch((err) => {
        setLoadingAgentInfo(false);
        dispatch(showNotifications("Error occured fetching info.", "error"));
      });
  };

  useEffect(() => fetchAgentInfo(), []);

  const classes = businessStyles();

  const [editMode, setEditMode] = useState(false);
  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  return (
    <div className={classes.root}>
      {!editMode ? (
        <>
          {/* <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <ProfilePictureWrapper
              src={businessInformation?.logo ? businessInformation?.logo : logoavatar}
              alt="Business Logo"
            />
          </div> */}

          <section>
            <TitleUnderline title={"Business Information"} />
            <DetailsCardContainer>
              <DetailsContainer>
                <SubtitleAndSubtext
                  loading={updatingInfo}
                  sm={4}
                  lg={4}
                  subtitle={"Business Name"}
                  subtext={businessInformation?.business_name}
                />
                <SubtitleAndSubtext
                  loading={updatingInfo}
                  sm={4}
                  lg={4}
                  subtitle={"Business Email"}
                  subtext={businessInformation?.company_email}
                />
                <SubtitleAndSubtext
                  loading={updatingInfo}
                  sm={4}
                  lg={4}
                  subtitle={"Business Mobile Number"}
                  subtext={businessInformation?.business_phone}
                />

                <SubtitleAndSubtext
                  loading={updatingInfo}
                  sm={4}
                  lg={4}
                  subtitle={"Business Registeration Number"}
                  subtext={businessInformation?.business_registration_number}
                />
              </DetailsContainer>
            </DetailsCardContainer>
          </section>

          <section>
            <TitleUnderline title={"Business Address Details"} />
            <DetailsCardContainer>
              <DetailsContainer>
                <SubtitleAndSubtext
                  sm={6}
                  lg={6}
                  loading={updatingInfo}
                  subtitle={"Business Registered Address"}
                  subtext={businessInformation?.business_registration_address}
                />
                <SubtitleAndSubtext
                  sm={3}
                  lg={3}
                  loading={updatingInfo}
                  subtitle={"State"}
                  subtext={businessInformation?.state}
                />
                <SubtitleAndSubtext
                  sm={3}
                  lg={3}
                  loading={updatingInfo}
                  subtitle={"LGA"}
                  subtext={businessInformation?.lga}
                />
              </DetailsContainer>
            </DetailsCardContainer>
          </section>

          <section>
            <TitleUnderline title={"Bank Account Details"} />
            <DetailsCardContainer>
              {loadingAgentInfo ? (
                <CircularProgress />
              ) : (
                <>
                  {agent && agent?.bank_accounts.length > 0 ? (
                    <>
                      <DetailsContainer>
                        {agent?.bank_accounts?.map((item, idx) => (
                          <>
                            <SubtitleAndSubtext
                              sm={4}
                              lg={4}
                              subtitle={"Account Number"}
                              subtext={item.account_number}
                            />
                            <SubtitleAndSubtext
                              sm={4}
                              lg={4}
                              subtitle={"Bank Name"}
                              subtext={item.name}
                            />
                            <SubtitleAndSubtext
                              sm={4}
                              lg={4}
                              subtitle={"Account Name"}
                              subtext={item.account_name}
                            />
                          </>
                        ))}
                      </DetailsContainer>
                    </>
                  ) : (
                    <>
                      <p style={{ margin: "0 auto" }}>No Bank Detail attached Yet</p>
                    </>
                  )}
                </>
              )}
            </DetailsCardContainer>
          </section>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginTop: "60px"
            }}>
            <Button
              type="button"
              buttonText="Edit Business Details"
              onClick={handleEditMode}
              style={{ background: "#10609F", color: "white" }}
            />
          </div>
        </>
      ) : (
        <>
          <section className={clsx(classes.formWrapper, classes.sectionSpacing)}>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={onFormSubmit}>
              {({ isSubmitting }) => (
                <Form>
                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%"
                    }}>
                    <ProfilePicUpload
                      name={logo.name}
                      alt={"Business Logo"}
                      src={
                        businessInformation?.logo ? businessInformation?.logo : logoavatar
                      }
                    />
                  </div> */}
                  <Grid container columnSpacing={4}>
                    <Grid item xs={12}>
                      <TitleUnderline title={"Business Information"} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormikCustomInputField
                        name={businessName.name}
                        label={businessName.label}
                        placeholder={businessName.placeHolder}
                        boxClasses={classes.inputLabel}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormikCustomInputField
                        name={businessPhone.name}
                        label={businessPhone.label}
                        placeholder={businessPhone.placeHolder}
                        boxClasses={classes.inputLabel}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormikCustomInputField
                        name={companyEmail.name}
                        label={companyEmail.label}
                        placeholder={companyEmail.placeHolder}
                        boxClasses={classes.inputLabel}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormikCustomInputField
                        name={businessRegistrationNumber.name}
                        label={businessRegistrationNumber.label}
                        placeholder={businessRegistrationNumber.placeHolder}
                        boxClasses={classes.inputLabel}
                      />
                    </Grid>
                  </Grid>
                  <Grid container columnSpacing={4}>
                    <Grid item xs={12}>
                      <TitleUnderline title={"Business Address Details"} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormikCustomInputField
                        name={businessRegistrationAddress.name}
                        label={businessRegistrationAddress.label}
                        placeholder={businessRegistrationAddress.placeHolder}
                        boxClasses={classes.inputLabel}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormikCustomInputField
                        name={state.name}
                        label={state.label}
                        placeholder={state.placeHolder}
                        boxClasses={classes.inputLabel}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormikCustomInputField
                        name={businessLga.name}
                        label={businessLga.label}
                        placeholder={businessLga.placeHolder}
                        boxClasses={classes.inputLabel}
                      />
                    </Grid>
                  </Grid>
                  <Stack direction={"row"} spacing={2} justifyContent="center" margin={4}>
                    <Button
                      type="submit"
                      buttonText="Cancel"
                      isLoading={updatingInfo}
                      className={classes.btn_save}
                      onClick={handleEditMode}
                      style={{ background: "white", color: "blue" }}
                    />

                    <Button
                      type="submit"
                      buttonText="Update"
                      isLoading={updatingInfo}
                      className={classes.btn_save}
                    />
                  </Stack>
                </Form>
              )}
            </Formik>
            <Grid container columnSpacing={4}>
              <Grid item xs={12}>
                <TitleUnderline title={"Bank Account Details"} />
              </Grid>
              <Grid item xs={12}>
                <AgentBankDetails />
              </Grid>
            </Grid>
          </section>
        </>
      )}
    </div>
  );
};

export default BusinessInformation;

{
  /* <CustomInputField
                                        name="company_email"
                                        label={
                                            <>
                                            Contact Email address <br />{" "}
                                            <span style={{ color: "#4786FF", fontSize: "0.9em" }}>
                                            (only company email)
                                                </span>
                                                </>
                                            }
                                            placeholder="Contact Email address"
                                            errors={errors.company_email && errors.company_email.message}
                                            boxClasses={classes.inputLabel}
                                            customRef={register({
                                                required: "This field is required"
                                        })}
                                        type="email"
                                    /> */
}

{
  /* <Grid item xs={12} sm={6}>
                                    <CustomArrowDropdown
                                    label="State of origin"
                                    placeholder="State of origin"
                                    options={ALL_STATES}
                                        handleDropdownChange={statehandleDropdownChange}
                                        handleAction={statehandleAction}
                                        selectedOption={stateselectedOption}
                                        error={stateError}
                                        boxClasses={classes.inputLabel}
                                        handleClose={statehandleClose}
                                        open={stateopen}
                                        anchorRef={stateanchorRef}
                                        cb={() => {
                                            setSelectedOption("");
                                        }}
                                        />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                    <CustomArrowDropdown
                                    label="Local government Area"
                                    placeholder="Local government Area"
                                    options={lga}
                                    handleDropdownChange={handleDropdownChange}
                                        handleAction={handleAction}
                                        selectedOption={selectedOption}
                                        error={lgaError}
                                        boxClasses={classes.inputLabel}
                                        handleClose={handleClose}
                                        open={open}
                                        anchorRef={anchorRef}
                                        />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                        <CustomInputField
                                        name="business_phone"
                                        label="Contact Number"
                                        placeholder="Contact Number"
                                        errors={errors.business_phone && errors.business_phone.message}
                                        boxClasses={classes.inputLabel}
                                        customRef={register({
                                            required: "This field is required",
                                            validate: validatePhoneNo
                                        })}
                                        type="number"
                                    />
                                </Grid> */
}
