import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import queryString from "query-string";
import { Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { loginStyles } from "../login/login.styles";
import AuthPageContainer from "../AuthPageContainer";
import Logo1 from "#/assets/images/liveizy-logo.png";

import { ErrorDiv } from "#/components/errorDiv";
import CustomButton from "#/components/ui-components/button.component";
import { CustomInput } from "#/components/ui-components/input.component";
import {
  formatNigerianPhoneNumber,
  getFromLocalStorage,
  nigerianPhoneNumberPattern,
  setToLocalStorage
} from "#/components/utils/util";

import { sendSignupRequest } from "#/store/actions/auth.actions";
import { clearValidationErrors } from "#/store/actions/notification.actions";

const RegistrationForm = (props) => {
  const classes = loginStyles();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [typing, setTyping] = useState(false);
  const messages = useSelector((state) => state.notifications.msg);
  const validationErrors = useSelector((state) => state.notifications.validationErrors);

  const { register, watch, handleSubmit, setValue, errors } = useForm({
    mode: "onBlur"
  });
  const values = watch();

  const history = useHistory();

  const handleInputChange = (e) => {
    dispatch(clearValidationErrors());

    const { name, value } = e.target;
    setValue(name, value);

    //to show the visibilty toggle on the password input
    if (name === "password" || name === "password_confirmation") {
      setTimeout(() => {
        setTyping(true);
      }, 2000);
    }
  };

  const onFormSubmit = async (vals) => {
    dispatch(clearValidationErrors());

    setSubmitting(true);

    vals.phone = formatNigerianPhoneNumber(vals.phone);

    // console.log(data);
    dispatch(
      sendSignupRequest(vals, history, () => {
        setSubmitting(false);
        localStorage.removeItem("referral_liveizy");
      })
    );
  };

  //validating password
  const validatePassword = (value) => {
    if (value !== values.password) {
      return "The passwords do not match";
    }
  };

  useEffect(() => {
    //getting the id from the url and query string
    const { liveizyId } = queryString.parse(window.location.search);
    if (liveizyId !== undefined) {
      setToLocalStorage("referral_liveizy", liveizyId);
      setValue("referral_liveizy", liveizyId);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const liveizyId = getFromLocalStorage("referral_liveizy");
    if (liveizyId !== null) {
      setValue("referral_liveizy", liveizyId);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container className={classes.formContainer}>
      <form className={classes.registrationForm} onSubmit={handleSubmit(onFormSubmit)}>
        <Grid item xs={12} className={classes.registrationLogo}>
          <div>
            <a href="https://liveizy.com/">
              <img
                src={Logo1}
                alt="logo"
                style={{
                  width: "6rem"
                }}
              />
            </a>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            marginBottom: "1.5rem"
          }}>
          <span
            style={{
              color: "#043B5C",
              fontSize: "1.2rem",
              fontWeight: "bold"
            }}>
            CREATE YOUR FREE ACCOUNT
          </span>
          <div
            style={{
              color: "#043B5C",
              fontSize: "0.8rem",
              fontWeight: "bold",
              marginTop: "1rem"
            }}>
            <span>Already have an Account? </span>
            <Link to="/" style={{ color: "#1461A2" }}>
              Log in
            </Link>
          </div>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <CustomInput
                label="First Name"
                name="first_name"
                error={errors?.first_name?.message || validationErrors?.first_name}
                customRef={register({
                  required: "This field is required"
                  // validate: validateName
                })}
                value={values.first_name || ""}
                inputWidth="379px"
                inputHeight="50px"
                type="text"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                label="Last Name"
                name="last_name"
                error={errors?.last_name?.message || validationErrors?.last_name}
                customRef={register({
                  required: "This field is required"
                  // validate: validateName
                })}
                value={values.last_name || ""}
                inputWidth="379px"
                inputHeight="50px"
                type="text"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                label="Email Address"
                name="email"
                error={errors?.email?.message || validationErrors?.email}
                customRef={register({
                  required: "This field is required",
                  pattern: {
                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                    message: "invalid email address"
                  }
                })}
                value={values.email || ""}
                inputWidth="379px"
                inputHeight="50px"
                type="email"
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <CustomInput
                label="Password"
                name="password"
                error={errors?.password?.message || validationErrors?.password}
                customRef={register({
                  required: "This field is required",
                  minLength: {
                    value: 2,
                    message: "Password must contain more than two characters"
                  }
                })}
                value={values.password || ""}
                inputWidth="379px"
                inputHeight="50px"
                type="password"
                onChange={handleInputChange}
                typing={typing}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                label="Confirm Password"
                name="password_confirmation"
                error={
                  errors?.password_confirmation?.message ||
                  validationErrors?.password_confirmation
                }
                customRef={register({
                  required: "This field is required",
                  validate: validatePassword
                })}
                value={values.password_confirmation || ""}
                inputWidth="379px"
                inputHeight="50px"
                type="password"
                onChange={handleInputChange}
                typing={typing}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <CustomInput
                label="Referral"
                name="referral_liveizy"
                error={
                  errors?.referral_liveizy?.message || validationErrors?.referral_liveizy
                }
                customRef={register({
                  required: false
                })}
                value={values.referral_liveizy || ""}
                inputWidth="379px"
                inputHeight="50px"
                type="text"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                label="Phone Number"
                name="phone"
                error={errors?.phone?.message || validationErrors?.phone}
                customRef={register({
                  required: "Phone number is required",
                  pattern: {
                    value: nigerianPhoneNumberPattern,
                    message:
                      "Invalid Phone Number format. e.g +2348012345678, 07012345678"
                  }
                })}
                value={values.phone || ""}
                inputWidth="379px"
                inputHeight="50px"
                type="text"
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </Grid>
        {messages !== "" && <ErrorDiv>{messages}</ErrorDiv>}
        <Grid item xs={12}>
          {/* <Link to="/login"> */}
          <CustomButton
            buttonText="Create Account"
            isLoading={submitting}
            style={{
              backgroundColor: "#4786FF",
              color: "#FFFFFF",
              width: "8rem",
              textTransform: "none",
              marginTop: "1.5rem",
              marginBottom: "1rem"
            }}
          />
          <div>{props.msg && props.msg === "" ? "Error" : ""}</div>
          {/* </Link> */}
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              color: "#043B5C",
              fontSize: "0.8rem",
              fontWeight: "bold"
            }}>
            <span>By Signing up you are agreeing to Liveizy </span>
            <Link to="#" style={{ color: "#1461A2" }}>
              Terms
              <br /> and Conditions
            </Link>
          </div>
        </Grid>
      </form>
    </Grid>
  );
};

const Register = () => {
  return (
    <AuthPageContainer size={true}>
      <RegistrationForm />
    </AuthPageContainer>
  );
};

const mapStateToProps = ({ notifications }) => ({
  message: notifications.msg,
  status: notifications.status
});

export default connect(mapStateToProps)(Register);
