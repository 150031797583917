import { BOOKING_CONSTANTS } from "../constants";

const {
    BOOK_INSPECTION_FAIL,
    BOOK_INSPECTION_PENDING,
    BOOK_INSPECTION_SUCCESS,

    CLEAR_BOOKING_FIELDS,

    GET_MY_BOOKINGS_FAIL,
    GET_MY_BOOKINGS_PENDING,
    GET_MY_BOOKINGS_SUCCESS,

    DELETE_INSPECTION_PENDING,
    DELETE_INSPECTION_FAIL,
    DELETE_INSPECTION_SUCCESS,

    GET_APARTMENTS_BOOKED_FAIL,
    GET_APARTMENTS_BOOKED_PENDING,
    GET_APARTMENTS_BOOKED_SUCCESS,

    REGISTER_SHOW_OF_INTEREST_FAIL,
    REGISTER_SHOW_OF_INTEREST_SUCCESS,
    REGISTER_SHOW_OF_INTEREST_PENDING,

    GET_APARTMENT_BOOKINGS_FAIL,
    GET_APARTMENT_BOOKINGS_PENDING,
    GET_APARTMENT_BOOKINGS_SUCCESS
} = BOOKING_CONSTANTS;

const initialState = {
    bookingInspection: false,
    successfullyBooked: false,
    myBookings: [],
    deletingInspection: false,
    tenantsRequests: [],
    apartmentBookings: []
};

const bookingReducer = (state = initialState, action) => {
    const { type, payload, message } = action;

    switch (type) {
        case BOOK_INSPECTION_PENDING:
            return {
                ...state,
                bookingInspection: true,
                successfullyBooked: false
            };
        case BOOK_INSPECTION_SUCCESS:
            return {
                ...state,
                bookingInspection: false,
                successfullyBooked: true
            };
        case BOOK_INSPECTION_FAIL:
            return {
                ...state,
                bookingInspection: false,
                successfullyBooked: false
            };
        case CLEAR_BOOKING_FIELDS:
            return {
                ...state,
                bookingInspection: false,
                successfullyBooked: false
            };
        case GET_MY_BOOKINGS_PENDING:
            return {
                ...state
            };
        case GET_MY_BOOKINGS_SUCCESS:
            return {
                ...state,
                myBookings: payload
            };
        case GET_MY_BOOKINGS_FAIL:
            return {
                ...state,
                myBookings: []
            };
        case DELETE_INSPECTION_PENDING:
            return {
                ...state,
                deletingInspection: true
            };
        case DELETE_INSPECTION_SUCCESS:
            return {
                ...state,
                deletingInspection: false
            };
        case DELETE_INSPECTION_FAIL:
            return {
                ...state,
                deletingInspection: false
            };
        case GET_APARTMENTS_BOOKED_PENDING:
            return {
                ...state
            };
        case GET_APARTMENTS_BOOKED_SUCCESS:
            return {
                ...state,
                tenantsRequests: payload
            };
        case GET_APARTMENTS_BOOKED_FAIL:
            return {
                ...state,
                tenantsRequests: []
            };
        case REGISTER_SHOW_OF_INTEREST_PENDING:
            return {
                ...state
            };
        case REGISTER_SHOW_OF_INTEREST_SUCCESS:
            return {
                ...state
            };
        case REGISTER_SHOW_OF_INTEREST_FAIL:
            return {
                ...state
            };
        case GET_APARTMENT_BOOKINGS_PENDING:
            return {
                ...state
            };
        case GET_APARTMENT_BOOKINGS_SUCCESS:
            return {
                ...state,
                apartmentBookings: payload
            };
        case GET_APARTMENT_BOOKINGS_FAIL:
            return {
                ...state,
                apartmentBookings: []
            };
        default:
            return state;
    }
};

export default bookingReducer;
