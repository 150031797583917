import { Box, Container } from "@mui/material";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AssociationDashoard from "../dashboard/AssociationDashboard";
import Financial from "../financial/Financial";
import AssociationSidebar from "../layout/sidebar/AssociationSidebar";
import ManageRoles from "../manageRoles/pages/ManageRoles";
import MemberDetails from "../members/pages/MemberDetails";
import Members from "../members/pages/Members";
import Summary from "../summary/pages/Summary";

const AssociationRoutes = () => {
  return (
    <Box display={"flex"}>
      <AssociationSidebar />
      <Container>
        <Switch>
          <Route path={"/association"} exact>
            <Redirect to={"/association/members"} />
          </Route>
          <Route exact path="/association/dashboard" component={AssociationDashoard} />
          <Route exact path="/association/summary" component={Summary} />
          <Route exact path="/association/members" component={Members} />
          <Route exact path="/association/manage-roles" component={ManageRoles} />
          <Route exact path="/association/financial" component={Financial} />
          <Route exact path="/association/members/:lid" component={MemberDetails} />
        </Switch>
      </Container>
    </Box>
  );
};

export default AssociationRoutes;
