const REQUIRED = "This field is Required";
const MOBILE_LENGTH_ERRORMSG =
  "Invalid Phone Number format. e.g +2348012345678, 07012345678";

export const employmentStatusModel = {
  formId: "employmentStatusModel",
  formField: {
    employmentStatus: {
      name: "employmentStatus",
      label: "Employment Status*",
      requiredErrorMsg: REQUIRED,
      placeHolder: "Employment Status"
    },
    nameOfEmployer: {
      name: "nameOfEmployer",
      label: "Name of Employer*",
      requiredErrorMsg: REQUIRED,
      placeHolder: "Name of Employer"
    },
    officeAddress: {
      name: "officeAddress",
      label: "Office Address*",
      requiredErrorMsg: REQUIRED,
      placeHolder: "Office Address"
    },
    monthlyIncome: {
      name: "monthlyIncome",
      label: "Monthly Income*",
      requiredErrorMsg: REQUIRED,
      placeHolder: "Monthly Income"
    },
    nameOfOfficeContactPerson: {
      name: "nameOfOfficeContactPerson",
      label: "Name of Office Contact Person*",
      requiredErrorMsg: REQUIRED,
      placeHolder: "Name of Office Contact Person"
    },
    officeContactPersonNumber: {
      name: "officeContactPersonNumber",
      label: "Office Contact Person Number*",
      requiredErrorMsg: REQUIRED,
      invalidLengthErrorMsg: MOBILE_LENGTH_ERRORMSG,
      placeHolder: "Office Contact Person Number"
    }
  }
};

export const currentTenancyInfo = {
  formId: "currentTenancyInfo",
  formField: {
    currentHomeAddress: {
      name: "currentHomeAddress",
      label: "Current Home Address*",
      requiredErrorMsg: REQUIRED,
      placeHolder: "Current Home Address"
    },
    currentRentValue: {
      name: "currentRentValue",
      label: "Current Rent Value*",
      requiredErrorMsg: REQUIRED,
      invalidErrorMsg: "Invalid. Must be numbers",
      placeHolder: "Current Rent Value"
    },
    nameOfManager: {
      name: "nameOfManager",
      label: "Name of Manager*",
      requiredErrorMsg: REQUIRED,
      placeHolder: "Name of Manager"
    },
    contactOfManager: {
      name: "contactOfManager",
      label: "Contact of Manager*",
      requiredErrorMsg: REQUIRED,
      invalidLengthErrorMsg: MOBILE_LENGTH_ERRORMSG,
      placeHolder: "Contact of Manager"
    },
    nameOfLandlord: {
      name: "nameOfLandlord",
      label: "Name of Landlord*",
      requiredErrorMsg: REQUIRED,
      placeHolder: "Name of Landlord"
    },
    contactOfLandlord: {
      name: "contactOfLandlord",
      label: "Contact of Landlord*",
      requiredErrorMsg: REQUIRED,
      invalidLengthErrorMsg: MOBILE_LENGTH_ERRORMSG,
      placeHolder: "Contact of Landlord"
    }
  }
};

export const meansOfIdModel = {
  formId: "meansOfIdModel",
  formField: {
    meansOfId: {
      name: "meansOfId",
      label: "Means Of ID*",
      requiredErrorMsg: REQUIRED,
      placeHolder: "Select Means Of ID"
    },
    meansOfIdFile: {
      name: "meansOfIdFile",
      label: "Upload Id Image",
      requiredErrorMsg: "Image is required",
      acceptedFileTypes: ["image/jpg", "image/png", "image/jpeg"],
      invalidFiletypeErrorMsg: "Unsupported format. File should be .jpg or .png or .jpeg"
    },
    nameOfNextOfKin: {
      name: "nameOfNextOfKin",
      label: "Name of Next of Kin*",
      requiredErrorMsg: REQUIRED,
      placeHolder: "Name of Next of Kin"
    },
    contactOfNextOfKin: {
      name: "contactOfNextOfKin",
      label: "Contact of Next of Kin*",
      requiredErrorMsg: REQUIRED,
      invalidLengthErrorMsg: MOBILE_LENGTH_ERRORMSG,
      placeHolder: "Contact of Next of Kin"
    }
  }
};
