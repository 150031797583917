import React from "react";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import ThreeDotMenu from "#/components/estateManagementComponents/three-dots-menu";
import { CircularProgress } from "@mui/material";
import { ESM_ROUTES } from "#/constants/routes";

const MemberRibbon = ({ member, deactivateMember, loadingDelete }) => {
  // const menuItems = [{ title: "Deactivate member", action: deactivateMember, disabled: member.super_admin }];

  return (
    <Card className="member-details">
      {/* {loadingDelete[member._id] ? (
                <CircularProgress size={20} />
            ) : ( */}
      <div className="mem-det-div1">
        {/* <ThreeDotMenu menuItems={menuItems} /> */}three diot
      </div>
      {/* )} */}
      <div className="mem-det-div2">
        <span className="member-name">
          {"member?.first_name"} {"member?.last_name"}
        </span>
        <span className="member-role">{`member.super_admin ? "super admin" : member.roles[0]?.title`}</span>
        <span className="member-link">
          <Link
            to={ESM_ROUTES.payments.replace(":lid", member.liveizy_id)}
            style={{ color: "blue" }}>
            {member.liveizy_id}
          </Link>
        </span>
      </div>
    </Card>
  );
};

export default MemberRibbon;
