import * as actionTypes from "../constants";

const { CREATE_SCHEDULE, DELETE_SCHEDULE, UPDATE_SCHEDULE, FECTH_SCHEDULE } = actionTypes.SCHEDULES_CONSTANTS;

const initialState = {
    creating: false,
    updating: false,
    deleting: false,
    loading: false,
    failed: false,
    apartmentSchedules: null,
    selectedSchedule: null
};

const schedulesReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case CREATE_SCHEDULE:
            return { ...state, ...payload };
        case FECTH_SCHEDULE:
            return { ...state, ...payload };
        case UPDATE_SCHEDULE:
            return { ...state, ...payload };

        case DELETE_SCHEDULE:
            return { ...state, ...payload };
        default:
            return state;
    }
};

export default schedulesReducer;
