import axios from "axios";

import { CHARGES_ACTIONTYPES_CONSTANTS } from "../constants";
import { showNotifications } from "#/store/actions/notification.actions";

//------------------------------------------------------------------
//Variables and Helpers
//------------------------------------------------------------------
const {
  GET_CHARGES_IN_APARTMENT_PENDING,
  GET_CHARGES_IN_APARTMENT_SUCCESS,
  GET_CHARGES_IN_APARTMENT_FAIL,
  CREATE_CHARGES_IN_APARTMENT_PENDING,
  CREATE_CHARGES_IN_APARTMENT_SUCCESS,
  CREATE_CHARGES_IN_APARTMENT_FAIL,
  UPDATE_CHARGE_FAIL,
  UPDATE_CHARGE_PENDING,
  UPDATE_CHARGE_SUCCESS,
  DELETE_CHARGE_FAIL,
  DELETE_CHARGE_PENDING,
  DELETE_CHARGE_SUCCESS
} = CHARGES_ACTIONTYPES_CONSTANTS;

const BASE_URL = process.env.REACT_APP_PROPERTY_SERVICE_BASE_URL;

// GET CHARGES IN AN APARTMENT
export const getApartmentCharges = (aId) => async (dispatch) => {
  dispatch({ type: GET_CHARGES_IN_APARTMENT_PENDING });
  try {
    // const response = await axios.get(`${BASE_URL}/charges/${id}`);
    const response = await axios.get(`${BASE_URL}/bill/get_apartment_bills/${aId}`);
    console.log("This is response for bills...", response);

    if ([200, 201].includes(response.status)) {
      dispatch({
        type: GET_CHARGES_IN_APARTMENT_SUCCESS,
        payload: response.data.data
        // payload: response.data.payload
      });
    }
  } catch (error) {
    dispatch({ type: GET_CHARGES_IN_APARTMENT_FAIL });
  }
};

// CREATE CHARGES IN AN APARTMENT
export const createChargesForApartment = (payload, cb) => async (dispatch) => {
  dispatch({ type: CREATE_CHARGES_IN_APARTMENT_PENDING });

  const { name } = payload;
  try {
    const response = await axios.post(`${BASE_URL}/bill/store?type=bill`, payload);
    if ([200, 201].includes(response.status)) {
      dispatch({
        type: CREATE_CHARGES_IN_APARTMENT_SUCCESS,
        payload: response.data.payload
      });
      dispatch(showNotifications(`${name} charge is created`, "success"));
      cb();
    }
  } catch (error) {
    dispatch({ type: CREATE_CHARGES_IN_APARTMENT_FAIL, error });
    dispatch(showNotifications(`${error.response.data.message}`, "error"));
  }
};

export const updateChargeForApartment = (payload, id, cb) => async (dispatch) => {
  dispatch({ type: UPDATE_CHARGE_PENDING });

  const { name } = payload;
  try {
    const response = await axios.patch(
      `${BASE_URL}/bill/update_bill/${id}?type=bill`,
      payload
    );
    if ([200, 201].includes(response.status)) {
      dispatch({
        type: UPDATE_CHARGE_SUCCESS,
        payload: response.data.payload
      });
      dispatch(showNotifications(`${name} charge is updated`, "success"));
      cb();
    }
  } catch (error) {
    dispatch({ type: UPDATE_CHARGE_FAIL, error });
  }
};

export const deleteChargeForApartment = (id, cb) => async (dispatch) => {
  dispatch({ type: DELETE_CHARGE_PENDING });

  try {
    const response = await axios.delete(`${BASE_URL}/bill/delete_bill/${id}?type=bill`);
    dispatch({
      type: DELETE_CHARGE_SUCCESS,
      payload: response.data.payload
    });
    dispatch(showNotifications(`Charge deleted`, "success"));
    cb();
  } catch (error) {
    //dispatch({ type: DELETE_CHARGE_FAIL, error });
    dispatch(showNotifications(`${error.response.data.message}`, "error"));
  }
};

//---------------------------------------------------------
//NETWORK REQUESTS
//---------------------------------------------------------

export const fetchApartmentCharges = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/charges/${id}`);
    if ([200, 201].includes(response.status)) {
      return response.data.payload;
    }
  } catch (error) {
    return error;
    // dispatch({ type: GET_CHARGES_IN_APARTMENT_FAIL });
  }
};

// import axios from "axios";

// import { CHARGES_ACTIONTYPES_CONSTANTS } from "../constants";
// import { showNotifications } from "#/store/actions/notification.actions";

// //------------------------------------------------------------------
// //Variables and Helpers
// //------------------------------------------------------------------
// const {
//     GET_CHARGES_IN_APARTMENT_PENDING,
//     GET_CHARGES_IN_APARTMENT_SUCCESS,
//     GET_CHARGES_IN_APARTMENT_FAIL,
//     CREATE_CHARGES_IN_APARTMENT_PENDING,
//     CREATE_CHARGES_IN_APARTMENT_SUCCESS,
//     CREATE_CHARGES_IN_APARTMENT_FAIL,
//     UPDATE_CHARGE_FAIL,
//     UPDATE_CHARGE_PENDING,
//     UPDATE_CHARGE_SUCCESS,
//     DELETE_CHARGE_FAIL,
//     DELETE_CHARGE_PENDING,
//     DELETE_CHARGE_SUCCESS
// } = CHARGES_ACTIONTYPES_CONSTANTS;

// const BASE_URL = process.env.REACT_APP_PROPERTY_SERVICE_BASE_URL;

// // GET CHARGES IN AN APARTMENT
// export const getApartmentCharges = (id) => async (dispatch) => {
//     dispatch({ type: GET_CHARGES_IN_APARTMENT_PENDING });
//     try {
//         const response = await axios.get(`${BASE_URL}/charges/${id}`);
//         if ([200, 201].includes(response.status)) {
//             dispatch({
//                 type: GET_CHARGES_IN_APARTMENT_SUCCESS,
//                 payload: response.data.payload
//             });
//         }
//     } catch (error) {
//         dispatch({ type: GET_CHARGES_IN_APARTMENT_FAIL });
//     }
// };

// // CREATE CHARGES IN AN APARTMENT
// export const createChargesForApartment = (payload, cb) => async (dispatch) => {
//     dispatch({ type: CREATE_CHARGES_IN_APARTMENT_PENDING });

//     const { name } = payload;
//     try {
//         const response = await axios.post(`${BASE_URL}/charges`, payload);
//         if ([200, 201].includes(response.status)) {
//             dispatch({
//                 type: CREATE_CHARGES_IN_APARTMENT_SUCCESS,
//                 payload: response.data.payload
//             });
//             dispatch(showNotifications(`${name} charge is created`, "success"));
//             cb();
//         }
//     } catch (error) {
//         dispatch({ type: CREATE_CHARGES_IN_APARTMENT_FAIL, error });
//         dispatch(showNotifications(`${error.response.data.payload.message}`, "error"));
//     }
// };

// export const updateChargeForApartment = (payload, id, cb) => async (dispatch) => {
//     dispatch({ type: UPDATE_CHARGE_PENDING });

//     const { name } = payload;
//     try {
//         const response = await axios.put(`${BASE_URL}/charges/${id}`, payload);
//         if ([200, 201].includes(response.status)) {
//             dispatch({
//                 type: UPDATE_CHARGE_SUCCESS,
//                 payload: response.data.payload
//             });
//             dispatch(showNotifications(`${name} charge is updated`, "success"));
//             cb();
//         }
//     } catch (error) {
//         dispatch({ type: UPDATE_CHARGE_FAIL, error });
//     }
// };

// export const deleteChargeForApartment = (id, cb) => async (dispatch) => {
//     dispatch({ type: DELETE_CHARGE_PENDING });

//     try {
//         const response = await axios.delete(`${BASE_URL}/charges/${id}`);
//         dispatch({
//             type: DELETE_CHARGE_SUCCESS,
//             payload: response.data.payload
//         });
//         dispatch(showNotifications(`Charge deleted`, "success"));
//         cb();
//     } catch (error) {
//         dispatch({ type: DELETE_CHARGE_FAIL, error });
//     }
// };

// //---------------------------------------------------------
// //NETWORK REQUESTS
// //---------------------------------------------------------

// export const fetchApartmentCharges = async (id) => {
//     try {
//         const response = await axios.get(`${BASE_URL}/charges/${id}`);
//         if ([200, 201].includes(response.status)) {
//             return response.data.payload;
//         }
//     } catch (error) {
//         return error;
//         // dispatch({ type: GET_CHARGES_IN_APARTMENT_FAIL });
//     }
// };
