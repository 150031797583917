import { CircularProgress, styled } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CreateIcon from "@material-ui/icons/Create";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationPopup from "#/components/popup/confirmation";
import { propertyServiceApi } from "#/apis/propertyServiceApi";
import { showNotifications } from "#/store/actions/notification.actions";

export default function BankDetailTile({ bankDetail, role, setRoleState }) {
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editedBankDetail, setEditedBankDetail] = useState({
    code: bankDetail.code,
    account_number: bankDetail.account_number
    // Add other fields as needed
  });

  const user = useSelector((state) => state.auth.user);
  const [deleteClick, setDeleteClick] = useState(false);

  const [iconsVisible, setIconsVisible] = useState(true);

  const deleteBankAccount = () => {
    setDeleteClick(false);
    setDeleting(true);
    const params = {
      params: { code: bankDetail.code, account_number: bankDetail.account_number }
    };

    propertyServiceApi()
      .delete(
        `/property-${role}/bank/${user.liveizy_id}?bankId=${bankDetail._id}`,
        params
      )
      .then((data) => {
        dispatch(showNotifications("Bank Account deleted.", "success"));
        setDeleting(false);
        setRoleState(data.data.payload);
      })
      .catch((err) => {
        setDeleting(false);
        dispatch(
          showNotifications(
            err.response?.data?.error || "An error occured, try again later.",
            "error"
          )
        );
      });
  };

  const startEdit = () => {
    setEditMode(true);
    setEditedBankDetail({
      code: bankDetail.code,
      account_number: bankDetail.account_number
    });
    setIconsVisible(false);
  };

  const cancelEdit = () => {
    setEditMode(false);
    setIconsVisible(true);
  };

  const saveEdit = () => {
    // Add focus to the input element when clicked
    inputRef.current && inputRef.current.focus();
    setIconsVisible(true);
    const params = {
      account_number: editedBankDetail.account_number
    };
    // Make an API call to update the details here
    propertyServiceApi()
      .patch(`/property-${role}/edit-bank?bankId=${bankDetail._id}`, params)
      .then((data) => {
        setEditMode(false);
        dispatch(showNotifications("Bank Account Edited.", "success"));
        setRoleState(data.data.payload);
      })
      .catch((err) => {
        setEditMode(false);
        dispatch(
          showNotifications(
            err.response?.data?.error || "An error occured, try again later.",
            "error"
          )
        );
      });

    // For simplicity, let's just log the edited details
    console.log("Edited Bank Details:", editedBankDetail);

    setEditMode(false);
  };

  const inputRef = useRef(null); // Create a ref for the input element
  useEffect(() => {
    // Use useEffect to focus on the input element after re-render
    if (editMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editMode]);

  return (
    <>
      {deleteClick && (
        <ConfirmationPopup
          title="Delete Bank Account"
          description="Are you sure you want to delete this account?"
          handleClose={() => setDeleteClick(false)}
          proceedAction={deleteBankAccount}
        />
      )}
      <Wrapper>
        <div className="lbc-bank-accs-item">
          <div className="lbc-bai-div1">
            {editMode ? (
              <div>
                {/* Edit Form/Modal */}
                <label>
                  Account Number:
                  <input
                    ref={inputRef}
                    type="text"
                    value={editedBankDetail.account_number}
                    onChange={(e) =>
                      setEditedBankDetail({
                        ...editedBankDetail,
                        account_number: e.target.value
                      })
                    }
                  />
                </label>
                {/* Add other fields as needed */}
                <button style={saveButtonStyle} onClick={saveEdit}>
                  Save
                </button>
                <button style={cancelButtonStyle} onClick={cancelEdit}>
                  Cancel
                </button>
              </div>
            ) : (
              <div>
                <p className="lbc-bank-name">{bankDetail?.name}</p>
                <div className="lbc-an-name-div">
                  <p>{bankDetail?.account_number}</p>
                  <p className="lbc-acc-name">{bankDetail?.account_name}</p>
                </div>
              </div>
            )}
          </div>
          {iconsVisible && (
            <>
              <IconButton
                sx={{ padding: 0 }}
                disabled={deleting}
                onClick={() => setDeleteClick(true)}
                aria-label="delete">
                {deleting ? <CircularProgress size={20} /> : <DeleteOutlineIcon />}
              </IconButton>
              <IconButton
                sx={{ padding: 0 }}
                disabled={deleting}
                onClick={startEdit}
                aria-label="edit">
                {deleting ? <CircularProgress size={20} /> : <CreateIcon />}
              </IconButton>
            </>
          )}
        </div>
      </Wrapper>
    </>
  );
}

const Wrapper = styled("div")`
  .lbc-bank-accs-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    padding: 5px 10px;
    border-radius: 4px;
    border: 2px solid darkgray;
    margin-bottom: 20px;
    .lbc-bank-name {
      color: black;
    }
    .lbc-an-name-div {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      p:first-of-type {
        color: blue;
        font-weight: bold;
        margin-right: 10px;
      }
      .lbc-acc-name {
        font-size: 14px;
      }
    }
  }
`;

const IconButton = styled("button")`
  outline: none;
  :focus {
    outline: none;
  }
`;

const saveButtonStyle = {
  backgroundColor: "#10609f", // Green color
  color: "white",
  padding: "10px 20px",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  marginRight: "8px"
};

const cancelButtonStyle = {
  backgroundColor: "#f44336", // Red color
  color: "white",
  padding: "10px 20px",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer"
};
