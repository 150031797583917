import React, { useState, useEffect } from "react";
import { businessStyles } from "./businessStyle";
import { useDispatch } from "react-redux";
import { Button, CircularProgress, IconButton, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { ListOfBanksInNigeria } from "#/constants";
import { propertyServiceApi, returnUserData } from "#/apis/propertyServiceApi";
import { handleFormInput, validate } from "#/lib/validation";
import "./landlord-business-component.scss";
import clsx from "clsx";
import { showNotifications } from "#/store/actions/notification.actions";
import ConfirmationPopup from "#/components/popup/confirmation";
import { walletServiceApi } from "#/apis/izyPayApi";
import { useSelector } from "react-redux";

const intialValues = {
    bank: { value: "", validation: true },
    accountNumber: { value: "", validation: true }
};

const AgentBankDetails = () => {
    const dispatch = useDispatch();
    const { banksInNigeria } = useSelector((state) => state.paystack);

    const [agent, setAgent] = useState(null);
    const [notExist, setNotExist] = useState(false);
    const [formValues, setFormValues] = useState(intialValues);
    const [fadeBtn, setFadeBtn] = useState(true);
    const [loading, setLoading] = useState(false);
    const [deleteClick, setDeleteClick] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [delItem, setDelItem] = useState(null);
    const [validated, setValidated] = useState(null);

    useEffect(() => {
        validate(formValues, (trueFalse) => {
            const { bank, accountNumber } = formValues;

            if (trueFalse) {
                validateBankAccount(bank.value.code, accountNumber.value);
            }
            setFadeBtn(!trueFalse);
        });
    }, [formValues]);

    useEffect(() => fetchAgentInfo(), []);

    const onFormSubmit = (e) => {
        e.preventDefault();
        if (notExist) return createAgent();
        if (!notExist) addBankAccount();
    };

    const createAgent = () => {
        setLoading(true);
        const user = returnUserData();
        const payload = { liveizy_id: user.liveizy_id };
        propertyServiceApi()
            .post("/property-manager", payload)
            .then((data) => {
                setLoading(false);
                setAgent(data.data);
                setNotExist(false);
                addBankAccount();
            })
            .catch(() => {
                dispatch(showNotifications("Error occured. Try again later", "error"));
                setLoading(false);
            });
    };

    const fetchAgentInfo = () => {
        const user = returnUserData();
        propertyServiceApi()
            .get(`/property-manager/${user.liveizy_id}`)
            .then((data) => {
                setAgent(data.data.payload);
            })
            .catch((err) => {
                if (err.response.status === 404) setNotExist(true);
            });
    };

    const addBankAccount = () => {
        setLoading(true);
        const user = returnUserData();
        const payload = {
            code: formValues.bank.value?.code,
            account_number: formValues.accountNumber.value
        };
        propertyServiceApi()
            .patch(`/property-manager/bank/${user.liveizy_id}`, payload)
            .then((data) => {
                setLoading(false);
                setAgent(data.data.payload);
                setFormValues(intialValues);
                dispatch(showNotifications("Account addedd", "success"));
            })
            .catch(({ response }) => {
                setLoading(false);
                dispatch(showNotifications(response?.data?.error, "error"));
            });
    };

    const onDeleteClick = (item) => {
        setDeleteClick(true);
        setDelItem(item);
    };

    const deleteBankAccount = () => {
        setDeleteClick(false);
        setDeleting(true);
        const user = returnUserData();
        const params = { params: { code: delItem.code, account_number: delItem.account_number } };

        propertyServiceApi()
            .delete(`/property-manager/bank/${user.liveizy_id}`, params)
            .then((data) => {
                setDeleting(false);
                setAgent(data.data.payload);
            })
            .catch((err) => {
                setDeleting(false);
            });
    };

    const validateBankAccount = (bank_code, account_number) => {
        setValidated("loading");
        walletServiceApi()
            .post("/user/validate/nuban", { bank_code, account_number })
            .then((data) => {
                setValidated(data.data.data.account_name);
            })
            .catch((err) => {
                dispatch(showNotifications("Could not resolve account name", "error"));
                setValidated(false);
            });
    };

    const classes = businessStyles();

    return (
        <div className={clsx("lbc-23de23d")}>
            <section className={classes.formWrapper}>
                <div className="lbc-bank-accs">
                    {agent?.bank_accounts?.map((item, idx) => (
                        <div className="lbc-bank-accs-item" key={idx}>
                            <div className="lbc-bai-div1">
                                <p className="lbc-bank-name">{item.name}</p>
                                <div className="lbc-an-name-div">
                                    <p>{item.account_number}</p>
                                    <p className="lbc-acc-name">{item.account_name}</p>
                                </div>
                            </div>
                            <IconButton
                                className="lbc-del-icon"
                                onClick={() => onDeleteClick(item)}
                                aria-label="delete">
                                {deleting && delItem.account_number === item.account_number ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    <DeleteOutlineIcon />
                                )}
                            </IconButton>
                        </div>
                    ))}
                </div>
                <form className="lbc-add-new-acc-form" onSubmit={onFormSubmit}>
                    <h4 className={classes.inputLabel}>Add New Bank Account</h4>
                    <div className={classes.forms}>
                        <Autocomplete
                            id="grouped-demo"
                            options={banksInNigeria}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select your bank"
                                    variant="outlined"
                                    fullWidth
                                    error={!formValues.bank.validation}
                                />
                            )}
                            value={formValues.bank.value}
                            onChange={(e, value) => handleFormInput("bank", value, /^.+$/gi, formValues, setFormValues)}
                            style={{ width: "100%", maxWidth: 300, marginBottom: 20 }}
                        />
                        <TextField
                            variant="outlined"
                            label="Account Number"
                            value={formValues.accountNumber.value}
                            onChange={(e) => {
                                const value = e.target.value.replace(/[^0-9]/g, "").slice(0, 10);
                                handleFormInput("accountNumber", value, /^\d{10}$/g, formValues, setFormValues);
                            }}
                            error={!formValues.accountNumber.validation}
                            disabled={validated === "loading"}
                            style={{ width: "100%", maxWidth: 300, marginBottom: 20 }}
                            helperText={validated === "loading" ? "validating..." : validated}
                        />
                    </div>
                    <div className={""}>
                        <Button
                            type="submit"
                            disabled={fadeBtn || loading || !validated || validated === "loading"}
                            className={classes.btn_save}
                            variant="contained"
                            color="primary"
                            style={{ width: "100%", maxWidth: 250, marginTop: 10 }}>
                            {loading ? <CircularProgress size={20} /> : "Add bank account"}
                        </Button>
                    </div>
                </form>
            </section>
            {deleteClick && (
                <ConfirmationPopup
                    title="Delete Bank Account"
                    description="Are you sure you want to delete this account?"
                    handleClose={() => setDeleteClick(false)}
                    proceedAction={deleteBankAccount}
                />
            )}
        </div>
    );
};

export default AgentBankDetails;
