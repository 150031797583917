import { makeStyles } from "@material-ui/core/styles";

export const useInnerListingStyles = makeStyles((theme) => ({
    innerListingContainer: {
        display: "flex",
        maxWidth: "1465px",
        backgroundColor: "white",
        padding: "25px 26px",
        borderRadius: "15px",
        marginBottom: "100px",

        [theme.breakpoints.between("0", "700")]: {
            flexDirection: "column",
            overflowX: "scroll",
            padding: "25px 15px",
            marginBottom: "20px"
        }
    },
    imagesContainer: {
        width: "60%",
        marginRight: "54px",
        maxWidth: "770px",

        [theme.breakpoints.between("0", "700")]: {
            width: "100%"
        },
        [theme.breakpoints.between("700", "1100")]: {
            width: "55%",
            marginRight: "34px"
        }
    },
    imagesBig: {
        width: "99%",
        marginBottom: "46px",

        [theme.breakpoints.between("0", "700")]: {
            width: "100%",
            marginBottom: "15px"
        },

        [theme.breakpoints.between("700", "1100")]: {
            width: "100%"
        },

        "& img": {
            width: "100%",
            height: 413,
            objectFit: "cover",
            borderRadius: "10px",

            [theme.breakpoints.between("0", "430")]: {
                height: 200
            },

            [theme.breakpoints.between("430", "600")]: {
                height: 300
            },

            [theme.breakpoints.between("600", "700")]: {
                height: 400
            },

            [theme.breakpoints.between("700", "1100")]: {
                height: 360
            }
        }
    },
    smallImagesDesktopView: {
        width: "100%",
        maxWidth: "770px",
        marginBottom: "30px",
        display: "flex",
        justifyContent: "space-between",

        [theme.breakpoints.between("700", "1100")]: {
            minWidth: "100%",
            overflowX: "scroll"
        },

        [theme.breakpoints.between("0", "700")]: {
            display: "none",
            width: "100%",
            overflowX: "scroll",
            justifyContent: "flex-start"
        },

        "& img": {
            width: "95%",
            maxWidth: "245px",
            height: 154,
            borderRadius: "10px",
            objectFit: "cover",

            [theme.breakpoints.between("700", "1100")]: {
                width: "95%",
                height: "100%"
            },

            [theme.breakpoints.between("0", "700")]: {
                width: "calc(100vw/3)",
                marginRight: 5,
                height: "100%"
            }
        }
    },
    smallImagesMobileView: {
        marginBottom: "30px",
        justifyContent: "space-between",
        display: "none",
        width: "100%",
        maxWidth: "770px",

        [theme.breakpoints.between("700", "1100")]: {
            minWidth: "100%",
            overflowX: "scroll"
        },

        [theme.breakpoints.between("0", "700")]: {
            width: "100%",
            display: "flex",
            overflowX: "scroll",
            justifyContent: "flex-start"
        },

        "& img": {
            width: "95%",
            maxWidth: "245px",
            height: 154,
            borderRadius: "10px",
            objectFit: "cover",

            [theme.breakpoints.between("700", "1100")]: {
                width: "95%",
                height: "100%"
            },

            [theme.breakpoints.between("0", "700")]: {
                width: "calc(100vw/3)",
                marginRight: 5,
                height: "100%"
            }
        }
    },
    seeMore: {
        width: "calc(100vw/3)",
        height: "100%",
        borderRadius: "10px",
        padding: "15px 10px",
        backgroundColor: "#F4F5F7"
    },
    details: {
        maxWidth: 491,
        width: "30%",

        [theme.breakpoints.between("0", "700")]: {
            width: "100%",
            maxWidth: "100%"
        },

        [theme.breakpoints.between("700", "1100")]: {
            width: 300
        },

        "& h1": {
            fontSize: 30,
            marginTop: 20
        },
        "& h4": {
            fontSize: 24,
            margin: 0
        },
        "& h3": {
            fontSize: 24,
            marginTop: 40
        },
        "& p": {
            width: "100%",
            fontSize: 16,
            marginBottom: 10,
            fontWeight: "normal"
        }
    },
    location: {
        display: "flex",
        marginTop: 10,
        alignItems: "center",

        "& p": {
            margin: 0
        },
        "& h4": {
            marginRight: 10
        }
    },
    features: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    feature: {
        width: 112
    },
    button: {
        width: "100%",
        color: "#F4F5F7",
        backgroundColor: "#4786FF",
        height: "55px",
        marginTop: 20,
        borderStyle: "none",
        borderRadius: "5px"
    }
}));
