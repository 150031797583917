import { Box, styled } from "@mui/material";
import React, { useState } from "react";
import LandlordSection from "./sections/LandlordSection";
import PManagerSection from "./sections/PManagerSection";

export default function LandlordInfoTabComponent({
  landlordDetails,
  setLandlordDetails,
  pManagerDetails,
  setPManagerDetails,
  landlordbankDetails,
  setLandlordBankDetails,
  pManagerBankDetails,
  setPManagerBankDetails
}) {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (index) => {
    setCurrentTab(index);
  };

  let tabComponent;
  switch (currentTab) {
    case 0:
      tabComponent = (
        <LandlordSection
          landlordDetails={landlordDetails}
          setLandlordDetails={setLandlordDetails}
          landlordbankDetails={landlordbankDetails}
          setLandlordBankDetails={setLandlordBankDetails}
        />
      );
      break;
    case 1:
      tabComponent = (
        <PManagerSection
          pManagerDetails={pManagerDetails}
          setPManagerDetails={setPManagerDetails}
          pManagerBankDetails={pManagerBankDetails}
          setPManagerBankDetails={setPManagerBankDetails}
        />
      );
      break;
    default:
      tabComponent = (
        <LandlordSection
          landlordDetails={landlordDetails}
          setLandlordDetails={setLandlordDetails}
        />
      );
      break;
  }
  return (
    <div>
      <Tabs>
        <Tab sx={currentTab === 0 && active} onClick={() => handleTabChange(0)}>
          Landlord Role
        </Tab>
        <Tab sx={currentTab === 1 && active} onClick={() => handleTabChange(1)}>
          Property Manager Role
        </Tab>
      </Tabs>
      <Box>{tabComponent}</Box>
    </div>
  );
}

const Tabs = styled("div")`
  margin: 0 auto;
  margin-bottom: 30px;
  background: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-height: 30px;
`;

const Tab = styled("div")`
  max-width: 200px;
  padding: 10px;
  font-size: 13px;
  height: 100%;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

  .active {
    background: #10609f;
    color: #fff;
  }
`;

const active = {
  background: "#10609f",
  color: "#fff"
};
