import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
    root: {
        display: "flex",
        marginBottom: 25,
        [theme.breakpoints.down("640")]: {
            overflowX: "scroll"
        },
        "& .summary-wrapper": {
            display: "flex",
            flexDirection: "column",
            color: "#fff !important",
            padding: "10px 17px",
            fontSize: 11,
            borderRadius: 5,
            marginRight: 25,
            minWidth: 150,
            width: 150,
            height: "68px !important",
            maxHeight: 68,
            "&:last-child": {
                marginRight: 0
            }
        },
        "& > div:nth-child(1)": {
            backgroundColor: "#45B29D"
        },
        "& > div:nth-child(2)": {
            backgroundColor: "#EFA25A "
        },
        "& > div:nth-child(3)": {
            backgroundColor: "#1461A2"
        },
        "& > div:nth-child(4)": {
            backgroundColor: "#DF5A49"
        },
        "& .summary-text": {
            color: "#fff",
            fontWeight: 400
        }
    }
});

const ApplicationSummary = (props) => (
    <div className={props.classes.root}>
        <div className="summary-wrapper">
            <Typography className="summary-text" variant="body1" component="p">
                0
            </Typography>
            <Typography className="summary-text" variant="body1" component="p">
                Approved
            </Typography>
        </div>
        <div className="summary-wrapper">
            <Typography className="summary-text" variant="body1" component="p">
                0
            </Typography>
            <Typography className="summary-text" variant="body1" component="p">
                Pending
            </Typography>
        </div>
        <div className="summary-wrapper">
            <Typography className="summary-text" variant="body1" component="p">
                0
            </Typography>
            <Typography className="summary-text" variant="body1" component="p">
                For Review
            </Typography>
        </div>
        <div className="summary-wrapper">
            <Typography className="summary-text" variant="body1" component="p">
                0
            </Typography>
            <Typography className="summary-text" variant="body1" component="p">
                Declined
            </Typography>
        </div>
    </div>
);

export default withStyles(styles)(ApplicationSummary);
