import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import CustomFilters from "./components/customFilters/customFilters.component";
import CustomCard from "./components/customCard/customCard.component";
import { useListingStyles } from "./listing.styles";
import { showNotifications } from "#/store/actions/notification.actions";
import { Pagination } from "@material-ui/lab";
import { propertyServiceApi } from "#/apis/propertyServiceApi";
import { useHistory } from "react-router";

const Search = (props) => {
    const classes = useListingStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const pageLimit = 12;
    const queryParam = new URLSearchParams(props.location.search.slice(1));

    const initialValues = {
        state: { value: queryParam.get("state") !== "null" ? queryParam.get("state") : "" },
        lga: { value: queryParam.get("lga") !== "null" ? queryParam.get("lga") : "" },
        price: { value: queryParam.get("price") !== "null" ? queryParam.get("price") : "" },
        floor: { value: queryParam.get("floor") !== "null" ? queryParam.get("floor") : "" },
        skip: { value: queryParam.get("skip") !== "null" ? queryParam.get("skip") : "" }
    };

    const [advertisedAptms, setAdvertisedAptms] = useState([]);
    const [fetchingAptms, setFetchingAptms] = useState(false);
    const [formValues, setFormValues] = useState(initialValues);
    const [count, setCount] = useState(0);

    useEffect(() => fetchAdvertisedApartments(), [formValues]);

    const fetchAdvertisedApartments = () => {
        setFetchingAptms(true);

        const { skip, state, lga, price, floor } = formValues;

        let queryString = `/listing?state=${state.value}&lga=${lga.value}&price=${price.value}&floor=${floor.value}`;
        queryString += `&skip=${skip.value}&limit=${pageLimit}`;

        history.push(queryString);

        propertyServiceApi()
            .get("/apartment/advertised", {
                params: {
                    state: state.value || "Lagos",
                    lga: lga.value,
                    price: price.value,
                    floor: floor.value,
                    skip: skip.value,
                    limit: pageLimit
                }
            })
            .then((data) => {
                setCount(count + 1);
                setFetchingAptms(false);
                setAdvertisedAptms(data.data.payload);
            })
            .catch((error) => {
                setFetchingAptms(false);
                dispatch(
                    showNotifications(
                        `Error occurred while getting advertised apartments: ${error.response.data.error}`,
                        "error"
                    )
                );
            });
    };

    const fetchNextPage = (page) => {
        const skip = (page - 1) * pageLimit;
        setFormValues({ ...formValues, skip: { value: skip } });
    };

    const renderBody = () => {
        if (fetchingAptms) {
            return (
                <div style={{ height: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress size={50} className="icon" color="inherit" />
                </div>
            );
        } else {
            return (
                <div className="listing-flex-container">
                    {!advertisedAptms[0] && <p style={{ marginTop: "30vh" }}>No apartments matching your request</p>}
                    {advertisedAptms.map((apartment) => {
                        return <CustomCard key={apartment._id} data={apartment} />;
                    })}
                </div>
            );
        }
    };

    return (
        <div className={classes.wrapper}>
            <CustomFilters {...{ formValues, setFormValues }} />

            {renderBody()}

            <div style={{ display: "flex", justifyContent: "center", margin: "50px 0 30px" }}>
                <Pagination
                    count={30}
                    shape="rounded"
                    page={Number(formValues.skip.value / pageLimit) + 1 || 1}
                    onChange={(event, page) => fetchNextPage(page)}
                />
            </div>
        </div>
    );
};

export default Search;
