import CustomInputField from "#/components/ui-components/customInput.component";
import { TextField } from "@mui/material";
import { useField } from "formik";
import React from "react";

const FormikCustomInputField = ({ name, type, ...otherProps }) => {
    const [field, meta] = useField(name);
    // console.log("value", name, field)

    const configTextField = {
        handleOnBlur: field.onBlur,
        handleOnChange: field.onChange,
        name: field.name,
        value: field.value,
        ...otherProps
    };

    // check for errors
    if (meta && meta.touched && meta.error) {
        configTextField.errors = meta.error;
        // configTextField.helperText = meta.error;
    }
    // props for numbers
    // if (type && type === "number") {
    //     configTextField.inputProps = { inputMode: "numeric", pattern: "[0-9]*" };
    // }

    return <CustomInputField {...configTextField} size="small" />;
};

export default FormikCustomInputField;
