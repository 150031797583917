import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useField, useFormikContext } from "formik";
import { FormHelperText } from "@mui/material";

export default function BasicSelect({ name, label, options }) {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (event) => {
    const { value } = event.target;
    setFieldValue(name, value);
  };

  const configSelectField = {
    ...field,
    label,
    select: true,
    fullWidth: true,
    variant: "outlined",
    error: false,
    onChange: handleChange
  };
  let errorText = "";

  if (meta && meta.touched && meta.error) {
    configSelectField.error = true;
    errorText = meta.error;
  }
  return (
    <Box sx={{ minWidth: 250 }}>
      <FormControl fullWidth error={configSelectField.error}>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select {...configSelectField}>
          <MenuItem disabled value="">
            <em>Select an Option</em>
          </MenuItem>
          {options.map((option, index) => {
            return (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>{errorText}</FormHelperText>
      </FormControl>
    </Box>
  );
}
