import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import { useDispatch, useSelector, batch } from "react-redux";
import useWindowSize from "react-use/lib/useWindowSize";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { CircularProgress } from "@material-ui/core";
import { useQuery } from "react-query";
import ApplicationDetailsMenu from "./ApplicationDetailsMenu";
import ApplicantInformation from "./ApplicantInformation";
import BackComponent from "#/components/propertycomponents/back-component";
import Header from "./Header";
import ApplicationSummary from "./ApplicationSummary";
import { getAllApartmentBookings } from "#/store/actions/booking.actions";
import { getApartmentById, fetchApartmentById } from "#/store/actions/apartment.actions";
import { getApartmentCharges } from "#/store/actions/charges.actions";
import { getUserInfoFromLiveizyId } from "#/store/actions/auth.actions";
import {
  useApartmentDetails,
  useApartmentCharges
} from "#/store/serverStateHooks/useCacheApartmentData";

const styles = (theme) => ({
  root: {
    overflow: "auto"
  },

  applicantsList: {
    transition: "transform 100ms ease-in-out",

    "@media(max-width:768px)": {
      position: "absolute",
      width: "100%",
      zIndex: 10,
      top: "0px",
      left: "0px",
      backgroundColor: "transparent"
    }
  },

  applicantDetails: {
    transition: "transform 100ms ease-in-out",

    "@media(max-width:768px)": {
      position: "absolute",
      width: "100%",
      zIndex: 10,
      top: "0px",
      left: "0px",
      backgroundColor: "transparent"
    }
  }
});

const ApplicationDetails = (props) => {
  //---------------------------------------------------------------
  //OTHER HELPER HOOKS
  //---------------------------------------------------------------
  const dispatch = useDispatch();
  const history = useHistory();
  const windowSize = useWindowSize();
  const { path, url } = useRouteMatch();
  const user = useSelector((state) => state.auth.user);
  const tenantsRequests = useSelector((state) => state.booking.tenantsRequests);

  let inInnerPage = props.location.state;

  //---------------------------------------------------------------
  //STATE
  //---------------------------------------------------------------
  const [activeApplicant, setActiveApplicant] = useState(null);
  const { data: apartmentDetails } = useApartmentDetails(
    activeApplicant?.apartment_id || ""
  );
  const { data: apartmentCharges } = useApartmentCharges(
    activeApplicant?.apartment_id || ""
  );

  const handleSelectApplicant = (item) => {
    history.push({ pathname: `${url}/${item._id}`, state: item });
  };

  //---------------------------------------------------------------
  //SIDE EFFECTS
  //---------------------------------------------------------------
  useEffect(() => {
    if (props.location.state) {
      setActiveApplicant(props.location.state);
    }
  }, [props.location.state]);

  return (
    <div className={props.classes.wrapper}>
      {windowSize.width < 768 && (
        <div style={{ display: "inline-block", marginBottom: "25px" }}>
          <BackComponent redirectUrl="/property/application" />
        </div>
      )}
      <Header title="Applications" />
      <ApplicationSummary />
      <Card className={props.classes.root}>
        <Grid container style={{ position: "relative", minHeight: "100vh" }}>
          <Grid
            item
            xs={12}
            md={3}
            className={props.classes.applicantsList}
            style={{
              transform:
                windowSize.width < 768
                  ? inInnerPage
                    ? "translateX(-100vw)"
                    : "translateX(0px)"
                  : "translateX(0px)"
            }}>
            <ApplicationDetailsMenu
              tenantsRequests={tenantsRequests}
              handleSelectApplicant={handleSelectApplicant}
              activeApplicant={activeApplicant}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={9}
            className={props.classes.applicantDetails}
            style={{
              transform:
                windowSize.width < 768
                  ? inInnerPage
                    ? "translateX(0px)"
                    : "translateX(100vw)"
                  : "translateX(0px)"
            }}>
            {activeApplicant && (
              <ApplicantInformation
                applicantBookingInfo={activeApplicant}
                apartmentDetails={apartmentDetails}
                history={history}
                dispatch={dispatch}
                liveizyId={user.liveizy_id}
              />
            )}
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default withStyles(styles)(ApplicationDetails);
