import React from "react";
import "./izyinvestcontent.scss";
import { connect, useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getIzyInvestBalance, getIzyInvestHistory } from "../../../store/actions/izyinvest.actions";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Box, CircularProgress, makeStyles } from "@material-ui/core";

// image
import Walletizyvest from "../../../assets/images/wallet/walletizyvest.svg";
import Walletizyvest2 from "../../../assets/images/wallet/walletizyvest2.svg";
import Walletimg from "../../../assets/images/wallet/wallet.svg";
import Walletizyvest3 from "../../../assets/images/wallet/walletizyvest3.svg";
import Svgimg from "../../../assets/images/wallet/5240.svg";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "flex",
    alignItems: "center",
    marginRight: "2%",
    color: "#043B5C",
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      "& .MuiSvgIcon-root": {
        height: "19px !important",
      },
    },
    arrow: {
      "& .MuiSvgIcon-root": {
        height: "19px !important",
      },
      // [theme.breakpoints.down('xs')]: {
      //   '& .MuiSvgIcon-root': {
      //     height: '19px !important',
      //   },
      // },
    },
  },
}));

const Izyinvestcontent = ({ dashboard, activePlan, date }) => {
  const classes = useStyles();
  const izystore = useSelector((store) => store.izyinvest);
  const dispatch = useDispatch();
  const availableBalance = izystore.availableBalance;
  const contributoryBalance = izystore.contributoryBalance;
  const monthlyInterest = izystore.monthlyInterest;
  const closingBalance = izystore.closingBalance;
  const dailyInterest = izystore.dailyInterest;

  //   const getBalance = () => {
  //     dispatch(getIzyInvestBalance());
  // }
  // const getHistory = () => {
  //     dispatch(getIzyInvestHistory());
  // }
  let localDate = new Date(date);

  return (
    <React.Fragment>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div onClick={dashboard} style={{ cursor: "pointer", width: "55%" }}>
            <h6
              // className="head-color py-1"
              // style={{
              //   display: 'flex',
              //   alignItems: 'center',
              //   marginRight: '2%',
              //   color: '#043B5C',
              //   fontWeight: 700,
              // }}
              className={classes.title}
            >
              {/* <span className="small-i">i</span>zyinvest */}
              <ArrowBackIcon className={classes.arrow} />
              <Box display={{ xs: "none", sm: "block" }}>Back to</Box>&nbsp;Dashboard
            </h6>
          </div>
          <h6
            // className="head-color py-1"
            className={classes.title}
            // style={{
            //   display: 'flex',
            //   alignItems: 'center',
            //   marginRight: '2%',
            // }}
          >
            {activePlan}
          </h6>
        </div>
        <hr className="hrrr" />
        <div className="container-fluid">
          <div className="row mb-4">
            <div className="col-lg-12">
              <div className="card size">
                <div className="row" style={{marginLeft:'0'}}>
                  <div className="col-6 colmb">
                    <img className="card-img-top svg" src={Svgimg} alt="Card image" />
                  </div>
                  <div className="col-3 mb">
                    <div className="mt-5">
                      <p className="izyvest-lastfunded">Last Funded</p>
                      <button className="btn btn-secondary" style={{ fontSize: "12px" }}>
                        {localDate.toDateString()}
                      </button>
                    </div>
                  </div>
                  <div className="col-3 fl">
                    <div className="mt-5">
                      <p className="izyvest-text">izyInvest</p>
                      <h6 className="izyvest-money">
                        NGN {availableBalance === null ? <CircularProgress size={16} color="inherit" /> : availableBalance}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row secondrow" style={{ margin: "0 auto" }}>
            <div className="col-lg-6">
              <div className="card card-design">
                <div className="card-body">
                  <h6 className="text-color">Contributory Balance</h6>
                  <div className="text-color mb-3">
                    <img src={Walletizyvest} alt="izyinvest wallet" className="img-size" />₦{" "}
                    {contributoryBalance === null ? <CircularProgress size={16} color="inherit" /> : contributoryBalance}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card card-design">
                <div className="card-body">
                  <h6 className="text-color">Closing Balance</h6>
                  <div className="text-color mb-3">
                    <img src={Walletimg} alt="izyinvest wallet" className="img-size" />₦{" "}
                    {closingBalance === null ? <CircularProgress size={16} color="inherit" /> : closingBalance}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-6">
              <div className="card card-design">
                <div className="card-body">
                  <h6 className="text-color">Available Balance</h6>
                  <div className="text-color mb-3">
                    <img
                      src={Walletizyvest3}
                      alt="izyinvest wallet"
                      className="img-size"
                    />
                    ₦{' '}
                    {closingBalance === null ? (
                      <CircularProgress size={16} color="inherit" />
                    ) : (
                      closingBalance
                    )}
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  getIzyInvestBalance: state.izyinvest.getIzyInvestBalance,
  getIzyInvestHistory: state.izyinvest.getIzyInvestHistory,
});

export default connect(mapStateToProps, {
  getIzyInvestBalance,
  getIzyInvestHistory,
})(Izyinvestcontent);
