import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

export const TypographyText = withStyles({
  root: {
    color: "#043B5C",
    fontFamily: "inherit"
  },
  h5: {
    fontWeight: 600,
    fontSize: 18
  },
  h6: {
    fontWeight: 700,
    fontSize: 15
  },
  body2: {
    fontWeight: 600,
    fontSize: 13
  },
  subtitle1: {
    fontWeight: 200,
    fontSize: 13
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: 13
  },
  body1: {
    fontSize: 13
  },
  caption: {
    fontWeight: "bold"
  }
})(Typography);

// styling option 2

//  const THEME = createMuiTheme({
//   overrides: {
//     MuiTypography: {
//       root: {
//         color: '#043B5C',
//       },
//       h5: {
//         fontWeight: 600,
//         color:'red'
//       },
//       h6: {
//         fontWeight: 700,
//         fontSize: 16,
//       },
//       body2: {
//         fontWeight: 600,
//         fontSize:16,
//       },
//       subtitle1: {
//         fontWeight: 200,
//       },
//     },
//   },
// });

export const useStylesM = makeStyles((theme) => ({
  btnmargin: {
    margin: "9% 0 6% 0%"
  },
  izyPay: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    fontSize: "12px",
    padding: "3% 9%",
    marginRight: "7%",
    borderRadius: "6px",
    border: 0,
    color: "#032F4A",
    fontWeight: "bold",
    boxShadow: "1px 20px 30px #332A7C24",
    textTransform: "none"
  },
  izyVest: {
    backgroundColor: "#1461A2",
    fontSize: "12px",
    padding: "3% 9%",
    marginRight: "7%",
    borderRadius: "6px",
    fontWeight: "bold",
    color: "white",
    textTransform: "none"
  },
  logo: {
    position: "relative",
    float: "right",
    width: "7%",
    letf: "15% !important",
    bottom: 27,
    marginRight: "4%"
  },
  quoteText: {
    display: "flex",
    alignItems: "baseline"
  },
  hr1: {
    margin: "6% 0 7% 0",
    backgroundColor: "#043B5C57"
  },
  hr2: {
    border: "2px solid #10609F"
  },
  span: {
    width: "6%",
    marginRight: "2%"
  },
  hrPosition: {
    display: "flex",
    alignItems: "center"
  },
  container: {
    width: "85%",
    padding: "0 5%",
    backgroundColor: "#FFFFFF",
    height: "fit-content",
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 6%",
      width: "100%",
      marginBottom: "15%"
    }
  },
  blueDot: {
    backgroundColor: "rgb(20, 97, 162)",
    height: 7,
    width: 10,
    position: "relative",
    bottom: 1,
    right: 5
  },
  whatsapp: {},
  youtube: {}
}));
