import { makeStyles } from "@material-ui/core/styles";

export const headerStyles = makeStyles((theme) => ({
  header: {
    // marginBottom: "55px",
    // backgroundImage: `url(${HeaderImage})`,
    width: "100%",
    height: "113px",
    backgroundSize: "auto",
    backgroundPositionY: "30%",
    backgroundPositionX: "0%",
    backgroundRepeat: "no-repeat",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: "0",
    [theme.breakpoints.down("sm")]: {
      // width: "350px",
      backgroundPositionY: "40%",
      backgroundPositionX: "5%",
      marginBottom: "30px"
    },

    "& h1": {
      color: "#F4F5F7",
      fontSize: "30px",
      textAlign: "center",
      marginLeft: "50px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "21px"
      }
    }
  },
  img: {
    marginTop: "200px",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },

  btnSubmit: {
    backgroundColor: "#10609F",
    color: "#fff",
    textTransform: "none",
    borderRadius: "7px",
    border: "1px solid #4786FF",
    padding: "0px 30px",
    fontSize: "13px",
    fontFamily: "Open Sans, sans-serif",
    height: "40px",

    [theme.breakpoints.between("0", "730")]: {
      width: "100%"
    },

    "&:hover": {
      backgroundColor: "#4786FF",
      opacity: "0.5"
    }
  },

  btnSubmitNude: {
    backgroundColor: "#fff",
    color: "#10609F",
    textTransform: "none",
    borderRadius: "7px",
    border: "1px solid #4786FF",
    padding: "0px 30px",
    fontSize: "13px",
    fontFamily: "Open Sans, sans-serif",
    height: "40px",

    [theme.breakpoints.between("0", "730")]: {
      width: "100%"
    },

    "&:hover": {
      backgroundColor: "#4786FF",
      opacity: "0.5"
    }
  },
  header__title: {
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.03em",
    color: "#052137",
    marginBottom: "20px",

    [theme.breakpoints.between("0", "730")]: {
      width: "100%"
    }

    // "&:hover": {
    //     backgroundColor: "#4786FF",
    //     opacity: "0.5"
    // }
  }
}));
