import rentLoanFormModel from "./rentLoanFormModel";
const {
  formField: {
    profileImage,
    bvn,
    surname,
    firstName,
    otherNames,
    gender,
    dateOfBirth,
    stateOfOrigin,
    lga,
    email,
    alternateEmail,
    mobileNumber,
    alternateMobileNumber,
    meansOfId,
    meansOfIdFile,
    employmentStatus,
    nameOfEmployer,
    officeAddress,
    monthlyIncome,
    nameOfOfficeContactPerson,
    officeContactPersonNumber,
    accountStatementDoc,
    homeAddress,
    laid,
    rentRange,
    rentValue,
    nameOfManager,
    contactOfManager,
    nameOfLandlord,
    contactOfLandlord,
    contactOfNextOfKin,
    contactOfreference,
    emailOfNextOfKin,
    nameOfNextOfKin,
    nameOfReference,
    nextOfKinHomeAddress
  }
} = rentLoanFormModel;

export default function (user, otherInformation, tenantDetails) {
  const { record, landlord, manager } = tenantDetails;
  const apartmentAddress = record
    ? `${record?.apartment_id?.house_number}, ${record?.apartment_id?.street}, ${record?.apartment_id?.other_address}, ${record?.apartment_id?.lga}, ${record?.apartment_id?.state}, ${record?.apartment_id?.country}`
    : null;

  const initialValues = {
    [profileImage.name]: user?.profile_image || null,
    [bvn.name]: user?.bvn || "",
    [surname.name]: user?.last_name || "",
    [firstName.name]: user?.first_name || "",
    [otherNames.name]: user?.other_name || "",
    [gender.name]: user?.gender || "",
    [dateOfBirth.name]: user?.date_of_birth || "",
    [stateOfOrigin.name]: user?.state_of_origin || "",
    [lga.name]: user?.local_governement_area || "",
    [email.name]: user?.email || "",
    [alternateEmail.name]: user?.alt_email || "",
    [mobileNumber.name]: user?.phone || "",
    [alternateMobileNumber.name]: user?.alt_phone || "",
    [meansOfId.name]: otherInformation?.means_of_id || "",
    [meansOfIdFile.name]: otherInformation?.means_of_id_file || null,
    [employmentStatus.name]: otherInformation?.employment_status || "",
    [nameOfEmployer.name]: otherInformation?.employer_name || "",
    [officeAddress.name]: otherInformation?.office_address || "",
    [monthlyIncome.name]: otherInformation?.monthly_income || "",
    [nameOfOfficeContactPerson.name]: otherInformation?.office_contact_person_name || "",
    [officeContactPersonNumber.name]:
      otherInformation?.office_contact_person_phone_number || "",
    [accountStatementDoc.name]: otherInformation?.account_statement_doc || null,
    [homeAddress.name]: apartmentAddress || "",
    [laid.name]: record?.apartment_id?.apartment_id || null,
    [rentValue.name]: record?.apartment_id?.price || "",
    [rentRange.name]: "",
    [nameOfManager.name]: manager ? `${manager?.first_name} ${manager?.last_name}` : "",
    [contactOfManager.name]: manager?.phone1 || "",
    [nameOfLandlord.name]: landlord
      ? `${landlord?.first_name} ${landlord?.last_name}`
      : "",
    [contactOfLandlord.name]: landlord?.phone1 || "",
    [contactOfNextOfKin.name]: otherInformation?.contact_of_next_kin || "",
    [contactOfreference.name]: otherInformation?.contact_of_reference || "",
    [emailOfNextOfKin.name]: otherInformation?.email_of_next_of_kin || "",
    [nameOfNextOfKin.name]: otherInformation?.name_of_next_of_kin || "",
    [nameOfReference.name]: otherInformation?.name_of_reference || "",
    [nextOfKinHomeAddress.name]: otherInformation?.next_of_kin_home_address || ""
  };

  return initialValues;
}
