import { makeStyles, withStyles } from "@material-ui/core/styles";
import StepConnector from "@material-ui/core/StepConnector";

export const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 40,
        left: "calc(-50% + 41px)"
    },
    active: {
        "& $line": {
            backgroundColor: "#043B5C7F"
        }
    },
    completed: {
        "& $line": {
            backgroundColor: "#043B5C7F"
        }
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: "#eaeaf0",
        borderRadius: 1
    }
})(StepConnector);

export const useStepperStyles = makeStyles((theme) => ({
    root: {
        width: "100%",

        "& .MuiStepper-root": {
            backgroundColor: "#6EB5DF72",
            height: "98px",
            padding: 0,

            "& .MuiStep-root": {
                transform: "translateY(58px)",

                "&:first-child": {
                    overflowX: "hidden"
                }
            }
        }
    },
    button: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

export const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: "#fff",
        zIndex: 1,
        color: "#fff",
        width: 81,
        height: 81,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0px 3px 6px #DCF2FF",

        "& img[alt=apartment]": {
            transform: "translateX(3px)"
        }
    },
    active: {
        border: "3px solid #043B5C7F",
        boxShadow: "none"
    },
    completed: {
        border: "3px solid #043B5C7F",
        boxShadow: "none"
    }
});
