import { useQuery, useQueries } from "react-query";
import { fetchApartmentById } from "#/store/actions/apartment.actions";
import { fetchApartmentCharges } from "../actions/charges.actions";
import { fetchApartmentRentRecords, fetchChargeRecords } from "../actions/records.actions";

//To get the partments general details
export const useApartmentDetails = (apartmentId) => {
    const { data, isLoading } = useQuery(["apartment", { id: apartmentId }], () => fetchApartmentById(apartmentId), {
        staleTime: 1 * 1000,
        enabled: !!apartmentId
    });

    return {
        data,
        isLoading
    };
};

export const useApartmentCharges = (apartmentId) => {
    const { data, isLoading } = useQuery(
        ["apartment_charges", { id: apartmentId }],
        () => fetchApartmentCharges(apartmentId),
        {
            staleTime: 1 * 1000,
            enabled: !!apartmentId
        }
    );

    return {
        data,
        isLoading
    };
};

export const useApartmentRentRecords = (apartmentId) => {
    const { data, isLoading } = useQuery(
        ["apartment_rent-records", { id: apartmentId }],
        () => fetchApartmentRentRecords(apartmentId),
        {
            staleTime: 1 * 1000,
            enabled: !!apartmentId
        }
    );

    return {
        data,
        isLoading
    };
};

export const useApartmentChargesRecords = (liveizyId, charges) => {
    const res = useQueries(
        charges.map((charge) => {
            return {
                queryKey: ["charge-record", { id: charge._id }],
                queryFn: () => fetchChargeRecords(liveizyId, charge._id),
                staleTime: 1 * 1000,
                enabled: !!charge._id
            };
        })
    );

    let data = [];
    let loadingStates = [];

    res.forEach((item) => {
        data.push(item.data);
        loadingStates.push(item.isLoading);
    });

    //If at least one truthy value exists, then we resolve to true
    const isLoading = loadingStates.find((item) => {
        return item === true;
    });

    return {
        data,
        isLoading
    };
};
