export const setToLocalStorage = (key, data) => {
  localStorage.setItem(key, data);
};

export const getFromLocalStorage = (key) => {
  const data = localStorage.getItem(key);
  return data;
};

//Depreciated
export const numberWithCommas = (x) => {
  // return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// convert string to number
export const stringToNumberMoney = (x) => {
  return Number(x.replace(/[^0-9\.-]+/g, ""));
};

//Format value with comma and add Naira sign
export const toCurrency = (number) => {
  const formatter = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN"
  });

  return formatter.format(number);
};

//Capitalize first letter in a text
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

//
export const initialsFirstNameLastName = (string) => {
  const initials = string
    .split(" ")
    .map((x) => x.charAt(0))
    .join("")
    .substr(0, 2)
    .toUpperCase();
  return initials;
};

//------------------------------------------------------------
//Convert GMT to AM or PM
//------------------------------------------------------------
export const convertGmtToAmOrPm = (gmtTime) => {
  const hourGmt = gmtTime.substring(0, 2);
  const secondsGmt = gmtTime.substring(3);

  if (hourGmt > 12) {
    const hourHand = hourGmt - 12;
    return `${hourHand}:${secondsGmt}pm`;
  } else if (gmtTime[0] === "0") {
    //Trim off leading Zero i.e 07:45am to 7:45am
    return `${gmtTime.substring(1)}am`;
  } else {
    return `${gmtTime}am`;
  }
};

//------------------------------------------------------------
//Convert AM or PM tp GMT
//------------------------------------------------------------
export const convertAmOrPmToGmt = (ampmTime) => {
  const amOrPm = ampmTime.slice(-2);

  if (amOrPm === "am") {
    let a = ampmTime.substring(0, ampmTime.length - 2);
    if (a.length === 4) {
      a = `0${a}`;
    }
    return a;
  } else if (amOrPm === "pm") {
    let a = ampmTime.substring(0, ampmTime.length - 2); //extract pm sign
    a = a.split(":");
    let b = parseInt(a[0]) + 12;
    let c = `${b}:${a[1]}`;
    return c;
  }
};

//------------------------------------------------------------
//Check if email is vaid
//------------------------------------------------------------
export const handleCheckEmailValidity = (value) =>
  new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(value);

//------------------------------------------------------------
//Get Due Date
//------------------------------------------------------------

export const getChargeDueDate = (updatedAt, paymentTerm) => {
  switch (paymentTerm) {
    case "once":
      return;
    case "monthly":
      return;
    case "quarterly":
      return;
    case "bi-annual":
      return;
    case "annually":
      return;
  }
};

//
export const calculatePercent = (percent, num) => {
  return (percent / 100) * num;
};

export const getDisplayedStatus = (status) => {
  return status === "pending" ? "Booked" : status;
};

//------------------------------------------------------------
//Add leading zero to number if less than 10 and return else return number
//------------------------------------------------------------

export function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

//------------------------------------------------------------
//Convert Date to calendar format
//------------------------------------------------------------

export function toCalenderDate(rawDate) {
  let date = new Date(rawDate);
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate())
  ].join("-");
}

// Nigerian phone number regex pattern
export const nigerianPhoneNumberPattern = /^(\+234|234|0){1}(7|8|9){1}(0|1){1}[0-9]{8}$/;

// Regex function to validate nigerian phone number
export function validateNigerianPhone(phoneNumber) {
  return nigerianPhoneNumberPattern.test(phoneNumber);
}

// format phonenumber to +234phonenumber
export function formatNigerianPhoneNumber(phoneNumber) {
  if (!phoneNumber) return null;
  if (/^(\+234|234|0){1}(7|8|9){1}(0|1){1}[0-9]{8}$/.test(phoneNumber)) {
    if (/^(234){1}(7|8|9){1}(0|1){1}[0-9]{8}$/.test(phoneNumber)) {
      return `+${phoneNumber}`;
    } else if (/^(0){1}(7|8|9){1}(0|1){1}[0-9]{8}$/.test(phoneNumber)) {
      return `+234${parseInt(phoneNumber)}`;
    } else {
      return phoneNumber;
    }
  } else {
    return alert("Invalid Nigerian Phone Number Format");
  }
}
