import { showNotifications } from "#/store/actions/notification.actions";
import * as actionTypes from "../constants";
import { propertyServiceApi } from "#/apis/propertyServiceApi";

const { CREATE_SCHEDULE, DELETE_SCHEDULE, UPDATE_SCHEDULE, FECTH_SCHEDULE } = actionTypes.SCHEDULES_CONSTANTS;

//------------------------------------------------------------------
//Creating Schedules
//------------------------------------------------------------------
export const createSchedules = (payload, cb) => async (dispatch) => {
    dispatch({ type: CREATE_SCHEDULE, payload: { loading: true, creating: true } });

    try {
        const response = await propertyServiceApi().post("/schedules", payload);
        const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = response.data.payload;
        const scheduleDays = { monday, tuesday, wednesday, thursday, friday, saturday, sunday };
        const scheduleExists = Object.values(scheduleDays).find((item) => item.length);
        dispatch({
            type: CREATE_SCHEDULE,
            payload: {
                apartmentSchedules: scheduleExists ? scheduleDays : null,
                creating: false,
                loading: false
            }
        });
        dispatch(showNotifications("Schedule created successfully", "success"));
        cb();
    } catch (error) {
        dispatch({ type: CREATE_SCHEDULE, payload: { loading: false, creating: true } });
        dispatch(showNotifications(`${error.response.data.error}`, "error"));
    }
};

//------------------------------------------------------------------
//Get Apartment Schedules
//------------------------------------------------------------------
export const getApartmentSchedules = (id) => async (dispatch) => {
    dispatch({ type: FECTH_SCHEDULE, payload: { loading: true } });

    try {
        const response = await propertyServiceApi().get(`/schedules/${id}`);
        const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = response.data.payload;
        const scheduleDays = { monday, tuesday, wednesday, thursday, friday, saturday, sunday };
        const scheduleExists = Object.values(scheduleDays).find((item) => item.length);
        dispatch({
            type: FECTH_SCHEDULE,
            payload: {
                apartmentSchedules: scheduleExists ? scheduleDays : null,
                loading: false
            }
        });
    } catch (error) {
        dispatch({ type: FECTH_SCHEDULE, payload: { loading: false } });
    }
};

//------------------------------------------------------------------
//Update Apartment Schedules
//------------------------------------------------------------------
export const updateApartmentSchedule = (payload, cb) => async (dispatch) => {
    const { apartment_id, day, period } = payload;
    dispatch({ type: UPDATE_SCHEDULE, payload: { updating: true, loading: true } });

    try {
        const response = await propertyServiceApi().put(`/schedules/${apartment_id}/${day}`, { period });
        const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = response.data.payload;
        const scheduleDays = { monday, tuesday, wednesday, thursday, friday, saturday, sunday };
        const scheduleExists = Object.values(scheduleDays).find((item) => item.length);
        dispatch(showNotifications("Updated schedule successfully!!!", "success"));
        dispatch({
            type: UPDATE_SCHEDULE,
            payload: {
                apartmentSchedules: scheduleExists ? scheduleDays : null,
                updating: false,
                loading: false
            }
        });
        cb();
    } catch (error) {
        dispatch({ type: UPDATE_SCHEDULE, payload: { updating: false, loading: false } });
        dispatch(showNotifications(`${error.response.data.payload}`, "error"));
    }
};

//------------------------------------------------------------------
//Delete Apartment Schedules
//------------------------------------------------------------------
export const deleteApartmentSchedule = (payload, cb) => async (dispatch) => {
    const { apartment_id, day } = payload;
    dispatch({
        type: DELETE_SCHEDULE,
        payload: {
            deleting: true,
            loading: true
        }
    });

    try {
        const response = await propertyServiceApi().delete(`/schedules/${apartment_id}/${day}`);
        const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = response.data.payload;
        const scheduleDays = { monday, tuesday, wednesday, thursday, friday, saturday, sunday };
        const scheduleExists = Object.values(scheduleDays).find((item) => item.length);
        dispatch({
            type: DELETE_SCHEDULE,
            payload: {
                apartmentSchedules: scheduleExists ? scheduleDays : null,
                deleting: false,
                loading: false
            }
        });
        dispatch(showNotifications("Schedule deleted successfully", "success"));
        cb();
    } catch (error) {
        dispatch(showNotifications(error.response?.data?.error, "error"));
        dispatch({ type: DELETE_SCHEDULE, payload: { deleting: false, loading: false } });
    }
};
