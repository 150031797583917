import SubHeadingUnderline from "#/association/components/SubHeadingUnderline";
import CustomInputAndLabelField from "#/components/customInputFields/CustomInputAndLabelField";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box, Button, Checkbox, IconButton, styled } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function Members() {
  return (
    <>
      <Box
        display={"flex"}
        margin={"0 auto"}
        maxWidth="250px"
        flexDirection="column"
        justifyContent={"center"}
        alignItems="center">
        <CustomInputAndLabelField
          label="ID"
          type="text"
          placeholder="Member's Liveizy ID"
        />
        <CustomInputAndLabelField label="Name" type="text" placeholder="Member's Name" />
        <CustomInputAndLabelField label="Role" type="text" placeholder="Member's Role" />
        <AddMemberBtn>Add Member</AddMemberBtn>
      </Box>

      <Box
        color={"#052137"}
        fontSize="14px"
        maxWidth={"80%"}
        margin={"0 auto"}
        marginTop="30px">
        <SubHeadingUnderline text="List of Members" icon={<DeleteOutlineIcon />} />
        <ListContainer>
          <ListItem>
            <div>
              <Checkbox
                // checked={checked}
                // onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
            <div>
              <p>Wale Adekunke</p>
            </div>
            <div>
              <p>9, Mosholashi</p>
            </div>
            <div>
              <p>Landlord</p>
            </div>
            <div>
              <Link to={`/association/members/UyTR2018`}>
                <ViewMoreBtn>View More</ViewMoreBtn>
              </Link>
            </div>
          </ListItem>
          <ListItem>
            <div>
              <Checkbox
                // checked={checked}
                // onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
            <div>
              <p>Jaachimma Onyenze</p>
            </div>
            <div>
              <p>9, Long Road, Avenue</p>
            </div>
            <div>
              <p>Landlord</p>
            </div>
            <div>
              <Link to={`/association/members/UyTR2018`}>
                <ViewMoreBtn>View More</ViewMoreBtn>
              </Link>
            </div>
          </ListItem>
          <ListItem>
            <div>
              <Checkbox
                // checked={checked}
                // onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
            <div>
              <p>James Kunle</p>
            </div>
            <div>
              <p>109A, Victoria Island, Main street, Lagos</p>
            </div>
            <div>
              <p>Landlord</p>
            </div>
            <div>
              <Link to={`/association/members/UyTR2018`}>
                <ViewMoreBtn>View More</ViewMoreBtn>
              </Link>
            </div>
          </ListItem>
        </ListContainer>
      </Box>
    </>
  );
}

const AddMemberBtn = styled(Button)`
  background: #10609f;
  color: white;

  :hover {
    background: #0d4a79;
    opacity: 1;
    box-shadow: inset;
  }
`;

const ListContainer = styled("div")`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 8px;
  margin-top: 20px;
  max-height: 400px;
`;

const ListItem = styled("div")`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid black;
  gap: 15px;
  width: 100%;

  div:nth-of-type(1) {
    flex: 1;
  }
  div:nth-of-type(2) {
    font-weight: 500;
    flex: 3;
  }
  div:nth-of-type(3) {
    flex: 4;
  }
  div:nth-of-type(4) {
    flex: 3;
  }
  div:nth-of-type(5) {
    flex: 3;
    text-align: center;
  }
  :last-child {
    border-bottom: none;
  }
`;

const ViewMoreBtn = styled(Button)`
  color: #10609f;
  border: 2px solid #10609f;
  text-transform: capitalize;
  font-size: 14px;

  :hover {
    background: #0d4a79;
    opacity: 1;
    box-shadow: inset;
    color: white;
  }
`;
