export const required = (value) => {
    const regexp = /.+/gi;

    if (regexp.test(value)) return { value, error: "" };
    else return { value, error: "this field is required" };
};

export const name = (value) => {
    value = value.trim().toLowerCase();
    const regexp = /^([a-z]+-?[a-z]+){1,255}$/gi;

    if (regexp.test(value)) return { value, error: "" };
    else return { value, error: "invalid input" };
};

export const text = (value) => {
    value = value.replace(/[^a-z,\s-]/gi, "");
    const regexp = /^[a-z]+/gi;

    if (regexp.test(value)) return { value, error: "" };
    else return { value, error: "invalid input" };
};

export const address = (value) => {
    value = value.replace(/[^a-z,\s-\d\.]/gi, "");
    const regexp = /^[a-z]+/gi;

    if (regexp.test(value)) return { value, error: "" };
    else return { value, error: "invalid input" };
};

export const email = (value) => {
    value = value.trim().toLowerCase();
    const regexp = /^\w[\w\.-]+@[a-z]+\..+$/gi;

    if (regexp.test(value)) return { value, error: "" };
    else return { value, error: "invalid email" };
};

export const numbersOnly = (value) => {};

export const nuban = (value) => {
    value = value.replace(/[^\d]/gi, "").slice(0, 10);
    const regexp = /^\d{10}$/gi;

    if (regexp.test(value)) return { value, error: "" };
    else return { value, error: "invalid bank account" };
};

export const liveizyId = (value) => {
    value = value.replace(/[^0-9a-z]/gi, "").slice(0, 8);
    const regexp = /^[a-z0-9]{8}$/gi;

    if (regexp.test(value)) return { value, error: "" };
    else return { value, error: "invalid Liveizy ID" };
};
