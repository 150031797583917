import React, { useEffect, useRef, useState } from "react";
import avatar from "#/assets/propertyPage/avatar.png";

import { styled } from "@mui/material";
import { BsFillCameraFill } from "react-icons/bs";
import { useField, useFormikContext } from "formik";

const Wrapper = styled("div")`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 15px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const UploadButton = styled("button")`
    background: white;
    position: absolute;
    bottom: -2%;
    right: 17%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 5px;
    font-size: 18px;
    border-radius: 50%;
`;

const ProfilePicUpload = ({ src, alt, name }) => {
    const [field, meta] = useField(name);
    const { setFieldValue, touched, setTouched, values } = useFormikContext();

    const fileInputRef = useRef();
    const [image, setImage] = useState({
        file: null,
        preview: avatar
    });

    useEffect(() => {
        if (src) {
            setImage((prevState) => {
                return {
                    ...prevState,
                    file: src,
                    preview: src
                };
            });
        }
    }, []);

    const handleTriggerImgSelection = (e) => {
        e.preventDefault();
        setTouched({ ...touched, [name]: true });
        fileInputRef.current.click();
    };

    const handleSelectImage = (e) => {
        const file = e.target.files[0];
        //If user did not select any file, get out of function
        if (file === undefined) return;
        setFieldValue(name, file);

        setImage((prevState) => {
            return {
                ...prevState,
                file: file
            };
        });

        const reader = new FileReader();

        reader.onload = function () {
            setImage((prevState) => {
                return {
                    ...prevState,
                    preview: reader.result
                };
            });
        };

        reader.readAsDataURL(file);
    };

    return (
        <div>
            <div style={{ position: "relative", width: "110px", height: "110px", margin: "25px" }}>
                <Wrapper>
                    <img src={image.preview} alt={alt} />
                </Wrapper>
                <input
                    name={name}
                    hidden
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    ref={fileInputRef}
                    onChange={handleSelectImage}
                />
                <UploadButton onClick={handleTriggerImgSelection}>
                    <BsFillCameraFill />
                </UploadButton>
            </div>
            {meta && meta.touched && meta.error && <span style={{ color: "red", fontSize: "14px" }}>{meta.error}</span>}
        </div>
    );
};

export default ProfilePicUpload;
