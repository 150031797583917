import React from "react";
import { useState } from "react";
import Button from "#/components/ui-components/button.component";
import MonthlyRentCalculator from "../../../modal/calculators-monthlyRent/MonthlyRentCalculator";
import { headerStyles } from "#/pages/Dashboard/TenantDashboard/partials/styles/styles";
import { Grid } from "@mui/material";

const ShowCalculator = ({ view, isTenant, tenantRecords, monthlyRentCalculation }) => {
  const classes = headerStyles();
  const [showModal, setShowModal] = useState(false);
  // const [showRentSavingsModal, setShowRentSavingsModal] = useState(false);

  const handleSetModalVisibility = () => {
    setShowModal(!showModal);
  };

  // const handleShowRentSavings = () => {
  //   setShowRentSavingsModal(!showRentSavingsModal);
  // };
  return (
    <>
      <section className={`${isTenant ? `show-calculator tenant` : `show-calculator`}`}>
        <div>
          {/* <h1 className={classes.header__title}>Hi {`${first_name} ${last_name}`}</h1> */}
          <p className={classes.header__title}>
            You can calculate your monthly rent payment easily.
          </p>
        </div>
        {/* <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "20px" }}> */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Button
              buttonText="CALCULATE MONTHLY RENT"
              onClick={handleSetModalVisibility}
              className={classes.btnSubmitNude}
              // disabled={!rentPaymentWindow && !validCharges[0]}
            />
          </Grid>
          {/* CREATE RENT SAVINGS BUTTON */}
          {/* {isTenant && (
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end" }}
              sm={6}>
              <Button
                buttonText="CREATE RENT SAVINGS"
                onClick={handleShowRentSavings}
                className={classes.btnSubmit}
              // disabled={!rentPaymentWindow && !validCharges[0]}
              />
            </Grid>
          )} */}

          {/* {!isTenant && (
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end" }}
              sm={6}>
              <Button
                buttonText="START MONTHLY RENT PRE-QUALIFICATION"
                onClick={() => history.push("/dashboard/rent-loan-form")}
                className={classes.btnSubmit}
                // disabled={!rentPaymentWindow && !validCharges[0]}
              />
            </Grid>
          )} */}
        </Grid>

        {/* </div> */}
      </section>
      {/* Tenants Calculator Modal
      {showModal && isTenant && (
        <CalculateMonthlyRentModalTenant
          monthlyRentCalculation={monthlyRentCalculation}
          handleSetModalVisibility={handleSetModalVisibility}
        />
      )} */}
      {/* Non Tenants Calculator Modal */}
      {showModal && (
        <MonthlyRentCalculator handleSetModalVisibility={handleSetModalVisibility} />
      )}
      {/* {showRentSavingsModal && isTenant && (
        <RentSavingsModal
          tenantRecords={tenantRecords}
          handleShowRentSavings={handleShowRentSavings}
        />
      )} */}
    </>
  );
};

export default ShowCalculator;
