import React from "react";

import { styled } from "@mui/material";
// import SubtitleAndSubtext from "../others/SubtitleAndSubtext";
// import DetailsCardContainer, { Details } from "./DetailsCardContainer";
import DetailsCardContainer, {
  DetailsContainer
} from "#/components/cards/DetailsCardContainer.component";
import SubtitleAndSubtext from "#/components/ui-components/SubtitleAndSubtext.component";
import { toCurrency } from "#/components/utils/util";

const CurrentResidentDetails = ({
  homeAddress,
  rentValue,
  nameOfManager,
  rentRange,
  contactOfManager,
  nameOfLandlord,
  laid,
  contactOfLandlord
}) => {
  return (
    <DetailsCardContainer>
      <DetailsContainer>
        {laid === null ? (
          <SubtitleAndSubtext subtitle={"Rent Range"} subtext={rentRange} />
        ) : (
          <>
            <SubtitleAndSubtext subtitle={"Apartment Id"} subtext={laid} />
            <SubtitleAndSubtext subtitle={"Current Home Address"} subtext={homeAddress} />
            <SubtitleAndSubtext subtitle={"Rent Value"} subtext={toCurrency(rentValue)} />

            <SubtitleAndSubtext subtitle={"Name of Manager"} subtext={nameOfManager} />
            <SubtitleAndSubtext
              subtitle={"Contact of Manager"}
              subtext={contactOfManager}
            />

            <SubtitleAndSubtext subtitle={"Name of Landlord"} subtext={nameOfLandlord} />
            {/* <SubtitleAndSubtext subtitle={"Property ID"} subtext={propertyID} /> */}

            <SubtitleAndSubtext
              subtitle={"Contact of Landlord"}
              subtext={contactOfLandlord}
            />
          </>
        )}
        {/* <SubtitleAndSubtext subtitle={"Apartment"} subtext={apartment} /> */}
      </DetailsContainer>
    </DetailsCardContainer>
  );
};

export default CurrentResidentDetails;
