import {
  CLEAR_NOTIFICATIONS,
  SHOW_NOTIFICATIONS,
  LOGOUT,
  alertConstants,
  SET_VALIDATION_ERRORS,
  CLEAR_VALIDATION_ERRORS
} from "../constants";

const initialState = {
  messages: [],
  msg: "",
  status: "",
  validationErrors: {}
};

// Feedback Reducer
const notifications = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_NOTIFICATIONS:
      return {
        ...state,
        messages: initialState.messages.concat({
          message: action.message,
          id: action.id,
          messageType: action.messageType,
          timeout: action.timeout
        })
      };

    case CLEAR_NOTIFICATIONS:
      return {
        // ...state,
        // messages: state.messages.filter((error) => error.id !== action.id),
      };
    case alertConstants.SUCCESS:
      return {
        status: alertConstants.SUCCESS,
        msg: action.msg
      };
    case alertConstants.ERROR:
      return {
        status: alertConstants.ERROR,
        msg: action.msg,
        status_code: action.status_code
      };
    case alertConstants.CLEAR:
      return {};
    case LOGOUT:
      return initialState;

    case SET_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: action.validationErrors
      };
    case CLEAR_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: {}
      };
    default:
      return state;
  }
};

export default notifications;
