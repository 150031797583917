import { styled } from "@mui/material";
import React from "react";

const SubHeader = ({ title, component = null }) => {
  return (
    <Container>
      <p>{title}</p>
      <>{component}</>
    </Container>
  );
};

export default SubHeader;

const Container = styled("div")`
  width: 100%;
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #05213780;
  @media (max-width: 600px) {
    border-bottom: none;
    padding: 0 0;
  }

  p {
    font-size: 1.2rem;
    font-weight: bold;
    color: #052137;
  }
`;
