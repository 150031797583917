import { combineReducers } from "redux";
import authReducer from "./authReducer";
import notificationReducer from "./notificationReducer";
import izypayReducer from "./izypayReducer";
import referralReducer from "./referralReducer";
import izyinvestReducer from "./izyinvestReducer";
import paystackReducer from "./paystackReducer";
import roleReducer from "./roleReducer";
import propertyReducer from "./propertyReducer";
import apartmentReducer from "./apartmentReducer";
import chargesReducer from "./chargesReducer";
import schedulesReducer from "./schedulesReducer";
import tenantReducer from "./tenantReducer";
import reminderReducer from "./reminderReducer";
import bookingReducer from "./bookingReducer";
import planReducer from "./planReducer";
import paymentsReducer from "./paymentsReducer";
import estateMembersReducer from "./estateReducer";

export default combineReducers({
    auth: authReducer,
    notifications: notificationReducer,
    izypay: izypayReducer,
    referral: referralReducer,
    izyinvest: izyinvestReducer,
    paystack: paystackReducer,
    roles: roleReducer,
    property: propertyReducer,
    apartment: apartmentReducer,
    charges: chargesReducer,
    schedules: schedulesReducer,
    tenant: tenantReducer,
    reminder: reminderReducer,
    booking: bookingReducer,
    plans: planReducer,
    payments: paymentsReducer,
    estateMembers: estateMembersReducer
});
