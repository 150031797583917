import { Box, styled } from "@mui/material";
import React from "react";
import BankDetailsInput from "../BankDetailsform/BankDetailsInput";
import TitleUnderline from "#/components/ui-components/TitleUnderline.component";
import BankAccountList from "../bankAccountList/BankAccountList";

export default function PManagerSection({
  pManagerDetails,
  setPManagerDetails,
  pManagerBankDetails,
  setPManagerBankDetails
}) {
  return (
    <section style={{ minHeight: "80vh" }}>
      <Box>
        <SectionTitle>
          Landlord(s) Bank Details <span>(As a Property Manager)</span>
        </SectionTitle>
        <Box>
          <BankDetailsInput role={"agent"} setState={setPManagerDetails} />
        </Box>
        <Box>
          <TitleUnderline title={"Bank Accounts List"} />
          {pManagerBankDetails?.length >= 1 ? (
            <BankAccountList
              bankAccountList={pManagerBankDetails}
              role={"manager"}
              setRoleState={setPManagerDetails}
            />
          ) : (
            <em>You have not submitted any bank details yet.</em>
          )}
        </Box>
      </Box>
    </section>
  );
}

const SectionTitle = styled("p")`
  color: #10609f;
  font-weight: bold;
`;
