import React from "react";
import { usePropertyDetailsStyles } from "../propertyDetails.style";

import Images from "./images.component";
import Details from "./details.component";

const Property = () => {
    const classes = usePropertyDetailsStyles();
    return (
        <div className={classes.innerListingContainer}>
            <Images />
            <Details />
        </div>
    );
};

export default Property;
