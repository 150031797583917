import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    inputField: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginBottom: "30px",

        "& label": {
            marginBottom: "10px",
            fontSize: "14px",
            fontWeight: "700",
            whiteSpace: "nowrap",
            color: "#043B5C",
            fontFamily: "Open Sans-Semibold"
        },

        "& .input-wrapper": {
            width: "100%",
            position: "relative",
            cursor: "pointer",

            "& input": {
                borderRadius: "5px",
                border: "1px solid #043B5C69",
                padding: "10px 25px",
                width: "100%",
                backgroundColor: "#fff",
                cursor: "pointer",
                outline: "none"
            },

            "&__dropdown": {
                position: "absolute",
                width: "6px",
                height: "6px",
                right: "30px",
                top: "50%",
                transform: "translateY(-50%) rotate(45deg)",
                borderRight: "1px solid #043B5C",
                borderBottom: "1px solid #043B5C"
            },

            "&__options": {
                position: "absolute",
                listStyle: "none",
                width: "100%",
                backgroundColor: "#F4F5F7",
                transition: "all 200ms ease-in-out",
                maxHeight: (showDropDown) => (showDropDown === true ? "300px" : "0"),
                overflow: (showDropDown) => (showDropDown === true ? "auto" : "hidden"),
                zIndex: 10,
                paddingLeft: "0px",

                "& li": {
                    padding: "10px",

                    "&:hover": {
                        backgroundColor: "#6EB5DF72",
                        color: "#fff"
                    }
                }
            }
        }
    },
    errorMessage: {
        color: "red",
        fontSize: "14px",
        marginTop: "5px"
    }
}));
