import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { propertyServiceApi } from "#/apis/propertyServiceApi";
import { useDispatch } from "react-redux";
import { alertError } from "#/store/actions/notification.actions";
import { CircularProgress } from "@material-ui/core";

const RoleAccordion = ({ role, permissions }) => {
    const classes = styles();
    const dispatch = useDispatch();

    const [expanded, setExpanded] = useState(false);
    const [selected, setSelected] = useState({});
    const [checkBoxLoading, setCheckBoxLoading] = useState({});

    useEffect(() => {
        const temp = {};
        if (!role?.permissions.length) return;
        role.permissions.forEach((el) => {
            temp[el._id || el] = true;
        });
        setSelected({ ...selected, ...temp });
    }, [role]);

    const handleChange = (trueFalse) => () => {
        setExpanded(trueFalse);
    };

    const handleOnPermChange = (id) => {
        const payload = { permissions: [id] };
        const endpoint = selected[id] ? "/remove-permission" : "/add-permission";
        setCheckBoxLoading({ ...checkBoxLoading, [id]: true });
        propertyServiceApi()
            .patch(`/estate-management/role/${role._id}${endpoint}`, payload)
            .then(() => {
                setSelected({ ...selected, [id]: !selected[id] });
            })
            .catch(({ message, response }) => {
                dispatch(alertError(response?.data.error || message));
            })
            .finally(() => {
                setCheckBoxLoading({ ...checkBoxLoading, [id]: false });
            });
    };

    return (
        <Accordion
            className={classes.accordion}
            key={role?.title}
            expanded={expanded}
            onChange={handleChange(!expanded)}>
            <AccordionSummary aria-controls={`${role?.title}-content`} id={`${role?.title}-header`}>
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    className="accordion-role-div">
                    <input
                        type="checkbox"
                        id={`custom-checkbox-${role?.title}`}
                        name="Select All"
                        onChange={(e) => {
                            e.stopPropagation();
                        }}
                    />
                    <Typography>{role.title}</Typography>
                </div>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
                {/*<div>
                    <input
                        type="checkbox"
                        id={`custom-checkbox-selectall-${role.title}`}
                        name="Select All"
                        value={null}
                        checked={allSelected}
                        onChange={() => setAllSelected(!allSelected)}
                    />
                    Select All
                </div>*/}
                {permissions?.map((perm, idx) => {
                    return (
                        <div key={idx}>
                            {checkBoxLoading[perm._id] ? (
                                <CircularProgress size={17} />
                            ) : (
                                <input
                                    type="checkbox"
                                    id={`custom-checkbox-${perm._id}`}
                                    value={null}
                                    checked={selected[perm._id]}
                                    onChange={() => handleOnPermChange(perm._id)}
                                />
                            )}
                            {perm.title}
                        </div>
                    );
                })}
            </AccordionDetails>
        </Accordion>
    );
};

export default RoleAccordion;

const styles = makeStyles((theme) => ({
    root: {
        width: "100%",
        padding: "0 5%",
        fontSize: 14,

        "& hr": {
            height: 1,
            width: "100%",
            backgroundColor: "#8c8b8b",
            border: "none",
            marginTop: 10
        },
        "& .memberRole-header": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",

            "& .member_delete_icon": {
                justifySelf: "end",
                alignSelf: "center",
                fontSize: 20
            },
            "& h3": {
                fontSize: 16,
                fontWeight: "bolder",
                alignSelf: "end",
                marginBottom: 0
            }
        }
    },

    roleForm: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "100%",
        marginBottom: "40px",

        "& input, label": {
            display: "block"
        },

        "& label .name": {
            marginBottom: "20px",
            fontWeight: 600
        },

        "& input[type=text]": {
            marginTop: "5px",
            marginBottom: 15,
            width: "100%",
            padding: "8px 16px",
            lineHeight: "25px",
            fontSize: 14,
            fontWeight: 500,
            borderRadius: 6,
            border: "1px solid #CDD9ED",
            backgroundColor: "#fff",
            color: "#99A3BA",
            transition: "border .3s ease",
            "&::placeholder": {
                color: "#CBD1DC"
            },
            "&:focus": {
                outline: "none",
                borderColor: "#275EFE"
            }
        },

        "& input[type=submit]": {
            alignSelf: "end",
            borderRadius: 6,
            border: "none",
            backgroundColor: "#2b74d4",
            color: "#fff",
            outline: "none",
            padding: "10px 26px",
            fontWeight: 500
        }
    },
    accordion: {
        "& .accordion-role-div": {
            display: "flex",
            alignItems: "center",
            "& input": {
                marginRight: 20
            }
        },
        "& .accordion-details": {
            padding: "16px 25px"
        }
    }
}));

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
        borderBottom: 0
    },
    "&:before": {
        display: "none"
    }
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
    flexDirection: "row",

    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)"
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1),

        "& .css-ahj2mt-MuiTypography-root": {
            fontSize: "20px",
            fontWeight: 600,
            color: "#000"
        }
    },
    "& .css-v84d5j-MuiSvgIcon-root": {
        fontSize: "20px",
        fontWeight: 600,
        color: "#000"
    }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
    display: "flex",
    flexDirection: "row-column",
    flexWrap: "wrap",

    "& div": {
        display: "flex",
        alignItems: "center",
        width: "50%",
        fontSize: "16px",

        "& input, span": {
            display: "block"
        },

        "& input": {
            marginRight: "10px",
            padding: "8px"
        }
    }
}));
