import { makeStyles } from "@material-ui/core/styles";

export const useModalStyles = makeStyles((theme) => ({
  modalContainer: {
    position: "fixed",
    top: "0px",
    left: "0px",
    width: "100%",
    minHeight: "100vh",
    backgroundColor: "",
    zIndex: 100000000, //I must be above, never below,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  backDrop: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 2
    // backdropFilter: "blur(9px)"
  },

  contentWrapper: {
    backgroundColor: "#fff",
    zIndex: 3,
    borderRadius: "10px 0px 0px 10px",
    width: "80%",
    maxWidth: "532px",
    height: "100%",
    maxHeight: "90vh",
    position: "relative",
    overflow: "auto",
    // scrollbarWidth: "none", // for firefox: Hides scrollbar but still scrolls

    [theme.breakpoints.down("sm")]: {
      width: "90%"
    },

    // // for Safari & Chrome: Hides scrollbar but still scrolls
    // "&::-webkit-scrollbar": {
    //     display: "none"
    // },

    "& .modal_header": {
      borderBottom: "1px solid #043B5C54",
      padding: "40px 30px 10px",

      "& h4": {
        color: "#043B5C",
        fontFamily: "Open Sans-Semibold, sans-serif",
        fontWeight: 600,
        fontSize: "18px"
      }
    }
  },

  cancelIcon: {
    position: "absolute",
    top: "10px",
    right: "10px",
    width: "15px",
    height: "15px",
    cursor: "pointer",

    "& span": {
      width: "2px",
      height: "15px",
      backgroundColor: "#062239",
      position: "absolute",
      display: "inline-block",
      left: "50%",
      transform: "translateX(-50%) rotate(45deg)",

      "&:nth-child(2)": {
        transform: "translateX(-50%) rotate(-45deg)"
      }
    }
  }
}));
