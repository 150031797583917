import { makeStyles } from "@material-ui/core/styles";
import { BsFileX } from "react-icons/bs";

const useStyles = makeStyles((theme) => ({
  root: {
    "& :focus": {
      outline: "none !important"
    },
    "button:focus": {
      outline: "none !important"
    },
    "& .MuiFormControl-fullWidth .MuiOutlinedInput-adornedStart": {
      borderRadius: "8px"
    }
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "none"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "none",
    width: "100%",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none",
    borderRadius: "10px 0px 0px 10px",
    [theme.breakpoints.up("md")]: {
      width: "100%"
    }
  },
  closeBtn: {
    padding: "11px 12%",
    backgroundColor: "inherit !important",
    border: "1px solid #043B5C91",
    borderRadius: "10px",
    color: "#043B5C",
    fontWeight: "bold",
    fontSize: 10,
    "& .MuiButton-label": {
      fontWeight: "bold"
    },
    [theme.breakpoints.down("md")]: {
      padding: "11px 10%",
      fontSize: 10
    }
  },
  closeBtnTransfer: {
    padding: "11px 12%",
    border: "1px solid #043B5C91",
    borderRadius: "10px",
    color: "#043B5C",
    backgroundColor: "inherit !important",
    fontWeight: "bold",
    fontSize: 10,
    "& .MuiButton-label": {
      fontWeight: "bold"
    },
    [theme.breakpoints.down("md")]: {
      padding: "11px 10%",
      fontSize: 10
    }
  },
  fundBtn: {
    padding: "11px 13%",
    width: "53%",
    marginLeft: "5%",
    background: "#4786FF 0% 0% no-repeat padding-box !important",
    borderRadius: "10px",
    opacity: 1,
    color: "#F4F5F7",
    float: "right",
    cursor: "pointer !important",
    position: "relative",
    fontSize: 12,
    // marginTop: '-17%',
    border: "none",
    fontWeight: "bold",
    "& .MuiButton-label": {
      fontWeight: "bold"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "11px 22%"
    },
    [theme.breakpoints.down("md")]: {
      padding: "11px 12%"
    },
    [theme.breakpoints.down("sm")]: {
      padding: "11px 15%"
    }
    // marginLeft:'7%'
  },
  fundBtnTransfer: {
    padding: "11px 10%",
    width: "56%",
    marginLeft: "5%",
    backgroundColor: "#4786FF !important",
    borderRadius: "10px",
    opacity: 1,
    color: "#F4F5F7",
    cursor: "pointer",
    fontSize: 10,
    float: "right",
    fontWeight: "bold",
    "& .MuiButton-label": {
      fontWeight: "bold"
    },
    "&:hover": {},
    [theme.breakpoints.down("xs")]: {
      padding: "11px 9%"
    },
    [theme.breakpoints.down("md")]: {
      padding: "11px 9%"
    },
    [theme.breakpoints.down("sm")]: {
      padding: "11px 10%"
    }
    // marginLeft:'7%'
  },
  divider: {
    margin: "4% 0 6%",
    // color:' #043B5C54'
    borderBottom: "1px solid #043B5C54"
  },
  hiddenContent: {
    fontSize: "13px",
    marginBottom: "1%",
    "& span .MuiSvgIcon-root": {
      float: "right",
      fontSize: "16px"
    }
  },
  label: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "#043B5C",
    position: "relative",
    top: "12px"
  },
  bold: {
    fontWeight: "bold"
  },
  fundText: {
    display: "grid",
    // color:'#043B5C !important',
    justifyContent: "center",
    "& .MuiTypography-body1": {
      fontWeight: "bold"
    }
  },
  fundTextTransfer: {
    "& .MuiTypography-body1": {
      fontWeight: "bold"
    }
  },
  textField: {
    // borderRadius: '10px',
    marginTop: "4%",

    "& .MuiOutlinedInput-root": {
      border: "#043B5C91",
      height: "42px",
      borderRadius: "8px",
      color: "#043B5C"
      // fontWeight: 500,
      // fontSize: 20,
    }
  },
  btnDiv: {
    marginTop: "7%",
    "& button:focus": {
      outline: "none"
    }
  },
  hiddenContainer: {
    backgroundColor: "hsla(202, 64%, 65%, 0.451)",
    padding: "5%",
    width: "80%",
    margin: "4% 0 8% 0",
    borderRadius: "4%"
  },
  imgPosition: {
    padding: "12px 17px",
    backgroundColor: "#6EB5DF73",
    borderRadius: "9px 0 0 8px",
    width: "52px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "1%"
    }
  },
  outline: {
    outline: "none !important"
  },
  transferBtnMargin: {
    marginTop: "10%",
    display: "flex",
    justifyContent: "center"
  },
  bank: {
    "& .MuiOutlinedInput-root": {
      height: "42px",
      borderRadius: "8px"
    }
  },
  boxTypography: {
    display: "grid",
    paddingLeft: "8%",
    marginTop: "3%",
    color: "#043B5C",
    fontWeight: 700
  },
  izybalanceBox: {
    border: "solid 1px #043B5C91",
    height: "42px",
    borderRadius: "8px",
    backgroundColor: "#6EB5DF30",
    marginTop: "4%"
  },
  checkBox: {
    "& .MuiSvgIcon-root": {
      width: 17
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#043B5C"
    }
  }
}));

export default useStyles;
