import * as actionTypes from "../constants";

const initialState = {
  isLoading: false,
  loading: false,
  referral_balance: 0,
  candidates: [],
};

const referralReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.referralConstant.GET_CANDIDATES_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.referralConstant.GET_CANDIDATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        candidates: action.payload,
      };
    case actionTypes.referralConstant.GET_CANDIDATES_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };

    case actionTypes.referralConstant.GET_MY_REFERRAL_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.referralConstant.GET_MY_REFERRAL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        referrals: action.payload,
      };
    case actionTypes.referralConstant.GET_MY_REFERRAL_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case actionTypes.referralConstant.GET_REFERRAL_BALANCE_PENDING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.referralConstant.GET_REFERRAL_BALANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        referral_balance: action.payload,
      };
    case actionTypes.referralConstant.GET_REFERRAL_BALANCE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.referralConstant.GET_ACCOUNT_DETAILS_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.referralConstant.GET_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        fetching: false,
        account_details: action.payload,
      };
    case actionTypes.referralConstant.GET_ACCOUNT_DETAILS_FAILURE:
      return {
        ...state,
        fetching: false,
      };

    case actionTypes.referralConstant.SEND_INVITATION_PENDING:
      return {
        ...state,
        sending: true,
        timeout: 3000,
      };
    case actionTypes.referralConstant.SEND_INVITATION_SUCCESS:
      return {
        ...state,
        sending: false,
        timeout: 3000,
        invite_data: action.payload,
        invite_message: action.message,
      };
    case actionTypes.referralConstant.SEND_INVITATION_FAILURE:
      return {
        ...state,
        timeout: 1000,
        sending: false,
      };
    case actionTypes.referralConstant.UPDATE_ACCOUNT_DETAILS_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.referralConstant.UPDATE_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        message: action.message,
      };
    case actionTypes.referralConstant.UPDATE_ACCOUNT_DETAILS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
};

export default referralReducer;
