import React from "react";
import { InputAdornment, makeStyles, TextField } from "@material-ui/core";
import { string, bool, func } from "prop-types";
import useStyles from "./fundwalletStyles";

export const ModalTextField = ({
  name,
  error,
  label,
  customRef,
  value,
  inputWidth = "100%",
  type,
  disabled,
  onChange,
  img,
  alt,
  imgStyle,
  defaultValue,
  placeholder,
  onClick,
}) => {
  const classes = useStyles();
  return (
    <div>
      <label className={classes.label}>{label}</label>
      <TextField
        style={{ color: "red !important" }}
        name={name}
        variant="outlined"
        fullWidth
        type={type}
        size="small"
        className={classes.textField}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" style={{ paddingLeft: "0" }}>
              <img src={img} alt={alt} className={classes.imgPosition} style={imgStyle} />
            </InputAdornment>
          ),
        }}
        inputRef={customRef}
        onChange={onChange}
        value={value}
        disabled={disabled}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onClick={onClick}
      />
      <div>
        {error && (
          <p
            id={`${name}-component-error-text`}
            style={{
              fontSize: "0.8rem",
              color: "red",
            }}
          >
            {error}
          </p>
        )}
      </div>
    </div>
  );
};

ModalTextField.prototype = {
  name: string,
  error: string,
  label: string,
  customRef: func,
  value: string,
  inputWidth: string,
  type: string,
  disbled: bool,
  defaultValue: string,
  onChange: func,
  imgStyle: string,
};
export const AccTextField = ({
  name,
  error,
  label,
  customRef,
  value,
  inputWidth = "100%",
  type,
  disabled,
  onChange,
  img,
  alt,
  imgStyle,
  defaultValue,
}) => {
  const classes = useStyles();
  return (
    <div>
      <label className={classes.label}>{label}</label>
      <TextField
        style={{ color: "red !important" }}
        name={name}
        variant="outlined"
        fullWidth
        type={type}
        size="small"
        className={classes.textField}
        InputProps={{}}
        inputRef={customRef}
        onChange={onChange}
        value={value}
        disabled={disabled}
        defaultValue={defaultValue}
      />
      <div>
        {error && (
          <p
            id={`${name}-component-error-text`}
            style={{
              fontSize: "0.8rem",
              color: "red",
            }}
          >
            {error}
          </p>
        )}
      </div>
    </div>
  );
};

ModalTextField.prototype = {
  name: string,
  error: string,
  label: string,
  customRef: func,
  value: string,
  inputWidth: string,
  type: string,
  disbled: bool,
  defaultValue: string,
  onChange: func,
  imgStyle: string,
  placeholder: string,
  onClick: func,
};
