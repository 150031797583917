import React, { Fragment, useState, useCallback } from "react";
import Slide from "@material-ui/core/Slide";
import { useDispatch, useSelector } from "react-redux";
import { sendEmail, updateUserProfile } from "#/store/actions/auth.actions";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Modal from "#/components/modal/modal.component";
import CustomButton from "#/components/ui-components/button.component";
import CustomInput from "#/components/apartmentForm/subcomponents/customInput/customInput";
import { useStyles } from "./phoneNumberAlert.styles";
import clsx from "clsx";

const PhoneNumberAlert = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const [submitting, setSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const {
    first_name,
    last_name,
    date_of_birth,
    state_of_origin,
    gender,
    roles: role,
    phone
  } = user;

  const handleSetPhoneNumber = (e) => {
    let { value } = e.target;

    value = value.match(/\d/g) || []; //allow only digits, NOTE: match could return null hence the empty array second option
    value = value.join("");
    if (value.length === 12) return;
    setPhoneNumber(value);
  };

  const handleSetModalVisibility = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    const formData = new FormData();
    formData.append("phone", phoneNumber);

    dispatch(
      updateUserProfile(
        formData,
        () => {},
        () => {}
      )
    );
  };

  if (phone == null && role.includes("Tenant")) {
    return (
      <Slide direction="down" in={true} timeout={1500} mountOnEnter unmountOnExit>
        <form className={classes.wrapper} noValidate onSubmit={handleSubmit}>
          <span className="text-one">
            Please update your Phone Number
            <span className="sub-text2" onClick={handleSetModalVisibility}>
              {" "}
              Click here
            </span>
          </span>
          {showModal && (
            <Modal
              isModalOpen={true}
              handleCloseModal={handleSetModalVisibility}
              hasHeader={false}
              style={{ padding: "30px" }}>
              <CustomInput
                name="phoneNumber"
                label="Phone Number"
                placeholder="080XXXXXXXX"
                value={phoneNumber}
                handleOnChange={handleSetPhoneNumber}
                errors=""
              />
              <div className="btn-wrapper">
                <CustomButton
                  type="button"
                  buttonText="Close"
                  onClick={handleSetModalVisibility}
                  className={clsx(classes.btnBlue, classes.btnOutlined)}
                />
                <CustomButton
                  type="submit"
                  buttonText="Save"
                  // onClick={Submit}
                  className={classes.btnBlue}
                  isLoading={submitting}
                />
              </div>
            </Modal>
          )}
        </form>
      </Slide>
    );
  } else {
    return null;
  }
};

export default PhoneNumberAlert;
