import React from "react";
import AddIcon from "@material-ui/icons/Add";
import Property from "./components/property.component";
import { usePropertyDetailsStyles } from "./propertyDetails.style";
import Container from "../../../components/layout/container";

const PropertyDetails = () => {
  const classes = usePropertyDetailsStyles();

  return (
    <React.Fragment>
      <div className={classes.header}>
        <h1>MY PROPERTIES DETAILS</h1>
        <button>
          <AddIcon />
          <span>Add apartment</span>
        </button>
      </div>
      <Property />
      <Property />
    </React.Fragment>
  );
};

export default PropertyDetails;
