import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete, Button, TextField } from "@mui/material";
import { handleFormInput, validate } from "#/lib/validation";
import { CircularProgress } from "@material-ui/core";
import { propertyServiceApi, returnEstateMember } from "#/apis/propertyServiceApi";
import PageTitle from "#/components/estateManagementComponents/page-title";
import { alertError, alertSuccess } from "#/store/actions/notification.actions";
import ConfirmationPopup from "#/components/popup/confirmation";
import FeeRibbon from "./fee-ribbon";
import { ESM_ROUTES } from "#/constants/routes";
import BreadCrumbs from "#/components/estateManagementComponents/bread-crumbs";
import { useHistory } from "react-router-dom";

const breadcrumbLinks = [
  { title: "dashboard", href: ESM_ROUTES.dashboard },
  { title: "fees", href: ESM_ROUTES.fee, currentPage: true }
];

const initialValues = {
  title: { value: "", validation: true, error: "" },
  amount: { value: "", validation: true, error: "" },
  payment_term: { value: "", validation: true, error: "" }
};

const Fees = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const terms = ["monthly", "annual", "bi-annual", "quarterly", "once"];
  const { member } = returnEstateMember();

  const [fees, setFees] = useState(null);
  const [formValues, setFormValues] = useState(initialValues);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [feeToDelete, setFeeToDelete] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(true);

  useEffect(() => {
    fetchFees();
  }, []);

  useEffect(() => {
    validate(formValues, (status) => setDisableSubmit(!status));
  }, [formValues]);

  const createFee = (e) => {
    e.preventDefault();
    const payload = {
      title: formValues.title.value,
      amount: parseInt(formValues.amount.value, 10),
      payment_term: formValues.payment_term.value
    };

    setLoading1(true);
    propertyServiceApi()
      .post("/estate-management/fee", payload)
      .then((data) => {
        setFormValues(initialValues);
        dispatch(alertSuccess("Successfully created fee!!"));
        fetchFees();
      })
      .catch(({ response, message }) => {
        dispatch(alertError(response?.data.error || message));
      })
      .finally(() => {
        setLoading1(false);
      });
  };

  const fetchFees = () => {
    setLoading4(true);
    propertyServiceApi()
      .get("/estate-management/fee")
      .then((data) => {
        setFees(data.data.payload);
      })
      .catch(({ response, message }) => {
        if (response?.status === 403) return;
        dispatch(alertError(response?.data.error || message));
      })
      .finally(() => {
        setLoading4(false);
      });
  };

  const deleteFee = (id) => () => {
    setLoading2({ ...loading2, [id]: true });
    setOpenConfirm(false);
    setFeeToDelete(null);
    propertyServiceApi()
      .delete(`/estate-management/fee/${id}`)
      .then((data) => {
        fetchFees();
      })
      .catch(({ response, message }) => {
        dispatch(alertError(response?.data.error || message));
      })
      .finally(() => {
        setLoading2({ ...loading2, [id]: false });
      });
  };

  const initiateDelete = (id) => () => {
    setOpenConfirm(true);
    setFeeToDelete(id);
  };

  const onDeleteCancel = () => {
    setOpenConfirm(false);
    setFeeToDelete(null);
  };

  // if (!member.super_admin) {
  //     history.push(ESM_ROUTES.dashboard);
  //     return null;
  // }

  return (
    <div className={classes.root}>
      <BreadCrumbs links={breadcrumbLinks} />
      <PageTitle title="Fee" />
      <form onSubmit={createFee} className={classes.feeForm}>
        <TextField
          id="title"
          style={{ marginBottom: 20 }}
          label="Title"
          variant="outlined"
          value={formValues.title.value}
          onChange={(e) => {
            const value = e.target.value;
            handleFormInput("title", value, /./gi, formValues, setFormValues);
          }}
        />
        <TextField
          id="amount"
          style={{ marginBottom: 20 }}
          label="Amount"
          variant="outlined"
          value={formValues.amount.value}
          onChange={(e) => {
            const value = e.target.value.replace(/[^0-9]/gi, "");
            handleFormInput(
              "amount",
              value,
              /^-?\d+(?:\.\d+)?$/g,
              formValues,
              setFormValues
            );
          }}
        />
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          style={{ marginBottom: 30 }}
          options={terms}
          getOptionLabel={(option) => option}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          fullWidth
          renderInput={(params) => <TextField {...params} label="Select term" />}
          value={formValues.payment_term.value}
          onChange={(e, value) => {
            handleFormInput("payment_term", value, /./gi, formValues, setFormValues);
          }}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="submit-btn"
          disabled={loading1 || disableSubmit}>
          {loading1 ? <CircularProgress size={25} /> : "Create fee"}
        </Button>
      </form>

      <div className={classes.feeLists}>
        <div className="feeLists-header">
          <h3>Lists of Fees</h3>
        </div>
        <hr />

        {loading4 && <CircularProgress size={20} />}
        {fees?.map((fee, index) => (
          <FeeRibbon
            key={index}
            fee={fee}
            deleteFee={initiateDelete(fee._id)}
            loadingDelete={loading2}
          />
        ))}
      </div>
      {openConfirm && (
        <ConfirmationPopup
          title="Delete fee"
          description="Are you sure you want to delete this fee?"
          handleClose={onDeleteCancel}
          proceedAction={deleteFee(feeToDelete)}
        />
      )}
    </div>
  );
};

export default Fees;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: 20,
    padding: "0 5%"
  },

  feeLists: {
    display: "flex",
    flexDirection: "column",
    marginTop: "60px",
    marginBottom: 100,
    width: "100%",

    "& hr": {
      height: 1,
      width: "100%",
      backgroundColor: "#8c8b8b",
      border: "none",
      marginTop: 10
    },
    "& .feeLists-header": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",

      "& .fee_delete_icon": {
        justifySelf: "end",
        alignSelf: "center",
        fontSize: "34px",
        cursor: "pointer"
      },
      "& h3": {
        fontSize: "20px",
        fontWeight: 800,
        alignSelf: "end",
        marginBottom: 0
      }
    },
    "& .fee-details": {
      marginTop: "20px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
      padding: "15px 15px 15px 0",

      "& .mem-det-div1": {
        width: "10%"
      },

      "& .mem-det-div2": {
        flex: 1,
        display: "flex",
        width: "100%",
        justifyContent: "space-around"
      },

      "& span": {
        margin: "0 5px",
        width: "33%",
        textAlign: "center"
      },

      "& .fee-title": {
        fontSize: "16px",
        fontWeight: 600,
        marginLeft: "10px"
      },
      "& .fee-term": {
        opacity: 0.6
      }
    }
  },

  feeForm: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "100%",

    "& input, label": {
      display: "block"
    },

    "& label": {
      fontSize: "16px",
      fontWeight: 600,
      opacity: 0.5
    },

    "& .submit-btn": {
      alignSelf: "end",
      width: 150,
      borderRadius: 6,
      border: "none",
      color: "#fff",
      outline: "none",
      padding: "8px 16px",
      fontWeight: 500
    }
  }
}));
