import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    inputField: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginBottom: "30px",

        "& label": {
            marginBottom: "10px",
            fontSize: "14px",
            fontWeight: "700",
            whiteSpace: "nowrap",
            color: "#043B5C",
            fontFamily: "Open Sans-Semibold"
        },

        "& h6": {
            color: "#043B5C7F",
            fontSize: "10px",
            marginBottom: "10px"
        },

        "& input": {
            borderRadius: "5px",
            border: "1px solid #043B5C69",
            padding: "10px 25px",
            width: "100%"
        }
    },
    errorMessage: {
        color: "red",
        fontSize: "14px",
        marginTop: "5px"
    }
}));

const CustomInput = ({
    name,
    label,
    placeholder = "",
    type = "text",
    value,
    handleOnChange,
    register,
    errors,
    errorMessage = "Field is Required",
    handleOnBlur,
    disabled = false,
    styles = {},
    customRef
}) => {
    const classes = useStyles();

    return (
        <div className={classes.inputField} style={styles}>
            <label htmlFor={name}>{label}</label>
            <input
                name={name}
                type={type}
                placeholder={placeholder}
                onChange={handleOnChange}
                value={value}
                onBlur={handleOnBlur}
                disabled={disabled}
                ref={customRef}
            />
            {errors && <div className={classes.errorMessage}>{errorMessage}</div>}
        </div>
    );
};

export default memo(CustomInput);
