import React from 'react';
import '../../App.css'; 
import './mainimage.scss'

// images
import largebedroom from '../../assets/images/tenant/largebedroom.png';

class Mainimage extends React.Component{
    // constructor(props){
    //     super(props)
    // }
    render(){
        return(
            // Main image shown in booking page
            <div>
                <img src={largebedroom} className="mainimage" alt="largebedroom"/>
            </div>
        );
    }
}

export default Mainimage; 