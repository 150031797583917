import React, { useEffect, useMemo } from "react";
import { ToastContainer, toast } from "react-toastify";
import { arrayOf, shape } from "prop-types";
import { connect } from "react-redux";

import "react-toastify/dist/ReactToastify.css";

const AlertComponent = ({ messages }) => {
    // console.log(messages);
    const feedbacks = useMemo(() => messages, [messages]);
    useEffect(() => {
        feedbacks.map((feedback) => {
            if (feedback.messageType === "error") {
                toast.error(feedback.message);
            } else if (feedback.messageType === "success") {
                toast.success(feedback.message);
            } else {
                toast.dark(feedback.message);
            }
            return feedback;
        });
    });
    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={6000}
                // hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                // pauseOnFocusLoss
                draggable
                // pauseOnHover
            />
        </div>
    );
};

AlertComponent.defaultProps = {
    messages: []
};

AlertComponent.propTypes = {
    messages: arrayOf(shape({}))
};

const mapStateToProps = ({ notifications }) => ({
    messages: notifications.messages
});

export default connect(mapStateToProps)(AlertComponent);
