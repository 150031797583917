import {
    FETCH_MEMBERS_PENDING,
    FETCH_MEMBERS_SUCCESS,
    FETCH_MEMBERS_FAIL,
    ADD_MEMBER,
    DELETE_MEMBER
} from "../constants";

const initialState = {
    loading: false,
    members: []
};

const estateMembersReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MEMBERS_PENDING:
            return {
                ...state,
                loading: true
            };
        case FETCH_MEMBERS_SUCCESS:
            return {
                ...state,
                members: action.payload,
                loading: false
            };
        case FETCH_MEMBERS_FAIL:
            return {
                ...state,
                loading: false
            };

        case ADD_MEMBER:
            return {
                ...state,
                members: state.members.concat(action.payload)
            };
        case DELETE_MEMBER:
            return {
                ...state,
                members: state.members.filter((member) => member._id !== action.payload._id)
            };

        default:
            return state;
    }
};

export default estateMembersReducer;
