import { makeStyles } from "@material-ui/core/styles";

export const usePropertyFormStyles = makeStyles((theme) => ({
    //-----------------------------
    //Component wrapper
    //-----------------------------
    formFields: {
        display: "grid",
        gridGap: "10%",
        marginBottom: "100px",
        padding: "80px 60px",
        backgroundColor: "#fff",
        maxWidth: "1440px",
        margin: "auto",

        //------------------------------------------------------------------------------------------
        //override margin, padding and box-sizing coming from any parent component or global scope
        //------------------------------------------------------------------------------------------
        "& *": {
            margin: 0,
            padding: 0,
            boxSizing: "border-box"
        },

        [theme.breakpoints.down("sm")]: {
            display: "block",
            marginBottom: "0px",
            padding: "0px"
        },

        "& .first-section": {
            display: "flex",
            flexDirection: "column",
            minWidth: "240px",

            [theme.breakpoints.down("sm")]: {
                marginBottom: "50px"
            },

            "&__title": {
                textTransform: "uppercase",
                marginBottom: "20px",

                [theme.breakpoints.down("sm")]: {
                    paddingLeft: "30px",
                    paddingTop: "15px"
                }
            },

            "&__contact-wrapper": {
                display: "flex",
                flexDirection: "column"
            }
        },

        "& .second-section": {
            display: "flex",
            flexDirection: "column",

            [theme.breakpoints.down("sm")]: {
                backgroundColor: "#fff",
                padding: "32px",
                borderRadius: "0px 0px 10px 10px",
                marginBottom: "30px"
            },

            "& .btn-add": {
                backgroundColor: "transparent",
                border: "none",
                color: "#4786FF",
                cursor: "pointer",
                alignSelf: "flex-start",
                marginTop: "20px"
            }
        }
    },
    //-----------------------------

    addAnotherApartment: {
        fontSize: "13px",
        color: "#4786FF",
        fontWeight: "700",
        cursor: "pointer",
        marginTop: "20px"
    },

    //-----------------------------
    // Cover image section
    //-----------------------------
    imageContainer: {
        backgroundColor: "#6EB5DF72",
        height: "250px",
        position: "relative",
        borderRadius: "10px 10px 0px 0px",
        overflow: "hidden",

        [theme.breakpoints.down("sm")]: {
            borderRadius: "0px"
        },

        "& .image": {
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat"
        },

        "& input[type=file]": {
            position: "absolute",
            opacity: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
            cursor: "pointer"
        },

        "& p": {
            textAlign: "center",
            position: "absolute",
            width: "100%",
            bottom: 0,
            padding: "15px",
            fontSize: "16px",
            cursor: "pointer",
            backgroundColor: "#DEEDFF",
            color: "#043B5C",
            fontWeight: "500",

            [theme.breakpoints.down("sm")]: {
                backgroundColor: "#fff"
            }
        }
    },
    //-----------------------------
    //-----------------------------
    //-----------------------------

    //-----------------------------
    // Property Id section
    //-----------------------------
    idContainer: {
        backgroundColor: "#F4F5F7",
        padding: "20px 40px",
        borderRadius: "0px 0px 5px 5px",

        "& h3": {
            marginBottom: "10px",
            fontSize: "14px",
            fontWeight: "900",

            "& span": {
                textTransform: "uppercase",
                fontSize: "13px",
                fontWeight: "400",
                color: "#043B5C"
            }
        }
    },
    //-----------------------------
    //-----------------------------
    //-----------------------------

    //-----------------------------
    // Input Field
    //-----------------------------
    inputField: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginBottom: "30px",

        "& label": {
            marginBottom: "10px",
            fontSize: "14px",
            fontWeight: "700"
        },

        "& h6": {
            color: "#043B5C7F",
            fontSize: "10px",
            marginBottom: "10px"
        },

        "& input": {
            borderRadius: "5px",
            border: "1px solid #043B5C69",
            padding: "10px 25px",
            width: "100%"
        }
    },
    errorMessage: {
        color: "red",
        fontSize: "14px",
        marginTop: "5px"
    },
    //-----------------------------
    //-----------------------------
    //-----------------------------

    //-----------------------------
    // Submit form button
    //-----------------------------
    buttonContainer: {
        gridColumn: "1 /span 2",
        display: "flex",
        justifyContent: "flex-end",
        flexWrap: "wrap",
        alignItems: "center",

        [theme.breakpoints.down("sm")]: {
            height: "100px",
            margin: "0px 30px"
        },

        "& button": {
            [theme.breakpoints.down("sm")]: {
                width: "100%"
            }
        }
    },

    btnBlue: {
        backgroundColor: "#4786FF",
        color: "#fff",
        borderRadius: "5px",
        border: "1px solid #4786FF",
        padding: "11px 35px",
        fontSize: "11px",
        cursor: "pointer"
    }
    //-----------------------------
    //-----------------------------
    //-----------------------------
}));
