import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import InvitedUsers from "./users/user";
import AccountBox from "./account/accountBox";
import ReferralBox from "./referralBox/reffBox.jsx";
import { connect } from "react-redux";
import {
  getCandidates,
  getReferral,
  getBalance,
  getAccountDetails,
  sendInvitation,
  updateAccountDetails,
} from "../../store/actions/referral.actions";
import RefUser from "./sendReferral/refUser";
import Container from "../../components/layout/container";

const Referral = (props) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      marginBottom: "50px",
      marginLeft: "20px",
      overflowX: "hidden",
      overflowY: "hidden",
      [theme.breakpoints.down("sm")]: {},
      marginLeft: "0px",
    },
  }));

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    props.dispatch(getCandidates());
    props.dispatch(getReferral());
    props.dispatch(getBalance());
    props.dispatch(getAccountDetails());

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SendInvite = (email, cb) => {
    props.dispatch(sendInvitation(email, cb));
  };

  const updateAccount = (data, cb) => {
    props.dispatch(updateAccountDetails(data, cb));
  };

  const classes = useStyles();

  const { balance, loading, sending, timeout, accountDetails, isFetching, candidates, user, isLoading } = props;

  return (
    <div className={classes.container}>
      <Grid container spacing={0}>
        <Grid container spacing={matches ? 0 : 6}>
          <Grid item xs={12} md={12} lg={6}>
            <ReferralBox balance={balance} loading={loading} user={user} />
          </Grid>

          <Grid item xs={12} md={12} lg={6}>
            <InvitedUsers referral={candidates} loading={isLoading} />
          </Grid>

          <Grid item xs={12} md={12} lg={6}>
            <RefUser invite={SendInvite} sending={sending} />
          </Grid>

          <Grid item xs={12} md={12} lg={6}>
            <AccountBox account={accountDetails} updateAccount={updateAccount} fetching={isFetching} />
          </Grid>
        </Grid>
        <Grid container spacing={matches ? 0 : 6}></Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = ({ referral, auth }) => ({
  loading: referral.loading,
  balance: referral.referral_balance,
  sending: referral.sending,
  timeout: referral.timeout,
  accountDetails: referral.account_details,
  isFetching: referral.isFetching,
  candidates: referral.candidates,
  isLoading: referral.isLoading,
  user: auth.user,
});

export default connect(mapStateToProps)(Referral);
