import { Box, styled } from "@mui/material";
import React from "react";
import BankDetailsInput from "../BankDetailsform/BankDetailsInput";
import TitleUnderline from "#/components/ui-components/TitleUnderline.component";
import BankAccountList from "../bankAccountList/BankAccountList";

export default function LandlordSection({
  landlordDetails,
  setLandlordDetails,
  landlordbankDetails,
  setLandlordBankDetails
}) {
  return (
    <section style={{ minHeight: "80vh" }}>
      <Box>
        <SectionTitle>
          Your Bank Details <span>(As a Landlord)</span>
        </SectionTitle>
        <Box>
          <BankDetailsInput role={"landlord"} setState={setLandlordDetails} />
        </Box>
        <Box>
          <TitleUnderline title={"Bank Accounts List"} />

          {landlordbankDetails?.length > 0 ? (
            <BankAccountList
              bankAccountList={landlordbankDetails}
              role={"owner"}
              setRoleState={setLandlordDetails}
            />
          ) : (
            <em>You have not submitted any bank details yet.</em>
          )}
        </Box>
      </Box>
    </section>
  );
}

const SectionTitle = styled("p")`
  color: #10609f;
  font-weight: bold;
`;
