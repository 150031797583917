import React from 'react';
import '../../App.css'; 

import './statusdet.scss'

// images
import Location from '../../assets/images/tenant/location.svg'
import Avatar from '../../assets/other images/user (1).svg'

// components
import Rentmodal from './rentmodal';

class Statusdet extends React.Component{
    constructor(props){
        super(props)
    }
    render() {
        return (
            // booking status detail

             <React.Fragment>
                    <p className="text-color" style={{fontWeight:"600", marginTop:"45px", marginBottom:"30px"}}>
                        Your Request For Inspection Has Been Accepted By The Property Manager          
                    </p>
                    <p className="text-color" style={{fontWeight:"600", marginBottom:"4px"}}>Address</p>
                    <p className="text-color" style={{fontWeight:"500", marginBottom:"4px"}}>Chevron Drive Estate</p>
                    <p className="text-color small-p"><img src={Location} alt="location-image"className="location"/>  132 Chevron drive, New york Nigeria</p>
                    <p className="text-color username"> <img src={Avatar} alt="profile"/> Chuck Emeka</p>
                    
                    <button className="btn btn-primary btn-interest" data-toggle="modal" data-target=".bd-example-modal-lg">Show Interest</button>
                    <Rentmodal />

                    <button className="btn btn-schedule" style={{borderColor:"blue" , color:"blue"}}>Update Schedule</button><br/>

                    <button className="btn mt-3 btn-delete" style={{borderColor:"red", width:"8rem", color:"red"}}>Delete</button>
             </React.Fragment>
        );
    }
}

export default Statusdet;