import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const PageHeader = ({ children }) => {
    const styles = makeStyles((theme) => ({
        header: {
            marginBottom: "20px",
            "& > h3": {
                textTransform: "uppercase",
                color: "#043B5C",
                fontFamily: "Open Sans-bold, sans-serif",
                fontSize: "1.6rem",
                fontWeigth: "bold",
                marginBottom: "0px"
            }
        }
    }));
    const classes = styles();
    return <div className={classes.header}>{children}</div>;
};

export default React.memo(PageHeader);
