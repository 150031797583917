import { FormHelperText, styled } from "@mui/material";
import React from "react";

const CustomInputAndLabelField = ({ label, icon, error, name, type, ...inputProps }) => {
  return (
    <Container>
      <Label htmlFor={name}>{label}</Label>
      <InputWrapper>
        {icon ? <Icon>{icon}</Icon> : null}
        <Input name={name} type={type} {...inputProps} />
      </InputWrapper>
      {error ? <FormHelperText error>{error}</FormHelperText> : null}
    </Container>
  );
};

export default CustomInputAndLabelField;

const Container = styled("div")`
  width: 100%;
  height: 80px;
`;

const Icon = styled("div")`
  color: #052137;
`;

const Input = styled("input")`
  border: none;
  width: 100%;
  color: black;
  background: transparent;

  :focus {
    outline: none;
  }
`;

const InputWrapper = styled("div")`
  display: flex;
  padding: 5px;
  gap: 5px;
  align-items: center;
  width: 100%;
  height: 40px;
  font-size: 15px;
  background: linear-gradient(
        103deg,
        rgba(245, 245, 245, 1) 0%,
        rgba(242, 243, 244, 1) 50%,
        rgba(251, 251, 251, 1) 100%
      )
      padding-box,
    linear-gradient(
        103deg,
        rgba(223, 224, 224, 1) 0%,
        rgba(242, 243, 244, 1) 50%,
        rgba(219, 219, 219, 1) 100%
      )
      border-box;
  border: 2px solid transparent;
  border-radius: 10px;
`;

const Label = styled("label")`
  font-weight: 400;
  font-size: 0.8rem;
  margin-bottom: 5px;
  text-align: left;
  color: rgba(5, 33, 55, 0.7);
`;
