import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    "& .title": {
      marginBottom: "20px",
      fontSize: "20px",
      fontFamily: "Open Sans-bold , san-serif",
      color: "#032F4A",
      textTransform: "uppercase",
      borderBottom: "1px solid #032F4A",
      paddingBottom: "10px"
    },

    "& .spinner": {
      margin: "0 auto"
    },

    "& .btn-wrapper": {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridGap: "0px 20px",
      marginTop: "20px"
    },

    "& p": {
      color: "#032F4A",
      fontFamily: "Open Sans-Semibold",
      fontSize: "16px"
    }
  },

  paymentList: {
    "& .payment": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "20px",

      "& section": {
        display: "flex",
        alignItems: "center",
        "& input": {
          marginRight: "10px",
          cursor: "pointer"
        },

        "& p": {
          color: "#032F4A",
          fontFamily: "Open Sans-Semibold",
          textTransform: "uppercase",
          fontSize: "16px"
        }
      },

      "& p": {
        color: "#032F4A",
        fontFamily: "Open Sans-bold , san-serif",
        fontSize: "16px"
      }
    },

    "& .charges-list": {
      fontSize: "14px",
      textTransform: "capitalize",
      paddingLeft: "25px",
      color: "#032F4A",
      display: "flex",
      justifyContent: "space-between"
    }
  },

  paymentTotal: {
    textAlign: "right",
    marginBottom: "20px",
    fontFamily: "Open Sans-bold , san-serif",
    color: "#032F4A",
    fontSize: "20px"
  },

  closeBtnTransfer: {
    backgroundColor: "white",
    color: "#043B5C",
    textTransform: "none",
    borderRadius: "5px",
    border: "1px solid #043B5C91",
    padding: "7px 25px",
    fontSize: "13px",
    fontFamily: "Open Sans, sans-serif",
    padding: "13px 13%",
    marginRight: "20px",
    fontFamily: "Open Sans-bold , san-serif",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },

  activateBtn: {
    backgroundColor: "#4786FF",
    color: "#E8F0F9",
    textTransform: "none",
    borderRadius: "5px",
    color: "#F4F5F7",
    border: "1px solid #45B29D",
    border: "none",
    padding: "7px 25px",
    fontSize: "13px",
    padding: "14px 15%",
    fontFamily: "Open Sans-bold , san-serif",
    fontWeight: "bold",

    "&:hover": {
      color: "#E8F0F9",
      backgroundColor: "#4786FF"
    },

    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      width: "100%"
    }
  },

  btnBlue: {
    backgroundColor: "#10609F",
    textTransform: "none",
    borderRadius: "7px",
    color: "#F4F5F7",
    border: "1px solid #4786FF",
    fontFamily: "Open Sans, sans-serif",
    padding: "7px 20px",
    fontSize: "13px",

    [theme.breakpoints.down("sm")]: {
      padding: "7px 15px",
      fontSize: "11px"
    },

    "&:hover": {
      backgroundColor: "#4786FF",
      opacity: "0.5"
    }
  },

  btnOutlined: {
    backgroundColor: "#fff",
    border: "1px solid #043B5C91",
    color: "#043B5C91",

    "&:hover": {
      backgroundColor: "#FFF",
      opacity: "0.5"
    }
  },
  modalHeader: {
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "23px",
    lineHeight: "48px"
  }
}));
