import { Button, Grid } from "@material-ui/core";
import React, { Fragment, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Styles, TypographyText } from "./style";
import testImg1 from "#/assets/images/liveizy-logo.png";
import sad from "#/assets/images/sad.png";
import { capitalizeFirstLetter, toCurrency } from "#/components/utils/util";
import CustomButton from "#/components/ui-components/button.component";
import { populateCurrentPropertyInfo } from "#/store/actions/property.actions";

const Summary = ({ property }) => {
  const classes = Styles();
  const dispatch = useDispatch();
  const history = useHistory();

  // get all vacant apartments in a property
  const getVacantApts = () => {
    let vacant = [];
    property.apartment.forEach((apartment) => {
      if (apartment.status === "vacant") vacant.push(apartment.status);
    });
    return vacant.length;
  };

  const getTenant = () => {
    let tenant = [];
    property.apartment.forEach((apartment) => {
      if (apartment.tenants && apartment.tenants.length >= 1)
        tenant.push(apartment.tenants);
    });
    return tenant.length;
  };

  const handleAddNewApartment = useCallback(() => {
    dispatch(
      populateCurrentPropertyInfo(property, () => {
        history.push({ pathname: `/create/${property._id}/apartment` });
      })
    );
  }, [property]);

  return (
    <Fragment>
      <Grid container>
        <Grid md={12} item container justifyContent="flex-end">
          <CustomButton
            className={classes.editBtn}
            type="button"
            buttonText="Add new apartment"
            style={{
              backgroundColor: "#4786FF",
              color: "#E8F0F9",
              textTransform: "none",
              color: "#F4F5F7",
              border: "1px solid #4786FF",
              padding: "8px 17px",
              fontSize: "12px",
              fontFamily: "Open Sans, sans-serif"
            }}
            onClick={handleAddNewApartment}
          />
        </Grid>
        <Grid container className={classes.summary}>
          <Grid item md={3} className="summary-list">
            <TypographyText variant="body1" style={{ whiteSpace: "nowrap" }}>
              List of Apartment
            </TypographyText>
            <br></br>
            <TypographyText variant="h3">{property.apartment.length}</TypographyText>
          </Grid>
          <Grid item md={3} className="summary-list">
            <TypographyText variant="body1">List of Tenants</TypographyText>
            <br></br>
            <TypographyText variant="h3">{getTenant()}</TypographyText>
          </Grid>
          <Grid item md={3} className="summary-list">
            <TypographyText variant="body1" style={{ whiteSpace: "nowrap" }}>
              No of Vacant Apartment
            </TypographyText>
            <br></br>
            <TypographyText variant="h3">{getVacantApts()} </TypographyText>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: "9%", display: "block" }}>
          {/* <Grid container className={classes.summaryTenant}>
                        <TypographyText variant="h5" className={classes.tenantMargin}>
                            Tenant
                        </TypographyText>
                        <Grid container className={`${classes.xsContainer} ${classes.xsContainer2}`}>
                            <Grid container spacing={4} item md={6} style={{ alignItems: "center" }}>
                                <Grid item>
                                    <img src={testImg1} alt="alt" className={classes.tenantImg} />
                                </Grid>
                                <Grid item>
                                    <TypographyText variant="body1" style={{ fontWeight: 900, fontSize: "1.0rem" }}>
                                        Kelechi oleka
                                    </TypographyText>
                                    <TypographyText variant="body1">Kelechi@Liveizy.com</TypographyText>
                                </Grid>
                            </Grid>
                            <Grid item md className={classes.btmButton}>
                                <Button variant="outlined" className={`${classes.editBtn} ${classes.tenBtnMob}`}>
                                    Add Tenant
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid> */}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Summary;
