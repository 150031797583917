import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

export const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5
    },
    colorPrimary: {
        backgroundColor: "#FFFFFF"
    },
    bar: {
        borderRadius: 5,
        backgroundColor: "#21A11C"
    }
}))(LinearProgress);

export const useStyles = makeStyles((theme) => ({
    izy: {
        border: "solid 2px #043B5C73",
        padding: "8%",
        borderRadius: "10px",
        margin: "34px 0px"
    },

    virtualAccount: {
        marginTop: "10px",
        "& .header": {
            fontWeight: "600",
            color: "#043B5C",
            fontSize: "13px"
        },

        "& .sub-text > p": {
            color: "#332A7C",
            fontWeight: "600",
            fontSize: "15px"
        }
    },
    rightCol: {
        backgroundColor: "aqua"
    },
    containerBorder: {
        // marginTop: '18%',
    },
    izyPay: {
        backgroundColor: "#4786FF !important",
        fontSize: "12px",
        padding: "3% 7%",
        borderRadius: "11px",
        marginRight: "7%",
        color: "white",
        fontWeight: "bold !important",
        textTransform: "none",
        border: 0
    },
    izyVest: {
        background: "#FFFFFF 0% 0% no-repeat padding-box !important",
        fontSize: "12px",
        marginRight: "7%",
        border: 0,
        color: "#032F4A",
        fontWeight: "bold",
        boxShadow: "1px 20px 30px #332A7C24",
        textTransform: "none",
        backgroundColor: "FFFFFF !important",
        padding: "3% 7%",
        borderRadius: "11px"
    },
    izyPayBtn: {
        background: "#FFFFFF 0% 0% no-repeat padding-box !important",
        fontSize: "12px",
        marginRight: "7%",
        border: 0,
        color: "#032F4A",
        fontWeight: "bold",
        boxShadow: "1px 20px 30px #332A7C24",
        textTransform: "none",
        backgroundColor: "FFFFFF !important",
        padding: "3% 7%",
        borderRadius: "11px"
    },
    izyVestBtn: {
        backgroundColor: "#4786FF !important",
        fontSize: "12px",
        marginRight: "7%",
        fontWeight: "bold",
        color: "white",
        textTransform: "none",
        border: 0,
        padding: "3% 7%",
        borderRadius: "11px"
    },
    flex: {
        display: "flex",
        alignItems: "center"
    },
    walletImg: {
        width: "8%",
        marginLeft: "3%"
    },
    transferBtn: {
        backgroundColor: "white !important",
        borderRadius: "11px",
        padding: "3% 0",
        marginBottom: "20px",
        fontWeight: "bold",
        color: "#062239"
    },
    fundBtn: {
        backgroundColor: "#4786FF !important",
        borderRadius: "11px",
        padding: "3% 0",
        marginBottom: "20px",
        fontWeight: "bold",
        color: "white"
    }
}));

// export default useStyles;
