import React, { useState } from "react";
import RentalBillCard from "./RentalBillCard";
import { styled } from "@mui/material";
import CustomBtn from "#/components/buttons/CustomBtn";
import { APP_ROUTES } from "#/constants/routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import useRentalPaymentCart from "../../hooks/useRentalPaymentCart";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@material-ui/core";
import PreferenceModal from "./PreferenceModal";
import { alertError } from "#/store/actions/notification.actions";

// Where payment details object is created and set in LOCALSTORAGE
const handlePayNow = (
  tenant,
  apartmentId,
  rentalCart,
  history,
  dispatch,
  preference,
  setPreference
) => {
  if (rentalCart?.length === 0) {
    return dispatch(alertError("No Selected Payments, Please select a bill to proceed"));
  }

  const paymentDetails = {
    tenant,
    apartmentId,
    rentalCart
  };

  localStorage.setItem("RENTAL_PAYMENTS", JSON.stringify(paymentDetails));

  // Delay the state update and routing decision until after the render cycle is complete
  setTimeout(() => {
    if (preference?.data || rentalCart[0]?.name === "Monthly Rent") {
      history.push(APP_ROUTES.dashboard.rentalpayments);
    } else {
      setPreference(true); // Open the modal if no preference data exists
    }
  }, 0);
};

// This makes use of the useRentalPaymentCart HOOKs
const RentalBillsList = ({ billsList, preferenceData, liveizyID, refetch }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [preference, setPreference] = useState(false);
  const { tenantRecord } = useSelector((store) => store.tenant);

  const tenant = tenantRecord[0];
  const apartmentId = tenantRecord?.[0]?.apartment_id?.apartment_id;

  const UNPAID_BILLS_WITH_PAID = billsList?.filter((bill) => {
    const result =
      bill.status === "unpaid" ||
      bill.status === "incomplete" ||
      (bill.status === "paid" && isUpdatedAtWithinLastDay(bill.updatedAt));
    return result;
  });

  function isUpdatedAtWithinLastDay(updatedAt) {
    const ONE_DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000; // One day in milliseconds
    const now = new Date();
    const updatedAtDate = new Date(updatedAt);
    const timeDifference = now - updatedAtDate;

    return timeDifference < ONE_DAY_IN_MILLISECONDS;
  }

  // IF UNPAID BILLS ARRAY HAS A BILL WITH MONTHLY RENT, FILTER OUT THE HOUSE RENT BILL
  const filteredHouseRent = (bills) => {
    const hasMonthlyRent = bills?.some((bill) => bill.name === "Monthly Rent");

    // if (hasMonthlyRent) {
    //   return bills?.filter((bill) => bill.name !== "House Rent");
    // }
    return bills;
  };

  // CALLING THE FILTERED BILL FUNCTION
  const filtered_bills = filteredHouseRent(UNPAID_BILLS_WITH_PAID);
  const { rentalPaymentCart, handleSelectItem } = useRentalPaymentCart(billsList);

  return (
    <>
      {billsList?.length > 0 ? (
        <>
          <Wrapper>
            {filtered_bills.map((bill) => {
              return (
                <RentalBillCard
                  key={bill._id}
                  bill={bill}
                  handleSelectBill={handleSelectItem}
                  preference={preferenceData}
                />
              );
            })}
          </Wrapper>
          <CustomBtn
            style={{ margin: "0 auto", padding: "0.45rem 2.5rem" }}
            onClick={() =>
              handlePayNow(
                tenant,
                apartmentId,
                rentalPaymentCart,
                history,
                dispatch,
                preferenceData,
                setPreference
              )
            }>
            Generate Invoice
          </CustomBtn>
        </>
      ) : (
        <em>No Next Payments.</em>
      )}

      <Modal
        open={preference}
        onClose={() => setPreference(false)}
        className="tw-flex tw-justify-center tw-items-center tw-w-full">
        <PreferenceModal
          lid={liveizyID}
          apartmentID={apartmentId}
          refetch={refetch}
          setModal={setPreference}
          tenant={tenant}
          rentalPaymentCart={rentalPaymentCart}
          history={history}
        />
      </Modal>
    </>
  );
};

export default RentalBillsList;

const Wrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.45rem;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    justify-content: start;
    gap: 1rem;
  }
`;
