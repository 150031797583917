/* eslint-disable no-useless-constructor */
import React from "react";
import "./rentmodal.scss";

import Usersvg from '../../assets/user.svg';

// components
import Modalsent from "./modalsent.jsx";

class Rentmodal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {showForm: false}
  }
  showForm = () => {
    return (
      <div>
          {/* Second Form */}
          <div className="container">
                  <h6 className="modal-subheading mt-4 mb-4">
                    <img src={Usersvg} alt="user" className="mr-1"/> Previous Residence
                  </h6>
                    <form>
                        <div className="row">
                        <div className="col-6">
                        <label className="modal-formlabel" for="landlord name">
                          Lanlord/Manager Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Chukwu Kelechi"
                        />
                      </div>
                      <div className="col-6">
                        <label
                          className="modal-formlabel"
                          for="lanlord contact"
                        >
                          Lanlord/Manager Contact
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Chukwu Kelechi"
                        />
                      </div>
                      <div className="col-6 mt-3">
                        <label
                          className="modal-formlabel"
                          for="address"
                        >
                          Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                      <div className="col-6 mt-3">

                      </div>
                      <div className="container">
                      <h6 className="modal-subheading mt-4 mb-4">
                        <img src={Usersvg} alt="user" className="mr-1"/> Emergency Contact
                      </h6>
                      <div className="row">
                      <div className="col-6">
                        <label className="modal-formlabel" for="name">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Chukwu Kelechi"
                        />
                      </div>
                      <div className="col-6">
                        <label
                          className="modal-formlabel"
                          for="contact"
                        >
                          Mobile Contact
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="080-303-3939-333"
                        />
                      </div>
                      <div className="col-6 mt-3">
                        <label className="modal-formlabel" for="Relationship">
                          Relationship
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Chukwu Kelechi"
                        />
                      </div>
                      <div className="col-6 mt-3">
                        <label
                          className="modal-formlabel"
                          for="address"
                        >
                          Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Chukwu Kelechi"
                        />
                      </div>
                      <div className="container mt-3 padding">
                      <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" id="exampleCheck1"/>
                        <label class="form-check-label" for="exampleCheck1">I declare this information is valid</label>
                      </div>
                      <span className="modalnote">Note:</span><label class="form-check-label" for="note"> Please provide correct details, all details will be verified</label>
                      <div class="modal-footer mt-5">
                        <button type="button" class="btn btn-light" onClick={() => this.setState({showForm: false}) }>Back</button>
                        <button type="submit" class="btn btn-primary btn-submit">Submit</button>
                      </div>
                      </div>
                      </div>
                      </div>
                        </div>
                    </form>
                  </div>
              {/* End Of Second Form */}
      </div>
    )
  }


  render() {
    return (
      <React.Fragment>
        <div
          className="modal fade bd-example-modal-lg"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Application to rent
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              
              
              <div className="modal-body">
              {this.state.showForm ? this.showForm() : 
              <React.Fragment>
                <div className="container">
                  <h6 className="modal-subheading mt-4 mb-4">
                    <img src={Usersvg} alt="user" className="mr-1"/> Personal Information
                  </h6>
                  <form>
                    <div className="row">
                      <div className="col-6">
                        <label className="modal-formlabel" for="full name">
                          Full Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Chukwu Kelechi"
                        />
                      </div>
                      <div className="col-6">
                        <label
                          className="modal-formlabel"
                          for="lanlord contact"
                        >
                          Known Name By Previous Manager/landlord
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Chukwu Kelechi"
                        />
                      </div>
                      <div className="col-6 mt-3">
                        <label
                          className="modal-formlabel"
                          for="email"
                        >
                          Email Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="chukwukelechi@gmail.com"
                        />
                      </div>
                      <div className="col-3 mt-3">
                      <label
                          className="modal-formlabel"
                          for="gender"
                        >
                          Gender
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="select"
                        />
                      </div>
                      <div className="col-3 mt-3">
                      <label
                          className="modal-formlabel"
                          for="lanlord contact"
                        >
                          Marital Status
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="select"
                        />
                      </div>

                      <div className="col-6 mt-3">
                        <label
                          className="modal-formlabel"
                          for="email"
                        >
                          Phone Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                      <div className="col-3 mt-3">
                      <label
                          className="modal-formlabel"
                          for="state"
                        >
                          State Of Origin
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="select"
                        />
                      </div>
                      <div className="col-3 mt-3">
                      <label
                          className="modal-formlabel"
                          for="Monthly income"
                        >
                          Monthly Income
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="select"
                        />
                      </div>
                      
                      <div className="col-6 mt-3">
                        <label className="modal-formlabel" for="full name">
                          Occupation
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                      <div className="col-6 mt-3">
                        <label
                          className="modal-formlabel"
                          for="Employer's Name/Business Name"
                        >
                          Employer's Name/Business Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                      <div className="col-6 mt-3">
                        <label
                          className="modal-formlabel"
                          for="Employer's/Business Email Address"
                        >
                          Employer's/Business Email Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                      <div className="col-6 mt-3">
                        <label
                          className="modal-formlabel"
                          for="Employer's Business Address"
                        >
                          Employer's Business Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                        />
                      </div>

                      
                      <div className="col-6 mt-3">
                        <label
                          className="modal-formlabel"
                          for="Employer's Mobile Contact"
                        >
                          Employer's Mobile Contact
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                      <div className="col-3 mt-3">
                      <label
                          className="modal-formlabel idno"
                          for="Identification"
                        >
                          Means Of Identification
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="select"
                        />
                      </div>
                      <div className="col-3 mt-3">
                      <label
                          className="modal-formlabel"
                          for="Identification Number"
                        >
                          Identification Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="98273789372832gd8"
                        />
                      </div>
                    </div>
                  </form>
                
              </div>
              

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary btn-next"
                  onClick={() => this.setState({showForm: true}) }
                >
                  Next
                </button>
              </div>
              </React.Fragment>
  }
              </div>
            </div>
          </div>
        </div>

        <Modalsent />
      </React.Fragment>
    );
  }
}

export default Rentmodal;
