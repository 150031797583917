import React from "react";
// import { MuiThemeProvider } from '@material-ui/core/styles';
import { Grid, Button, Divider, Box } from "@material-ui/core";
import { useStylesM, TypographyText } from "./leftStyles";
import logo from "../../../assets/other images/liveizy-logo-1.png";
import whatsappIcon from "../../../assets/other images/whatsapp-icon.svg";
import youtubeIcon from "../../../assets/other images/youtube-icon.svg";
import dashIcon from "../../../assets/other images/dash-icon.svg";
import squareIcon from "../../../assets/other images/square-icon.svg";
import quote from "../../../assets/other images/quote.svg";
import kolfadLogo from "../../../assets/images/kolfad_logo.jpeg";
import { useStyles } from "../../Wallet/leftCol/style";

const izyInvestUses = [
  {
    use:
      "You can save daily, weekly, monthly, anytime you choose within the tenure you set for your fixed savings."
  },
  {
    use: "You can not change the tenure date you set once the plan is active. "
  },
  {
    use:
      "You can not terminate your plan, it automatically terminates on the date you set."
  },
  {
    use: "You can create more than one plan and use different bank details."
  },
  {
    use: "Each plans has its least expected transactions."
  }
];

const LeftCol = (props) => {
  const classes = useStylesM();
  const classesM = useStyles();
  const goizyPay = () => {
    props.history.push("/wallet");
  };
  const goizyInvest = () => {
    props.history.push({ pathname: "/wallet", state: { openizyInvest: true } });
  };

  return (
    <>
      <Grid container justify="center" className={classes.container}>
        <Grid item md={12} sm={9} xs={12}>
          <div className="tw-w-full tw-flex tw-items-center tw-justify-center md:tw-justify-normal md:tw-items-start">
            <div className="tw-w-[282px] md:tw-mb-20 tw-mb-10 tw-flex tw-items-center tw-justify-between tw-h-[60px] tw-bg-white tw-shadow-[0px_4px_18px_-6px_rgba(0,0,0,0.25)] tw-rounded-xl">
              <div
                onClick={goizyPay}
                className={`tw-cursor-pointer ${
                  props.history.pathname === "/wallet"
                    ? "tw-bg-[#10609F] tw-text-white tw-rounded-xl tw-transition tw-duration-500"
                    : "tw-bg-transparent tw-text-[#052137]"
                }  tw-flex tw-items-center tw-justify-center tw-w-[150px] tw-h-full`}>
                <p className={`tw-text-lg tw-font-semibold`}>izyPay</p>
              </div>
              <div
                onClick={goizyInvest}
                className={`tw-cursor-pointer tw-bg-[#10609F] tw-transition tw-duration-500 tw-text-white tw-rounded-xl tw-flex tw-items-center tw-justify-center tw-w-[150px] tw-h-full `}>
                <p className={`tw-text-lg tw-font-semibold`}>izyInvest</p>
              </div>
            </div>
          </div>
          {/* <Grid item className={classes.btnmargin}>
            <button className={classesM.izyPayBtn} onClick={goizyPay}>
              
              izyPay
            </button>
            <button className={classesM.izyVestBtn} onClick={goizyInvest}>
       
              izyInvest
            </button>
          </Grid> */}
          {/* <Divider className={classes.hr1} /> */}

          <div className="tw-flex tw-flex-col tw-gap-[30px] tw-mt-8">
            <div className="flex tw-items-center">
              <img src={dashIcon} alt="dash-icon" />
              <p className="tw-text-[#052137] md:tw-text-lg tw-text-base tw-font-semibold tw-ml-2">
                Welcome to izyInvest
              </p>
            </div>
            <p className="tw-text-[#10609F] md:tw-text-lg tw-text-sm tw-font-semibold">
              THINGS YOU NEED TO KNOW
            </p>

            <div className="lg:tw-w-[480px] tw-w-full tw-flex tw-flex-col tw-gap-3">
              {izyInvestUses.map((el, idx) => (
                <div className="flex tw-items-baseline tw-gap-2" key={idx}>
                  <img
                    src={squareIcon}
                    alt="square-icon"
                    className="tw-w-[8px] tw-h-[8px] md:tw-w-[12px] md:tw-h-[12px]"
                  />
                  <p className="tw-text-[#052137] md:tw-text-lg tw-text-xs tw-font-semibold">
                    {el.use}
                  </p>
                </div>
              ))}
            </div>

            <p className="tw-text-[#052137] tw-text-xs md:tw-text-lg tw-font-semibold lg:tw-w-[459px] tw-w-full">
              Contact us via whatsapp or visit our youtube channel to see how to setup
            </p>

            <div className="tw-flex tw-items-center tw-gap-2">
              <a
                target={"_blank"}
                rel="noreferrer"
                href="https://wa.me/message/2F6AECZ75SSDD1">
                <img
                  src={whatsappIcon}
                  alt="whatsapp-icon"
                  className="tw-w-[4rem] tw-h-[4rem]"
                />
              </a>

              <a
                target={"_blank"}
                rel="noreferrer"
                href="https://wa.me/message/2F6AECZ75SSDD1">
                <img
                  src={youtubeIcon}
                  alt="youtube-icon"
                  className="tw-w-[4rem] tw-h-[4rem]"
                />
              </a>
            </div>
          </div>
          {/* <Grid item style={{ marginTop: "55px" }}>
            <Grid>
              <Grid className={classes.hrPosition}>
                <span className={classes.span}>
                  <hr className={classes.hr2} />
                </span>
                <Box>
                  <TypographyText
                    variant="subtitle2"
                    style={{ fontWeight: "bold !important" }}>
                    Welcome to izyInvest
                  </TypographyText>
                </Box>
              </Grid>
              <TypographyText variant="h5" style={{ marginTop: "28px" }}>
                THINGS YOU NEED TO KNOW
              </TypographyText>
              {izyInvestUses.map((uses, index) => {
                return (
                  <Box m={1} className={classes.quoteText} key={index}>
                    <span className={classes.blueDot}></span>
                    <TypographyText>{uses.use}</TypographyText>
                    <br></br>
                  </Box>
                );
              })}

              <Grid style={{ width: "84%" }}>
                <TypographyText variant="caption">
                  Contact us via whatsapp or visit our youtube channel to see how to setup
                </TypographyText>
              </Grid>
              <img src={logo} alt="logo" className={classes.logo} />
              <br></br>

              <Grid>
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href="https://wa.me/message/2F6AECZ75SSDD1">
                  <img
                    src={whatsappIcon}
                    alt="whatsapp-icon"
                    className={classes.whatsapp}
                  />
                </a>

                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href="https://wa.me/message/2F6AECZ75SSDD1">
                  <img src={youtubeIcon} alt="youtube-icon" className={classes.youtube} />
                </a>
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>
    </>
  );
};

export default LeftCol;
