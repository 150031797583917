import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const CustomInputField = ({
    name,
    label,
    placeholder,
    type = "text",
    value,
    handleOnChange,
    errors,
    handleOnBlur,
    disabled = false,
    customRef,
    styles = {},
    boxClasses,
    readOnly = false
}) => {
    const classes = useStyles();

    return (
        <div className={classes.inputField} style={styles}>
            <label htmlFor={name} className={boxClasses}>
                {label}
            </label>
            <input
                name={name}
                type={type}
                placeholder={placeholder}
                onChange={handleOnChange}
                value={value}
                onBlur={handleOnBlur}
                disabled={disabled}
                ref={customRef}
                readOnly={readOnly}
            />
            {errors && <div className={classes.errorMessage}>{errors}</div>}
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    inputField: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginBottom: "30px",

        "& label": {
            marginBottom: "10px",
            fontSize: "14px",
            fontWeight: "700",
            whiteSpace: "nowrap",
            color: "#043B5C",
            fontFamily: "Open Sans-Semibold"
        },

        "& input": {
            borderRadius: "5px",
            border: "1px solid #043B5C69",
            padding: "10px 25px",
            width: "100%",

            "&:read-only": {
                cursor: "not-allowed",
                backgroundColor: "rgba(0,0,0,0.1)"
            }
        }
    },
    errorMessage: {
        color: "red",
        fontSize: "0.8rem",
        marginTop: "5px"
    }
}));

export default CustomInputField;
