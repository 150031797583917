import React from "react";
import { makeStyles } from "@material-ui/core";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { Link } from "react-router-dom";

const styles = makeStyles((theme) => ({
    backArrow: {
        display: "flex",
        alignItems: "center",
        margin: "20px 0",
        opacity: 0.5,
        cursor: "pointer",
        "& svg": {
            fontSize: 20
        }
    }
}));

const BackArrow = ({ link }) => {
    const classes = styles();

    return (
        <div className={classes.backArrow}>
            <Link to={link || "/estate-management/dashboard"}>
                <ArrowCircleLeftIcon /> back
            </Link>
        </div>
    );
};

export default BackArrow;
