import {
  LOGIN,
  LOGIN_FAIL,
  SIGNUP,
  LOGOUT,
  // UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_INFO,
  SAVE_TOKEN,
  SERVICE_POPUP,
  ASSIGN_SERVICE_ROLES_SUCCESS,
  SET_FIRST_TIME,
  VALIDATE_USER_ID_SUCCESS,
  VALIDATE_USER_ID_FAIL,
  VALIDATE_USER_ID,
  SENDING_EMAIL_SUCCESS,
  SENDING_EMAIL_PENDING,
  SENDING_EMAIL_FAIL,
  SEND_FORGET_PASSWORD_PENDING,
  SEND_FORGET_PASSWORD_FAIL,
  SEND_FORGET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_PENDING,
  RESET_PASSWORD_SUCCESS,
  UPDATE_USER_OTHER_INFORMATION_FAIL,
  UPDATE_USER_OTHER_INFORMATION_PENDING,
  UPDATE_USER_OTHER_INFORMATION_SUCCESS,
  UPDATE_PROFILE_INFORMATION_PENDING,
  UPDATE_PROFILE_INFORMATION_SUCCESS,
  UPDATE_PROFILE_INFORMATION_FAIL,
  GET_PROFILE_INFORMATION_PENDING,
  GET_PROFILE_INFORMATION_SUCCESS,
  GET_PROFILE_INFORMATION_FAIL,
  UPDATE_BUSINESS_INFORMATION_PENDING,
  UPDATE_BUSINESS_INFORMATION_SUCCESS,
  UPDATE_BUSINESS_INFORMATION_FAIL,
  GET_BUSINESS_INFORMATION_PENDING,
  GET_BUSINESS_INFORMATION_SUCCESS,
  GET_BUSINESS_INFORMATION_FAIL,
  GET_USER_OTHER_INFORMATION_FAIL,
  GET_USER_OTHER_INFORMATION_PENDING,
  GET_USER_OTHER_INFORMATION_SUCCESS
} from "../constants";

const initialState = {
  isAuthenticated: false,
  firstTime: null,
  user: {},
  token: null,
  popup: false,
  role: null,
  liveIzyUser: "",
  loading: false,
  err: "",
  message: "",
  sending: false,
  updatingOtherInfo: false,
  otherInformation: {},
  updatingInfo: false,
  profileInformation: {},
  businessInformation: {}
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        token: action.token,
        user: action.user,
        isAuthenticated: true
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        errorMsg: action.errorMsg
      };
    case SIGNUP:
      return {
        ...state,
        token: action.token,
        user: action.user,
        isAuthenticated: true
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false
      };
    // case UPDATE_USER_PROFILE:
    //   return {
    //     ...state,
    //     user:null,
    //   }
    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        user: action.payload
      };
    // case UPDATE_USER_PROFILE_FAIL:
    //   return {
    //     ...state
    //   }
    case SAVE_TOKEN:
      return {
        ...state,
        token: action.token,
        user: action.user,
        isAuthenticated: true
      };
    case UPDATE_USER_INFO:
      return {
        ...state,
        user: action.user
      };
    case SERVICE_POPUP:
      return {
        ...state,
        popup: action.popup
      };
    case ASSIGN_SERVICE_ROLES_SUCCESS:
      return {
        ...state,
        role: action.role
      };
    case SET_FIRST_TIME:
      return {
        ...state,
        firstTime: false
      };
    case VALIDATE_USER_ID:
      return {
        ...state,
        loading: true
      };
    case VALIDATE_USER_ID_SUCCESS:
      return {
        ...state,
        liveIzyUser: action.liveIzyUser,
        loading: false
      };
    case VALIDATE_USER_ID_FAIL:
      return {
        ...state,
        loading: false,
        err: action.err
      };
    case SENDING_EMAIL_PENDING:
      return {
        ...state,
        sending: true
      };
    case SENDING_EMAIL_SUCCESS:
      return {
        ...state,
        message: action.message,
        sending: false
      };
    case SENDING_EMAIL_FAIL:
      return {
        ...state,
        sending: false
      };
    case SEND_FORGET_PASSWORD_PENDING:
      return {
        ...state,
        sending: true
      };
    case SEND_FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        msg: action.msg,
        sending: false
      };
    case SEND_FORGET_PASSWORD_FAIL:
      return {
        ...state,
        sending: false
      };
    case RESET_PASSWORD_PENDING:
      return {
        ...state
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state
      };
    case RESET_PASSWORD_FAIL:
      return {
        ...state
      };
    case UPDATE_USER_OTHER_INFORMATION_PENDING:
      return {
        ...state,
        updatingOtherInfo: true
      };
    case UPDATE_USER_OTHER_INFORMATION_SUCCESS:
      return {
        ...state,
        updatingOtherInfo: false,
        otherInformation: action.payload
      };
    case UPDATE_USER_OTHER_INFORMATION_FAIL:
      return {
        ...state,
        updatingOtherInfo: false
      };
    case UPDATE_PROFILE_INFORMATION_PENDING:
      return {
        ...state,
        updatingInfo: true
      };
    case UPDATE_PROFILE_INFORMATION_SUCCESS:
      return {
        ...state,
        updatingInfo: false,
        otherInformation: action.payload
      };
    case UPDATE_PROFILE_INFORMATION_FAIL:
      return {
        ...state,
        updatingInfo: false
      };
    case GET_PROFILE_INFORMATION_PENDING:
      return {
        ...state,
        updatingInfo: true
      };
    case GET_PROFILE_INFORMATION_SUCCESS:
      return {
        ...state,
        updatingInfo: false,
        profileInformation: action.payload
      };
    case GET_PROFILE_INFORMATION_FAIL:
      return {
        ...state,
        updatingInfo: false
      };
    case UPDATE_BUSINESS_INFORMATION_PENDING:
      return {
        ...state,
        updatingInfo: true
      };
    case UPDATE_BUSINESS_INFORMATION_SUCCESS:
      return {
        ...state,
        updatingInfo: false
      };
    case UPDATE_BUSINESS_INFORMATION_FAIL:
      return {
        ...state,
        updatingInfo: false
      };
    case GET_BUSINESS_INFORMATION_PENDING:
      return {
        ...state,
        updatingInfo: true
      };
    case GET_BUSINESS_INFORMATION_SUCCESS:
      return {
        ...state,
        updatingInfo: false,
        businessInformation: action.payload
      };
    case GET_BUSINESS_INFORMATION_FAIL:
      return {
        ...state,
        updatingInfo: false
      };
    case GET_USER_OTHER_INFORMATION_PENDING:
      return {
        ...state,
        updatingOtherInfo: true
      };
    case GET_USER_OTHER_INFORMATION_SUCCESS:
      return {
        ...state,
        updatingOtherInfo: false,
        otherInformation: action.payload
      };
    case GET_USER_OTHER_INFORMATION_FAIL:
      return {
        ...state,
        updatingOtherInfo: false
      };

    default:
      return state;
  }
};

export default authReducer;
