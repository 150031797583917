import React from "react";
import { Slide, Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  emailVerify: {
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #45B29D",
    borderRadius: "3px",
    backgroundColor: "#74D5C226",
    padding: "10px 50px",
    marginBottom: "30px",
    fontSize: "17px",
    fontWeight: "bold",

    [theme.breakpoints.down("sm")]: {
      padding: "10px"
    }
  },
  textOne: {
    color: "#043B5C",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px"
    }
  },
  subText: {
    color: "#E41A1A",
    textDecoration: "underline",
    cursor: "pointer"
  },
  subText2: {
    color: "green",
    textDecoration: "underline",
    cursor: "pointer"
  },
  textTwo: {
    color: "#043B5C",
    fontSize: "15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px"
    }
  },
  spinner: {
    margin: "0 auto"
  }
}));

const RenewalAlert = ({ renewal, setShowRenewalAlert, onClose, setRenew, setRetry }) => {
  const classes = useStyles();

  const handleRenew = () => {
    setRenew(true);
    setRetry(true);
  };

  const renderPending = () => (
    <Slide direction="down" in={true} timeout={1500} mountOnEnter unmountOnExit>
      <Box className={classes.emailVerify}>
        <span className={classes.textOne}>
          You are due for a loan rent renewal. Do you want to renew? &nbsp;&nbsp;&nbsp;
          <Button variant="contained" color="primary" onClick={() => setRenew(true)}>
            Yes
          </Button>
          &nbsp;&nbsp;
          <Button variant="contained" color="primary" onClick={onClose}>
            No
          </Button>
        </span>
      </Box>
    </Slide>
  );

  const renderOngoing = () => (
    <Slide direction="down" in={true} timeout={1500} mountOnEnter unmountOnExit>
      <Box className={classes.emailVerify}>
        <span className={classes.textOne}>
          You have successfully applied for loan renewal. Please wait for admin approval.
          &nbsp;&nbsp;&nbsp;
          <Button
            variant="contained"
            color="primary"
            onClick={() => alert("Details Coming soon")}>
            View Details
          </Button>
          &nbsp;&nbsp;
          <Button variant="contained" color="primary" onClick={onClose}>
            Close
          </Button>
        </span>
      </Box>
    </Slide>
  );

  const renderRejected = () => (
    <Slide direction="down" in={true} timeout={1500} mountOnEnter unmountOnExit>
      <Box className={classes.emailVerify}>
        <span className={classes.textOne}>
          Your loan request was rejected by the admin. &nbsp;&nbsp;&nbsp;
          <Button variant="contained" color="primary" onClick={handleRenew}>
            Retry
          </Button>
          &nbsp;&nbsp;
          <Button variant="contained" color="primary" onClick={onClose}>
            Close
          </Button>
        </span>
      </Box>
    </Slide>
  );

  const alertBody = () => {
    if (!renewal || !renewal.data) {
      return null; // Handle undefined or null renewal safely
    }

    const { renewalStatus, adminStatus, is_applied } = renewal?.data;

    if (renewalStatus && !adminStatus && is_applied === "pending") {
      return renderPending();
    }

    if (renewalStatus && is_applied === "ongoing") {
      return renderOngoing();
    }

    if (renewalStatus && is_applied === "rejected") {
      return renderRejected();
    }

    if (renewalStatus && is_applied === "accepted") {
      setShowRenewalAlert(false);
      return null;
    }

    return null;
  };

  return alertBody();
};

export default RenewalAlert;
