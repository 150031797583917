import { nigerianPhoneNumberPattern } from "#/components/utils/util";
import * as Yup from "yup";

import rentLoanFormModel from "./rentLoanFormModel";
const {
  formField: {
    profileImage,
    bvn,
    surname,
    firstName,
    otherNames,
    gender,
    dateOfBirth,
    stateOfOrigin,
    lga,
    email,
    alternateEmail,
    mobileNumber,
    alternateMobileNumber,
    meansOfId,
    meansOfIdFile,
    employmentStatus,
    nameOfEmployer,
    officeAddress,
    monthlyIncome,
    nameOfOfficeContactPerson,
    officeContactPersonNumber,
    accountStatementDoc,
    homeAddress,
    laid,
    rentRange,
    rentValue,
    nameOfManager,
    contactOfManager,
    nameOfLandlord,
    contactOfLandlord,
    contactOfNextOfKin,
    contactOfreference,
    emailOfNextOfKin,
    nameOfNextOfKin,
    nameOfReference,
    nextOfKinHomeAddress
  }
} = rentLoanFormModel;

const TYPE_NUMBER_ERROR_MSG = "Must be a Number.";
const TYPE_DATE_ERROR_MSG = "Must be a date type.";

// const mobileNumberLengthCheck = (value) => {
//   return !isNaN(value) && `${value}`.length >= 10;
// };

const calculateMinDate = () => {
  return new Date().toISOString().split("T")[0];
};

const calculateMaxDate = () => {
  const today = new Date();
  const minDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

  return minDate.toISOString().split("T")[0];
};

const fileTypeCheck = (value, typesArray) => {
  if (typeof value === "string") {
    return /^https?:\/\/.+\.(jpg|jpeg|png)$/.test(value);
  }
  return !value || (value && typesArray.includes(value?.type));
};

const documentfileTypeCheck = (value, typesArray) => {
  if (typeof value === "string") {
    return /^https?:\/\/.+\.(pdf|docx)$/.test(value);
  }
  return !value || (value && typesArray.includes(value?.type));
};

const personalDetails = Yup.object().shape({
  [profileImage.name]: Yup.mixed()
    .nullable()
    .required(`${profileImage.requiredErrorMsg}`)
    .test("File_type", `${profileImage.invalidFiletypeErrorMsg}`, (value) =>
      fileTypeCheck(value, profileImage.acceptedFileTypes)
    ),
  [bvn.name]: Yup.number()
    .typeError(TYPE_NUMBER_ERROR_MSG)
    .required(`${bvn.requiredErrorMsg}`)
    .test(
      "Length",
      `${bvn.invalidLengthErrorMsg}`,
      (value) => !isNaN(value) && `${value}`.length === bvn.bvnCount
    ),
  [surname.name]: Yup.string().required(`${surname.requiredErrorMsg}`),
  [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
  [otherNames.name]: Yup.string(),
  [gender.name]: Yup.string().required(`${gender.requiredErrorMsg}`),
  [dateOfBirth.name]: Yup.date()
    .typeError(TYPE_DATE_ERROR_MSG)
    .test("dateOfBirth", "You must be 18 or older", function (value) {
      const today = new Date();
      today.setFullYear(today.getFullYear() - 18);
      return value <= today;
    })
    .required(`${dateOfBirth.requiredErrorMsg}`),
  [stateOfOrigin.name]: Yup.string().required(`${stateOfOrigin.requiredErrorMsg}`),
  [lga.name]: Yup.string().required(`${lga.requiredErrorMsg}`),
  [email.name]: Yup.string()
    .email(`${email.invalidErrorMsg}`)
    .required(`${email.requiredErrorMsg}`),
  [alternateEmail.name]: Yup.string().email(`${alternateEmail.invalidErrorMsg}`),
  [mobileNumber.name]: Yup.string()
    .required(`${mobileNumber.requiredErrorMsg}`)
    .matches(nigerianPhoneNumberPattern, `${mobileNumber.invalidLengthErrorMsg}`),
  [alternateMobileNumber.name]: Yup.string().matches(
    nigerianPhoneNumberPattern,
    `${alternateMobileNumber.invalidLengthErrorMsg}`
  ),
  [meansOfId.name]: Yup.string().required(`${meansOfId.requiredErrorMsg}`),
  [meansOfIdFile.name]: Yup.mixed()
    .nullable()
    .required(`${meansOfIdFile.requiredErrorMsg}`)
    .test("File_type", `${meansOfIdFile.invalidFiletypeErrorMsg}`, (value) =>
      fileTypeCheck(value, meansOfIdFile.acceptedFileTypes)
    )
});

const jobDetails = Yup.object().shape({
  [employmentStatus.name]: Yup.string().required(`${employmentStatus.requiredErrorMsg}`),
  [nameOfEmployer.name]: Yup.string().required(`${nameOfEmployer.requiredErrorMsg}`),
  [officeAddress.name]: Yup.string().required(`${officeAddress.requiredErrorMsg}`),
  [monthlyIncome.name]: Yup.string()
    .matches(/^\d+$/, "Please enter only digits")
    .required(`${monthlyIncome.requiredErrorMsg}`),
  [nameOfOfficeContactPerson.name]: Yup.string().when([employmentStatus.name], {
    is: (employmentStatus) => employmentStatus == "Self Employed",
    then: Yup.string(),
    otherwise: Yup.string().required(`${officeContactPersonNumber.requiredErrorMsg}`)
  }),
  [officeContactPersonNumber.name]: Yup.string().when([employmentStatus.name], {
    is: (employmentStatus) => employmentStatus == "Self Employed",
    then: Yup.string().matches(
      nigerianPhoneNumberPattern,
      `${officeContactPersonNumber.invalidLengthErrorMsg}`
    ),
    otherwise: Yup.string()
      .required(`${officeContactPersonNumber.requiredErrorMsg}`)
      .matches(
        nigerianPhoneNumberPattern,
        `${officeContactPersonNumber.invalidLengthErrorMsg}`
      )
  }),
  [accountStatementDoc.name]: Yup.mixed()
    .required(`${accountStatementDoc.requiredErrorMsg}`)
    .test("File_type", `${accountStatementDoc.invalidFiletypeErrorMsg}`, (value) =>
      documentfileTypeCheck(value, accountStatementDoc.acceptedFileTypes)
    )
});

const currentAddressDetails = Yup.object().shape({
  [homeAddress.name]: Yup.string().when([rentRange.name], {
    is: (rentRange) => !rentRange,
    then: Yup.string().required(`${homeAddress.requiredErrorMsg}`),
    otherwise: Yup.string()
  }),
  [laid.name]: Yup.string().nullable(),
  [rentValue.name]: Yup.number().typeError(TYPE_NUMBER_ERROR_MSG),
  // .required(`${rentValue.requiredErrorMsg}`),
  [rentRange.name]: Yup.string().when([laid.name], {
    is: (laid) => laid === null,
    then: Yup.string().nullable().required(`${rentRange.requiredErrorMsg}`),
    otherwise: Yup.string().nullable()
  }),
  [nameOfManager.name]: Yup.string(),
  // .required(`${nameOfManager.requiredErrorMsg}`),
  [contactOfManager.name]: Yup.string()
    // .required(`${contactOfManager.requiredErrorMsg}`)
    .matches(nigerianPhoneNumberPattern, `${contactOfManager.invalidLengthErrorMsg}`),
  [nameOfLandlord.name]: Yup.string(),
  // .required(`${nameOfLandlord.requiredErrorMsg}`),
  [contactOfLandlord.name]: Yup.string()
    // .required(`${contactOfLandlord.requiredErrorMsg}`)
    .matches(nigerianPhoneNumberPattern, `${contactOfLandlord.invalidLengthErrorMsg}`)
});

const referenceDetails = Yup.object().shape({
  [nameOfNextOfKin.name]: Yup.string().required(`${nameOfNextOfKin.requiredErrorMsg}`),
  [contactOfNextOfKin.name]: Yup.string()
    .required(`${contactOfNextOfKin.requiredErrorMsg}`)
    .matches(nigerianPhoneNumberPattern, `${contactOfNextOfKin.invalidLengthErrorMsg}`),
  [emailOfNextOfKin.name]: Yup.string()
    .email(`${emailOfNextOfKin.invalidErrorMsg}`)
    .required(`${emailOfNextOfKin.requiredErrorMsg}`),
  [nextOfKinHomeAddress.name]: Yup.string().required(
    `${nextOfKinHomeAddress.requiredErrorMsg}`
  ),
  [nameOfReference.name]: Yup.string().required(`${nameOfReference.requiredErrorMsg}`),
  [contactOfreference.name]: Yup.string()
    .required(`${contactOfreference.requiredErrorMsg}`)
    .matches(nigerianPhoneNumberPattern, `${contactOfreference.invalidLengthErrorMsg}`)
});

const reviewForm = personalDetails.concat(
  jobDetails.concat(currentAddressDetails.concat(referenceDetails))
);

export default [
  personalDetails,
  jobDetails,
  currentAddressDetails,
  referenceDetails,
  reviewForm
];
