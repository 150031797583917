import { forwardRef } from "react";
import "../buttons/button.scss";

const Button = forwardRef((props, ref) => (
  <>
    <div
      className="app-button"
      style={{
        position: "relative",
        margin: !props.noMargin ? "10px auto" : "",
        maxWidth: props.maxWidth,
        width: props.width
      }}>
      <button
        className={`btn btn--crv ${props.focus ? "focus" : ""} ${
          props.type === "primary"
            ? "btn--primary"
            : props.type === "default"
            ? "btn--default"
            : props.type === "noStyle"
            ? "btn--no-style"
            : props.type === "success"
            ? "btn--success"
            : props.type === "outline"
            ? "btn-outline--blue"
            : props.type === "link"
            ? "btn--link"
            : props.type === "plain"
            ? "btn--plain"
            : props.type === "danger"
            ? "btn--danger"
            : ""
        } ${props.display === "block" ? "btn--block" : ""} ${
          props.loading ? "is-loading" : ""
        } ${props.size === "small" ? "btn--sm" : props.size === "tiny" ? "btn--xs" : ""}`}
        style={{
          padding:
            props.type === "link" ? "" : props.padding ? props.padding : "11px 22px",
          margin: props.margin,
          fontSize:
            props.size === "small" || props.size === "tiny"
              ? "12px"
              : props.type === "link"
              ? ""
              : "15px",
          backgroundColor: props.color,
          width: props.width
        }}
        disabled={props.disabled}
        autoFocus={props.autofocus}
        {...(props.onClick && {
          onClick: props.loading ? () => null : props.onClick
        })}
        {...(props.focus && {
          tabIndex: 0
        })}
        id={props.id}
        {...(props.buttonType && {
          type: props.buttonType
        })}
        ref={ref}>
        {props.children || props.text}
      </button>
    </div>
  </>
));

Button.displayName = "Button";

export default Button;
