import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
    root: {
        backgroundColor: '#d2e4f7',
        borderRadius: 5,
        height: '130vh',
        [`${theme.breakpoints.down('900')} and (orientation: portrait)`]: {
            height: '30vh',
            marginBottom: 10,
        },
        [`${theme.breakpoints.down('900')} and (orientation: landscape)`]: {
            height: '53vh',
            marginBottom: 10,
        },
        '& .status-container-paragraph': {
            fontSize: 14,
            color: '#032F4A',
            fontWeight: 'bold',
        },
        '& .status-container, .menu-div': {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '12px',
            color: '#032F4A',
        },
        '& .status-item': {
            borderRadius: 5,
            backgroundColor: '#fff !important',
        },
        '& .status-text': {
            fontSize: 11,
            fontWeight: 700,
            color: '#032F4A',
        },
        '& .tablet-size': {
            [theme.breakpoints.down('1030')]: {
                display: 'block',
            },
            [theme.breakpoints.down('900')]: {
                display: 'inline-block',
            },
        },
        '& button': {
            padding: '6px 10px',
            borderRadius: 5,
            fontSize: 10,
            color: '#fff',
            border: 0,
            width: 60,
        },
        '& .btn-paid': {
            backgroundColor: '#2adbc6',
        },
        '& .btn-overdue': {
            backgroundColor: '#db3535',
        },
    },
});

const InvoiceDetailMenu = (props) => {
    return <div className={props.classes.root}>
                <div className="menu-div">
                    <Typography className="status-container-paragraph" variant="body1"  component="p">
                        Status
                    </Typography>
                    <Typography className="status-container-paragraph" variant="body1"  component="p">
                        Applicant
                    </Typography>
                </div>
                <div className="status-container status-item">
                    <div>
                        <button className="btn-paid">Paid</button>
                    </div>
                    <div>
                        <Typography className="status-text" variant="body1"  component="p">
                            Icreate Solution|<span className="tablet-size">Suite 0001</span><span style={{display: 'block'}}>Jun 17,2020</span>
                        </Typography>
                    </div>
                </div>
                <div className="status-container">
                    <div>
                        <button className="btn-overdue">Overdue</button>
                    </div>
                    <div>
                        <Typography className="status-text" variant="body1"  component="p">
                            Icreate Solution|<span className="tablet-size">Suite 0001</span><span style={{display: 'block'}}>Jun 17,2020</span>
                        </Typography>
                    </div>
                </div>
            </div>
}

export default withStyles(styles)(InvoiceDetailMenu);
