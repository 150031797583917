/* eslint-disable no-useless-constructor */
import React from "react";
import { Link } from "react-router-dom";
import "./listing.styles.scss";
// images
import listapart from "../../assets/images/tenant/listapart.png";
import listapart2 from "../../assets/images/tenant/listapart2.png";
import listapart3 from "../../assets/images/tenant/listapart3.png";
import Location from "../../assets/images/tenant/location.svg";

// icons
// import PinDropOutlinedIcon from "@material-ui/icons/PinDropOutlined";

class Listing extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <div className="row mb-5">
          <div className="col-sm-12 col-md-4">
            <Link to="#">
              <button className="btn btn-green list-status-button">
                Monthly
              </button>
              <div className="card" style={{ width: "20rem" }}>
                <img className="card-img-top" src={listapart} alt="apartment" />
                <div className="overlay">
                  <button className="btn btn-grey text">Preview</button>
                </div>
                <div className="card-body">
                  <div className="card-text">
                    <p className="text-color" style={{ fontWeight: "600" }}>
                      Chevron Drive Estate
                    </p>
                    <p
                      className="text-color"
                      style={{
                        fontSize: "12px",
                        marginTop: "-16px",
                        marginLeft: "-2px",
                      }}
                    >
                      <img
                        src={Location}
                        alt="location"
                        style={{ width: "13px", height: "17px" }}
                      />
                      132 Chevron drive, New york Nigeria 3 bedrooms, 2
                      bathrooms
                    </p>
                    <p
                      className="text-color"
                      style={{
                        fontSize: "12px",
                        marginTop: "-8px",
                        marginLeft: "-2px",
                      }}
                    >
                      ₦20,000.00
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-sm-12 col-md-4">
            <Link to="#">
              <button className="btn btn-green list-status-button">
                Monthly
              </button>
              <div className="card" style={{ width: "20rem" }}>
                <img
                  className="card-img-top"
                  src={listapart2}
                  alt="apartment"
                />
                <div className="overlay">
                  <button className="btn btn-grey text">Preview</button>
                </div>
                <div className="card-body">
                  <div className="card-text">
                    <p className="text-color" style={{ fontWeight: "600" }}>
                      Chevron Drive Estate
                    </p>
                    <p
                      className="text-color"
                      style={{
                        fontSize: "12px",
                        marginTop: "-16px",
                        marginLeft: "-2px",
                      }}
                    >
                      <img
                        src={Location}
                        alt="location"
                        style={{ width: "13px", height: "17px" }}
                      />{" "}
                      132 Chevron drive, New york Nigeria 3 bedroom, 2 bathrooms
                    </p>
                    <p
                      className="text-color"
                      style={{
                        fontSize: "12px",
                        marginTop: "-8px",
                        marginLeft: "-2px",
                      }}
                    >
                      ₦20,000.00
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-sm-12 col-md-4">
            <Link to="#">
              <button className="btn btn-green list-status-button">
                Quartely
              </button>
              <div className="card" style={{ width: "20rem" }}>
                <img className="card-img-top" src={listapart3} alt="Card" />
                <div className="overlay">
                  <button className="btn btn-grey text">Preview</button>
                </div>
                <div className="card-body">
                  <div className="card-text">
                    <p className="text-color" style={{ fontWeight: "600" }}>
                      Chevron Drive Estate
                    </p>
                    <p
                      className="text-color"
                      style={{
                        fontSize: "12px",
                        marginTop: "-16px",
                        marginLeft: "-2px",
                      }}
                    >
                      <img
                        src={Location}
                        alt="location"
                        style={{ width: "13px", height: "17px" }}
                      />{" "}
                      132 Chevron drive, New york Nigeria 3 bedrooms, 2
                      bathrooms
                    </p>
                    <p
                      className="text-color"
                      style={{
                        fontSize: "12px",
                        marginTop: "-8px",
                        marginLeft: "-2px",
                      }}
                    >
                      ₦20,000.00
                    </p>
                    {/* <PinDropOutlinedIcon style={{color: "#043B5C", fontSize:"18px"}}/> */}
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Listing;
