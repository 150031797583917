import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Card, CircularProgress } from "@mui/material";
//import { Link } from "react-router-dom";
import ConfirmationPopup from "#/components/popup/confirmation";
import PageTitle from "#/components/estateManagementComponents/page-title";
import { propertyServiceApi, returnEstateMember } from "#/apis/propertyServiceApi";
import { useDispatch } from "react-redux";
import { alertError } from "#/store/actions/notification.actions";
import PayFeeCard from "./pay-fee-card";
import { returnCommaSepAmount } from "#/lib/validation";
import { returnNgDateFormat } from "#/lib/dateHelpers";
import BreadCrumbs from "#/components/estateManagementComponents/bread-crumbs";
import { ESM_ROUTES } from "#/constants/routes";
import { useHistory } from "react-router-dom";

const breadcrumbLinks = [
  { title: "dashboard", href: ESM_ROUTES.dashboard, currentPage: true }
];

const MembersDashboard = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { member, memberRole } = returnEstateMember();
  // const permissions = memberRole?.permissions.map((perm) => perm.key);

  const [fees, setFees] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [selected, setSelected] = useState({});
  const [toBePaid, setToBePaid] = useState(0);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [lastPaid, setLastPaid] = useState(null);

  // useEffect(() => {
  //     fetchFees();
  // }, []);

  // useEffect(() => {
  //     fetchFeeRecords();
  // }, []);

  const fetchFees = () => {
    setLoading1(true);
    propertyServiceApi()
      .get("/estate-management/fee")
      .then((data) => {
        setFees(data.data.payload);
      })
      .catch(({ response, message }) => {
        dispatch(alertError(response?.data.error || message));
      })
      .finally(() => {
        setLoading1(false);
      });
  };

  const fetchFeeRecords = () => {
    const query = { params: { member: member._id, limit: 1 } };
    propertyServiceApi()
      .get("/estate-management/fee-record", query)
      .then((data) => {
        setLastPaid(data.data.payload[0]);
      })
      .catch((err) => {});
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleOnChange = (id, amount) => () => {
    setToBePaid(!selected[id] ? toBePaid + amount : toBePaid - amount);
    setSelected({ ...selected, [id]: selected[id] ? 0 : amount });
  };

  const handleFeePayment = (e) => {
    e.preventDefault();
    setOpenConfirm(false);
    localStorage.setItem(
      "ESM_PAY_FEES",
      JSON.stringify({
        items: fees
          .filter((el) => selected[el._id])
          .map(({ _id, title }) => {
            return { _id, title, amount: selected[_id] };
          })
      })
    );
    history.push(ESM_ROUTES.payFees);
  };

  return (
    <div className={classes.root}>
      <BreadCrumbs links={breadcrumbLinks} />
      <PageTitle title="Member's Dashboard" />
      <div className="members-description">
        <p>{member?.estate?.name}</p>
        <h3>{member?.estate?.address}</h3>
      </div>
      {/* {(member?.super_admin || permissions?.includes("2aM")) && ( */}
      <div className="add-members">
        <Button href={ESM_ROUTES.members} variant="outlined" color="primary">
          Add/View Members
        </Button>
      </div>
      {/* )} */}
      <div className="member-payments-details">
        <Card className="member-payments">
          <div>
            <p>Member Name</p>
            <span>
              {member?.first_name} {member?.last_name}
            </span>
          </div>
          <div>
            <p>Member Role</p>
            <span>{(member?.super_admin && "Super Admin") || memberRole?.title}</span>
          </div>
        </Card>
        {lastPaid && (
          <Card className="member-payments-record">
            <p>
              <span>Last Payment</span>
              <span>&#x20A6; {returnCommaSepAmount(lastPaid?.amount_payed)}</span>
            </p>
            <p>
              <span>Last Payment Date</span>
              <span>{returnNgDateFormat(lastPaid?.createdAt)}</span>
            </p>
            <p>
              <span>Next Payment</span>
              <span>{returnNgDateFormat(lastPaid?.next_date)}</span>
            </p>
          </Card>
        )}
      </div>
      <form onSubmit={handleSubmit} className={classes.memberPayable}>
        <div>
          {loading1 && <CircularProgress size={25} />}
          {fees.map((fee, index) => (
            <PayFeeCard
              key={index}
              fee={fee}
              member={member}
              selected={selected}
              handleOnChange={handleOnChange}
            />
          ))}
        </div>
        <div className="member-pay">
          <span>Payable:</span>
          <span>&#x20A6;{returnCommaSepAmount(toBePaid) || "0.00"}</span>
          <div className="arranger">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ width: 100 }}
              disabled={!toBePaid}
              onClick={() => setOpenConfirm(true)}>
              pay
            </Button>
          </div>
        </div>
      </form>
      {/*<h3 className="notification-section">Notification</h3>
            <hr />
            <div className={classes.memberNotifcation}>
                <Card className="notifcation">
                    <p className="notifcation-title">
                        <span>Emergency meeting</span>
                        <span>12/10/2021, 12:30 P.M.</span>
                    </p>
                    <p className="notifcation-message">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas fringilla, lectus ac porta
                        vestibulum, justo orci interdum turpis, id pellentesque risus diam in purus. Donec risus urna,
                        vulputate nec aliquam quis, cursus et tellus. Sed ullamcorper massa felis, sit amet mollis nibh
                        venenatis vitae. Morbi augue justo, molestie nec dignissim vel, gravida non massa.
                    </p>
                    <Link className="see-more-link" to={"http://#"} style={{ color: "blue" }}>
                        See more...
                    </Link>
                </Card>
            </div>*/}
      {openConfirm && (
        <ConfirmationPopup
          title="Payment"
          description="Proceed to Payments page?"
          handleClose={() => setOpenConfirm(false)}
          proceedAction={handleFeePayment}
        />
      )}
    </div>
  );
};

export default MembersDashboard;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0 5%",
    marginTop: 20,

    "& div": {
      width: "100%"
    },

    "& .notification-section": {
      fontSize: "20px",
      fontWeight: 800,
      marginTop: "20px",
      alignSelf: "start",
      marginBottom: 0
    },

    "& hr": {
      height: 1,
      width: "100%",
      backgroundColor: "#8c8b8b",
      border: "none",
      marginTop: 10
    },
    "& .members-description": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "5px 12px",
      backgroundColor: "#151429",
      marginBottom: "10px",
      width: "100%",

      "& p, h3": {
        padding: "5px",
        color: "#fff"
      },

      "& p": {
        fontSize: "14px",
        marginTop: "5px"
      },

      "& h3": {
        fontSize: "18px",
        fontWeight: 500
      }
    },

    "& .add-members": {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: 30
    },

    "& .member-payments-details": {
      backgroundColor: "#2429F5",
      padding: "10px",
      width: "100%",

      "& .member-payments": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#DC130E",
        padding: "30px 5px",
        border: "1px solid #fff",
        borderRadius: "6px",
        marginBottom: "5px",
        color: "#fff",

        "& div": {
          width: "auto"
        },
        "& p": {
          fontSize: 16,
          color: "#fff"
        },

        "& span": {
          alignSelf: "end",
          textTransform: "capitalize",
          fontWeight: 800
        },

        "& span:nth-child(odd)": {
          fontSize: "16px",
          fontWeight: 500,
          marginRight: "20px"
        },

        "& span:nth-child(even)": {
          fontSize: "14px"
        },

        "& span:nth-child(2)": {
          marginRight: "10px"
        }
      },

      "& .member-payments-record": {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        backgroundColor: "#1585CC",
        padding: "30px 5px",
        border: "1px solid #fff",
        borderRadius: "6px",

        "& p": {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "#fff",

          "&:nth-child(1)": {
            marginRight: "40px"
          },

          "&:nth-child(2)": {
            marginRight: "40px"
          },

          "& span": {
            display: "block",
            padding: "5px",

            "&:nth-child(1)": {
              fontSize: "14px"
            },

            "&:nth-child(2)": {
              fontSize: "18px",
              fontWeight: 600
            }
          }
        }
      }
    }
  },

  memberPayable: {
    display: "flex",
    flexDirection: "column",
    marginTop: "15px",
    padding: "20px 12px",
    backgroundColor: "#CCE8EF",
    width: "100%",

    "& .payable-details": {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 10,
      borderRadius: 6,
      marginBottom: 10,
      minHeight: 84,

      "& input, span": {
        display: "block",
        fontWeight: 600,
        color: "#000",
        opacity: "0.8"
      },

      "& p": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        "& span:last-child": {
          opacity: 0.5
        },

        "& input": {
          height: "40px",
          width: "80%",
          padding: "8px 16px",
          lineHeight: "25px",
          fontSize: 14,
          fontWeight: 500,
          border: "1px solid #CDD9ED",
          backgroundColor: "lightgrey",
          transition: "border .3s ease",
          "&::placeholder": {
            color: "#CBD1DC"
          },
          "&:focus": {
            outline: "none",
            borderColor: "#275EFE"
          }
        }
      }
    },
    "& .member-pay": {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "end",
      alignItems: "center",
      marginTop: "60px",

      "& .arranger": {
        marginLeft: 30,
        marginTop: 10,
        width: "auto",
        [theme.breakpoints.up(500)]: {
          marginTop: 0
        }
      },

      "& span": {
        fontSize: "20px",
        fontWeight: 800,
        color: "blue"
      },

      "& span:first-child": {
        marginRight: "30px"
      }
    }
  },

  memberNotifcation: {
    "& .notifcation": {
      padding: "10px",
      marginBottom: "40px"
    },

    "& .notifcation-title": {
      display: "flex",
      justifyContent: "space-between",

      "& span": {
        fontSize: "18px",
        fontWeight: 600
      }
    },

    "& .notifcation-message": {
      fontSize: "14px",
      marginTop: "10px",
      textAlign: "justify"
    },

    "& .see-more-link": {
      marginLeft: 350,
      fontWeight: 700
    }
  }
}));
