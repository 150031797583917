import React from "react";
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import "./success-modal.scss";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

export default function SuccessModal({ open, setOpen }) {
    const history = useHistory();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <div>
            <Dialog fullScreen={fullScreen} open={open} aria-labelledby="responsive-dialog-title">
                <div className="show-interest-success-modal">
                    <div className="check-mark-div">
                        <CheckRoundedIcon style={{ color: "#ffffff" }} />
                    </div>
                    <h3>Congratulations</h3>
                    <p>Your application has been sent</p>
                    <div className="btn-div">
                        <Button
                            className="btn-proceed"
                            variant="outlined"
                            color="primary"
                            onClick={() => setOpen(false)}>
                            BACK TO EDITING
                        </Button>
                        <Button
                            className="btn-proceed"
                            variant="outlined"
                            color="primary"
                            onClick={() => history.push("/dashboard")}>
                            BACK TO DASHBOARD
                        </Button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}
