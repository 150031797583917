import { Grid, styled } from "@mui/material";
import React from "react";

const Container = styled("div")`
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  gap: 2px;
  overflow-wrap: break-word;
`;

const Subtitle = styled("p")`
  color: #052137b2;
  color: rgba(5, 33, 55, 0.6);
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: 0.02813rem;
`;

const Subtext = styled("p")`
  color: #052137;
  font-size: 0.8rem;
  font-weight: 700;
`;

const SubtitleSubtext = ({ loading, subtitle, subtext, style, xs, sm, md, lg }) => {
  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg}>
      <Container style={style}>
        <Subtitle>{subtitle}</Subtitle>
        {loading ? (
          <div className="skeleton skeleton-text"></div>
        ) : (
          <Subtext className={loading ? "skeleton-text" : ""}>
            {subtext && subtext !== "₦NaN" ? subtext : <em>Nil</em>}
          </Subtext>
        )}
      </Container>
    </Grid>
  );
};

export default SubtitleSubtext;
