import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";
import { propertyServiceApi, returnEstateMember } from "#/apis/propertyServiceApi";
import RoleAccordion from "./role-accordion/index.js";
import { alertError, alertSuccess } from "#/store/actions/notification.actions";
import PageTitle from "#/components/estateManagementComponents/page-title/index.js";
import BreadCrumbs from "#/components/estateManagementComponents/bread-crumbs/index.js";
import { ESM_ROUTES } from "#/constants/routes/index.js";

const breadcrumbLinks = [
  { title: "dashboard", href: "/estate-management/dashboard" },
  { title: "roles", href: "/estate-management/role", currentPage: true }
];

const Role = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { member } = returnEstateMember();

  const [roles, setRoles] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //     fetchRoles();
  // }, []);

  // useEffect(() => {
  //     fetchPermissions();
  // }, []);

  const fetchRoles = () => {
    propertyServiceApi()
      .get("/estate-management/role")
      .then((data) => {
        setRoles(data.data.payload);
      })
      .catch(({ response, message }) => {
        if (response?.status === 403) return;
        dispatch(alertError(response?.data.error || message));
      });
  };

  const fetchPermissions = () => {
    propertyServiceApi()
      .get("/estate-management/permission")
      .then((data) => {
        setPermissions(data.data.payload);
      })
      .catch(({ response, message }) => {
        if (response?.status === 403) return;
        dispatch(alertError(response?.data.error || message));
      });
  };

  const createRole = () => {
    setLoading(true);
    const payload = { title };
    propertyServiceApi()
      .post("/estate-management/role", payload)
      .then((data) => {
        dispatch(alertSuccess("successfully created role"));
        fetchRoles();
      })
      .catch(({ response, message }) => {
        dispatch(alertError(response?.data.error || message));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleInputChange = (e) => {
    const valid = /^[a-z]+-?[a-z]+$/gi.test(e.target.value);
    setTitle(e.target.value.toLowerCase());
    setTitleError(!valid);
  };

  // if (!member.super_admin) {
  //     history.push(ESM_ROUTES.dashboard);
  //     return null;
  // }

  return (
    <div className={classes.root}>
      <BreadCrumbs links={breadcrumbLinks} />
      <PageTitle title="roles and permissions" />
      <form onSubmit={createRole} className={classes.roleForm}>
        <label>
          <span className="name">Role Title</span>
          <input
            id="title-field"
            type="text"
            value={title}
            onChange={handleInputChange}
            placeholder="e.g Chairman"
          />
          {true && <span className="error-field">invalid data</span>}
        </label>
        <Button
          className={classes.btn}
          variant="contained"
          color="primary"
          type="submit"
          disabled={titleError || !title || loading}>
          {loading ? <CircularProgress size={20} /> : "Add Role"}
        </Button>
      </form>
      <div className="memberRole-header">
        <h3>Roles and Permission</h3>
      </div>
      <hr />
      <div>
        {roles?.map((role, index) => (
          <RoleAccordion key={index} {...{ role, permissions }} />
        ))}
      </div>
    </div>
  );
};

export default Role;

const styles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0 5%",
    marginTop: 20,

    "& hr": {
      height: 1,
      width: "100%",
      backgroundColor: "#8c8b8b",
      border: "none",
      marginTop: 10
    },
    "& .memberRole-header": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",

      "& .member_delete_icon": {
        justifySelf: "end",
        alignSelf: "center",
        fontSize: 20
      },
      "& h3": {
        fontSize: 16,
        fontWeight: "bolder",
        alignSelf: "end",
        marginBottom: 0
      }
    }
  },

  roleForm: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "100%",
    marginBottom: "40px",

    "& input, label": {
      display: "block"
    },

    "& label .name": {
      marginBottom: "20px",
      fontWeight: 600
    },

    "& label .error-field": {
      color: "red"
    },

    "& input[type=text]": {
      marginTop: "5px",
      marginBottom: 15,
      width: "100%",
      padding: "8px 16px",
      lineHeight: "25px",
      fontSize: 14,
      fontWeight: 500,
      borderRadius: 6,
      border: "1px solid #CDD9ED",
      backgroundColor: "#fff",
      color: "#99A3BA",
      transition: "border .3s ease",
      "&::placeholder": {
        color: "#CBD1DC"
      },
      "&:focus": {
        outline: "none",
        borderColor: "#275EFE"
      }
    },

    "& input[type=submit]": {
      alignSelf: "end",
      borderRadius: 6,
      border: "none",
      backgroundColor: "#2b74d4",
      color: "#fff",
      outline: "none",
      padding: "10px 26px",
      fontWeight: 500
    }
  },
  accordion: {
    "& .accordion-role-div": {
      display: "flex",
      alignItems: "center",
      "& input": {
        marginRight: 20
      }
    },
    "& .accordion-details": {
      padding: "16px 25px"
    }
  },
  btn: {
    width: 150,
    alignSelf: "end"
  }
}));
