import React, { useState, useEffect } from "react";
import { useModalStyles } from "./modal.styles";

const Modal = ({
    content = "",
    isModalOpen = false,
    handleCloseModal,
    children,
    boxClasses,
    style,
    hasHeader = true
}) => {
    const classes = useModalStyles();

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(isModalOpen);
    }, [isModalOpen]);

    if (!isOpen) return null;

    return (
        <div className={classes.modalContainer}>
            <div className={classes.backDrop} onClick={handleCloseModal}></div>

            <div className={classes.contentWrapper} style={style}>
                {hasHeader && (
                    <div className="modal_header">
                        {/* A modal ought to have a cancel icon in itself, you can comment this out if it is not needed */}
                        {/* <div className={classes.cancelIcon} onClick={handleCloseModal}>
                        <span></span>
                        <span></span>
                    </div> */}
                        <h4>{content}</h4>
                    </div>
                )}

                {children}
            </div>
        </div>
    );
};

export default Modal;
