import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "#/components/modal/modal.component";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "#/components/ui-components/button.component";
import userImg from "#/assets/other images/user (1).svg";
import contactImg from "#/assets/other images/name.svg";
import InputIcon from "#/components/ui-components/inputIcon-component";
import { useForm } from "react-hook-form";
import { validateID } from "#/store/actions/auth.actions";
import { alertClear } from "#/store/actions/notification.actions";
import { addPropertyManager } from "#/store/actions/property.actions";

const AddPMModal = ({ modal, closeModal }) => {
    //State
    const [display, setDisplay] = useState(false);

    //Hook
    const { register, watch, handleSubmit, setValue, errors } = useForm({
        mode: "onBlur"
    });
    const values = watch();

    //Redux
    const dispatch = useDispatch();
    const messages = useSelector((store) => store.notifications.msg);
    let { liveIzyUser, loading, user } = useSelector((state) => state.auth);
    let { loading: reloading } = useSelector((state) => state.property);

    //Gets User Liveizy details
    useEffect(() => {
        const timerId = setTimeout(() => {
            const liveIzyIdVals = values.user_liveizy;
            if (liveIzyIdVals !== undefined && liveIzyIdVals.length === 8) {
                dispatch(validateID(liveIzyIdVals, () => setDisplay(true)));
            }
        }, 1000);
        return () => {
            clearTimeout(timerId);
        };
    }, [values.user_liveizy]);

    useEffect(() => {
        if (liveIzyUser !== "") {
            setValue("name", liveIzyUser.name);
        }
    }, [liveIzyUser, display]);

    //Checks input field
    useEffect(() => {
        if (values.user_liveizy !== undefined && values.user_liveizy.length !== 8 && messages !== "") {
            setDisplay(false);
            dispatch(alertClear());
        }
    }, [messages, values.user_liveizy]);

    //Validating form
    const validateName = (value) => {
        if (value !== undefined && value.length !== 8) {
            return "LiveIzy Id must be 8 characters";
        }
    };

    const onSubmit = (val) => {
        if (!display) return;

        const payload = {
            propertyManagerId: val.user_liveizy
        };

        dispatch(
            addPropertyManager(payload, user.liveizy_id, () => {
                closeModal && closeModal();
            })
        );
    };

    const classes = Styles();

    return (
        <React.Fragment>
            <Modal content="Add PM" isModalOpen={modal} handleCloseModal={closeModal} style={{ maxWidth: "432px" }}>
                <div className={classes.modal}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <InputIcon
                            img={userImg}
                            label="LiveIzy ID"
                            type="text"
                            name="user_liveizy"
                            error={errors.user_liveizy && errors.user_liveizy.message}
                            customRef={register({
                                required: "This field is required",
                                validate: validateName
                            })}
                        />
                        {display && (
                            <InputIcon
                                img={contactImg}
                                label="Name"
                                type="text"
                                name="name"
                                disabled={true}
                                customRef={register({
                                    required: "This field is required"
                                })}
                            />
                        )}
                        <CustomButton
                            type="submit"
                            buttonText="CLOSE"
                            onClick={closeModal}
                            className={classes.closeBtnTransfer}
                        />
                        <CustomButton
                            type="submit"
                            fullWidth={true}
                            buttonText="ADD PROPERTY MANAGER"
                            isLoading={loading || reloading}
                            style={{
                                color: "#F4F5F7",
                                width: "200px",
                                border: "none",
                                padding: "14px 15px",
                                fontSize: "13px",
                                fontFamily: "Open Sans-bold , san-serif",
                                fontWeight: "bold",
                                bordeRradius: "5px",
                                textTransform: "none",
                                backgroundColor: "#4786FF"
                            }}
                        />
                    </form>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default AddPMModal;

const Styles = makeStyles((theme) => ({
    modal: {
        padding: "20px 32px 40px",

        "& p": {
            color: "#043B5C",
            fontSize: "15px",
            fontFamily: "Open Sans-bold , san-serif"
        }
    },

    modalBox: {
        background: "#6EB5DF30 0% 0% no-repeat padding-box",
        borderRadius: "10px",
        padding: "20px",
        marginTop: "10px",
        display: "flex",
        flexWrap: "wrap",

        "& p": {
            color: "#043B5C",
            fontSize: "16px",
            marginRight: "10px"
        }
    },

    closeBtnTransfer: {
        backgroundColor: "white",
        color: "#043B5C",
        textTransform: "none",
        borderRadius: "5px",
        border: "1px solid #043B5C91",
        padding: "13px 15px",
        fontSize: "13px",
        fontFamily: "Open Sans, sans-serif",
        // padding: "13px 13%",
        marginRight: "20px",
        fontFamily: "Open Sans-bold , san-serif",
        fontWeight: "bold",
        width: "100px",
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        }
    },

    activateBtn: {
        backgroundColor: "#4786FF",
        color: "#E8F0F9",
        textTransform: "none",
        borderRadius: "5px",
        color: "#F4F5F7",
        border: "1px solid #45B29D",
        border: "none",
        padding: "14px 15px",
        fontSize: "13px",
        // padding: "14px 15%",
        fontFamily: "Open Sans-bold , san-serif",
        fontWeight: "bold",
        width: "200px",

        "&:hover": {
            color: "#E8F0F9",
            backgroundColor: "#4786FF"
        },

        [theme.breakpoints.down("sm")]: {
            marginTop: "10px",
            width: "100%"
        }
    },

    channel: {
        marginTop: "20px",
        marginBottom: "20px",

        "& .channel_method": {
            marginRight: "20px",
            "& label": {
                marginLeft: "5px",
                color: "#043B5C",
                fontSize: "17px",
                fontFamily: "Open Sans-Semibold , san-serif",
                margin: "0px",
                cursor: "pointer"
            }
        }
    },

    channel_checkbox: {
        padding: 0,
        marginRight: "0px"
    },

    form: {
        marginTop: "20px",
        marginBottom: "50px",
        [theme.breakpoints.down("md")]: {}
    },

    label: {
        display: "block",
        color: "#043B5C",
        fontSize: "14px",
        fontFamily: "Open Sans-Semibold, sans-serif",
        fontWeight: "600",
        marginBottom: "15px",
        letterSpacing: "0.09px",
        [theme.breakpoints.down("md")]: {
            marginBottom: "10px"
        }
    },

    inputWrapper: {
        display: "flex",
        justifyContent: "space-between",
        border: "1px solid #e5e8eb",
        // padding: (open) => (open ? "11px 15px 11px 15px" : "11px 25px 11px 25px"),
        alignItems: "center",
        cursor: "pointer",

        "& .input-placeholder": {
            color: "#99AFBD",
            fontFamily: "Open Sans-Semibold, sans-serif",
            fontWeight: "600",
            fontSize: "13px",
            display: "flex",
            flexWrap: "wrap",

            "& .remind-pills": {
                marginRight: "7px",
                background: "#99AFBD",
                color: "#E8F0F9",
                fontSize: "12px",
                borderRadius: "20px",
                padding: "3px 15px",
                marginBottom: "7px",
                fontFamily: "Open Sans, sans-serif"
            }
        }
    },

    checkbox: {
        backgroundColor: "#EEF4FB",
        overflow: "auto",
        zIndex: "2",
        padding: "20px 30px",
        visibility: (open) => (open ? "visible" : "hidden"),
        opacity: (open) => (open ? 1 : 0),
        transitionProperty: "visibility, opacity, transform",
        transitionDuration: "0.35s",
        position: "absolute",
        width: "100%",

        "& .list": {
            fontFamily: "Open Sans-Semibold, sans-serif",
            fontWeight: "600",
            fontSize: "14px",

            "& .remind_list": {
                margin: "0px",
                listStyle: "none",
                padding: "0px",
                display: "inline-grid",
                gridTemplateColumns: "repeat(4, auto)",
                justifyContent: "center",
                gridColumnGap: "26px",

                [theme.breakpoints.down("sm")]: {
                    gridColumnGap: "10px"
                },

                "& .options": {
                    "& input": {
                        marginRight: "10px"
                    },

                    " & label": {
                        color: "#043B5C",
                        padding: 0,
                        cursor: "pointer"
                    }
                }
            }
        }
    }
}));
