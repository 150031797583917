import BlueGradientJumbotron from "#/association/components/jumbotrons/BlueGradientJumbotron";
import PageContainer from "#/association/components/PageContainer";
import SubHeadingUnderline from "#/association/components/SubHeadingUnderline";
import SubtitleSubtext from "#/association/components/SubtitleSubtext";
import { toCurrency } from "#/components/utils/util";
import { Grid } from "@mui/material";
import React from "react";

const Summary = () => {
  return (
    <PageContainer>
      <section>
        <SubHeadingUnderline text={"Landlord and Tenant Association Gbagada Phase 1"} />
        <BlueGradientJumbotron marginTop={3}>
          <Grid rowGap={3} container>
            <Grid item xs={6} sm={4}>
              <SubtitleSubtext title={"Total Apartment"} text="54" />
            </Grid>

            <Grid item xs={6} sm={4}>
              <SubtitleSubtext title={"Total Rent"} text="14" />
            </Grid>

            <Grid item xs={6} sm={4}>
              <SubtitleSubtext title={"Total Occupied"} text="40" />
            </Grid>

            <Grid item xs={6} sm={4}>
              <SubtitleSubtext title={"Total Payable"} text={toCurrency(45700345)} />
            </Grid>

            <Grid item xs={6} sm={4}>
              <SubtitleSubtext title={"Total Paid"} text={toCurrency(30724461)} />
            </Grid>
          </Grid>
        </BlueGradientJumbotron>
      </section>
    </PageContainer>
  );
};

export default Summary;
