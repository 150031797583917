/* eslint-disable no-useless-constructor */
import React from "react";
import "../../../App.css";

// components
import Listing from "../../../components/listing/listing.jsx";
import Smallimage from "../../../components/booking/smallimage.jsx";
import Mainimage from "../../../components/booking/mainimage.jsx";
import Totalresults from "../../../components/booking/totalresults.jsx";
import Statusdet from "../../../components/booking/statusdet.jsx";
import Images from "../../../components/booking/images.component";
import Container from "../../../components/layout/container";

class Status extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Container>
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 col-lg-7">
                    <Images />
                  </div>
                  <div className="col-sm-12  col-lg-5">
                    <Statusdet />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Totalresults />

          <Listing />
        </div>
      </Container>
    );
  }
}

export default Status;
