import React from "react";
import "./body.styles.scss";
import Header from "./header.component";
import clsx from "clsx";
import ScrollToTop from "#/components/scrollToTop-component";

const Body = (props) => {
    //This wasn't clicking the full screen. Please check sidebar line 64
    // const mobileToggle = () => {
    //   if (props.isMobile && props.mobileToggle) {
    //     props.onClickHandler();
    //   } else return;
    // };

    return (
        <React.Fragment>
            <div id="content" style={{ backgroundColor: "#fff" }}>
                {/* <Header clickToggle={props.onClickHandler} /> */}
                <div className="content-body">
                    <ScrollToTop />
                    {props.children}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Body;
