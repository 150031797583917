import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";

export default function DataTable({ rows, columns, pageSize, ...otherProps }) {
  return (
    <div
      style={{
        // height: 800,
        width: "100%"
      }}>
      <DataGrid
        className="tw-w-full"
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        {...otherProps}
      />
    </div>
  );
}
