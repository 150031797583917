import { PROPERTY_ACTIONTYPES_CONSTANTS } from "../constants";

//------------------------------------------------------------------
//Variables and Helpers
//------------------------------------------------------------------
const {
    GET_PROP_ID_FAIL,
    GET_PROP_ID_PENDING,
    GET_PROP_ID_SUCCESS,
    CREATE_PROPERTY_FAIL,
    CREATE_PROPERTY_PENDING,
    CREATE_PROPERTY_SUCCESS,
    GET_ALL_PROPERTIES_FAIL,
    GET_ALL_PROPERTIES_SUCCESS,
    GET_ALL_PROPERTIES_PENDING,
    GET_PROPERTY_BY_ID_PENDING,
    GET_PROPERTY_BY_ID_SUCCESS,
    GET_PROPERTY_BY_ID_FAIL,
    CLEAR_PROPERTY_INFO,
    UPDATE_PROPERTY_FAIL,
    UPDATE_PROPERTY_SUCCESS,
    UPDATE_PROPERTY_PENDING,
    UPDATE_PROPERTY_INFO,
    DELETE_PROPERTY_PENDING,
    DELETE_PROPERTY_SUCCESS,
    DELETE_PROPERTY_FAIL,
    ADD_PM_PENDING,
    ADD_PM_SUCCESS,
    ADD_PM_FAIL,
    CREATE_PRO_OWNER_PENDING,
    CREATE_PRO_OWNER_SUCCESS,
    CREATE_PRO_OWNER_FAIL
} = PROPERTY_ACTIONTYPES_CONSTANTS;

const initialState = {
    propertyId: "",
    creatingProperty: false,
    creatingPropertyErr: null,
    allPropertyInfo: null,
    allProperties: [],
    loading: false,
    propertyById: null
};

const propertyReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_PROP_ID_PENDING:
            return {
                ...state
            };
        case GET_PROP_ID_SUCCESS:
            return {
                ...state,
                propertyId: payload
            };
        case GET_PROP_ID_FAIL:
            return {
                ...state
            };
        case CREATE_PROPERTY_PENDING:
            return {
                ...state,
                creatingProperty: true
            };
        case CREATE_PROPERTY_SUCCESS:
            return {
                ...state,
                creatingProperty: false,
                creatingPropertyErr: null,
                allPropertyInfo: payload
            };
        case CREATE_PROPERTY_FAIL:
            return {
                ...state,
                creatingProperty: false,
                creatingPropertyErr: payload,
                allPropertyInfo: null
            };
        case GET_ALL_PROPERTIES_PENDING:
            return {
                ...state,
                loading: true
            };
        case GET_ALL_PROPERTIES_SUCCESS:
            return {
                ...state,
                loading: false,
                allProperties: payload
            };
        case GET_ALL_PROPERTIES_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_PROPERTY_BY_ID_PENDING:
            return {
                ...state,
                loading: true
            };
        case GET_PROPERTY_BY_ID_SUCCESS: {
            return {
                ...state,
                loading: false,
                propertyById: action.propertyById
            };
        }
        case GET_PROPERTY_BY_ID_FAIL: {
            return {
                ...state,
                loading: false
            };
        }
        case CLEAR_PROPERTY_INFO: {
            return {
                ...state,
                allPropertyInfo: null
            };
        }
        case UPDATE_PROPERTY_PENDING:
            return {
                ...state
            };
        case UPDATE_PROPERTY_SUCCESS:
            return {
                ...state
            };
        case UPDATE_PROPERTY_FAIL:
            return {
                ...state
            };
        case UPDATE_PROPERTY_INFO:
            return {
                ...state,
                allPropertyInfo: payload
            };
        case DELETE_PROPERTY_PENDING:
            return {
                ...state,
                deleting: true
            };
        case DELETE_PROPERTY_SUCCESS:
            return {
                ...state,
                deleting: false
            };
        case DELETE_PROPERTY_FAIL:
            return {
                ...state,
                deleting: false
            };
        case ADD_PM_PENDING:
            return {
                ...state,
                loading: true
            };
        case ADD_PM_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case ADD_PM_FAIL:
            return {
                ...state,
                loading: false
            };
        case CREATE_PRO_OWNER_PENDING:
            return {
                ...state,
                loading: true
            };
        case CREATE_PRO_OWNER_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case CREATE_PRO_OWNER_FAIL:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};

export default propertyReducer;
