import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import { Autocomplete, Button, TextField } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationPopup from "#/components/popup/confirmation";
import PageTitle from "#/components/estateManagementComponents/page-title";
import { handleFormInput, validate } from "#/lib/validation";
import { propertyServiceApi, returnEstateMember } from "#/apis/propertyServiceApi";
import { alertError, alertSuccess } from "#/store/actions/notification.actions";
import { authServiceApi } from "#/apis/authServiceApi";
import MemberRibbon from "./member-ribbon";
import BreadCrumbs from "#/components/estateManagementComponents/bread-crumbs";
import { ESM_ROUTES } from "#/constants/routes";
import { useHistory } from "react-router-dom";

const initialValues = {
  liveizy_id: { value: "", validation: true, error: "" },
  role: { value: {}, validation: true, error: "" }
};

const breadcrumbLinks = [
  { title: "dashboard", href: ESM_ROUTES.dashboard },
  { title: "members", href: ESM_ROUTES.members, currentPage: true }
];

const Members = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { member, memberRole } = returnEstateMember();
  // const permissions = memberRole?.permissions.map((perm) => perm.key);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [formValues, setFormValues] = useState(initialValues);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [loading5, setLoading5] = useState(false);
  const [disableSubmembermit, setDisableSubmit] = useState(true);
  const [roles, setRoles] = useState([]);
  const [members, setMembers] = useState(null);
  const [verified, setVerified] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const { user } = useSelector((state) => state.auth);

  // useEffect(() => {
  //   validate(formValues, (status) => {
  //     if (status) validateLiveizyId();
  //   });
  // }, [formValues]);

  // useEffect(() => {
  //   fetchRoles();
  // }, []);member

  // useEffect(() => {
  //   fetchMembers();
  // }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createMember = (e) => {
    e.preventDefault();
    const [first_name, last_name] = verified.name.split(" ");
    const payload = {
      liveizy_id: verified.liveizy_id,
      first_name: first_name,
      last_name: last_name,
      estate: "membememberr?.estate._id || member?.estate",
      roles: [formValues.role.value._id]
    };
    setLoading1(true);
    propertyServiceApi()
      .post("/estate-management/member", payload)
      .then((data) => {
        setFormValues(initialValues);
        setVerified(null);
        setDisableSubmit(true);
        dispatch(alertSuccess("Succefully added member!!"));
        fetchMembers();
      })
      .catch(({ response, message }) => {
        setDisableSubmit(false);
        dispatch(alertError(response?.data.error || message));
      })
      .finally(() => {
        setLoading1(false);
      });
  };

  const deactivateMember = (id) => () => {
    setLoading2({ ...loading2, [id]: true });
    setOpenConfirm(false);
    setMemberToDelete(null);
    propertyServiceApi()
      .patch(`/estate-management/member/${id}`, { status: "inactive" })
      .then((data) => {
        fetchMembers();
      })
      .catch(({ response, message }) => {
        dispatch(alertError(response?.data.error || message));
      })
      .finally(() => {
        setLoading2({ ...loading2, [id]: false });
      });
  };

  const fetchRoles = () => {
    setLoading3(true);
    propertyServiceApi()
      .get("/estate-management/role")
      .then((data) => {
        setRoles(data.data.payload);
      })
      .catch(({ response, message }) => {
        if (response?.status === 403) return;
        dispatch(alertError(response?.data.error || message));
      })
      .finally(() => {
        setLoading3(false);
      });
  };

  const fetchMembers = () => {
    setLoading4(true);
    const queryParams = {
      params: {
        estate: member?.estate._id,
        populate1: "estate",
        populate2: "roles",
        limit: rowsPerPage,
        skip: page * rowsPerPage
      }
    };
    propertyServiceApi()
      .get("/estate-management/member", queryParams)
      .then((data) => {
        setMembers(data.data.payload);
      })
      .catch(({ response, message }) => {
        if (response?.status === 403) return;
        dispatch(alertError(response?.data.error || message));
      })
      .finally(() => {
        setLoading4(false);
      });
  };

  const validateLiveizyId = () => {
    const { title } = formValues.role.value;
    if (!title) return;
    setLoading5(true);
    authServiceApi()
      .get(`/user/validate/liveizy/${formValues.liveizy_id.value}`)
      .then((data) => {
        setVerified(data.data.data);
        setDisableSubmit(false);
      })
      .catch(({ response, message }) => {
        setDisableSubmit(true);
        if (response.status === 404)
          return dispatch(alertError("Unable to verify liveizy ID" || message));
        dispatch(alertError(response?.data.message || message));
      })
      .finally(() => {
        setLoading5(false);
      });
  };

  const initiateDelete = (id) => () => {
    setOpenConfirm(true);
    setMemberToDelete(id);
  };

  const onDeleteCancel = () => {
    setOpenConfirm(false);
    setMemberToDelete(null);
  };

  // if (!member.super_admin && !permissions?.includes("2aM")) {
  //   history.push(ESM_ROUTES.dashboard);
  //   return null;
  // }

  return (
    <div className={classes.root}>
      <BreadCrumbs links={breadcrumbLinks} />
      <PageTitle title="Members" />
      <form onSubmit={createMember} className={classes.memberForm}>
        <TextField
          id="outlined-basic"
          style={{ marginBottom: 20 }}
          label="Enter user Liveizy ID"
          variant="outlined"
          value={formValues.liveizy_id.value}
          error={!formValues.liveizy_id.validation}
          helperText={formValues.liveizy_id.error || verified?.name}
          disabled={loading5}
          onChange={(e) => {
            const value = e.target.value;
            handleFormInput("liveizy_id", value, /.+/gi, formValues, setFormValues, [
              "liveizyId"
            ]);
          }}
        />
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          style={{ marginBottom: 30 }}
          options={roles}
          getOptionLabel={(option) => option?.title || ""}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select role"
              helperText={loading5 && "...verifying"}
            />
          )}
          value={formValues.role.value}
          disabled={loading5}
          onChange={(e, value) => {
            handleFormInput("role", value, /./gi, formValues, setFormValues);
          }}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="submit-btn"
          disabled={loading1}>
          {loading1 ? <CircularProgress size={25} /> : "Add Member"}
        </Button>
      </form>

      <div className={classes.memberLists}>
        <div className="memberLists-header">
          <h3>List of Members</h3>
        </div>
        <hr />

        {loading4 && <CircularProgress size={20} />}
        {/* {members?.map((member, index) => ( */}
        <MemberRibbon
          // key={index}
          member={user}
          deactivateMember={initiateDelete(user.id)}
          loadingDelete={loading2}
        />
        {/* ))} */}
      </div>
      {/* {members && ( */}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <TablePagination
          className="paginate"
          component="div"
          count={1000}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
        />
      </div>
      {/* )} */}
      {openConfirm && (
        <ConfirmationPopup
          title="Deactivate member"
          description="Are you sure you want to deactivate this member?"
          handleClose={onDeleteCancel}
          proceedAction={deactivateMember(memberToDelete)}
        />
      )}
    </div>
  );
};

export default Members;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: 20,
    padding: "0 5%"
  },

  memberLists: {
    display: "flex",
    flexDirection: "column",
    marginTop: "60px",
    marginBottom: 40,
    width: "100%",

    "& hr": {
      height: 1,
      width: "100%",
      backgroundColor: "#8c8b8b",
      border: "none",
      marginTop: 10
    },
    "& .memberLists-header": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",

      "& .member_delete_icon": {
        justifySelf: "end",
        alignSelf: "center",
        fontSize: "34px",
        cursor: "pointer"
      },
      "& h3": {
        fontSize: "20px",
        fontWeight: 800,
        alignSelf: "end",
        marginBottom: 0
      }
    },
    "& .member-details": {
      marginTop: "20px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
      padding: "15px 15px 15px 0",

      "& .mem-det-div1": {
        width: "10%"
      },

      "& .mem-det-div2": {
        flex: 1,
        display: "flex",
        width: "100%",
        justifyContent: "space-around"
      },

      "& span": {
        margin: "0 5px",
        width: "33%",
        textAlign: "center"
      },

      "& .member-name": {
        fontSize: "16px",
        fontWeight: 600,
        marginLeft: "10px"
      },
      "& .member-role": {
        opacity: 0.6

        // '&::first-letter': {
        //     textTransform: 'capitalize',
        // },
      }
    }
  },

  memberForm: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "100%",

    "& input, label": {
      display: "block"
    },

    "& label .name": {
      marginLeft: "5px",
      marginBottom: "20px",
      fontWeight: 600,
      opacity: 0.5
    },

    "& .submit-btn": {
      alignSelf: "end",
      width: 150,
      borderRadius: 6,
      border: "none",
      color: "#fff",
      outline: "none",
      padding: "8px 16px",
      fontWeight: 500
    }
  }
}));
