import { TextField } from "@mui/material";
import { useField } from "formik";
import React from "react";

const DatePickerField = ({ name, ...otherProps }) => {
    const [field, meta] = useField(name);

    const configDatePickerField = {
        ...field,
        ...otherProps,
        type: "date",
        fullWidth: true,
        InputLabelProps: {
            shrink: true
        },
        variant: "outlined",
        format: "MM/yy",
        views: ["year", "month"],
        // minDate:new Date(),
        maxdate: new Date()
    };

    if (meta && meta.touched && meta.error) {
        configDatePickerField.error = true;
        configDatePickerField.helperText = meta.error;
    }
    return <TextField {...configDatePickerField} />;
};

export default DatePickerField;
