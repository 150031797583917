import axios from "axios";
import { REMINDER_CONSTANTS } from "../constants";
import { showNotifications } from "#/store/actions/notification.actions";

const {
  CREATE_PROPERTY_REMINDER_PENDING,
  CREATE_PROPERTY_REMINDER_SUCCESS,
  CREATE_PROPERTY_REMINDER_FAIL,
  CREATE_APARTMENT_REMINDER_PENDING,
  CREATE_APARTMENT_REMINDER_SUCCESS,
  CREATE_APARTMENT_REMINDER_FAIL,

  GET_INACTIVE_REMINDERS_FAIL,
  GET_INACTIVE_REMINDERS_PENDING,
  GET_INACTIVE_REMINDERS_SUCCESS,

  GET_ACTIVE_REMINDERS_FAIL,
  GET_ACTIVE_REMINDERS_PENDING,
  GET_ACTIVE_REMINDERS_SUCCESS,

  GET_DELIVERED_REMINDERS_FAIL,
  GET_DELIVERED_REMINDERS_PENDING,
  GET_DELIVERED_REMINDERS_SUCCESS,

  RENT_REMINDER_PAYMENT_FAIL,
  RENT_REMINDER_PAYMENT_PENDING,
  RENT_REMINDER_PAYMENT_SUCCESS,

  CHECK_REMINDERS_EXIST_FAIL,
  CHECK_REMINDERS_EXIST_SUCCESS,
  CHECK_REMINDERS_EXIST_PENDING,

  GET_SUBSCRIPTION_REMINDERS_SUMMARY_FAIL,
  GET_SUBSCRIPTION_REMINDERS_SUMMARY_PENDING,
  GET_SUBSCRIPTION_REMINDERS_SUMMARY_SUCCESS,

  GET_APARTMENTS_IN_PLAN_FAIL,
  GET_APARTMENTS_IN_PLAN_PENDING,
  GET_APARTMENTS_IN_PLAN_SUCCESS
} = REMINDER_CONSTANTS;

const BASE_URL = process.env.REACT_APP_PROPERTY_SERVICE_BASE_URL;

export const createReminderForProperty = (payload, cb) => async (dispatch) => {
  dispatch({ type: CREATE_PROPERTY_REMINDER_PENDING });

  try {
    const response = await axios.post(`${BASE_URL}/reminders/property`, payload, {
      headers: {
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "1"
      }
    });
    if ([200, 201].includes(response.status)) {
      dispatch({
        type: CREATE_PROPERTY_REMINDER_SUCCESS,
        payload: response.data.message
      });
      dispatch(showNotifications(`${response.data.message}`, "success"));
      cb();
    }
  } catch (error) {
    dispatch({ type: CREATE_PROPERTY_REMINDER_FAIL, error });
    // console.log(error);
    dispatch(showNotifications(`${error.response.data.error}`, "error"));
  }
};

export const createReminderForApartment = (payload, cb) => async (dispatch) => {
  dispatch({ type: CREATE_APARTMENT_REMINDER_PENDING });

  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}/reminders/apartment`, payload, {
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "1"
        }
      })
      .then((response) => {
        if ([200, 201].includes(response.status)) {
          dispatch({
            type: CREATE_APARTMENT_REMINDER_SUCCESS,
            payload: response.data.message
          });
          return resolve(response.data.message);
        }
      })
      .catch((error) => {
        dispatch({ type: CREATE_APARTMENT_REMINDER_FAIL, error });
        return reject(error.response.data.error);
      });
  });
};

//--------------------------------------------------
//Get Subscription reminders summary
//--------------------------------------------------
export const getSubscriptionRemindersSummary = (id, status, cb) => async (dispatch) => {
  dispatch({ type: GET_SUBSCRIPTION_REMINDERS_SUMMARY_PENDING });
  try {
    const response = await axios.get(`${BASE_URL}/reminders/subscriptions/${id}`, {
      headers: {
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "1"
      }
    });

    if ([200, 201].includes(response.status)) {
      dispatch({
        type: GET_SUBSCRIPTION_REMINDERS_SUMMARY_SUCCESS,
        payload: response.data
      });
    }
  } catch (error) {
    dispatch({ type: GET_SUBSCRIPTION_REMINDERS_SUMMARY_FAIL, error });
  }
};

//--------------------------------------------------
//Get Apartments in plan
//--------------------------------------------------
export const getApartmentsInPlan = (id, status, cb) => async (dispatch) => {
  dispatch({ type: GET_APARTMENTS_IN_PLAN_PENDING });
  try {
    const response = await axios.get(
      `${BASE_URL}/apartment/hasreminder/${id}?status=inactive`,
      {
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "1"
        }
      }
    );

    if ([200, 201].includes(response.status)) {
      dispatch({
        type: GET_APARTMENTS_IN_PLAN_SUCCESS,
        payload: response.data.payload
      });
    }
  } catch (error) {
    // console.log(error);
    dispatch({ type: GET_APARTMENTS_IN_PLAN_FAIL, error });
  }
};

//--------------------------------------------------
//Get all inactive reminders
//--------------------------------------------------
// export const getAllInactiveReminders = (id, status, cb) => async (dispatch) => {
//     dispatch({ type: GET_INACTIVE_REMINDERS_PENDING });

//     try {
//         const response = await axios.get(`${BASE_URL}/reminders/manager/${id}?status=${status}`);
//         console.log(response, "the response");
//         if ([200, 201].includes(response.status)) {
//             dispatch({
//                 type: GET_INACTIVE_REMINDERS_SUCCESS,
//                 payload: response.data.payload
//             });
//         }
//     } catch (error) {
//         console.log(error);
//         dispatch({ type: GET_INACTIVE_REMINDERS_FAIL, error });
//     }
// };

//--------------------------------------------------
//Get all active reminders
//--------------------------------------------------
// export const getAllActiveReminders = (id, status, cb) => async (dispatch) => {
//     dispatch({ type: GET_ACTIVE_REMINDERS_PENDING });
//     try {
//         const response = await axios.get(`${BASE_URL}/reminders/manager/${id}?status=${status}`);

//         if ([200, 201].includes(response.status)) {
//             dispatch({
//                 type: GET_ACTIVE_REMINDERS_SUCCESS,
//                 payload: response.data.payload
//             });
//         }
//     } catch (error) {
//         console.log(error);
//         dispatch({ type: GET_ACTIVE_REMINDERS_FAIL, error });
//     }
// };

//--------------------------------------------------
//Get all delivered reminders
//--------------------------------------------------
// export const getAllDeliveredReminders = (id, status, cb) => async (dispatch) => {
//     dispatch({ type: GET_DELIVERED_REMINDERS_PENDING });

//     try {
//         const response = await axios.get(`${BASE_URL}/reminders/manager/${id}?status=${status}`);

//         if ([200, 201].includes(response.status)) {
//             dispatch({
//                 type: GET_DELIVERED_REMINDERS_SUCCESS,
//                 payload: response.data.payload
//             });
//         }
//     } catch (error) {
//         console.log(error);
//         dispatch({ type: GET_DELIVERED_REMINDERS_FAIL, error });
//     }
// };

//--------------------------------------------------
//Pay for reminders
//--------------------------------------------------
export const payForRentReminders = (cb) => async (dispatch) => {
  dispatch({ type: RENT_REMINDER_PAYMENT_PENDING });

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_PROPERTY_SERVICE_BASE_URL}/payments/rent-reminder`
    );

    if ([200, 201].includes(response.status)) {
      dispatch({
        type: RENT_REMINDER_PAYMENT_SUCCESS,
        payload: response.data.message
      });
    }
    dispatch(showNotifications(`${response.data.message}`, "success"));
    cb();
  } catch (error) {
    dispatch({ type: RENT_REMINDER_PAYMENT_FAIL, error });
    dispatch(showNotifications(`${error.response.data.error}`, "error"));
  }
};

//--------------------------------------------------
//Check if any reminder exists
//--------------------------------------------------
export const checkExistenceOfReminders = (id, status, cb) => async (dispatch) => {
  dispatch({ type: CHECK_REMINDERS_EXIST_PENDING });

  try {
    const response = await axios.get(
      `${BASE_URL}/reminders/limited/manager/${id}?skip=0&limit=5`
    );

    if ([200, 201].includes(response.status)) {
      dispatch({
        type: CHECK_REMINDERS_EXIST_SUCCESS,
        payload: response.data.payload
      });
    }
  } catch (error) {
    dispatch({ type: CHECK_REMINDERS_EXIST_FAIL, error });
  }
};
