import { makeStyles, withStyles } from "@material-ui/core/styles";

export const useSubscriptionStyles = makeStyles((theme) => {
    return {
        wrapper: {
            position: "relative",
            //------------------------------
            //Font overrides
            //------------------------------
            display: "flex",
            backgroundColor: "#fff",
            borderRadius: "10px",

            "& h1": {
                fontSize: "18px",
                fontWeight: "600",
                margin: "0px"
            },

            "& h3": {
                fontSize: "14px",
                fontWeight: "600",
                margin: "0px"
            }
        },

        tabs: {
            width: "250px",
            backgroundColor: "#EEF4FB",
            borderRadius: "10px",
            transition: "all 100ms ease-in-out",

            //--------------
            // Take note (mobile screns have a very different logic from desktop screens)
            //--------------
            "@media(max-width:768px)": {
                position: "absolute",
                width: "100%",
                zIndex: 10,
                backgroundColor: "transparent"
            },

            "& .title": {
                marginBottom: "20px",
                marginTop: "35px",
                paddingLeft: "30px",
                fontWeight: "600",
                fontSize: "16px",

                "@media(max-width:768px)": {
                    display: "none"
                }
            },

            "& .links-wrapper": {
                listStyle: "none",
                paddingLeft: "0px",

                "& a": {
                    height: "60px",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "30px",
                    borderRadius: "10px 0 0 10px",
                    fontWeight: "600",
                    fontSize: "16px",

                    "@media(max-width:768px)": {
                        backgroundColor: "#fff",
                        marginBottom: 10,
                        justifyContent: "space-between",
                        paddingRight: "30px"
                    },

                    "&.active": {
                        backgroundColor: "#fff"
                    },

                    "& .icon-wrapper": {
                        display: "none",

                        "@media(max-width:768px)": {
                            display: "block",
                            transform: "scale(0.8)"
                        }
                    }
                }
            }
        },

        subscriptionContent: {
            backgroundColor: "#EEF4FB",
            borderRadius: "10px",
            margin: "50px",
            alignSelf: "flex-start",
            flex: "1",
            transition: "all 100ms ease-in-out",

            //--------------
            // Take note (mobile screns have a very different logic from desktop screens)
            //--------------
            "@media(max-width:768px)": {
                position: "absolute",
                width: "100%",
                zIndex: 5,
                margin: 0,
                backgroundColor: "transparent"
            },

            "& .mobile-header": {
                display: "none",

                "@media(max-width:768px)": {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 20,
                    paddingRight: 10
                },

                "&__back": {},

                "&__options": {}
            }
        }
    };
});
