import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  CircularProgress,
  Modal,
  TextField,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { propertyTeamStyles } from "./styles";
import PageHeader from "#/components/propertycomponents/page-header";
import {
  alertError,
  alertSuccess,
  showNotifications
} from "#/store/actions/notification.actions";
import SadFaceIconComponent from "#/components/ui-components/sadFaceIcon.component";
import AddButton from "#/components/propertycomponents/button-component";
import { propertyServiceApi } from "#/apis/propertyServiceApi";
import { authServiceApi } from "#/apis/authServiceApi";
import ExternalTeams from "./ExternalTeams";

import { InputLabel, MenuItem, Select } from "@mui/material";
import ConfirmationPopup from "#/components/popup/confirmation";
import usePost from "#/hooks/util-hooks/usePost";
import useFetch from "#/hooks/util-hooks/useFetch";
import { renderDate2 } from "#/shared/utils/general";

const PropertyTeam = () => {
  const [memberList, setMemberList] = useState([]);
  const [businessCompany, setBusinessCompany] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingBusiness, setLoadingBusiness] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleCreateModal, setCreateModal] = useState(false);
  const [toggleBusinessModal, setBusinessModal] = useState(false);
  const [newAdminModal, setNewAdminModal] = useState(false);
  const [dashboardView, setDashboardView] = useState("my-teams");
  const [selfContact, setSelfContact] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [liveizyID, setLiveizyID] = useState("");
  const [userName, setUserName] = useState("");

  const [popupDetails, setPopupDetails] = useState({
    title: "",
    description: "",
    proceedAction: null
  });

  const [newAdminForm, setAdminForm] = useState({
    lid: "",
    businessId: "",
    user_role: "",
    title: ""
  });

  const [businessForm, setBusinessForm] = useState({
    legal_name: "",
    address: "",
    phone: "",
    email: "",
    title: "",
    contact_person_id: selfContact || "",
    creator_role: ""
  });

  const [existingBusinessData, setExistingBusinessData] = useState(null);
  const [editFormData, setEditFormData] = useState({
    title: "",
    added_by: "",
    member_id: "",
    permissions: [
      { name: "bill", permissions: [] },
      { name: "apartment", permissions: [] },
      { name: "tenant", permissions: [] },
      { name: "property", permissions: [] }
    ]
  });

  const [createFormData, setcreateFormData] = useState({
    title: "",
    added_by: "",
    member_id: "",
    permissions: [
      { name: "bill", permissions: [] },
      { name: "apartment", permissions: [] },
      { name: "tenant", permissions: [] },
      { name: "property", permissions: [] }
    ]
  });

  const handlePopupOpen = (title, description, action) => {
    setPopupDetails({
      title,
      description,
      proceedAction: action
    });
    setOpenPopup(true);
  };

  const handlePopupClose = () => {
    setOpenPopup(false);
  };

  const dispatch = useDispatch();

  const handleModalOpen = () => {
    setToggleModal(true);
  };

  const handleModalClose = () => {
    setToggleModal(false);
  };

  const handleCreateModalOpen = () => {
    setCreateModal(true);
  };

  const handleCreateModalClose = () => {
    setCreateModal(false);
  };

  const handleAdminModalClose = () => {
    setNewAdminModal(false);
  };

  const handleBusinessModalOpen = () => {
    setBusinessModal(true);
    if (businessCompany) {
      // const [firstBusiness] = businessCompany; // Assuming you only need the first business
      setExistingBusinessData(businessCompany);
      setBusinessForm(businessCompany);
    }
  };

  const handleBusinessModalClose = () => {
    setBusinessModal(false);
  };

  const { data: adminData, loading: adminIsLoading, refetch } = useFetch(
    businessCompany._id ? `/business-administrators/${businessCompany._id}` : null
  );

  const { data: userData, loading: userIsLoading, error } = useFetch(
    liveizyID ? `/user/validate/liveizy/${liveizyID}` : null,
    {},
    authServiceApi
  );

  useEffect(() => {
    if (userData?.data !== null) {
      setUserName(userData?.data?.name || "");
    }
  }, [userData]);

  const handleGetUserInfo = (e) => {
    setLiveizyID(e.target.value);
  };

  const userInfoView = () => {
    return (
      <div>
        {userIsLoading && <p>Loading user info...</p>}
        {error && liveizyID && <p>Error fetching user info. Please try again.</p>}
        {liveizyID && userName && !userIsLoading && (
          <p>
            <strong>{userName}</strong>
          </p>
        )}
      </div>
    );
  };

  const handleCreate = () => {
    //Check if businessCompany is populated
    if (businessCompany && Object.keys(businessCompany).length === 0) {
      dispatch(showNotifications("You have to create a company first", "error"));
    } else {
      handleCreateModalOpen();
    }
  };

  const handleCreateBusiness = () => {
    handleBusinessModalOpen();
  };

  const handleInputChangeAdmin = (e) => {
    const { name, value } = e.target;
    setAdminForm((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleInputChangeBusiness = (e) => {
    const { name, value } = e.target;
    setBusinessForm((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleNewMemberCreate = (event) => {
    event.preventDefault();

    if (!createFormData.title || !createFormData.member_id) {
      dispatch(showNotifications("Please fill in all fields", "error"));
      return;
    }

    const formData = {
      title: createFormData.title,
      added_by: createFormData.added_by,
      liveizy_id: createFormData.member_id,
      permissions: createFormData.permissions,
      business: businessCompany._id
    };

    mutateNewMember(formData);
  };

  const handleNewAdminCreate = (event) => {
    event.preventDefault();

    if (!newAdminForm.lid || !newAdminForm.user_role) {
      dispatch(showNotifications("Please fill in all fields here", "error"));
      return;
    }

    mutateNewAdmin({
      lid: newAdminForm.lid,
      businessId: businessCompany._id,
      user_role: newAdminForm.user_role,
      title: newAdminForm.title
    });
  };

  const handleBusinessCreate = async (event) => {
    //Form submission logic
    event.preventDefault();
    try {
      //Decided if its an edit or create
      if (Object.keys(existingBusinessData).length !== 0) {
        if (
          !businessForm.phone ||
          !businessForm.legal_name ||
          !businessForm.address ||
          !businessForm.email ||
          !businessForm.title ||
          !businessForm.creator_role
        ) {
          dispatch(showNotifications("Please fill in all fields here", "error"));
          return;
        }
        // Construct the data object
        const formData = {
          legal_name: businessForm.legal_name,
          address: businessForm.address,
          phone: businessForm.phone,
          emailAddress: businessForm.email,
          title: businessForm.title,
          contact_person_id: businessForm.contact_person_id || selfContact,
          creator_role: businessForm.creator_role
        };
        const ownerId = existingBusinessData._id;

        // console.log(formData);
        // console.log(selfContact);

        // Make the POST request
        setLoadingBusiness(true);
        await propertyServiceApi().patch(`/business?id=${ownerId}`, formData);
        setLoadingBusiness(false);
        dispatch(showNotifications("Business created successfully", "success"));
        handleBusinessModalClose();

        // Fetch the updated businesses list
        fetchUserBusinessFromAPI();
      } else {
        // Make sure all fields are filled
        if (
          !businessForm.phone ||
          !businessForm.legal_name ||
          !businessForm.address ||
          !businessForm.email ||
          !businessForm.title ||
          !businessForm.creator_role
        ) {
          dispatch(showNotifications("Please fill in all fields", "error"));
          return;
        }

        // Construct the data object
        const formData = {
          legal_name: businessForm.legal_name,
          address: businessForm.address,
          phone: businessForm.phone,
          emailAddress: businessForm.email,
          title: businessForm.title,
          contact_person_id: businessForm.contact_person_id || selfContact,
          creator_role: businessForm.creator_role,
          roles: userInfo.roles
        };

        // console.log(formData);
        // console.log(selfContact);

        // Make the POST request
        setLoadingBusiness(true);
        await propertyServiceApi().post(`/business`, formData);
        setLoadingBusiness(false);
        dispatch(showNotifications("Business created successfully", "success"));
        handleBusinessModalClose();

        // Fetch the updated businesses list
        fetchUserBusinessFromAPI();
      }
    } catch (error) {
      // Handle errors
      console.error("Error submitting form:", error);
      const errorMessage =
        error.response?.data?.message || "An error occurred, try again later.";
      dispatch(showNotifications(errorMessage, "error"));
    }
  };

  const handleInputChangeCreate = (e) => {
    const { name, value } = e.target;
    setcreateFormData({
      ...createFormData,
      [name]: value
    });
  };

  const handlePermissionChange = (permissionName, permissionType) => {
    setcreateFormData((prevFormData) => {
      const updatedPermissions = prevFormData.permissions.map((permission) => {
        if (permission.name === permissionName) {
          return {
            ...permission,
            permissions: permission.permissions.includes(permissionType)
              ? permission.permissions.filter((p) => p !== permissionType)
              : [...permission.permissions, permissionType]
          };
        }
        return permission;
      });
      return {
        ...prevFormData,
        permissions: updatedPermissions
      };
    });
  };

  const handleEdit = (memberId) => {
    // Fetch member details and set to editFormData
    const member = memberList.find((m) => m._id === memberId);

    if (!member.permissions || !Array.isArray(member.permissions)) {
      // If member permissions are not defined or not an array, set an empty array
      member.permissions = [];
    }

    setEditFormData({
      title: member.title,
      added_by: member.added_by,
      member_id: member.member_id,
      _id: member._id,
      permissions: member.permissions.map((permission) => ({
        name: permission.name,
        permissions: [...permission.permissions]
      }))
    });

    handleModalOpen();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditFormData({
      ...editFormData,
      [name]: value
    });
  };

  const handlePermissionChangeEdit = (categoryIndex, permissionName) => {
    setEditFormData((prevFormData) => {
      const updatedPermissions = [...prevFormData.permissions];
      const permissionIndex = updatedPermissions[categoryIndex].permissions.indexOf(
        permissionName
      );
      if (permissionIndex === -1) {
        // Permission not found, add it
        updatedPermissions[categoryIndex].permissions.push(permissionName);
      } else {
        // Permission found, remove it
        updatedPermissions[categoryIndex].permissions.splice(permissionIndex, 1);
      }
      return {
        ...prevFormData,
        permissions: updatedPermissions
      };
    });
  };
  const handleFormSubmit = async (event) => {
    //Form submission logic
    event.preventDefault();
    try {
      // Make sure all fields are filled
      if (!editFormData.title || !editFormData.member_id) {
        dispatch(showNotifications("Please fill in all fields", "error"));
        return;
      }

      // Construct the data object
      const formData = {
        title: editFormData.title,
        added_by: editFormData.added_by,
        member_id: editFormData.member_id,
        id: editFormData._id,
        permissions: editFormData.permissions.map((permission) => ({
          name: permission.name,
          permissions: permission.permissions.filter((perm) => perm !== true)
        }))
      };

      // Make the POST request
      await propertyServiceApi().patch(`/account-member`, formData);
      dispatch(showNotifications("Members updated successfully", "success"));
      handleModalClose();
      // Fetch the updated member list
      fetchPropertiesFromAPI();
    } catch (error) {
      // Handle errors
      console.error("Error submitting form:", error);
      const errorMessage =
        error.response?.data?.message || "An error occurred, try again later.";
      dispatch(showNotifications(errorMessage, "error"));
    }
  };

  const handleDelete = async (memberId) => {
    // Placeholder for delete logic
    try {
      const param = {
        id: memberId
      };
      await propertyServiceApi().delete(`/account-member/${memberId}`, param);
      dispatch(showNotifications("Members deleted successfully", "success"));
      // Fetch the updated member list
      fetchPropertiesFromAPI();
    } catch (error) {
      // Handle errors
      console.error("Error deleting member:", error);
      const errorMessage =
        error.response?.data?.message || "An error occurred, try again later.";
      dispatch(showNotifications(errorMessage, "error"));
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const [propertiesResponse, businessResponse, userInfoResponse] = await Promise.all([
        fetchPropertiesFromAPI(),
        fetchUserBusinessFromAPI(),
        fetchUserFromAPI()
      ]);
      setMemberList(propertiesResponse.data.data);
      setBusinessCompany(businessResponse.data.data);
      setUserInfo(userInfoResponse.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage = error.response?.data?.message;
      dispatch(showNotifications(errorMessage, "error"));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchPropertiesFromAPI = async () => {
    setLoading(true);
    try {
      const response = await propertyServiceApi().get(`/account-member`);
      setMemberList(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage =
        error.response?.data?.message || "An error occurred, try again later.";
      dispatch(showNotifications(errorMessage, "error"));
    }
  };

  const fetchUserBusinessFromAPI = async () => {
    try {
      const response = await propertyServiceApi().get(`/business`);
      const responseData = response.data.data;
      if (responseData === null || typeof responseData !== "object") {
        // Handle the case where responseData is null or not an object
        setBusinessCompany([]); // Set to an empty array or handle differently based on your requirements
      } else {
        setBusinessCompany(responseData); // Set the state with the valid data
      }
    } catch (error) {
      setLoading(false);
      const errorMessage =
        error.response?.data?.message || "An error occurred, try again later.";
      dispatch(showNotifications(errorMessage, "error"));
    }
  };

  const fetchUserFromAPI = async () => {
    setLoading(true);
    try {
      const response = await authServiceApi().get(`/user/me`);
      setUserInfo(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage =
        error.response?.data?.message || "An error occurred, try again later.";
      dispatch(showNotifications(errorMessage, "error"));
    }
  };

  const { mutate: mutateToSuper } = usePost("/business/promote-super-admin", "post", {
    onSuccess: (data) => {
      dispatch(alertSuccess(data.message));
      refetch();
      fetchData();
    },
    onError: (error) => {
      dispatch(alertError(error.message));
      refetch();
      fetchData();
    }
  });

  const { mutate: mutateToChief } = usePost("/business/promote-chief-admin", "post", {
    onSuccess: (data) => {
      dispatch(alertSuccess(data.message));
      refetch();
      fetchData();
    },
    onError: (error) => {
      dispatch(alertError(error.message));
      refetch();
      fetchData();
    }
  });

  const { mutate: demoteToSuper } = usePost("/business/demote-super-admin", "post", {
    onSuccess: (data) => {
      dispatch(alertSuccess(data.message));
      refetch();
      fetchData();
    },
    onError: (error) => {
      dispatch(alertError(error.message));
      refetch();
      fetchData();
    }
  });

  const { mutate: demoteToAdmin } = usePost("/business/demote-admin", "post", {
    onSuccess: (data) => {
      dispatch(alertSuccess(data.message));
      refetch();
      fetchData();
    },
    onError: (error) => {
      dispatch(alertError(error.message));
      refetch();
      fetchData();
    }
  });

  const { mutate: mutateNewAdmin, loading: newAdminIsloading } = usePost(
    "/business/add-as-manager",
    "post",
    {
      onSuccess: (data) => {
        dispatch(alertSuccess(data.response.data.message));
        refetch();
      },
      onError: (error) => {
        dispatch(alertError(error.response.data.message));
        refetch();
      }
    }
  );

  const { mutate: mutateNewMember, loading: newMemberIsloading } = usePost(
    "/account-member",
    "post",
    {
      onSuccess: (data) => {
        dispatch(showNotifications("Members created successfully", "success"));
        handleCreateModalClose();
        // Fetch the updated member list
        fetchPropertiesFromAPI();
      },
      onError: (error) => {
        const errorMessage =
          error.response?.data?.message || "An error occurred, try again later.";
        dispatch(showNotifications(errorMessage, "error"));
      }
    }
  );

  const classes = propertyTeamStyles();

  return (
    <>
      <section>
        <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-center tw-my-14">
          <ul className="tw-rounded-2xl my-shadow tw-flex tw-transition-all tw-duration-100">
            <button
              onClick={() => setDashboardView("my-teams")}
              className={`${
                dashboardView === "my-teams"
                  ? "tw-bg-[#10609F] tw-text-white"
                  : "tw-bg-white tw-text-[#10609F]"
              } tw-px-8 tw-py-4 tw-rounded-2xl tw-cursor-pointer tw-font-semibold tw-transition-all tw-duration-100 text-link`}>
              Team
            </button>
            <button
              onClick={() => setDashboardView("external-teams")}
              className={`${
                dashboardView === "external-teams"
                  ? "tw-bg-[#10609F] tw-text-white"
                  : "bg-white tw-text-[#10609F]"
              } tw-px-8 tw-py-4 tw-rounded-2xl tw-cursor-pointer tw-font-semibold tw-transition-all tw-duration-100 text-link`}>
              External Teams
            </button>
          </ul>
        </div>
      </section>

      {dashboardView === "my-teams" && (
        <div
          className={"property-team-page" + " " + classes.root}
          id="property-team-page">
          <PageHeader>
            <h3> {businessCompany?.legal_name}</h3>
          </PageHeader>
          <div className={`plp-filter-block ${classes.search_container}`}>
            <div className={classes.right_section}>
              <div className={`${classes.buttonContainer} ${classes.buttonGap}`}>
                <Button className={classes.btn} onClick={handleCreate}>
                  <AddIcon className="icon" />
                  <span className="btn_name">New Property Member</span>
                </Button>

                <Button className={classes.btn} onClick={handleCreateBusiness}>
                  <AddIcon className="icon" />
                  <span className="btn_name">
                    {!loading && businessCompany.length === 0
                      ? "Create Company Account"
                      : "Edit Company Account"}
                  </span>
                </Button>
              </div>
            </div>

            <Button className={classes.btn} onClick={() => setNewAdminModal(true)}>
              <AddIcon className="icon" />
              <span className="btn_name">Add Administrator</span>
            </Button>
          </div>

          {loading && (
            <div className="tw-flex mtop-8 tw-justify-center tw-items-center">
              <CircularProgress />
            </div>
          )}

          <section style={{ marginTop: "40px" }}>
            {!loading && memberList.length === 0 && (
              <SadFaceIconComponent>
                {" "}
                <p>You have not added any member</p>
                <AddButton />
              </SadFaceIconComponent>
            )}
            {!loading && memberList.length > 0 && (
              <div>
                <h1 className="tw-mt-10 tw-text-[#102535] tw-font-semibold tw-mb-2 tw-text-xl">
                  Members
                </h1>
                <div className="tw-relative tw-overflow-x-auto">
                  <table
                    border="1"
                    className="tw-w-full tw-text-sm tw-text-left border tw-min-w-max">
                    <thead className="tw-text-xs tw-uppercase tw-bg-gray-200">
                      <tr>
                        <th scope="col" className="table-data">
                          s/n
                        </th>
                        <th scope="col" className="table-data">
                          Title
                        </th>
                        <th scope="col" className="table-data">
                          Name
                        </th>
                        <th scope="col" className="table-data">
                          Role
                        </th>
                        <th scope="col" className="table-data">
                          Permission Type
                        </th>
                        <th scope="col" className="table-data">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {memberList?.map((member, index) => (
                        <tr key={member.id}>
                          <td className="table-data">{index + 1}</td>
                          <td className="table-data">{member.title || "N/A"}</td>
                          <td className="table-data">{member.name || "N/A"}</td>
                          <td className="table-data">
                            {member?.roleDetails[0]?.role_name || "N/A"}
                          </td>
                          <td className="table-data">
                            {member.permission_type || "N/A"}
                          </td>
                          <td className="table-data">
                            <div className="tw-flex">
                              <button
                                className="table-action"
                                type="button"
                                onClick={() => handleEdit(member._id)}>
                                <EditIcon fontSize="small" /> Edit
                              </button>
                              {(member?.roleDetails[0]?.role_name === "admin" ||
                                member?.roleDetails[0]?.role_name === "super_admin") && (
                                <button
                                  className="table-action"
                                  type="button"
                                  onClick={() =>
                                    handlePopupOpen(
                                      "Promote to Chief Admin",
                                      `Are you sure you want to promote ${member.name} to Chief Admin?`,
                                      () =>
                                        mutateToChief({
                                          user_role: "chief_admin",
                                          lid: member?.member_id,
                                          businessId: member?.business
                                        })
                                    )
                                  }>
                                  Promote to Chief Admin
                                </button>
                              )}
                              {member?.roleDetails[0]?.role_name === "admin" && (
                                <button
                                  className="table-action"
                                  type="button"
                                  onClick={() =>
                                    handlePopupOpen(
                                      "Promote to Super Admin",
                                      `Are you sure you want to promote ${member.name} to Super Admin?`,
                                      () =>
                                        mutateToSuper({
                                          user_role: "super_admin",
                                          lid: member?.member_id,
                                          businessId: member?.business
                                        })
                                    )
                                  }>
                                  Promote to Super Admin
                                </button>
                              )}
                              {member?.roleDetails[0]?.role_name === "chief_admin" && (
                                <button
                                  className="table-action"
                                  type="button"
                                  onClick={() =>
                                    handlePopupOpen(
                                      "Demote to Super Admin",
                                      `Are you sure you want to demote ${member.name} to Super Admin?`,
                                      () =>
                                        demoteToSuper({
                                          user_role: "super_admin",
                                          lid: member?.member_id,
                                          businessId: member?.business
                                        })
                                    )
                                  }>
                                  Demote To Super Admin
                                </button>
                              )}
                              {(member?.roleDetails[0]?.role_name === "chief_admin" ||
                                member?.roleDetails[0]?.role_name === "super_admin") && (
                                <button
                                  className="table-action"
                                  type="button"
                                  onClick={() =>
                                    handlePopupOpen(
                                      "Demote to Admin",
                                      `Are you sure you want to demote ${member.name} to Admin?`,
                                      () =>
                                        demoteToAdmin({
                                          user_role: "admin",
                                          lid: member?.member_id,
                                          businessId: member?.business
                                        })
                                    )
                                  }>
                                  Demote To Admin
                                </button>
                              )}

                              <button
                                className="table-action red"
                                type="button"
                                onClick={() =>
                                  handlePopupOpen(
                                    "Delete Member",
                                    "Are you sure you want to delete this member?",
                                    () => handleDelete(member._id)
                                  )
                                }>
                                <DeleteIcon fontSize="small" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            <div className="tw-mt-14">
              <h1 className=" tw-text-[#102535] tw-font-semibold tw-mb-2 tw-text-xl">
                Administators
              </h1>
              {adminIsLoading && (
                <div className="tw-flex mtop-8 tw-justify-center tw-items-center">
                  <CircularProgress />
                </div>
              )}
              {(adminData?.data?.length === 0 || businessCompany.length === 0) && (
                <p>No Administrator here</p>
              )}
              {adminData?.data && adminData?.data?.length > 0 && (
                <div className="tw-relative tw-overflow-x-auto">
                  <table
                    border="1"
                    className="tw-w-full tw-text-sm tw-text-left border tw-min-w-max">
                    <thead className="tw-text-xs tw-uppercase tw-bg-gray-200">
                      <tr>
                        <th scope="col" className="table-data">
                          s/n
                        </th>
                        <th scope="col" className="table-data">
                          Liveizy id
                        </th>
                        <th scope="col" className="table-data">
                          Role
                        </th>
                        <th scope="col" className="table-data">
                          Added By
                        </th>
                        <th scope="col" className="table-data">
                          Date Created
                        </th>
                        <th scope="col" className="table-data">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {adminData?.data?.map((member, index) => (
                        <tr key={member.id}>
                          <td className="table-data">{index + 1}</td>
                          <td className="table-data">{member.lid || "N/A"}</td>
                          <td className="table-data">{member.role_name || "N/A"}</td>
                          <td className="table-data">{member.added_by || "N/A"}</td>
                          <td className="table-data">
                            {renderDate2(member.createdAt) || "N/A"}
                          </td>
                          <td className="table-data">
                            <div className="tw-flex">
                              {member?.role_name === "super_admin" && (
                                <button
                                  className="table-action"
                                  type="button"
                                  onClick={() =>
                                    handlePopupOpen(
                                      "Promote to Chief Admin",
                                      `Are you sure you want to promote ${member.role_name} to Chief Admin?`,
                                      () =>
                                        mutateToChief({
                                          user_role: "chief_admin",
                                          lid: member?.lid,
                                          businessId: member?.business_id
                                        })
                                    )
                                  }>
                                  Promote To Chief Admin
                                </button>
                              )}
                              {member?.role_name === "chief_admin" && (
                                <button
                                  className="table-action"
                                  type="button"
                                  onClick={() =>
                                    handlePopupOpen(
                                      "Demote to Super Admin",
                                      `Are you sure you want to demote ${member.role_name} to Super Admin?`,
                                      () =>
                                        demoteToSuper({
                                          user_ole: "super_admin",
                                          lid: member?.lid,
                                          businessId: member?.business_id
                                        })
                                    )
                                  }>
                                  Demote To Super Admin
                                </button>
                              )}
                              {(member?.role_name === "chief_admin" ||
                                member?.role_name === "super_admin") && (
                                <button
                                  className="table-action"
                                  type="button"
                                  onClick={() =>
                                    handlePopupOpen(
                                      "Demote to Admin",
                                      `Are you sure you want to demote ${member.role_name} to Admin?`,
                                      () =>
                                        demoteToAdmin({
                                          user_role: "admin",
                                          lid: member?.lid,
                                          businessId: member?.business_id
                                        })
                                    )
                                  }>
                                  Demote To Admin
                                </button>
                              )}
                              <button
                                className="table-action red"
                                type="button"
                                onClick={() =>
                                  handlePopupOpen(
                                    "Delete Member",
                                    "Are you sure you want to delete this member?",
                                    () => handleDelete(member._id)
                                  )
                                }>
                                <DeleteIcon fontSize="small" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            {/* <div className="tw-mt-14">
              <h1 className=" tw-text-[#102535] tw-font-semibold tw-mb-2 tw-text-xl">
                Properties
              </h1>
              {propertyIsLoading && (
                <div className="tw-flex mtop-8 tw-justify-center tw-items-center">
                  <CircularProgress />
                </div>
              )}
              {(propertyData?.data?.length === 0 || businessCompany.length === 0) && <p>No Properties here</p>}
              {propertyData?.data && propertyData?.data?.length > 0 && (
                <div className="tw-relative tw-overflow-x-auto">
                  <table
                    border="1"
                    className="tw-w-full tw-text-sm tw-text-left border tw-min-w-max">
                    <thead className="tw-text-xs tw-uppercase tw-bg-gray-200">
                      <tr>
                        <th scope="col" className="table-data">
                          s/n
                        </th>
                        <th scope="col" className="table-data">
                          Name
                        </th>
                        <th scope="col" className="table-data">
                          state
                        </th>
                        <th scope="col" className="table-data">
                          Location
                        </th>
                        <th scope="col" className="table-data">
                          Creator
                        </th>
                        <th scope="col" className="table-data">
                          Date Created
                        </th>
                        <th scope="col" className="table-data">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {propertyData?.data?.map((member, index) => (
                        <tr key={member.id}>
                          <td className="table-data">{index + 1}</td>
                          <td className="table-data">{member.title || "N/A"}</td>
                          <td className="table-data">{member.state || "N/A"}</td>
                          <td className="table-data">
                            {`${member.street}, ${member.lga}` || "N/A"}
                          </td>
                          <td className="table-data">{member.creator || "N/A"}</td>
                          <td className="table-data">
                            {renderDate2(member.createdAt) || "N/A"}
                          </td>
                          <td className="table-data">
                            <button
                              className="table-action"
                              type="button"
                              onClick={() => handleNavigate(member?.property_id)}>
                              View Property
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div> */}
          </section>

          <Modal open={toggleModal} onClose={handleModalClose} className={classes.modal}>
            <div
              className={classes.modalContent}
              style={{
                width: "50%",
                height: "auto",
                padding: "50px 20px",
                overflow: "auto"
              }}>
              <h2 style={{ marginBottom: "20px" }}>Edit Member</h2>
              <form onSubmit={handleFormSubmit}>
                <TextField
                  className={classes.formField}
                  label="Title"
                  name="title"
                  value={editFormData.title}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: "20px" }}
                />
                <TextField
                  className={classes.formField}
                  label="Added By"
                  name="added_by"
                  value={editFormData.added_by}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  disabled
                  style={{ marginBottom: "20px" }}
                />
                <TextField
                  className={classes.formField}
                  label="Member ID"
                  name="member_id"
                  value={editFormData.member_id}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: "20px" }}
                />

                {editFormData.permissions.map((permission, categoryIndex) => (
                  <div key={categoryIndex}>
                    <FormControl component="fieldset" className={classes.formField}>
                      <FormLabel component="legend">
                        {permission.name.toUpperCase()}
                      </FormLabel>
                      <FormGroup
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: "30px"
                        }}>
                        {["view", "edit", "create", "delete"].map(
                          (permissionName, permissionIndex) => (
                            <FormControlLabel
                              key={permissionIndex}
                              control={
                                <Checkbox
                                  checked={permission.permissions.includes(
                                    permissionName
                                  )}
                                  onChange={() =>
                                    handlePermissionChangeEdit(
                                      categoryIndex,
                                      permissionName
                                    )
                                  }
                                  name={`${permission.name}.${permissionName}`}
                                />
                              }
                              label={permissionName}
                            />
                          )
                        )}
                      </FormGroup>
                    </FormControl>
                  </div>
                ))}
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </form>
            </div>
          </Modal>

          <Modal
            open={toggleCreateModal}
            onClose={handleCreateModalClose}
            className={classes.modal}
            style={{ alignItems: "center", justifyContent: "center" }}>
            <div
              className={classes.modalContent}
              style={{
                width: "50%",
                height: "auto",
                padding: "50px 20px",
                overflow: "auto"
              }}>
              <h2 style={{ marginBottom: "20px" }}>Add Member</h2>
              <form onSubmit={handleNewMemberCreate}>
                <TextField
                  className={classes.formField}
                  label="Title(portfolio)"
                  name="title"
                  value={createFormData.title}
                  onChange={handleInputChangeCreate}
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: "20px" }}
                />
                <TextField
                  className={classes.formField}
                  label="Liveizy ID"
                  name="member_id"
                  value={createFormData.member_id}
                  onChange={(e) => {
                    handleInputChangeCreate(e);
                    handleGetUserInfo(e);
                  }}
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: "7px" }}
                />
                {userInfoView()}

                <div
                  style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}>
                  <FormControl component="fieldset" className={classes.formField}>
                    <FormLabel component="legend">Permissions</FormLabel>
                    {createFormData.permissions.map((permission, index) => (
                      <FormGroup
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: "30px"
                        }}>
                        <FormLabel component="legend">
                          {permission.name.toUpperCase()}
                        </FormLabel>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={permission.permissions.includes("view")}
                              onChange={() =>
                                handlePermissionChange(permission.name, "view")
                              }
                              name={`${permission.name}.view`}
                            />
                          }
                          label="View"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={permission.permissions.includes("edit")}
                              onChange={() =>
                                handlePermissionChange(permission.name, "edit")
                              }
                              name={`${permission.name}.edit`}
                            />
                          }
                          label="Edit"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={permission.permissions.includes("create")}
                              onChange={() =>
                                handlePermissionChange(permission.name, "create")
                              }
                              name={`${permission.name}.create`}
                            />
                          }
                          label="Create"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={permission.permissions.includes("delete")}
                              onChange={() =>
                                handlePermissionChange(permission.name, "delete")
                              }
                              name={`${permission.name}.delete`}
                            />
                          }
                          label="Delete"
                        />
                      </FormGroup>
                    ))}
                  </FormControl>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px"
                  }}>
                  <Button type="submit" variant="contained" color="primary">
                    {newMemberIsloading ? (
                      <CircularProgress size={25} color="inherit" className="spinner" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              </form>
            </div>
          </Modal>

          <Modal
            open={toggleBusinessModal}
            onClose={handleBusinessModalClose}
            className={classes.modal}
            style={{ alignItems: "center", justifyContent: "center" }}>
            <div
              className={classes.modalContent}
              style={{
                width: "50%",
                height: "auto",
                padding: "50px 20px",
                overflow: "auto"
              }}>
              <h1 style={{ marginBottom: "20px" }}>Create Business </h1>
              <form onSubmit={handleBusinessCreate}>
                <TextField
                  className={classes.formField}
                  label="Company Name (Legal Name)"
                  name="legal_name"
                  value={businessForm.legal_name}
                  onChange={handleInputChangeBusiness}
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: "20px" }}
                />

                <TextField
                  className={classes.formField}
                  label="Company Address"
                  name="address"
                  value={businessForm.address}
                  onChange={handleInputChangeBusiness}
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: "20px" }}
                />

                <TextField
                  className={classes.formField}
                  label="Company Phone No"
                  name="phone"
                  value={businessForm.phone}
                  onChange={handleInputChangeBusiness}
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: "20px" }}
                />

                <TextField
                  className={classes.formField}
                  label="Company Email"
                  name="email"
                  value={businessForm.email}
                  onChange={handleInputChangeBusiness}
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: "20px" }}
                />

                <div className="tw-my-7">
                  <p>
                    <span className="tw-font-semibold">
                      This creates the first Administator account on your business
                    </span>
                  </p>
                  <p>
                    <span className="tw-font-semibold">Chief Admin</span>: This is the
                    owner of Business or Directors
                  </p>
                  <p>
                    <span className="tw-font-semibold">Super Admin</span>: This is the
                    designated super manager of Business
                  </p>
                </div>

                <TextField
                  className={classes.formField}
                  label="Administator Title"
                  name="title"
                  value={businessForm.title}
                  onChange={handleInputChangeBusiness}
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: "20px" }}
                />

                <FormControl fullWidth style={{ marginBottom: "20px" }}>
                  <InputLabel id="creator_role">Administator Role</InputLabel>
                  <Select
                    labelId="creator_role"
                    id="creator_role"
                    name="creator_role"
                    value={businessForm.creator_role}
                    label="Administator Role"
                    onChange={handleInputChangeBusiness}>
                    <MenuItem value="chief_admin">Chief Admin</MenuItem>
                    <MenuItem value="super_admin">Super Admin</MenuItem>
                  </Select>
                </FormControl>

                <div>
                  {!selfContact && (
                    <>
                      <TextField
                        className={classes.formField}
                        label="Contact Person (Liveizy ID)"
                        name="contact_person_id"
                        value={businessForm.contact_person_id || selfContact}
                        onChange={(e) => {
                          handleInputChangeBusiness(e);
                          handleGetUserInfo(e);
                        }}
                        variant="outlined"
                        fullWidth
                        style={{ marginBottom: "10px" }}
                      />
                      {userInfoView()}
                    </>
                  )}

                  <FormControlLabel
                    style={{ marginTop: "20px" }}
                    control={
                      <Checkbox
                        checked={Boolean(selfContact)}
                        onChange={() =>
                          setSelfContact((prev) => (prev ? "" : userInfo?.liveizy_id))
                        }
                        name={`selfContact`}
                      />
                    }
                    label="Set self as contact person"
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px"
                  }}>
                  <Button type="submit" variant="contained" color="primary">
                    {loadingBusiness ? (
                      <CircularProgress size={25} color="inherit" className="spinner" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              </form>
            </div>
          </Modal>

          <Modal
            open={newAdminModal}
            onClose={handleAdminModalClose}
            className={classes.modal}
            style={{ alignItems: "center", justifyContent: "center" }}>
            <div
              className={classes.modalContent}
              style={{
                width: "50%",
                height: "auto",
                padding: "50px 20px",
                overflow: "auto"
              }}>
              <h2 style={{ marginBottom: "20px" }}>Add Administrator</h2>
              <form onSubmit={handleNewAdminCreate}>
                <div>
                  <TextField
                    className={classes.formField}
                    label="New Administrator(Liveizy ID)"
                    name="lid"
                    value={newAdminForm.lid}
                    onChange={(e) => {
                      handleInputChangeAdmin(e);
                      handleGetUserInfo(e);
                    }}
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: "7px" }}
                  />
                  {userInfoView()}
                </div>
                <div>
                  <TextField
                    className={classes.formField}
                    label="Administrator Title"
                    name="title"
                    value={newAdminForm.title}
                    onChange={handleInputChangeAdmin}
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: "20px", marginTop: "20px" }}
                  />
                </div>

                <div className="tw-my-5">
                  <p>
                    <span className="tw-font-semibold">Chief Admin</span>: This is the
                    owner of Business or Directors
                  </p>
                  <p>
                    <span className="tw-font-semibold">Super Admin</span>: This is the
                    designated super manager of Business
                  </p>
                </div>

                <FormControl fullWidth style={{ marginBottom: "20px" }}>
                  <InputLabel id="creator_role">Administrator Role</InputLabel>
                  <Select
                    labelId="user_role"
                    id="user_role"
                    name="user_role"
                    value={newAdminForm.user_role}
                    label="Role"
                    onChange={handleInputChangeAdmin}>
                    <MenuItem value="chief_admin">Chief Admin</MenuItem>
                    <MenuItem value="super_admin">Super Admin</MenuItem>
                  </Select>
                </FormControl>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px"
                  }}>
                  <Button type="submit" variant="contained" color="primary">
                    {newAdminIsloading ? (
                      <CircularProgress size={25} color="inherit" className="spinner" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              </form>
            </div>
          </Modal>

          {/* Popup Component */}
          {openPopup && (
            <ConfirmationPopup
              title={popupDetails.title}
              description={popupDetails.description}
              proceedAction={() => {
                popupDetails.proceedAction();
                handlePopupClose();
              }}
              handleClose={handlePopupClose}
            />
          )}
        </div>
      )}
      {dashboardView === "external-teams" && <ExternalTeams />}
    </>
  );
};

export default PropertyTeam;
