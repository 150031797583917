import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import { useRouteMatch, NavLink, Route, Redirect } from "react-router-dom";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import useWindowSize from "react-use/lib/useWindowSize";
import BackComponent from "#/components/propertycomponents/back-component";
import RentReminder from "./component/rentReminder/rentReminder";
import LiremReminder from "./component/liremSubscription/liremSubscription";
import LipmanReminder from "./component/lipmanSubscription/lipmanSubscription";
import OptionsDropdown from "./component/optionsDropdown/optionsDropdown";
import {
  getAllInactiveReminders,
  getAllActiveReminders,
  getAllDeliveredReminders,
  payForRentReminders,
  getSubscriptionRemindersSummary,
  getApartmentsInPlan
} from "#/store/actions/reminder.action";
import { useSubscriptionStyles } from "./subscription.styles";

const Subscription = () => {
  //--------------------------------------------------
  //Helper hooks
  //--------------------------------------------------
  const classes = useSubscriptionStyles();
  const windowSize = useWindowSize();
  const dispatch = useDispatch();

  let { path, url } = useRouteMatch();
  const inRentReminder = useRouteMatch(`${url}/rent-reminder`);
  const inLirem = useRouteMatch(`${url}/lirem`);
  const inLipman = useRouteMatch(`${url}/lipman`);
  const inTenancyAgreement = useRouteMatch(`${url}/tenancy-agreement`);

  const inInnerPage = inRentReminder || inLirem || inLipman;

  //--------------------------------------------------
  //Store
  //--------------------------------------------------
  const user = useSelector((state) => state.auth.user);
  const {
    inactiveRems,
    activeRems,
    deliveredRems,
    loadingSummary,
    paying,
    dateActivated,
    apartmentsInPlan
  } = useSelector((state) => state.reminder);

  const isLandlord = user.roles.includes("Landlord");
  //---------------------------------------------
  //Function Declaration
  //---------------------------------------------
  const handlePayment = () => {
    dispatch(
      payForRentReminders(() => {
        //After paying for inactive reminders, update all reminders
        batch(() => {
          dispatch(getApartmentsInPlan(user.id));
          dispatch(getSubscriptionRemindersSummary(user.id));
        });
      })
    );
  };

  //--------------------------------------------------
  //Side effects
  //--------------------------------------------------
  //Get reminders on intital omponent mount
  useEffect(() => {
    batch(() => {
      dispatch(getApartmentsInPlan(user.id));
      dispatch(getSubscriptionRemindersSummary(user.id));
    });
  }, []);

  return (
    <div className={classes.wrapper}>
      {/* ------------------------------- */}
      {/* Page Navigation */}
      {/* ------------------------------- */}
      <nav
        className={classes.tabs}
        style={{
          transform:
            windowSize.width < 768
              ? inInnerPage
                ? "translateX(-100vw)"
                : "translateX(0px)"
              : "translateX(0px)"
        }}>
        <h3 className="title">Subscription</h3>
        <ul className="links-wrapper">
          {/*
                    <NavLink to={`${url}/rent-reminder`}>
                        Rent Reminder
                        <div className="icon-wrapper">
                            <ArrowForwardIosIcon />
                        </div>
                    </NavLink>
                    */}
          {isLandlord && (
            <>
              <NavLink to={`${url}/lirem`}>
                LiReM
                <div className="icon-wrapper">
                  <ArrowForwardIosIcon />
                </div>
              </NavLink>
              <NavLink to={`${url}/lipman`}>
                LiPMaN
                <div className="icon-wrapper">
                  <ArrowForwardIosIcon />
                </div>
              </NavLink>
            </>
          )}

          {/* <NavLink to={`${url}/tenancy-agreement`}>Tenancy Agreement</NavLink> */}
        </ul>
      </nav>

      <div
        className={classes.subscriptionContent}
        style={{
          transform:
            windowSize.width < 768
              ? inInnerPage
                ? "translateX(0px)"
                : "translateX(100vw)"
              : "translateX(0px)"
        }}>
        {/* ------------------------------- */}
        {/* Mobile screen header */}
        {/* ------------------------------- */}
        {/* (Hidden on desktop view by using media queries) */}
        <div className="mobile-header">
          <div className="mobile-header__back">
            <BackComponent redirectUrl="/property/subscription" />
          </div>
          <div className="mobile-header__options">
            <OptionsDropdown></OptionsDropdown>
          </div>
        </div>

        {/* ------------------------------- */}
        {/* Routes */}
        {/* ------------------------------- */}
        {/* If we are on a desktop screen then redirect straight away to the rent reminder screen */}
        {windowSize.width < 768 ? null : (
          <Route path={path} exact>
            <Redirect to={`${path}/rent-reminder`} />
          </Route>
        )}
        <Route path={`${path}/rent-reminder`}>
          <RentReminder
            inactiveReminders={inactiveRems}
            activeReminders={activeRems}
            deliveredReminders={deliveredRems}
            dateActivated={dateActivated}
            loadingSummary={loadingSummary}
            handlePayment={handlePayment}
            apartmentsInPlan={apartmentsInPlan}
            paying={paying}
          />
        </Route>
        <Route path={`${path}/lirem`}>
          <LiremReminder />
        </Route>
        <Route path={`${path}/lipman`}>
          <LipmanReminder />
        </Route>
        {/* <Route path={`${path}/tenancy-agreement`}>This is for tenancy agreement</Route> */}
      </div>
    </div>
  );
};

export default Subscription;
