import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

const BreadCrumbs = ({ links }) => {
    return (
        <div style={{ marginBottom: 30, opacity: 0.7 }}>
            <Breadcrumbs aria-label="breadcrumb">
                {links.map((link, idx) => (
                    <Link
                        key={idx}
                        underline="hover"
                        color={link.currentPage ? "text.primary" : "inherit"}
                        href={link?.href}
                        aria-current={link.currentPage && "page"}>
                        {link?.title}
                    </Link>
                ))}
            </Breadcrumbs>
        </div>
    );
};

export default BreadCrumbs;
