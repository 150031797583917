import React from "react";
import ProfilePictureWrapper from "./ProfilePictureWrapper";

// THIS COMPONENT RENDERS IMAGE URL FROM CLOUDINARY

const ProfilePicLinkPreview = ({ profileImage }) => {
    return (
        <ProfilePictureWrapper>
            <img src={profileImage} alt="profile pic preview" />
        </ProfilePictureWrapper>
    );
};

export default ProfilePicLinkPreview;
