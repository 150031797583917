import { makeStyles } from "@material-ui/core/styles";

export const accountSectionStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: "20px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  cardbody: {
    marginLeft: "10px",
  },

  buttonX: {
    marginTop: "30px",
  },

  cardhead: {
    padding: "20px 15px 20px 20px",
    marginTop: "0px",
    backgroundColor: "#E8F0F9",
    opacity: "1",

    "& span": {
      fontSize: "20px",
      fontWeight: "bold",
      color: "#043B5C",
    },
  },

  icon: {
    margin: "2px 8px 2px 8px",
  },

  button: {
    padding: "12px 70px",
    backgroundColor: "#4786FF",
    borderColor: "#4786FF",
    color: "#F4F5F7",
    textTransform: "capitalize",
    fontSize: "13px",
    width: "185px",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },

    "&:hover": {
      color: "#F4F5F7",
      backgroundColor: "#4786FF",
    },

    "&:focus": {
      outline: "none",
    },
  },

  inputTitle: {
    marginBottom: "10px",
    fontSize: "13px",
    fontWeight: "600",
    color: "#043B5C",
  },

  Title: {
    // marginBottom: "10px",
    fontSize: "13px",
    fontWeight: "bold",
    color: "#043B5C",
  },

  input: {
    width: "100%",
    padding: "13px 25px",
    border: "1px solid #cbcbcb",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "250px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },

    [theme.breakpoints.between("md", "lg")]: {
      width: "100%",
    },
  },

  formControl: {
    width: "100%",
    // madWidth: 200,

    // [theme.breakpoints.between("768", "1024")]: {
    //   width: "100%",
    // },
    "& .MuiInputBase-root": {
      // width: "250px",
      // [theme.breakpoints.down("xs")]: {
      //   width: "270px",
      // },
      // [theme.breakpoints.up("md")]: {
      //   width: "290px",
      // },
      // [theme.breakpoints.down("sm")]: {
      //   width: "260px",
      // },

      "& >.Mui-focused": {
        focus: "none",
        outline: "none",
      },
    },
  }, // [theme.breakpoints.between("md", "lg")]: {
  //   width: "100%",
  // },

  select: {
    padding: "9px",
    // width: "100%",
    // [theme.breakpoints.down("md")]: {
    //   width: "270px",
    // },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));
