import { makeStyles } from "@material-ui/core/styles";

export const useInnerListingStyles = makeStyles((theme) => ({
    innerListingContainer: {
        // display: "flex",
        backgroundColor: "white",
        padding: 60,
        borderRadius: "15px",
        marginBottom: "100px",

        [theme.breakpoints.between("0", "700")]: {
            flexDirection: "column",
            padding: "25px 15px",
            marginBottom: "20px"
        },

        "& h1": {
            fontSize: 18,
            fontWeight: 800,
            color: "#043B5C",

            [theme.breakpoints.between("0", "992")]: {
                fontSize: 14
            }
        },

        "& h5": {
            fontSize: 14,
            fontWeight: 800,
            color: "#043B5C",

            [theme.breakpoints.between("0", "992")]: {
                fontSize: 12
            }
        },

        "& .main-body": {
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: 70,

            [theme.breakpoints.between("0", "992")]: {
                display: "block"
            }
        }
    },

    sliderContainer: {
        position: "relative",

        "& .slider-directions": {
            position: "absolute",
            width: "100%",
            height: 350,
            zIndex: 5,

            [theme.breakpoints.between("0", "992")]: {
                height: 210
            },

            "& .direction": {
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                width: 15,
                height: 15,
                borderBottom: "2px solid #fff",
                borderLeft: "2px solid #fff",
                cursor: "pointer",

                [theme.breakpoints.between("0", "992")]: {
                    width: 10,
                    height: 10
                },

                "&.back": {
                    left: 15,
                    transform: "translateY(-50%) rotate(45deg)"
                },

                "&.forward": {
                    right: 15,
                    transform: "translateY(-50%) rotate(-135deg)"
                }
            }
        }
    },

    imageContainer: {
        height: 350,
        backgroundColor: "rgba(4, 58, 92, 0.759)",
        borderRadius: 15,
        marginBottom: 40,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        fontSize: "18px",

        [theme.breakpoints.between("0", "992")]: {
            height: 210
        }
    },

    apartmentDescription: {
        marginBottom: 40,

        "& .description": {
            fontSize: 14,
            color: "#043B5C",

            [theme.breakpoints.between("0", "992")]: {
                fontSize: 12
            }
        }
    },

    apartmentCharges: {
        marginBottom: 40,

        "& .charges-title": {
            marginBottom: 15,
            fontFamily: "Open Sans-bold"
        },

        "& .charges-wrapper": {
            display: "flex",
            flexWrap: "wrap"
        },

        "& .no-charges": {
            fontSize: 14,
            color: "#043B5C",

            [theme.breakpoints.between("0", "992")]: {
                fontSize: 12
            }
        }
    },

    apartmentAmenities: {
        marginBottom: 40,

        "& .amenities-title": {
            marginBottom: 15,
            fontFamily: "Open Sans-bold"
        },

        "& .amenitites-wrapper": {
            display: "flex",
            flexWrap: "wrap"
        },

        "& .no-amenities": {
            fontSize: 14,
            color: "#043B5C",

            [theme.breakpoints.between("0", "992")]: {
                fontSize: 12
            }
        }
    },

    apartmentDetails: {
        "& .miscellaneous": {
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gridGap: 20,
            marginBottom: 35
        },

        "& .user-profile": {
            display: "flex",
            alignItems: "flex-end",
            marginBottom: 35,

            "& .avatar": {
                width: 45,
                height: 45,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                borderRadius: "50%"
            },

            "& .name-phone": {
                "& h1": {
                    fontFamily: "Open Sans-bold",
                    marginBottom: 0
                },

                "& p": {
                    fontFamily: "Open Sans-Semibold",
                    fontSize: 12,
                    color: "#043B5C"
                }
            }
        }
    },

    availableTimeSlots: {
        backgroundColor: "#F4F5F7",
        height: 355,
        marginBottom: 35,
        borderRadius: 10,
        overflow: "auto",
        maxWidth: 400,

        "& h1": {
            color: "#E6ECEF",
            backgroundColor: "#043B5C",
            height: 50,
            paddingLeft: 30,
            display: "flex",
            alignItems: "center"
        },

        "& .available-days": {
            listStyle: "none",
            padding: "0px 30px",

            "& li": {
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #043B5C26",
                height: 40,
                alignItems: "center",
                cursor: "pointer",

                "&:hover": {
                    color: "#E6ECEF",
                    backgroundColor: "#043B5C"
                },

                "&:last-child": {
                    borderBottom: "0px"
                },

                "& h4": {
                    fontSize: 14,
                    fontFamily: "Open Sans-Semibold"
                }
            }
        }
    },

    bookedInspection: {
        backgroundColor: "#F4F5F7",
        height: 355,
        marginBottom: 35,
        borderRadius: 10,
        overflow: "hidden",
        maxWidth: 400,
        display: "flex",
        flexDirection: "column",

        "& h1": {
            color: "#E6ECEF",
            backgroundColor: "#043B5C",
            height: 50,
            paddingLeft: 30,
            display: "flex",
            alignItems: "center"
        },

        "& h2": {
            textAlign: "center",
            color: "#043B5C",
            fontFamily: "Open Sans-Semibold",
            fontSize: 30,
            marginBottom: 15
        },

        "& h3": {
            textAlign: "center",
            color: "#043B5C",
            fontFamily: "Open Sans-Semibold",
            fontSize: 20,
            marginBottom: 15
        },

        "& button": {
            borderRadius: 5,
            border: "1px solid #4786FF",
            height: 40,
            display: "flex",
            alignItems: "center",
            backgroundColor: "transparent",
            color: "#4786FF",
            padding: "0px 40px",
            fontSize: 14,
            fontFamily: "Open Sans-Semibold"
        }
    },

    btnBlue: {
        backgroundColor: "#4786FF",
        textTransform: "none",
        borderRadius: "5px",
        color: "#F4F5F7",
        border: "1px solid #4786FF",
        fontFamily: "Open Sans, sans-serif",
        marginBottom: "35px"
    },

    btnRedOutlined: {
        backgroundColor: "#fff",
        color: "#FF4747",
        border: "1px solid #FF4747"
    },

    btnBlueOutlined: {
        backgroundColor: "#fff",
        color: "#4786FF",
        border: "1px solid #4786FF"
    },

    // -------------------------------------------
    // -------------------------------------------
    // -------------------------------------------

    apartmentHeaderAndContent: {
        "& h1": {
            fontSize: 18,
            fontWeight: 800,
            color: "#043B5C",
            fontFamily: "Open Sans-bold",

            [theme.breakpoints.between("0", "992")]: {
                fontSize: 14
            }
        },

        "& h5": {
            fontSize: 14,
            fontWeight: 800,
            color: "#043B5C",
            fontFamily: "Open Sans-Semibold",

            [theme.breakpoints.between("0", "992")]: {
                fontSize: 12
            }
        },

        "&.address-details": {
            marginBottom: 25,
            paddingTop: 35,

            [theme.breakpoints.between("0", "992")]: {
                paddingTop: 0
            }
        }
    },

    //Charge card
    chargeCard: {
        display: "flex",
        backgroundColor: "#F3F3F3",
        borderRadius: "5px",
        padding: "15px 15px",
        cursor: "pointer",
        transition: "all 200ms ease-in-out",
        marginRight: "15px",
        marginBottom: "15px",

        "& .left-column": {
            marginRight: "15px",
            "& h3": {
                fontSize: "14px",
                marginBottom: "10px",
                fontWeight: "600",
                color: "#043B5C",
                fontFamily: "Open Sans-bold"
            },

            "& h4": {
                fontSize: "13px",
                marginBottom: "0px",
                color: "#043B5C",
                fontFamily: "Open Sans-Semibold"
            }
        },

        "& .charge-amount": {
            fontSize: "14px",
            color: "#043B5C",
            fontFamily: "Open Sans-Semibold"
        }
    },

    amenitiesCard: {
        display: "flex",
        backgroundColor: "#F3F3F3",
        borderRadius: "10px",
        padding: "10px 20px",
        cursor: "pointer",
        transition: "all 200ms ease-in-out",
        marginRight: "15px",
        marginBottom: "15px",
        color: "#043B5C",
        fontFamily: "Open Sans-Semibold",
        fontSize: 14
    }
}));

// export const useInnerListingStyles = makeStyles((theme) => ({
//     innerListingContainer: {
//         display: "flex",
//         maxWidth: "1465px",
//         backgroundColor: "white",
//         padding: "25px 26px",
//         borderRadius: "15px",
//         marginBottom: "100px",

//         [theme.breakpoints.between("0", "700")]: {
//             flexDirection: "column",
//             overflowX: "scroll",
//             padding: "25px 15px",
//             marginBottom: "20px"
//         }
//     },
//     imagesContainer: {
//         width: "60%",
//         marginRight: "54px",
//         maxWidth: "770px",

//         [theme.breakpoints.between("0", "700")]: {
//             width: "100%"
//         },
//         [theme.breakpoints.between("700", "1100")]: {
//             width: "55%",
//             marginRight: "34px"
//         }
//     },
//     imagesBig: {
//         width: "99%",
//         marginBottom: "46px",

//         [theme.breakpoints.between("0", "700")]: {
//             width: "100%",
//             marginBottom: "15px"
//         },

//         [theme.breakpoints.between("700", "1100")]: {
//             width: "100%"
//         },

//         "& img": {
//             width: "100%",
//             height: 413,
//             objectFit: "cover",
//             borderRadius: "10px",

//             [theme.breakpoints.between("0", "430")]: {
//                 height: 200
//             },

//             [theme.breakpoints.between("430", "600")]: {
//                 height: 300
//             },

//             [theme.breakpoints.between("600", "700")]: {
//                 height: 400
//             },

//             [theme.breakpoints.between("700", "1100")]: {
//                 height: 360
//             }
//         }
//     },
//     smallImagesDesktopView: {
//         width: "100%",
//         maxWidth: "770px",
//         marginBottom: "30px",
//         display: "flex",
//         justifyContent: "space-between",

//         [theme.breakpoints.between("700", "1100")]: {
//             minWidth: "100%",
//             overflowX: "scroll"
//         },

//         [theme.breakpoints.between("0", "700")]: {
//             display: "none",
//             width: "100%",
//             overflowX: "scroll",
//             justifyContent: "flex-start"
//         },

//         "& img": {
//             width: "95%",
//             maxWidth: "245px",
//             height: 154,
//             borderRadius: "10px",
//             objectFit: "cover",

//             [theme.breakpoints.between("700", "1100")]: {
//                 width: "95%",
//                 height: "100%"
//             },

//             [theme.breakpoints.between("0", "700")]: {
//                 width: "calc(100vw/3)",
//                 marginRight: 5,
//                 height: "100%"
//             }
//         }
//     },
//     smallImagesMobileView: {
//         marginBottom: "30px",
//         justifyContent: "space-between",
//         display: "none",
//         width: "100%",
//         maxWidth: "770px",

//         [theme.breakpoints.between("700", "1100")]: {
//             minWidth: "100%",
//             overflowX: "scroll"
//         },

//         [theme.breakpoints.between("0", "700")]: {
//             width: "100%",
//             display: "flex",
//             overflowX: "scroll",
//             justifyContent: "flex-start"
//         },

//         "& img": {
//             width: "95%",
//             maxWidth: "245px",
//             height: 154,
//             borderRadius: "10px",
//             objectFit: "cover",

//             [theme.breakpoints.between("700", "1100")]: {
//                 width: "95%",
//                 height: "100%"
//             },

//             [theme.breakpoints.between("0", "700")]: {
//                 width: "calc(100vw/3)",
//                 marginRight: 5,
//                 height: "100%"
//             }
//         }
//     },
//     seeMore: {
//         width: "calc(100vw/3)",
//         height: "100%",
//         borderRadius: "10px",
//         padding: "15px 10px",
//         backgroundColor: "#F4F5F7"
//     },
//     details: {
//         maxWidth: 491,
//         width: "30%",

//         [theme.breakpoints.between("0", "700")]: {
//             width: "100%",
//             maxWidth: "100%"
//         },

//         [theme.breakpoints.between("700", "1100")]: {
//             width: 300
//         },

//         "& h1": {
//             fontSize: 30,
//             marginTop: 20
//         },
//         "& h4": {
//             fontSize: 24,
//             margin: 0
//         },
//         "& h3": {
//             fontSize: 24,
//             marginTop: 40
//         },
//         "& p": {
//             width: "100%",
//             fontSize: 16,
//             marginBottom: 10,
//             fontWeight: "normal"
//         }
//     },
//     location: {
//         display: "flex",
//         marginTop: 10,
//         alignItems: "center",

//         "& p": {
//             margin: 0
//         },
//         "& h4": {
//             marginRight: 10
//         }
//     },
//     features: {
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "space-between"
//     },
//     feature: {
//         width: 112
//     },
//     button: {
//         width: "100%",
//         color: "#F4F5F7",
//         backgroundColor: "#4786FF",
//         height: "55px",
//         marginTop: 20,
//         borderStyle: "none",
//         borderRadius: "5px"
//     }
// }));
