import React from "react";

// import { styled } from "@mui/material";
// import SubtitleAndSubtext from "../others/SubtitleAndSubtext";
// import DetailsCardContainer, { Details } from "./DetailsCardContainer";
import DetailsCardContainer, {
  DetailsContainer
} from "#/components/cards/DetailsCardContainer.component";
import SubtitleAndSubtext from "#/components/ui-components/SubtitleAndSubtext.component";
import DocumentPreview from "../others/DocumentPreview";
import { toCurrency } from "#/components/utils/util";

const JobDetails = ({
  employmentStatus,
  officeContactPersonName,
  employerName,
  officeContactPersonNumber,
  officeAddress,
  monthlyIncome
}) => {
  return (
    <DetailsCardContainer>
      <DetailsContainer>
        <SubtitleAndSubtext subtitle={"Employment Status"} subtext={employmentStatus} />
        <SubtitleAndSubtext
          subtitle={"Office Contact Person Name"}
          subtext={officeContactPersonName}
        />

        <SubtitleAndSubtext subtitle={"Employer Name"} subtext={employerName} />
        <SubtitleAndSubtext
          subtitle={"Office Contact Person Number"}
          subtext={officeContactPersonNumber}
        />

        <SubtitleAndSubtext subtitle={"Office Address"} subtext={officeAddress} />

        <SubtitleAndSubtext
          subtitle={"Monthly Income"}
          subtext={toCurrency(monthlyIncome)}
        />
      </DetailsContainer>
      <div style={{ marginTop: "10px" }} className="position-center-column">
        <p>
          <b>ACCOUNT STATEMENT DOC</b>
        </p>
        <DocumentPreview />
      </div>
    </DetailsCardContainer>
  );
};

export default JobDetails;
