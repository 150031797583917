import CustomInputAndLabelField from "#/components/customInputFields/CustomInputAndLabelField";
import { DeleteOutlineOutlined } from "@material-ui/icons";
import { Box, Grid, IconButton, styled } from "@mui/material";
import React from "react";
import CustomMuiBtn from "../components/CustomMUIBtn";
import SubHeadingUnderline from "../components/SubHeadingUnderline";
import PayoutHistoryTable from "./components/PayoutHistoryTable";

export default function Financial() {
  return (
    <Container>
      <section>
        <SubHeadingUnderline text={"Add Bank Details"} />
        <BankDetailsInputs>
          <CustomInputAndLabelField label={"Account Number"} type="text" />
          <CustomInputAndLabelField label={"Bank"} type="text" />
          <CustomInputAndLabelField label={"Account Name"} type="text" />
          <CustomMuiBtn>ADD</CustomMuiBtn>
        </BankDetailsInputs>
      </section>

      <section>
        <SubHeadingUnderline text={"Bank Details"} />
        <BankDetailCard>
          <BankDeleteIcon>
            <DeleteOutlineOutlined />
          </BankDeleteIcon>

          <Grid container>
            <Grid item xs={4}>
              Account Number:
            </Grid>
            <Grid item xs={8}>
              <b>0045678909</b>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={4}>
              Bank:
            </Grid>
            <Grid item xs={8}>
              <b>Access Bank</b>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={4}>
              Account Name:
            </Grid>
            <Grid item xs={8}>
              <b>Jaachimma Onyenze</b>
            </Grid>
          </Grid>
        </BankDetailCard>
      </section>

      <section>
        <SubHeadingUnderline text={"Payout History"} />
        <Box marginTop={3}>
          <PayoutHistoryTable />
        </Box>
      </section>
    </Container>
  );
}

const Container = styled("div")`
  max-width: 650px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const BankDetailsInputs = styled("div")`
  max-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-top: 30px;
`;

const BankDetailCard = styled("div")`
  padding: 15px 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  font-size: 14px;
  border-radius: 12px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
`;

const BankDeleteIcon = styled(IconButton)`
  text-align: right;
  cursor: pointer;
  padding: 5px;

  @media (min-width: 400px) {
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 15px;
    margin-top: 10px;
  }
`;
