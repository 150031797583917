import { Box, styled } from "@mui/material";

const FlexColumnMediaQuery = styled(Box)`
  display: flex;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export default FlexColumnMediaQuery;
