import { makeStyles, withStyles } from "@material-ui/core/styles";
import { LinearProgress, Typography } from "@material-ui/core";

export const TypographyText = withStyles((theme) => ({
    root: {
        color: "#043B5C",
        fontFamily: "inherit"
    },
    h2: {
        fontSize: 16,
        color: "#043B5C",
        [theme.breakpoints.down("xs")]: {
            fontSize: 15
        }
    },
    h3: {
        fontWeight: 800,
        fontSize: 30,
        fontFamily: "Open Sans-bold"
    },
    h4: {
        fontWeight: "bolder",
        fontSize: 23,
        fontFamily: "Open Sans-bold"
    },
    h5: {
        fontWeight: 600,
        fontSize: 17,
        color: "#4786FF",
        [theme.breakpoints.down("sm")]: {
            fontSize: 15
        }
    },
    h6: {
        fontWeight: 700,
        fontSize: 15
    },
    body2: {
        fontWeight: 600,
        fontSize: "1.2rem",
        fontFamily: "Open Sans-Semibold, sans-serif",
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            fontSize: 16
        }
    },
    subtitle1: {
        fontWeight: 900,
        fontSize: 16,
        [theme.breakpoints.down("xs")]: {
            fontSize: 15
        }
    },
    subtitle2: {
        fontWeight: 500,
        fontSize: 11
    },
    body1: {
        fontSize: 13,
        [theme.breakpoints.down("xs")]: {
            fontSize: 12
        }
    },
    caption: {
        fontSize: 15,
        fontWeight: 500,
        color: "#4786FF",
        [theme.breakpoints.down("xs")]: {
            fontSize: 13
        }
    },
    h1: {
        fontSize: 12,
        fontWeight: 900
    }
}))(Typography);

export const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        width: "50%",
        [theme.breakpoints.down("xs")]: {
            width: "100%"
        }
        // borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: "#45B29D"
    },
    bar: {
        // borderRadius: 5,
        backgroundColor: "#E8F0F9"
    }
}))(LinearProgress);

export const Styles = makeStyles((theme) => ({
    root: {
        marginLeft: "20px",
        // marginRight: "30px",
        [theme.breakpoints.down("sm")]: {
            marginLeft: "0px"
        },

        "& .contain": {
            background: " #FFFFFF 0% 0% no-repeat padding-box",
            boxShadow: "0px 20px 30px #EFF5FF",
            borderRadius: "15px 15px 0px 0px",
            maxHeight: "100%",
            height: "100%",
            [theme.breakpoints.down("xs")]: {
                backgroundColor: "#F4F5F7"
            }
        }
    },
    containerHead: {
        [theme.breakpoints.up("md")]: {
            // padding: '0 2% 4%',
        },
        "& .sub-head": {
            margin: "0 0 30px 0px",
            alignItems: "center",
            [theme.breakpoints.down("xs")]: {
                margin: "0px",
                alignItems: "center"
            }
        }
    },

    settingsBox: {
        background: "#EEF4FB 0% 0% no-repeat padding-box",
        height: "100%",
        [theme.breakpoints.down("sm")]: {
            height: "auto",
            whiteSpace: "nowrap",
            // overflowX: 'scroll',
            background: "#DEEDFF 0% 0% no-repeat padding-box",
            borderRadius: "6px 6px 0 0"
        },

        "& .settings": {
            display: "block",
            flexDirection: "column",
            justifyContent: "space-between",
            maxHeight: "30rem",
            // border: 'solid',
            borderBottom: "solid 1px #6EB5DF77",
            // overflowY: 'scroll',
            [theme.breakpoints.down("sm")]: {
                flexDirection: "inherit",
                justifyContent: "start",
                height: "auto",
                display: "flex"
                // overflowX: 'scroll',
            },

            // '&__head': {
            //   padding: '30px 20px 7px 20px',
            //   color: '#043B5C',
            //   fontSize: '0.9em',
            //   fontWeight: '600',
            //   fontFamily: 'Open Sans-light, sans-serif',
            // },

            "&__data": {
                padding: "28px 40px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
                cursor: "pointer",
                [theme.breakpoints.down("xs")]: {
                    //   padding: 0,
                    padding: "15px 25px",
                    borderRadius: "6px 7px 0 0",
                    fontSize: 12,
                    justifyContent: "inherit"
                },

                "&.active": {
                    background: "white",
                    fontSize: 15,
                    fontWeight: "bold",
                    [theme.breakpoints.up("sm")]: {
                        // borderBottom: 'solid 1px #6EB5DF77',
                        // borderRight: "solid 2px #EEF4FB",
                        // borderLeft: "solid 10px #EEF4FB",
                        cursor: "pointer"
                    },
                    [theme.breakpoints.down("xs")]: {
                        background: "#043B5C",
                        "& p": {
                            color: "#F4F5F7"
                        }
                    }
                },

                "& p": {
                    margin: "0",
                    color: "#043B5C",
                    fontSize: "1em",
                    fontWeight: "600",
                    cursor: "pointer",
                    fontSize: 15,
                    fontFamily: "Open Sans-Semibold, sans-serif",

                    [theme.breakpoints.down("md")]: {
                        fontSize: "0.9em"
                    },
                    [theme.breakpoints.down("xs")]: {
                        fontSize: "0.9em"
                    }
                }
            }
        }
    },

    settingDisplay: {
        padding: "50px",
        paddingTop: "1rem",
        [theme.breakpoints.down("sm")]: {
            padding: 0
        },

        "& .setting__display": {}
    },
    settingsHead: {
        backgroundColor: "#eef4fb",
        padding: "3rem 0",
        alignItems: "center",
        [theme.breakpoints.only("xs")]: {
            display: "block",
            background: "#F4F5F7 0% 0% no-repeat padding-box"
        },
        "& .column1": {
            display: "flex",
            justifyContent: "center"
        },
        "& .img": {
            //   border: 'solid',
            // width: "90%",
            height: "11rem",
            backgroundColor: "#6EB5DF72",
            borderRadius: "10px 10px 0 0",
            overflow: "hidden",
            // display: "flex",
            // flexDirection: "column",
            // alignItems: 'center',
            // justifyContent: 'center',
            [theme.breakpoints.down("sm")]: {
                width: "100%"
            },
            "& .arrowImg": {
                position: "relative",
                bottom: "35%",
                background: "transparent",
                padding: "0 3%",
                display: "flex",
                justifyContent: "space-between"
                // '& :hover':{
                //     display:'none',
                //     backgroundColor:'green'
                // }
            },
            "& .imgDiv": {
                width: "100%",
                objectFit: "cover"
            }
        },
        "& .image-text": {
            backgroundColor: "#F4F5F7",
            textAlign: "center",
            fontSize: 14,
            padding: "10px",
            position: "relative",
            fontFamily: "Open Sans, sans-serif",
            color: "#043B5C"
        },

        "& .carousel-body": {
            width: "90%",
            [theme.breakpoints.down("md")]: {
                width: "100%"
            }
        }
    },
    editBtn: {
        backgroundColor: "#4786FF !important",
        color: "white",
        border: "none",
        // fontSize: 12,
        padding: "0.4rem 1rem",
        fontSize: ".7rem",
        [theme.breakpoints.down("sm")]: {
            fontSize: 10
        },
        [theme.breakpoints.only("xs")]: {
            width: "100%"
        }
    },
    btnEditApartment: {
        backgroundColor: "#4786FF !important",
        color: "white",
        border: "none",
        padding: "0.4rem 1rem",
        fontSize: ".7rem",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            fontSize: 9,
            borderRadius: 0,
            padding: "1% 0"
        }
    },
    btnGrid: {
        [theme.breakpoints.only("xs")]: {
            width: "100%"
        }
    },
    amenities: {
        backgroundColor: "#F3F3F3",
        border: "solid 1px #F3F3F3",
        borderRadius: 6,
        color: "#043B5C",
        fontSize: 14,
        fontWeight: "bold",
        padding: "10px 20px",
        marginRight: 20,
        marginBottom: 15,
        [theme.breakpoints.down("xs")]: {}
    },
    underLine: {
        display: "block",
        borderBottom: "solid 1px #043b5c",
        width: "5.8rem",
        position: "relative",
        top: "0.5rem"
    },
    // tenantImg: {
    //   width: "70px",
    //   background: "brown",
    //   height: "70px",
    //   borderRadius: "50%",
    // },
    address: {
        // width: "80%",
        // textAlign: "center",
        [theme.breakpoints.down("xs")]: {
            width: "100%"
        }
    },
    xsContainer: {
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        borderRadius: "10px",
        opacity: 1,
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            padding: "25px 20px"
        },

        [theme.breakpoints.down("xs")]: {
            padding: "25px 20px"
        },
        "& .sub-1": {
            display: "block",
            backgroundColor: "white",
            borderRadius: "0 0 10px 10px",

            "& .sub-2": {
                marginBottom: 40,

                [theme.breakpoints.down("xs")]: {
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr"
                },

                "& .MuiTypography-subtitle1": {
                    fontSize: 16,
                    marginBottom: 10,

                    [theme.breakpoints.down("xs")]: {
                        fontSize: 14
                    }
                },
                "& .MuiTypography-h5": {
                    fontSize: 14,

                    [theme.breakpoints.down("xs")]: {
                        fontSize: 13
                    }
                }
            },

            "& .description-wrapper": {
                marginBottom: 40,

                "& .MuiTypography-subtitle2": {
                    fontSize: 14,

                    [theme.breakpoints.down("xs")]: {
                        fontSize: 13
                    }
                }
            }
        },
        "& .date": {
            justifyContent: "start",
            [theme.breakpoints.down("xs")]: {
                justifyContent: "space-between"
            }
        },
        "& .days": {
            width: "18%",
            [theme.breakpoints.down("sm")]: {
                width: "100%"
            }
        },
        "& .renew": {
            display: "flex",
            justifyContent: "start",
            columnGap: "8rem",
            [theme.breakpoints.down("xs")]: {}
        }
    },
    lease: {
        marginTop: 40,

        [theme.breakpoints.down("xs")]: {
            backgroundColor: "#4786ff",
            borderRadius: "10px 10px 0 0",
            marginTop: "3rem"
        }
    },
    leaseBody: {
        marginBottom: 40,
        [theme.breakpoints.down("xs")]: {
            padding: 0
        },

        "& .lease-header": {
            "& .MuiTypography-root": {
                fontSize: 18,
                fontWeight: 800
            }
        }
    },
    curLease: {
        [theme.breakpoints.down("xs")]: {
            color: "white",
            fontSize: 15,
            padding: "10px 0px 10px 20px"
        }
    },
    settingsArrow: {
        fontSize: 11,
        color: "#043b5c"
    },
    xsContainer2: {
        display: "flex",
        flexFlow: "row",
        alignItems: "baseline",
        columnGap: "4rem",
        [theme.breakpoints.down("xs")]: {
            display: "flex",
            flexFlow: "column",
            alignItems: "baseline"
        }
    },

    tenantMargin: {
        color: "#043B5C",
        marginBottom: 10,

        [theme.breakpoints.down("xs")]: {
            backgroundColor: "#4786FF",
            width: "100%",
            color: "#fff",
            fontSize: 15,
            padding: "10px 0px 10px 20px",
            marginBottom: 0
        }
    },
    charges: {
        display: "block",
        marginBottom: 25,

        [theme.breakpoints.down("xs")]: {
            display: "flex"
        },
        "& .charge-card": {
            [theme.breakpoints.up("sm")]: {
                display: "flex",
                gridGap: "1rem",
                alignItems: "baseline"
            },
            "& .charge-title": {
                fontFamily: "Open Sans-bold",
                [theme.breakpoints.down("xs")]: {
                    fontSize: 12,
                    fontFamily: "Open Sans-bold"
                }
            }
        }
    },
    amenitiesAsst: {
        [theme.breakpoints.down("xs")]: {
            padding: "15px",
            textAlign: "center",
            width: "100%"
        }
    },
    mobileAddr: {
        [theme.breakpoints.down("md")]: {
            width: "100%"
        },
        "& .property-details-section": {
            width: "60%",
            marginLeft: "50px",

            [theme.breakpoints.down("md")]: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                columnGap: "10%",
                backgroundColor: "white",
                padding: "5% 3%",
                borderRadius: " 0 0 10px 10px",
                width: "100%",
                textAlign: "center",
                borderRadius: "10px",
                marginLeft: "0px"
            },

            [theme.breakpoints.down("sm")]: {
                width: "100%"
            }
        },
        "& .property-details": {
            // width: "0%",
            color: "#043B5C",
            fontFamily: "Open Sans-bold, sans-serif",
            // fontWeight: "bold",

            "& .sub-text-header": {
                color: "#043B5C",
                fontWeight: "bold",
                margin: 0,
                fontSize: "18px",
                [theme.breakpoints.down("md")]: {
                    fontWeight: "600",
                    textAlign: "left"
                }
            },
            "& .sub-text": {
                color: "#043B5C",
                fontWeight: "500",
                margin: 0,
                fontSize: "18px",
                [theme.breakpoints.down("md")]: {
                    textAlign: "left",
                    fontWeight: "400"
                },
                [theme.breakpoints.down("sm")]: {
                    fontSize: "13px"
                }
            }
        }
    },
    addProp: {
        width: "auto",
        marginRight: "7%",
        [theme.breakpoints.down("xs")]: {
            marginRight: 0
        }
    },
    summary: {
        marginTop: "1%",
        justifyContent: "space-between",
        padding: "0 4%",
        textAlign: "center",
        width: "80%",
        [theme.breakpoints.down("xs")]: {
            padding: "11% 7%",
            backgroundColor: "white",
            paddingBottom: "15%",
            borderRadius: "0 0 10px 10px",
            width: "100%",
            columnGap: "74px"
        },
        "& .summary-list": {
            "& h3": {
                fontSize: 40
            },
            " & p": {
                fontWeight: 400
            },
            [theme.breakpoints.down("xs")]: {
                marginBottom: "10%",
                display: "flex",
                flexDirection: "column-reverse"
            }
        }
    },
    summaryTenant: {
        marginTop: "3%",
        backgroundColor: "white",
        padding: "3% 4%",
        borderRadius: 10,
        [theme.breakpoints.down("xs")]: {
            padding: "7% 4%",
            marginTop: "6%"
        }
    },
    tenBtnMob: {
        [theme.breakpoints.down("xs")]: {
            backgroundColor: "white !important",
            color: "#043B5C",
            border: "solid 1px #6EB5DFA8"
        }
    },
    addNewAptmnt: {
        [theme.breakpoints.down("xs")]: {
            padding: 1,
            fontSize: 11,
            borderRadius: 0
        }
    },
    dateDiv: {
        marginBottom: 20,

        [theme.breakpoints.down("xs")]: {},

        "& h1.MuiTypography-root": {
            fontSize: 16,
            marginBottom: 5
        },

        "& h2.MuiTypography-root": {
            fontSize: 14
        }
    },
    sad: {
        display: "flex",
        justifyContent: "center",
        padding: "7% 0",
        backgroundColor: "white",
        [theme.breakpoints.down("xs")]: {
            padding: "10% 0"
        },
        "& .sad-under": {
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
            "& img": {
                width: "70%",
                [theme.breakpoints.down("xs")]: {
                    width: "55%",
                    padding: "16% 0"
                }
            }
        }
    },
    loader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh"
    },
    val: {
        textAlign: "center",
        width: "50%",
        [theme.breakpoints.down("xs")]: {
            width: "100%"
        }
    }
}));
