import {
  ACTIVATE_IZYINVEST_PLAN,
  ACTIVATE_IZYINVEST_PLAN_FAIL,
  ACTIVATE_IZYINVEST_PLAN_SUCCESS,
  GET_IZYPAY_BALANCE,
  GET_IZYPAY_PLAN,
  GET_IZYINVEST_BALANCE,
  GET_IZYINVEST_BALANCE_FAIL,
  GET_IZYINVEST_BALANCE_SUCCESS,
  GET_IZYINVEST_HISTORY,
  GET_IZYINVEST_HISTORY_FAIL,
  GET_IZYINVEST_HISTORY_SUCCESS,
  FUND_IZYVEST_WALLET_SUCCESS,
  FUND_IZYVEST_WALLET_FAIL,
  FUND_IZYVEST_WALLET,
  GET_IZYINVEST_PLANS_LIST,
  GET_IZYINVEST_PLANS_LIST_SUCCESS,
  GET_IZYINVEST_PLANS_LIST_FAIL
} from "../constants";
import axios from "axios";
import { showNotifications } from "./notification.actions";
import { getIzyPayBalance } from "./izypay.actions";
// const token = localStorage.getItem('token');
const config = {
  baseURL: process.env.REACT_APP_WALLETSERVICE,
  headers: {
    // Authorization: `Bearer ${token}`,
    // 'X-Requested-With': 'XMLHttpRequest',
    accept: "application/json",
    "Content-Type": "application/json",
    "x-env-source": "web"
  }
};

// const doAppendToken = () => {
//   config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
// };

export const activateIzyInvest = (data, cb, cb2) => async (dispatch) => {
  dispatch({ type: ACTIVATE_IZYINVEST_PLAN });

  await axios
    .post("/user/invest/activate", data, config)
    .then((res) => {
      dispatch({
        type: ACTIVATE_IZYINVEST_PLAN_SUCCESS,
        newPlanId: res.data.data.id
      });

      localStorage.setItem("activeplan", true);
      dispatch(getIzyPlan(res.data.data.id, res.data.data.plan.min_recur_deposit));
      dispatch(showNotifications(`${data.plan} Plan created successfully`, "success"));

      cb();
    })
    .catch((error) => {
      const defaultMsg = "Error activating plan. Try again later";
      dispatch({ type: ACTIVATE_IZYINVEST_PLAN_FAIL });
      dispatch(showNotifications(error.response?.data?.message || defaultMsg, "error"));
      cb2(error);
    });
};

export const getIzyPlan = (activate, miniumDeposit) => async (dispatch) => {
  try {
    await axios.get("/user/invest/plans", config).then((res) => {
      if (res.status === 204) return;
      if (res.data.data.length > 0) {
        localStorage.setItem("activeplan", true);
        if (activate) {
          dispatch({
            type: GET_IZYPAY_PLAN,
            izyInvestPlan: res.data.data,
            newPlanId: activate,
            minimumDep: miniumDeposit
          });
          // dispatch(getIzyInvestBalance(activate));
        } else {
          dispatch({
            type: GET_IZYPAY_PLAN,
            izyInvestPlan: res.data.data,
            newPlanId: res.data.data[0].uuid,
            minimumDep: res.data.data[0].min_recur_deposit
          });
          // dispatch(getIzyInvestBalance(res.data.data[0].uuid));
        }
      }
    });
  } catch (error) {
    let data = window.localStorage.getItem("data");
    if (data === null || data === undefined) {
      return;
    } else return;
  }
};

const planId = "GOLD";

export const getIzyInvestBalance = (planId) => async (dispatch) => {
  // doAppendToken()
  dispatch({ type: GET_IZYINVEST_BALANCE });

  try {
    await axios.get(`/user/invest/${planId}`, config).then((res) => {
      if (res.data.status) {
        dispatch(getIzyInvestHistory(res.data.data.id));
        dispatch({
          type: GET_IZYINVEST_BALANCE_SUCCESS,
          payload: res.data.data,
          availableBalance: res.data.data.available_balance,
          contributoryBalance: res.data.data.contributory_balance,
          closingBalance: res.data.data.closing_balance,
          expectedClosingBalance: res.data.data.expectedClosingBalance,
          monthlyInterest: res.data.data.monthly_interest,
          minimumFirstDeposit: res.data.data.plan.min_init_deposit,
          minimumDep: res.data.data.plan.min_recur_deposit,
          payoutDate: res.data.data.end_date,
          bank: res.data.data.bank,
          accountNumber: res.data.data.account_number
        });
      }
    });
  } catch (error) {
    dispatch({ type: GET_IZYINVEST_BALANCE_FAIL });
    dispatch(showNotifications(error, "error"));
  }
};

export const getIzyInvestHistory = (planId) => async (dispatch) => {
  // doAppendToken()
  dispatch({ type: GET_IZYINVEST_HISTORY });

  try {
    const res = await axios.get(`/user/invest/${planId}/history`, config);
    console.log("history", res.data);
    if (res.data.status) {
      dispatch({
        type: GET_IZYINVEST_HISTORY_SUCCESS,
        payload: res.data.data,
        dailyInterest: res.data.data.dailyInterest,
        previousBalance: res.data.data.previousBalance,
        newBalance: res.data.data.newBalance
      });
    }
  } catch (error) {
    console.log({ error }, "hist");
    dispatch({ type: GET_IZYINVEST_HISTORY_FAIL });
    dispatch(showNotifications(error, "error"));
  }
};

export const selectIzyInvestHistory = (store) => store.izyinvest.plansList;

export const fundIzyvest = (data, cb) => async (dispatch) => {
  const { planId, amount } = data;
  dispatch({ type: FUND_IZYVEST_WALLET });
  try {
    await axios.post(`/user/invest/${planId}/deposit`, { amount }, config).then((res) => {
      if (res.data.status) {
        // dispatch(getIzyPlan(""));
        dispatch(getIzyPayBalance());
        dispatch({ type: FUND_IZYVEST_WALLET_SUCCESS });
        dispatch(showNotifications("Funds deposited", "success"));
        cb();
      }
    });
  } catch (error) {
    dispatch({ type: FUND_IZYVEST_WALLET_FAIL, fundIzyvest: false });
    dispatch(showNotifications(error.response?.data?.message, "error"));
  }
};

// update izyinvest plan
export const updateIzyvestPlan = (data, cb) => async (dispatch) => {
  const { planId, bankId, account_number, account_name } = data;
  dispatch({ type: FUND_IZYVEST_WALLET });
  try {
    await axios
      .put(
        `/user/invest/user/${planId}`,
        { bankId, account_number, account_name },
        config
      )
      .then((res) => {
        if (res.data.status) {
          // dispatch(getIzyPlan(""));
          dispatch(getIzyPayBalance());
          dispatch({ type: FUND_IZYVEST_WALLET_SUCCESS });
          dispatch(showNotifications("User plan updated successfully", "success"));
          cb();
        }
      });
  } catch (error) {
    dispatch({ type: FUND_IZYVEST_WALLET_FAIL, fundIzyvest: false });
    dispatch(showNotifications(error.response?.data?.message, "error"));
  }
};

export const selectIzyInvestPlans = (store) => store.izyinvest.izyInvestPlan;
export const selectHistoryPlanId = (store) => store.izyinvest.planId;

export const RENT_SAVINGS = "RENT SAVINGS";
export const selectRentSavingsPlan = (store) => {
  let rentSavings = null;
  store.izyinvest.izyInvestPlan.forEach((plan) => {
    if (plan?.plan?.plan === RENT_SAVINGS) {
      rentSavings = plan;
    }
  });

  return rentSavings;
};

export const getIzyInvestPlansList = () => async (dispatch) => {
  // doAppendToken()
  dispatch({ type: GET_IZYINVEST_PLANS_LIST });

  try {
    await axios.get(`/user/invest/plans/list`, config).then((res) => {
      dispatch({
        type: GET_IZYINVEST_PLANS_LIST_SUCCESS,
        payload: res.data.data
      });
    });
  } catch (error) {
    dispatch({ type: GET_IZYINVEST_PLANS_LIST_FAIL });
    dispatch(showNotifications(error || "Error fetching izyInvest Plans.", "error"));
  }
};
