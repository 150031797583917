import { nigerianPhoneNumberPattern } from "#/components/utils/util";
import * as Yup from "yup";

import formModel from "./formModel";
const {
  formField: {
    profileImage,
    lastName,
    firstName,
    otherName,
    gender,
    dateOfBirth,
    stateOfOrigin,
    lga,
    email,
    alternateEmail,
    mobileNumber,
    alternateMobileNumber
  }
} = formModel;

const TYPE_NUMBER_ERROR_MSG = "Must be a Number.";
const TYPE_DATE_ERROR_MSG = "Must be a date type.";

const mobileNumberLengthCheck = (value) => {
  return !isNaN(value) && `${value}`.length >= 10;
};

const fileTypeCheck = (value, typesArray) => {
  if (typeof value === "string") {
    return /^https?:\/\/.+\.(jpg|jpeg|png)$/.test(value);
  }
  return !value || (value && typesArray.includes(value?.type));
};

export default Yup.object().shape({
  [profileImage.name]: Yup.mixed()
    .nullable()
    // .required(`${profileImage.requiredErrorMsg}`)
    .test("File_type", `${profileImage.invalidFiletypeErrorMsg}`, (value) =>
      fileTypeCheck(value, profileImage.acceptedFileTypes)
    ),
  [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
  [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
  [otherName.name]: Yup.string(),
  [gender.name]: Yup.string(),
  // .required(`${gender.requiredErrorMsg}`),
  [dateOfBirth.name]: Yup.date()
    .typeError(TYPE_DATE_ERROR_MSG)
    .max(new Date(), dateOfBirth.maxDateErrorMsg),
  // .required(`${dateOfBirth.requiredErrorMsg}`),
  [stateOfOrigin.name]: Yup.string(),
  // .required(`${stateOfOrigin.requiredErrorMsg}`),
  [lga.name]: Yup.string(),
  // .required(`${lga.requiredErrorMsg}`),
  [email.name]: Yup.string()
    .email(`${email.invalidErrorMsg}`)
    .required(`${email.requiredErrorMsg}`),
  [alternateEmail.name]: Yup.string().email(`${alternateEmail.invalidErrorMsg}`),
  [mobileNumber.name]: Yup.string()
    .required(`${mobileNumber.requiredErrorMsg}`)
    .matches(nigerianPhoneNumberPattern, `${mobileNumber.invalidLengthErrorMsg}`),
  [alternateMobileNumber.name]: Yup.string().matches(
    nigerianPhoneNumberPattern,
    `${alternateMobileNumber.invalidLengthErrorMsg}`
  )
});
