import { CHARGES_ACTIONTYPES_CONSTANTS } from "../constants";

//------------------------------------------------------------------
//Variables and Helpers
//------------------------------------------------------------------
const {
    GET_CHARGES_IN_APARTMENT_PENDING,
    GET_CHARGES_IN_APARTMENT_SUCCESS,
    GET_CHARGES_IN_APARTMENT_FAIL,
    CREATE_CHARGES_IN_APARTMENT_PENDING,
    CREATE_CHARGES_IN_APARTMENT_SUCCESS,
    CREATE_CHARGES_IN_APARTMENT_FAIL,
    UPDATE_CHARGE_FAIL,
    UPDATE_CHARGE_PENDING,
    UPDATE_CHARGE_SUCCESS,
    DELETE_CHARGE_FAIL,
    DELETE_CHARGE_PENDING,
    DELETE_CHARGE_SUCCESS
} = CHARGES_ACTIONTYPES_CONSTANTS;

const init = {
    charges: [],
    loading: false,
    deleting: false
};

const chargesReducer = (state = init, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_CHARGES_IN_APARTMENT_PENDING:
            return {
                ...state,
                loading: true
            };
        case GET_CHARGES_IN_APARTMENT_SUCCESS:
            return {
                ...state,
                charges: payload,
                loading: false
            };

        case GET_CHARGES_IN_APARTMENT_FAIL:
            return {
                ...state,
                laoding: false
            };

        case CREATE_CHARGES_IN_APARTMENT_PENDING:
            return {
                ...state,
                loading: true
            };
        case CREATE_CHARGES_IN_APARTMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                message: payload
            };
        case CREATE_CHARGES_IN_APARTMENT_FAIL:
            return {
                ...state,
                loading: false
            };
        case UPDATE_CHARGE_PENDING:
            return {
                ...state
            };
        case UPDATE_CHARGE_SUCCESS:
            return {
                ...state
            };
        case UPDATE_CHARGE_FAIL:
            return {
                ...state
            };
        case DELETE_CHARGE_PENDING:
            return {
                ...state,
                deleting: true
            };
        case DELETE_CHARGE_SUCCESS:
            return {
                ...state,
                deleting: false
            };
        case DELETE_CHARGE_FAIL:
            return {
                ...state,
                deleting: false
            };

        default:
            return state;
    }
};

export default chargesReducer;
