import React from 'react';
import '../../App.css'; 

class Totalresults extends React.Component{
    constructor(props){
        super(props)
    }
    render() {
        return (
            //Number of total results

             <React.Fragment>
                 <p className="text-color mt-5" style={{fontSize:"22px", fontWeight:"600"}}>1089 Results</p>
             </React.Fragment>
        );
    }
}

export default Totalresults;