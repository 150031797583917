import ToggleSwitchWithLabel from "#/components/toggleSwitch/ToggleSwitchWithLabel";
import { styled } from "@mui/material";
import PaperContainer from "../paperContainer/Index";
import { toCalenderDate, toCurrency } from "#/components/utils/util";
import MonthlyRentInvoiceCard from "./invoice/MonthlyRentInvoiceCard";
import RentWallet from "#/components/wallets/rental/RentWallet";
import FlexCenter from "#/components/flexboxes/FlexCenter";

const MonthlyRentStatusActive = ({ statusAction, data }) => {
  const lastInvoice = data?.data[data?.data?.length - 1];
  const unpaidBills = lastInvoice?.unpaid_bills;

  const monthlyRent = unpaidBills?.find((bill) => {
    return bill?.name.startsWith("RENT ");
  });

  return (
    <Wrapper
      className={statusAction ? "xs:tw-p-10 tw-p-5" : ""}
      style={statusAction ? { boxShadow: "0px 2px 8px 0px rgba(99, 99, 99, 0.2)" } : {}}>
      {/* TOP SECTION WILL ONLY DISPLAY FOR BILLS THAT START WITH RENT WHICH IS LOAN RENT */}
      {monthlyRent && (
        <TopSection>
          <ToggleWrapper>
            <ToggleSwitchWithLabel
              color={"#37e133"}
              checked={true}
              label={
                <span
                  style={{ color: "#37e133", fontWeight: "normal", marginLeft: "-18px" }}>
                  Monthly Rent Active
                </span>
              }
            />
          </ToggleWrapper>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.7rem"
            }}>
            <DateCard>
              <small>Next Payment Date</small>
              <small>{toCalenderDate(lastInvoice?.end_date)}</small>
            </DateCard>

            <DateCard>
              <small>Monthly Rent Price</small>
              <small>{toCurrency(monthlyRent?.amount)}</small>
            </DateCard>
            {/* <DateCard>
            <small>Next Payment Date</small>
            <small>{toCalenderDate(invoiceDetails.dueDate)}</small>
          </DateCard>

          <DateCard>
            <small>Monthly Rent Price</small>
            <small>{toCurrency(invoiceDetails.amountDue)}</small>
          </DateCard> */}
          </div>
        </TopSection>
      )}
      <PaymentSection>
        <FlexCenter style={{ flex: "1" }}>
          {/* commented this to fix the issue on the dashboard */}
          {lastInvoice?.payment_status === "processing" && data?.data?.length > 0 ? (
            <MonthlyRentInvoiceCard invoice={lastInvoice} />
          ) : null}
        </FlexCenter>

        <FlexCenter style={{ flex: "1", width: "100%" }}>
          <RentWallet
            reference={lastInvoice?.invoice_ref}
            totalPayable={lastInvoice?.total_amount}
            fullData={lastInvoice}
          />
        </FlexCenter>
      </PaymentSection>
    </Wrapper>
  );
};

export default MonthlyRentStatusActive;

const Wrapper = styled("div")`
  width: 100%;
  display: grid;
  gap: 0.95rem;
`;

const TopSection = styled("section")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.125rem;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const DateCard = styled(PaperContainer)`
  color: rgba(5, 33, 55, 0.8);
  display: grid;
  flex: 1;
  width: 17ch;
  padding: 1.2rem 0.9rem;
  small:last-of-type {
    font-weight: bold;
  }
`;

const ToggleWrapper = styled(PaperContainer)`
  padding: 1.5rem 2rem;

  width: 300px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const PaymentSection = styled("div")`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.65rem;

  @media (max-width: 750px) {
    gap: 2rem;
    flex-direction: column;
  }
`;
