import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CakeOutlinedIcon from "@material-ui/icons/CakeOutlined";
import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined";
import TrendingUpOutlinedIcon from "@material-ui/icons/TrendingUpOutlined";
import ContactMailOutlinedIcon from "@material-ui/icons/ContactMailOutlined";
import Grid from "@material-ui/core/Grid";
import avatar from "#/assets/images/tenant/default-avatar.png";
import { format } from "date-fns";
import { styles } from "./ApplicationInformation.styles";
import {
  approveOrRejectBooking,
  getApartmentsBooked
} from "#/store/actions/booking.actions";
import { authServiceApi } from "#/apis/authServiceApi";

const ApplicantInformation = (props) => {
  const { applicantBookingInfo, apartmentDetails } = props;

  const [userDetail, setUserDetail] = useState(null);

  useEffect(() => fetchApplicantDetail(), []);

  const handleApproveRejectBooking = async (status) => {
    const body = {
      status
    };
    await approveOrRejectBooking(applicantBookingInfo._id, body);
    props.dispatch(getApartmentsBooked(props.liveizyId));
    props.history.push("/dashboard");
  };

  const fetchApplicantDetail = () => {
    authServiceApi()
      .get("/user/get/details/" + applicantBookingInfo.user_id)
      .then((data) => {
        setUserDetail(data.data.data);
      })
      .catch((err) => {});
  };

  if (!apartmentDetails || !userDetail) {
    return <div>No tenant selected yet</div>;
  }
  const {
    apartment_id,
    title,
    house_number,
    street,
    state,
    lga,
    lcda
  } = apartmentDetails;

  const { date_of_birth, phone } = userDetail.profile;

  return (
    <div className={props.classes.root}>
      <header className={props.classes.header}>
        <Typography variant="h5" component="h1">
          <b>Application</b>
        </Typography>
      </header>
      <div className={props.classes.detailsContainer}>
        <div className="img-container">
          <img src={applicantBookingInfo.interest?.photo || avatar} alt="tenants" />
        </div>
        <div className="contact-details-container">
          <Typography variant="h5" component="h4">
            <b>{title}</b>
          </Typography>
          <Typography className="applied-details" variant="body1" component="p">
            {house_number} {street} {state} {lga} {lcda}
          </Typography>
          <Typography className="applied-details" variant="body1" component="p">
            Applied To: <span style={{ color: "#E41A1A" }}>NO.{apartment_id}</span>
          </Typography>
          <Typography className="applied-details" variant="body1" component="p">
            Applied on:{" "}
            <span style={{ fontWeight: 300 }}>
              {format(new Date(`${applicantBookingInfo.createdAt}`), "dd MMMM yyyy")}
            </span>
          </Typography>
          <Typography className="applied-details" variant="body1" component="p">
            Inspection day:{" "}
            <span style={{ fontWeight: 300 }}>{applicantBookingInfo.day}</span>
          </Typography>
          <Typography className="applied-details" variant="body1" component="p">
            Time: <span style={{ fontWeight: 300 }}>{applicantBookingInfo.time}</span>
          </Typography>
        </div>
        {["interested", "declined", "approved"].includes(applicantBookingInfo.status) && (
          <div
            className="links-container link-wrapper"
            style={{ display: "flex", alignItems: "center" }}>
            {["interested", "declined"].includes(applicantBookingInfo.status) && (
              <div
                className="btn btn-approve"
                onClick={() => handleApproveRejectBooking("approved")}>
                Approve
              </div>
            )}
            {["interested", "approved"].includes(applicantBookingInfo.status) && (
              <div
                className="btn btn-reject"
                onClick={() => handleApproveRejectBooking("declined")}>
                Reject
              </div>
            )}
          </div>
        )}

        <div className="age-wrapper">
          <Typography className="icon-text" variant="body1" component="p">
            <span>
              <CakeOutlinedIcon />{" "}
            </span>
            Date of Birth
          </Typography>
          <span className="applicant-detail">
            {applicantBookingInfo.interest?.date_of_birth?.split("T")[0]}
          </span>
          <span className="applicant-detail detail-age"></span>
        </div>
        <div className="employment-wrapper">
          <Typography className="icon-text" variant="body1" component="p">
            <span>
              <AssignmentIndOutlinedIcon />{" "}
            </span>
            Employment
          </Typography>
          <span className="applicant-detail">-</span>
        </div>
        <div className="income-wrapper">
          <Typography className="icon-text" variant="body1" component="p">
            <span>
              <TrendingUpOutlinedIcon />{" "}
            </span>
            Monthly Income (&#x20A6;)
          </Typography>
          <span className="applicant-detail">
            {applicantBookingInfo.interest?.monthly_income}
          </span>
        </div>
        <div className="email-wrapper">
          <Typography className="icon-text" variant="body1" component="p">
            <span>
              <ContactMailOutlinedIcon />{" "}
            </span>
            <span>Contact</span>
          </Typography>
          <span className="applicant-detail">{phone}</span>
          <span className="applicant-detail-email">
            {applicantBookingInfo.interest?.email}
          </span>
        </div>
      </div>
      {applicantBookingInfo.status !== "pending" && (
        <>
          <div className={props.classes.report}>
            <div className="background-report">
              <Typography variant="body1" className="report-text" component="p">
                <b>Background Report</b>
              </Typography>
            </div>
            <hr />
            <Grid container>
              <Grid className="report-wrapper" item xs={12} sm={6} md={4}>
                <div>
                  <span className="background-title">State of Origin:</span>
                  <span className="background-details">
                    {applicantBookingInfo.interest?.state_of_origin}
                  </span>
                </div>
              </Grid>
              <Grid className="report-wrapper" item xs={12} sm={6} md={4}>
                <div>
                  <span className="background-title">Date of Birth:</span>
                  <span className="background-details">
                    {applicantBookingInfo.interest?.date_of_birth?.split("T")[0]}
                  </span>
                </div>
              </Grid>
              <Grid className="report-wrapper" item xs={12} sm={6} md={4}>
                <div>
                  <span className="background-title">Employment status</span>
                  <span className="background-details">
                    {applicantBookingInfo.interest?.employment_status}
                  </span>
                </div>
              </Grid>
              <Grid className="report-wrapper" item xs={12} sm={6} md={4}>
                <div>
                  <span className="background-title">Employer Name:</span>
                  <span className="background-details">
                    {applicantBookingInfo.interest?.employer_name}
                  </span>
                </div>
              </Grid>
              <Grid className="report-wrapper" item xs={12} sm={6} md={4}>
                <div>
                  <span className="background-title">Office Address:</span>
                  <span className="background-details">
                    {applicantBookingInfo.interest?.office_address}
                  </span>
                </div>
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={2} className={props.classes.grid}>
            <Grid item xs={12} sm={6} md={6}>
              <div className="history-container">
                <Typography variant="h6" component="h6" className="text-container">
                  <b>Rental Record</b>
                </Typography>
                <hr />
                <div className="report-wrapper">
                  <span className="background-title">
                    Name of previous landlord/Manager:
                  </span>
                  <span className="background-details">
                    {applicantBookingInfo.interest?.name_of_landlord ||
                      applicantBookingInfo.interest?.name_of_pm}
                  </span>
                </div>
                <div className="report-wrapper">
                  <span className="background-title">Landlord/Manager Contact:</span>
                  <span className="background-details">
                    {applicantBookingInfo.interest?.phone_of_landlord ||
                      applicantBookingInfo.interest?.phone_of_pm}
                  </span>
                </div>
                <div className="report-wrapper">
                  <span className="background-title">Address:</span>
                  <span className="background-details">
                    {applicantBookingInfo.interest?.current_address}
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className="history-container">
                <Typography variant="h6" component="h6" className="text-container">
                  <b>Reference/Emergency</b>
                </Typography>
                <hr />
                <div className="report-wrapper">
                  <span className="background-title">Name:</span>
                  <span className="background-details">
                    {applicantBookingInfo.interest?.contact_person_name}
                  </span>
                </div>
                <div className="report-wrapper">
                  <span className="background-title">Mobile Number:</span>
                  <span className="background-details">
                    {applicantBookingInfo.interest?.contact_person_number}
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className="history-container">
                <Typography className="text-container" variant="h6" component="h6">
                  <b>Residence Score</b>
                </Typography>
                <hr />
                <div className="report-wrapper">
                  <span className="background-title">Not available</span>
                </div>
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default withStyles(styles)(ApplicantInformation);
