import clsx from "clsx";
import React, { useEffect, useState } from "react";

import { useStyles, TypographyText, radioStyles } from "../RightCol/rightstyles";
import {
  Grid,
  Paper,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Button
} from "@material-ui/core";
import IzyInvestPopup from "../../Wallet/fund-wallet/izyInvestPopup";
import CustomButton from "../../../components/ui-components/button.component";
import rightArrowIcon from "../../../assets/other images/right-arrow.svg";
import Modal from "#/shared/components/Modal/Modal";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { _formatCurrency } from "#/lib/validation";

function StyledRadio(props) {
  const classesR = radioStyles();

  return (
    <Radio
      className={classesR.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classesR.icon, classesR.checkedIcon)} />}
      icon={<span className={classesR.icon} />}
      {...props}
    />
  );
}

const Plan = (props) => {
  const {
    plan,
    key,
    onClick,
    isActive,
    clicked,
    days,
    openModal,
    open,
    closeModal,
    index
  } = props;
  const classes = useStyles();
  // const [value, setValue] = useState("Yes");
  const [selectedPlan, setSelectedPlan] = useState(plan);
  // const [open, setOpen] = useState(true);
  const [activePlan, setActivePlan] = useState(clicked);
  const [display, setDisplay] = useState(false);
  const [bankAccountName, setBankAccountName] = useState();
  const [error, setError] = useState("");

  let { loading, nubanDetails } = useSelector((store) => store.izypay);

  const { register, watch, handleSubmit, setValue, errors, control, form } = useForm({});
  const values = watch();

  const history = useHistory();

  useEffect(() => {
    // hide bank Name if account number is edited
    if (
      display &&
      values.accountNumber !== undefined &&
      values.accountNumber.length !== 10
    ) {
      setDisplay(false);
      setValue("bank", "select bank");
    }

    // validate nuban if bank and accountNumber is filled
    if (
      values.accountNumber !== undefined &&
      values.accountNumber.length === 10 &&
      values.bank !== "select bank" &&
      values.payoutDate === undefined
    ) {
      let data = {
        account_number: values.accountNumber,
        bank_code: values.bank
      };
      // dispatch(ValidateNuban(data, () => setDisplay(true)));
      //handleSubmit(ActivatePlan);
    }
  }, [values.accountNumber, values.bank]);

  useEffect(() => {
    setActivePlan(clicked);
  }, [clicked]);

  // const handleClose = () => {
  //   props.close(false);
  //   setDisplay(false);
  // };

  // const validateDate = (value) => {
  //   let date = Date.now();
  //   let requiredDays = props.days;
  //   const oneDay = 24 * 60 * 60 * 1000 * requiredDays;
  //   const selectedDay = new Date(value);
  //   const dDay = new Date(date + oneDay);
  //   if (selectedDay < dDay) {
  //     setError(`Payout Date Must be at least ${props.days} days ahead`);
  //   }
  //   if (selectedDay >= dDay || value === undefined) setError("");
  // };

  // const handleInputChange = (e) => {
  //   e.preventDefault();
  //   const { value } = e.target;
  //   setValue(value);
  // };

  // const handleChange = (event) => {
  //   setValue(event.target.value);
  // };
  // const handleClick = () => onClick(plan.plan, plan.days);

  // openModal
  const openIzyInvestModal = () => {
    openModal();
  };

  // const ActivatePlan = () => {
  //   history.push({ pathname: "/wallet", state: { openizyInvest: true } });
  //   console.log(history.push({ pathname: "/wallet", state: { openizyInvest: true } }));
  //   closeModal();
  // };
  // console.log(plan);

  return (
    <>
      <section key={key} className="tw-flex tw-flex-col tw-gap-[8px]">
        <div>
          <div className="tw-flex md:tw-flex-row tw-flex-col tw-items-center tw-justify-between tw-rounded-[19px] tw-py-[24px] tw-px-[34px] tw-bg-white tw-shadow-[0px_4px_26px_-9px_rgba(0,0,0,0.25)]">
            <div className="tw-flex tw-flex-col tw-gap-4">
              <p className={`tw-font-black tw-text-sm`} style={{}}>
                {plan.plan}
              </p>
              <div className="tw-flex tw-flex-col tw-gap-[9px]">
                <p className="tw-text-[#052137] tw-font-semibold tw-text-sm">
                  Monthly Interest {"(%)"}:{" "}
                  <span className="tw-text-[#25CC22] tw-font-bold">
                    {plan.monthly_interest}
                  </span>
                </p>
                <p className="tw-text-[#052137] tw-font-semibold tw-text-sm">
                  First Deposit: Not lower than {_formatCurrency(plan.min_init_deposit)}
                </p>
                <p className="tw-text-[#052137] tw-font-semibold tw-text-sm">
                  Recurring Deposit: Not lower than{" "}
                  {_formatCurrency(plan.min_recur_deposit)}
                </p>
                <p className="tw-text-[#052137] tw-font-semibold tw-text-sm">
                  Fixed Tenure: Not Before {plan.min_duration} days
                </p>
              </div>
            </div>
            <div className="md:tw-block tw-hidden">
              <CustomButton
                style={{
                  color: "white",
                  backgroundColor: "#10609F",
                  padding: "17px 43px"
                }}
                className={classes.chooseBtn}
                onClick={openIzyInvestModal}
                buttonText="CREATE PLAN"
              />
            </div>

            <div
              className="md:tw-hidden tw-flex tw-items-center tw-mt-8 tw-gap-2"
              onClick={openIzyInvestModal}>
              <p className="tw-text-[#10609F] tw-font-semibold tw-text-xs tw-cursor-pointer">
                CREATE PLAN
              </p>
              <img src={rightArrowIcon} alt="arrow-icon" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Plan;
