import { Grid, styled } from "@mui/material";
import React from "react";

const Container = styled("div")`
    max-width: 750px;
    width: 100%;
    margin: 0 auto;
    background: white;
    box-shadow: 0px 4.2992px 31.1692px -8.59841px rgba(0, 0, 0, 0.25);
    border-radius: 29.4316px;
    padding: 20px;
    margin-bottom: 40px;
`;

const DetailsCardContainer = ({ children, style }) => {
    return <Container sx={style}>{children}</Container>;
};

export const Details = styled("div")`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    /* gap: 13px; */
`;

const Wrapper = styled("div")`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const DetailsContainer = ({ children, style }) => {
    return (
        <Grid
            width={"auto"}
            sx={{ margin: "0 auto" }}
            container
            spacing={1}
            justifyContent={"flex-start"}
            marginTop={2}>
            {children}
        </Grid>
    );
};

export default DetailsCardContainer;
