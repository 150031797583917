import { makeStyles, withStyles } from "@material-ui/core/styles";

export const useOptionsDropdownStyles = makeStyles((theme) => ({
    optionsDropdownWrapper: {
        display: "none",

        position: "relative",
        "& button": {
            backgroundColor: "transparent",
            border: "0px",
            color: "#ff0000",
            cursor: "pointer",

            "@media(max-width:768px)": {
                display: "none"
            }
        },

        "& .three-dots": {
            display: "flex",
            flexDirection: "column",

            "@media(min-width:768px)": {
                display: "none"
            },

            "& span": {
                width: 4,
                height: 4,
                borderRadius: "50%",
                backgroundColor: "#06223968",
                marginBottom: 2
            }
        },

        "& .menu-wrapper": {
            backgroundColor: "#fff",
            position: "relative",
            borderRadius: "5px",
            marginTop: "10px",

            "@media(min-width:768px)": {
                transform: "translateX(-50%) !important"
            },

            "&:before": {
                content: "''",
                position: "absolute",
                top: "-10px",
                left: "50%",
                transform: "translateX(-50%)",
                borderBottom: "10px solid #fff",
                borderLeft: "5px solid transparent",
                borderRight: "5px solid transparent",

                "@media(min-width:768px)": {
                    left: "90%"
                }
            },

            "& .MuiMenuItem-root": {
                padding: "3px 35px 3px 15px",
                fontSize: "12px"
            }
        }
    }
}));
