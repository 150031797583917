import React from "react";
import { usePropertyAdvStyles } from "./style";

import Images from "./components/images.component";
import Details from "./components/details.component";

const PropertyAdv = () => {
  const classes = usePropertyAdvStyles();

  return (
    <React.Fragment>
      <div className={classes.modal}></div>
      <div className={classes.innerListingContainer}>
        <Images />
        <Details />
      </div>
    </React.Fragment>
  );
};

export default PropertyAdv;
