import React from "react";
import { Link } from "@material-ui/core";
import LipmanPropComponent from "../../propertyListing/components/PlanPropComponent";

const ViewLiveizyPlan = ({ planProps, plan }) => {
    return (
        <div id="lipman-listing-page">
            <div className="lpmp-properties-container">
                <div className="lpmp-properties-list-header">
                    <b>Property / Estate name</b>
                    <b>Property ID</b>
                </div>
                <div className="lpmp-properties-list">
                    {planProps[0] &&
                        planProps.map((item, idx) => {
                            if (!item.apartment[0]) return null;
                            return <LipmanPropComponent key={idx} property={item} plan={plan} />;
                        })}
                    {!planProps[0] && (
                        <p
                            style={{
                                color: "black",
                                fontSize: 14,
                                lineHeight: "20px",
                                opacity: 0.6,
                                padding: "50px 5px",
                                boxSizing: "content-box"
                            }}>
                            No Apartments added to Plan yet. Click{" "}
                            <Link href={plan === "LiPMaN" ? "/property/plan/lipman" : "/property/plan/lirem"}>
                                add apartments
                            </Link>{" "}
                            to create a Plan.
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ViewLiveizyPlan;
