import { authServiceApi } from "#/apis/authServiceApi";
import { propertyServiceApi } from "#/apis/propertyServiceApi";
import { formatNigerianPhoneNumber } from "#/components/utils/util";

import {
  alertClear,
  alertError,
  showNotifications
} from "#/store/actions/notification.actions";

const postProfileImageToCloudinary = async (profileImage) => {
  try {
    const formData = new FormData();
    formData.append("file", profileImage);
    formData.append("upload_preset", "monthly_rent_applicants_profile_images");

    const data = await fetch("https://api.cloudinary.com/v1_1/dfz1vgrgv/image/upload", {
      method: "POST",
      body: formData
    })
      .then((response) => response.json())
      .catch((error) => {
        console.log(error); // do something else, like dispatch error message
      });
    return data.secure_url;
  } catch (error) {
    console.log(error); // do something else
  }
};

async function postAccountStatementDocumentToCloudinary(values) {
  const document = values?.accountStatementDoc;
  try {
    const formData = new FormData();
    formData.append("file", document);
    formData.append("upload_preset", "monthly_rent_applicants_account_statement_docs");

    const data = await fetch("https://api.cloudinary.com/v1_1/dfz1vgrgv/image/upload", {
      method: "POST",
      body: formData
    })
      .then((response) => response.json())
      .catch((error) => {
        console.log("Cloudinary doc upload error", error);
      });

    return data.secure_url;
  } catch (error) {
    console.log(error);
  }
}

export async function postAgreementStatementDocumentToCloudinary(document) {
  try {
    const formData = new FormData();
    formData.append("file", document);
    formData.append("upload_preset", "monthly_rent_applicants_account_statement_docs");

    const data = await fetch("https://api.cloudinary.com/v1_1/dfz1vgrgv/image/upload", {
      method: "POST",
      body: formData
    })
      .then((response) => response.json())
      .catch((error) => {
        console.log("Cloudinary doc upload error", error);
      });

    return data.secure_url;
  } catch (error) {
    console.log(error);
  }
}

export const updateMonthlyRentStatus = async (dispatch) => {
  await propertyServiceApi()
    .patch("tenant/rent-apply-status")
    .then((res) => dispatch(showNotifications("Application Successful", "success"))) // dispatch modal here
    .catch((error) => {
      dispatch(showNotifications("An error Occured", "error"));
    });
};

export const submitStageOne = async (
  values,
  dispatch,
  actions,
  history,
  isReview,
  liveizyId
) => {
  try {
    // Profile info
    const profileImageUrl = await postProfileImageToCloudinary(values?.profileImage);
    const profileInfo = {
      bvn: values?.bvn,
      first_name: values?.firstName,
      gender: values?.gender,
      state_of_origin: values?.stateOfOrigin,
      phone: formatNigerianPhoneNumber(values?.mobileNumber),
      last_name: values?.surname,
      other_name: values?.otherNames,
      date_of_birth: values?.dateOfBirth,
      local_governement_area: values?.lga,
      alt_email: values?.alternateEmail,
      profile_image: profileImageUrl,
      alt_phone: formatNigerianPhoneNumber(values?.alternateMobileNumber)
    };

    const otherInformations = {
      means_of_id: values?.meansOfId,
      means_of_id_file: "",
      employer_name: values?.nameOfEmployer,
      employment_status: values?.employmentStatus,
      office_address: values?.officeAddress,
      office_contact_person_name: values?.nameOfOfficeContactPerson,
      office_contact_person_phone_number: formatNigerianPhoneNumber(
        values?.officeContactPersonNumber
      ),
      monthly_income: values?.monthlyIncome,
      account_statement_doc: "",
      name_of_next_of_kin: values?.nameOfNextOfKin,
      contact_of_next_kin: formatNigerianPhoneNumber(values?.contactOfNextOfKin),
      email_of_next_of_kin: values?.emailOfNextOfKin,
      next_of_kin_home_address: values?.nextOfKinHomeAddress,
      name_of_reference: values?.nameOfReference,
      contact_of_reference: formatNigerianPhoneNumber(values?.contactOfreference)
    };

    const addressInfo = {
      // landlord_name: values?.nameOfLandlord,
      // landlord_contact: formatNigerianPhoneNumber(values?.contactOfLandlord),
      // manager_name: values?.nameOfManager,
      // manager_contact: formatNigerianPhoneNumber(values?.contactOfManager),
      laid: values?.laid,
      // address: values?.homeAddress,
      rent_range: values?.rentRange
    };

    // update profile info
    await authServiceApi().put("/user/profile", profileInfo);

    // upload files to cloudinary
    otherInformations.account_statement_doc = await postAccountStatementDocumentToCloudinary(
      values
    );
    otherInformations.means_of_id_file = await postProfileImageToCloudinary(
      values?.meansOfIdFile
    );

    // update other info
    await authServiceApi().put("user/other-information", otherInformations);

    // update application status
    if (isReview) {
      await propertyServiceApi().patch(`/application/update/${liveizyId}`, {
        ...addressInfo,
        monthly_rent_status_stage_1: "pending"
      });
    } else {
      await propertyServiceApi().post("/application/add", addressInfo);
    }
    dispatch(showNotifications("Application Submitted", "success"));
    actions.setSubmitting(false);
    history.push("/dashboard");
  } catch (error) {
    actions.setSubmitting(false);
    if (error.response.data.error) {
      dispatch(showNotifications(error.response.data.error, "error"));
    }
    if (error.response && error.response.data !== undefined) {
      if (
        error.response.data.data ||
        (error.response.data.error && typeof error.response.data.error === "object")
      ) {
        const errors = error.response.data.data;
        errors.forEach((error) => {
          Object.entries(error).forEach(([key, value]) => {
            dispatch(alertError(value));
          });
        });
      } else {
        dispatch(alertError(error.response.data.message));
      }

      setTimeout(() => {
        dispatch(alertClear());
      }, 3000);
    }
  }
};
