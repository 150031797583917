import React, { useState } from "react";
import { lpmsStyles } from "./styles";
import Button from "#/components/ui-components/button.component";
import avatar from "#/assets/images/tenant/default-avatar.png";
import AddPMModal from "./components/addPM-modal";
import SadFaceIconComponent from "#/components/ui-components/sadFaceIcon.component";

const LPMS = () => {
    const [modal, setModal] = useState(false);

    const classes = lpmsStyles();
    // This is to toggle open the modal
    const closeModal = () => {
        setModal(!modal);
    };

    let Display;

    if (true) {
        Display = (
            <SadFaceIconComponent>
                {" "}
                <p>You have not added any Property Manager</p>
                <Button
                    buttonText="Add PM"
                    onClick={closeModal}
                    isLoading={""}
                    className={classes.sadIconButton}
                    style={{}}
                />
            </SadFaceIconComponent>
        );
    }

    return (
        <div className={classes.wrapper}>
            {modal && <AddPMModal modal={modal} closeModal={closeModal} />}
            <div className={classes.topHeader}>
                <div className="header-text">
                    <h3>LPMS</h3>
                </div>
                <div className="">
                    <Button buttonText="Add PM" onClick={closeModal} isLoading={""} className="btn" style={{}} />
                    <Button buttonText="Request PM" isLoading={""} className="btn" />
                </div>
            </div>

            <div className={classes.rows}>
                <div className={classes.boxHeading}>
                    <p>Property Manager</p>
                    <p>Status</p>
                </div>
                <div className="">
                    {Display}
                    {/* <div className={classes.boxContent}>
                        <div className="box_user">
                            <img src={avatar} alt="tenants" />
                            <p>
                                <span>Okunfolami Ibukun</span>
                                <span>LID: UvBa1591</span>
                            </p>
                        </div>
                        <div className="status_button">
                            <div className="btn" style={{ backgroundColor: "#45B29D" }}>
                                Active
                            </div>
                            <Button
                                buttonText="Revote access"
                                onClick={""}
                                isLoading={""}
                                className="btn"
                                style={{ backgroundColor: "#6E6E6E42" }}
                            />
                        </div>
                    </div> */}
                    {/* <div className={classes.boxContent}>
                        <div className="box_user">
                            <img src={avatar} alt="tenants" />
                            <p>
                                <span>Okunfolami Ibukun</span>
                                <span>LID: UvBa1591</span>
                            </p>
                        </div>
                        <div className="status_button">
                            <div className="btn" style={{ backgroundColor: "#D75746" }}>
                                Declined
                            </div>
                        </div>
                    </div> */}
                    {/* <div className={classes.boxContent}>
                        <div className="box_user">
                            <img src={avatar} alt="tenants" />
                            <p>
                                <span>Okunfolami Ibukun</span>
                                <span>LID: UvBa1591</span>
                            </p>
                        </div>
                        <div className="status_button">
                            <div className="btn" style={{ backgroundColor: "#EFA25A" }}>
                                Pending
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default LPMS;
