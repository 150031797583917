import { Box, CircularProgress, Modal } from "@mui/material";
import React from "react";

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4
};

const LoadingModal = () => {
    return (
        <Modal open={true} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={modalStyle}>
                <CircularProgress />
            </Box>
        </Modal>
    );
};

export default LoadingModal;
