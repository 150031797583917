import * as React from "react";

function SvgMoney(props) {
  return (
    <svg width={31} height={31} {...props}>
      <defs>
        <style>{".money_svg__a{fill:#043b5c}"}</style>
      </defs>
      <path
        className="money_svg__a"
        d="M8.132 8.134v1.945a1.252 1.252 0 00.6-.868.937.937 0 00-.6-1.077zM6.6 5.562a.627.627 0 00.156.607c.05.04.106.078.166.117V5.015a.9.9 0 00-.322.547z"
      />
      <path
        className="money_svg__a"
        d="M7.568 0a7.568 7.568 0 107.568 7.568A7.577 7.577 0 007.568 0zm2.357 9.411a2.462 2.462 0 01-1.793 1.962v.434a.605.605 0 01-1.211 0v-.376a2.932 2.932 0 01-1.469-.55.605.605 0 01.663-1.013 1.835 1.835 0 00.806.345V7.656a4.466 4.466 0 01-.913-.534 1.812 1.812 0 01-.6-1.784 2.04 2.04 0 011.4-1.6l.113-.032V3.33a.605.605 0 111.211 0v.31a2.1 2.1 0 011.2.622.606.606 0 01-.91.8.834.834 0 00-.295-.175v1.957c.107.04.215.079.32.116a2.167 2.167 0 011.478 2.451z"
      />
      <g>
        <path
          className="money_svg__a"
          d="M30.244 13.454l-3.158-.536a9.415 9.415 0 00-1.4-1.7 6.607 6.607 0 012.359-3.88l.191-.152a.908.908 0 00-.566-1.616h-2.422a5.775 5.775 0 00-5.088 3.08 9.15 9.15 0 00-.967-.052H16.9A9.379 9.379 0 01.839 14.102a9.377 9.377 0 002.794 11.289v4.7a.908.908 0 00.908.908h3.633a.908.908 0 00.862-.621l1-3.012h8.5l1 3.012a.908.908 0 00.862.621h3.633a.908.908 0 00.908-.908v-4.7a9.35 9.35 0 002.854-3.684l2.633-1.067a.908.908 0 00.567-.842v-5.449a.908.908 0 00-.756-.9zm-7.115 2.106a.908.908 0 11.908-.908.908.908 0 01-.908.908z"
        />
      </g>
    </svg>
  );
}

export default SvgMoney;
