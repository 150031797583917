import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import modalStyles from "./modalStyles";
import "./modal.styles.scss";
import { ModalTextField } from "#/pages/Wallet/fund-wallet/ModalHelper";
import wallet from "#/assets/images/wallet (1).svg";
import { Controller, useForm } from "react-hook-form";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import CustomButton from "#/components/ui-components/button.component";
import { Checkbox, CircularProgress, Typography } from "@material-ui/core";
import clsx from "clsx";
import { verifyPayment } from "#/store/actions/izypay.actions";
import { PaystackButton } from "react-paystack";
import NumberFormat from "react-number-format";
import { stringToNumberMoney } from "#/components/utils/util";

const FundWalletForm = ({ handleClose }) => {
  const classes = modalStyles();
  const dispatch = useDispatch();
  const tLoading = useSelector((store) => store.izypay.loading);
  const store = useSelector((store) => store.auth);
  const user = store.user;

  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;
  const email = user.email;
  const name = {
    firstName: user.first_name,
    lastName: user.last_name
  };
  const phone = user.phone;
  const { register, watch, handleSubmit, setValue, errors, control } = useForm({
    mode: "onBlur"
  });
  const handleInputChange = (e) => {
    if (e.target.validity.valid) setAmount(e.target.value * 100);
  };

  const values = watch();

  // get amount value
  useEffect(() => {
    let amountNUmber;
    if (values.amount !== undefined) {
      amountNUmber = stringToNumberMoney(values.amount);
      // console.log(amountNUmber.toFixed(2))
      setAmount(amountNUmber * 100);
    }
  }, [values.amount]);

  // Paystack button
  const componentProps = {
    email,
    amount,
    metadata: {
      name,
      phone
    },
    publicKey,
    text: "FUND",
    onSuccess: (response) => {
      dispatch(
        verifyPayment(response.reference, () => {
          // setAmount(0);
          handleClose();
        })
      );
      handleClose();
    }
    // onClose: () => console.log('here'),
  };
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <React.Fragment>
      <form>
        <Controller
          as={NumberFormat}
          customInput={ModalTextField}
          thousandSeparator
          label="Enter Amount"
          img={wallet}
          error={errors.amount && errors.amount.message}
          defaultValue=""
          rules={{
            required: "This field is Required",
            pattern: "^-?[0-9]d*.?d*$"
            // validate: validateDeposit,
          }}
          value={values.amount}
          onChange={handleInputChange}
          name="amount"
          inputmode="numeric"
          prefix="₦"
          control={control}
          placeholder="Enter amount"
          allowNegative={false}
        />
        {/* <div className="strike">
                    <span className={classes.checkBox}>
                        <Checkbox
                            checked={checked}
                            onChange={handleChange}
                            inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                        Pay with account number
                        <span></span>
                    </span>
                </div>
                {checked && (
                    <div>
                        <div className={classes.hiddenContainer}>
                            <Typography className={clsx(classes.hiddenContent, classes.bold)}>
                                Bank Account Number
                            </Typography>
                            <Typography className={classes.hiddenContent}>
                                GTB Bank: 00994577788{" "}
                                <span>
                                    <FileCopyOutlinedIcon />
                                </span>
                            </Typography>
                            <Typography className={classes.hiddenContent}>
                                GTB Bank: 00994577788{" "}
                                <span>
                                    <FileCopyOutlinedIcon />
                                </span>
                            </Typography>
                        </div>
                    </div>
                )} */}
      </form>
      <div className={classes.transferBtnMargin} style={{ marginTop: "4%" }}>
        <CustomButton
          variant="outlined"
          className={classes.closeBtn}
          onClick={handleClose}
          buttonText="CLOSE"
        />
        {amount < 1 ? (
          <button className={classes.fundBtn}>FUND</button>
        ) : (
          <PaystackButton {...componentProps} className={classes.fundBtn}>
            {loading ? <CircularProgress size={16} color="inherit" /> : "FUNDzz"}
          </PaystackButton>
        )}
      </div>
    </React.Fragment>
  );
};

export default FundWalletForm;
