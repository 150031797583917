import React, { useEffect, useState } from "react";
import { ListOfBanksInNigeria } from "#/constants";
import { Autocomplete, Button, CircularProgress, styled, TextField } from "@mui/material";
import { handleFormInput, validate } from "#/lib/validation";
import { walletServiceApi } from "#/apis/izyPayApi";
import { showNotifications } from "#/store/actions/notification.actions";
import { useDispatch } from "react-redux";
// ----------------------
// BANK DETAILS PROP FORMAT
// -----------------------

// const [bankDetails, setBankDetails] = useState({
//     bank: {
//         value: null,
//         validation: true,
//         error: ""
//     },
//     accountNumber: {
//         value: "",
//         validation: true,
//         error: ""
//     }
// });
// const [accountName, setAccountName] = useState("");

const BankAccountForm = ({
  bankDetails,
  setBankDetails,
  accountName,
  setAccountName,
  handleSubmit
}) => {
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(null);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //     const { bank, accountNumber } = bankDetails;
  //     validate({ bank, accountNumber }, (trueFalse) => {
  //         if (trueFalse) {
  //             validateBankAccount(bank.value.code, accountNumber.value);
  //         }
  //         // setFadeBtn(!trueFalse);
  //     });
  // }, [bankDetails]);

  const validateBankAccount = (bank_code, account_number) => {
    setValidated("loading");
    setAccountName(false);

    walletServiceApi()
      .post("/user/validate/nuban", { bank_code, account_number })
      .then((data) => {
        setValidated("");
        setAccountName(data.data.data.account_name);
      })
      .catch((err) => {
        setValidated("Could not resolve Account Name");
        setAccountName(false);
        dispatch(showNotifications("Could not resolve Account Name", "error"));
      });
  };
  return (
    <div>
      <div>
        <Autocomplete
          id="grouped-demo"
          fullWidth
          options={ListOfBanksInNigeria}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select your bank"
              variant="outlined"
              fullWidth
              error={!bankDetails.bank.validation}
            />
          )}
          value={bankDetails.bank.value}
          onChange={(e, value) =>
            handleFormInput("bank", value, /^.+$/gi, bankDetails, setBankDetails)
          }
          style={{ marginBottom: 20 }}
        />
        <TextField
          fullWidth
          variant="outlined"
          label="Account Number"
          value={bankDetails.accountNumber.value}
          onChange={(e) => {
            const value = e.target.value.replace(/[^0-9]/g, "").slice(0, 10);
            handleFormInput(
              "accountNumber",
              value,
              /^\d{10}$/g,
              bankDetails,
              setBankDetails
            );
          }}
          error={!bankDetails.accountNumber.validation}
          disabled={validated === "loading"}
          style={{ marginBottom: 20 }}
          helperText={
            !bankDetails.accountNumber.validation
              ? `Account Number should be 10 digits`
              : validated === "loading"
              ? "validating..."
              : ""
          }
        />
        <TextField
          fullWidth
          variant="outlined"
          label="Account Name"
          //   value={accountName === false ? "" : accountName}
          value={accountName}
          onChange={(e) => setAccountName(e.target.value)}
          //   InputProps={{
          //     readOnly: true
          //   }}
          error={accountName === false}
          style={{ marginBottom: 20 }}
          helperText={validated === "loading" ? "validating..." : validated}
        />
      </div>
    </div>
  );
};

export default BankAccountForm;
