import React, { useEffect, useState } from "react";
import DetailsCardContainer, {
  DetailsContainer
} from "#/components/cards/DetailsCardContainer.component";
import SubtitleAndSubtext from "#/components/ui-components/SubtitleAndSubtext.component";
import { Button, CircularProgress, Grid, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { currentTenancyInfo } from "./formModel";
import { Form, Formik } from "formik";
import FormikCustomInputField from "#/components/formik/formfields/FormikCustomInputField";
import { currentTenancyInfoSchema } from "./validationSchema";
import { currentTenancyInitialValues } from "./formInitialValues";
import { tenancyInfoSubmit } from "./utils";
import { getApartmentById } from "#/store/actions/apartment.actions";
import { toCurrency } from "#/components/utils/util";
import { propertyServiceApi } from "#/apis/propertyServiceApi";

const {
  formField: {
    currentHomeAddress,
    currentRentValue,
    nameOfManager,
    contactOfManager,
    nameOfLandlord,
    contactOfLandlord
  }
} = currentTenancyInfo;

const btnStyle = {
  background: "#4786FF",
  borderRadius: "5px",
  color: "#F4F5F7",
  border: "1px solid #4786FF",
  padding: "10px 30px",
  fontSize: "12px",
  fontFamily: "Open Sans-Semibold, sans-serif",
  width: "145px",

  "&:hover": {
    background: "#4786FF"
  }
};

const CurrentTenancyInfo = ({
  apartmentById,
  propertyManager,
  propertyOwner,
  loading
}) => {
  const dispatch = useDispatch();
  //   const [editMode, setEditMode] = useState(false);
  const editMode = false;
  const { otherInformation, updatingOtherInfo } = useSelector((state) => state.auth);
  //   const { apartment_id } = useSelector((state) => state.tenant.tenantsApartment);
  //   const { apartmentById, loading } = useSelector((state) => state.apartment);
  //   const [propertyOwner, setPropertyOwner] = useState({});
  //   const [propertyManager, setPropertyManager] = useState({});

  //   useEffect(() => {
  //     dispatch(getApartmentById(apartment_id));
  //   }, [apartment_id]);

  //   useEffect(() => {
  //     if (apartmentById?.owner) {
  //       propertyServiceApi()
  //         .get(`/property-owner/${apartmentById.owner}`)
  //         .then((res) => {
  //           setPropertyOwner(res.data.payload);
  //         });
  //     }
  //   }, [apartmentById]);

  //   useEffect(() => {
  //     if (apartmentById?.manager) {
  //       propertyServiceApi()
  //         .get(`/property-manager/${apartmentById.manager}`)
  //         .then((res) => {
  //           setPropertyManager(res.data.payload);
  //         });
  //     }
  //   }, [apartmentById]);

  const initialValues = currentTenancyInitialValues(otherInformation);

  //   const handleEditMode = () => {
  //     ()=>{}(!editMode);
  //   };

  const handleFormSubmit = async (values, actions) => {
    await tenancyInfoSubmit(values, dispatch, () => {});
    actions.setSubmitting(false);
  };

  return (
    <>
      {!editMode ? (
        <>
          <DetailsCardContainer>
            <DetailsContainer>
              <SubtitleAndSubtext
                sm={4}
                xs={12}
                lg={6}
                loading={loading}
                subtitle={"Current Home Address"}
                subtext={`${
                  apartmentById?.house_number ? apartmentById?.house_number + "," : ""
                } ${apartmentById?.street ? apartmentById?.street + "," : ""} ${
                  apartmentById?.other_address ? apartmentById?.other_address + "," : ""
                } ${apartmentById?.lga ? apartmentById?.lga + "," : ""} ${
                  apartmentById?.state ? apartmentById?.state + "," : ""
                } ${apartmentById?.country ? apartmentById?.country + "." : ""}`}
              />
              <SubtitleAndSubtext
                loading={loading}
                subtitle={"Current Rent Value"}
                subtext={toCurrency(apartmentById?.price)}
              />
              <SubtitleAndSubtext
                loading={loading}
                subtitle={"Name of Landlord"}
                subtext={`${propertyOwner?.first_name ? propertyOwner?.first_name : ""} ${
                  propertyOwner?.last_name ? propertyOwner?.last_name : ""
                }`}
              />
              <SubtitleAndSubtext
                loading={loading}
                subtitle={"Contact of Landlord"}
                subtext={propertyOwner?.phone1}
              />
              <SubtitleAndSubtext
                loading={loading}
                subtitle={"Name of Manager"}
                subtext={`${
                  propertyManager?.first_name ? propertyManager?.first_name : ""
                } ${propertyManager?.last_name ? propertyManager?.last_name : ""}`}
              />
              <SubtitleAndSubtext
                loading={loading}
                subtitle={"Contact of Manager"}
                subtext={propertyManager?.phone1}
              />
            </DetailsContainer>
          </DetailsCardContainer>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginTop: "60px"
            }}>
            {/* <Button
              sx={{ width: "120px", background: "#10609F" }}
              onClick={handleEditMode}
              variant="contained">
              Edit
            </Button> */}
          </div>
        </>
      ) : (
        <>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={currentTenancyInfoSchema}
            onSubmit={handleFormSubmit}>
            {({ isSubmitting }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormikCustomInputField
                      name={currentHomeAddress.name}
                      label={currentHomeAddress.label}
                      placeholder={currentHomeAddress.placeHolder}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikCustomInputField
                      name={currentRentValue.name}
                      label={currentRentValue.label}
                      placeholder={currentRentValue.placeHolder}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikCustomInputField
                      name={nameOfManager.name}
                      label={nameOfManager.label}
                      placeholder={nameOfManager.placeHolder}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikCustomInputField
                      name={contactOfManager.name}
                      label={contactOfManager.label}
                      placeholder={contactOfManager.placeHolder}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikCustomInputField
                      name={nameOfLandlord.name}
                      label={nameOfLandlord.label}
                      placeholder={nameOfLandlord.placeHolder}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikCustomInputField
                      name={contactOfLandlord.name}
                      label={contactOfLandlord.label}
                      placeholder={contactOfLandlord.placeHolder}
                    />
                  </Grid>
                </Grid>
                <Stack direction={"row"} spacing={2} justifyContent="center">
                  <Button
                    type="button"
                    // isLoading={updatingOtherInfo}
                    sx={btnStyle}
                    disabled={updatingOtherInfo}
                    // onClick={handleEditMode}
                    style={{ background: "white", color: "blue" }}>
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    disabled={updatingOtherInfo}
                    // isLoading={updatingOtherInfo}
                    sx={btnStyle}>
                    {updatingOtherInfo ? <CircularProgress size={"small"} /> : "Update"}
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default CurrentTenancyInfo;
