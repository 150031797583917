import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Row from "./Row";

const rows = [
    {
        name: "Kelechi Oleka",
        property: "Icreate | suites",
        address: "Flutter | suites",
        accountStatus: "Verified",
        status: "Active",
        rent: "10000",
        roommates: "2"
    },
    {
        name: "Kelechi Oleka",
        property: "Icreate | suites",
        address: "Flutter | suites",
        accountStatus: "Verified",
        status: "Active",
        rent: "10000",
        roommates: "2"
    },
    {
        name: "Kelechi Oleka",
        property: "Icreate | suites",
        address: "Flutter | suites",
        accountStatus: "Verified",
        status: "Active",
        rent: "10000",
        roommates: "2"
    },
    {
        name: "Kelechi Oleka",
        property: "Icreate | suites",
        address: "Flutter | suites",
        accountStatus: "Verified",
        status: "Active",
        rent: "10000",
        roommates: "2"
    }
];

const styles = (theme) => ({
    table: {
        "& thead tr": {
            backgroundColor: "#d2e4f7"
        },
        "& .MuiTableCell-root": {
            padding: 13,
            borderBottom: 0
        },
        "& .MuiTableCell-head": {
            color: "#043B5C !important",
            fontWeight: "bold !important",
            fontSize: 14
        }
    }
});

const TenantsListTable = (props) => {
    return (
        <TableContainer component={Paper}>
            <Table className={props.classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="center">Property/Unit</TableCell>
                        <TableCell align="center">Address</TableCell>
                        <TableCell align="center">Account Status</TableCell>
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="center">Rent</TableCell>
                        <TableCell align="center">Roommates</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <Row key={row.name} row={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default withStyles(styles)(TenantsListTable);
