import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { CircularProgress } from "@material-ui/core";
import { Autocomplete, Button, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ConfirmationPopup from "#/components/popup/confirmation";
import PageTitle from "#/components/estateManagementComponents/page-title";
import { ListOfBanksInNigeria } from "#/constants";
import { handleFormInput, validate } from "#/lib/validation";
import { propertyServiceApi, returnEstateMember } from "#/apis/propertyServiceApi";
import { alertError, alertSuccess } from "#/store/actions/notification.actions";
import { returnNgDateFormat } from "#/lib/dateHelpers";
import { ESM_ROUTES } from "#/constants/routes";
import BreadCrumbs from "#/components/estateManagementComponents/bread-crumbs";

const initialValues = {
  account_number: { value: "", validation: true, error: "" },
  bank: { value: {}, validation: true, error: "" }
};

const breadcrumbLinks = [
  { title: "dashboard", href: "/estate-management/dashboard" },
  { title: "financial", href: "/estate-management/finance", currentPage: true }
];

const Financial = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { member } = returnEstateMember();

  const [formValues, setFormValues] = useState(initialValues);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState({});
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [loading5, setLoading5] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const [payouts, setPayouts] = useState([]);
  const [verified, setVerified] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [accountToDelete, setAccountToDelete] = useState(null);

  // useEffect(() => {
  //     validate(formValues, (status) => {
  //         if (status) validateAccountNumber();
  //     });
  // }, [formValues]);

  // useEffect(() => {
  //     fetchAccount();
  // }, []);

  // useEffect(() => {
  //     fetchPayouts();
  // }, []);

  const createAccount = (e) => {
    e.preventDefault();
    setLoading1(true);
    const payload = {
      bank_code: formValues.bank.value.code,
      account_number: formValues.account_number.value,
      bank_name: formValues.bank.value.name,
      account_name: verified.account_name,
      estate: member.estate._id
    };
    propertyServiceApi()
      .post("/estate-management/bank-account", payload)
      .then((data) => {
        setFormValues(initialValues);
        setVerified(null);
        fetchAccount();
        setDisableSubmit(true);
        dispatch(alertSuccess("Succefully added bank account!!"));
      })
      .catch(({ response, message }) => {
        setDisableSubmit(true);
        dispatch(alertError(response?.data.error || message));
      })
      .finally(() => {
        setLoading1(false);
      });
  };

  const deleteAccount = (id) => () => {
    setLoading2({ ...loading2, [id]: true });
    setOpenConfirm(false);
    setAccountToDelete(null);
    propertyServiceApi()
      .delete(`/estate-management/bank-account/${id}`)
      .then((data) => {
        fetchAccount();
      })
      .catch(({ response, message }) => {
        dispatch(alertError(response?.data.error || message));
      })
      .finally(() => {
        setLoading2({ ...loading2, [id]: false });
      });
  };

  const fetchAccount = () => {
    setLoading3(true);
    propertyServiceApi()
      .get("/estate-management/bank-account", { params: { estate: member?.estate._id } })
      .then((data) => {
        setAccounts(data.data.payload);
      })
      .catch(({ response, message }) => {
        if (response?.status === 403) return;
        dispatch(alertError(response?.data.error || message));
      })
      .finally(() => {
        setLoading3(false);
      });
  };

  const fetchPayouts = () => {
    setLoading4(true);
    propertyServiceApi()
      .get("/payout", {
        params: { estate: member.estate._id, populate2: "bank_account" }
      })
      .then((data) => {
        setPayouts(data.data.payload);
      })
      .catch(({ response, message }) => {
        if (response?.status === 403) return;
        dispatch(alertError(response?.data.error || message));
      })
      .finally(() => {
        setLoading4(false);
      });
  };

  const validateAccountNumber = () => {
    const { code } = formValues.bank.value;
    const payload = {
      account_number: formValues.account_number.value,
      bank_code: code
    };
    if (!code) return;
    setLoading5(true);
    propertyServiceApi()
      .post("/estate-management/bank-account/validate", payload)
      .then((data) => {
        setVerified(data.data.payload);
        setDisableSubmit(false);
      })
      .catch(({ response, message }) => {
        setVerified(null);
        setDisableSubmit(true);
        dispatch(alertError(response?.data.error || message));
      })
      .finally(() => {
        setLoading5(false);
      });
  };

  const initiateDelete = (id) => () => {
    setOpenConfirm(true);
    setAccountToDelete(id);
  };

  const onDeleteCancel = () => {
    setOpenConfirm(false);
    setAccountToDelete(null);
  };

  // if (!member.super_admin) {
  //     history.push(ESM_ROUTES.dashboard);
  //     return null;
  // }

  return (
    <div className={classes.root}>
      <BreadCrumbs links={breadcrumbLinks} />
      <PageTitle title="Add Bank Details" />
      <form onSubmit={createAccount} className={classes.memberForm}>
        <TextField
          id="outlined-basic"
          style={{ marginBottom: 20 }}
          label="Enter bank account"
          variant="outlined"
          value={formValues.account_number.value}
          error={!formValues.account_number.validation}
          helperText={formValues.account_number.error || verified?.accountName}
          disabled={loading5}
          onChange={(e) => {
            const value = e.target.value;
            handleFormInput(
              "account_number",
              value,
              /\d{10}/gi,
              formValues,
              setFormValues,
              ["nuban"]
            );
          }}
        />
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          style={{ marginBottom: 30 }}
          options={ListOfBanksInNigeria}
          getOptionLabel={(option) => option?.name || ""}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select bank"
              helperText={loading5 && "...verifying"}
            />
          )}
          value={formValues.bank.value}
          disabled={loading5}
          onChange={(e, value) => {
            handleFormInput("bank", value, /./gi, formValues, setFormValues);
          }}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="submit-btn"
          disabled={disableSubmit || loading1}>
          {loading1 ? <CircularProgress size={25} /> : "Add Account"}
        </Button>
      </form>
      <div className={classes.bankDetails}>
        <h3>Bank Details</h3>
        {loading3 ? (
          <CircularProgress />
        ) : (
          accounts?.map((bank, idx) => (
            <Card key={idx} className="member-details">
              <div className="mb-dt1">
                <div>
                  <p>Account Number</p> <span>{bank.account_number}</span>
                </div>
                <div>
                  <p>Bank</p> <span>{bank.bank_name}</span>
                </div>
                <div>
                  <p>Account Name</p> <span>{bank.account_name}</span>
                </div>
              </div>
              <div className="mb-dt2">
                <IconButton
                  onClick={initiateDelete(bank._id)}
                  disabled={loading2[bank._id]}>
                  {loading2[bank._id] ? (
                    <CircularProgress size={20} />
                  ) : (
                    <DeleteIcon className="delete_icon" />
                  )}
                </IconButton>
              </div>
            </Card>
          ))
        )}
      </div>
      <div className={classes.payHistory}>
        <h3>Payout History</h3>
        <hr />

        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Amount</TableCell>
                <TableCell align="right">Commission</TableCell>
                <TableCell align="right">Date Paid</TableCell>
                <TableCell align="right">Bank Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payouts?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    &#x20A6;{row.amount_payed_out}
                  </TableCell>
                  <TableCell align="right">&#x20A6;{row.commission_payed}</TableCell>
                  <TableCell align="right">{returnNgDateFormat(row.createdAt)}</TableCell>
                  <TableCell align="right">
                    <span>{row.bank_account?.bank_name}</span>
                    <span>{row.bank_account?.account_number}</span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {openConfirm && (
        <ConfirmationPopup
          title="Delete account"
          description="Are you sure you want to delete this account?"
          handleClose={onDeleteCancel}
          proceedAction={deleteAccount(accountToDelete)}
        />
      )}
    </div>
  );
};

export default Financial;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0 5%",
    marginTop: 20
  },

  muiInput: {
    height: 35
  },

  payHistory: {
    display: "flex",
    flexDirection: "column",
    marginTop: "60px",
    marginBottom: 100,
    width: "100%",
    "& hr": {
      height: 1,
      width: "100%",
      backgroundColor: "#8c8b8b",
      border: "none",
      marginTop: 10
    },

    "& h3": {
      fontSize: "18px",
      fontWeight: 800,
      marginBottom: 0
    },

    "& td > span": {
      display: "block",
      fontWeight: 600
    },

    "& td.css-177gid-MuiTableCell-root, th.css-1ex1afd-MuiTableCell-root": {
      padding: "16px"
    },

    "& .css-15wwp11-MuiTableHead-root th": {
      fontSize: "15px",
      fontWeight: 600,
      marginBottom: "10px"
    },

    "& .css-quj9j6-MuiTable-root": {
      minWidth: 0
    }
  },

  memberForm: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "100%",

    "& p >span": {
      fontWeight: 600,
      fontSize: "16px",
      opacity: 0.7
    },

    "& .submit-btn": {
      alignSelf: "end",
      width: 150,
      borderRadius: 6,
      border: "none",
      color: "#fff",
      outline: "none",
      padding: "8px 16px",
      fontWeight: 500
    }
  },

  bankDetails: {
    marginTop: "30px",
    marginBottom: "20px",
    width: "100%",

    "& h3": {
      fontSize: "18px",
      fontWeight: 600,
      margin: "15px 0px"
    },

    "& .member-details": {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      padding: "15px",
      width: "100%",
      marginBottom: 20,

      "& .mb-dt1": {
        "& div": {
          marginBottom: 20
        },
        "& div > p": {
          marginTop: 0,
          fontSize: 16
        },
        "& div > span": {
          fontSize: 14,
          fontWeight: 700,
          color: "blue"
        }
      },
      "& .mb-dt2": {},
      [theme.breakpoints.up(500)]: {
        "& .mb-dt1": {
          width: "80%",
          "& div": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "& p, span": {
              width: "50%"
            }
          }
        }
      }
    },
    "& .delete_icon": {
      fill: "#c84a3c"
    }
  }
}));
