import React, { useState } from "react";
import { Button, Grid } from "@material-ui/core";
import avatar from "#/assets/images/tenant/default-avatar.png";
import CustomButton from "#/components/ui-components/button.component";
import { Styles } from "./style";
import mail from "#/assets/settingsAssest/mail.svg";
import setting from "#/assets/settingsAssest/settings.svg";
import AddIcon from "@material-ui/icons/Add";
import "./style.scss";

const Setting = (props) => {
    const [reminder, setReminder] = useState([
        { value: "24 hours", isChecked: false },
        { value: "2 days", isChecked: false },
        { value: "14 days", isChecked: false },
        { value: "30 days", isChecked: false },
        { value: "60 days", isChecked: false },
        { value: "90 days", isChecked: false },
        { value: "120 days", isChecked: false },
        { value: "150 days", isChecked: false },
        { value: "190 days", isChecked: false },
        { value: "250 days", isChecked: false }
    ]);

    const [open, setOpen] = useState(false);

    const closeDropdown = (e) => {
        e.preventDefault();
        setOpen(false);
    };

    const classes = Styles(open);
    return (
        <section style={{ paddingRight: "10px" }}>
            <div className={classes.subscription_tab}>
                <p>Settings</p>
                <div className="icon" onClick={() => props.showSubscription()}>
                    <img src={setting} alt="icon" />
                </div>
            </div>
            <form>
                <div className={classes.form}>
                    <div className={classes.label}>
                        <label htmlFor="set_rent">
                            Set rent reminder Periods{" "}
                            <span className="" style={{ color: "#b6c6d1" }}>
                                (Tick your reminders periods)
                            </span>
                        </label>
                        <div className="" style={{ position: "relative" }}>
                            <div className={classes.inputWrapper} onClick={() => setOpen(!open)}>
                                <div className="input-placeholder">Add</div>
                                <span className="input-icon">
                                    <AddIcon fontSize="small" style={{ color: "#4786FF" }} />
                                </span>
                            </div>
                            <div className={classes.checkbox}>
                                <div className="list">
                                    <ul className="remind_list">
                                        {reminder.map((remind, index) => (
                                            <li className="options" key={index}>
                                                <input type="checkbox" name={remind.value} />
                                                <label htmlFor={remind.value}>{remind.value}</label>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <CustomButton
                                    type="submit"
                                    buttonText="Add"
                                    onClick={closeDropdown}
                                    style={{
                                        backgroundColor: "#45B29D",
                                        color: "#E8F0F9",
                                        textTransform: "none",
                                        borderRadius: "5px",
                                        color: "#F4F5F7",
                                        border: "1px solid #45B29D",
                                        padding: "7px 25px",
                                        fontSize: "13px",
                                        fontFamily: "Open Sans, sans-serif"
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.textAreaWrapper}>
                    <p className="sub-header">Your Message</p>
                    <div className="sub-content">
                        <p>
                            He has an extraordinary hold over large swathes of this country, a visceral connection that
                            among thousands of supporters has brought a near cult-like devotion. After four years in the
                            White House, his supporters studied the fine print of his presidency and clicked
                            enthusiastically on the terms and conditions of his presidency.After four years in the White
                            House, his supporters studied the fine print of his presidency and clicked enthusiastically
                            on the terms and conditions of his presidency.
                        </p>
                    </div>
                    <div style={{ marginTop: "40px" }}>
                        <CustomButton
                            type="submit"
                            buttonText="Edit"
                            style={{
                                backgroundColor: "#4786FF",
                                color: "#FFFFFF",
                                textTransform: "none",
                                background: "#4786FF",
                                borderRadius: "5px",
                                color: "#F4F5F7",
                                border: "1px solid #4786FF",
                                padding: "10px 30px",
                                fontSize: "13px",
                                fontFamily: "Open Sans-Semibold, sans-serif",
                                width: "145px"
                            }}
                        />
                    </div>
                </div>
            </form>
        </section>
    );
};

export default Setting;
