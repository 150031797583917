import { makeStyles } from "@material-ui/core/styles";

export const Styles = makeStyles((theme) => ({
  root: {},

  header: {
    background: "#EEF4FB 0% 0% no-repeat padding-box",
    borderRadius: "10px",
    marginBottom: "20px",

    "&  .header__body": {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      height: "210px",

      "&__left": {
        "& p": {
          color: "#043B5C",
          fontFamily: "Open Sans-bold, sans-serif",
          fontWeight: "bold",
          fontSize: "25px",
          width: "250px",
          lineHeight: "1.3em",
          marginBottom: "25px",
          // letterSpacing: "0.16px",
        },
      },

      "&__right": {
        background: "#4786FF",
        "& img": {
          // borderRadius: "100%",
          // width: "40%",
          // objectFit: "cover",
          // height: 100;
        },
      },
    },
  },

  btn: {
    background: "#4786FF",
    borderRadius: "5px",
    color: "#F4F5F7",
    border: "1px solid #4786FF",
    padding: "10px 30px",
    fontSize: "12px",
    fontFamily: "Open Sans-Semibold, sans-serif",
    width: "145px",
    textTransform: "captizile",

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  inputWrapper: {
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #e5e8eb",
    padding: "11px 75px 11px 40px",
    alignItems: "center",
    cursor: "pointer",

    "& .input-placeholder": {
      color: "#99AFBD",
      fontFamily: "Open Sans-Semibold, sans-serif",
      fontWeight: "600",
      fontSize: "13px",
    },
  },

  checkbox: {
    // height: "200px",
    backgroundColor: "#EEF4FB",
    overflow: "auto",
    zIndex: "2",
    padding: "20px 30px",
    visibility: (open) => (open ? "visible" : "hidden"),
    opacity: (open) => (open ? 1 : 0),
    transitionProperty: "visibility, opacity, transform",
    transitionDuration: "0.35s",
    position: "absolute",
    width: "100%",

    "& .list": {
      fontFamily: "Open Sans-Semibold, sans-serif",
      fontWeight: "600",
      fontSize: "14px",
      marginBottom: "10px",
      "& .remind_list": {
        margin: "0px",
        listStyle: "none",
        padding: "0px",
        display: "inline-grid",
        gridTemplateColumns: "repeat(5, 120px)",
        gridTemplateRows: "40px",
        justifyContent: "center",

        "& .options": {
          "& input": {
            marginRight: "10px",
          },

          " & label": {
            color: "#043B5C",
            padding: 0,
          },
        },
      },
    },
  },

  textAreaWrapper: {
    height: "100%",
    background: " #EEF4FB 0% 0% no-repeat padding-box",
    borderRadius: "10px",
    opacity: 1,
    width: "775px",
    margin: "0 auto",
    padding: "25px 40px",

    "& .sub-header": {
      color: "#043B5C",
      fontFamily: "Open Sans-bold, sans-serif",
      fontWeight: "bold",
      fontSize: "17px",
      position: "relative",
      marginBottom: "30px",

      "&::after": {
        content: '""""',
        position: "absolute",
        borderBottom: "1px solid #043B5C",
        display: "block",
        width: "100%",
        top: "120%",
      },
    },

    "& .sub-content": {
      marginTop: "45px",
      "& p": {
        color: "#043B5C",
        fontFamily: "Open Sans, sans-serif",
        fontSize: "16px",
      },
    },
  },

  label: {
    display: "block",
    color: "#043B5C",
    fontSize: "13px",
    fontFamily: "Open Sans-Semibold, sans-serif",
    fontWeight: "600",
    marginBottom: "15px",
    letterSpacing: "0.09px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "10px",
    },
  },

  inputBox: {
    [theme.breakpoints.down("md")]: {
      marginBottom: "20px",
    },
    "& label": {
      display: "block",
      color: "#043B5C",
      fontSize: "13px",
      fontFamily: "Open Sans-Semibold, sans-serif",
      fontWeight: "600",
      marginBottom: "15px",
      letterSpacing: "0.09px",
      [theme.breakpoints.down("md")]: {
        marginBottom: "10px",
      },
    },

    "& input": {
      background: "#FFFFFF",
      border: "1px solid #043B5C54",
      borderRadius: "5px",
      width: "100%",
      padding: "11px 16px",
      backgroundColor: "#EEF4FB",
      border: "none",

      [theme.breakpoints.down("md")]: {
        width: "100% !important",
      },
    },
  },

  inputIcon: {
    [theme.breakpoints.down("md")]: {
      marginBottom: "20px",
    },

    "& label": {
      display: "block",
      color: "#043B5C",
      fontSize: "13px",
      fontFamily: "Open Sans-Semibold, sans-serif",
      fontWeight: "600",
      marginBottom: "15px",
      letterSpacing: "0.09px",
      [theme.breakpoints.down("md")]: {
        marginBottom: "10px",
      },
    },

    "& input": {
      background: "#FFFFFF",
      border: "1px solid #043B5C54",
      borderRadius: "5px",
      width: "100%",
      fontWeight: "500",
      padding: "10px 16px",
      display: "block",

      border: " 1px solid #06223968",

      "&:focus": {
        outline: "none",
      },

      [theme.breakpoints.down("md")]: {
        width: "100% !important",
      },
    },
  },

  forms: {
    marginBottom: "20px",

    [theme.breakpoints.down("md")]: {},
  },

  form: {
    marginBottom: "70px",

    [theme.breakpoints.down("md")]: {},
  },

  subscription_tab: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // alignContent: "center",
    position: "relative",
    marginBottom: "30px",

    "&::after": {
      content: '""""',
      position: "absolute",
      borderBottom: "1px solid #043B5C",
      display: "block",
      width: "100%",
      top: "120%",
    },

    "& p": {
      fontSize: "15px",
      fontFamily: "Open Sans-bold, sans-serif",
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "#043B5C",
      margin: 0,
    },

    "& .icon": {
      marginRight: "70px",
      cursor: "pointer",

      "& img": {
        width: "30px",
        backgroundColor: "#DEEDFF",
        padding: "5px",
        borderRadius: "5px",
      },
    },
  },
}));
