import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "@material-ui/core";
import { getAllProperties } from "#/store/actions/property.actions";
import { propertyListingStyles } from "./styles";
import PageHeader from "#/components/propertycomponents/page-header";
import { propertyServiceApi } from "#/apis/propertyServiceApi";
import PlanInitiateComponent from "./components/PlanInitiateComponent";
import GeneralPropComponent from "./components/GeneralPropComponent";
import CreatePlanModal from "./components/CreatePlanModal";
import Back from "../../../components/propertycomponents/back-component";
import { useHistory } from "react-router";

const LipmanPage = () => {
    const history = useHistory();

    const [lipmanProps, setLipmanProps] = useState([]);
    const [showAddToPlan, setShowAddToPlan] = useState(false);
    const [selectedPropts, setSelectedPropts] = useState({});
    const [selectedAptms, setSelectedAptms] = useState({});
    const [reload, setReload] = useState(false);
    const [modalToggle, setModalToggle] = useState(false);

    const user = useSelector((state) => state.auth.user);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllProperties());
        fetchPropAptsWithPlans();
    }, [reload]);

    const fetchPropAptsWithPlans = () => {
        propertyServiceApi()
            .get(`/property/ownerPropAptsWithPlans/${user.liveizy_id}`)
            .then((data) => {
                const filtered = data.data.payload.filter((item) => item.apartment[0]);
                setLipmanProps(filtered);
            })
            .catch((err) => {
                // console.log(err);
            });
    };

    const classes = propertyListingStyles();

    return (
        <div className={classes.root + " " + "property-listing-page"} id="lipman-listing-page">
            {Object.values(selectedAptms)[0] && <PlanInitiateComponent displayModal={setModalToggle} />}
            {modalToggle && (
                <CreatePlanModal
                    displayModal={setModalToggle}
                    plan="LiPMaN"
                    aptmsArray={Object.keys(selectedAptms)}
                    setShowAddToPlan={setShowAddToPlan}
                    setReload={setReload}
                />
            )}

            <div style={{ marginBottom: 20 }}>
                <Back redirectUrl="/property/list" />
            </div>

            <PageHeader>
                <h3 style={{ textTransform: "unset" }}>{showAddToPlan ? "LiPMaN Plan" : "Create LiPMaN Plan"}</h3>
            </PageHeader>

            {/**SEARCH BOXES */}
            <div className={"plp-lipman-filter-block" + " " + classes.search_container}>
                <div className={classes.right_section}>
                    <Button className={classes.btn} onClick={() => history.push("/property/subscription/lipman")}>
                        <span className="btn_name">View Plan</span>
                    </Button>
                </div>
            </div>

            {/**TABLE */}
            <section style={{ marginTop: "40px" }}>
                {lipmanProps.map((item, idx) => {
                    return (
                        <GeneralPropComponent
                            key={idx}
                            property={item}
                            selectedPropts={selectedPropts}
                            setSelectedPropts={setSelectedPropts}
                            selectedAptms={selectedAptms}
                            setSelectedAptms={setSelectedAptms}
                            plan="LiPMaN"
                        />
                    );
                })}
            </section>
        </div>
    );
};

export default LipmanPage;
