import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Header from "../../../components/common/Header";
import TenantsListTable from "./TenantsListTable";

const styles = {
    root: {
        // margin: "100px",
    }
};

const ListPropertyManagerIncome = (props) => {
    return (
        <div className={props.classes.root}>
            <Header title="Tenants" export="Export" newIncome="New Income" />
            <TenantsListTable />
        </div>
    );
};

export default withStyles(styles)(ListPropertyManagerIncome);
