import React from "react";
import { RiWhatsappFill } from "react-icons/ri";
import LinkIconWrapper from "./LinkIconWrapper";

const WhatsappLinkIcon = ({ size = "1.72rem" }) => {
  return (
    <>
      <LinkIconWrapper target="_blank" href="https://wa.me/2348067730381">
        <RiWhatsappFill color="#60d669" size={size} />
      </LinkIconWrapper>
    </>
  );
};

export default WhatsappLinkIcon;
