import React, { memo, useCallback } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";

import { useApartmentFormStyles } from "./apartmentForm.styles";
import { useHistory } from "react-router-dom";

import MultipleImagesUploader from "#/components/multipleImagesUploader/multipleImagesUploader.component";
import ArrowDropdown from "#/components/customDropdowns/arrowDropdown/arrowDropdown";

import CustomInput from "./subcomponents/customInput/customInput";
import ApartmentDetailsDropdown from "./subcomponents/apartmentDetailsDropdown/apartmentDetailsDropdown";
// import CreateOtherChargesForm from "./subcomponents/createOtherCharges/createOtherCharges";
import VacantForm from "./subcomponents/vacant/vacant";

import { useSelector } from "react-redux";

const ApartmentForm = ({
  allPropertyInfo,
  handleSetCoverImage,
  propertyCoverImagePreview,
  apartmentId,
  apartmentStatusHelpers,
  apartmentPaymentFreqHelpers,
  apartmentFormFields,
  apartmentPositionHelpers,
  handleOnChangeApartmentInput,
  addMultipleApartmentImagesHelpers,
  creatingApartment,
  advertStatus,
  handleSetAdvertStatus,
  apartDetailsDropdownHelpers,
  handleCreateApartment,
  apartmentErrors,
  isForEdit = false,
  apartmentCharges = [],
  previouslyUploadedImages,
  handleRemovePreviouslyUploadedImage,
  styles = {},
  apartmentFloorHelpers,
  updatingApartment
}) => {
  const classes = useApartmentFormStyles();

  const { apartmentSchedules } = useSelector((state) => state.schedules);
  // const apartmentChargesHelpers = useApartmentCharges(apartmentId);

  const history = useHistory();

  //Status of apartment(soa)
  const {
    dropdownOptions: soaDo,
    selectedOption: apartmentStatus,
    handleSetSelectedOption: soaHsso
  } = apartmentStatusHelpers;

  //Payment Frequency (pf)
  const {
    dropdownOptions: pfDo,
    selectedOption: paymentFrequency,
    handleSetSelectedOption: pfHsso
  } = apartmentPaymentFreqHelpers;

  //Position OF Apartment
  const {
    dropdownOptions: poaDo,
    selectedOption: poaSo,
    handleSetSelectedOption: poaHsso
  } = apartmentPositionHelpers;

  //Apartment Floor
  const {
    dropdownOptions: afDo,
    selectedOption: afSo,
    handleSetSelectedOption: afHsso
  } = apartmentFloorHelpers;

  const { rent, entrance, rentDate, otherDesc } = apartmentFormFields;

  const handleAddBills = useCallback(() => {
    history.push(
      `/create/${allPropertyInfo?.property_id}/bill-settings/${apartmentId}/${allPropertyInfo?.property_id}`
    );
  }, [history, apartmentId, allPropertyInfo?.property_id]);

  return (
    <div className={classes.formFields} style={styles}>
      <section className="first-section">
        {isForEdit && (
          <h3 className="first-section__title" style={{ fontWeight: "800" }}>
            EDIT APARTMENT
          </h3>
        )}
        <h3 className="first-section__title" style={{ textTransform: "capitalize" }}>
          {allPropertyInfo?.title}
        </h3>

        {/* Cover image */}
        <div className={classes.imageContainer}>
          <div
            className="image"
            style={{
              backgroundImage: isForEdit
                ? `url(${allPropertyInfo?.property_images[0]})`
                : `url(${
                    propertyCoverImagePreview || allPropertyInfo?.property_images[0]
                  })`
            }}>
            <input
              name="propertyCoverImagePreview"
              type="file"
              accept=".jpg, .jpeg, .png"
              onChange={handleSetCoverImage}
            />
          </div>
        </div>

        {/* Property Address */}
        <div className={classes.propertyAddressContainer}>
          <div className="section">
            <h3>Address Street</h3>
            <p>{allPropertyInfo?.street}</p>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="section">
              <h3>House Number</h3>
              <p>{allPropertyInfo?.house_number}</p>
            </div>
            <div className="section">
              <h3>State</h3>
              <p>{allPropertyInfo?.state}</p>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="section">
              <h3>Property ID</h3>
              <p>{allPropertyInfo?.property_id}</p>
            </div>
            <div className="section">
              <h3>LCDA</h3>
              <p>{allPropertyInfo?.lcda}</p>
            </div>
          </div>
          <div className="section">
            <h3>Local Government</h3>
            <p>{allPropertyInfo?.lga}</p>
          </div>
        </div>
      </section>

      {/* Apartment Details */}
      <section className="second-section">
        <form noValidate className={classes.apartment}>
          {/* Apartment Number (LAID) */}
          <CustomInput
            name="laid"
            label="LAID"
            placeholder={`${apartmentId}`}
            value={`${apartmentId}`}
            disabled={true}
          />

          {/* Status of Apartment */}
          <ArrowDropdown
            name="availabilityOfProperty"
            label="Status of Apartment"
            placeholder="select"
            options={soaDo}
            selectedOption={apartmentStatus}
            handleSetSelectedOption={soaHsso}
            error={apartmentErrors.apartmentStatus}
          />

          {/* Payment Frequency */}
          <ArrowDropdown
            name="paymentFrequency"
            label="Select Payment Frequency"
            placeholder="Select Unit"
            options={pfDo}
            selectedOption={paymentFrequency}
            handleSetSelectedOption={pfHsso}
            error={apartmentErrors.paymentTerm}
          />

          {/* Other details */}
          <section className="apartment-name-details">
            {/* Apartment Details */}
            <div className="apartment-name-details__dropdown-wrapper">
              <ApartmentDetailsDropdown
                name="apartmentDetails"
                label="Apartment Details"
                apartDetailsDropdownHelpers={apartDetailsDropdownHelpers}
                error={apartmentErrors.apartmentDetails}
              />
            </div>

            {/* Annual Rent Price */}
            <div className="apartment-name-details__input-wrapper">
              <CustomInput
                name="rent"
                label="Annual Rent Price"
                placeholder="100,000"
                value={rent}
                handleOnChange={handleOnChangeApartmentInput}
                errors={apartmentErrors.rent}
              />
            </div>
          </section>

          {/* Position, floor, entrance */}
          <section className="rent-floor-entrance">
            <div className="rent-floor-entrance__input-wrapper">
              <ArrowDropdown
                name="apartmentPosition"
                label="Pos. of Apartment"
                placeholder=""
                options={poaDo}
                selectedOption={poaSo}
                handleSetSelectedOption={poaHsso}
                error={apartmentErrors.position}
              />
            </div>
            <div className="rent-floor-entrance__input-wrapper">
              <ArrowDropdown
                name="floor"
                label="Apartment Floor"
                placeholder=""
                options={afDo}
                selectedOption={afSo}
                handleSetSelectedOption={afHsso}
                error={apartmentErrors.floor}
              />
            </div>
            <div className="rent-floor-entrance__input-wrapper">
              <CustomInput
                name="entrance"
                label="Entrance"
                placeholder="4"
                value={entrance}
                handleOnChange={handleOnChangeApartmentInput}
                errors={apartmentErrors.entrance}
              />
            </div>
          </section>

          {/**Rent Payment Date for Apartment with status occuiped #868686 */}
          {apartmentStatus?.mainValue === "occupied" && (
            <section className="rent-payment-date">
              <div className="rent-payment-date__input-wrapper">
                <CustomInput
                  name="rentDate"
                  label="Rent Payment Date"
                  placeholder="04/09"
                  type="date"
                  value={rentDate}
                  handleOnChange={handleOnChangeApartmentInput}
                  errors={apartmentErrors.rentDate}
                />
              </div>
            </section>
          )}

          {/* Other Description */}
          <section className="other-description">
            <h4>Other Description</h4>
            <textarea
              name="otherDesc"
              id="otherDesc"
              cols=""
              rows=""
              value={otherDesc}
              onChange={handleOnChangeApartmentInput}></textarea>
          </section>

          {/* Apartment Images */}
          <section className="apartment-images">
            <MultipleImagesUploader
              header="Apartment Images"
              isForApartment={true}
              multipleImagesHelpers={addMultipleApartmentImagesHelpers}
              handleRemovePreviouslyUploadedImage={handleRemovePreviouslyUploadedImage}
              previouslyUploadedImages={previouslyUploadedImages}
            />
          </section>

          {/* Other charges */}
          {isForEdit && (
            // <CreateOtherChargesForm
            //   apartmentId={apartmentId}
            //   apartmentChargesHelpers={apartmentChargesHelpers}
            //   apartmentCharges={apartmentCharges}
            //   classes={classes}
            // />
            <Button
              onClick={handleAddBills}
              variant="contained"
              color="primary"
              className="tw-capitalize tw-px-10 tw-py-5">
              Edit Charges
            </Button>
          )}

          {/* Display UI based on status of apartment */}
          {/* {apartmentStatus?.mainValue === "occupied" && (
                        <OccupiedForm
                            classes={classes}
                            apartmentId={apartmentId}
                            allPropertyInfo={allPropertyInfo}
                            rentDate={rentDate}
                        />
                    )} */}
          {isForEdit && apartmentStatus?.mainValue === "vacant" && (
            <VacantForm
              classes={classes}
              apartmentId={apartmentId}
              propertyId={allPropertyInfo?.property_id}
              apartmentSchedules={apartmentSchedules}
            />
          )}

          {/* Advertise  */}
          {isForEdit && apartmentStatus?.mainValue === "vacant" && (
            <section className="advertise-apartment">
              <input
                type="checkbox"
                onChange={() => handleSetAdvertStatus(apartmentSchedules)}
                checked={advertStatus}
                id="select"
              />
              <label htmlFor="select">Advertise apartment</label>
            </section>
          )}

          {/* {!isForEdit && (
            <h6
              className={classes.addAnotherApartment}
              onClick={(e) => handleCreateApartment(e, true, false, apartmentId)}>
              Add Another apartment
            </h6>
          )} */}
        </form>

        {isForEdit ? (
          <div className={classes.buttonContainer}>
            <button
              onClick={(e) => handleCreateApartment(e, false, true, apartmentId)}
              className={classes.btnBlue}>
              {updatingApartment ? (
                <CircularProgress size={16} color="inherit" />
              ) : (
                "Update"
              )}
            </button>
          </div>
        ) : (
          <div className={classes.buttonContainer}>
            <button
              onClick={(e) => handleCreateApartment(e, false, false, apartmentId)}
              className={classes.btnBlue}>
              {creatingApartment ? (
                <CircularProgress size={16} color="inherit" />
              ) : (
                "Finish"
              )}
            </button>
          </div>
        )}
      </section>
    </div>
  );
};

ApartmentForm.propTypes = {
  allPropertyInfo: PropTypes.any,
  handleSetCoverImage: PropTypes.func,
  propertyCoverImagePreview: PropTypes.string,
  apartmentId: PropTypes.string,
  apartmentStatusHelpers: PropTypes.any,
  apartmentPaymentFreqHelpers: PropTypes.any,
  apartmentFormFields: PropTypes.shape({
    rent: PropTypes.string,
    floor: PropTypes.string,
    entrance: PropTypes.string,
    otherDesc: PropTypes.string,
    apartmentStatus: PropTypes.string,
    rentDate: PropTypes.string
  }),
  apartmentPositionHelpers: PropTypes.any,
  handleOnChangeApartmentInput: PropTypes.func,
  addMultipleApartmentImagesHelpers: PropTypes.any,
  creatingApartment: PropTypes.bool,
  advertStatus: PropTypes.bool,
  handleSetAdvertStatus: PropTypes.func,
  apartDetailsDropdownHelpers: PropTypes.any,
  handleCreateApartment: PropTypes.func
};

export default memo(ApartmentForm);
