import {
    GET_BANKS_SUCCESS,
    PAYSTACK_VERIFY_ACCOUNT,
    PAYSTACK_VERIFY_ACCOUNT_FAIL,
    PAYSTACK_VERIFY_ACCOUNT_SUCCESS
} from "../constants";

const initialState = {
    verifiedAccount: [],
    bankcode: null,
    paystackStart: false,
    banks: [],
    banksInNigeria: []
};

const paystackReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BANKS_SUCCESS:
            return {
                banks: action.banks,
                banksInNigeria: action.banksInNigeria,
                bankcode: action.bankcode
            };
        case PAYSTACK_VERIFY_ACCOUNT:
            return {
                ...state,
                paystackStart: true
            };
        case PAYSTACK_VERIFY_ACCOUNT_SUCCESS:
            return {
                ...state,
                verifiedAccount: action.verifiedAccount,
                paystackStart: false
            };
        case PAYSTACK_VERIFY_ACCOUNT_FAIL:
            return {
                ...state,
                paystackStart: false
            };

        default:
            return state;
    }
};

export default paystackReducer;
