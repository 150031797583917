import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useStyles } from "./rightStyles";
import { TransactionTable } from "./table";
// import Secondcolumn from "../../../components/wallet/secondcolumn";
// import { IzyinvestPlans } from "../../../components/izyinvestPlans/izyinvestPlans";
// import Izyinvestcontent from "../../../components/izyinvestdashboard/izyinvestcontent";
import { SAVE_CURRENT_PLANID } from "../../../store/constants";
import { useDispatch, useSelector } from "react-redux";
// import Secondcolumnlayout from "../../../components/wallet/secondcolumnlayout";
import Secondcolumn from "../izyinvest-settings/secondcolumn";
import { IzyinvestPlans } from "../izyinvestPlans/izyinvestPlans";
import Secondcolumnlayout from "./secondcolumnlayout";
import Izyinvestcontent from "../izyinvestdashboard/izyinvestcontent";
import { selectIzyPayTransactions } from "#/store/actions/izypay.actions";

const Right = (props) => {
  const classes = useStyles();

  const { newPlanId } = useSelector((store) => store.izyinvest);
  const izyPayTransactions = useSelector(selectIzyPayTransactions);

  const { settings, closeSettings } = props;

  const [currentP, setCurrentP] = useState(newPlanId);
  const [open, setOpen] = useState(false);
  const [planDetail, setPlandetail] = useState(false);
  const [page, setPage] = useState(0); // this state is for izyInvest plans pagination.
  // to maintain the page state after a user views a single plan.
  // const [settings, setSettings] = useState(false);
  const [activePlan, setActivePlan] = useState("");
  const [lastFunded, setLastFunded] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    setCurrentP(newPlanId);
  }, [newPlanId]);
  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };
  const setPlan = (planId, planName, date, minimumDep) => {
    setActivePlan(planName);
    setCurrentP(planId);
    setPlandetail(true);
    setLastFunded(date);
    dispatch({
      type: SAVE_CURRENT_PLANID,
      newPlanId: planId,
      minimumDep: minimumDep
    });
  };
  const dashBoard = () => {
    setPlandetail(false);
  };

  let renderedLayouts;
  if (props.showIzyinvest) {
    if (settings) {
      renderedLayouts = (
        <Secondcolumn close={closeSettings} izyinvest={props.izyinvest} />
      );
    } else if (planDetail) {
      renderedLayouts = (
        <Izyinvestcontent
          dashboard={dashBoard}
          activePlan={activePlan}
          date={lastFunded}
        />
      );
    } else {
      renderedLayouts = (
        <IzyinvestPlans page={page} setPage={setPage} setPlan={setPlan} />
      );
    }
  } else renderedLayouts = <TransactionTable transactions={izyPayTransactions} />;
  return (
    <Grid item xs={12} md={12} sm={12} className={classes.body}>
      <Secondcolumnlayout>{renderedLayouts}</Secondcolumnlayout>
    </Grid>
  );
};

export default Right;
