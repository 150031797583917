import { MenuItem, keyframes, styled } from "@mui/material";
import React from "react";
import Select from "react-select";

const CustomSelectField = ({ label, icon, options, ...otherSelectProps }) => {
  return (
    <Container>
      <Label>{label}</Label>
      <InputWrapper>
        {icon ? <Icon>{icon}</Icon> : null}
        <SelectField styles={selectFieldStyles} options={options} {...otherSelectProps} />
      </InputWrapper>
    </Container>
  );
};

export default CustomSelectField;

const Container = styled("div")`
  width: 100%;
  height: 80px;
`;

const Icon = styled("div")`
  color: #052137;
`;

const SelectField = styled(Select)`
  border: transparent;
  width: 100%;
  height: 100%;
  color: black;
  background: transparent;
  outline: none;
`;

const selectFieldStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderColor: "transparent",
    boxShadow: "none",
    background: "transparent",
    "&:hover": {
      borderColor: "transparent"
    }
  })
};

const InputWrapper = styled("div")`
  display: flex;
  padding: 0 5px;
  gap: 5px;
  align-items: center;
  width: 100%;
  height: 40px;
  font-size: 15px;
  background: linear-gradient(
        103deg,
        rgba(245, 245, 245, 1) 0%,
        rgba(242, 243, 244, 1) 50%,
        rgba(251, 251, 251, 1) 100%
      )
      padding-box,
    linear-gradient(
        103deg,
        rgba(223, 224, 224, 1) 0%,
        rgba(242, 243, 244, 1) 50%,
        rgba(219, 219, 219, 1) 100%
      )
      border-box;
  border: 2px solid transparent;
  border-radius: 10px;
`;

const Label = styled("p")`
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 5px;
  text-align: left;
  color: rgba(5, 33, 55, 0.7);
`;
