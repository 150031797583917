import React, { useState } from "react";
import "./layout.styles.scss";
import Body from "./body";
import Sidebar from "../siderbar/sidebar";
import { setToLocalStorage, getFromLocalStorage } from "./../utils/util";
import useMobile from "../../hooks/util-hooks/useMobile";
import useBreakPoint from "../../hooks/util-hooks/useBreakPoint";

const Layout = ({ children }) => {
    const [toggle, setToggle] = useState(() => getFromLocalStorage("bodyState") || "full");
    const [mobileToggle, setMobileToggle] = useState(false);
    const { isMobile } = useMobile();

    const breakPointValue = "990px";
    const { isLayout } = useBreakPoint(breakPointValue);

    const handleToggle = () => {
        // This is checking if the user is on a mobile phone.
        if (isMobile) {
            document.body.classList.remove("collapsed");
            setMobileToggle((prevState) => !prevState);
            return;
        }

        //Adding the css class to the sidebar
        if (toggle === "full" && !isMobile) {
            setToggle("collaspe");
            document.body.classList.add("collapsed");
            setToLocalStorage("bodyState", "collaspe");
        } else if (toggle === "collaspe" && !isMobile) {
            setToggle("full");
            document.body.classList.remove("collapsed");
            setToLocalStorage("bodyState", "full");
        }
    };

    React.useEffect(() => {
        if (toggle === "collaspe") {
            document.body.classList.add("collapsed");
        } else if (toggle === "full") {
            document.body.classList.remove("collapsed");
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useLayoutEffect(() => {
        /**
         * When the user resize the browser to a media breakpoint
         * of 990px, the sidebar automatically collaspes by itself
         */
        if (isLayout) {
            document.body.classList.add("collapsed");
            setToggle("collaspe");
            setToLocalStorage("bodyState", "collaspe");
        }
    }, [isLayout]);

    return (
        <React.Fragment>
            <div className="wrapper">
                <Sidebar toggle={toggle} mobile={mobileToggle} setMobileToggle={setMobileToggle} />
                <Body toggle={toggle} onClickHandler={handleToggle} isMobile={isMobile} mobileToggle={mobileToggle}>
                    {children}
                </Body>
            </div>
        </React.Fragment>
    );
};

export default Layout;
