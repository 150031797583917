import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';



const styles = theme => ({
    root: {
        color: '#032F4A',
        padding: '20px 23px',
        [theme.breakpoints.down('350')]: {
            padding: '20px 15px',
        },
    },
    header: {
        marginBottom: 15,
    },
    invoiceContainer: {
        padding: '35px',
        backgroundColor: '#d2e4f7',
        marginBottom: 15,
        borderRadius: 5,
        [theme.breakpoints.down('900')]: {
            marginTop: 10,
        },
        '& .invoice-container': {
            '& .invoice-text': {
                fontWeight: 700,
                fontSize: 14,
                color: 'inherit',
            },
            '& .invoice-span-text': {
                display: 'block',
                fontSize: 11,
                marginBottom: 2,
                color: 'light-grey',
            },
            '& .invoice-paid': {
                border: '4px solid #5da886',
                textTransform: 'uppercase',
                fontWeight: 'bold',
                fontSize: 22,
                padding: '4px 20px',
                height: 40,
                width: 80,
                borderRadius: 5,
                marginLeft: 40,
                transform: 'rotate(15deg)',
                '& p': {
                    color: '#5da886 !important',
                    fontWeight: 800,
                    textAlign: 'center',
                } 
            },
        },
    },
    linkContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 5,
        marginBottom: 20,
        '& a': {
            textDecoration: 'none',
            padding: '8px 20px',
            [theme.breakpoints.down('640')]: {
                padding: '8px 15px',
            },
            borderRadius: 5,
            fontSize: 11,
        },
        '& .download-link a': {
            border: '1px solid #339fcc',
            color: '#339fcc',
        },
        '& .edit-delete-link': {
            '& a:nth-child(1)': {
                color: '#339fcc',
                marginRight: 17,
                [theme.breakpoints.down('350')]: {
                    marginRight: 0,
                },
            },
            '& a:nth-child(2)': {
                border: '1px solid #db3535',
                color: '#db3535',
            },
        },
    },
    hrLine: {
        borderTop: '2px solid #d2e4f7',
        marginBottom: 20,
    },
    infoContainer: {
        padding: 10,
        color: '#032F4A',
        '& .contact-container': {
            display: 'flex',
            marginBottom: 25,
            '& div:first-child': {
                marginRight: 30,
            },
            '& .contact-text': {
                fontSize: 11,
            },
            '& .invoice-contact-text': {
                fontSize: 13,
                fontWeight: 700,
                color: 'inherit',
            },
        }
    },
    wrapper: {
        padding: 10,
        color: '#032F4A',
        '& .payment': {
            fontSize: 11,
            color: 'inherit',
        },
        '& .payment-detail': {
            fontSize: 13,
            fontWeight: 700,
            color: 'inherit'
        },
        '& .payment-container': {
            backgroundColor: '#d2e4f7',
        },
        '& .total-payment-container': {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 25,
        },
        '& .amount': {
            textAlign: 'right',
        },
        '& .payment-text': {
            paddingTop: 16,
            color: '#032F4A',
        },
    },
    table: {
        marginBottom: 25,
        '& tr': {
            borderBottom: '2px solid #d2e4f7 !important',
        },
        '& .MuiTableCell-root': {
            padding: 16,
            borderBottom: 0,
        },
        '& .tr-text': {
            fontSize: 12,
            fontWeight: 800,
        },
        '& .tr-border': {
            borderRight: '2px solid #d2e4f7 !important',
        },
    },
});

const InvoiceDetail = (props) => {
    return <div className={props.classes.root}>
                <header className={props.classes.header}>
                    <Typography variant="h5" component="h1">
                    <b>Invoice:324445</b>
                    </Typography>
                </header>
                <div className={props.classes.invoiceContainer}>
                    <div className="invoice-container">
                        <Grid container spacing={3} className={props.classes.grid}>
                            <Grid item md={3}>
                                <div>
                                    <Typography className="invoice-text" variant="body1" component="p">
                                        INVOICE
                                    </Typography>
                                    <Typography gutterBottom className="invoice-text" variant="body1" component="p">
                                        324445
                                    </Typography>
                                    <span className="invoice-span-text">Chevron Drive Estate</span>
                                </div>
                            </Grid>
                            <Grid item md={3}>
                                <div>
                                    <span className="invoice-span-text">Invoice generated on</span>
                                    <Typography gutterBottom className="invoice-text" variant="body1" component="p">
                                        Jun18,2020
                                    </Typography>
                                    <span className="invoice-span-text">Due</span>
                                    <Typography className="invoice-text" variant="body1" component="p">
                                        Jun18,2020
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item md={6}>
                                <div className="invoice-paid">
                                    <Typography variant="body1" component="p">
                                        Paid
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div className={props.classes.linkContainer}>
                    <div className="download-link">
                        <a href="www.google.com">Download</a>
                    </div>
                    <div className="edit-delete-link">
                        <a href="www.google.com">Edit Invoice</a>
                        <a href="www.google.com">Delete</a>
                    </div>
                </div>
                <hr className={props.classes.hrLine}/>
                <div className={props.classes.infoContainer}>
                    <div className="contact-container">
                        <div>
                            <span className="contact-text">Subject</span>
                            <Typography className="invoice-contact-text" variant="body1" component="p">
                                Rent due on July 18,2020
                            </Typography>
                        </div>
                        <div>
                            <span className="contact-text">Sent To</span>
                            <Typography className="invoice-contact-text" variant="body1" component="p">
                                Kelechi Oleka
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className={props.classes.wrapper}>
                    <TableContainer>
                        <Table className={props.classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="tr-text tr-border">Item</TableCell>
                                    <TableCell className="tr-text tr-border" align="center"></TableCell>
                                    <TableCell className="tr-text tr-border" align="center">Quality</TableCell>
                                    <TableCell className="tr-text tr-border" align="center">Rate</TableCell>
                                    <TableCell className="tr-text" align="center">Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className="tr-text tr-border">Rent</TableCell>
                                    <TableCell className="tr-text tr-border" align="center">Rent due on July 17,2020</TableCell>
                                    <TableCell className="tr-text tr-border" align="center"></TableCell>
                                    <TableCell className="tr-text tr-border" align="center"></TableCell>
                                    <TableCell className="tr-text" align="center"></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                            <Typography className="payment payment-text" variant="body1" component="p">
                            Payment Received
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={9} container>
                            <Grid item xs={12} md={12}>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="payment-detail">Payer</TableCell>
                                                <TableCell className="payment-detail" align="center">Submitted</TableCell>
                                                <TableCell className="payment-detail" align="center">Deposited</TableCell>
                                                <TableCell className="payment-detail" align="center">Method</TableCell>
                                                <TableCell className="payment-detail" align="center">Amount</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow className="payment-container">
                                                <TableCell className="payment" align="center">Kelechi Oleka</TableCell>
                                                <TableCell className="payment" align="center">July 04,2020</TableCell>
                                                <TableCell className="payment" align="center">July 04,2020</TableCell>
                                                <TableCell className="payment" align="center">izypay</TableCell>
                                                <TableCell className="payment" align="center">&#x20A6;20,0000</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div className="total-payment-container">
                                    <div>
                                        <Typography className="payment-detail" variant="body1" component="p">
                                        Description
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography className="payment amount" variant="body1" component="p">
                                        Total Due:&#x20A6;17000
                                        </Typography>
                                        <Typography className="payment amount" variant="body1" component="p">
                                        Total Due:&#x20A6;17000
                                        </Typography>
                                        <Typography className="payment amount" variant="body1" component="p">
                                        Remaining Balance:&#x20A6;17000
                                        </Typography>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
}

export default withStyles(styles)(InvoiceDetail);
