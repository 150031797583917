import { makeStyles, Typography, withStyles } from '@material-ui/core';

export const TypographyText = withStyles((theme) => ({
  root: {},
  subtitle1: {
    color: '#1461A2',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  h6: {
    color: '#043B5C',
    fontWeight: 'bolder',
    [theme.breakpoints.down('xs')]: {
        fontSize: 17,
      },
  },
  caption: {
    fontSize: 10,
    padding: '0 6%',
    lineHeight: ' 12px',
    '& .MuiSvgIcon-root': {
      height: 11,
      marginBottom: '3px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 8,
    },
  },
  body2: {
    fontSize: 15,
    fontWeight: 'bold',
    color: '#043B5C',
  },
  h5: {
    fontSize: 22,
    color:'#043B5C',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
}))(Typography);

export const useStyles = makeStyles((theme) => ({
  boxContainer: {
    height: 130,
    border: '1px solid #6EB5DF72',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    [theme.breakpoints.down('xs')]: {
      height: 118,
    },
  },
  boxContainer1: {
    height: 130,
    border: '1px solid #6EB5DF72',
    alignItems: 'center',
    borderRadius: '10px',
    marginBottom: '16%',
    [theme.breakpoints.down('xs')]: {
      height: 118,
    },
  },
  createPlan: {
    marginLeft: '4%',
    marginBottom: '3%',
    marginRight: '4%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '13%',
    },
  },
  PlusIcon: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    fontSize: '25px',
    color: '1461A2 !important',
    bottom: '14px',
    margin: '0 auto',
    height: '20px',
    width: 'fit-content',
  },
  purple: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '0 6%',
    // [theme.breakpoints.down('xs')]: {
    //   display: 'flex',
    //   textAlign: 'center',
    //   flexFlow: 'row wrap',
    //   justifyContent: 'center',
    //   padding: 0,
    // },
    [theme.breakpoints.down('md')]: {
      padding: '0 4%',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      textAlign: 'center',
      flexFlow: 'row wrap',
      justifyContent: 'center',
      padding: '0 2%',
    },
  },
  red: {
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '4%',
  },
  planTag: {
    backgroundColor: '#4786FF',
    color: 'white',
    padding: '2px 1% 0',
    // fontSize: '11px',
    fontWeight: 'bold',
    marginTop: '4%',
  },
  vertical: {
    borderLeft: 'thin solid #043B5C',
    margin: '3% 0',
  },
}));
