import { makeStyles } from "@material-ui/core/styles";

export const useListingStyles = makeStyles((theme) => ({
    wrapper: {
        width: "100%",
        backgroundColor: "#F4F5F7",

        [theme.breakpoints.between("0", "992")]: {
            overflowX: "scroll",
            padding: "15px"
        }
    },
    cardContainer: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center"
    }
}));
