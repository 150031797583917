import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import combineReducers from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import JwtDecode from "jwt-decode";
import { LOGOUT } from "./constants";
import { logout } from "./actions/auth.actions";

const middlewares = [thunk];

const initialState = {};

// Check for expired token and logout user if Expired
const checkTokenExpirationMiddleware = (store) => (next) => (action) => {
  if (action.type === LOGOUT) {
    return;
  }
  const token =
    JSON.parse(localStorage.getItem("data")) &&
    JSON.parse(localStorage.getItem("data"))["token"];
  if (token !== null && token !== undefined) {
    if (JwtDecode(token).exp < Date.now() / 1000) {
      const { user } = JSON.parse(localStorage.getItem("data"));
      const { liveizy_id } = user;
      const pathname = window.location.pathname;
      const data = { liveizy_id, pathname };
      localStorage.setItem("Last Visited", JSON.stringify(data));
      store.dispatch(logout());
      // next(action);
    }
    next(action);
  } else next(action);
};

const devTools =
  process.env.NODE_ENV === "production"
    ? applyMiddleware(...middlewares)
    : composeWithDevTools(applyMiddleware(...middlewares));

const store = createStore(combineReducers, initialState, devTools);

export default store;
