import { makeStyles } from "@material-ui/core";

export const useMultipleImages = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down("sm")]: {
            marginBottom: "30px"
        },

        "& label": {
            display: "block",
            marginBottom: "10px",
            fontSize: "14px",
            fontWeight: "700"
        },

        "& h6": {
            color: "#043B5C7F",
            fontSize: "10px",
            marginBottom: "10px"
        }
    },
    previousImagesWrapper: {
        display: "flex",
        marginBottom: "15px !important"
    },
    previousImage: {
        width: "90px",
        height: "90px",
        marginBottom: "5px",
        borderRadius: "8px",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        marginRight: "20px !important",
        position: "relative",

        "& .cancel-icon": {
            position: "absolute",
            top: 0,
            right: 0,
            width: 21,
            height: 21,
            borderRadius: "50%",
            backgroundColor: "#fff",
            cursor: "pointer",

            "&:before": {
                content: '""'
            },

            "&:after": {
                content: '""'
            },

            "& img": {
                position: "absolute"
            }
        }
    },
    inputContainer: {
        "& input": {
            borderRadius: "5px",
            border: "1px solid #043B5C69",
            padding: "10px 25px",
            marginRight: "20px",
            cursor: "pointer",
            overflow: "hidden",
            marginBottom: "15px",
            width: "100%",
            color: "#fff",

            [theme.breakpoints.down("sm")]: {
                marginBottom: "20px",
                width: "100%"
            }
        }
    },
    btnRed: {
        backgroundColor: "#CF0713AD",
        color: "#fff",
        borderRadius: "5px",
        border: "1px solid #CF0713AD",
        padding: "11px 25px !important",
        fontSize: "11px",
        cursor: "pointer"
    },
    imagesContainer: {
        display: "flex",
        width: "100%",
        overflow: "auto",

        "&::-webkit-scrollbar": {
            height: "1px"
        },

        "&::-webkit-scrollbar-track": {
            background: "transparent"
        },

        "&::-webkit-scrollbar-thumb": {
            background: "#DEEDFF"
        },

        "& .image": {
            marginRight: "20px",
            backgroundColor: "#F4F5F7",
            borderRadius: "8px",
            padding: "6px",
            position: "relative",

            "& .cancel-icon": {
                position: "absolute",
                top: 0,
                right: 0,
                width: 21,
                height: 21,
                borderRadius: "50%",
                backgroundColor: "#fff",
                cursor: "pointer",

                "&:before": {
                    content: '""'
                },

                "&:after": {
                    content: '""'
                }
            },

            "&__photo": {
                width: "90px",
                height: "90px",
                marginBottom: "5px",
                borderRadius: "8px",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat"
            },

            "&__title": {
                marginBottom: "2px",
                fontSize: "10px",
                width: "90px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
            },

            "&__size": {
                fontSize: "10px",
                marginBottom: "4px"
            }
        }
    }
}));
