import { makeStyles } from "@material-ui/core/styles";

export const lpmsStyles = makeStyles((theme) => ({
    topHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        "@media(max-width:576px)": {
            flexDirection: "column"
        },

        "& .header-text": {
            marginBottom: "0px",
            "& > h3": {
                textTransform: "uppercase",
                color: "#043B5C",
                fontFamily: "Open Sans-bold, sans-serif",
                fontSize: "1.6rem",
                fontWeigth: "bold",
                marginBottom: "0px"
            }
        },

        "& p": {
            textDecoration: "underline",
            fontSize: "1rem",
            color: "#043b5c"
        },

        "& .btn": {
            backgroundColor: "#4786FF",
            color: "#E8F0F9",
            textTransform: "none",
            borderRadius: "5px",
            color: "#F4F5F7",
            border: "1px solid #4786FF",
            padding: "10px 30px",
            fontSize: "13px",
            fontFamily: "Open Sans, sans-serif",
            marginRight: "10px"
        }
    },

    rows: {
        width: "70%",
        marginTop: "50px",

        "@media(max-width:968px)": {
            width: "100%"
        }
    },

    boxHeading: {
        background: "#FFFFFF",
        boxShadow: "0px 20px 30px #EFF5FF",
        borderRadius: "15px 15px 0px 0px",
        opacity: " 1",
        display: "flex",
        padding: "25px 20px 25px 50px",
        marginBottom: "5px",
        display: "grid",
        gridTemplateColumns: "60% auto",

        "@media(max-width:768px)": {
            padding: "20px 10px 20px 20px"
        },

        "& p": {
            fontFamily: "Open Sans-bold",
            color: "#043b5c",
            textTransform: "uppercase",
            fontSize: "1rem"
        }
    },

    boxContent: {
        background: "#FFFFFF",
        boxShadow: "0px 3px 6px #EFF5FF",
        borderRadius: "10px",
        opacity: " 1",
        display: "flex",
        // justifyContent: "space-between",
        padding: "20px 20px 20px 50px",
        marginBottom: "5px",
        alignItems: "center",
        display: "grid",
        gridTemplateColumns: "60% auto",

        "@media(max-width:768px)": {
            padding: "20px 10px 20px 20px"
        },

        "& .status_button": {
            // width:"32%",

            "@media(max-width:968px)": {
                // width:"50%",
            },

            "& .btn": {
                textTransform: "none",
                borderRadius: "5px",
                color: "#F4F5F7",
                padding: "10px 0px",
                fontSize: "13px",
                fontFamily: "Open Sans, sans-serif",
                marginRight: "5px",
                width: "119px",
                border: "none",
                "@media(max-width:768px)": {
                    marginBottom: "5px"
                }
            }
        },

        "& .box_user": {
            display: "inline-flex",

            "& img": {
                width: "45px",
                height: "inherit",
                borderRadius: "50px",
                marginRight: "5px",
                "@media(max-width:768px)": {
                    width: "60px",
                    height: "inherit"
                }
            },

            "& p": {
                fontSize: "0.7rem",
                marginLeft: "5px",
                alignSelf: "center",
                color: "#043B5C",

                "@media(max-width:768px)": {},

                "& span:nth-child(1)": {
                    fontSize: "1rem",
                    fontFamily: "Open Sans-Semibold, sans-serif",
                    "@media(max-width:768px)": { fontSize: "0.9rem" }
                },
                "& span:nth-child(2)": {
                    display: "block"
                }
            }
        }
    },

    sadIconButton: {
        backgroundColor: "#4786FF",
        color: "#E8F0F9",
        textTransform: "none",
        borderRadius: "5px",
        color: "#F4F5F7",
        border: "1px solid #4786FF",
        padding: "10px 30px",
        fontSize: "13px",
        fontFamily: "Open Sans, sans-serif",
        marginRight: "10px",

        "&:hover": {
            backgroundColor: "#4786FF"
        }
    }
}));
