import { makeStyles } from "@material-ui/core/styles";

export const userSectionStyles = makeStyles((theme) => ({
  root: {
    // width: "500px",
    height: "475px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: "50px",
    },
  },

  maxWidth: 360,

  cardhead: {
    padding: "20px 15px 20px 20px",
    marginTop: "0px",
    backgroundColor: "#E8F0F9",
    opacity: "1",

    "& span": {
      fontSize: "20px",
      fontWeight: "bold",
      color: "#043B5C",
    },
  },

  userList: {
    "& .MuiListItemText-primary": {
      fontSize: "16px",
      color: "#043B5C",
    },

    "& .MuiListItemText-secondary": {
      fontSize: "12px",
      color: "#043B5C7F",
    },
  },

  userIcon: {
    fontSize: "30px",
  },

  img: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  icon: {
    // margin: "2px 8px 2px 8px",
  },

  spinner: {
    display: "flex",
    justifyContent: "center",
  },
  text: {
    display: "flex",
    justifyContent: "center",
  },

  empty: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "1rem",
    marginBottom: "1rem",

    "& >p": {
      textAlign: "center",
      color: "#043B5C",
      fontSize: "19px",
      marginBottom: "20px",
    },

    "& >img": {
      marginBottom: "20px",
    },
  },

  button: {
    padding: "8px 40px",
    borderRadius: "30px",
    backgroundColor: "#4786FF",
    borderColor: "#4786FF",
    color: "#F4F5F7",
    textTransform: "capitalize",
    fontSize: "13px",
    width: "120px",
    cursor: "default !important",
    border: "none",

    [theme.breakpoints.down("md")]: {
      width: "90px",
      padding: "8px 20px",
    },

    // "&:hover": {
    //   color: "#F4F5F7",
    //   backgroundColor: "#4786FF",
    // },

    "&:focus": {
      outline: "none",
    },
  },
}));
