import { APARTMENT_PAYMENTS, ESTATE_PAYMENTS } from "../constants";

const { PAY_ESTATE_FEES_PENDING, PAY_ESTATE_FEES_SUCCESS, PAY_ESTATE_FEES_FAIL } = ESTATE_PAYMENTS;

const {
    PAY_RENT_FAIL,
    PAY_RENT_PENDING,
    PAY_RENT_SUCCESS,

    PAY_APARTMENT_CHARGES_FAIL,
    PAY_APARTMENT_CHARGES_PENDING,
    PAY_APARTMENT_CHARGES_SUCCESS,

    RENEW_RENT_FAIL,
    RENEW_RENT_PENDING,
    RENEW_RENT_SUCCESS,

    RESET_PAYMENT_STORE_DETAILS
} = APARTMENT_PAYMENTS;
const initialState = {
    paymentDetails: null,
    paying: false
};

//FOR APARTMENT
const paymentReducer = (state = initialState, action) => {
    const { type, payload, message } = action;

    switch (type) {
        case PAY_ESTATE_FEES_PENDING:
            return {
                ...state,
                paying: true
            };
        case PAY_ESTATE_FEES_SUCCESS:
            return {
                ...state,
                paying: false
            };
        case PAY_ESTATE_FEES_FAIL:
            return {
                ...state,
                paying: false
            };

        case PAY_RENT_PENDING:
            return {
                ...state,
                paying: true
            };
        case PAY_RENT_SUCCESS:
            return {
                ...state,
                paying: false
            };
        case PAY_RENT_FAIL:
            return {
                ...state,
                paying: false
            };

        case RENEW_RENT_PENDING:
            return {
                ...state,
                paying: true
            };
        case RENEW_RENT_SUCCESS:
            return {
                ...state,
                paying: false,
                paymentDetails: payload
            };
        case RENEW_RENT_FAIL:
            return {
                ...state,
                paying: false
            };

        case PAY_APARTMENT_CHARGES_PENDING:
            return {
                ...state,
                paying: true
            };
        case PAY_APARTMENT_CHARGES_SUCCESS:
            return {
                ...state,
                paying: false,
                paymentDetails: payload
            };
        case PAY_APARTMENT_CHARGES_FAIL:
            return {
                ...state,
                paying: false
            };

        case RESET_PAYMENT_STORE_DETAILS:
            return {
                paying: false,
                paymentDetails: null
            };
        default:
            return state;
    }
};

export default paymentReducer;
