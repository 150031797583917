export const styles = (theme) => ({
    root: {
        color: "#043B5C",
        padding: "20px 23px",
        [theme.breakpoints.down("700")]: {
            paddingTop: 10,
            padding: 0
        }
    },
    header: {
        marginBottom: 15,
        "& h1": {
            fontSize: "29px",
            [theme.breakpoints.down("640")]: {
                fontSize: 16
            }
        },
        [theme.breakpoints.down("640")]: {
            backgroundColor: "#fff",
            padding: "15px 25px",
            borderRadius: 5
        }
    },
    detailsContainer: {
        display: "grid",
        // gridTemplateColumns: `repeat(7, 1fr)`,
        gridTemplateRows: "auto",
        gridRowGap: 25,
        gridColumnGap: 2,
        gridTemplateAreas: `
            "img contact contact contact contact link link"
            "age age employment employment income income email"
        `,
        padding: "35px 20px",
        backgroundColor: "#EEF4FB",
        borderRadius: 5,
        marginBottom: 25,
        [theme.breakpoints.down("700")]: {
            padding: "20px 15px",
            display: "grid",
            // gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: "auto",
            gridRowGap: 15,
            gridTemplateAreas: `
                "img img"
                "contact contact"
                "age employment"
                "income email"
                "link link"
            `
        },
        "& .img-container, .contact-details-container,": {
            [theme.breakpoints.down("700")]: {
                display: "grid",
                placeContent: "center center"
            }
        },
        "& .age-wrapper, .employment-wrapper, .income-wrapper, .email-wrapper": {
            [theme.breakpoints.down("700")]: {
                marginLeft: 25
            }
        },
        "& .img-container": {
            gridArea: "img"
        },
        "& .contact-details-container": {
            gridArea: "contact",
            [theme.breakpoints.down("700")]: {
                textAlign: "center"
            }
        },
        "& .link-wrapper": {
            gridArea: "link",
            [theme.breakpoints.up("700")]: {
                // gridColumn: '7 / 7',
                marginLeft: 60
            }
        },
        "& .age-wrapper": {
            gridArea: "age",
            [theme.breakpoints.up("700")]: {
                gridColumn: "2 / 2"
            }
        },
        "& .employment-wrapper": {
            gridArea: "employment"
        },
        "& .income-wrapper": {
            gridArea: "income"
        },
        "& .email-wrapper": {
            gridArea: "email"
        },
        "& img": {
            width: "80px",
            borderRadius: "100%",
            display: "inline-block"
        },
        "& .applied-details": {
            fontSize: 14,
            color: "inherit",
            fontWeight: 600
        },
        "& .links-container": {
            [theme.breakpoints.down("700")]: {
                marginTop: 10,
                marginLeft: 25
            }
        },
        "& .btn": {
            textDecoration: "none",
            padding: "8px 20px",
            borderRadius: 5,
            fontSize: 13,
            color: "#fff",
            border: 0,
            [theme.breakpoints.down("700")]: {
                fontSize: 11
            }
        },
        "& .btn-approve": {
            backgroundColor: "#2adbc6",
            marginRight: 10
        },
        "& .btn-reject": {
            backgroundColor: "#db3535"
        },
        "& .applicant-detail": {
            display: "block",
            fontWeight: "bold",
            marginTop: 8,
            fontSize: 13,
            [theme.breakpoints.up("700")]: {
                fontSize: 14
            }
        },
        "& .detail-age": {
            marginTop: 0
        },
        "& .applicant-detail-email": {
            display: "block",
            fontSize: 11
        },
        "& .icon-text": {
            fontSize: 13,
            color: "#1461A2"
        }
    },
    report: {
        color: "#043B5C",
        padding: 25,
        borderRadius: 5,
        marginBottom: 30,
        boxShadow: "0px 0px 4px 0px rgba(210,228,247,1)",
        "& .background-title, background-details": {
            display: "block"
        },
        "& .background-title": {
            color: "inherit",
            fontSize: 11,
            [theme.breakpoints.up("700")]: {
                fontSize: 13
            }
        },
        "& .background-details": {
            color: "inherit",
            fontSize: 14,
            fontWeight: 600,
            [theme.breakpoints.down("700")]: {
                fontSize: 13
            }
        },
        "& .background-report": {
            marginBottom: 10
        },
        "& .report-text": {
            color: "inherit"
        },
        "& hr": {
            marginTop: 0,
            marginBottom: 15,
            borderTop: "2px solid rgba(0, 0, 0, 0.1)",
            borderBottom: "2px solid rgba(255, 255, 255, 0.3)"
        },
        "& .report-wrapper": {
            marginBottom: 15,
            "&:last-child": {
                [theme.breakpoints.down("700")]: {
                    marginBottom: 0
                }
            }
        }
    },
    grid: {
        "& .history-container": {
            padding: 20,
            borderRadius: 5,
            boxShadow: "0px 0px 4px 0px rgba(210,228,247,1)",
            "& .text-container": {
                marginBottom: "0 !important",
                textTransform: "uppercase",
                fontSize: 16,
                [theme.breakpoints.down("700")]: {
                    fontSize: 14
                }
            },
            "& hr": {
                marginTop: 10,
                borderTop: "2px solid rgba(0, 0, 0, 0.1)",
                borderBottom: "2px solid rgba(255, 255, 255, 0.3)"
            }
        },
        "& .background-title, background-details": {
            display: "block"
        },
        "& .background-title": {
            color: "inherit",
            fontSize: 13,
            [theme.breakpoints.down("700")]: {
                fontSize: 11
            }
        },
        "& .background-details": {
            color: "inherit",
            fontSize: 15,
            fontWeight: 600,
            [theme.breakpoints.down("700")]: {
                fontSize: 13
            }
        },
        "& .report-wrapper": {
            marginBottom: 15,
            "&:last-child": {
                marginBottom: 0,
                [theme.breakpoints.down("700")]: {
                    marginBottom: 0
                }
            }
        }
    }
});
