import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import modalStyles from "./modalStyles";
import "./modal.styles.scss";
import { ModalTextField } from "#/pages/Wallet/fund-wallet/ModalHelper";
import wallet from "#/assets/images/wallet (1).svg";
import { Controller, useForm } from "react-hook-form";
import CustomButton from "#/components/ui-components/button.component";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { fundIzyvest, getIzyInvestBalance } from "#/store/actions/izyinvest.actions";
import NumberFormat from "react-number-format";
import { stringToNumberMoney, toCurrency } from "#/components/utils/util";
import { ErrorDiv } from "#/components/errorDiv";
import { selectIzyPayBalance } from "#/store/actions/izypay.actions";

const FundIzyInvestForm = ({ handleClose, planId }) => {
  const classes = modalStyles();
  const { register, watch, handleSubmit, setValue, errors, control } = useForm({
    mode: "onBlur"
  });
  // const store = useSelector((store) => store.izyinvest);
  const izyPayBalance = useSelector(selectIzyPayBalance);
  const errMsg = useSelector((store) => store.notifications.msg);
  const { loading, minimumDep, contributoryBalance, minimumFirstDeposit } = useSelector(
    (store) => store.izyinvest
  );

  const dispatch = useDispatch();
  useEffect(() => {}, [izyPayBalance]);
  const handleInputChange = (e) => {
    const { value } = e.target;
    setValue(value);
  };
  const values = watch();
  const FundIzyvest = (vals) => {
    let amount;
    if (vals) amount = stringToNumberMoney(vals.amount);
    if (amount < 1 || loading) {
      return;
    } else {
      // const { amount } = vals;
      const data = { amount, planId };
      dispatch(
        fundIzyvest(data, () => {
          dispatch(getIzyInvestBalance(planId));
          handleClose();
        })
      );
    }
  };

  // Validate plan minimum recurring deposit and minimum first deposit
  const validateDeposit = (value) => {
    let intergerVal;

    if (value) intergerVal = stringToNumberMoney(value);

    if (intergerVal < minimumFirstDeposit && contributoryBalance < minimumFirstDeposit) {
      return `Minimum first deposit is ${toCurrency(minimumFirstDeposit)}`;
    } else {
      if (intergerVal < minimumDep) return `Minimum deposit is ${toCurrency(minimumDep)}`;
    }
    if (intergerVal > Number(izyPayBalance)) {
      return `Insufficient wallet balance`;
    }
  };
  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(FundIzyvest)}>
        <label className={classes.label}>Izy Pay Balance</label>
        <Box size="small" className={classes.izybalanceBox} variant="outlined">
          <Typography className={classes.boxTypography}>
            {izyPayBalance === undefined ? (
              <CircularProgress size={16} color="inherit" />
            ) : (
              toCurrency(`${izyPayBalance}`)
            )}
          </Typography>
        </Box>
        {/* <ModalTextField
          label="Enter Amount"
          img={wallet}
          type="number"
          error={errors.amount && errors.amount.message}
          customRef={register({
            required: 'This field is Required',
            pattern: '^-?[0-9]d*.?d*$',
            validate: validateDeposit,
          })}
          value={values.amount}
          onChange={handleInputChange}
          name="amount"
        /> */}
        <Controller
          as={NumberFormat}
          customInput={ModalTextField}
          thousandSeparator
          // className={classes.textField}
          label="Enter Amount"
          img={wallet}
          error={errors.amount && errors.amount.message}
          defaultValue=""
          rules={{
            required: "This field is Required",
            pattern: "^-?[0-9]d*.?d*$",
            validate: validateDeposit
          }}
          value={values.amount}
          onChange={handleInputChange}
          name="amount"
          prefix="₦"
          control={control}
          allowNegative={false}
        />
        {errMsg !== "" && <ErrorDiv>{errMsg}</ErrorDiv>}
        <div className={classes.transferBtnMargin}>
          <CustomButton
            variant="outlined"
            className={classes.closeBtnTransfer}
            onClick={handleClose}
            buttonText="CLOSE"
          />
          <CustomButton
            variant="outlined"
            className={classes.fundBtnTransfer}
            buttonText="FUND"
            isLoading={loading}
          />
        </div>
      </form>
    </React.Fragment>
  );
};

export default FundIzyInvestForm;
