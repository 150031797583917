import { makeStyles, withStyles } from "@material-ui/core/styles";

export const useDashboardStyles = makeStyles((theme) => ({
    root: {},

    sectionsWrapper: {
        display: "grid",
        gridGap: "30px",
        gridTemplateColumns: "2fr 1fr",

        "@media(max-width:1280px)": {
            display: "block"
        },

        "& .left-column": {
            "&__bottom": {
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridGap: "30px",

                "@media(max-width:1280px)": {
                    display: "block"
                }
            }
        }
    }
}));
