import { styled, IconButton } from "@mui/material";
import React from "react";

const SubHeadingUnderline = ({ text, icon, ...buttonProps }) => {
  return (
    <>
      <Box
        display={"flex"}
        justifyContent="space-between"
        alignItems={"center"}
        marginBottom={3}>
        <p
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
            fontSize: "1.4rem",
            color: "#052137"
          }}>
          {text}
        </p>
        {icon ? <IconButton {...buttonProps}>{icon}</IconButton> : null}
      </Box>
    </>
  );
};

export default SubHeadingUnderline;

const Box = styled("div")`
  border-bottom: 1.05px solid #052137;

  @media (max-width: 500px) {
    border-bottom: none;
  }
`;
