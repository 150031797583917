import React, { useState, useEffect } from "react";
import CardContent from "@material-ui/core/CardContent";
import { Card, CardHeader, TextField, Button, CircularProgress } from "@material-ui/core";

import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import Typography from "@material-ui/core/Typography";
import { referralSectionStyles } from "./style";
import wallet from "#/assets/svg/smallWallet.svg";
import copy from "copy-to-clipboard";
import { useDispatch } from "react-redux";
import { showNotifications } from "#/store/actions/notification.actions";
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, LinkedinShareButton } from "react-share";

const ReferralBox = ({ balance, loading, user: { liveizy_id } }) => {
  const classes = referralSectionStyles();
  const dispatch = useDispatch();
  const [refLink, setRefLink] = useState("");

  useEffect(() => {
    //Getting current URL from the browser and patching the liveizy id with it.
    const currentURL = window.location.origin;
    const refURL = currentURL + `/register?liveizyId=${liveizy_id}`;
    setRefLink(refURL);

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Copytext = () => {
    copy(refLink);
    dispatch(showNotifications(" Referral Link Copied", "success"));
  };

  const twitter = {
    tags: ["Liveizy", "HomeManagement"],
    related: ["@liveizy"],
  };

  return (
    <Card className={classes.card} variant="outlined">
      <CardHeader className={classes.cardhead} title="Referral Program" />
      <CardContent>
        <Typography variant="body1" component="p" className={classes.cardtext}>
          Liveizy Referral Program - allows every users attract new citizen, while earning 5% of the value of his expenses with izyPay.
        </Typography>
        <Card variant="outlined" className={classes.refbalance}>
          <CardContent>
            <Typography component="p">Referral Balance</Typography>

            <div className={classes.balancebox}>
              <img src={wallet} alt="wallet" />
              <Typography className={classes.balanceVal} component="div">
                ₦ {loading ? <CircularProgress size={16} color="inherit" /> : balance}
              </Typography>
            </div>
          </CardContent>
        </Card>

        <Typography component="p" className={classes.refLink}>
          Your referral Link :
        </Typography>
        <div className={classes.Linkbox}>
          <TextField id="refferal_link" disabled value={refLink} className={classes.root} variant="outlined" />
          <Button className={classes.btn} variant="outlined" size="large" onClick={Copytext}>
            copy
          </Button>
        </div>
        <div>
          <Typography component="p" className={classes.refLink}>
            Share link:
          </Typography>
          {/**SOCIAL MEDIA BUTTONS */}
          <div style={{ marginTop: "20px" }}>
            <LinkedinShareButton url={refLink} title={"Register on Liveizy"}>
              <LinkedInIcon style={{ color: "#0077B7", fontSize: 30 }} />
            </LinkedinShareButton>

            <FacebookShareButton url={refLink} quote={"Register on Liveizy"} hashtag="#Liveizy">
              <FacebookIcon style={{ color: "#0077B7", fontSize: 30 }} />
            </FacebookShareButton>

            <TwitterShareButton url={refLink} hashtags={twitter.tags} title={"Register on Liveizy"} related={twitter.related} via={"liveizy"}>
              <TwitterIcon style={{ color: "#1da1f2", fontSize: 30 }} />
            </TwitterShareButton>

            <WhatsappShareButton url={refLink} title={"Register on Liveizy"}>
              <WhatsAppIcon style={{ color: "#25D366", fontSize: 30 }} />
            </WhatsappShareButton>
          </div>
          {/**SOCIAL MEDIA BUTTONS */}
        </div>
      </CardContent>
    </Card>
  );
};

export default ReferralBox;
