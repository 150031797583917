import React, { useState } from "react";
import ProfilePictureWrapper from "./ProfilePictureWrapper";
import defaultdp from "../others/defaultpx.jpg";

// THIS COMPONENT RENDERS IMAGE UPLOADED FROM LOCAL COMPUTER

const ProfilePicFilePreview = ({ profileImage }) => {
    const [profilePxPreview, setProfilePixPreview] = useState(null);
    if (profileImage) {
        const reader = new FileReader();
        reader.readAsDataURL(profileImage);
        reader.onload = () => {
            setProfilePixPreview(reader.result);
        };
    }
    return (
        <ProfilePictureWrapper>
            <img src={profileImage ? profilePxPreview : defaultdp} alt="profile pic preview" />
        </ProfilePictureWrapper>
    );
};

export default ProfilePicFilePreview;
