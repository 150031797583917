const REQUIRED = "This field is Required";
const MOBILE_LENGTH_ERRORMSG =
  "Invalid Phone Number format. e.g +2348012345678, 07012345678";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  formId: "rentLoanForm",
  formField: {
    profileImage: {
      name: "profileImage",
      label: "Profile Image",
      requiredErrorMsg: "Profile Image is required",
      acceptedFileTypes: ["image/jpg", "image/png", "image/jpeg"],
      invalidFiletypeErrorMsg: "Unsupported format. File should be .jpg or .png or .jpeg"
    },
    bvn: {
      name: "bvn",
      label: "BVN*",
      requiredErrorMsg: "BVN is required",
      bvnCount: 11,
      invalidLengthErrorMsg: "BVN must be 11 digits"
    },
    surname: {
      name: "surname",
      label: "Last Name*",
      requiredErrorMsg: "Last Name is required"
    },
    firstName: {
      name: "firstName",
      label: "First Name*",
      requiredErrorMsg: "First Name is required"
    },
    otherNames: {
      name: "otherNames",
      label: "Other Names"
    },
    gender: {
      name: "gender",
      label: "Gender*",
      requiredErrorMsg: "Gender is required"
    },
    dateOfBirth: {
      name: "dateOfBirth",
      label: "Date of Birth*",
      requiredErrorMsg: "Date of Birth is required",
      maxDateErrorMsg: "Date cannot be in the future",
      minDateErrorMsg: "You must be 18 or older"
    },
    stateOfOrigin: {
      name: "stateOfOrigin",
      label: "State of Origin*",
      requiredErrorMsg: "State of Origin is required"
    },
    lga: {
      name: "lga",
      label: "Local Government Area of Origin*",
      requiredErrorMsg: "LGA is required"
    },
    email: {
      name: "email",
      label: "Email Address",
      requiredErrorMsg: REQUIRED,
      invalidErrorMsg: "Email Address is not valid. e.g example@email.com"
    },
    alternateEmail: {
      name: "alternateEmail",
      label: "Alternate Email (Optional)",
      invalidErrorMsg: "Alternate Email Address is invalid. e.g example@email.com"
    },
    mobileNumber: {
      name: "mobileNumber",
      label: "Mobile number*",
      requiredErrorMsg: "Mobile Number is required",
      invalidLengthErrorMsg: MOBILE_LENGTH_ERRORMSG
    },
    alternateMobileNumber: {
      name: "alternateMobileNumber",
      label: "Alternate Mobile Number (Optional)",
      invalidLengthErrorMsg: MOBILE_LENGTH_ERRORMSG
    },
    meansOfId: {
      name: "meansOfId",
      label: "Select Means Of Id*",
      requiredErrorMsg: "Means of Id is required"
    },
    meansOfIdFile: {
      name: "meansOfIdFile",
      label: "Upload Id Image",
      requiredErrorMsg: "ID Image is required",
      acceptedFileTypes: ["image/jpg", "image/png", "image/jpeg"],
      invalidFiletypeErrorMsg: "Unsupported format. File should be .jpg or .png or .jpeg"
    },
    employmentStatus: {
      name: "employmentStatus",
      label: "Employment Status*",
      requiredErrorMsg: "Employment Status is required"
    },
    nameOfEmployer: {
      name: "nameOfEmployer",
      label: "Name of Employer*",
      requiredErrorMsg: "Name of Employer is required"
    },
    officeAddress: {
      name: "officeAddress",
      label: "Office Address*",
      requiredErrorMsg: "Office Address is required"
    },
    monthlyIncome: {
      name: "monthlyIncome",
      label: "Monthly Income*",
      requiredErrorMsg: "Monthly Income is required"
    },
    nameOfOfficeContactPerson: {
      name: "nameOfOfficeContactPerson",
      label: "Name of Office Contact Person*",
      requiredErrorMsg: "Name of Office Contact Person is required"
    },
    officeContactPersonNumber: {
      name: "officeContactPersonNumber",
      label: "Office Contact Person Number*",
      requiredErrorMsg: "Office Contact Person Number is required",
      invalidLengthErrorMsg: MOBILE_LENGTH_ERRORMSG
    },
    accountStatementDoc: {
      name: "accountStatementDoc",
      label: " Click to Upload Account Statement Document* (.pdf)",
      requiredErrorMsg: "Account Statement Document is required",
      acceptedFileTypes: ["application/pdf"],
      invalidFiletypeErrorMsg: "Invalid file type. File type should be .pdf"
    },
    homeAddress: {
      name: "homeAddress",
      label: "Home Address*",
      requiredErrorMsg: "Home Address is required"
    },
    laid: {
      name: "laid",
      label: "Apartment Id",
      requiredErrorMsg: REQUIRED
    },
    rentValue: {
      name: "rentValue",
      label: "Rent Value*",
      requiredErrorMsg: REQUIRED,
      invalidErrorMsg: "Invalid. Must be numbers"
    },
    rentRange: {
      name: "rentRange",
      label: "Rent Range*",
      requiredErrorMsg: "Rent Range is required"
    },
    nameOfManager: {
      name: "nameOfManager",
      label: "Name of Manager",
      requiredErrorMsg: "Name of Manager is required"
    },
    contactOfManager: {
      name: "contactOfManager",
      label: "Contact of Manager",
      requiredErrorMsg: "Contact of Manager is required",
      invalidLengthErrorMsg: MOBILE_LENGTH_ERRORMSG
    },
    nameOfLandlord: {
      name: "nameOfLandlord",
      label: "Name of Landlord",
      requiredErrorMsg: "Name of Landlord is required"
    },
    contactOfLandlord: {
      name: "contactOfLandlord",
      label: "Contact of Landlord",
      requiredErrorMsg: "Contact of Landlord is required",
      invalidLengthErrorMsg: MOBILE_LENGTH_ERRORMSG
    },
    nameOfNextOfKin: {
      name: "nameOfNextOfKin",
      label: "Name of Next of Kin*",
      requiredErrorMsg: "Name of Next of Kin is required"
    },
    contactOfNextOfKin: {
      name: "contactOfNextOfKin",
      label: "Contact of Next of Kin",
      requiredErrorMsg: "Contact of Next of Kin is required",
      invalidLengthErrorMsg: MOBILE_LENGTH_ERRORMSG
    },
    emailOfNextOfKin: {
      name: "emailOfNextOfKin",
      label: "Email of Next of Kin",
      requiredErrorMsg: "Email of Next of Kin is required",
      invalidErrorMsg: "Email Address is not valid. e.g example@email.com"
    },
    nextOfKinHomeAddress: {
      name: "nextOfKinHomeAddress",
      label: "Next of Kin Home Address",
      requiredErrorMsg: "Next of Kin Home Address is required"
    },
    nameOfReference: {
      name: "nameOfReference",
      label: "Name of Reference",
      requiredErrorMsg: "Name of Reference is required"
    },
    contactOfreference: {
      name: "contactOfreference",
      label: "Contact of Reference",
      requiredErrorMsg: "Contact of Reference is required",
      invalidLengthErrorMsg: MOBILE_LENGTH_ERRORMSG
    }
  }
};
