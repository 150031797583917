import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

export const TypographyText = withStyles((theme) => ({
    root: {
        color: "#043B5C",
        fontFamily: "inherit"
    },
    h2: {
        fontSize: 16,
        color: "#043B5C",
        [theme.breakpoints.down("xs")]: {
            fontSize: 15
        }
    },
    h3: {
        fontWeight: 800,
        fontSize: 30,
        fontFamily: "Open Sans-bold"
    },
    h4: {
        fontWeight: "bolder",
        fontSize: 23,
        fontFamily: "Open Sans-bold"
    },
    h5: {
        fontWeight: 600,
        fontSize: 17,
        color: "#4786FF",
        [theme.breakpoints.down("sm")]: {
            fontSize: 15
        }
    },
    h6: {
        fontWeight: 700,
        fontSize: 15
    },
    body2: {
        fontWeight: 600,
        fontSize: "1.2rem",
        fontFamily: "Open Sans-Semibold, sans-serif",
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            fontSize: 16
        }
    },
    subtitle1: {
        fontWeight: 900,
        fontSize: 13,
        [theme.breakpoints.down("xs")]: {
            fontSize: 11
        }
    },
    subtitle2: {
        fontWeight: 500,
        fontSize: 11
    },
    body1: {
        fontSize: 13,
        [theme.breakpoints.down("xs")]: {
            fontSize: 12
        }
    },
    caption: {
        fontSize: 15,
        fontWeight: 500,
        color: "#4786FF",
        [theme.breakpoints.down("xs")]: {
            fontSize: 13
        }
    },
    h1: {
        fontSize: 12,
        fontWeight: 900
    }
}))(Typography);

export const Styles = makeStyles((theme) => ({
    summary: {
        marginTop: "40px",
        justifyContent: "space-between",
        padding: "0 4%",
        textAlign: "center",
        width: "80%",

        [theme.breakpoints.down("sm")]: {
            padding: "40px",
            backgroundColor: "white",
            paddingBottom: "15%",
            borderRadius: "0 0 10px 10px",
            width: "100%",
            columnGap: "74px",
            marginTop: "0px",
            justifyContent: "center"
        },
        "& .summary-list": {
            "& h3": {
                fontSize: 40
            },
            " & p": {
                fontWeight: 400
            },
            [theme.breakpoints.down("sm")]: {
                marginBottom: "10%",
                display: "flex",
                flexDirection: "column-reverse"
            }
        }
    },

    summaryTenant: {
        marginTop: "3%",
        backgroundColor: "white",
        padding: "3% 4%",
        borderRadius: 10,
        [theme.breakpoints.down("sm")]: {
            padding: "7% 4%",
            marginTop: "6%"
        }
    },

    editBtn: {
        // backgroundColor: "#4786FF !important",
        // color: "white",
        // border: "none",
        // // fontSize: 12,
        // padding: "0.4rem 1rem",
        // fontSize: ".7rem",
        borderRadius: "5px",
        [theme.breakpoints.only("sm")]: {
            width: "100%",
            borderRadius: "0px"
            //   fontSize: 9,
            //   borderRadius: 0,
            //   padding: "1% 0",
        },

        [theme.breakpoints.only("xs")]: {
            width: "100%",
            borderRadius: "0px"
        }
    },

    xsContainer2: {
        marginTop: "3%",
        display: "flex",
        flexFlow: "row",
        alignItems: "baseline",
        columnGap: "4rem",
        [theme.breakpoints.down("xs")]: {
            marginTop: "3%",
            display: "flex",
            flexFlow: "column",
            alignItems: "baseline",
            padding: "3% 0,"
        }
    },

    tenantImg: {
        width: "70px",
        background: "brown",
        height: "70px",
        borderRadius: "50%"
    },

    btmButton: {
        [theme.breakpoints.down("xs")]: {
            marginTop: "15%"
        }
    }

    // editBtn: {
    //   backgroundColor: "#4786FF !important",
    //   color: "white",
    //   border: "none",
    //   // fontSize: 12,
    //   padding: "0.4rem 1rem",
    //   fontSize: ".7rem",
    //   [theme.breakpoints.down("sm")]: {
    //     fontSize: 10,
    //     width: "100%",
    //   },
    //   [theme.breakpoints.only("xs")]: {
    //     width: "100%",
    //   },
    // },
}));
