import { propertyServiceApi } from "#/apis/propertyServiceApi";
import { showNotifications } from "#/store/actions/notification.actions";

export const fetchLandlordDetails = (user, setState, dispatch) => {
  propertyServiceApi()
    .get(`/property-owner/${user.liveizy_id}`)
    .then((data) => {
      setState(data.data.payload);
    })
    .catch((err) => {
      if (err.response?.status === 404) {
        createLandlord(user.liveizy_id, setState, dispatch);
      }
    });
};
export const fetchLandlordBankDetails = (user, setState, dispatch) => {
  propertyServiceApi()
    .get(`/property-owner/${user.liveizy_id}`)
    .then((data) => {
      setState(data.data.bankAccount);
    })
    .catch((err) => {
      if (err.response?.status === 404) {
        createLandlord(user.liveizy_id, setState, dispatch);
      }
    });
};

export const fetchPManagerDetails = (user, setState, dispatch) => {
  if (user.roles.includes("Agent")) {
    propertyServiceApi()
      .get(`/property-manager/${user.liveizy_id}`)
      .then((data) => {
        setState(data.data.payload);
      })
      .catch((err) => {
        if (err.response?.status === 404) {
          createAgent(user.liveizy_id, setState, dispatch);
        }
      });
  }
};
export const fetchPManagerBankDetails = (user, setState, dispatch) => {
  if (user.roles.includes("Agent")) {
    propertyServiceApi()
      .get(`/property-manager/${user.liveizy_id}`)
      .then((data) => {
        setState(data.data.bankAccount);
      })
      .catch((err) => {
        if (err.response?.status === 404) {
          createAgent(user.liveizy_id, setState, dispatch);
        }
      });
  }
};

const createLandlord = (liveizy_id, setState, dispatch) => {
  const payload = { liveizy_id };
  propertyServiceApi()
    .post("/property-owner", payload)
    .then((data) => {
      setState(data.data.payload);
      dispatch(showNotifications("Account added", "success"));
    })
    .catch(() => {});
};

const createAgent = (liveizy_id, setState, dispatch) => {
  const payload = { liveizy_id };
  propertyServiceApi()
    .post("/property-owner", payload)
    .then((data) => {
      setState(data.data.payload);
      dispatch(showNotifications("Account added", "success"));
    })
    .catch(() => {});
};
