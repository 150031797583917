import React from "react";
import CustomInputAndLabelField from "#/components/customInputFields/CustomInputAndLabelField";
import CustomSelectField from "#/components/customInputFields/customSelectField/CustomSelectField";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid } from "@mui/material";
import { useFormik } from "formik";
import { FormHelperText } from "@mui/material";
import { walletServiceApi } from "#/apis/izyPayApi";
import { showNotifications } from "#/store/actions/notification.actions";
import { getIzyInvestBalance, getIzyPlan } from "#/store/actions/izyinvest.actions";

export default function UpdateBankDetails({ planId }) {
  const dispatch = useDispatch();
  const bankList = useSelector((state) => state.paystack?.banks);
  const bankOptions = bankList.map((bank) => ({
    label: bank.bankName,
    value: parseInt(bank.bankCode)
  }));

  const formik = useFormik({
    initialValues: {
      accName: "",
      accNumber: "",
      bank: ""
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      updateBankDetails(values, resetForm);
    }
  });

  const updateBankDetails = (values, resetForm) => {
    const { bank, accName, accNumber } = values;
    const payload = {
      accountName: accName,
      accountNumber: accNumber,
      bank: bank.value
    };
    try {
      walletServiceApi()
        .put(`/user/invest/user/${planId}`, payload)
        .then((res) => {
          if (res.data.status) {
            dispatch(getIzyInvestBalance(planId));
            dispatch(showNotifications("Details Updated.", "success"));
          }
        });
    } catch (error) {
      dispatch(
        showNotifications(error.response?.data?.message || "An error occured", "error")
      );
    }
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container columnSpacing={2} rowSpacing={0}>
        <Grid item xs={12} sm={6} md={6}>
          <CustomSelectField
            label={"Bank Name"}
            placeholder={"Select Bank..."}
            options={bankOptions}
            isLoading={bankList.length === 0}
            name="bank"
            onChange={(selectedOption) => {
              const event = {
                target: {
                  name: "bank",
                  value: selectedOption
                }
              };
              formik.handleChange(event);
            }}
            defaultValue={formik.values.bank}
          />
          {formik.errors.bank ? (
            <FormHelperText sx={{ marginTop: "-15px" }} error={true} margin="dense">
              {formik.errors.bank}
            </FormHelperText>
          ) : null}
        </Grid>

        <Grid item xs={12} sm={6} md={5}>
          <CustomInputAndLabelField
            label={"Account Number"}
            type={"text"}
            placeholder={"Enter Account Number"}
            name="accNumber"
            onChange={formik.handleChange}
            max={10}
            value={formik.values.accNumber}
          />
          {formik.errors.accNumber ? (
            <FormHelperText sx={{ marginTop: "-15px" }} error={true} margin="dense">
              {formik.errors.accNumber}
            </FormHelperText>
          ) : null}
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <CustomInputAndLabelField
            label={"Account Name"}
            type={"text"}
            placeholder={"Enter Account Name"}
            name="accName"
            onChange={formik.handleChange}
            value={formik.values.accName}
          />
          {formik.errors.accName ? (
            <FormHelperText sx={{ marginTop: "-15px" }} error={true} margin="dense">
              {formik.errors.accName}
            </FormHelperText>
          ) : null}
        </Grid>
        <Grid
          xs={12}
          sm={6}
          md={5}
          sx={{
            paddingBottom: "20px",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "end"
          }}>
          <Button size="small" type="submit" variant="contained">
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

const validate = (values) => {
  const errors = {};
  if (!values.accName) {
    errors.accName = "This field is Required";
  }

  if (!values.accNumber) {
    errors.accNumber = "This field is Required";
  } else if (values.accNumber.length !== 10 || !/^[0-9]+$/.test(values.accNumber)) {
    errors.accNumber = "Must be 10 digits";
  }

  if (!values.bank) {
    errors.bank = "Please select Bank";
  }

  return errors;
};
