import {
  CLEAR_NOTIFICATIONS,
  SHOW_NOTIFICATIONS,
  alertConstants,
  SET_VALIDATION_ERRORS,
  CLEAR_VALIDATION_ERRORS
} from "../constants";

export const clearNotifications = (id) => ({
  type: CLEAR_NOTIFICATIONS,
  id
});

export const showNotifications = (message, messageType, timeout) => {
  const id = Date.now();
  return (dispatch) => {
    dispatch({
      type: SHOW_NOTIFICATIONS,
      message: typeof message === "object" ? message.message : message,
      ...(messageType && { messageType }),
      ...(timeout && { timeout }),
      id
    });
    setTimeout(() => {
      dispatch(clearNotifications(id));
    }, 3000);
  };
};

export const alertSuccess = (message, timeout = 4000) => {
  const id = Date.now();
  return (dispatch) => {
    dispatch({
      type: SHOW_NOTIFICATIONS,
      message,
      messageType: "success",
      timeout,
      id
    });
    setTimeout(() => {
      dispatch(clearNotifications(id));
    }, 3000);
  };
};

export const alertError = (message, timeout = 4000) => {
  const id = Date.now();
  return (dispatch) => {
    dispatch({
      type: SHOW_NOTIFICATIONS,
      message,
      messageType: "error",
      timeout,
      id
    });
    setTimeout(() => {
      dispatch(clearNotifications(id));
    }, 3000);
  };
};

export const setValidationErrors = (errorObj) => {
  return (dispatch) => {
    dispatch({ type: SET_VALIDATION_ERRORS, validationErrors: errorObj });
  };
};

export const clearValidationErrors = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_VALIDATION_ERRORS });
  };
};

export const alertClear = () => {
  return { type: alertConstants.CLEAR };
};
