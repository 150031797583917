import { Button, styled } from "@mui/material";

const CustomBtn = styled(Button)`
  background: #10609f;
  color: white;
  font-size: 13px;
  padding: 12px 1.5rem;
  border-radius: 7px;

  :hover {
    background: #0d4a79;
    opacity: 1;
    box-shadow: inset;
  }
  @media (max-width: 420px) {
    width: auto;
  }
`;

export default CustomBtn;
