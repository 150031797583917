import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import Card from "@mui/material/Card";
import { FaPlusSquare } from "react-icons/fa";
import { propertyServiceApi, returnEstateMember } from "#/apis/propertyServiceApi";
import { alertError } from "#/store/actions/notification.actions";
import avatar from "../../assets/images/default-avatar.png";
import PageTitle from "#/components/estateManagementComponents/page-title";
import BreadCrumbs from "#/components/estateManagementComponents/bread-crumbs";
import { ESM_ROUTES } from "#/constants/routes";

const breadcrumbLinks = [
  { title: "dashboard", href: ESM_ROUTES.dashboard },
  { title: "payments", href: ESM_ROUTES.payments, currentPage: true }
];

const PaymentDetails = () => {
  const params = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { member } = returnEstateMember();

  const [user, setUser] = useState(null);
  const [payments, setPayments] = useState([]);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);

  // useEffect(() => {
  //     fetchUser();
  // }, []);

  const DateTimeParser = (date) => {
    if (date === undefined) return date;
    const dateTime = new Date(date);
    const parsedDate = `${dateTime.getDate()}/${dateTime.getMonth()}/${dateTime.getFullYear()}`;
    return parsedDate;
  };

  const fetchUser = () => {
    const queryParams = {
      params: {
        estate: member.estate._id,
        liveizy_id: params.lid,
        populate1: "roles",
        limit: 1
      }
    };
    setLoading3(true);
    propertyServiceApi()
      .get(`/estate-management/member`, queryParams)
      .then((data) => {
        setUser(data.data.payload[0]);
        if (data.data.payload[0]._id) fetchPaymentLists(data.data.payload[0]._id);
      })
      .catch(({ response, message }) => {
        dispatch(alertError(response?.data.error || message));
      })
      .finally(() => {
        setLoading3(false);
      });
  };

  const fetchPaymentLists = (memberId) => {
    setLoading4(true);
    const queryParams = {
      params: { member: memberId, limit: 10 }
    };
    propertyServiceApi()
      .get("/estate-management/fee-record", queryParams)
      .then((data) => {
        setPayments(data.data.payload);
      })
      .catch(({ response, message }) => {
        dispatch(alertError(response?.data.error || message));
      })
      .finally(() => {
        setLoading4(false);
      });
  };

  const handlePaymentDetailsLists = () => {
    return payments?.map((pay, index) => (
      <Card key={index} className="payable-details">
        <div className="payment-figure">
          <div>
            <p className="bold-text">Purpose</p>
            <span>{pay?.title}</span>
          </div>
          <div>
            <p className="bold-text">Amount Payable</p>
            <span>&#x20A6;{pay?.amount_payable}</span>
          </div>
          <div>
            <p className="bold-text">Amount Paid</p>
            <span>&#x20A6;{pay?.amount_payed}</span>
          </div>
          <div>
            <p className="bold-text">Amount Left</p>
            <span>&#x20A6;{pay?.amount_left}</span>
          </div>
          <div>
            <p className="bold-text">Date Paid</p>
            <span>{DateTimeParser(pay?.updatedAt)}</span>
          </div>
        </div>
      </Card>
    ));
  };

  return (
    <div className={classes.root}>
      <BreadCrumbs links={breadcrumbLinks} />
      <PageTitle title="payment" />
      <div className="header">
        {loading3 && <CircularProgress style={{ color: "#fff" }} size={20} />}
        {user && (
          <div className="profile-details">
            <img className="profile-details-image" src={avatar} alt="profile" />
            <div>
              <span className="profile-details-name">{`${user?.first_name} ${user?.last_name}`}</span>
              <span>{member?.estate.address}</span>
              <span>
                {user?.super_admin ? "super admin" : user.roles && user.roles[0].title}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className={classes.PaymentDetails}>
        <div className="payment-details-header">
          <h3>Payment Details</h3>
          <span>
            <FaPlusSquare className="add-icon" />
          </span>
        </div>
        <div className="payment-details-lists">
          {loading4 && <CircularProgress style={{ color: "#fff" }} size={20} />}
          {payments && handlePaymentDetailsLists()}
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: 20,
    padding: "0 5%",

    "& .header": {
      backgroundColor: "#0961DB",
      marginBottom: "70px",
      borderTopLeftRadius: "15px",
      borderTopRightRadius: "15px",
      padding: "70px",
      width: "100%",

      "& .profile-details": {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",

        "& .profile-details-image": {
          height: "180px",
          width: "180px",
          backgroundColor: "black",
          borderRadius: "100%",
          marginRight: "30px"
        },

        "& div > span": {
          display: "block",
          padding: "6px",
          color: "#fff",
          fontSize: "18px",
          fontWeight: 700
        },

        "& .profile-details-name": {
          fontSize: "22px",
          fontWeight: 700
        }
      }
    }
  },

  PaymentDetails: {
    backgroundColor: "#0961DB",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    width: "100%",
    padding: "7px",

    "& .payment-details-header": {
      display: "flex",
      padding: "10px",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: "25px 10px",

      "& h3": {
        fontSize: "18px",
        fontWeight: 600,
        color: "#fff",
        alignSelf: "end"
      },

      "& span": {
        display: "block"
      },

      "& .add-icon": {
        fontSize: "36px",
        fill: "#fff"
      }
    },

    "& .payment-details-lists": {
      margin: "10px 5px",

      "& .payable-details": {
        borderRadius: "6px",
        padding: "20px 10px",
        marginBottom: "10px",

        "& .payment-figure": {
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",

          "& div": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }
        },

        "& p > span": {
          display: "block",
          fontSize: "16px"
        },

        "& .bold-text": {
          fontWeight: 600
        },

        "& .balance": {
          marginTop: "5px",
          display: "flex",
          justifyContent: "end",

          "& span:first-child": {
            marginRight: "60px"
          }
        }
      }
    }
  }
}));
