import {
  LOAD_POPUP,
  ASSIGN_SERVICE_ROLES_SUCCESS,
  ASSIGN_SERVICE_ROLES_PENDING,
  ASSIGN_SERVICE_ROLES_FAIL,
  ASSIGN_GENERAL_ROLE,
  UPDATE_USER_INFO
} from "../constants";
import axios from "axios";
import { showNotifications } from "./notification.actions";
import { createPropertyOwner, createPropertyManager } from "./property.actions";

const config = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    // Authorization: `Bearer ${token}`,
    "X-Requested-With": "XMLHttpRequest"
  }
};

export const closeServicePopup = () => (dispatch) => {
  dispatch({
    type: LOAD_POPUP,
    popup: false
  });
};

export const assignGeneralRole = (data) => (dispatch) => {
  dispatch({
    type: ASSIGN_GENERAL_ROLE,
    role: data
  });
};

export const assignRole = (data, cb) => (dispatch) => {
  dispatch({ type: ASSIGN_SERVICE_ROLES_PENDING });
  // const { token } = localStorage.getItem("data") && JSON.parse(localStorage.getItem("data"));
  // config.headers.Authorization = `Bearer ${token}`;
  axios
    .post("/user/roles/assign", data, config)
    .then((response) => {
      dispatch({
        type: ASSIGN_SERVICE_ROLES_SUCCESS,
        payload: response.data.data,
        message: response.data.message
      });
      dispatch({ type: UPDATE_USER_INFO, user: response.data.data });
      dispatch(closeServicePopup());

      //UPDATING USER DETAILS IN LOCAL STORAGE
      let auth = JSON.parse(localStorage.getItem("data"));
      const auths = { ...auth };
      auths.user = response.data.data;
      localStorage.setItem("data", JSON.stringify(auths));

      const formData = {
        liveizy_id: response.data.data?.liveizy_id,
        email: response.data.data?.email,
        phone: response.data.data?.phone ?? "",
        first_name: response.data.data?.first_name,
        last_name: response.data.data?.last_name
      };
      //This creates a property-owner record on the backend for the role of Landlord
      if (data.role === "Landlord") {
        dispatch(createPropertyOwner(formData));
      } else if (data.role === "Agent") {
        const payload = {
          ...formData,
          phone2: response.data.data?.phone ?? "",
          phone3: response.data.data?.phone ?? "",
          state: response.data.data?.state_of_origin ?? "",
          business_name: ""
        };
        dispatch(createPropertyManager(payload));
      }

      cb();
      dispatch(showNotifications(response.data.message, "success"));
    })
    .catch((error) => {
      dispatch({ type: ASSIGN_SERVICE_ROLES_FAIL, error });
      dispatch(showNotifications(error.message, "error"));
    });
};
