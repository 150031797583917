import React from "react";

export const ErrorDiv = ({ children }) => {
  return (
    <div
      style={{
        marginTop: "5px",
        margin: "0 auto",
      }}
    >
      <p
        style={{
          color: "red",
          fontSize: "14px",
          textAlign: "center",
        }}
      >
        {children}
      </p>
    </div>
  );
};
