import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { IzyWallet } from "./leftCol/leftCol";
import Right from "./RightCol/right-Col";
import "./wallet-dashboard.scss";
import { useEffect } from "react";
import {
  getIzyPayBalance,
  selectIzyPayBalance,
  selectVirtualAccount
} from "../../store/actions/izypay.actions";
import { connect, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import Container from "../../components/layout/container";

const useStyles = makeStyles((theme) => ({
  border: {
    marginBottom: "10%"
  },
  rightCol: {
    // backgroundColor: 'white',
    borderRadius: "12px",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      // border: 'solid',
      margin: "0 2%"
    }
    // marginTop: '6%',
  },
  background: {
    width: "100%",
    // backgroundColor: "#F4F5F7",
    paddingBottom: "3%",
    [theme.breakpoints.down("xs")]: {
      width: "inherit"
      // width: '332px',
    }
  },
  izyPayBtn: {
    background: "#FFFFFF 0% 0% no-repeat padding-box !important",
    fontSize: "12px",
    marginRight: "7%",
    border: 0,
    color: "#032F4A",
    fontWeight: "bold",
    boxShadow: "1px 20px 30px #332A7C24",
    textTransform: "none",
    backgroundColor: "FFFFFF !important",
    padding: "3% 7%",
    borderRadius: "11px"
  },
  izyVestBtn: {
    backgroundColor: "#4786FF !important",
    fontSize: "12px",
    marginRight: "7%",
    fontWeight: "bold",
    color: "white",
    textTransform: "none",
    border: 0,
    padding: "3% 7%",
    borderRadius: "11px"
  }
}));

const Wallet = ({ history }) => {
  const izystore = useSelector((store) => store.izypay);
  const izyPayBalance = useSelector(selectIzyPayBalance);
  const virtualAccount = useSelector(selectVirtualAccount);
  const izyinvest = useSelector((store) => store.izyinvest);
  const classes = useStyles();
  const transactions = izystore.transactions;
  const [showIzyinvest, setShowIzyinvest] = useState(false);
  const [settings, setSettings] = useState(false);
  const [currentP, setCurrentP] = useState(izyinvest.newPlanId);
  const location = useLocation();
  const dispatch = useDispatch();

  // get current windowlocation
  const windowLocataion = window.location.origin;
  // get up to date balance from api
  useEffect(() => {
    getIzyPayBalance();
  }, []);

  let isVerified =
    window.localStorage.getItem("data") &&
    window.JSON.parse(localStorage.getItem("data"))["user"]["is_email_verified"];

  let activePlan = window.localStorage.getItem("activeplan", true);

  useEffect(() => {
    // dispatch(getIzyPayBalance());
  }, []);

  useEffect(() => {
    // get current plan
    setCurrentP(izyinvest.newPlanId);

    // show izyInvest
    if (activePlan && location.state) setShowIzyinvest(location.state.openizyInvest);
    // console.log(location.state.openizyInvest);
  }, [izyinvest.newPlanId]);

  const doShowizyInvest = () => {
    if (!isVerified) return;
    if (activePlan) {
      setShowIzyinvest(true);
    } else return history.push("/izyinvest");
  };
  const hideIzyvest = () => {
    setShowIzyinvest(false);
  };

  const openSettings = () => {
    setSettings(true);
  };
  const closeSettings = () => {
    setSettings(false);
  };

  console.log(activePlan);

  return (
    <>
      <div className="tw-w-full tw-flex tw-items-center tw-justify-center md:tw-justify-normal md:tw-items-start">
        <div className="tw-w-[282px] md:tw-mb-20 tw-mb-10 tw-flex tw-items-center tw-justify-between tw-h-[60px] tw-bg-white tw-shadow-[0px_4px_18px_-6px_rgba(0,0,0,0.25)] tw-rounded-xl">
          <div
            onClick={hideIzyvest}
            className={`tw-cursor-pointer ${
              !showIzyinvest
                ? "tw-bg-[#10609F] tw-text-white tw-rounded-xl tw-transition tw-duration-500"
                : "tw-bg-transparent tw-text-[#052137]"
            }  tw-flex tw-items-center tw-justify-center tw-w-[150px] tw-h-full`}>
            <p className={`tw-text-lg tw-font-semibold`}>izyPay</p>
          </div>
          <div
            onClick={doShowizyInvest}
            className={`tw-cursor-pointer ${
              showIzyinvest
                ? "tw-bg-[#10609F] tw-text-white tw-rounded-xl tw-transition tw-duration-500"
                : "tw-bg-transparent tw-text-[#052137]"
            }  tw-flex tw-items-center tw-justify-center tw-w-[150px] tw-h-full `}>
            <p className={`tw-text-lg tw-font-semibold`}>izyInvest</p>
          </div>
        </div>
      </div>

      <div className="!tw-w-full">
        <Grid container className={classes.background}>
          {!showIzyinvest ? (
            <Grid item md={4} sm xs={12} className={classes.border}>
              <IzyWallet
                balance={izyPayBalance}
                virtualAccount={virtualAccount}
                izyinvest={izyinvest.availableBalance}
                showIzyinvest={showIzyinvest}
                doShowizyInvest={doShowizyInvest}
                hideIzyvest={hideIzyvest}
                closeSettings={closeSettings}
                openSettings={openSettings}
                currentP={currentP}
                isVerified={isVerified}
                windowLocataion={windowLocataion}
              />
            </Grid>
          ) : null}
          <Grid
            item
            md={!showIzyinvest ? 8 : 0}
            sm={!showIzyinvest ? 8 : 0}
            xs={12}
            className={classes.rightCol}
            style={{ width: "100%" }}>
            <Right
              transactions={transactions}
              showIzyinvest={showIzyinvest}
              settings={settings}
              closeSettings={closeSettings}
              izyinvest={izyinvest}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default connect()(Wallet);
