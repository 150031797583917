import { propertyServiceApi } from "#/apis/propertyServiceApi";
import { APP_ROUTES } from "#/constants/routes";
import { alertError, alertSuccess } from "#/store/actions/notification.actions";

export const handleMakePayment = (
  rentalPaymentCart,
  partRentNumberValue,
  paymentDetails,
  totalAmount,
  history,
  dispatch
) => {
  return new Promise((resolve, reject) => {
    const { rentalCart, tenant } = paymentDetails;

    const bill_array = rentalCart.map((bill) => {
      return bill._id;
    });

    if (!paymentDetails) {
      reject("No payment details provided");
      return;
    }

    // Check if there is any bill includes is_disburse
    const includesDisburse = rentalPaymentCart?.some((bill) => bill.is_disburse);

    // Check if there is any bill with is_loan_rent: true
    const includesLoanRent = rentalPaymentCart?.some((bill) => bill.is_loan_rent);

    const payload = {
      property: tenant.property,
      tenant_lid: tenant.user_id,
      apartment_id: tenant.apartment_id.apartment_id,
      payment_status: "processing",
      is_loan_rent: true,
      start_date: new Date(),
      end_date: new Date(),
      unpaid_bills: bill_array
    };

    const payloadNR = {
      property: tenant.property,
      tenant_lid: tenant.user_id,
      apartment_id: tenant.apartment_id.apartment_id,
      payment_status: "processing",
      is_loan_rent: includesLoanRent ? true : false,
      part_amt: partRentNumberValue,
      start_date: new Date(),
      end_date: new Date(),
      unpaid_bills: bill_array
    };

    // Use payload or payloadNR based on the condition
    const selectedPayload = includesDisburse ? payload : payloadNR;

    // Use if-else to decide which propertyServiceApi() call to make
    if (includesDisburse) {
      propertyServiceApi()
        .post("/invoice/store", selectedPayload)
        .then(({ data }) => {
          dispatch(alertSuccess("Rental Cart Created. Copy Reference to make transfer"));
          history.push(APP_ROUTES.dashboard.main);
          resolve(data); // Resolve with data on success
        })
        .catch((error) => {
          dispatch(alertError(error.response?.data?.msg || "Error making payment"));
          reject(error); // Reject with error on failure
        });
    } else {
      propertyServiceApi()
        .post("/invoice/store/normal_rent", payloadNR)
        .then(({ data }) => {
          dispatch(alertSuccess("Rental Cart Created. Copy Reference to make transfer"));
          history.push(APP_ROUTES.dashboard.main);
          resolve(data); // Resolve with data on success
        })
        .catch((error) => {
          dispatch(alertError(error.response?.data?.msg || "Error making payment"));
          reject(error); // Reject with error on failure
        });
    }
  });
};

// import { propertyServiceApi } from "#/apis/propertyServiceApi";
// import { APP_ROUTES } from "#/constants/routes";
// import { alertError, alertSuccess } from "#/store/actions/notification.actions";

// const RENTDESCRIPTION = "NR";

// export const handleMakePayment = (
//   rentalPaymentCart,
//   partRentNumberValue,
//   paymentDetails,
//   totalAmount,
//   history,
//   dispatch
// ) => {
//   console.log("Information to build my invoice...", paymentDetails);
//   if (!paymentDetails) return;
//   const includesRent = rentalPaymentCart?.find(
//     (bill) => bill.description === RENTDESCRIPTION
//   );
//   let rent_payment;
//   let rentId = null;
//   if (includesRent) {
//     rentId = rentalPaymentCart?.find((bill) => bill.description === RENTDESCRIPTION)._id;
//     if (partRentNumberValue > 0) {
//       rent_payment = "partial";
//     } else if (partRentNumberValue === 0) {
//       rent_payment = "full";
//     }
//   } else {
//     rent_payment = "none";
//   }

//   const payload = {
//     property: "",
//     tenant_lid: "",
//     apartment_id: paymentDetails,
//     payment_status: "",
//     is_loan_rent: "",
//     start_date: rentId,
//     end_date: rentId,
//     unpaid_bills: ""
//   };
//   // const payload = {
//   //   apartment_id: apartmentId,
//   //   amount_due: totalAmount,
//   //   rent_payment,
//   //   charges: rentalPaymentCart
//   //     ?.filter((bill) => bill.description !== RENTDESCRIPTION)
//   //     .map((bill) => bill._id),
//   //   rent: rentId
//   // };

//   propertyServiceApi()
//     .post("/invoice/store", payload)
//     .then(({ data }) => {
//       dispatch(alertSuccess("Rental Cart Created. Copy Reference to make transfer"));
//       history.push(APP_ROUTES.dashboard.main);
//     })
//     .catch((error) => {
//       return dispatch(alertError(error.response.data.message));
//     });

//   // propertyServiceApi()
//   //   .post("/cart", payload)
//   //   .then(({ data }) => {
//   //     dispatch(alertSuccess("Rental Cart Created. Copy Reference to make transfer"));
//   //     history.push(APP_ROUTES.dashboard.main);
//   //   })
//   //   .catch((error) => {
//   //     return dispatch(alertError(error.response.data.message));
//   //   });
// };
// UNKO1702;
// 662955-0099-Lz

// propertyServiceApi()
//   .post("/invoice/store", payload)
//   .then(({ data }) => {
//     console.log("This should be returned data...", data);
//     dispatch(alertSuccess("Rental Cart Created. Copy Reference to make transfer"));
//     history.push(APP_ROUTES.dashboard.main);
//   })
//   .catch((error) => {
//     return dispatch(alertError(error.response.data.message));
//   });

// const includesRent = rentalPaymentCart?.find(
//   (bill) => bill.description === RENTDESCRIPTION
// );
// let rent_payment;
// let rentId = null;
// if (includesRent) {
//   rentId = rentalPaymentCart?.find((bill) => bill.description === RENTDESCRIPTION)._id;
//   if (partRentNumberValue > 0) {
//     rent_payment = "partial";
//   } else if (partRentNumberValue === 0) {
//     rent_payment = "full";
//   }
// } else {
//   rent_payment = "none";
// }
