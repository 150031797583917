import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import TenantDetailsMenu from "./TenantDetailsMenu";
import TenantInformation from "./TenantInformation";
import Header from "../../../components/common/Header";
import MobileView from "./TenantInformationMobileView";
import { getApartmentById } from "#/store/actions/apartment.actions";

const styles = (theme) => ({
    wrapper: {
        "& .mobile-view": {
            display: "none",
            [theme.breakpoints.down("900")]: {
                display: "block"
            }
        }
    },
    root: {
        display: "block",
        [theme.breakpoints.down("900")]: {
            display: "none"
        }
    }
});

const TenantDetail = (props) => {
    const dispatch = useDispatch();

    const { tenantsUnderPm } = useSelector((state) => state.tenant);
    const apartmentById = useSelector((state) => state.apartment.apartmentById);

    const [detail, setDetail] = useState(tenantsUnderPm[0]);
    const [selectedTenant, setSelectedTenant] = useState(null);

    const handleSelectTenant = (val) => {
        setSelectedTenant(val);
    };

    useEffect(() => {
        if (selectedTenant) {
            dispatch(getApartmentById(selectedTenant.apartment_id));
        }
    }, [selectedTenant]);

    return (
        <div className={props.classes.wrapper}>
            <Header title="Tenants" export="Export" newIncome="New Invoice" />
            <div className="mobile-view">
                <MobileView
                    tenantLists={tenantsUnderPm}
                    handleClick={() => console.log("here")}
                    tenant={selectedTenant}
                />
            </div>
            <Card className={props.classes.root}>
                <Grid container>
                    <Grid item xs={12} md={3} className={props.classes.menu}>
                        <TenantDetailsMenu
                            id={() => console.log("there")}
                            handleSelectTenant={handleSelectTenant}
                            tenants={tenantsUnderPm}
                        />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <TenantInformation tenantDetails={selectedTenant} apartmentInfo={apartmentById} />
                    </Grid>
                </Grid>
            </Card>
        </div>
    );
};

export default withStyles(styles)(TenantDetail);
