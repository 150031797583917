import React, { useEffect, useState } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { createApartmentLiPManPlan, createApartmentLiReMPlan } from "#/store/actions/plans.action";
import { useDispatch, useSelector } from "react-redux";
import { propertyServiceApi } from "#/apis/propertyServiceApi";

const planActions = {
    LiReM: {
        action: createApartmentLiReMPlan
    },
    LiPMaN: {
        action: createApartmentLiPManPlan
    }
};

const CreateLipmanPlanModal = ({ displayModal, plan, aptmsArray, setShowAddToPlan, reload, setReload }) => {
    const dispatch = useDispatch();

    const { creatingPlan } = useSelector((state) => state.plans);

    const [plans, setPlans] = useState({});

    useEffect(() => fetchLiveizyPlans(), []);

    const submitPlan = () => {
        const payload = { liveizy_plan: plan, apartment_ids: aptmsArray };
        dispatch(
            planActions[plan].action(payload, () => {
                setShowAddToPlan(true);
                setReload(!reload);
                displayModal(false);
            })
        );
    };

    const fetchLiveizyPlans = () => {
        propertyServiceApi()
            .get("/liveizy-plan-module/plans/view-liveizy-plans")
            .then((data) => {
                setPlans(data.data.payload);
            })
            .catch((err) => {
                setPlans({});
            });
    };

    if (creatingPlan)
        return (
            <div className="lipman-form plans-info-modal">
                <div className="pim-content-div pim-loader-div">
                    <CircularProgress />
                </div>
            </div>
        );

    return (
        <div className="lipman-form plans-info-modal" onClick={(e) => displayModal(false)}>
            <div className="pim-content-div" onClick={(e) => e.stopPropagation()}>
                <div className="pim-content-header-div">Create {plans[plan]?.name} Plan</div>
                <div className="pim-content-bottom-div">
                    <p>
                        No. Of Apartments: <strong>{aptmsArray.length || null}</strong>
                    </p>
                    <p>
                        Liveizy Commission: <strong>{plans[plan]?.percentage * 100 || null}%</strong>
                    </p>
                </div>
                <div className="pim-cbd-div">
                    <Button
                        className="pim-cbd-div-btn"
                        variant="contained"
                        color="primary"
                        onClick={submitPlan}
                        disabled={!aptmsArray[0]}>
                        SUBMIT PLAN
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CreateLipmanPlanModal;
