import React, { useEffect, useRef, useState } from "react";
import avatar from "#/assets/propertyPage/avatar.png";

import { styled } from "@mui/material";
import { BsFillCameraFill } from "react-icons/bs";

const Wrapper = styled("div")`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 15px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const UploadButton = styled("button")`
    background: white;
    position: absolute;
    bottom: -2%;
    right: 17%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 5px;
    font-size: 18px;
    border-radius: 50%;
`;

const ProfilePicAndButton = ({ src, alt }) => {
    const fileInputRef = useRef();
    const [image, setImage] = useState({
        file: null,
        preview: avatar
    });

    useEffect(() => {
        if (src) {
            setImage((prevState) => {
                return {
                    ...prevState,
                    preview: src
                };
            });
        }
    }, []);

    const handleTriggerImgSelection = (e) => {
        e.preventDefault();
        fileInputRef.current.click();
    };

    const handleSelectTenantImg = (e) => {
        const file = e.target.files[0];

        setImage((prevState) => {
            return {
                ...prevState,
                file: file
            };
        });

        //If user did not select any file, get out of function
        if (file === undefined) return;

        const reader = new FileReader();

        reader.onload = function () {
            setImage((prevState) => {
                return {
                    ...prevState,
                    preview: reader.result
                };
            });
        };

        reader.readAsDataURL(file);
    };

    return (
        <div style={{ position: "relative", width: "110px", height: "110px", margin: "25px" }}>
            <Wrapper>
                <img src={image.preview} alt={alt} />
            </Wrapper>
            <input
                name="profilePicture"
                hidden
                type="file"
                accept=".jpg, .jpeg, .png"
                ref={fileInputRef}
                onChange={handleSelectTenantImg}
            />
            <UploadButton onClick={handleTriggerImgSelection}>
                <BsFillCameraFill />
            </UploadButton>
        </div>
    );
};

export default ProfilePicAndButton;
