import React from "react";
import { Grid, TextField, Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Card, CardHeader, CardContent } from "@material-ui/core";
import { accountSectionStyles } from "../account/style";
import CustomButton from "#/components/ui-components/button.component";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

const RefUser = ({ invite, sending }) => {
    const { register, watch, handleSubmit, setValue, errors, reset } = useForm({
        defaultValues: {
            email: ""
        }
    });

    const values = watch();

    const handleEmail = (e) => {
        const value = e.target.value;
        setValue(value);
    };

    const onSubmit = (vals) => {
        const { ...rest } = vals;
        const callbackUrl = window.location.origin;
        const data = { ...rest, callbackUrl };

        invite(data, () => reset());
    };

    const classes = accountSectionStyles();
    return (
        <Card variant="outlined" className={classes.root}>
            <CardHeader className={classes.cardhead} title="Refer By Email" />
            <CardContent className={classes.cardbody}>
                <Grid container>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)} style={{ padding: "10px 20px" }}>
                            <Typography className={classes.Title} component="p">
                                EMAIL
                            </Typography>

                            <TextField
                                id="outlined-full-width"
                                style={{ marginBottom: 20 }}
                                placeholder="AdekunleChindeu@gmail.com"
                                fullWidth
                                name="email"
                                value={values.email || ""}
                                margin="normal"
                                type="email"
                                inputRef={register({
                                    required: "Email is required",
                                    pattern: {
                                        value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                        message: "Invalid Email address, Sure Say you want Referral Bonus ?"
                                    }
                                })}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                onChange={handleEmail}
                                variant="outlined"
                            />
                            {errors.email && <span style={{ color: "red" }}>{errors.email.message}</span>}
                            <CustomButton
                                isLoading={sending}
                                type="submit"
                                buttonText="Send Invitation"
                                fullWidth={true}
                                style={{
                                    backgroundColor: "#4786FF",
                                    color: "#FFFFFF",
                                    textTransform: "none",
                                    marginTop: "1rem",
                                    paddingTop: "1rem",
                                    paddingBottom: "1rem"
                                }}
                            />
                        </form>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default React.memo(RefUser);
