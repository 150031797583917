import React, { Fragment } from "react";
// import './fundWallet.scss';
import {
  Modal,
  Grid,
  Typography,
  Divider,
  // Button,
  // CircularProgress,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import useStyles from "./fundwalletStyles";

const GenModal = ({ handleClose, open, title, children, buttonText, submitting, submit, onClick, float }) => {
  const classes = useStyles();
  return (
    <div className="waiting for him">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        // onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fragment>
          <Grid item md={3} xs={11} sm={5} className={classes.outline}>
            <Fade in={open}>
              <div className="mainpaperdisplay" style={{ width: "90%" }}>
                <div className={classes.paper}>
                  <div className={classes.fundText}>
                    <Typography style={{ color: "#043B5C" }}>{title}</Typography>
                  </div>
                  <Divider className={classes.divider} />
                  {children}
                </div>
              </div>
            </Fade>
          </Grid>
        </Fragment>
      </Modal>
    </div>
  );
};

export default GenModal;
