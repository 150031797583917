import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  makeStyles
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import TablePagination from "@material-ui/core/TablePagination";
import { editPropertyTeam } from "./styles";
import PageHeader from "#/components/propertycomponents/page-header";
import { showNotifications } from "#/store/actions/notification.actions";
//import PlansInfoModal from "./components/PlansInfoModal";
import SadFaceIconComponent from "#/components/ui-components/sadFaceIcon.component";
import AddButton from "#/components/propertycomponents/button-component";
import { propertyServiceApi } from "#/apis/propertyServiceApi";

const PropertyTeam = () => {
  const [memberList, setMemberList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [toggleModal, setToggleModal] = useState(false);
  const [currentModal, setCurrentModal] = useState("bothPlans");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleModal = (trueOrFalse, context = "bothPlans") => {
    setCurrentModal(context);
    setToggleModal(trueOrFalse);
  };

  const RouteToProperty = () => {
    const { is_email_verified } = user;
    if (!is_email_verified) {
      dispatch(showNotifications("Please, verify your email to continue", "error"));
      return;
    } else {
      history.push("/property/members/edit");
    }
  };

  useEffect(() => {
    fetchPropertiesFromAPI();
  }, []);

  const fetchPropertiesFromAPI = async () => {
    try {
      const response = await propertyServiceApi().get(`/account-member`);
      setMemberList(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage =
        error.response?.data?.error || "An error occurred, try again later.";
      dispatch(showNotifications(errorMessage, "error"));
    }
  };

  const classes = editPropertyTeam();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (memberId) => {
    // Placeholder for edit logic
    console.log("Edit member with ID:", memberId);
  };

  const handleDelete = (memberId) => {
    // Placeholder for delete logic
    console.log("Delete member with ID:", memberId);
  };

  return (
    <div className={"property-team-page" + " " + classes.root} id="property-team-page">
      <PageHeader>
        <h3>Property Team Members</h3>
      </PageHeader>

      <section style={{ marginTop: "40px" }}>
        <h2>Edit Page</h2>
      </section>
    </div>
  );
};

export default PropertyTeam;
