import React, { useState, useEffect } from "react";
// import './fundWallet.scss';
import { Modal, Grid, Typography, Divider, TextField, Button, CircularProgress, MenuItem } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
// import userImg from "../../../src/assets/other images/user (1).svg";
import accImg from "../../../../src/assets/other images/icons8-calculator-64.png";
import useStyles from "./fundwalletStyles";
import { useForm } from "react-hook-form";
import Name from "../../../../src/assets/other images/name.svg";
import { useDispatch, useSelector } from "react-redux";
import ReactHookFormSelect from "../../../components/ui-components/select";
import { activateIzyInvest } from "../../../store/actions/izyinvest.actions";
import { useHistory } from "react-router-dom";
import { ValidateNuban } from "../../../store/actions/izypay.actions.js";
import CustomButton from "../../../components/ui-components/button.component";
import { ModalTextField } from "./ModalHelper";
import { ErrorDiv } from "../../../components/errorDiv";
import BankAccountForm from "#/components/bankAccountForm/BankAccountForm";

const IzyInvest = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [open, setOpen] = useState(true);
    const [plan, setPlan] = useState(props.clicked);
    const [display, setDisplay] = useState(false);
    const [bankAccountName, setBankAccountName] = useState();

    let submitting = useSelector((store) => store.izyinvest.loading);
    const store = useSelector((store) => store.paystack);
    let { loading, nubanDetails } = useSelector((store) => store.izypay);
    const messages = useSelector((store) => store.notifications.msg);

    const { register, watch, handleSubmit, setValue, errors, control, form } = useForm({});

    const values = watch();
    let buttonText = "Confirm";
    const history = useHistory();

    if (display) {
        buttonText = "Activate";
        loading = submitting;
    }

    const handleInputChange = (e) => {
        e.preventDefault();
        const { value } = e.target;
        setValue(value);
    };

    useEffect(() => {
        // hide bank Name if account number is edited
        if (display && values.accountNumber !== undefined && values.accountNumber.length !== 10) {
            setDisplay(false);
            setValue("bank", "select bank");
        }

        // validate nuban if bank and accountNumber is filled
        if (
            values.accountNumber !== undefined &&
            values.accountNumber.length === 10 &&
            values.bank !== "select bank" &&
            values.payoutDate === undefined
        ) {
            let data = {
                account_number: values.accountNumber,
                bank_code: values.bank
            };
            // dispatch(ValidateNuban(data, () => setDisplay(true)));
            //handleSubmit(ActivatePlan);
        }
    }, [values.accountNumber, values.bank]);

    // Get plan name
    useEffect(() => {
        setPlan(props.clicked);
    }, [props.clicked]);

    const handleClose = () => {
        props.close(false);
        setDisplay(false);
    };

    // Activate izyinvest plan
    const ActivatePlan = (vals) => {
        // const { ...rest } = vals;
        // const data = { ...rest };
        // const nuban = {
        //     bank_code: data.bank,
        //     account_number: data.accountNumber
        // };
        // console.log("nuban", nuban);
        // if (error !== "" || nuban.bank_code === "select bank") return;
        // data.plan = plan;
        // data.accountName = bankAccountName; //nubanDetails.account_name;
        // delete data.name;
        const data = {
            plan: plan,
            accountName: accountName,
            accountNumber: bankDetails?.accountNumber?.value,
            bank: parseInt(bankDetails?.bank?.value?.code),
            payoutDate: values.payoutDate
        };

        // data.bank = parseInt(data.bank);
        // console.log("dataaa", data);
        /*
        if (!display) {
            dispatch(ValidateNuban(nuban, () => setDisplay(true)));
        }*/

        //else
        dispatch(
            activateIzyInvest(data, () => {
                setOpen(false);
                history.push({ pathname: "/wallet", state: { openizyInvest: true } });
            })
        );
    };

    // Validate payout date
    const [error, setError] = useState("");
    const validateDate = (value) => {
        let date = Date.now();
        let requiredDays = props.days;
        const oneDay = 24 * 60 * 60 * 1000 * requiredDays;
        const selectedDay = new Date(value);
        const dDay = new Date(date + oneDay);
        if (selectedDay < dDay) {
            setError(`Payout Date Must be at least ${props.days} days ahead`);
        }
        if (selectedDay >= dDay || value === undefined) setError("");
    };

    const [bankDetails, setBankDetails] = useState({
        bank: {
            value: null,
            validation: true,
            error: ""
        },
        accountNumber: {
            value: "",
            validation: true,
            error: ""
        }
    });
    const [accountName, setAccountName] = useState("");

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={props.open}
                // onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}>
                <Grid item md={3} xs={11} sm={5} className={classes.outline}>
                    <Fade in={open}>
                        <div>
                            <div className={classes.paper}>
                                <div className={classes.fundText} style={{ float: "left", display: "contents" }}>
                                    <Typography style={{ color: "#043B5C" }}>Savings Details</Typography>
                                </div>
                                <Divider className={classes.divider} />
                                <form style={{ display: "contents" }} onSubmit={handleSubmit(ActivatePlan)}>
                                    <label style={{ marginBottom: "22px" }} className={classes.label}>
                                        Bank Details{" "}
                                    </label>
                                    <BankAccountForm
                                        bankDetails={bankDetails}
                                        accountName={accountName}
                                        setAccountName={setAccountName}
                                        setBankDetails={setBankDetails}
                                    />
                                    <div>
                                        <label className={classes.label}>Payout Date </label>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            className={classes.textField}
                                            name="payoutDate"
                                            type="date"
                                            error={errors.payoutDate && errors.payoutDate.message}
                                            inputRef={register({
                                                required: "This field is required",
                                                validate: validateDate
                                            })}
                                            value={values.payoutDate}
                                            onChange={handleInputChange}
                                            disabled={loading}
                                        />
                                    </div>

                                    {/* oLD COMPONENT WITHOUT VALIDATION 
                                    <ModalTextField
                                        className={classes.textField}
                                        name="accountNumber"
                                        error={errors.accountNumber && "This field is required"}
                                        customRef={register({
                                            required: true,
                                            // pattern: /^[A-Za-z]+$/i,
                                            maxLength: 10
                                        })}
                                        value={values.accountNumber}
                                        onChange={handleInputChange}
                                        type="number"
                                        label="Account Number"
                                        img={accImg}
                                        disabled={loading}
                                    />
                                    <div>
                                        <label className={classes.label}>Bank</label>
                                        <ReactHookFormSelect
                                            id="bank"
                                            name="bank"
                                            className={classes.bank}
                                            error={errors.bank && "This field is required"}
                                            //   label="BANK"
                                            customRef={register({
                                                required: "select one option"
                                            })}
                                            disabled={loading || display}
                                            control={control}
                                            defaultValue="select bank"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            // disabled={isDisabled}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start" style={{ paddingLeft: "0" }}>
                                                        <img src={Name} alt="wallet" className={classes.imgPosition} />
                                                    </InputAdornment>
                                                )
                                            }}>
                                            <MenuItem value="select bank">
                                                <em>Select bank</em>
                                            </MenuItem>
                                            {store.banks.map((bank, index) => (
                                                <MenuItem value={bank.bankCode} key={index}>
                                                    {bank.bankName}
                                                </MenuItem>
                                            ))}
                                        </ReactHookFormSelect>
                                        <ModalTextField
                                            label="Account Name"
                                            img={Name}
                                            type="text"
                                            error={errors.name && "This field is required"}
                                            onChange={(e) => setBankAccountName(e.target.value)}
                                            name="name"
                                            imgStyle={{ height: "42px" }}
                                            value={bankAccountName}
                                        />
                                        <div>
                                            <label className={classes.label}>Payout Date </label>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                className={classes.textField}
                                                name="payoutDate"
                                                type="date"
                                                error={errors.payoutDate && errors.payoutDate.message}
                                                inputRef={register({
                                                    required: "This field is required",
                                                    validate: validateDate
                                                })}
                                                value={values.payoutDate}
                                                onChange={handleInputChange}
                                                disabled={loading}
                                            />
                                            <div>
                                                {error !== "" && (
                                                    <p
                                                        // id={`${name}-component-error-text`}
                                                        style={{
                                                            fontSize: "0.8rem",
                                                            color: "red"
                                                        }}>
                                                        {error}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div> */}
                                    {messages !== "" && <ErrorDiv>{messages}</ErrorDiv>}
                                    <div className={classes.transferBtnMargin}>
                                        <CustomButton
                                            variant="outlined"
                                            className={classes.closeBtnTransfer}
                                            onClick={handleClose}
                                            buttonText="CLOSE"
                                        />
                                        <CustomButton
                                            variant="outlined"
                                            className={classes.fundBtnTransfer}
                                            type="submit"
                                            disabled={
                                                !accountName ||
                                                !bankDetails?.accountNumber?.value ||
                                                !parseInt(bankDetails?.bank?.value?.code) ||
                                                !values.payoutDate
                                            }
                                            buttonText={buttonText}
                                            isLoading={loading}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Fade>
                </Grid>
            </Modal>
        </div>
    );
};

export default IzyInvest;
