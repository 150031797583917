import React from "react";
import { makeStyles } from "@material-ui/core";

const InputIcon = ({ label, type = "text", customRef, name, img, boxClasses, disabled = false, error }) => {
    const classes = useStyles();
    return (
        <div className={classes.inputIcon}>
            <label htmlFor={name}>{label}</label>
            <div className="form-grop">
                <span>
                    <img src={img} alt="icon" />
                </span>
                <input type={type} className="form-icon" name={name} ref={customRef} disabled={disabled} />
            </div>
            {error && (
                <div className={classes.error}>
                    <p
                        id={`${name}-component-error-text`}
                        style={{
                            fontSize: "0.8rem",
                            color: "red"
                        }}>
                        {error}
                    </p>
                </div>
            )}
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    inputIcon: {
        marginBottom: "20px",
        [theme.breakpoints.down("md")]: {
            marginBottom: "20px"
        },

        "& .form-grop": {
            position: "relative",
            display: "flex",
            width: "100%",
            "& > span": {
                whiteSpace: "nowrap",
                display: "block",

                "& :not(:first-child):not(:last-child)": {
                    borderRadius: "0"
                },
                "& :first-child": {
                    borderRadius: "6px 0 0 6px"
                },
                "& :last-child": {
                    borderRadius: "0 6px 6px 0"
                },
                "& :not(:first-child)": {
                    marginLeft: "-1px"
                }
            },

            "& input": {
                whiteSpace: "nowrap",
                display: "block",

                "&:not(:first-child):not(:last-child)": {
                    borderRadius: "0"
                },

                "&:first-child": {
                    borderRadius: "6px 0 0 6px"
                },
                "&:last-child": {
                    borderRadius: "0 6px 6px 0"
                },
                "&:not(:first-child)": {
                    marginLeft: "-1px",
                    borderLeft: "0"
                }
            },
            "& input": {
                position: "relative",
                zIndex: "1",
                flex: "1 1 auto",
                width: "1%",
                marginTop: "0",
                marginBottom: "0",
                padding: "12px"
            },
            "& > span": {
                textAlign: "center",
                padding: "11px 18px",
                fontSize: "14px",
                background: "#eef4fb",
                border: "1px solid #06223968",
                transition: "background 0.3s ease, border 0.3s ease, color 0.3s ease",

                "& > img": {
                    width: "25px"
                }
            },
            "&:focus-within": {
                "& > span": {
                    color: "",
                    background: "",
                    borderColor: "#06223968"
                }
            }
        },

        "& label": {
            display: "block",
            color: "#043B5C",
            fontSize: "0.9rem",
            fontFamily: "Open Sans-Semibold, sans-serif",
            fontWeight: "600",
            marginBottom: "5px",
            letterSpacing: "0.09px",
            [theme.breakpoints.down("md")]: {
                marginBottom: "10px"
            }
        },

        "& input": {
            background: "#FFFFFF",
            border: "1px solid #043B5C54",
            borderRadius: "5px",
            width: "100%",
            fontWeight: "500",
            padding: "10px 16px",
            display: "block",

            border: " 1px solid #06223968",

            "&:focus": {
                outline: "none"
            },

            [theme.breakpoints.down("md")]: {
                width: "100% !important"
            }
        }
    }
}));

export default InputIcon;
