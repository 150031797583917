import React, { useState } from "react";
import { useInnerListingStyles } from "./innerListing.style";

import img1 from "../../assets/images/tenant/minibedroom.png";
import img2 from "../../assets/images/tenant/photo-of-bedroom-1454806.png";
import img3 from "../../assets/images/tenant/minibedroom3.png";
import img4 from "../../assets/images/tenant/largebedroom.png";

const data = {
    image: [img1, img2, img3]
};

const Images = () => {
    const classes = useInnerListingStyles();
    const [Big, setBig] = useState(img4);
    const [more, setMore] = useState(false);

    const handleMore = () => {
        setMore(true);
    };

    const handleBig = (event) => {
        let src = event.target.src;
        setBig(src);
    };

    const images = data.image.map((image) => {
        return (
            <div onClick={handleBig}>
                <img src={image}></img>
            </div>
        );
    });

    return (
        <>
            
                <div className={classes.imagesBig}>
                    <div className={classes.imagesBig}>
                        <img src={Big} width="100%"></img>
                    </div>
                </div>
                <div className={classes.smallImagesDesktopView}>{images}</div>
                <div className={classes.smallImagesMobileView}>
                    {!more ? [images[0], images[1]] : images}
                    {more ? null : (
                        <div className={classes.seeMore} onClick={handleMore}>
                            <p>See More</p>
                        </div>
                    )}
                </div>
        </>
    );
};

export default Images;
