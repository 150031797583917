import CustomBtn from "#/components/buttons/CustomBtn";
import { CardWrapper } from "#/shared/components";
import { styled } from "@mui/material";
import React from "react";

const NoTenantCard = ({ handleAddTenantModal }) => {
  return (
    <Wrapper>
      <p>NO TENANT ATTACHED TO THIS APARTMENT YET</p>
      <AddTenantBtn onClick={handleAddTenantModal}>Add Tenant</AddTenantBtn>
    </Wrapper>
  );
};

export default NoTenantCard;

const Wrapper = styled(CardWrapper)`
  color: rgba(5, 33, 55, 0.6);
  font-size: 1rem;
  font-weight: 600;
  font-style: italic;
  text-transform: uppercase;

  @media (min-width: 600px) {
    padding: 0rem 0rem;
    box-shadow: none;
  }

  @media (max-width: 600px) {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1.35rem 1.25rem;
  }
`;

const AddTenantBtn = styled(CustomBtn)`
  width: 90%;
  @media (min-width: 600px) {
    display: none;
  }
`;
