import { toCurrency } from "#/components/utils/util";
import { APP_ROUTES } from "#/constants/routes";
import { selectIzyPayBalance } from "#/store/actions/izypay.actions";
import { Box, styled } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { IoIosArrowRoundForward } from "react-icons/io";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import { useState } from "react";

const IzyPayWallet = () => {
  const history = useHistory();
  const balance = useSelector(selectIzyPayBalance) || 0;
  const [view, setView] = useState(false);

  const goToIzypay = () => {
    history.push(APP_ROUTES.izyPay.main);
  };
  return (
    <Container>
      <Details>
        <div className="price">
          <p>izyPay</p>
          <div className="flex tw-items-end tw-gap-10">
            <h4>{view ? toCurrency(balance) : `****`}</h4>
            {view ? (
              <IoIosEye
                color=" #10609F"
                size={23}
                className="tw-cursor-pointer"
                onClick={() => setView(false)}
              />
            ) : (
              <IoIosEyeOff
                color=" #10609F"
                size={23}
                className="tw-cursor-pointer"
                onClick={() => setView(true)}
              />
            )}
          </div>
        </div>

        <div className="flex gap-1 items-center">
          <h6 onClick={goToIzypay}>View</h6>
          <IoIosArrowRoundForward color="#C8202D" size={20} />
        </div>
      </Details>
    </Container>
  );
};

export default IzyPayWallet;

const Container = styled(Box)`
  background: #fff;
  box-shadow: 1px 1px 1rem #00000020;
  border-radius: 2rem;
  padding: 1rem 1.5rem;
  width: 30%;
  @media (max-width: 900px) {
    width: 60%;
  }
  @media (max-width: 700px) {
    width: 100%;
  }
`;

const Details = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: end;
  p {
    text-align: start;
    font-weight: bold;
    font-size: 1rem;
    color: #052137;
    margin-bottom: 7px;
  }

  h4 {
    font-weight: bold;
    color: #10609f;
    padding: 0;
    font-size: 2rem;
  }

  h6 {
    text-align: end;
    color: #c8202d;
    font-size: 0.86rem;
    text-decoration: underline;
    cursor: pointer;
    transition: all 0.3s ease-in;

    :hover {
      color: #ff0000;
      font-size: 0.9rem;
    }
  }
`;

// const BackgroundShapes = styled("div")`
//   z-index: -1;
// `;

// const FirstCircle = styled("div")`
//   border: 6px #89a3fe solid;
//   width: 45px;
//   height: 45px;
//   position: absolute;
//   bottom: -30%;
//   left: -5%;
//   border-radius: 50%;
//   opacity: 0.4;
// `;

// const SecondCircle = styled("div")`
//   background: #82909b;
//   width: 40px;
//   height: 40px;
//   position: absolute;
//   top: -30%;
//   left: 40%;
//   border-radius: 50%;
//   opacity: 0.4;
// `;

// const ThirdCircle = styled("div")`
//   position: absolute;
//   text-align: end;
//   top: -20%;
//   right: -2%;
//   overflow: visible;
//   opacity: 0.5;
// `;

// const CrimsonCircle = styled("div")`
//   background: #c8202d;
//   width: 70px;
//   height: 70px;
//   margin-right: -5px;
//   border-radius: 50%;
// `;

// const ThinCircle = styled("div")`
//   border: 1px white solid;
//   width: 72px;
//   height: 72px;
//   position: absolute;
//   right: 5%;
//   top: -2%;
//   border-radius: 50%;
// `;
