import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import { green } from "@material-ui/core/colors";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const LipmanPropComponent = ({ property, plan }) => {
    const classes = useStyles();

    const [displayApartments, setDisplayApartments] = useState(false);

    const dontRender = property.apartment.every((item) => {
        return !item.plan || item.plan?.plan_type !== plan;
    });

    if (dontRender) return null;

    return (
        <>
            <div className="lpmp-lipman-property">
                <IconButton
                    aria-label="delete"
                    className={"lpmp-lp-dropdown" + " " + classes.margin}
                    size="small"
                    onClick={() => setDisplayApartments(!displayApartments)}>
                    {displayApartments ? (
                        <ExpandLessRoundedIcon fontSize="inherit" />
                    ) : (
                        <ExpandMoreRoundedIcon fontSize="inherit" />
                    )}
                </IconButton>
                <div className="lpmp-lp-flex-div">
                    <p>{property.title}</p>
                    <span>
                        {property.house_number} {property.street}, {property.state}
                    </span>
                </div>
                <span>{property._id}</span>
            </div>
            {displayApartments &&
                property.apartment[0] &&
                property.apartment.map((item, idx) => {
                    if (item.plan && item.plan.plan_type === plan)
                        return (
                            <div key={idx} className="lpmp-lipman-apartment2 lpmp-lipman-apartment">
                                <div>
                                    <p>
                                        Apartment ID: <b>{item._id}</b>
                                    </p>
                                    <p>
                                        Liveizy Plan: <b>{item.plan.plan_type}</b>
                                    </p>
                                    <p>
                                        Lipman Status: <b>{item.plan.status}</b>
                                    </p>
                                    <p>
                                        Payment Term: <b>{item.payment_term}</b>
                                    </p>
                                    <p>
                                        Total Price: <b>&#8358;{item.price}</b>
                                    </p>
                                    <p>
                                        Net Price: <b>&#8358;{item.price * (1 - item.plan.commission)}</b>
                                    </p>
                                    <p>
                                        Commission: <b>&#8358;{item.price * item.plan.commission}</b>
                                    </p>
                                </div>
                            </div>
                        );
                    return null;
                })}
        </>
    );
};

export default LipmanPropComponent;

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        "&$checked": {
            color: green[600]
        }
    },
    checked: {}
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1)
    },
    extendedIcon: {
        marginRight: theme.spacing(1)
    }
}));
