import React, { Fragment } from "react";
import clsx from "clsx";

import locationIcon from "#/assets/listingPage/location.svg";
import avatar from "#/assets/propertyPage/avatar.png";

const ApartmentDetails = ({ data, classes }) => {
    const {
        apartment_images,
        no_of_bedrooms,
        no_of_toilet,
        payment_term,
        price,
        apartment_id,
        title,
        floor,
        apartment_position,
        house_number,
        street,
        state,
        lga,
        lcda,
        status,
        extras,
        extra_description
    } = data;

    return (
        <Fragment>
            <div className={clsx(classes.apartmentHeaderAndContent, "address-details")}>
                <h1 className="big-bold">
                    {" "}
                    <img src={locationIcon} width="20px" alt="location"></img>
                    {house_number} {street} {state} {lga} {lcda}
                </h1>
                <h5 className="small-bold">Apartment ID: {apartment_id}</h5>
                <h5 className="small-bold">{title}</h5>
            </div>
            <div className="miscellaneous">
                <div className={classes.apartmentHeaderAndContent}>
                    <h5 className="small-bold">Bedrooms</h5>
                    <h1 className="big-bold">{no_of_bedrooms}</h1>
                </div>
                <div className={classes.apartmentHeaderAndContent} style={{ gridColumn: "2 / span 2" }}>
                    <h5 className="small-bold">Bathrooms</h5>
                    <h1 className="big-bold">3</h1>
                </div>
                <div className={classes.apartmentHeaderAndContent}>
                    <h5 className="small-bold">Status of Apartment</h5>
                    <h1 className="big-bold">{status}</h1>
                </div>
                <div className={classes.apartmentHeaderAndContent}>
                    <h5 className="small-bold">Payment Frequency</h5>
                    <h1 className="big-bold">{payment_term}</h1>
                </div>
                <div className={classes.apartmentHeaderAndContent}>
                    <h5 className="small-bold">Floor</h5>
                    <h1 className="big-bold">{floor}</h1>
                </div>
                <div className={classes.apartmentHeaderAndContent}>
                    <h5 className="small-bold">Rent Price ({payment_term})</h5>
                    <h1 className="big-bold">&#8358; {price}</h1>
                </div>
                <div className={classes.apartmentHeaderAndContent}>
                    <h5 className="small-bold">Position of Apartment</h5>
                    <h1 className="big-bold">{apartment_position}</h1>
                </div>
            </div>
            {/*<div className="user-profile">
                <div className="avatar" style={{ backgroundImage: `url(${avatar})`, marginRight: "20px" }}></div>
                <div className="name-phone">
                    <h1>Chuck Emeka</h1>
                    <p>08105546642</p>
                </div>
            </div>*/}
        </Fragment>
    );
};

export default ApartmentDetails;
