import { useLayoutEffect, useState } from "react";

export default function useMobile() {
    const [isMobile, setMobile] = useState(false);

    useLayoutEffect(() => {
        const userAgent = typeof window.navigator === "undefined" ? "" : navigator.userAgent;
        const mobile = Boolean(userAgent.match(/Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i));
        setMobile(mobile);

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { isMobile };
}
