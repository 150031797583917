import React from 'react';
import '../../App.css'; 
import './smallimage.scss'

// images
import Minibedroom from '../../assets/images/tenant/minibedroom.png';
import Minibedroom2 from '../../assets/images/tenant/photo-of-bedroom-1454806.png';
import Minibedroom3 from '../../assets/images/tenant/minibedroom3.png';

class Smallimage extends React.Component{
    // constructor(props){
    //     super(props)
    // }
    render(){
        return(
            // Small images in booking page
        <React.Fragment>
            <div className="container mt-4">
                <div className="row">
                    <div className="col-sm-12 col-lg-4">
                        <img src={Minibedroom} alt="mini bedroom" className="small-image"/>
                    </div>
                    <div className="col-sm-12 col-lg-4">
                        <img src={Minibedroom2} alt="mini bedroom" className="small-image"/>
                    </div>
                    <div className="col-sm-12 col-lg-4">
                        <img src={Minibedroom3} alt="mini bedroom" className="small-image"/>
                    </div>
                </div>
            </div>
        </React.Fragment>
        );
    }
}

export default Smallimage;