import { makeStyles } from "@material-ui/core/styles";

export const usePropertyStyles = makeStyles((theme) => ({
    wrapper: {
        color: "#043B5C",
        borderRadius: "10px",
        backgroundColor: "#fff",
        overflow: "hidden",

        [theme.breakpoints.down("sm")]: {
            backgroundColor: "transparent",
            overflow: "visible"
        },

        //------------------------------------------------------------------------------------------
        //override margin, padding and box-sizing coming from any parent component or global scope
        //------------------------------------------------------------------------------------------
        "& *": {
            margin: 0,
            padding: 0,
            boxSizing: "border-box"
        }
    },
    stepperWrapper: {
        marginBottom: "80px",

        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },

    imageContainer: {
        backgroundColor: "#6EB5DF72",
        height: "250px",
        position: "relative",
        borderRadius: "10px 10px 0px 0px",
        overflow: "hidden",

        "& .image": {
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat"
        },

        "& input[type=file]": {
            position: "absolute",
            opacity: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
            cursor: "pointer"
        },

        "& p": {
            textAlign: "center",
            position: "absolute",
            width: "100%",
            bottom: 0,
            padding: "15px",
            fontSize: "16px",
            cursor: "pointer",
            backgroundColor: "#DEEDFF",
            color: "#043B5C",
            fontWeight: "500",

            [theme.breakpoints.down("sm")]: {
                backgroundColor: "#fff"
            }
        }
    },
    idContainer: {
        backgroundColor: "#F4F5F7",
        padding: "20px 40px",
        borderRadius: "0px 0px 5px 5px",

        "& h3": {
            marginBottom: "10px",
            fontSize: "14px",
            fontWeight: "900"
        },

        "& P": {
            textTransform: "uppercase",
            fontSize: "13px",
            color: "#043B5C"
        }
    },
    propertyAddressContainer: {
        backgroundColor: "#F4F5F7",
        padding: "20px 40px",
        borderRadius: "0px 0px 5px 5px",

        "& .section": {
            marginBottom: "10px",

            "& h3": {
                marginBottom: "10px",
                fontSize: "13px",
                fontWeight: "900"
            },

            "& P": {
                textTransform: "uppercase",
                fontSize: "11px",
                color: "#043B5C"
            }
        }
    },
    buttonContainer: {
        gridColumn: "1 /span 2",
        display: "flex",
        justifyContent: "flex-end",
        flexWrap: "wrap",
        alignItems: "center",

        [theme.breakpoints.down("sm")]: {
            height: "100px"
        },

        "& button": {
            [theme.breakpoints.down("sm")]: {
                width: "100%"
            }
        }
    },
    btnLink: {
        backgroundColor: "transparent",
        border: "none",
        textDecoration: "underline",
        color: "#1461A2",
        alignSelf: "flex-end",
        cursor: "pointer"
    },

    btnBlue: {
        backgroundColor: "#4786FF",
        color: "#fff",
        borderRadius: "5px",
        border: "1px solid #4786FF",
        padding: "11px 35px",
        fontSize: "11px",
        cursor: "pointer"
    },
    btnWhite: {
        backgroundColor: "#fff",
        color: "#043B5C",
        borderRadius: "5px",
        border: "1px solid #043B5C",
        padding: "11px 35px",
        fontSize: "11px",
        cursor: "pointer"
    },

    errorMessage: {
        color: "red",
        fontSize: "14px",
        marginTop: "5px"
    }
}));
