import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TableCell, TableRow } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';

export const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    background: '#F4F5F7',
    color: theme.palette.common.black,
    fontWeight: 'bold',
  },
  body: {
    fontSize: 14,
    color: '#043B5C',
  },
}))(TableCell);
export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
export const theme = createMuiTheme({
  typography: {
    body1: {
      color: '#043B5C',
    },
  },
});

export const useStyles = makeStyles((theme) => ({
  body: {
    backgroundColor: 'white',
    // padding: '1% 1%',
    borderRadius: '12px',
    width:'96%',
    // border:'solid'
    '& .MuiPaper-elevation1': {
      boxShadow: '1px 20px 30px #3498DB1A !important',
    },
  },
  background: {
    backgroundColor: 'green',
    fontWeight: 'bold',
    color: 'red',
  },
  table: {
    minWidth: 875,
    [theme.breakpoints.down('sm')]: {
      // flexGrow: '0',
      // maxWidth: '61% !important',
      // flexBasis: '50%',
    },
  },
  typographyN: {
    fontSize: '13px',
    color: '#043B5C',
  },
  typography1: {
    color: '#043B5C',
    fontWeight: 'bold',
    fontSize: '21px',
    // textAlign: 'start',
  },
  typography2: {
    color: '#043B5C',
    fontWeight: 'bold',
    fontSize: '15px',
  },
  disp: {
    alignItems: 'baseline',
    paddingTop: '5%',
    paddingBottom: '14%',
    //   border:'solid yellow'
  },
  hr: {
    width: '100%',
    marginTop: '13%',
  },
  // alignIzy: {
  //   textAlign: 'end',
  // },
  dot: {
    backgroundColor: 'red',
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    display: 'inline-block',
    position: 'relative',
  },
  walletText: {
    marginBottom: '4%',
  },
}));

// export default useStyles;
