import { styled } from "@mui/material";
import React from "react";

const Container = styled("div")`
    margin-bottom: 13px;
    margin-right: 15px;
    overflow-wrap: break-word;
    width: 160px;
    /* background: red;
    border: 1px solid black; */
`;

const SubtitleAndSubtext = ({ subtitle, subtext, style }) => {
    return (
        <Container style={style}>
            <p style={{ marginBottom: "2px", fontWeight: 300, color: "#052137B2" }}>{subtitle}</p>
            <small>{subtext}</small>
        </Container>
    );
};

export default SubtitleAndSubtext;
