import React, { useState, useEffect } from "react";
import { Card, CardHeader, Grid, ListItemAvatar, Avatar, Button, ListItemText, ListItem, CardContent, Box, List } from "@material-ui/core";
import PersonOutlineSharpIcon from "@material-ui/icons/PersonOutlineSharp";
import { userSectionStyles } from "./style";
import { CircularProgress } from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import sadFace from "#/assets/svg/sad.svg";

const Users = ({ referral, loading }) => {
  const classes = userSectionStyles();
  let Display;

  if (referral.length) {
    Display = referral.map((user, index) => (
      <ListItem key={index}>
        <ListItemAvatar className={classes.img}>
          <Avatar>
            <PersonOutlineSharpIcon className={classes.userIcon} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText className={classes.userList} primary={`${user.first_name} ${user.last_name}`} secondary={user.email} />
        <button className={classes.button}>{user.email_verified ? "Active" : "Pending"}</button>
      </ListItem>
    ));
  }

  if (loading) {
    Display = (
      <Box className={classes.spinner}>
        <CircularProgress size={40} className={classes.icon} color="inherit" />
      </Box>
    );
  } else if (!referral.length) {
    Display = (
      <div className={classes.empty}>
        <p>you have not referred anyone</p>
        <p>you are missing out on the referral bonus oh!</p>
        <img src={sadFace} width="35%" alt="sad face" />
      </div>
    );
  }

  const sampleContainer = {
    maxHeight: "366px",
  };

  return (
    <Card className={classes.root} variant="outlined">
      <CardHeader className={classes.cardhead} title="Invited Users" />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <PerfectScrollbar style={sampleContainer} options={{ suppressScrollX: true, wheelPropagation: false }}>
              <List>{Display}</List>
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default React.memo(Users);
