import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import OccupiedForm from "#/components/apartmentForm/subcomponents/occupied/occupied";
import AddTenantModal from "#/components/apartmentForm/subcomponents/occupied/AddTenantModal";

const useStyles = makeStyles((theme) => ({
    btnEditApartment: {
        backgroundColor: "#4786FF !important",
        color: "white",
        border: "none",
        padding: "0.4rem 1rem",
        fontSize: ".7rem",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            fontSize: 9,
            borderRadius: 0,
            padding: "1% 0"
        }
    },

    addTenant: {
        marginBottom: "30px",

        "& label": {
            fontWeight: "400 !important"
        },

        "& .tenant-biodata": {
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "20px",

            "@media(max-width: 1130px)": {
                display: "block"
            }
        },

        "& .tenants-details": {
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "20px",
            marginBottom: "30px",

            "@media(max-width: 1130px)": {
                display: "block"
            },

            "& .avatar": {
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                width: "100px",
                height: "100px",
                borderRadius: "50%"
            },

            "& button": {
                borderRadius: "5px",
                padding: "8px 15px",
                fontSize: "10px",
                fontWeight: "700",
                border: "0px",
                color: "#043B5C",
                backgroundColor: "#E8F0F9"
            }
        },

        "& .btn-blue": {
            backgroundColor: "#4786FF",
            color: "#fff",
            borderRadius: "5px",
            border: "1px solid #4786FF",
            padding: "8px 35px",
            fontSize: "11px",
            cursor: "pointer",
            outlineColor: "#4786FF"
        }
    }
}));

const AddTenant = ({ apartmentId, rentDate }) => {
    const classes = useStyles();
    const [showTenantForm, setShowTenantForm] = useState(false);
    return (
        <div>
            {showTenantForm ? (
                <>
                    {/* <OccupiedForm
                        classes={classes}
                        apartmentId={apartmentId}
                        allPropertyInfo={allPropertyInfo}
                        rentDate={rentDate}
                    /> */}
                    <AddTenantModal
                        setShowTenantForm={setShowTenantForm}
                        apartmentId={apartmentId}
                        rentDate={rentDate}
                    />
                </>
            ) : (
                <Button className={classes.btnEditApartment} onClick={() => setShowTenantForm(true)}>
                    Add Tenant
                </Button>
            )}
        </div>
    );
};

export default AddTenant;
