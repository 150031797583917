import React, { useState } from "react";
import { usePropertyAdvStyles } from "../style";

import img1 from "#/assets/listingPage/bed-bedroom-blanket-clean-545012@2x.png";
import img2 from "#/assets/listingPage/photo-of-bedroom-1454806-1@2x.png";
import img3 from "#/assets/listingPage/bed-bedroom-ceiling-chandelier-262048@2x.png";
import img4 from "#/assets/listingPage/photo-of-bedroom-1454806-1@2x.png";

const data = {
  image: [img1, img2, img3],
};

const Images = () => {
  const classes = usePropertyAdvStyles();
  const [Big, setBig] = useState(img4);
  const [more, setMore] = useState(false);

  const handleMore = () => {
    setMore(true);
  };

  const handleBig = (event) => {
    let src = event.target.src;
    setBig(src);
  };

  const images = data.image.map((image) => {
    return (
      <div onClick={handleBig}>
        <img src={image}></img>
      </div>
    );
  });

  return (
    <>
      <div className={classes.imagesContainer}>
        <div className={classes.imagesBig}>
          <div className={classes.imagesBig}>
            <img src={Big} width="100%"></img>
          </div>
        </div>
        <div className={classes.smallImagesDesktopView}>{images}</div>
        <div className={classes.smallImagesMobileView}>
          {!more ? [images[0], images[1]] : images}
          {more ? null : (
            <div className={classes.seeMore} onClick={handleMore}>
              <p>See More</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Images;
