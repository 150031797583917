import React from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { makeStyles } from "@material-ui/core/styles";

/**
 *
 * @param {String} error: the error message
 * @param {String} placeholder: the placeholder of the input
 * @param {String} label: the label of the Dropdown
 * @param {Function} cb: ------
 * @param {String} options: array with object fields {name:"", id:""}.
 * @param {String} anchorRef: hooks props.
 * @param {Function} handleClose: hooks props.
 * @param {Boolean} open: hooks props.
 * @param {Function} handleAction: hooks props.
 * @param {Function} handleDropdownChange: hooks props.
 */

const CustomArrowDropdown = ({
    label,
    placeholder,
    options,
    error,
    styles = {},
    boxClasses,
    selectedOption,
    anchorRef,
    handleClose,
    open,
    handleAction,
    handleDropdownChange,
    cb
}) => {
    const classes = useStyles(open);

    return (
        <div className={classes.inputField} style={styles}>
            <label className={boxClasses}>{label}</label>
            <div className="input-wrapper" onClick={handleDropdownChange}>
                <input type="text" value={selectedOption} placeholder={placeholder} ref={anchorRef} disabled />
                <div className="input-wrapper__dropdown"></div>
            </div>
            <div className="">
                {open && (
                    <ClickAwayListener onClickAway={handleClose}>
                        <ul className="input-wrapper__options">
                            {options &&
                                options.map((option) => {
                                    return (
                                        <li
                                            onClick={() =>
                                                handleAction && handleAction(option.name, option.id, () => cb && cb())
                                            }
                                            key={Math.random() * 1000}>
                                            {option.name}
                                        </li>
                                    );
                                })}
                        </ul>
                    </ClickAwayListener>
                )}
            </div>
            {error && <div className={classes.errorMessage}>{error}</div>}
        </div>
    );
};

export default CustomArrowDropdown;

export const useStyles = makeStyles((theme) => ({
    inputField: {
        marginBottom: "30px !important",
        width: "100%",
        position: "relative",

        "& label": {
            marginBottom: "10px",
            fontSize: "14px",
            fontWeight: "700",
            whiteSpace: "nowrap",
            color: "#043B5C",
            fontFamily: "Open Sans-Semibold"
        },

        "& .input-wrapper": {
            width: "100%",
            position: "relative",
            cursor: "pointer",

            "& input": {
                borderRadius: "5px",
                border: "1px solid #043B5C69",
                padding: "10px 25px",
                width: "100%",
                backgroundColor: "#fff",
                cursor: "pointer",
                outline: "none"
            },

            "&__dropdown": {
                position: "absolute",
                width: "6px",
                height: "6px",
                right: "30px",
                top: "50%",
                transform: "translateY(-50%) rotate(45deg)",
                borderRight: "1px solid #043B5C",
                borderBottom: "1px solid #043B5C"
            },

            "&__options": {
                position: "absolute",
                listStyle: "none",
                width: "100%",
                backgroundColor: "#F4F5F7",
                transition: "all 200ms ease-in-out",
                maxHeight: (open) => (open === true ? "300px" : "0"),
                overflow: (open) => (open === true ? "auto" : "hidden"),
                zIndex: 10,
                paddingLeft: "0px",

                "& li": {
                    padding: "10px",

                    "&:hover": {
                        backgroundColor: "#6EB5DF72",
                        color: "#fff"
                    }
                }
            }
        }
    },
    errorMessage: {
        color: "red",
        fontSize: "14px",
        marginTop: "5px"
    }
}));
