import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import { loginStyles } from "./login/login.styles";
import { ReactComponent as QuoteImg } from "../../assets/images/quote.svg";
import Logo2 from "../../assets/images/logo-1.png";

const AuthPageContainer = ({ children, size }) => {
  const classes = loginStyles();
  return (
    <Grid container>
      <Hidden smDown>
        <Grid item md={4} className={classes.loginSide}>
          <div className={classes.quoteBox}>
            <div className={classes.quote}>
              <QuoteImg />
            </div>
            <div className={classes.quoteWriteUp}>
              Our job is to grant you peace of mind when it comes to choosing an apartment as a tenant or as a landlord looking for the right tenant
              <br />
              <br />
            </div>
            <div className={classes.placement}>
              <div>
                <div className={classes.quoteName}>
                  <strong>Tochi Adesanya</strong>
                </div>
                <div className={classes.position}>CEO & CO-FOUNDER LIVEIZY</div>
              </div>
              <div>
                <img
                  src={Logo2}
                  alt="logo"
                  style={{
                    width: "1.5rem",
                  }}
                />
              </div>
            </div>
          </div>
        </Grid>
      </Hidden>
      <Grid item md={8} className={size ? classes.loginBoxRegister : classes.loginBox}>
        {children}
      </Grid>
    </Grid>
  );
};

export default AuthPageContainer;
