import React from 'react';
import './rentmodal.scss';

class Modalsent extends React.Component{
    constructor(props){
        super(props)
    }
    render(){
        return(
            <React.Fragment>
                <div className="modal fade bd-examplee-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Application to rent</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Modalsent;