import { styled } from "@mui/material";

const CardWrapper = styled("div")`
  border-radius: 1.21238rem;
  background: #fff;
  box-shadow: 0px 4px 29px -9px rgba(0, 0, 0, 0.25);
  padding: 1rem;
`;

export default CardWrapper;
