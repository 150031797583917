import React, { useCallback } from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import PinDropOutlinedIcon from '@material-ui/icons/PinDropOutlined';

import { useCustomCardStyles } from "./customCard.styles";
import locationIcon from "../../../../assets/listingPage/location.svg";
import { Link, useHistory } from "react-router-dom";

import img6 from "#/assets/listingPage/home-real-estate-106399.png";

export default function SearchCard(props) {
    const history = useHistory();
    const classes = useCustomCardStyles();
    const { data } = props;

    const {
        apartment_images,
        no_of_bedrooms,
        no_of_toilet,
        payment_term,
        price,
        apartment_id,
        title,
        floor,
        apartment_position,
        house_number,
        street,
        state,
        lga,
        lcda
    } = data;

    const returnPosition = (num) => {
        if (String(num).endsWith(11)) return `${num}th`;
        if (String(num).endsWith(12)) return `${num}th`;
        if (String(num).endsWith(13)) return `${num}th`;
        if (String(num).endsWith(1)) return `${num}st`;
        if (String(num).endsWith(2)) return `${num}nd`;
        if (String(num).endsWith(3)) return `${num}rd`;
        
        return `${num}th`;
    }

    const handleRedirect = useCallback(() => {
        history.push({ pathname: `/inner-listing/${apartment_id}`, state: { details: data } });
    }, []);

    const formatter = new Intl.NumberFormat();

    return (
        <Card className={classes.root} id="apartment-listing-card">
            <div className={classes.duration}>{props.data.payment_term}</div>
            <div className="overlay" onClick={handleRedirect}>
                <button className={classes.button}>Preview</button>
            </div>
            <p className={classes.apartmentId}>Apartment Id: {apartment_id}</p>
            <CardActionArea>
                <CardMedia
                    className={classes.image}
                    component="img"
                    alt=""
                    height="220"
                    image={apartment_images[0] || ""}
                />
                <div className="card-content-alc">
                    <h2 className={classes.cardTitle}>{props.data.title}</h2>
                    <div className="first-address-block-alc">
                        <img src={locationIcon} width="20px" alt="location" />
                        <p style={{marginLeft:5}}>{house_number} {street} {lcda} {lga} {state}</p>
                    </div>
                    <p className={classes.features}>
                        {no_of_bedrooms} Bedrooms, {no_of_toilet} Bathrooms, {returnPosition(floor)} floor
                    </p>
                    <p className={classes.features} style={{ marginBottom: "7px" }}>
                        Position of apartment: {apartment_position}
                    </p>

                    <h3>&#8358; {formatter.format(price)}</h3>
                </div>
            </CardActionArea>
        </Card>
    );
}
