import React from "react";
import { usePropertyAdvStyles } from "../style";

import locationIcon from "#/assets/listingPage/location.svg";
import bedIcon from "#/assets/listingPage/bed.svg";
import toiletIcon from "#/assets/listingPage/bath.svg";

const ListingDetails = () => {
  const classes = usePropertyAdvStyles();

  return (
    <div className={classes.details}>
      <h1>Would you like to promote this apartment</h1>

      <div className={classes.btnContainer}>
        <button className={classes.btnWhite}>Back</button>
        <button className={classes.btnBlue}>Go live</button>
      </div>
    </div>
  );
};

export default ListingDetails;
