import React from "react";
import { Grid } from "@material-ui/core";
import { loginStyles } from "../login/login.styles";
import { CustomInput } from "../../../components/ui-components/input.component";
import { useForm } from "react-hook-form";
import Logo1 from "../../../assets/images/liveizy-logo.png";
import CustomButton from "../../../components/ui-components/button.component";
import { Link } from "react-router-dom";
import { useState } from "react";
import AuthPageContainer from "../AuthPageContainer";
import { authServiceApi } from "#/apis/authServiceApi";
import Alert from "@material-ui/lab/Alert";

const ResendForm = () => {
  const classes = loginStyles();
  const { register, watch, handleSubmit, setValue, errors, reset } = useForm({
    mode: "onBlur"
  });
  const values = watch();

  const [emailSent, setEmailSent] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [alreadyVerified, setAlreadyVerified] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value);
  };

  const formSubmit = async (formData) => {
    setIsSubmitting(true);

    try {
      const { data } = await authServiceApi().post(
        "/user/auth/email/resend-verification",
        formData
      );
      setEmailSent(data.message);
    } catch (error) {
      if (error.response.status === 400) {
        setAlreadyVerified(`${error.response.data.message}`);
      } else if (error.response.status === 422) {
        setErrorMessage(error.response.data.errors.email[0]);
      } else {
        setErrorMessage("Something went wrong. Please try again");
      }
    } finally {
      setIsSubmitting(false);
      reset();
    }
  };

  return (
    <Grid container className={classes.formContainer}>
      <form className={classes.form} onSubmit={handleSubmit(formSubmit)}>
        <Grid
          item
          xs={12}
          style={{
            marginBottom: "2.5rem"
          }}>
          {alreadyVerified && (
            <Alert severity="info">
              <p>{alreadyVerified}</p>
              <p>
                Close the tab to continue browsing or{" "}
                <Link to="/" style={{ color: "#1461A2", fontWeight: "bold" }}>
                  Go to login page
                </Link>
              </p>
            </Alert>
          )}

          {emailSent && (
            <Alert variant="filled" severity="success">
              {emailSent}
            </Alert>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            marginBottom: "2.5rem"
          }}>
          <div>
            <img
              src={Logo1}
              alt="logo"
              style={{
                width: "6rem"
              }}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            marginBottom: "1.5rem"
          }}>
          <span
            style={{
              color: "#043B5C",
              fontSize: "1.2rem",
              fontWeight: "bold"
            }}>
            ENTER EMAIL TO RESEND VERIFICATION LINK
          </span>
        </Grid>
        <Grid item xs={12}>
          <CustomInput
            label="Email address"
            name="email"
            error={errors?.email?.message || errorMessage}
            customRef={register({
              required: "This field is required",
              pattern: {
                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                message: "invalid email address"
              }
            })}
            value={values.email || ""}
            inputWidth="379px"
            inputHeight="50px"
            type="email"
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomButton
            buttonText="Send"
            isLoading={isSubmitting}
            style={{
              backgroundColor: "#4786FF",
              color: "#FFFFFF",
              width: "8rem",
              textTransform: "none",
              marginTop: "1.5rem",
              marginBottom: "1rem"
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Link
            to="/"
            style={{
              color: "#1461A2",
              fontSize: "0.8rem",
              fontWeight: "bold"
            }}>
            Log in to your Account
          </Link>
        </Grid>
      </form>
    </Grid>
  );
};

const ResendLink = () => {
  return (
    <AuthPageContainer>
      <ResendForm />
    </AuthPageContainer>
  );
};

export default ResendLink;
