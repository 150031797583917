export const OPTIONS_AVAILABILITY_OF_PROPERTY = [
  {
    mainValue: "select",
    displayValue: "Select"
  },
  {
    mainValue: "vacant",
    displayValue: "vacant"
  },
  {
    mainValue: "occupied",
    displayValue: "occupied"
  }
];

// Blocks of Flat
// Fully Detached Duplex
// Semi - Detached Terrace
// Maisonette
// Terrace
// Bungalow

export const OPTIONS_PROPERTY_TYPE = [
  {
    mainValue: "selectType",
    displayValue: "Select Type"
  },
  {
    mainValue: "blocksOfFlat",
    displayValue: "Blocks of flat"
  },
  {
    mainValue: "fullyDetachedDuplex",
    displayValue: "Fully Detached Duplex"
  },
  {
    mainValue: "semiDetachedTerrace",
    displayValue: "Semi - Detached Terrace"
  },
  {
    mainValue: "maisonette",
    displayValue: "Maisonette"
  },
  {
    mainValue: "terrace",
    displayValue: "Terrace"
  },
  {
    mainValue: "bungalow",
    displayValue: "Bungalow"
  },
  {
    mainValue: "duplex",
    displayValue: "Duplex"
  },
  {
    mainValue: "others",
    displayValue: "Others"
  }
];

export const OPTIONS_PAYMENT_FREQUENCY = [
  {
    mainValue: "selectUnit",
    displayValue: "Select Unit"
  },

  {
    mainValue: "monthly",
    displayValue: "Monthly"
  },
  {
    mainValue: "bi-annual",
    displayValue: "Bi-Annual"
  },
  {
    mainValue: "annual",
    displayValue: "Annual"
  },
  {
    mainValue: "quarterly",
    displayValue: "Quarterly"
  }
];

export const OPTIONS_DAYS = [
  {
    mainValue: "Pick a day",
    displayValue: "Pick a day"
  },
  {
    mainValue: "sunday",
    displayValue: "Sunday"
  },
  {
    mainValue: "monday",
    displayValue: "Monday"
  },
  {
    mainValue: "tuesday",
    displayValue: "Tuesday"
  },
  {
    mainValue: "wednesday",
    displayValue: "Wednesday"
  },
  {
    mainValue: "thursday",
    displayValue: "Thursday"
  },
  {
    mainValue: "friday",
    displayValue: "Friday"
  },
  {
    mainValue: "saturday",
    displayValue: "Saturday"
  }
];

export const PAYMENT_TERMS = [
  {
    mainValue: "select",
    displayValue: "select"
  },
  {
    mainValue: "once",
    displayValue: "Once"
  },
  {
    mainValue: "monthly",
    displayValue: "Monthly"
  },
  {
    mainValue: "quarterly",
    displayValue: "Quarterly"
  },
  {
    mainValue: "bi-annual",
    displayValue: "Bi-annual"
  },
  {
    mainValue: "annual",
    displayValue: "Annually"
  }
];

export const APARTMENT_POSITON = [
  {
    mainValue: "select",
    displayValue: "select"
  },
  {
    mainValue: "in-front",
    displayValue: "In-front"
  },
  {
    mainValue: "central",
    displayValue: "Central"
  },
  {
    mainValue: "behind",
    displayValue: "Behind"
  },
  {
    mainValue: "none",
    displayValue: "None"
  }
];

export const METHOD_OF_INVITATION = [
  {
    mainValue: "select",
    displayValue: "select"
  },
  {
    mainValue: "liveizy-id",
    displayValue: "Invite by LiveizyId"
  },
  {
    mainValue: "bio-data",
    displayValue: "Invite by Bio-data"
  }
];

export const PROPERTY_ROLES = [
  {
    mainValue: "select",
    displayValue: "Select one of your roles"
  },
  {
    mainValue: "agent",
    displayValue: "Property Manager"
  },
  {
    mainValue: "landlord",
    displayValue: "Landlord"
  }
];

export const CREATED_FOR = [
  {
    mainValue: "select",
    displayValue: "Select"
  },
  {
    mainValue: "personal",
    displayValue: "Personal"
  },
  {
    mainValue: "company",
    displayValue: "Company"
  }
];
