import { useState, useLayoutEffect, useCallback } from "react";

//--------------------------------------------------------------
// Provide Screen breakpoint eg 990px,768px for this hooks
//--------------------------------------------------------------

const useBreakPoint = (val) => {
    const [isLayout, setLayout] = useState(false);

    //  const breakpoint = "(max-width: 990px)";
    const breakpoint = `(max-width: ${val})`;
    const matchList = window.matchMedia(breakpoint);

    const handleLayout = useCallback(() => {
        if (matchList.matches) {
            setLayout(true);
        } else {
            setLayout(false);
        }
    }, []);

    useLayoutEffect(() => {
        if (window) {
            matchList.addListener(handleLayout);
        }
        return () => matchList.removeListener(handleLayout);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { isLayout };
};

export default useBreakPoint;
