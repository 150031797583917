import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";

import AuthPageContainer from "../AuthPageContainer";
import { authServiceApi } from "#/apis/authServiceApi";
import { loginStyles } from "../login/login.styles";

const VerifyEmail = () => {
  const classes = loginStyles();

  const [errorMsg, setErrorMsg] = useState(null);
  const [isVerifying, setIsVerifying] = useState(true);
  const [isVerified, setIsVerified] = useState(null);

  const params = useParams();
  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const urlPath = `${params.id}?expires=${query.get("expires")}&signature=${query.get(
      "signature"
    )}`;

    const verifyEmail = async () => {
      try {
        await authServiceApi().get(`/user/auth/email/verify/${urlPath}`);
        setIsVerifying(false);
        setIsVerified("Email verified successfully.");
      } catch (error) {
        if (error.response.status === 400) {
          setIsVerified(error.response.data.message);
        } else if (error.response.status === 403) {
          setErrorMsg(error.response.data.message);
        } else {
          setErrorMsg("Something went wrong.");
        }
        setIsVerifying(false);
      }
    };

    verifyEmail();
  }, [params, search]);

  return (
    <AuthPageContainer>
      <Grid container className={classes.formContainer}>
        {isVerifying && <div>Verifying your email...</div>}

        {isVerified && (
          <div>
            <p>{isVerified}</p>
            <p>
              Close the tab to continue browsing or{" "}
              <Link to="/" style={{ color: "#1461A2", fontWeight: "bold" }}>
                Go to login page
              </Link>
            </p>
          </div>
        )}

        {errorMsg && (
          <div>
            <p>{errorMsg}</p>
            <p>
              Request for a new verification link.{" "}
              <Link to="/resend/link" style={{ color: "#1461A2", fontWeight: "bold" }}>
                Resend Link
              </Link>
            </p>
          </div>
        )}
      </Grid>
    </AuthPageContainer>
  );
};

export default VerifyEmail;
