import { makeStyles } from "@material-ui/core";
export const useNewStyles = makeStyles((theme) => ({
    newInitialsCircleWrapper: {
        color: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: 800,
        marginRight: 15,

        "& img": {
            width: 50,
            height: 50,
            objectFit: "cover",
            borderRadius: "50%"
        }
    },
    newApartmentSummaryHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",

        "& .summaryButtons": {
            display: "flex",
            gap: "1rem"
        },

        "& h1": {
            fontSize: 20,
            fontWeight: 800,
            color: "rgb(4, 59, 92)",
            marginBottom: 0,

            [theme.breakpoints.down("sm")]: {
                display: "none"
            }
        }
    },
    newLeaseAndTenantSection: {
        [theme.breakpoints.down("sm")]: {
            padding: "25px 20px"
        },

        [theme.breakpoints.down("xs")]: {
            padding: "0px"
        }
    },
    tenantsWrapper: {
        [theme.breakpoints.down("sm")]: {
            padding: "20px"
        }
    }
}));
