import { FormHelperText, styled } from "@mui/material";
import { useField, useFormikContext } from "formik";
import React from "react";

const FormikCustomSelectField = ({ name, label, options, ...otherProps }) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);

    const handleChange = (event) => {
        const { value } = event.target;
        setFieldValue(name, value);
    };

    const configSelectField = {
        ...field,
        ...otherProps,
        onChange: handleChange
    };

    return (
        <div style={{ marginBottom: "30px" }}>
            <label
                style={{
                    fontSize: "14px",
                    fontWeight: "700",
                    marginBottom: "10px",
                    whiteSpace: "nowrap",
                    color: "#043B5C",
                    fontFamily: "Open Sans-Semibold"
                }}
                htmlFor={name}>
                {label}
            </label>
            <Select {...configSelectField}>
                <option disabled value="">
                    Select an Option
                </option>
                {options.map((option, index) => {
                    return (
                        <option key={index} value={option.value}>
                            {option.label}
                        </option>
                    );
                })}
            </Select>
            {meta && meta.touched && meta.error && <FormHelperText sx={{ color: "red" }}>{meta.error}</FormHelperText>}
        </div>
    );
};

const Select = styled("select")`
    position: relative;
    -webkit-appearance: button;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
    height: 45px;
    width: 100%;
    padding: 10px;
    background: white;
    border: 1px solid #043b5c69;
    border-radius: 5px;
    font-size: 15px;
    overflow: hidden;
    option {
        font-size: 15px;
    }
`;

export default FormikCustomSelectField;
