import { useState, useCallback, useEffect } from "react";

const useArrowDropdown = (options) => {
  const [dropdownOptions, setDropdownOptions] = useState(options || []);
  const [selectedOption, setSelectedOption] = useState(
    options?.[0] || { mainValue: "select", displayValue: "Select an option" }
  );

  useEffect(() => {
    if (options && options.length > 0) {
      // Only update if options are new or different
      if (
        dropdownOptions.length !== options.length ||
        !dropdownOptions.every((opt, idx) => opt.mainValue === options[idx]?.mainValue)
      ) {
        setDropdownOptions(options);
        setSelectedOption(
          options[0] || { mainValue: "select", displayValue: "Select an option" }
        );
      }
    }
  }, [options, dropdownOptions]);

  const handleSetSelectedOption = useCallback(
    (e) => {
      const option = dropdownOptions.find((item) => item.mainValue === e.target.id);
      if (option) {
        setSelectedOption(option);
      }
    },
    [dropdownOptions]
  );

  return {
    dropdownOptions,
    selectedOption,
    handleSetSelectedOption,
    setSelectedOption,
    setDropdownOptions
  };
};

export default useArrowDropdown;
