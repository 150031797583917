import React, { useState } from "react";
import PaymentComponent from "../components/PaymentComponent";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { payForEstateFees } from "#/store/actions/payments.action";
import { ESM_ROUTES } from "#/constants/routes";

const PaymentPageFees = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const itemsToPay = localStorage.getItem("ESM_PAY_FEES");
    const items = JSON.parse(itemsToPay);
    const total = items?.items?.reduce((a, b) => a + b.amount, 0);

    const [, setSelectedMethod] = React.useState(null);
    const [amount, setAmount] = useState(total || 0);

    if (!itemsToPay) {
        history.push("/dashboard");
        return null;
    }

    const izyPayEsmFeesPay = () => {
        const payload = { fees: items.items };
        dispatch(
            payForEstateFees(payload, (status) => {
                if (status) history.push(ESM_ROUTES.dashboard);
            })
        );
    };

    return (
        <PaymentComponent
            amountReadonly={true}
            spectaAvailable={false}
            fullAmount={total}
            amount={amount}
            setAmount={setAmount}
            items={items}
            paymentMethod={"izypay"}
            setPaymentMethod={setSelectedMethod}
            onSubmit={izyPayEsmFeesPay}
            hideSelectMethod
        />
    );
};

export default PaymentPageFees;
