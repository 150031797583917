import React, { useState, useEffect } from "react";
import { Grid, FormControl, MenuItem, OutlinedInput, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Card, CardHeader, Button, CardContent } from "@material-ui/core";
import { accountSectionStyles } from "./style";
import Select from "@material-ui/core/Select";
import { CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { ValidateNuban } from "#/store/actions/izypay.actions";
import { ErrorDiv } from "#/components/errorDiv";
import "./style.scss";

const AccountBox = ({ account, updateAccount, fetching }) => {
  const store = useSelector((state) => state.paystack);
  const messages = useSelector((state) => state.notifications.msg);
  let { loading, nubanDetails } = useSelector((state) => state.izypay);

  const [btnState, setbtnState] = useState(true);
  const [accName, setAccName] = useState("");
  const { register, handleSubmit, setValue, errors, watch, control, reset } = useForm();
  const values = watch();
  const dispatch = useDispatch();

  //------------------------------------------------------
  //Getting user previously used account details on mount
  //-----------------------------------------------------

  useEffect(() => {
    if (account !== null && account !== undefined) {
      const { account_name, account_number, bank_id } = account;
      let bankID = "";
      store.banks.forEach((item) => {
        if (item.bankId === +bank_id) {
          bankID = item.bankCode;
        }
      });
      setValue("banks", bankID);
      setValue("accountNumber", account_number);
      setAccName(account_name);
    }
  }, [account]);

  //--------------------------------------------------
  //Setting the account name from the store
  //--------------------------------------------------
  useEffect(() => {
    setAccName(nubanDetails.account_name);
  }, [nubanDetails, dispatch]);

  const { accountNumber, banks } = values;
  //----------------------------------------------------------------------------
  //Clearing account name input field if user tries to clear account number field
  //----------------------------------------------------------------------------
  useEffect(() => {
    if (accountNumber !== undefined && accountNumber.toString().length !== 10) {
      setAccName("");
    }
  }, [accountNumber]);

  //-------------------------------------------------
  //Validating user account details
  //-------------------------------------------------
  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        accountNumber !== undefined &&
        accountNumber.toString().length === 10 &&
        banks !== undefined &&
        banks.length > 2
      ) {
        //user account information
        const nuban = {
          bank_code: banks,
          account_number: accountNumber
        };
        setbtnState(false);
        dispatch(ValidateNuban(nuban, () => setbtnState(true)));
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [accountNumber, banks]);

  //--------------------------------------------------
  //Submitting form
  //--------------------------------------------------

  const onSubmit = (vals, e) => {
    e.preventDefault();
    const { accountNumber, banks } = vals;

    let bank_id = "";
    store.banks.forEach((item) => {
      if (item.bankCode === banks) {
        bank_id = item.bankId;
      }
    });

    //Getting user bank name
    const formData = {
      bank_id,
      account_number: accountNumber,
      account_name: accName
    };

    updateAccount(formData, () => {
      reset();
      setAccName("");
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value);
  };

  const classes = accountSectionStyles();
  return (
    <Card variant="outlined" className={classes.root}>
      <CardHeader className={classes.cardhead} title="Account details (for commission)" />
      <CardContent className={classes.cardbody}>
        <Grid container spacing={4}>
          <Grid container item xs={12} spacing={4}>
            <Grid item xs={12} sm={12} lg={6}>
              <div>
                <label htmlFor="banks" className={classes.inputTitle}>
                  SELECT BANK
                </label>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Controller
                    as={
                      <Select>
                        {store.banks.map((bank) => (
                          <MenuItem value={bank.bankCode} key={bank.bankCode}>
                            {bank.bankName}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                    defaultValue=""
                    name="banks"
                    className={classes.select}
                    control={control}
                    rules={{ required: "This field is required" }}
                  />
                </FormControl>
                {errors.banks && (
                  <span style={{ color: "red", fontSize: "15px" }}>
                    {errors.banks.message}
                  </span>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <div>
                <label htmlFor="accountNumber" className={classes.inputTitle}>
                  ACCOUNT NUMBER
                </label>
                <input
                  name="accountNumber"
                  className={classes.input}
                  type="number"
                  ref={register({
                    required: "This field is required",
                    minLength: {
                      value: 10,
                      message: "Enter a valid Account number"
                    },
                    maxLength: {
                      value: 10,
                      message: "You want your Referral Bonus ?"
                    }
                  })}
                  placeholder="0999999955"
                  value={values.accountNumber || ""}
                  onChange={handleInputChange}
                />
                {errors.accountNumber && (
                  <span style={{ color: "red" }}>{errors.accountNumber.message}</span>
                )}
              </div>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={4}>
            <Grid item xs={12} sm={12} lg={6}>
              <div>
                <label htmlFor="accountName" className={classes.inputTitle}>
                  ACCOUNT NAME
                </label>
                <input
                  name="accountName"
                  className={classes.input}
                  type="text"
                  placeholder="oleka kelechi"
                  value={accName || ""}
                  readOnly
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <div className={classes.buttonX}>
                <Button
                  variant="contained"
                  size="large"
                  className={classes.button}
                  type="button"
                  onClick={handleSubmit(onSubmit)}>
                  {btnState ? (
                    fetching ? (
                      <CircularProgress
                        size={16}
                        className={classes.icon}
                        color="inherit"
                      />
                    ) : (
                      "Submit"
                    )
                  ) : (
                    loading && (
                      <CircularProgress
                        size={16}
                        className={classes.icon}
                        color="inherit"
                      />
                    )
                  )}
                </Button>
              </div>
            </Grid>
            {messages !== "" && <ErrorDiv>{messages}</ErrorDiv>}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AccountBox;
