import React from "react";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Link } from "react-router-dom";
import { Accordion, Button, CircularProgress, makeStyles } from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSelector } from "react-redux";
import logo from "../../../assets/images/liveizy-logo.png";
import logoMini from "../../../assets/images/logo-1.png";
import { ReactComponent as SpectaImg } from "../../../assets/images/pay-with-specta-logo.svg";
import { returnCommaSepAmount } from "#/lib/validation";
import ConfirmationPopup from "#/components/popup/confirmation";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    }
}));

const PaymentComponent = ({
    amountReadonly,
    spectaAvailable,
    fullAmount,
    amount,
    setAmount,
    monthsLeft,
    setMonths,
    items,
    paymentMethod,
    setPaymentMethod,
    onSubmit,
    payOptions,
    hideSelectMethod
}) => {
    const classes = useStyles();

    const { paying } = useSelector((state) => state.payments);

    const [value, setValue] = React.useState("");
    const [accordionOpen, setAccordionOpen] = React.useState(true);
    const [openConfirm, setOpenConfirm] = React.useState(false);

    const handleRadioChange = (event) => {
        setValue(event.target.value);
        setPaymentMethod(event.target.value);
    };

    const payWithSpecta = spectaAvailable && (
        <p>
            <SpectaImg width={80} />
            <b>Pay with Specta</b>
        </p>
    );

    const izyPay = (
        <p>
            <img src={logoMini} alt="" />
            <span>Pay with IzyPay</span>
        </p>
    );

    return (
        <div className="payment-page-container">
            {openConfirm && !paying && (
                <ConfirmationPopup
                    title="Proceed with Payment"
                    description="Are you sure you want to proceed with payment?"
                    handleClose={() => setOpenConfirm(false)}
                    proceedAction={onSubmit}
                />
            )}
            <div className="payment-page-content">
                <div className="pgc-top-ribbon">
                    <Link to="/dashboard">
                        <img src={logo} alt="pay with liveizy" />
                    </Link>
                    <h3>PAYMENTS</h3>
                    <span>
                        <LockOutlinedIcon fontSize="large" />
                        Secured
                    </span>
                </div>
                <div className="pgc-bottom-divs">
                    <div className="pgc-balance-ribbon">
                        <h4>Payment Amount:</h4>
                        <div>
                            <span>&#8358;</span>
                            <input
                                value={returnCommaSepAmount(amount)}
                                readOnly={amountReadonly}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </div>
                        {payOptions && (
                            <select
                                onChange={(e) => {
                                    setAmount((e.target.value * fullAmount) / 12);
                                    setMonths(e.target.value);
                                }}>
                                {Array.from({ length: 12 }, (_, i) => i + 1)
                                    .filter((item) => item <= monthsLeft)
                                    .reverse()
                                    .map((item) => (
                                        <option value={item}>
                                            {item === 12 ? "1 year" : `${item} ${item > 1 ? "months" : "month"}`}
                                        </option>
                                    ))}
                            </select>
                        )}
                    </div>
                    <div className="accordion-view-window">
                        <Accordion expanded={accordionOpen} onChange={() => setAccordionOpen(!accordionOpen)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                                <Typography className={classes.heading}>Items ({items.items.length})</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    {items.items.map((item, idx) => (
                                        <Typography key={idx}>
                                            - <b>{item.title}</b>: &#8358;{" "}
                                            {returnCommaSepAmount(item.price || item.amount)}
                                        </Typography>
                                    ))}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className="pgc-pay-method">
                        <div className="pgc-pm-selected">
                            <h3>Payment Method</h3>
                            {paymentMethod === "izypay" && izyPay}
                            {paymentMethod === "paywithspecta" && payWithSpecta}
                        </div>
                    </div>
                    {!hideSelectMethod && (
                        <>
                            <h4 className="pgc-spacer-header">Select payment method</h4>
                            <div className="pgc-pay-method-select pgc-pay-method">
                                <div className="pgc-pm-selected">
                                    <RadioGroup
                                        aria-label="quiz"
                                        name="quiz"
                                        value={value}
                                        onChange={handleRadioChange}>
                                        <FormControlLabel value="izypay" control={<Radio />} label={izyPay} />
                                    </RadioGroup>
                                </div>
                            </div>
                            <div className="pgc-pay-method-select pgc-pay-method">
                                <div className="pgc-pm-selected">
                                    <RadioGroup
                                        aria-label="quiz"
                                        name="quiz"
                                        value={value}
                                        onChange={handleRadioChange}>
                                        <FormControlLabel
                                            value="paywithspecta"
                                            control={<Radio />}
                                            label={payWithSpecta}
                                        />
                                    </RadioGroup>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className="pgc-bottom-divs">
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{ height: 50 }}
                        disabled={!paymentMethod || amount <= 0 || paying}
                        onClick={() => setOpenConfirm(true)}>
                        {paying ? <CircularProgress size={30} /> : "Pay"}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default PaymentComponent;
