import React, { useState, useCallback, memo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

import Button from "#/components/ui-components/button.component";
import {
    requestBookInspection,
    clearBookingFields,
    getMyBookings,
    deleteBooking
} from "#/store/actions/booking.actions";

import ApartmentDetails from "./components/apartmentDetails";
import ScheduleBox from "./components/scheduleBox";
import { propertyServiceApi } from "#/apis/propertyServiceApi";

const DetailsAndInspection = ({
    classes,
    data,
    apartmentSchedules,
    dispatch,
    user,
    windowSize,
    handleSetModalVisibility
}) => {
    const { apartment_id } = data;
    const { liveizy_id } = user;

    const history = useHistory();

    //-------------------------------------------------------------
    //STORE
    //-------------------------------------------------------------
    const { bookingInspection, successfullyBooked } = useSelector((state) => state.booking);

    //-------------------------------------------------------------
    //STATE
    //-------------------------------------------------------------
    const [showScheduleDates, setShowScheduleDates] = useState(false);
    const [booked, setBooked] = useState("");

    useEffect(() => fetchBookingForApartment(), []);

    //-------------------------------------------------------------
    //HELPER FUNCTIONS
    //-------------------------------------------------------------

    const fetchBookingForApartment = () => {
        setBooked("loading");
        propertyServiceApi()
            .get("/booking/getAllBooking", { params: { user_id: liveizy_id, apartment_id } })
            .then((data) => {
                data.data.payload[0] ? setBooked(data.data.payload[0]) : setBooked(null);
            })
            .catch((err) => {
                setBooked(null);
            });
    };

    //Open available schedules on the apartment
    const handleOpenAvailableSchedules = () => {
        setShowScheduleDates(!showScheduleDates);
        dispatch(clearBookingFields());
    };

    //redirect to the dashboard
    const handleRedirectToDashboard = useCallback(() => {
        dispatch(clearBookingFields());

        history.push("/dashboard");
    }, []);

    //Book a schedule
    const handleBookDate = async (item, booked) => {
        //iF Booked is a truthy then we are updating and not creating a new schedule
        const body = {
            date: new Date(),
            time: item.time ? item.time[0] : null,
            day: item.day,
            user_id: user.id,
            apartment_id: apartment_id
        };

        //Update booking
        if (booked) {
            dispatch(
                deleteBooking(booked._id, () => {
                    dispatch(
                        requestBookInspection(body, () => {
                            dispatch(getMyBookings(user.liveizy_id));
                        })
                    );
                })
            );
        } else {
            dispatch(
                requestBookInspection(body, () => {
                    dispatch(getMyBookings(user.liveizy_id));
                })
            );
        }
    };

    const handleDeleteBooking = (data) => {
        const { _id } = data;
        dispatch(
            deleteBooking(_id, () => {
                dispatch(getMyBookings(user.liveizy_id));

                history.push("/dashboard");
            })
        );
    };

    const handleRedirectToShowOfInterest = () => {
        history.push(`/booking/show-of-interest/${booked._id}`);
    };

    const renderButtons = () => {
        if (booked === "" || booked === "loading") return null;
        if (booked && booked?.status == "interested") {
            return (
                <>
                    <p style={{ fontStyle: "italic" }}>Agent would reach out to you soon</p>
                    <div>
                        <Button
                            buttonText="Not Interested"
                            onClick={() => handleDeleteBooking(booked)}
                            className={clsx(classes.btnBlue, classes.btnRedOutlined)}
                            style={{
                                padding: windowSize.width > 992 ? "7px 20px" : "7px 15px",
                                fontSize: windowSize.width > 992 ? "13px" : "11px"
                            }}
                        />
                    </div>
                </>
            );
        }
        if (booked && booked?.status == "approved") {
            return (
                <>
                    <p style={{ fontStyle: "italic", marginBottom: 20 }}>
                        Booking has been approved. Please go ahead and pay.
                    </p>
                    <div>
                        <Button
                            buttonText="Pay for Apartment"
                            onClick={() => handleSetModalVisibility(true)}
                            className={classes.btnBlue}
                            style={{
                                padding: windowSize.width > 992 ? "7px 20px" : "7px 15px",
                                fontSize: windowSize.width > 992 ? "13px" : "11px",
                                marginRight: "20px"
                            }}
                        />
                    </div>
                </>
            );
        }
        if (booked && booked?.status === "pending") {
            return (
                <div>
                    <Button
                        buttonText="Show Interest"
                        onClick={handleRedirectToShowOfInterest}
                        className={classes.btnBlue}
                        style={{
                            padding: windowSize.width > 992 ? "7px 20px" : "7px 15px",
                            fontSize: windowSize.width > 992 ? "13px" : "11px",
                            marginRight: "20px"
                        }}
                    />
                </div>
            );
        }
        if (booked === null) {
            return (
                <Button
                    buttonText={showScheduleDates ? "Close Schedules" : "Book Inspection"}
                    onClick={handleOpenAvailableSchedules}
                    className={classes.btnBlue}
                    style={{
                        padding: windowSize.width > 992 ? "7px 20px" : "7px 15px",
                        fontSize: windowSize.width > 992 ? "13px" : "11px"
                    }}
                />
            );
        } /*
        if (booked === null) {
            return (
                <div>
                    {![owner, property_manager].includes(liveizy_id)
                        ?   <Button
                                buttonText="Show Interest"
                                onClick={handleRedirectToShowOfInterest}
                                className={classes.btnBlue}
                                style={{
                                    padding: windowSize.width > 992 ? "7px 20px" : "7px 15px",
                                    fontSize: windowSize.width > 992 ? "13px" : "11px",
                                    marginRight: "20px"
                                }}
                            />
                        :   null
                    }
                    {[owner, property_manager].includes(liveizy_id)
                        ?   <Button
                                buttonText="Update Schedule"
                                onClick={handleOpenAvailableSchedules}
                                className={clsx(classes.btnBlue, classes.btnBlueOutlined)}
                                style={{
                                    padding: windowSize.width > 992 ? "7px 20px" : "7px 15px",
                                    fontSize: windowSize.width > 992 ? "13px" : "11px",
                                    marginRight: "20px"
                                }}
                            />
                        :   null
                    }
                </div>
            );
        }*/
    };

    return (
        <div className={classes.apartmentDetails}>
            {showScheduleDates ? (
                <ScheduleBox
                    apartment_id={apartment_id}
                    apartmentSchedules={apartmentSchedules}
                    bookingInspection={bookingInspection}
                    successfullyBooked={successfullyBooked}
                    classes={classes}
                    handleBookDate={handleBookDate}
                    handleRedirectToDashboard={handleRedirectToDashboard}
                    booked={booked}
                />
            ) : (
                <ApartmentDetails data={data} classes={classes} />
            )}
            {renderButtons()}
        </div>
    );
};

export default memo(DetailsAndInspection);
