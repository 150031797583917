import React from "react";
import PaperContainer from "../../paperContainer/Index";
import { styled } from "@mui/material";
import { toCurrency } from "#/components/utils/util";
import CopyToClipboardBtn from "#/shared/components/buttons/CopyToClipboardBtn";

const COMPLETE = "complete";

const MonthlyRentInvoiceCard = ({ invoice }) => {
  const unpaidBills = invoice?.unpaid_bills;

  return (
    <div>
      <InvoiceCard>
        <Title>
          <p>Invoice</p>
          <p>First Payment for Monthly Rent</p>
        </Title>
        <InvoiceTable>
          <thead>
            <tr>
              <td>Payment Details</td>
              <td>Amount (₦)</td>
            </tr>
          </thead>

          <tbody>
            {unpaidBills?.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>{toCurrency(item.amount_calc).substring(1)}</td>
                {/* modified the above to use amount_calc instead of amount */}
              </tr>
            ))}
          </tbody>

          <tfoot>
            <tr>
              <td>Total Payable</td>
              <td>{toCurrency(invoice?.total_amount).substring(1)}</td>
            </tr>
          </tfoot>
        </InvoiceTable>

        <InvoiceDetails>
          <em>Copy & add Ref ID to transfer description when crediting wallet</em>
          <div>
            <p>
              Ref. ID: <span>{invoice?.invoice_ref}</span>
            </p>
            <CopyToClipboardBtn
              text={invoice?.invoice_ref}
              alertMessage={"Invoice Reference ID copied"}
            />
          </div>
          <StatusText status={invoice?.payment_status}>
            Payment Status:{" "}
            <span>{invoice?.payment_status === COMPLETE ? "Paid" : "Pending"}</span>
          </StatusText>
        </InvoiceDetails>
      </InvoiceCard>
    </div>
  );
};

export default MonthlyRentInvoiceCard;

const InvoiceCard = styled(PaperContainer)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.95rem;
  max-width: 350px;
  width: 100%;
  text-align: center;
  font-size: 0.85rem;
  color: #052137cc;
  padding: 2rem;
`;

const Title = styled("div")`
  p:first-of-type {
    font-weight: 700;
    text-transform: uppercase;
    color: #05213780;
    font-size: 1.1rem;
  }
`;

const InvoiceTable = styled("table")`
  thead tr {
    border-bottom: 3px dashed #c8202d;
    font-weight: 600;
    color: #052137cc;
    padding: 1rem;
    font-size: 1rem;
  }

  tfoot tr {
    border-top: 3px dashed #c8202d;
    font-weight: 700;
    padding: 2rem;
  }

  tfoot tr td {
    padding: 1rem 0rem 1rem;
    font-size: 1.3rem;
  }

  tr td:last-child {
    border-left: 1px solid #c8202d;
    color: #052137;
  }

  tbody td {
    padding: 5px 0;
    font-size: 1rem;
    text-transform: capitalize;
    color: #052137;
  }
`;

const InvoiceDetails = styled("div")`
  display: grid;
  gap: 0.225rem;
  em {
    color: #c8202d;
    font-size: 0.65rem;
  }

  div {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 1rem;
    margin: 0 auto;

    p {
      font-size: 0.75rem;
      span {
        color: #c8202d;
        font-size: 1rem;
      }
    }
  }
`;

const StatusText = styled("p")`
  span {
    color: ${(props) => (props.status === COMPLETE ? "#25CC22" : "#c8202d")};
    font-weight: 700;
  }
`;

// //FROM PETER IHIMIRE
// // const dispatch = useDispatch();

// const invoiceList = useSelector(selectTenantInvoices);
// // const loadingInvoices = useSelector(selectLoadingTenantInvoices);
// // const [invoices, setInvoices] = useState([]);

// console.log("Here we go, invoice list still exist from main card...", invoiceList);
// // const lastInvoice = invoiceList.pop();
// const lastInvoice = invoiceList[invoiceList.length - 1];
// console.log("This is the last invoice document from main card...", lastInvoice);

/* <tbody>
            {subscriptionFee > 0 ? (
              <tr>
                <td>Subscription fee</td>
                <td>{toCurrency(subscriptionFee).substring(1)}</td>
              </tr>
            ) : null}
            <tr>
              <td>Monthly Payment</td>
              <td>{toCurrency(monthlyRentPrice).substring(1)}</td>
            </tr>
            {securityDeposit > 0 ? (
              <tr>
                <td>Security Deposit</td>
                <td>{toCurrency(securityDeposit).substring(1)}</td>
              </tr>
            ) : null}
            {subscriptionFee || securityDeposit > 0 ? (
              <tr>
                <td>Contribution</td>
                <td>{toCurrency(tenantContribution).substring(1)}</td>
              </tr>
            ) : null}
          </tbody> */

/* <em>Copy & add Ref ID to transfer description when crediting wallet</em>
          <div>
            <p>
              Ref. ID: <span>{referenceId}</span>
            </p>
            <CopyToClipboardBtn
              text={referenceId}
              alertMessage={"Invoice Reference ID copied"}
            />
          </div>
          <StatusText status={paymentStatus}>
            Payment Status: <span>{paymentStatus === COMPLETE ? "Paid" : "Pending"}</span>
          </StatusText> */
