export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN = "LOGIN";
export const SIGNUP = "SIGNUP";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SAVE_TOKEN = "SAVE_TOKEN";
export const SET_FIRST_TIME = "SET_FIRST_TIME";
export const VALIDATE_USER_ID_SUCCESS = "VALIDATE_USER_ID_SUCCESS";
export const VALIDATE_USER_ID_FAIL = "VALIDATE_USER_ID_FAIL";
export const VALIDATE_USER_ID = "VALIDATE_USER_ID";

// Notification constants
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
export const SHOW_NOTIFICATIONS = "SHOW_NOTIFICATIONS";
export const alertConstants = {
  SUCCESS: "ALERT_SUCCESS",
  ERROR: "ALERT_ERROR",
  CLEAR: "ALERT_CLEAR"
};

// IzpPay Wallet constants
export const GET_IZYPAY_BALANCE = "GET_IZYPAY_BALANCE";
export const GET_IZYPAY_BALANCE_FAIL = "GET_IZYPAY_BALANCE_FAIL";
export const GET_IZYPAY_BALANCE_SUCCESS = "GET_IZYPAY_BALANCE_SUCCESS";

// Rent Wallet
export const GENERATE_RENT_WALLET = "GENERATE_RENT_WALLET";
export const GENERATE_RENT_WALLET_FAIL = "GENERATE_RENT_WALLET_FAIL";
export const GENERATE_RENT_WALLET_SUCCESS = "GENERATE_RENT_WALLET_SUCCESS";

// Update USER Profile constants
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAIL = "UPDATE_USER_PROFILE_FAIL";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";

// Service PopUp constant
export const LOAD_POPUP = "LOAD_POPUP";
export const ASSIGN_GENERAL_ROLE = "ASSIGN_GENERAL_ROLE";
export const SERVICE_POPUP = "SERVICE_POPUP";
export const ASSIGN_SERVICE_ROLES_PENDING = "ASSIGN_SERVICE_ROLES_PENDING";
export const ASSIGN_SERVICE_ROLES_SUCCESS = "ASSIGN_SERVICE_ROLES_SUCCESS";
export const ASSIGN_SERVICE_ROLES_FAIL = "ASSIGN_SERVICE_ROLES_FAIL";

//TRANSFER FROM WALLET
export const START_TRANSFER = "START_TRANSFER";
export const START_TRANSFER_SUCCESS = "START_TRANSFER_SUCCESS";
export const START_TRANSFER_FAIL = "START_TRANSFER_FAIL";
export const CLOSE_TRANSFER_POPUP = "CLOSE_TRANSFER_POPUP";

// PAYSTACK const
export const PAYSTACK_SUCCESS = "PAYSTACK_SUCCESS";
export const PAYSTACK_VERIFY_ACCOUNT = "PAYSTACK_VERIFY_ACCOUNT";
export const PAYSTACK_VERIFY_ACCOUNT_SUCCESS = "PAYSTACK_VERIFY_ACCOUNT_SUCCESS";
export const PAYSTACK_VERIFY_ACCOUNT_FAIL = "PAYSTACK_VERIFY_ACCOUNT_FAIL";
export const GET_BANKS_SUCCESS = "GET_BANKS_SUCCESS";
export const GET_BANKS_FAIL = "GET_BANKS_FAIL";

// IzyInvest constants
export const GET_IZYINVEST_BALANCE = "GET_IZYINVEST_BALANCE";
export const GET_IZYINVEST_BALANCE_FAIL = "GET_IZYINVEST_BALANCE_FAIL";
export const GET_IZYINVEST_BALANCE_SUCCESS = "GET_IZYINVEST_BALANCE_SUCCESS";
export const GET_IZYINVEST_HISTORY = "GET_IZYINVEST_HISTORY";
export const GET_IZYINVEST_HISTORY_FAIL = "GET_IZYINVEST_HISTORY_FAIL";
export const GET_IZYINVEST_HISTORY_SUCCESS = "GET_IZYINVEST_HISTORY_SUCCESS";
export const FUND_IZYVEST_WALLET = "FUND_IZYVEST_WALLET";
export const FUND_IZYVEST_WALLET_SUCCESS = "FUND_IZYVEST_WALLET_SUCCESS";
export const FUND_IZYVEST_WALLET_FAIL = "FUND_IZYVEST_WALLET_FAIL";
export const SAVE_CURRENT_PLANID = "SAVE_CURRENT_PLANID";
export const GET_IZYINVEST_PLANS_LIST = "GET_IZYINVEST_PLANS_LIST";
export const GET_IZYINVEST_PLANS_LIST_SUCCESS = "GET_IZYINVEST_PLANS_LIST_SUCCESS";
export const GET_IZYINVEST_PLANS_LIST_FAIL = "GET_IZYINVEST_PLANS_LIST_FAIL";

// Izyplan
export const GET_IZYPAY_PLAN = "GET_IZYPAY_PLAN";
export const ACTIVATE_IZYINVEST_PLAN = "ACTIVATE_IZYINVEST_PLAN";
export const ACTIVATE_IZYINVEST_PLAN_SUCCESS = "ACTIVATE_IZYINVEST_SUCCESS";
export const ACTIVATE_IZYINVEST_PLAN_FAIL = "ACTIVATE_IZYINVEST_PLAN_FAIL";

export const referralConstant = {
  GET_CANDIDATES_PENDING: "GET_CANDIDATES_PENDING",
  GET_CANDIDATES_SUCCESS: "GET_CANDIDATES_SUCCESS",
  GET_CANDIDATES_FAILURE: "GET_CANDIDATES_FAILURE",

  GET_MY_REFERRAL_PENDING: "GET_MY_REFERRAL_PENDING",
  GET_MY_REFERRAL_SUCCESS: "GET_MY_REFERRAL_SUCCESS",
  GET_MY_REFERRAL_FAILURE: "GET_MY_REFERRAL_FAILURE",

  GET_REFERRAL_BALANCE_PENDING: "GET_REFERRAL_BALANCE_PENDING",
  GET_REFERRAL_BALANCE_SUCCESS: "GET_REFERRAL_BALANCE_SUCCESS",
  GET_REFERRAL_BALANCE_FAILURE: "GET_REFERRAL_BALANCE_FAILURE",

  GET_ACCOUNT_DETAILS_PENDING: "GET_ACCOUNT_DETAILS_PENDING",
  GET_ACCOUNT_DETAILS_SUCCESS: "GET_ACCOUNT_DETAILS_SUCCESS",
  GET_ACCOUNT_DETAILS_FAILURE: "GET_ACCOUNT_DETAILS_FAILURE",

  UPDATE_ACCOUNT_DETAILS_PENDING: "UPDATE_ACCOUNT_DETAILS_PENDING",
  UPDATE_ACCOUNT_DETAILS_SUCCESS: "UPDATE_ACCOUNT_DETAILS_SUCCESS",
  UPDATE_ACCOUNT_DETAILS_FAILURE: "UPDATE_ACCOUNT_DETAILS_FAILURE",

  SEND_INVITATION_PENDING: "SEND_INVITATION_PENDING",
  SEND_INVITATION_SUCCESS: "SEND_INVITATION_SUCCESS",
  SEND_INVITATION_FAILURE: "SEND_INVITATION_FAILURE"
};

// Validate Nuban
export const VALIDATE_NUBAN = "VALIDATE_NUBAN";
export const VALIDATE_NUBAN_SUCCESS = "VALIDATE_NUBAN_SUCCESS";
export const VALIDATE_NUBAN_FAIL = "VALIDATE_NUBAN_FAIL";

//Resend Verification Email
export const SENDING_EMAIL_PENDING = " SENDING_EMAIL_PENDING";
export const SENDING_EMAIL_SUCCESS = " SENDING_EMAIL_SUCCESS";
export const SENDING_EMAIL_FAIL = " SENDING_EMAIL_FAIL";

//Forget Password x Email
export const SEND_FORGET_PASSWORD_PENDING = "SEND_FORGET_PASSWORD_PENDING";
export const SEND_FORGET_PASSWORD_SUCCESS = "SEND_FORGET_PASSWORD_SUCCESS";
export const SEND_FORGET_PASSWORD_FAIL = "SEND_FORGET_PASSWORD_FAIL";

//Reset Password
export const RESET_PASSWORD_PENDING = "RESET_PASSWORD_PENDING";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
//strings
export const PROPERTY = "property";
//Property actions
export const PROPERTY_ACTIONTYPES_CONSTANTS = {
  GET_PROP_ID_PENDING: "GET_PROP_ID_PENDING",
  GET_PROP_ID_SUCCESS: "GET_PROP_ID_SUCCESS",
  GET_PROP_ID_FAIL: "GET_PROP_ID_FAIL",

  CREATE_PROPERTY_PENDING: "CREATE_PROPERTY_PENDING",
  CREATE_PROPERTY_SUCCESS: "CREATE_PROPERTY_SUCCESS",
  CREATE_PROPERTY_FAIL: "CREATE_PROPERTY_FAIL",

  GET_ALL_PROPERTIES_PENDING: "GET_ALL_PROPERTIES_PENDING",
  GET_ALL_PROPERTIES_SUCCESS: "GET_ALL_PROPERTIES_SUCCESS",
  GET_ALL_PROPERTIES_FAIL: "GET_ALL_PROPERTIES_FAIL",

  GET_PROPERTY_BY_ID_PENDING: "GET_PROPERTY_BY_ID_PENDING",
  GET_PROPERTY_BY_ID_SUCCESS: "GET_PROPERTY_BY_ID_SUCCESS",
  GET_PROPERTY_BY_ID_FAIL: "GET_PROPERTY_BY_ID_FAIL",

  CLEAR_PROPERTY_INFO: "CLEAR_PROPERTY_INFO",
  UPDATE_PROPERTY_INFO: "UPDATE_PROPERTY_INFO",

  UPDATE_PROPERTY_PENDING: "UPDATE_PROPERTY_PENDING",
  UPDATE_PROPERTY_SUCCESS: "UPDATE_PROPERTY_SUCCESS",
  UPDATE_PROPERTY_FAIL: "UPDATE_PROPERTY_FAIL",

  DELETE_PROPERTY_PENDING: "DELETE_PROPERTY_PENDING",
  DELETE_PROPERTY_SUCCESS: "DELETE_PROPERTY_SUCCESS",
  DELETE_PROPERTY_FAIL: "DELETE_PROPERTY_FAIL",

  ADD_PM_PENDING: "ADD_PM_PENDING",
  ADD_PM_SUCCESS: "ADD_PM_SUCCESS",
  ADD_PM_FAIL: "ADD_PM_FAIL",

  CREATE_PRO_OWNER_PENDING: "CREATE_PRO_OWNER_PENDINGG",
  CREATE_PRO_OWNER_SUCCESS: "CREATE_PRO_OWNER_SUCCESS",
  CREATE_PRO_OWNER_FAIL: "CREATE_PRO_OWNER_FAIL",

  CREATE_PRO_MANAGER_PENDING: "CREATE_PRO_MANAGER_PENDINGG",
  CREATE_PRO_MANAGER_SUCCESS: "CREATE_PRO_MANAGER_SUCCESS",
  CREATE_PRO_MANAGER_FAIL: "CREATE_PRO_MANAGER_FAIL"
};

//Apartment actions
export const APARTMENT_ACTIONTYPES_CONSTANTS = {
  GET_PM_PO_TOTAL_APARTMENT_RENT_VALUE: "GET_PM_PO_TOTAL_APARTMENT_RENT_VALUE",
  GET_PM_PO_TOTAL_NUM_APARTMENTS: "GET_PM_PO_TOTAL_NUM_APARTMENTS",

  GET_APARTMENT_ID_PENDING: "GET_APARTMENT_ID_PENDING",
  GET_APARTMENT_ID_SUCCESS: "GET_APARTMENT_ID_SUCCESS",
  GET_APARTMENT_ID_FAIL: "GET_APARTMENT_ID_FAIL",

  CREATE_APARTMENT_PENDING: "CREATE_APARTMENT_PENDING",
  CREATE_APARTMENT_SUCCESS: "CREATE_APARTMENT_SUCCESS",
  CREATE_APARTMENT_FAIL: "CREATE_APARTMENT_FAIL",

  GET_APARTMENT_BY_ID_PENDING: "GET_APARTMENT_BY_ID_PENDING",
  GET_APARTMENT_BY_ID_SUCCESS: "GET_APARTMENT_BY_ID_SUCCESS",
  GET_APARTMENT_BY_ID_FAIL: "GET_APARTMENT_BY_ID_FAIL",

  UPDATE_APARTMENT_PENDING: "UPDATE_APARTMENT_PENDING",
  UPDATE_APARTMENT_SUCCESS: "UPDATE_APARTMENT_SUCCESS",
  UPDATE_APARTMENT_FAIL: "UPDATE_APARTMENT_FAIL",

  GET_VACANT_APARTMENTS_PENDING: "GET_VACANT_APARTMENTS_PENDING",
  GET_VACANT_APARTMENTS_SUCCESS: "GET_VACANT_APARTMENTS_SUCCESS",
  GET_VACANT_APARTMENTS_FAIL: "GET_VACANT_APARTMENTS_FAIL",

  DELETE_APARTMENT_PENDING: "DELETE_APARTMENT_PENDING",
  DELETE_APARTMENT_SUCCESS: "DELETE_APARTMENT_SUCCESS",
  DELETE_APARTMENT_FAIL: "DELETE_APARTMENT_FAIL",

  FILTER_VACANT_APARTMENTS_PENDING: "FILTER_VACANT_APARTMENTS_PENDING",
  FILTER_VACANT_APARTMENTS_SUCCESS: "FILTER_VACANT_APARTMENTS_SUCCESS",
  FILTER_VACANT_APARTMENTS_FAIL: "FILTER_VACANT_APARTMENTS_FAIL",

  ADVERTISE_APARTMENTS_PENDING: "ADVERTISE_APARTMENTS_PENDING",
  ADVERTISE_APARTMENTS_SUCCESS: "ADVERTISE_APARTMENTS_SUCCESS",
  ADVERTISE_APARTMENTS_FAIL: "ADVERTISE_APARTMENTS_FAIL",

  RESET_FILTER: "RESET_FILTER"
};

// CHARGES ACTIONS
export const CHARGES_ACTIONTYPES_CONSTANTS = {
  GET_CHARGES_IN_APARTMENT_PENDING: "GET_CHARGES_IN_APARTMENT_PENDING",
  GET_CHARGES_IN_APARTMENT_SUCCESS: "GET_CHARGES_IN_APARTMENT_SUCCESS",
  GET_CHARGES_IN_APARTMENT_FAIL: "GET_CHARGES_IN_APARTMENT_FAIL",

  CREATE_CHARGES_IN_APARTMENT_PENDING: "CREATE_CHARGES_IN_APARTMENT_PENDING",
  CREATE_CHARGES_IN_APARTMENT_SUCCESS: "CREATE_CHARGES_IN_APARTMENT_SUCCESS",
  CREATE_CHARGES_IN_APARTMENT_FAIL: "CREATE_CHARGES_IN_APARTMENT_FAIL",

  UPDATE_CHARGE_PENDING: "UPDATE_CHARGE_PENDING",
  UPDATE_CHARGE_SUCCESS: "UPDATE_CHARGE_SUCCESS",
  UPDATE_CHARGE_FAIL: "UPDATE_CHARGE_FAIL",

  DELETE_CHARGE_PENDING: "DELETE_CHARGE_PENDING",
  DELETE_CHARGE_SUCCESS: "DELETE_CHARGE_SUCCESS",
  DELETE_CHARGE_FAIL: "DELETE_CHARGE_FAIL"
};

export const SCHEDULES_CONSTANTS = {
  CREATE_SCHEDULE: "CREATE_SCHEDULE",
  DELETE_SCHEDULE: "DELETE_SCHEDULE",
  UPDATE_SCHEDULE: "UPDATE_SCHEDULE",
  FECTH_SCHEDULE: "FECTH_SCHEDULE",
  CREATE_SCHEDULE_APARTMENT_PENDING: "CREATE_SCHEDULE_APARTMENT_PENDING",
  CREATE_SCHEDULE_APARTMENT_SUCCESS: "CREATE_SCHEDULE_APARTMENT_SUCCESS",
  CREATE_SCHEDULE_APARTMENT_FAIL: "CREATE_SCHEDULE_APARTMENT_FAIL",

  GET_SCHEDULES_IN_APARTMENT_PENDING: "GET_SCHEDULES_IN_APARTMENT_PENDING",
  GET_SCHEDULES_IN_APARTMENT_SUCCESS: "GET_SCHEDULES_IN_APARTMENT_SUCCESS",
  GET_SCHEDULES_IN_APARTMENT_FAIL: "GET_SCHEDULES_IN_APARTMENT_FAIL",

  UPDATE_SCHEDULE_PENDING: "UPDATE_SCHEDULE_PENDING",
  UPDATE_SCHEDULE_SUCCESS: "UPDATE_SCHEDULE_SUCCESS",
  UPDATE_SCHEDULE_FAIL: "UPDATE_SCHEDULE_FAIL",

  DELETE_SCHEDULE_PENDING: "DELETE_SCHEDULE_PENDING",
  DELETE_SCHEDULE_SUCCESS: "DELETE_SCHEDULE_SUCCESS",
  DELETE_SCHEDULE_FAIL: "DELETE_SCHEDULE_FAIL"
};

export const TENANTS_CONSTANTS = {
  CREATE_TENANT_PENDING: "CREATE_TENANT_PENDING",
  CREATE_TENANT_SUCCESS: "CREATE_TENANT_SUCCESS",
  CREATE_TENANT_FAIL: "CREATE_TENANT_FAIL",

  CREATE_TENANT_BY_LID_PENDING: "CREATE_TENANT_BY_LID_PENDING",
  CREATE_TENANT_BY_LID_SUCCESS: "CREATE_TENANT_BY_LID_SUCCESS",
  CREATE_TENANT_BY_LID_FAIL: "CREATE_TENANT_BY_LID_FAIL",

  GET_APARTMENT_TENANTS_PENDING: "GET_APARTMENT_TENANTS_PENDING",
  GET_APARTMENT_TENANTS_SUCCESS: "GET_APARTMENT_TENANTS_SUCCESS",
  GET_APARTMENT_TENANTS_FAIL: "GET_APARTMENT_TENANTS_FAIL",

  GET_TENANTS_UNDER_PM_PENDING: "GET_TENANTS_UNDER_PM_PENDING",
  GET_TENANTS_UNDER_PM_SUCCESS: "GET_TENANTS_UNDER_PM_SUCCESS",
  GET_TENANTS_UNDER_PM_FAIL: "GET_TENANTS_UNDER_PM_FAIL",

  CLEAR_CREATED_TENANT: "CLEAR_CREATED_TENANT",

  GET_TENANTS_APARTMENT_PENDING: "GET_TENANTS_APARTMENT_PENDING",
  GET_TENANTS_APARTMENT_SUCCESS: "GET_TENANTS_APARTMENT_SUCCESS",
  GET_TENANT_RECORDS: "GET_TENANT_RECORDS",
  GET_TENANTS_APARTMENT_FAIL: "GET_TENANTS_APARTMENT_FAIL",
  GET_TENANT_RECORD_PENDING: "GET_TENANT_RECORD_PENDING",
  GET_TENANT_RECORD_SUCCESS: "GET_TENANT_RECORD_SUCCESS",
  GET_TENANT_RECORD_FAIL: "GET_TENANT_RECORD_FAIL",
  GET_MONTHLY_RENT_DETAILS_SUCCESS: "GET_MONTHLY_RENT_DETAILS_SUCCESS",
  GET_MONTHLY_RENT_DETAILS_FAILED: "GET_MONTHLY_RENT_DETAILS_FAILED",

  GET_TENANT_BILLS: "GET_TENANT_BILLS",
  GET_TENANT_BILLS_SUCCESS: "GET_TENANT_BILLS_SUCCESS",
  GET_TENANT_BILLS_FAILED: "GET_TENANT_BILLS_FAILED",

  GET_TENANT_INVOICES: "GET_TENANT_INVOICES",
  GET_TENANT_INVOICES_SUCCESS: "GET_TENANT_INVOICES_SUCCESS",
  GET_TENANT_INVOICES_FAILED: "GET_TENANT_INVOICES_FAILED"
};

export const REMINDER_CONSTANTS = {
  CREATE_PROPERTY_REMINDER_PENDING: "CREATE_PROPERTY_REMINDER_PENDING",
  CREATE_PROPERTY_REMINDER_SUCCESS: "CREATE_PROPERTY_REMINDER_SUCCESS",
  CREATE_PROPERTY_REMINDER_FAIL: "CREATE_PROPERTY_REMINDER_FAIL",

  CREATE_APARTMENT_REMINDER_PENDING: "CREATE_APARTMENT_REMINDER_PENDING",
  CREATE_APARTMENT_REMINDER_SUCCESS: "CREATE_APARTMENT_REMINDER_SUCCESS",
  CREATE_APARTMENT_REMINDER_FAIL: "CREATE_APARTMENT_REMINDER_FAIL",

  GET_INACTIVE_REMINDERS_PENDING: "GET_INACTIVE_REMINDERS_PENDING",
  GET_INACTIVE_REMINDERS_SUCCESS: "GET_INACTIVE_REMINDERS_SUCCESS",
  GET_INACTIVE_REMINDERS_FAIL: "GET_INACTIVE_REMINDERS_FAIL",

  GET_ACTIVE_REMINDERS_PENDING: "GET_ACTIVE_REMINDERS_PENDING",
  GET_ACTIVE_REMINDERS_SUCCESS: "GET_ACTIVE_REMINDERS_SUCCESS",
  GET_ACTIVE_REMINDERS_FAIL: "GET_ACTIVE_REMINDERS_FAIL",

  GET_DELIVERED_REMINDERS_PENDING: "GET_DELIVERED_REMINDERS_PENDING",
  GET_DELIVERED_REMINDERS_SUCCESS: "GET_DELIVERED_REMINDERS_SUCCESS",
  GET_DELIVERED_REMINDERS_FAIL: "GET_DELIVERED_REMINDERS_FAIL",

  RENT_REMINDER_PAYMENT_PENDING: "RENT_REMINDER_PAYMENT_PENDING",
  RENT_REMINDER_PAYMENT_SUCCESS: "RENT_REMINDER_PAYMENT_SUCCESS",
  RENT_REMINDER_PAYMENT_FAIL: "RENT_REMINDER_PAYMENT_FAIL",

  CHECK_REMINDERS_EXIST_PENDING: "CHECK_REMINDERS_EXIST_PENDING",
  CHECK_REMINDERS_EXIST_SUCCESS: "CHECK_REMINDERS_EXIST_SUCCESS",
  CHECK_REMINDERS_EXIST_FAIL: "CHECK_REMINDERS_EXIST_FAIL",

  GET_SUBSCRIPTION_REMINDERS_SUMMARY_PENDING:
    "GET_SUBSCRIPTION_REMINDERS_SUMMARY_PENDING",
  GET_SUBSCRIPTION_REMINDERS_SUMMARY_SUCCESS:
    "GET_SUBSCRIPTION_REMINDERS_SUMMARY_SUCCESS",
  GET_SUBSCRIPTION_REMINDERS_SUMMARY_FAIL: "GET_SUBSCRIPTION_REMINDERS_SUMMARY_FAIL",

  GET_APARTMENTS_IN_PLAN_PENDING: "GET_APARTMENTS_IN_PLAN_PENDING",
  GET_APARTMENTS_IN_PLAN_SUCCESS: "GET_APARTMENTS_IN_PLAN_SUCCESS",
  GET_APARTMENTS_IN_PLAN_FAIL: "GET_APARTMENTS_IN_PLAN_FAIL"
};

export const BOOKING_CONSTANTS = {
  BOOK_INSPECTION_PENDING: "BOOK_INSPECTION_PENDING",
  BOOK_INSPECTION_FAIL: "BOOK_INSPECTION_FAIL",
  BOOK_INSPECTION_SUCCESS: "BOOK_INSPECTION_SUCCESS",

  CLEAR_BOOKING_FIELDS: "CLEAR_BOOKING_FIELDS",

  GET_MY_BOOKINGS_PENDING: "GET_MY_BOOKINGS_PENDING",
  GET_MY_BOOKINGS_SUCCESS: "GET_MY_BOOKINGS_SUCCESS",
  GET_MY_BOOKINGS_FAIL: "GET_MY_BOOKINGS_FAIL",

  DELETE_INSPECTION_PENDING: "DELETE_INSPECTION_PENDING",
  DELETE_INSPECTION_SUCCESS: "DELETE_INSPECTION_SUCCESS",
  DELETE_INSPECTION_FAIL: "DELETE_INSPECTION_FAIL",

  GET_APARTMENTS_BOOKED_PENDING: "GET_APARTMENTS_BOOKED_PENDING",
  GET_APARTMENTS_BOOKED_SUCCESS: "GET_APARTMENTS_BOOKED_SUCCESS",
  GET_APARTMENTS_BOOKED_FAIL: "GET_APARTMENTS_BOOKED_FAIL",

  REGISTER_SHOW_OF_INTEREST_PENDING: "REGISTER_SHOW_OF_INTEREST_PENDING",
  REGISTER_SHOW_OF_INTEREST_SUCCESS: "REGISTER_SHOW_OF_INTEREST_SUCCESS",
  REGISTER_SHOW_OF_INTEREST_FAIL: "REGISTER_SHOW_OF_INTEREST_FAIL",

  GET_APARTMENT_BOOKINGS_PENDING: "GET_APARTMENT_BOOKINGS_PENDING",
  GET_APARTMENT_BOOKINGS_SUCCESS: "GET_APARTMENT_BOOKINGS_SUCCESS",
  GET_APARTMENT_BOOKINGS_FAIL: "GET_APARTMENT_BOOKINGS_FAIL"
};

export const PLANS_CONSTANTS = {
  CREATE_APARTMENT_LIPMAN_PLAN_PENDING: "CREATE_APARTMENT_LIPMAN_PLAN_PENDING",
  CREATE_APARTMENT_LIPMAN_PLAN_SUCCESS: "CREATE_APARTMENT_LIPMAN_PLAN_SUCCESS",
  CREATE_APARTMENT_LIPMAN_PLAN_FAIL: "CREATE_APARTMENT_LIPMAN_PLAN_FAIL",
  CREATE_APARTMENT_LIREM_PLAN_PENDING: "CREATE_APARTMENT_LIREM_PLAN_PENDING",
  CREATE_APARTMENT_LIREM_PLAN_SUCCESS: "CREATE_APARTMENT_LIREM_PLAN_SUCCESS",
  CREATE_APARTMENT_LIREM_PLAN_FAIL: "CREATE_APARTMENT_LIREM_PLAN_FAIL"
};

//Used for submitting show of interest form
export const UPDATE_USER_OTHER_INFORMATION_PENDING =
  "UPDATE_USER_OTHER_INFORMATION_PENDING";
export const UPDATE_USER_OTHER_INFORMATION_SUCCESS =
  "UPDATE_USER_OTHER_INFORMATION_SUCCESS";
export const UPDATE_USER_OTHER_INFORMATION_FAIL = "UPDATE_USER_OTHER_INFORMATION_FAIL";

export const GET_USER_OTHER_INFORMATION_PENDING = "GET_USER_OTHER_INFORMATION_PENDING";
export const GET_USER_OTHER_INFORMATION_SUCCESS = "GET_USER_OTHER_INFORMATION_SUCCESS";
export const GET_USER_OTHER_INFORMATION_FAIL = "GET_USER_OTHER_INFORMATION_FAIL";

export const UPDATE_PROFILE_INFORMATION_PENDING = "UPDATE_PROFILE_INFORMATION_PENDING";
export const UPDATE_PROFILE_INFORMATION_SUCCESS = "UPDATE_PROFILE_INFORMATION_SUCCESS";
export const UPDATE_PROFILE_INFORMATION_FAIL = "UPDATE_PROFILE_INFORMATION_FAIL";

export const GET_PROFILE_INFORMATION_PENDING = "GET_PROFILE_INFORMATION_PENDING";
export const GET_PROFILE_INFORMATION_SUCCESS = "GET_PROFILE_INFORMATION_SUCCESS";
export const GET_PROFILE_INFORMATION_FAIL = "GET_PROFILE_INFORMATION_FAIL";

export const UPDATE_BUSINESS_INFORMATION_PENDING = "UPDATE_BUSINESS_INFORMATION_PENDING";
export const UPDATE_BUSINESS_INFORMATION_SUCCESS = "UPDATE_BUSINESS_INFORMATION_SUCCESS";
export const UPDATE_BUSINESS_INFORMATION_FAIL = "UPDATE_BUSINESS_INFORMATION_FAIL";

export const GET_BUSINESS_INFORMATION_PENDING = "GET_BUSINESS_INFORMATION_PENDING";
export const GET_BUSINESS_INFORMATION_SUCCESS = "GET_BUSINESS_INFORMATION_SUCCESS";
export const GET_BUSINESS_INFORMATION_FAIL = "GET_BUSINESS_INFORMATION_FAIL";

export const ESTATE_PAYMENTS = {
  PAY_ESTATE_FEES_PENDING: "PAY_ESTATE_FEES_PENDING",
  PAY_ESTATE_FEES_SUCCESS: "PAY_ESTATE_FEES_SUCCESS",
  PAY_ESTATE_FEES_FAIL: "PAY_ESTATE_FEES_FAIL"
};

export const APARTMENT_PAYMENTS = {
  PAY_RENT_PENDING: "PAY_RENT_PENDING",
  PAY_RENT_SUCCESS: "PAY_RENT_SUCCESS",
  PAY_RENT_FAIL: "PAY_RENT_FAIL",

  RENEW_RENT_PENDING: "RENEW_RENT_PENDING",
  RENEW_RENT_SUCCESS: "RENEW_RENT_SUCCESS",
  RENEW_RENT_FAIL: "RENEW_RENT_FAIL",

  PAY_APARTMENT_CHARGES_PENDING: "PAY_APARTMENT_CHARGES_PENDING",
  PAY_APARTMENT_CHARGES_SUCCESS: "PAY_APARTMENT_CHARGES_SUCCESS",
  PAY_APARTMENT_CHARGES_FAIL: "PAY_APARTMENT_CHARGES_FAIL",

  RESET_PAYMENT_STORE_DETAILS: "RESET_PAYMENT_STORE_DETAILS",

  GET_RENT_RECORDS_PENDING: "GET_RENT_RECORDS_PENDING",
  GET_RENT_RECORDS_SUCCESS: "GET_RENT_RECORDS_SUCCESS",
  GET_RENT_RECORDS_FAIL: "GET_RENT_RECORDS_FAIL"
};

export const PERMISSIONS_CONSTANTS = {
  ESTATES: "ESTATES",
  BANK_ACCOUNTS: "BANK_ACCOUNTS",
  FEE: "FEE",
  MEMBERS: "MEMBERS",
  FEE_RECORDS: "FEE_RECORDS",
  ROLES: "ROLES",
  PERMISSIONS: "PERMISSIONS"
};

export const FETCH_MEMBERS_PENDING = "FETCH_MEMBERS_PENDING";
export const FETCH_MEMBERS_SUCCESS = "FETCH_MEMBERS_SUCCESS";
export const FETCH_MEMBERS_FAIL = "FETCH_MEMBERS_FAIL";
export const ADD_MEMBER = "ADD_MEMBER";
export const DELETE_MEMBER = "DELETE_MEMBER";

export const SET_VALIDATION_ERRORS = "SET_VALIDATION_ERRORS";
export const CLEAR_VALIDATION_ERRORS = "CLEAR_VALIDATION_ERRORS";
