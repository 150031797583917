import React from "react";
import { Button, Grid } from "@material-ui/core";
import avatar from "#/assets/images/tenant/default-avatar.png";
import CustomButton from "#/components/ui-components/button.component";
import { Styles } from "./style";
import mail from "#/assets/settingsAssest/mail.svg";
import setting from "#/assets/settingsAssest/settings.svg";
import avartar from "#/assets/settingsAssest/avartar.svg";
import "./style.scss";

const Subscription = (props) => {
    const classes = Styles();
    return (
        <section style={{ paddingRight: "10px" }}>
            <div className={classes.subscription_tab}>
                <p>Subscribe</p>
                <div className="icon" onClick={() => props.showSettings()}>
                    <img src={setting} alt="icon" />
                </div>
            </div>
            <form>
                <div className={classes.forms}>
                    <div className={classes.inputBox}>
                        <label htmlFor="total_apartment">Total Number of apartments</label>
                        <input type="text" name="total_apartment" />
                    </div>
                </div>
                <div className={classes.forms}>
                    <div className={classes.inputIcon}>
                        <label htmlFor="add_apartment">Add Number of apartment for Lease & Agreement</label>
                        <div className="form-grop">
                            <span>
                                <img src={avartar} alt="" />
                            </span>
                            <input type="text" className="form-icon" name="add_apartment" />
                        </div>
                    </div>
                </div>
                <div className={classes.forms}>
                    <div className={classes.inputIcon}>
                        <label htmlFor="no_reminder">Number of Reminder for each apartment</label>
                        <div className="form-grop">
                            <span>
                                <img src={mail} alt="" />
                            </span>
                            <input type="text" className="form-icon" name="no_reminder" />
                        </div>
                    </div>
                </div>
                <div className={classes.forms}>
                    <div className={classes.inputBox}>
                        <label htmlFor="total_reminder">Total Reminders</label>
                        <input type="text" name="total_reminder" />
                    </div>
                </div>
                <div className={classes.forms}>
                    <div className={classes.inputBox}>
                        <label htmlFor="total">Total</label>
                        <input type="text" name="total" />
                    </div>
                </div>

                <div style={{ marginTop: "40px" }}>
                    <CustomButton
                        type="submit"
                        buttonText="Pay"
                        style={{
                            backgroundColor: "#4786FF",
                            color: "#FFFFFF",
                            textTransform: "none",
                            background: "#4786FF",
                            borderRadius: "5px",
                            color: "#F4F5F7",
                            border: "1px solid #4786FF",
                            padding: "10px 30px",
                            fontSize: "13px",
                            fontFamily: "Open Sans-Semibold, sans-serif",
                            width: "145px"
                        }}
                    />
                </div>
            </form>
        </section>
    );
};

export default Subscription;
