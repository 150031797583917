import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";

const styles = {
    paper: {
        width: "250px"
    },
    popover: {
        pointerEvents: "none"
    },
    container: {
        "& header.doubleitems, .wrapper-div": {
            padding: "15px !important"
        },
        "& .item-container": {
            paddingTop: "0px !important",
            paddingRight: "0px !important",
            paddingLeft: "0px !important",
            paddingBottom: 8
        },
        "& .item-container:last-child": {
            paddingBottom: "0px !important"
        },
        "& span": {
            fontSize: 11,
            color: "#dadae8",
            marginBottom: 3
        },
        "& header": {
            backgroundColor: "#d2e4f7"
        },
        "& .doubleitems": {
            display: "flex",
            justifyContent: "space-between"
        },
        "& p": {
            fontSize: 14,
            color: "#101730",
            fontWeight: "700",
            marginBottom: "0 !important"
        },
        "& .edit-invoice": {
            color: "#1866e7"
        },
        "& .paid-icon": {
            color: "#32a85e"
        },
        "& .due-icon": {
            color: "#db3535"
        }
    }
};

const DetailPopOver = (props) => {
    const { classes, content, anchorE, popID, id, onClose } = props;
    // console.log(popID === id, content)
    return (
        <Popover
            className={classes.paper}
            classes={{
                paper: classes.paper
            }}
            open={popID === id}
            anchorEl={anchorE}
            onClose={onClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center"
            }}>
            <div className={classes.container}>
                <header className="doubleitems">
                    {content.status === "Paid" || content.status === "Partially paid" ? (
                        <CheckCircleOutlineIcon className="paid-icon" />
                    ) : (
                        <ReportProblemOutlinedIcon className="due-icon" />
                    )}{" "}
                    <p>{content.status}</p>
                </header>
                <div className="wrapper-div">
                    <div className="doubleitems item-container">
                        <div>
                            <span>Due Date</span>
                            <p>{content.dueDate}</p>
                        </div>
                        <div>
                            <span>Total Due</span>
                            <p>&#x20A6; {content.totalDue}</p>
                        </div>
                    </div>
                    <div className="item-container">
                        <span>Reminder sent</span>
                        <p>{content.reminder}</p>
                    </div>
                    <div className="doubleitems item-container">
                        <div>
                            <span>Invoice Item</span>
                            <p>{content.invoiceItem}</p>
                        </div>
                        <div>
                            <p>&#x20A6; {content.invoiceItemAmount}</p>
                        </div>
                    </div>
                    <div className="item-container">
                        <span>Payment Submitted</span>
                        <p>{content.paymentName}</p>
                    </div>
                    <div className="item-container">
                        <span className="edit-invoice">Edit Invoice</span>
                    </div>
                </div>
            </div>
        </Popover>
    );
};

export default withStyles(styles)(DetailPopOver);
