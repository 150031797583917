import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const styles = {
    header: {
        marginBottom: 30
    },
    title: {
        textTransform: "uppercase",
        color: "#032F4A",
        fontWeight: 800
    }
};

const Header = (props) => (
    <header className={props.classes.header}>
        <div>
            <Typography className={props.classes.title} variant="h5" component="h1">
                <b>{props.title}</b>
            </Typography>
        </div>
    </header>
);

export default withStyles(styles)(Header);
