import { Box, styled } from "@mui/material";

const PageContainer = styled(Box)`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export default PageContainer;
