import React from "react";
import AddButton from "#/components/propertycomponents/button-component";

import sadFace from "#/assets/svg/sad.svg";
import { makeStyles } from "@material-ui/core/styles";

const SadFaceIconComponent = (props) => {
    const Styles = makeStyles((theme) => ({
        empty: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
            marginTop: "60px",

            "& >img": {
                width: "200px",
                marginBottom: "7px"
            },

            "& p": {
                color: "#062239",
                fontFamily: "Open Sans, sans-serif",
                marginBottom: "7px"
            }
        }
    }));

    const classes = Styles();
    return (
        <div className={classes.empty}>
            <img src={sadFace} width="35%" alt="sad face" />
            {props.children}
        </div>
    );
};

export default SadFaceIconComponent;
