import { makeStyles, withStyles, createTheme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

export const TypographyText = withStyles((theme) => ({
  root: {
    color: "#043B5C",
    opacity: 1,
    fontFamily: "inherit"
  },
  h3: {
    fontWeight: "bold",
    fontSize: "27px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
      fontWeight: "bold"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      fontWeight: "bold"
    }
  },
  h4: {
    fontWeight: 900,
    fontSize: "14px"
  },
  h6: {
    fontWeight: 700,
    fontSize: 12
  },
  textLg: {
    fontWeight: 600,
    fontSize: 18,
    color: "#052137"
  },
  body2: {
    fontWeight: 200,
    fontSize: 10,
    position: "relative",
    marginLeft: "-5px"
  },
  h5: {
    fontSize: 17
  },
  subtitle1: {
    fontWeight: 400,
    marginTop: "40%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      marginTop: 0
    }
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: "600",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px"
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "11px"
    }
  },
  body1: {
    fontSize: "12px"
  },
  caption: {
    fontSize: "12px",
    [theme.breakpoints.only("sm")]: {
      fontSize: 11
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 10
    }
  }
}))(Typography);

export const radioStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  icon: {
    borderRadius: "50%",
    width: 7,
    height: 7,
    boxShadow: "1px 20px 30px #3498DB1A",
    background: "#F3F5F7 0% 0% no-repeat padding-box",
    opacity: 1,
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5"
    }
  },
  checkedIcon: {
    background: "#4786FF 0% 0% no-repeat padding-box",
    opacity: 1,
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""'
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3"
    }
  }
});

export const useStyles = makeStyles((theme) => ({
  align: {
    display: "flex",
    alignItems: "center"
  },
  paper: {
    display: "flex",
    marginBottom: "2%",
    boxShadow: "1px 20px 30px #3498DB1A",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "4%"
    }
  },
  span: {
    background: "#4786FF73 0% 0% no-repeat padding-box",
    borderRadius: "10px 0px 0px 10px",
    opacity: 1,
    width: "5px",
    display: "inline-block",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      width: "4px"
    }
  },
  active: {
    background: "#332A7C 0% 0% no-repeat padding-box",
    borderRadius: "10px 0px 0px 10px",
    opacity: 1,
    width: "5px",
    display: "inline-block",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      width: "4px"
    }
  },
  container: {
    padding: "24px 30px 28px",
    [theme.breakpoints.down("sm")]: {
      padding: "12px 2% 10px"
    }
  },
  radioPosi: {
    paddingLeft: "15px",
    "& .MuiFormControlLabel-root": {
      marginRight: "7px !important"
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "7px",
      display: "contents"
    },
    [theme.breakpoints.only("md")]: {
      paddingLeft: "20%"
    }
  },
  radioLabel: {
    color: "#043B5C",
    fontSize: "14px",
    marginTop: "30%",
    [theme.breakpoints.down("xs")]: {
      fontSize: 10
    }
  },
  align2: {
    alignItems: "baseline",
    marginTop: "-5%",
    [theme.breakpoints.down("xs")]: {
      // marginLeft: '15%',
    }
  },
  recurringContent: {
    display: "flex",
    flexFlow: "row wrap",
    width: "55%",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  radioDiv: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: "8px",
      display: "flex"
    }
  },
  chooseBtn: {
    color: "white",
    backgroundColor: "#4786FF",
    padding: "0px 12%",
    position: "relative",
    bottom: "10%",
    textTransform: "none",
    fontSize: 12,
    [theme.breakpoints.down("xs")]: {
      fontSize: 10
    }
  }
}));
