import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { FaAngleRight } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { propertyServiceApi, returnEstateMember } from "#/apis/propertyServiceApi";
import PageLoader from "../../ui-components/pageLoader";
import { useDispatch, useSelector } from "react-redux";
import { alertError, alertSuccess } from "#/store/actions/notification.actions";
import liveizyLogo from "#/assets/images/liveizy-logo.png";
import BackArrow from "../back-arrow";
import { ESM_ROUTES } from "#/constants/routes";
import { logout } from "#/store/actions/auth.actions";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

const styles = makeStyles((theme) => ({
  topBar: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    borderBottom: "1.5px solid gainsboro",
    padding: "10px 0",
    "& h4": {
      width: "100%",
      fontFamily: "Montserrat",
      fontWeight: 600,
      color: "#1461a2",
      fontSize: 20
    }
  },
  menuChildren: {
    maxWidth: "900px",
    margin: "0 auto 50px",
    "& h1,h2,h3,h4,h5,h6": {
      fontFamily: "Montserrat"
    },
    "& div,p,span,table": {
      fontFamily: "Nunito"
    }
  },
  sideBarTop: {
    padding: "30px 20px"
  },
  backToMain: {
    padding: "0 20px"
  }
}));

function TemporaryDrawer(props) {
  const { user } = useSelector((state) => state.auth);
  const classes = styles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { member, memberRole } = returnEstateMember();
  // const permissions = memberRole?.permissions.map((perm) => perm.key);

  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  // React.useEffect(() => {
  //     if (member) return;
  //     setUpMemberDetails();
  // }, []);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const setUpMemberDetails = () => {
    setLoading(true);
    propertyServiceApi()
      .post("/estate-management/member/token")
      .then((data) => {
        const { member, role, token } = data.data.payload;
        localStorage.setItem("ESTATE_MEMBER", JSON.stringify(member));
        localStorage.setItem("ESTATE_ROLE", JSON.stringify(role));
        localStorage.setItem("PERMISSION_TOKEN", token);
        setLoading(false);
        dispatch(alertSuccess("You are successfully logged in to your Estate!!!"));
      })
      .catch((err) => {
        setLoading(false);
        // history.push("/dashboard");
        dispatch(
          alertError(
            "You currently have not been added to any estate. Reach out to your Estate Admin"
          )
        );
      });
  };

  const list = (anchor, classes) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      <div className={classes.sideBarTop}>
        <Link to="/dashboard">
          <img src={liveizyLogo} />
        </Link>
      </div>
      <div className={classes.backToMain}>
        <BackArrow link="/dashboard" />
      </div>
      <Divider />
      <List>
        <Link to={ESM_ROUTES.dashboard}>
          <ListItem button>
            <ListItemText primary="Dashboard" />
            <ListItemIcon>
              <FaAngleRight className="menu-item-icon" />
            </ListItemIcon>
          </ListItem>
        </Link>
        {/* {(member?.super_admin || permissions?.includes("2aM")) && ( */}
        <Link to={ESM_ROUTES.members}>
          <ListItem button>
            <ListItemText primary="Members" />
            <ListItemIcon>
              <FaAngleRight className="menu-item-icon" />
            </ListItemIcon>
          </ListItem>
        </Link>
        {/* )} */}
        {/* {member?.super_admin && ( */}
        <Link to={ESM_ROUTES.roles}>
          <ListItem button>
            <ListItemText primary="Manage Roles" />
            <ListItemIcon>
              <FaAngleRight className="menu-item-icon" />
            </ListItemIcon>
          </ListItem>
        </Link>
        {/* )} */}
        {/* {member?.super_admin && ( */}
        <Link to={ESM_ROUTES.finance}>
          <ListItem button>
            <ListItemText primary="Financial" />
            <ListItemIcon>
              <FaAngleRight className="menu-item-icon" />
            </ListItemIcon>
          </ListItem>
        </Link>
        {/* )} */}
        <Link to={ESM_ROUTES.payments.replace(":lid", user.liveizy_id)}>
          <ListItem button>
            <ListItemText primary="Payments" />
            <ListItemIcon>
              <FaAngleRight className="menu-item-icon" />
            </ListItemIcon>
          </ListItem>
        </Link>
        {/* {member?.super_admin && ( */}
        <Link to={ESM_ROUTES.fee}>
          <ListItem button>
            <ListItemText primary="Fees" />
            <ListItemIcon>
              <FaAngleRight className="menu-item-icon" />
            </ListItemIcon>
          </ListItem>
        </Link>
        {/* )} */}
        {/* {member?.super_admin && ( */}
        <Link to={ESM_ROUTES.summary}>
          <ListItem button>
            <ListItemText primary="Summary" />
            <ListItemIcon>
              <FaAngleRight className="menu-item-icon" />
            </ListItemIcon>
          </ListItem>
        </Link>
        {/* )} */}
      </List>
      <Divider />
      <br />
      <ListItem button onClick={() => dispatch(logout())}>
        <ListItemIcon>
          <PowerSettingsNewIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItem>
    </Box>
  );

  // if (!member || loading) return <PageLoader />;

  return (
    <div>
      <React.Fragment>
        <div className={classes.topBar}>
          <Button onClick={toggleDrawer("left", true)}>
            <MenuIcon />
          </Button>
          <h4>Estate Management</h4>
        </div>
        <Drawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}>
          {list("left", classes)}
        </Drawer>
        <div className={classes.menuChildren}>{props.children}</div>
      </React.Fragment>
    </div>
  );
}

export default TemporaryDrawer;
