import React from "react";
import Card from "@mui/material/Card";
import ThreeDotMenu from "#/components/estateManagementComponents/three-dots-menu";
import { CircularProgress } from "@mui/material";

const FeeRibbon = ({ fee, deleteFee, loadingDelete }) => {
    const menuItems = [{ title: "Delete fee", action: deleteFee }];
    return (
        <Card className="fee-details">
            {loadingDelete[fee._id] ? (
                <CircularProgress size={20} />
            ) : (
                <div className="mem-det-div1">
                    <ThreeDotMenu menuItems={menuItems} />
                </div>
            )}
            <div className="mem-det-div2">
                <span className="fee-title">{fee?.title}</span>
                <span className="fee-amount">{fee?.amount}</span>
                <span className="fee-term">{fee?.payment_term}</span>
            </div>
        </Card>
    );
};

export default FeeRibbon;
