import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import InvoiceDetailMenu from './InvoiceDetailMenu';
import InvoiceDetail from './InvoiceDetail';
import Header from '../common/Header';




const styles = theme => ({
    root: {
    //  marginBottom: 35,
    },
  });

const IncomeInvoice = (props) => {
    
    return (
        <div className={props.classes.wrapper}>
            <Header title="Income" export="Export" newIncome="New Invoice"/>
            <Card className={props.classes.root}>
                <Grid container>
                    <Grid item xs={12} md={3}><InvoiceDetailMenu /></Grid>
                    <Grid item xs={12} md={9}><InvoiceDetail /></Grid>
                </Grid>
            </Card>
        </div>
    )
}

export default withStyles(styles)(IncomeInvoice);
