import React, { useState, useRef, memo } from "react";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { useOptionsDropdownStyles } from "./optionsDropdown.styles";

const OptionsDropdown = ({ children }) => {
    const classes = useOptionsDropdownStyles();
    //------------------------------------------------------
    //Menu dropdown logic
    //------------------------------------------------------
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    //Toggle display
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    //Specifically for closing, when we click outside
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handleListKeyDown = (event) => {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    };
    //------------------------------------------------------
    //------------------------------------------------------
    //------------------------------------------------------
    return (
        <div className={classes.optionsDropdownWrapper}>
            <button
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}>
                {children}
            </button>

            <div
                className="three-dots"
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}>
                <span></span>
                <span></span>
                <span></span>
            </div>

            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => {
                    return (
                        <Grow
                            {...TransitionProps}
                            style={{
                                // transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                                transformOrigin: "left start"
                            }}>
                            <div className="menu-wrapper">
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        <MenuItem onClick={handleClose}>Renew</MenuItem>
                                        <MenuItem onClick={handleClose}>Edit Plan</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </div>
                        </Grow>
                    );
                }}
            </Popper>
        </div>
    );
};

export default memo(OptionsDropdown);
