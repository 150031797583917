import React from "react";
import DetailsCardContainer, {
  DetailsContainer
} from "#/components/cards/DetailsCardContainer.component";
import SubtitleAndSubtext from "#/components/ui-components/SubtitleAndSubtext.component";
import DocumentPreview from "../others/DocumentPreview";
import { toCurrency } from "#/components/utils/util";

const ReferenceDetails = ({
  contactOfNextOfKin,
  contactOfreference,
  emailOfNextOfKin,
  nameOfNextOfKin,
  nameOfReference,
  nextOfKinHomeAddress
}) => {
  return (
    <DetailsCardContainer>
      <DetailsContainer>
        <SubtitleAndSubtext subtitle={"Name of Next of Kin"} subtext={nameOfNextOfKin} />
        <SubtitleAndSubtext
          subtitle={"Contact of Next of Kin"}
          subtext={contactOfNextOfKin}
        />
        <SubtitleAndSubtext
          subtitle={"Email of Next of Kin"}
          subtext={emailOfNextOfKin}
        />
        <SubtitleAndSubtext
          subtitle={"Next of Kin Home Address"}
          subtext={nextOfKinHomeAddress}
        />
        <SubtitleAndSubtext subtitle={"Name of Reference"} subtext={nameOfReference} />
        <SubtitleAndSubtext
          subtitle={"Contact of Reference"}
          subtext={contactOfreference}
        />
      </DetailsContainer>
    </DetailsCardContainer>
  );
};

export default ReferenceDetails;
