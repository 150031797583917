import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateID } from "#/store/actions/auth.actions";
import modalStyles from "../../fund-wallet/fundwalletStyles";
import { ModalTextField } from "../../fund-wallet/ModalHelper";
import wallet from "#/assets/images/wallet (1).svg";
import { Controller, useForm } from "react-hook-form";
import CustomButton from "#/components/ui-components/button.component";
import { doTransfer, selectIzyPayBalance } from "#/store/actions/izypay.actions";
import userImg from "#/assets/other images/user (1).svg";
import contactImg from "#/assets/other images/name.svg";
import { alertClear } from "#/store/actions/notification.actions";
import { ErrorDiv } from "#/components/errorDiv";
import NumberFormat from "react-number-format";
import { stringToNumberMoney } from "#/components/utils/util";
import { CircularProgress } from "@mui/material";

export const TranfserForm = ({ handleClose }) => {
  const classes = modalStyles();
  const [display, setDisplay] = useState(false);
  const { register, watch, handleSubmit, setValue, errors, control } = useForm({
    mode: "onBlur"
  });
  const { liveIzyUser, loading } = useSelector((store) => store.auth);
  const tLoading = useSelector((store) => store.izypay.loading);
  const izyPayBalance = useSelector(selectIzyPayBalance);
  const messages = useSelector((store) => store.notifications.msg);
  const [liveIzyId, setLiveIzyId] = useState("");
  // const [liveIzyId, setLiveIzyId] = useState('');
  const dispatch = useDispatch();
  let buttonText = "Transfer";
  // if (display) {
  //   loading = tLoading;
  // }
  const handleInputChange = (e) => {
    const { value } = e.target;
    setValue(value);
  };
  const values = watch();

  useEffect(() => {
    const timerId = setTimeout(() => {
      const liveIzyIdVals = values.user_liveizy;
      if (liveIzyIdVals !== undefined) {
        setLiveIzyId(liveIzyIdVals);
      }
    }, 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, [values.user_liveizy]);

  useEffect(() => {
    if (liveIzyId.length === 8) {
      dispatch(validateID(liveIzyId, () => setDisplay(true)));
    }
  }, [liveIzyId]);

  useEffect(() => {
    if (
      values.user_liveizy !== undefined &&
      values.user_liveizy.length !== 8 &&
      messages !== ""
    ) {
      setDisplay(false);
      dispatch(alertClear());
    }
  }, [messages, values.user_liveizy]);

  const Transfer = (vals) => {
    if (loading) return;
    const { ...rest } = vals;
    const data = { ...rest };
    if (data.amount < 1) return;
    let amountT = data.amount;
    const dataT = {
      amount: stringToNumberMoney(amountT),
      user_liveizy: liveIzyUser.liveizy_id
    };
    if (display) {
      dispatch(doTransfer(dataT, () => handleClose()));
    }
  };
  const validateName = (value) => {
    if (value !== undefined && value.length !== 8) {
      return "LiveIzy Id must be 8 characters";
    }
  };

  const validateWalletBalance = (value) => {
    if (stringToNumberMoney(value) > Number(izyPayBalance)) {
      return `Insufficient wallet balance`;
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(Transfer)}>
        <ModalTextField
          label="LiveIzy ID"
          img={userImg}
          type="text"
          error={errors.user_liveizy && errors.user_liveizy.message}
          customRef={register({
            required: "This field is required",
            validate: validateName
          })}
          value={values.user_liveizy}
          onChange={handleInputChange}
          name="user_liveizy"
          disabled={loading}
          placeholder="Enter liveIzy ID"
          defaultValue=""
        />
        {loading && <CircularProgress />}
        {display && (
          <React.Fragment>
            <ModalTextField
              label="Name"
              img={contactImg}
              type="text"
              error={errors.name && "This field is required"}
              customRef={register({
                required: true
              })}
              onChange={handleInputChange}
              name="name"
              disabled={true}
              value={liveIzyUser.name}
              imgStyle={{ height: "42px" }}
            />
            <Controller
              as={NumberFormat}
              customInput={ModalTextField}
              thousandSeparator
              label="Enter Amount"
              img={wallet}
              error={errors.amount && errors.amount.message}
              defaultValue=""
              rules={{
                required: "This field is Required",
                pattern: "^-?[0-9]d*.?d*$",
                validate: validateWalletBalance
              }}
              value={values.amount}
              onChange={handleInputChange}
              name="amount"
              inputmode="numeric"
              prefix="₦"
              control={control}
              allowNegative={false}
              disabled={loading}
            />
          </React.Fragment>
        )}
        {messages !== "" && <ErrorDiv>{messages}</ErrorDiv>}
        <div className={classes.transferBtnMargin}>
          <CustomButton
            variant="outlined"
            className={classes.closeBtnTransfer}
            onClick={handleClose}
            buttonText="CLOSE"
          />
          {display && (
            <CustomButton
              variant="outlined"
              className={classes.fundBtnTransfer}
              buttonText={buttonText}
              isLoading={tLoading}
            />
          )}
        </div>
      </form>
    </React.Fragment>
  );
};
