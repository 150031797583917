import { propertyServiceApi } from "#/apis/propertyServiceApi";
import LoadingModal from "#/components/loadingModal/LoadingModal";
import SubtitleAndSubtext from "#/pages/RentLoanNew/UIcomponents/others/SubtitleAndSubtext";
import { showNotifications } from "#/store/actions/notification.actions";
import { getAllProperties } from "#/store/actions/property.actions";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  styled,
  TextField
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const BankSelectField = ({ setShowBankModal, owner, itemId, headerName }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [bankList, setBankList] = useState([]);
  const [bankListPm, setBankListPm] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [selectedPoBank, setSelectedPoBank] = useState(null);
  const [selectedPmBank, setSelectedPmBank] = useState(null);
  const [fetchingPoBanks, setFetchingPoBanks] = useState(false);
  const [fetchingPmBanks, setFetchingPmBanks] = useState(false);

  useEffect(() => {
    if (!owner) return;

    // fetch landlord's bank list
    setFetchingPoBanks(true);
    propertyServiceApi()
      .get(`/property-owner/${owner}`)
      .then((res) => {
        if (res.data.bankAccount && res.data.bankAccount.length > 0) {
          setBankList(res.data.bankAccount);
        }
        setFetchingPoBanks(false);
      })
      .catch((error) => {
        setFetchingPoBanks(false);
        dispatch(
          showNotifications(
            error?.response?.data?.error ||
              "An error occured while fetchingPoBanks Property Owner's details",
            "error"
          )
        );
      });
  }, []);

  useEffect(() => {
    if (!user.roles.includes("Agent")) return;

    // fetch landlord's bank list
    setFetchingPmBanks(true);
    propertyServiceApi()
      .get(`/property-manager/${user.liveizy_id}`)
      .then((res) => {
        if (res.data.bankAccount && res.data.bankAccount.length > 0) {
          setBankListPm(res.data.bankAccount);
        }
        setFetchingPmBanks(false);
      })
      .catch((error) => {
        setFetchingPmBanks(false);
        dispatch(
          showNotifications(
            error?.response?.data?.error ||
              "An error occured while fetching Property Manager's details",
            "error"
          )
        );
      });
  }, []);

  const handleAssignBank = () => {
    const payload = {
      bank_accounts: selectedBank
    };
    if (headerName.toLowerCase() === "property") {
      setLoading(true);
      propertyServiceApi()
        .patch(`/property/add-account/${itemId}`, payload)
        .then((data) => {
          setLoading(false);
          dispatch(showNotifications("Bank Account assigned successfully", "success"));
          dispatch(getAllProperties());
          setShowBankModal(false);
        })
        .catch(({ response }) => {
          setLoading(false);
          dispatch(
            showNotifications(response?.data?.error || "An error occured", "error")
          );
        });
    } else if (headerName.toLowerCase() === "apartment") {
      setLoading(true);
      propertyServiceApi()
        .patch(`/apartment/add-account/${itemId}`, payload)
        .then((data) => {
          setLoading(false);
          dispatch(showNotifications("Bank Account assigned successfully", "success"));
          dispatch(getAllProperties());
          setShowBankModal(false);
        })
        .catch(({ response }) => {
          setLoading(false);
          dispatch(
            showNotifications(response?.data?.error || "An error occured", "error")
          );
        });
    }
  };

  return (
    <>
      <h4 style={{ fontWeight: "bold", color: "#106f9f" }}>Landlord</h4>
      {!owner ? (
        <p>Landlord has not been attached to this property yet</p>
      ) : (
        <div>
          {fetchingPoBanks ? (
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              {bankList.length > 0 ? (
                <Autocomplete
                  id="po-bank-list"
                  options={bankList}
                  autoHighlight
                  getOptionLabel={(option) => option.account_number}
                  renderOption={(props, option) => (
                    <OptionsStyle component="li" {...props}>
                      <h3>{option.account_number}</h3>
                      <h4>{option.name}</h4>
                      <h5>{option.account_name}</h5>
                    </OptionsStyle>
                  )}
                  value={selectedBank}
                  onChange={(evnt, value) => {
                    setSelectedBank(value);
                    setSelectedPoBank(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select from Owner's banks list"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password" // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              ) : (
                <div style={{ textAlign: "center" }}>
                  <p>
                    <em>Owner has not submitted any bank account.</em>
                  </p>

                  <p style={{ fontSize: "13px" }}>
                    Bank accounts can be submitted in <b>Account Settings</b>.
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      )}

      {/* PM section */}
      <h4 style={{ fontWeight: "bold", color: "#106f9f", margin: "15px 0 10px 0" }}>
        Property Manager
      </h4>
      {
        <div>
          {fetchingPmBanks ? (
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              {bankListPm.length > 0 ? (
                <Autocomplete
                  id="po-bank-list"
                  options={bankListPm}
                  autoHighlight
                  getOptionLabel={(option) => option.account_number}
                  renderOption={(props, option) => (
                    <OptionsStyle component="li" {...props}>
                      <h3>{option.account_number}</h3>
                      <h4>{option.name}</h4>
                      <h5>{option.account_name}</h5>
                    </OptionsStyle>
                  )}
                  value={selectedBank}
                  onChange={(evnt, value) => {
                    setSelectedBank(value);
                    setSelectedPmBank(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select from Property Manager's banks list"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password" // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              ) : (
                <div style={{ textAlign: "center" }}>
                  <p>
                    <em>Property Manager has not submitted any bank account.</em>
                  </p>

                  <p style={{ fontSize: "13px" }}>
                    Bank accounts can be submitted in <b>Account Settings</b>.
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      }
      {selectedBank && (
        <div>
          <SubtitleAndSubtext
            style={{ width: "auto" }}
            subtitle={"Account Name"}
            subtext={selectedBank.account_name}
          />
          <SubtitleAndSubtext
            style={{ width: "auto", marginTop: "-15px" }}
            subtitle={"Bank Name"}
            subtext={selectedBank.name}
          />
          <Button
            variant="contained"
            disabled={!selectedBank || loading}
            onClick={handleAssignBank}>
            Update Bank
          </Button>
        </div>
      )}
    </>
  );

  if (bankListPm.length > 0) {
    return (
      <div>
        {loading && <LoadingModal />}
        <Autocomplete
          id="po-bank-list"
          options={bankList}
          autoHighlight
          getOptionLabel={(option) => option.account_number}
          renderOption={(props, option) => (
            <OptionsStyle component="li" {...props}>
              <h3>{option.account_number}</h3>
              <h4>{option.name}</h4>
              <h5>{option.account_name}</h5>
            </OptionsStyle>
          )}
          value={selectedBank}
          onChange={(evnt, value) => {
            setSelectedBank(value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select from Owner's banks list"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password" // disable autocomplete and autofill
              }}
            />
          )}
        />
        {selectedBank && (
          <div>
            <SubtitleAndSubtext
              style={{ width: "auto" }}
              subtitle={"Account Name"}
              subtext={selectedBank.account_name}
            />
            <SubtitleAndSubtext
              style={{ width: "auto", marginTop: "-15px" }}
              subtitle={"Bank Name"}
              subtext={selectedBank.name}
            />
            <Button
              variant="contained"
              disabled={!selectedBank || loading}
              onClick={handleAssignBank}>
              Update Bank
            </Button>
          </div>
        )}
      </div>
    );
  }
  return (
    <div style={{ textAlign: "center" }}>
      <p>
        <em>Owner has not submitted any bank account.</em>
      </p>

      <p style={{ fontSize: "13px" }}>
        Bank accounts can be submitted in <b>Account Settings</b>.
      </p>
    </div>
  );
};

export default BankSelectField;

const OptionsStyle = styled("div")`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  h3 {
    color: blue;
    font-weight: bold;
  }

  h4 {
    color: black;
  }
  h5 {
    color: #0d0c0c;
  }
`;
