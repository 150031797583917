import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import { green } from "@material-ui/core/colors";
import Checkbox from "@material-ui/core/Checkbox";

const GeneralPropComponent = ({
    property,
    selectedPropts,
    setSelectedPropts,
    selectedAptms,
    setSelectedAptms,
    plan
}) => {
    const classes = useStyles();

    const [displayApartments, setDisplayApartments] = useState(false);

    const handleAptmSelect = (checked, apartment) => {
        if (checked)
            setSelectedAptms({
                ...selectedAptms,
                [apartment._id]: apartment._id
            });
        if (!checked) {
            const temp = selectedAptms;
            delete temp[apartment._id];
            setSelectedAptms(temp);
            const propTemp = { ...selectedPropts };
            delete propTemp[property._id];
            setSelectedPropts(propTemp);
        }
    };

    const handleProptSelect = (checked) => {
        if (checked) {
            setSelectedPropts({
                ...selectedPropts,
                [property._id]: property._id
            });
            let selectedApartments = { ...selectedAptms };
            property.apartment.forEach((item) => {
                if (!item.plan) selectedApartments[item._id] = item._id;
            });
            setSelectedAptms(selectedApartments);
        }
        if (!checked) {
            const temp = { ...selectedPropts };
            delete temp[property._id];
            setSelectedPropts(temp);
            let selectedApartments = { ...selectedAptms };
            Object.keys(selectedAptms).forEach((item) => {
                if (item.startsWith(property._id)) delete selectedApartments[item];
            });
            setSelectedAptms(selectedApartments);
        }
    };

    return (
        <>
            <div className="lpmp-lipman-property">
                <IconButton
                    aria-label="delete"
                    className={"lpmp-lp-dropdown" + " " + classes.margin}
                    size="small"
                    onClick={() => setDisplayApartments(!displayApartments)}>
                    {displayApartments ? (
                        <ExpandLessRoundedIcon fontSize="inherit" />
                    ) : (
                        <ExpandMoreRoundedIcon fontSize="inherit" />
                    )}
                </IconButton>
                <Checkbox
                    className="lpmp-lp-checkbox"
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    checked={selectedPropts[property._id] ? true : false}
                    onChange={(e) => handleProptSelect(e.target.checked)}
                />
                <div className="lpmp-lp-flex-div">
                    <p>{property.title}</p>
                    <span>
                        {property.house_number} {property.street}, {property.state}
                    </span>
                </div>
                <span>{property._id}</span>
            </div>
            {displayApartments &&
                property.apartment[0] &&
                property.apartment.map((item, idx) => {
                    return (
                        <div key={idx} className="lpmp-lipman-apartment">
                            {!["LiPMaN", "LiReM"].includes(item.plan?.plan_type) && (
                                <Checkbox
                                    className="lpmp-lp-checkbox"
                                    color="primary"
                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                    checked={selectedAptms[item._id] || selectedPropts[item.property] ? true : false}
                                    onChange={(e) => handleAptmSelect(e.target.checked, item)}
                                />
                            )}
                            <div>
                                <p>
                                    Apartment ID: <b>{item._id}</b>
                                </p>
                                <p>
                                    Liveizy Plan: <b>{item.plan?.plan_type || "free plan"}</b>
                                </p>
                                <p>
                                    Plan Status: <b>{item.plan?.status || "none"}</b>
                                </p>
                                <p>
                                    Payment Term: <b>{item.payment_term}</b>
                                </p>
                                <p>
                                    Total Price: <b>&#8358;{item.price}</b>
                                </p>
                            </div>
                        </div>
                    );
                })}
        </>
    );
};

export default GeneralPropComponent;

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        "&$checked": {
            color: green[600]
        }
    },
    checked: {}
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1)
    },
    extendedIcon: {
        marginRight: theme.spacing(1)
    }
}));
