import axios from "axios";
import { BOOKING_CONSTANTS } from "../constants";
import { showNotifications } from "#/store/actions/notification.actions";

const {
  BOOK_INSPECTION_FAIL,
  BOOK_INSPECTION_PENDING,
  BOOK_INSPECTION_SUCCESS,

  CLEAR_BOOKING_FIELDS,

  GET_MY_BOOKINGS_FAIL,
  GET_MY_BOOKINGS_PENDING,
  GET_MY_BOOKINGS_SUCCESS,

  DELETE_INSPECTION_PENDING,
  DELETE_INSPECTION_FAIL,
  DELETE_INSPECTION_SUCCESS,

  GET_APARTMENTS_BOOKED_FAIL,
  GET_APARTMENTS_BOOKED_PENDING,
  GET_APARTMENTS_BOOKED_SUCCESS,

  REGISTER_SHOW_OF_INTEREST_FAIL,
  REGISTER_SHOW_OF_INTEREST_SUCCESS,
  REGISTER_SHOW_OF_INTEREST_PENDING,

  GET_APARTMENT_BOOKINGS_FAIL,
  GET_APARTMENT_BOOKINGS_PENDING,
  GET_APARTMENT_BOOKINGS_SUCCESS
} = BOOKING_CONSTANTS;

const BASE_URL = process.env.REACT_APP_PROPERTY_SERVICE_BASE_URL;

export const requestBookInspection = (payload, cb) => async (dispatch) => {
  dispatch({ type: BOOK_INSPECTION_PENDING });

  try {
    const response = await axios.post(`${BASE_URL}/booking/storeBooking`, payload, {
      headers: {
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "1"
      }
    });

    if ([200, 201].includes(response.status)) {
      dispatch({
        type: BOOK_INSPECTION_SUCCESS
      });
      // dispatch(showNotifications(`Inspection Successfully booked`, "success"));
      cb();
    }
  } catch (error) {
    dispatch(showNotifications(`${error.response.data.error}`, "error"));
    dispatch({ type: BOOK_INSPECTION_FAIL, error });
  }
};

export const clearBookingFields = () => (dispatch) => {
  dispatch({ type: CLEAR_BOOKING_FIELDS });
};

export const getMyBookings = (userId, cb) => async (dispatch) => {
  dispatch({ type: GET_MY_BOOKINGS_PENDING });

  try {
    const response = await axios.get(
      `${BASE_URL}/booking/getAllBooking?user_id=${userId}`,
      {
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "1"
        }
      }
    );

    if ([200, 201].includes(response.status)) {
      dispatch({
        type: GET_MY_BOOKINGS_SUCCESS,
        payload: response.data.payload
      });
    }
  } catch (error) {
    dispatch({ type: GET_MY_BOOKINGS_FAIL, error });
  }
};

export const deleteBooking = (bookingId, cb) => async (dispatch) => {
  dispatch({ type: DELETE_INSPECTION_PENDING });

  try {
    const response = await axios.delete(
      `${BASE_URL}/booking/deleteBooking/${bookingId}`,
      {
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "1"
        }
      }
    );

    if ([200, 201].includes(response.status)) {
      dispatch({ type: DELETE_INSPECTION_SUCCESS });
      dispatch(showNotifications(`Inspection Successfully Deleted`, "success"));
      cb();
    }
  } catch (error) {
    dispatch({ type: DELETE_INSPECTION_FAIL, error });
  }
};

export const getApartmentsBooked = (userId, cb) => async (dispatch) => {
  dispatch({ type: GET_APARTMENTS_BOOKED_PENDING });

  try {
    const response = await axios.get(`${BASE_URL}/booking/property-owner/${userId}`, {
      headers: {
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "1"
      }
    });

    if ([200, 201].includes(response.status)) {
      dispatch({ type: GET_APARTMENTS_BOOKED_SUCCESS, payload: response.data.payload });
    }
  } catch (error) {
    dispatch({ type: GET_APARTMENTS_BOOKED_FAIL, error });
  }
};

export const registerUsersShowOfInterest = (userId, cb) => async (dispatch) => {
  dispatch({ type: REGISTER_SHOW_OF_INTEREST_PENDING });

  try {
    const response = await axios.patch(`${BASE_URL}/booking/interest/${userId}`, {
      headers: {
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "1"
      }
    });

    if ([200, 201].includes(response.status)) {
      dispatch({
        type: REGISTER_SHOW_OF_INTEREST_SUCCESS,
        payload: response.data.payload
      });
    }
    cb();
  } catch (error) {
    dispatch({ type: REGISTER_SHOW_OF_INTEREST_FAIL, error });
  }
};

export const getAllApartmentBookings = (apartmentId, cb) => async (dispatch) => {
  dispatch({ type: GET_APARTMENT_BOOKINGS_PENDING });

  try {
    const response = await axios.get(
      `${BASE_URL}/booking/getAllBooking?apartment_id=${apartmentId}`,
      {
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "1"
        }
      }
    );

    if ([200, 201].includes(response.status)) {
      dispatch({
        type: GET_APARTMENT_BOOKINGS_SUCCESS,
        payload: response.data.payload
      });
    }
  } catch (error) {
    dispatch({ type: GET_APARTMENT_BOOKINGS_FAIL, error });
  }
};

/*
    The response from this request does not need to be attached to the global store, 
    cause it changes every single time depending on the logged in users need. Therefore adding 
    it to the global state would only serve to unnecessarily populate the store.

    If you think otherwise please kindly let me know. Thank you
*/
export const approveOrRejectBooking = async (id, body) => {
  try {
    const res = await axios.patch(`${BASE_URL}/booking/review/${id}`, body);

    if (res.data.success) {
      return res.data.data.user;
    }
  } catch (error) {
    return error;
  }
};
