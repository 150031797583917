import {
  required,
  name,
  text,
  address,
  email,
  numbersOnly,
  nuban,
  liveizyId
} from "./options";

const optionFuncs = {
  required,
  name,
  text,
  address,
  email,
  numbersOnly,
  nuban,
  liveizyId
};

export const validate = (valueValidationObj, cb) => {
  const valid = Object.values(valueValidationObj).every((value) => {
    if (value.value instanceof File || value.value instanceof FileList) {
      return true;
    }
    if (typeof value?.value === "object") {
      return Object.keys(value?.value || {}).length;
    }
    return value?.value !== "" && value?.validation === true;
  });

  cb(valid);
};

export const validateType1 = (values, validation, cb) => {
  const valid = Object.keys(values).every(
    (value) => values[value] !== "" && validation[value] === true
  );

  cb(!valid);
};

export const handleFormInput = (
  name,
  rawValue,
  regex,
  valueValidationObj,
  cb,
  options = []
) => {
  const formTemp = { ...valueValidationObj };
  let errorMessage = "";
  let newValue = rawValue;
  let validation;

  if (options.length) {
    for (let i = 0; i < options.length; i++) {
      const { value, error } = optionFuncs[options[i]](rawValue);
      newValue = value;
      errorMessage = error;
      validation = !error ? true : false;
      if (error) {
        break;
      }
    }
  }
  if (regex) validation = regex.test(newValue) ? true : false;
  if (!validation && !errorMessage) errorMessage = "invalid input";

  formTemp[name] = {
    ...formTemp[name],
    value: newValue,
    validation: !errorMessage && validation ? true : false,
    error: errorMessage
  };

  cb(formTemp);
};

export const handleConfirmPassword = (name, value, password, valueValidationObj, cb) => {
  const formTemp = { ...valueValidationObj };

  formTemp[name] = {
    ...formTemp[name],
    value,
    validation: value === password ? true : false
  };

  cb(formTemp);
};

export const handleArrayInput = (name, value, min, max, valueValidationObj, cb) => {
  const formTemp = { ...valueValidationObj };
  const len = value.length;

  formTemp[name] = { value, validation: len >= min && len <= max ? true : false };

  cb(formTemp);
};

export const handleFileInputType1 = (
  name,
  fileDoc,
  allowedFormats,
  maxSize,
  values,
  validation,
  cb1,
  cb2
) => {
  const newForm = { ...values };
  const newObject = { ...validation };
  let valid = false;

  if (!fileDoc) valid = false;
  if (fileDoc && fileDoc.size <= maxSize) valid = true;
  if (fileDoc && fileDoc.size > maxSize)
    valid = `file size should not be more than ${maxSize / 1000000}mb`;
  if (fileDoc && !allowedFormats.test(fileDoc.name)) valid = "wrong file format";

  newObject[name] = valid;
  newForm[name] = fileDoc;

  cb1(newForm);
  cb2(newObject);
};

export const handleFileInput = (name, file, maxSize, valueValidationObj, cb) => {
  if (!file)
    return cb({
      ...valueValidationObj,
      [name]: { ...valueValidationObj[name], value: "", validation: true }
    });

  const formTemp = { ...valueValidationObj };
  let valid = false;

  if (file && file.size <= maxSize) valid = true;
  if (file && file.size > maxSize)
    valid = `file size should not be more than ${maxSize / 1000000}mb`;

  formTemp[name] = { ...valueValidationObj[name], value: file, validation: valid };
  cb(formTemp);
};

export const handleTimeInput = (name, type, value, valueValidationObj, cb) => {
  if (!value) return;
  const formTemp = { ...valueValidationObj };
  const regEx = /^\d{2}:\d{2}-\d{2}:\d{2}$/gi;
  const oldValue = formTemp[name].value;
  const newValue =
    type === "from"
      ? value + "-" + oldValue.replace(/^\d{2}:\d{2}-/gi, "")
      : oldValue.replace(/-\d{2}:\d{2}$/gi, "-") + value;

  formTemp[name] = { value: newValue, validation: regEx.test(newValue) ? true : false };

  cb(formTemp);
};

export const handleUnrequiredFieldsType1 = (
  value,
  field,
  values,
  validation,
  cb1,
  cb2
) => {
  if (!value) {
    const valuesTemp = { ...values };
    const validationTemp = { ...validation };
    delete valuesTemp[field];
    delete validationTemp[field];
    cb1(valuesTemp);
    cb2(validationTemp);
  }
};

export const returnCommaSepAmount = (amount) => {
  if (!amount || Number(amount) === 0) return "";
  const numObject = new Intl.NumberFormat("en-US");
  const curr = Number(amount).toFixed(2);
  const [start, trail] = curr.split(".");
  return `${numObject.format(start)}.${trail}`;
};

export const _formatCurrency = (amount, flag) => {
  if (amount || amount === 0) {
    let formatter = new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 0
    });

    let currencyAmount = formatter.format(Number(amount));
    let numbersFormat = formatter.formatToParts(Number(amount));

    if (flag === 1 && amount && Number(amount) > 999999) {
      try {
        let currencySybmol = numbersFormat.find((x) => x.type === "currency")?.value;
        let formattedAmount = numbersFormat
          .filter((x) => x.type !== "currency")
          .map((x) => x.value)
          .join("");

        let currencyAmountInNumber;
        currencyAmountInNumber = Number(formattedAmount.replace(/,/g, ""));
        if (currencyAmountInNumber >= 1000000000) {
          currencyAmountInNumber =
            (currencyAmountInNumber / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
        }
        if (Number(currencyAmountInNumber) >= 1000000) {
          currencyAmountInNumber =
            (Number(currencyAmountInNumber) / 1000000).toFixed(1).replace(/\.0$/, "") +
            "M";
        }
        currencyAmount = `${currencySybmol}${currencyAmountInNumber}`;
      } catch (error) {
        /* empty */
      }
    } else if (flag === 2) {
      currencyAmount = currencyAmount.substring(0, currencyAmount.length - 1);
    } else if (flag === 3) {
      currencyAmount = currencyAmount.replace(" ", "");
      currencyAmount = currencyAmount.substring(0, currencyAmount.length - 1);
    }

    return currencyAmount;
  }
};

export const _formatDaysToDate = (days) => {
  const startDate = new Date(); // Unix Epoch start date
  const milliseconds = days * 24 * 60 * 60 * 1000; // Convert days to milliseconds
  const targetDate = new Date(startDate.getTime() + milliseconds);

  // Format the date to dd/mm/yyyy
  let dd = targetDate.getDate();
  let mm = targetDate.getMonth() + 1; // January is 0, so we add 1
  let yyyy = targetDate.getFullYear();

  // Ensure leading zeros if needed
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  return yyyy + "-" + mm + "-" + dd;
};
