import { Button, CircularProgress, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { propertyServiceApi } from "#/apis/propertyServiceApi";
import Modal from "#/components/modal/modal.component";

const PaymentHistoryModal = ({ openModal, closeModal, invoiceRef }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await propertyServiceApi().get(
          `ref-invoice-receipts/${invoiceRef}`
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching apartment details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [invoiceRef]);

  if (isLoading) return <CircularProgress />;
  return (
    <Modal
      isModalOpen={openModal}
      handleCloseModal={() => closeModal(false)}
      style={{ padding: "30px", borderRadius: 10 }}
      hasHeader={false}>
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: "20px"
        }}>
        <Typography variant="h6">Payment Details</Typography>
        <div>{data?.data}</div>
        <p className="tw-font-semibold tw-text-xl">Coming soon</p>
        <div style={{ display: "flex", gap: "20px" }}>
          <Button onClick={() => closeModal(false)} variant="outlined">
            Cancel
          </Button>
          <Button disabled={true} variant="contained">
            Download Receipt
          </Button>
        </div>
      </Container>
    </Modal>
  );
};

export default PaymentHistoryModal;
