import React from "react";
import "../../../App.css";

// components

import Listing from "../../../components/listing/listing.jsx";
import Smallimage from "../../../components/booking/smallimage.jsx";
import Mainimage from "../../../components/booking/mainimage.jsx";
import Description from "../../../components/booking/description.jsx";
import Totalresults from "../../../components/booking/totalresults.jsx";
import Container from "../../../components/layout/container";

class Inspection extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Container>
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 col-lg-7">
                    <Mainimage />

                    <Smallimage />
                  </div>
                  <div className="col-sm-12  col-lg-5">
                    <Description />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Totalresults />

          <Listing />
        </div>
      </Container>
    );
  }
}

export default Inspection;
