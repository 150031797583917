import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Row from './Row';


const rows = [
  {
      propertyName:'Icreate Studio', 
      details:'June 18, 2020 Quaterly', 
      unit:'Suite 3', 
      amount: '17000', 
      paid: '10000', 
      balance: '7000',
      incomeDetails: [
          {
              propertyName:'kelechi Oleka', 
              dueOn: 'Jul 21,2020',
              paidOn: 'Jul 21,2020',
              id: '334455',
              status: 'Paid',
              unit:'Suite 3', 
              amount: '17000', 
              paid: '10000', 
              balance: '7000',
              content: {
                status: 'Paid',
                dueDate: 'Jul 17,2020',
                totalDue: '7000',
                reminder: '0',
                invoiceItem: 'Rent',
                invoiceItemAmount: '7000',
                paymentName: 'Kelechi Oleka by izypay',
              }
          },
          {
            propertyName:'Oleka kelechi', 
            dueOn: 'Jul 21,2020',
            paidOn: 'Jul 21,2020',
            id: '886966',
            status: 'Over due',
            unit:'Suite 3', 
            amount: '17000',
            paid: '10000', 
            balance: '7000',
            content: {
              status: 'Late by 3 days',
              dueDate: 'Jul 17,2020',
              totalDue: '7000',
              reminder: '4',
              invoiceItem: 'Rent',
              invoiceItemAmount: '7000',
              paymentName: 'Kelechi Oleka by izypay',
            }
        }
        ]
  },
  {
    propertyName:'Icreate Studio', 
    details:'June 18, 2020 Quaterly', 
    unit:'Suite 3', 
    amount: '17000', 
    paid: '10000', 
    balance: '7000',
    incomeDetails: [
        {
            propertyName:'kelechi Oleka', 
            dueOn: 'Jul 21,2020',
            paidOn: 'Jul 21,2020',
            id: '33445',
            status: 'Partially Paid',
            unit:'Suite 3', 
            amount: '17000',
            paid: '10000', 
            balance: '7000',
            content: {
              status: 'Paid',
              dueDate: 'Jul 17,2020',
              totalDue: '7000',
              reminder: '0',
              invoiceItem: 'Rent',
              invoiceItemAmount: '7000',
              paymentName: 'Kelechi Oleka by izypay',
            }
        },
        {
          propertyName:'Oleka kelechi', 
          dueOn: 'Jul 21,2020',
          paidOn: 'Jul 21,2020',
          id: '88696',
          status: 'Over due',
          unit:'Suite 3', 
          amount: '17000',
          paid: '10000', 
          balance: '7000',
          content: {
            status: 'Paid',
            dueDate: 'Jul 17,2020',
            totalDate: '7000',
            reminder: '0',
            invoiceItem: 'Rent',
            invoiceItemAmount: '7000',
            paymentName: 'Kelechi Oleka by izypay',
          }
      }
      ]
  }
];

const styles = theme => ({
    table: {
        '& thead': {
            borderBottom: '3px solid #fff !important',
        },
        '& thead tr': {
            backgroundColor: '#d2e4f7 !important',
        },
        '& .MuiTableCell-root': {
            padding: 12,
        },
        '& .MuiTableCell-head': {
            color: '#0f224a !important',
            fontWeight: 'bold !important',
            fontSize: '14 !important',
        },
        '& .MuiTableBody-root tr td#collapse-td': {
          paddingLeft: 0,
          paddingRight: 0,
        },
        '& .MuiTableCell-alignRight': {
          textAlign: 'right !important',
        },
    },
})

const IncomeTable = (props) => {
  return (
    <TableContainer component={Paper}>
      <Table className={props.classes.table} aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Property name</TableCell>
            <TableCell align="right">ID</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Paid</TableCell>
            <TableCell className="balance" align="right">Balance</TableCell>
            <TableCell style={{ paddingRight: '10px',}} />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row}/>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default withStyles(styles)(IncomeTable);