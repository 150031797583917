import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import {
  generateApartmentId,
  createApartment,
  clearPropertyInfo,
  updateApartment
} from "#/store/actions/apartment.actions";
import useArrowDropdown from "./useArrowDropdown";
import useApartDetailsDropdown from "./useApartDetailsDropdown";
import useAddMultipleImages from "./useAddMultipleImages";
// import useApartmentCharges from "./useApartmentCharges";
import {
  OPTIONS_AVAILABILITY_OF_PROPERTY,
  OPTIONS_PAYMENT_FREQUENCY,
  APARTMENT_POSITON,
  APARTMENT_FLOORS
} from "#/components/apartmentForm/apartmentForm.constants";
import { showNotifications } from "#/store/actions/notification.actions";
import {
  getTenantsUnderPropertyManager,
  clearCreatedTenant
} from "#/store/actions/tenant.actions";
import { getPropertyById } from "#/store/actions/property.actions";

const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
const removeNonNumeric = (num) => (num || "").toString().replace(/[^0-9]/g, "");
const removeLeadingZero = (num) => num.toString().replace(/^0+/, "");

const formatAmount = (value) => {
  return parseFloat(removeNonNumeric(value || ""));
};

const useApartmentForm = (allPropertyInfo, isForEdit = false) => {
  //------------------------------------------------------------------
  //Helper hooks
  //------------------------------------------------------------------
  const dispatch = useDispatch();
  const history = useHistory();

  //custom hooks
  const apartmentStatusHelpers = useArrowDropdown(OPTIONS_AVAILABILITY_OF_PROPERTY);
  const apartmentPaymentFreqHelpers = useArrowDropdown(OPTIONS_PAYMENT_FREQUENCY);
  const apartmentPositionHelpers = useArrowDropdown(APARTMENT_POSITON);
  const apartmentFloorHelpers = useArrowDropdown(APARTMENT_FLOORS);
  const { addMultipleImagesHelpers } = useAddMultipleImages();
  const { apartDetailsDropdownHelpers } = useApartDetailsDropdown();

  //------------------------------------------------------------------
  //Store
  //------------------------------------------------------------------
  const { creatingApartment, updatingApartment } = useSelector(
    (state) => state.apartment
  );
  const { apartmentId } = useSelector((state) => state.apartment);
  const { user } = useSelector((state) => state.auth);

  //------------------------------------------------------------------
  //States
  //------------------------------------------------------------------
  const [apartmentFormFields, setApartmentFormFields] = useState({
    rent: "",
    floor: "",
    entrance: "",
    otherDesc: "",
    apartmentStatus: "",
    rentDate: format(new Date(), "yyyy-MM-dd") // use this
  });
  const [advertStatus, setAdvertStatus] = useState(false);
  const [previouslyUploadedImages, setPreviouslyUploadedImages] = useState([]);
  const [errors, setErrors] = useState({
    rent: false,
    apartmentStatus: false,
    rentDate: false,
    paymentTerm: false,
    floor: false,
    position: false,
    entrance: false,
    apartmentDetails: false
  });
  const { rent, rentDate, floor, entrance, otherDesc } = apartmentFormFields;

  //------------------------------------------------------------------
  //Function Declarations
  //------------------------------------------------------------------
  //When user types in some inputs
  const handleOnChangeApartmentInput = useCallback((e) => {
    let { value, name } = e.target;

    //Numeric validation for rent,floor,entrance
    if (name === "floor" || name === "entrance") {
      value = value.match(/\d/g) || []; //allow only digits, NOTE: match could return null hence the empty array second option
      value = value.join("");
    }

    if (name === "rent") {
      value = addCommas(removeNonNumeric(removeLeadingZero(value)));
    }

    setApartmentFormFields((prevState) => {
      return {
        ...prevState,
        [name]: value
      };
    });
  }, []);

  //Toggle advert status
  const handleSetAdvertStatus = useCallback(
    (scheduleAvailable) => {
      if (!advertStatus === true && !scheduleAvailable)
        return dispatch(
          showNotifications("You have to create inspection schedule first.", "warning")
        );
      setAdvertStatus(!advertStatus);
    },
    [advertStatus, dispatch]
  );

  //Setting errors
  const handleSetErrors = useCallback((field) => {
    setErrors((prevState) => {
      return {
        ...prevState,
        [field]: true
      };
    });
  }, []);

  const handleRemovePreviouslyUploadedImage = useCallback(
    (index) => {
      const filteredData = previouslyUploadedImages.filter((item, i) => {
        return index !== i;
      });

      setPreviouslyUploadedImages(filteredData);
    },
    [previouslyUploadedImages]
  );

  //removing errors(NOTE: this was not merged into the handleSetErrors function because of some edge cases)
  const handleRemoveErrors = useCallback((field) => {
    setErrors((prevState) => {
      return {
        ...prevState,
        [field]: false
      };
    });
  }, []);

  //Submit the apartment
  const handleCreateApartment = (
    e,
    addAnother = false,
    isUpdating = false,
    sentApartId
  ) => {
    e.preventDefault();

    const formData = new FormData();

    const { allMultipleImages } = addMultipleImagesHelpers;
    const {
      bedrooms,
      bathrooms,
      sittingRooms,
      ensuites
    } = apartDetailsDropdownHelpers.mainFacilities;
    const { otherFacilities } = apartDetailsDropdownHelpers;

    //--------------------------------------------
    //Validations
    //--------------------------------------------
    if (
      apartmentStatusHelpers.selectedOption.mainValue === "select" ||
      apartmentPaymentFreqHelpers.selectedOption.mainValue === "selectunit" ||
      rent === "" ||
      apartmentPositionHelpers.selectedOption.mainValue === "select" ||
      apartmentFloorHelpers.selectedOption.mainValue === "Select Floor" ||
      entrance === "" ||
      bedrooms === "" ||
      bathrooms === "" ||
      sittingRooms === "" ||
      ensuites === "" ||
      (rentDate === "" && apartmentStatusHelpers.selectedOption.mainValue === "occupied")
    ) {
      dispatch(showNotifications("Please Fill all required fields", "error"));
    }

    if (apartmentStatusHelpers.selectedOption.mainValue === "select") {
      return handleSetErrors("apartmentStatus");
    }

    if (bedrooms === "" || bathrooms === "" || sittingRooms === "" || ensuites === "") {
      return handleSetErrors("apartmentDetails");
    }

    if (apartmentPaymentFreqHelpers.selectedOption.mainValue === "selectunit") {
      return handleSetErrors("paymentTerm");
    }

    if (rent === "") {
      return handleSetErrors("rent");
    }

    if (apartmentPositionHelpers.selectedOption.mainValue === "select") {
      return handleSetErrors("position");
    }

    if (apartmentFloorHelpers.selectedOption.mainValue === "Select Floor") {
      return handleSetErrors("floor");
    }

    if (entrance === "") {
      return handleSetErrors("entrance");
    }

    if (
      rentDate === "" &&
      apartmentStatusHelpers.selectedOption.mainValue === "occupied"
    ) {
      return handleSetErrors("rentDate");
    }
    //--------------------------------------------
    //--------------------------------------------
    //--------------------------------------------
    let extras = [];

    Object.entries(otherFacilities).forEach((item) => {
      if (item[1]) {
        extras.push(item[0]);
      }
    });

    const { lcda, lga, title, street, state, house_number } = allPropertyInfo;

    formData.append("apartment_id", sentApartId);
    formData.append("price", formatAmount(rent));
    formData.append("property", allPropertyInfo._id);
    formData.append("status", apartmentStatusHelpers.selectedOption.mainValue);
    formData.append("rent_payment_date", rentDate);
    formData.append(
      "payment_term",
      apartmentPaymentFreqHelpers.selectedOption.mainValue.toLowerCase()
    );
    formData.append("no_of_bedrooms", bedrooms);
    formData.append("no_of_toilet", bathrooms);
    formData.append("no_of_sitting_room", sittingRooms);
    formData.append("no_of_ensuite", ensuites);
    formData.append("floor", apartmentFloorHelpers.selectedOption.mainValue);
    formData.append(
      "apartment_position",
      apartmentPositionHelpers.selectedOption.mainValue.toLowerCase()
    );
    formData.append("entrance", entrance);
    formData.append("extra_description", otherDesc);
    formData.append("advert_status", advertStatus);
    formData.append("title", title);
    formData.append("house_number", house_number);
    formData.append("street", street);
    formData.append("state", state);
    formData.append("lga", lga);
    formData.append("lcda", lcda || "empty");
    formData.append("country", "Nigeria");
    formData.append("created_by", allPropertyInfo.created_by);

    //Make property image the first apartment image
    //formData.append("image_urls", property_images[0]);

    if (previouslyUploadedImages?.length > 0) {
      for (let i = 0; i < previouslyUploadedImages.length; i++) {
        formData.append("image_urls", previouslyUploadedImages[i]);
      }
    }

    if (extras?.length) {
      for (let i = 0; i < extras.length; i++) {
        formData.append("extras", extras[i]);
      }
    }

    if (allMultipleImages?.length) {
      for (let i = 0; i < allMultipleImages.length; i++) {
        formData.append("apartment_images", allMultipleImages[i]);
      }
    }

    if (isUpdating) {
      // console.log("This is formdata", allPropertyInfo);
      dispatch(
        updateApartment(formData, allPropertyInfo.property_id, sentApartId, () => {
          batch(() => {
            dispatch(showNotifications("Apartment updated successfully", "success"));
            dispatch(getTenantsUnderPropertyManager(user.id));
            dispatch(clearCreatedTenant());
            history.push(
              `/property-list/property-apartment/${allPropertyInfo._id}?apartmentId=${sentApartId}`
            );
          });
        })
      );
    } else {
      if (addAnother) {
        dispatch(
          createApartment(formData, allPropertyInfo.property_id, () => {
            setApartmentFormFields({
              rent: "",
              floor: "",
              entrance: "",
              otherDesc: "",
              apartmentStatus: "",
              rentDate: ""
            });

            setAdvertStatus(false);

            setErrors({
              rent: false,
              apartmentStatus: false,
              rentDate: false,
              paymentTerm: false,
              floor: false,
              position: false,
              entrance: false
            });

            apartmentStatusHelpers.setSelectedOption(OPTIONS_AVAILABILITY_OF_PROPERTY[0]);
            apartmentPaymentFreqHelpers.setSelectedOption(OPTIONS_PAYMENT_FREQUENCY[0]);
            apartmentPositionHelpers.setSelectedOption(APARTMENT_POSITON[0]);
            addMultipleImagesHelpers.setAllMultipleImagesPreview([]);
            addMultipleImagesHelpers.setAllMultipleImages();
            apartDetailsDropdownHelpers.resetFields();

            batch(() => {
              dispatch(generateApartmentId(allPropertyInfo.property_id));
              dispatch(
                showNotifications(
                  "Apartment created successfully, fill fields for next apartment",
                  "success"
                )
              );
              dispatch(getTenantsUnderPropertyManager(user.id));
              dispatch(clearCreatedTenant());
            });
          })
        );
      } else {
        dispatch(
          createApartment(formData, allPropertyInfo.property_id, () => {
            dispatch(generateApartmentId(allPropertyInfo.property_id));
            batch(() => {
              dispatch(clearPropertyInfo());
              dispatch(showNotifications("Apartment created successfully", "success"));
              dispatch(clearCreatedTenant());
            });
            history.push(
              `/create/${allPropertyInfo._id}/bill-settings/${sentApartId}/${allPropertyInfo._id}`
            );
          })
        );
      }
    }
  };

  //------------------------------------------------------------------
  //Use Effects
  //------------------------------------------------------------------
  //Remove Errors if validation condition has been met
  useEffect(() => {
    if (apartmentStatusHelpers.selectedOption?.mainValue !== "select") {
      handleRemoveErrors("apartmentStatus");
    }

    const {
      bedrooms,
      bathrooms,
      sittingRooms,
      ensuites
    } = apartDetailsDropdownHelpers.mainFacilities;
    if (bedrooms !== "" && bathrooms !== "" && sittingRooms !== "" && ensuites !== "") {
      handleRemoveErrors("apartmentDetails");
    }

    if (apartmentPaymentFreqHelpers.selectedOption?.mainValue !== "selectunit") {
      handleRemoveErrors("paymentTerm");
    }

    if (rent !== "") {
      handleRemoveErrors("rent");
    }

    if (rentDate !== "") {
      handleRemoveErrors("rentDate");
    }

    if (apartmentFloorHelpers.selectedOption?.mainValue !== "Select Floor") {
      handleRemoveErrors("floor");
    }

    if (apartmentPositionHelpers.selectedOption?.mainValue !== "select") {
      handleRemoveErrors("position");
    }

    if (entrance !== "") {
      handleRemoveErrors("entrance");
    }
  }, [
    rent,
    rentDate,
    floor,
    entrance,
    apartmentStatusHelpers.selectedOption?.mainValue,
    apartmentPaymentFreqHelpers.selectedOption?.mainValue,
    apartmentPositionHelpers.selectedOption?.mainValue,
    apartDetailsDropdownHelpers.mainFacilities,
    apartmentFloorHelpers.selectedOption?.mainValue,
    handleRemoveErrors
  ]);
  //Generate apartment LAID number
  useEffect(() => {
    console.log("helle dispatch initial");
    console.log(allPropertyInfo);
    console.log(isForEdit);

    if (allPropertyInfo && allPropertyInfo.property_id && !isForEdit) {
      dispatch(generateApartmentId(allPropertyInfo.property_id));

      console.log("helle dispatch");
    }
  }, [allPropertyInfo, dispatch, isForEdit]);

  return {
    apartmentFormHelpers: {
      apartmentId,
      apartmentStatusHelpers,
      apartmentPaymentFreqHelpers,
      apartmentFormFields,
      apartmentPositionHelpers,
      apartmentFloorHelpers,
      handleOnChangeApartmentInput,
      addMultipleApartmentImagesHelpers: addMultipleImagesHelpers,
      creatingApartment,
      advertStatus,
      handleSetAdvertStatus,
      handleCreateApartment,
      apartDetailsDropdownHelpers,
      apartmentErrors: errors,
      setApartmentFormFields,
      setAdvertStatus,
      previouslyUploadedImages,
      setPreviouslyUploadedImages,
      handleRemovePreviouslyUploadedImage,
      updatingApartment
    }
  };
};

export default useApartmentForm;
