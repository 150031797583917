export const ESM_ROUTES = {
  dashboard: "/estate-management/dashboard",
  members: "/estate-management/members",
  roles: "/estate-management/role",
  finance: "/estate-management/finance",
  payments: "/estate-management/payment/:lid",
  summary: "/estate-management/summary",
  fee: "/estate-management/fee",
  payFees: "/payments/esm/fees"
};

export const DASHBOARD_ROUTES = {
  dashboard: "/dashboard",
  monthlyRentForm: "/dashboard/rent-loan-form"
};

export const APP_ROUTES = {
  dashboard: {
    main: "/dashboard",
    monthlyRentForm: "/dashboard/monthlyrent/apply",
    monthlyRentAgreement: "/dashboard/monthlyrent/agreement",
    monthlyRentActivation: "/dashboard/monthlyrent/activate",
    monthlyRenew: "/dashboard/monthlyrent/renew",
    rentalpayments: "/dashboard/rentalpayment"
  },
  izyInvest: {
    main: "/izyinvest"
  },
  izyPay: {
    main: "/wallet"
  }
};
