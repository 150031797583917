import React, { useEffect, useState, useCallback, useRef } from "react";
import NaijaStates from "naija-state-local-government";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { useAddressDropdownStyles } from "./addressDropdown.styles";

const AddressDropdown = ({
  name,
  label,
  placeholder,
  type,
  err,
  addressDropdownHelpers
}) => {
  const { address, handleOnBlur, handleOnChangeAddress } = addressDropdownHelpers;

  const { houseNumber, line, state, lga, lcda, otherAddress } = address;

  //--------------------------------------------------------
  //Toggle display of dropdown
  //--------------------------------------------------------
  const [showDropDown, setShowDropdown] = useState(false);
  const anchorRef = useRef(null);

  const handleShowDropdown = (e) => {
    const { id } = e.target;
    if (id === "input-field" || id === "input-menu" || id === "btn-finish") {
      setShowDropdown(!showDropDown);
    }
  };
  const handleCloseDropdown = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setShowDropdown(false);
  };
  //--------------------------------------------------------
  //--------------------------------------------------------
  //--------------------------------------------------------

  const classes = useAddressDropdownStyles(showDropDown);

  //-----------------------------------------------------------
  //State, lga and lcda (Might get moved to useAddressDropdown hook, but for now it can stay here)
  //-----------------------------------------------------------
  const [chosenState, setChosenState] = useState("");
  const [chosenStateLgas, setChosenStateLgas] = useState([]);
  const [chosenLga, setChosenLga] = useState("");
  const [chosenLcda, setChosenLcda] = useState("");

  const handleSelectState = useCallback((e) => {
    setChosenState(e.target.value);
  }, []);

  const handleSelectLga = useCallback((e) => {
    setChosenLga(e.target.value);
  }, []);

  const handleSelectLcda = useCallback((e) => {
    setChosenLcda(e.target.value);
  }, []);

  useEffect(() => {
    if (chosenState) {
      const lgasInState = NaijaStates.lgas(chosenState).lgas;
      setChosenStateLgas(lgasInState);
    }
  }, [chosenState]);

  const lagosLcdas = {
    Agege: ["Agege", "Orile-Agege"],
    "Ajeromi-Ifelodun": ["Ajeromi", "Ifelodun"],
    Alimosho: [
      "Alimosho",
      "Agbado/Oke-Odo",
      "Ayobo-Ipaja",
      "Egbe-Idimu",
      "Igando-Ikotun",
      "Mosan Okunola"
    ],
    "Amuwo-Odofin": ["Amuwo-Odofin", "Oriade"],
    Apapa: ["Apapa", "Apapa Iganmu"],
    Badagry: ["Badagry", "Badagry West", "Olorunda"],
    Epe: ["Epe", "Eredo", "Ikosi Ejinrin"],
    "Eti-Osa": ["Eti-Osa East", "Eti-Osa West", "Iru Victoria Island", "Ikoyi-Obalende"],
    "Ibeju-Lekki": ["Ibeju", "Lekki"],
    "Ifako-Ijaiye": ["Ifako/Ijaiye", "Ojokoro"],
    Ikeja: ["Ikeja", "Ojodu", "Onigbongbo"],
    Ikorodu: [
      "Ikorodu",
      "Ikorodu North",
      "Ikorodu West",
      "Imota",
      "Ijede",
      "Igbogbo-Baiyeku"
    ],
    Kosofe: ["Kosofe", "Ikosi Isheri", "Agboyi-Ketu"],
    "Lagos-Island": ["Lagos Island West", "Lagos Island East"],
    "Lagos-Mainland": ["Lagos Mainland", "Yaba"],
    Mushin: ["Mushin", "Odi-Olowo"],
    Ojo: ["Ojo", "Oto Awori", "Iba"],
    "Oshodi-Isolo": ["Ejigbo", "Isolo", "Oshodi"],
    Shomolu: ["Shomolu", "Bariga"],
    Surulere: ["Surulere", "Coker Aguda", "Itire-Ikate"]
  };
  //-----------------------------------------------------------
  //-----------------------------------------------------------
  //-----------------------------------------------------------

  const renderDropdown = () => {
    return (
      <div
        className={classes.addressDropdown}
        onChange={handleOnChangeAddress}
        onBlur={handleOnBlur}>
        <div className="address-input-field">
          <label>House Number</label>
          <input type="text" name="houseNumber" value={houseNumber} onChange={() => {}} />
        </div>

        <div className="section">
          <div className="address-input-field">
            <label>Street</label>
            <input type="text" name="line" value={line} onChange={() => {}} />
          </div>
          <div className="address-input-field">
            <label>Other Address</label>
            <input
              type="text"
              name="otherAddress"
              value={otherAddress}
              onChange={() => {}}
            />
          </div>
          <div className="address-input-field">
            <label>State</label>
            <select
              name="state"
              id="state"
              value={chosenState}
              onChange={handleSelectState}>
              <option value="">Choose a state</option>
              {NaijaStates.states().map((item) => {
                return (
                  <option value={item} key={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
          {chosenState !== "" && (
            <div className="address-input-field">
              <label>Local Government</label>
              <select name="lga" id="lga" value={chosenLga} onChange={handleSelectLga}>
                {chosenStateLgas.map((item) => {
                  return (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
        </div>
        <div className="section">
          {chosenState === "Lagos" && (
            <div className="address-input-field">
              <label>LCDA</label>
              {/* <input type="text" name="lcda" value={lcda} /> */}
              <select
                name="lcda"
                id="lcda"
                value={chosenLcda}
                onChange={handleSelectLcda}>
                {lagosLcdas[chosenLga] &&
                  lagosLcdas[chosenLga].map((item) => {
                    return (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    );
                  })}
              </select>
            </div>
          )}
        </div>
        <button
          className="btn-finish"
          type="button"
          onClick={handleShowDropdown}
          id="btn-finish">
          Finish
        </button>
      </div>
    );
  };

  return (
    <div className={classes.inputField}>
      <label htmlFor={name}>{label}</label>

      <div className="input-wrapper" onClick={handleShowDropdown} ref={anchorRef}>
        <input
          id="input-field"
          className="input-wrapper__input"
          name={name}
          type={type}
          value={
            houseNumber.length !== 0 ||
            line.length !== 0 ||
            state.length !== 0 ||
            lga.length !== 0 ||
            lcda.length !== 0
              ? houseNumber + " " + line + " " + state + " " + lga + " " + lcda
              : placeholder
          }
          disabled
          placeholder={placeholder}
          onChange={() => {}}
        />
        <div className="input-wrapper__menu">
          <span id="input-menu" className="input-wrapper__menu__click-box"></span>
        </div>
        <ClickAwayListener onClickAway={handleCloseDropdown}>
          {renderDropdown()}
        </ClickAwayListener>
      </div>
      {err && (
        <div name={name} className={classes.errorMessage}>
          All address fields are required
        </div>
      )}
    </div>
  );
};

export default AddressDropdown;
