import { nigerianPhoneNumberPattern } from "#/components/utils/util";
import * as Yup from "yup";

import formModel from "./formModel";
const {
  formField: {
    businessName,
    businessPhone,
    businessRegistrationAddress,
    businessRegistrationNumber,
    companyEmail,
    lga,
    state
  }
} = formModel;

const TYPE_NUMBER_ERROR_MSG = "Must be a Number.";

const mobileNumberLengthCheck = (value) => {
  value = value.match(/\d/g) || []; //allow only digits, NOTE: match could return null hence the empty array second option
  value = value.join("");
  return value && value.length >= 10;
};

const phoneNumberRegex = /\d{11}/g;

const fileTypeCheck = (value, typesArray) => {
  if (typeof value === "string") {
    return /^https?:\/\/.+\.(jpg|jpeg|png)$/.test(value);
  }
  return !value || (value && typesArray.includes(value?.type));
};

const documentfileTypeCheck = (value, typesArray) => {
  if (typeof value === "string") {
    return /^https?:\/\/.+\.(pdf|docx)$/.test(value);
  }
  return !value || (value && typesArray.includes(value?.type));
};

export default Yup.object().shape({
  // [logo.name]: Yup.mixed()
  //   .nullable()
  //   // .required(`${logo.requiredErrorMsg}`)
  //   .test("File_type", `${logo.invalidFiletypeErrorMsg}`, (value) =>
  //     fileTypeCheck(value, logo.acceptedFileTypes)
  //   ),
  [businessName.name]: Yup.string(),
  [businessPhone.name]: Yup.string().matches(
    nigerianPhoneNumberPattern,
    `${businessPhone.invalidLengthErrorMsg}`
  ),
  [companyEmail.name]: Yup.string().email(`${companyEmail.invalidErrorMsg}`),
  [businessRegistrationNumber.name]: Yup.string(),
  [businessRegistrationAddress.name]: Yup.string(),
  [state.name]: Yup.string(),
  [lga.name]: Yup.string()
});
