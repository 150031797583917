import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import { loginStyles } from "../login/login.styles";
import { CustomInput } from "../../../components/ui-components/input.component";
import { useForm } from "react-hook-form";
import Logo1 from "../../../assets/images/liveizy-logo.png";
import CustomButton from "../../../components/ui-components/button.component";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgetPassword } from "../../../store/actions/auth.actions";
import { ErrorDiv } from "../../../components/errorDiv";
import AuthPageContainer from "./../AuthPageContainer";

const LoginForm = () => {
    const classes = loginStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { sending } = useSelector((state) => state.auth);
    const messages = useSelector((state) => state.notifications.msg);
    const { register, watch, handleSubmit, setValue, errors, reset } = useForm({ mode: "onBlur" });
    const values = watch();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValue(name, value);
    };

    const onFormSubmit = (vals) => {
        const { ...rest } = vals;
        const callbackUrl = window.location.origin;
        const data = { ...rest, callbackUrl };
        dispatch(
            forgetPassword(data, history, () => {
                reset();
            })
        );
    };

    return (
        <Grid container className={classes.formContainer}>
            <form className={classes.form} onSubmit={handleSubmit(onFormSubmit)}>
                <Grid
                    item
                    xs={12}
                    style={{
                        marginBottom: "2.5rem"
                    }}>
                    <div>
                        <img
                            src={Logo1}
                            alt="logo"
                            style={{
                                width: "6rem"
                            }}
                        />
                    </div>
                </Grid>
                <Grid
                    item
                    xs={12}
                    style={{
                        marginBottom: "1.5rem"
                    }}>
                    <span
                        style={{
                            color: "#043B5C",
                            fontSize: "1.2rem",
                            fontWeight: "bold"
                        }}>
                        ENTER EMAIL{" "}
                    </span>
                </Grid>
                <Grid item xs={12}>
                    <CustomInput
                        label="Email address"
                        name="email"
                        error={errors.email && errors.email.message}
                        customRef={register({
                            required: "This field is required",
                            pattern: {
                                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                message: "invalid email address"
                            }
                        })}
                        value={values.email || ""}
                        inputWidth="379px"
                        inputHeight="50px"
                        type="email"
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomButton
                        buttonText="Send"
                        isLoading={sending}
                        style={{
                            backgroundColor: "#4786FF",
                            color: "#FFFFFF",
                            width: "8rem",
                            textTransform: "none",
                            marginTop: "1.5rem",
                            marginBottom: "1rem"
                        }}
                    />
                    {messages !== "" && <ErrorDiv>{messages}</ErrorDiv>}
                </Grid>
                <Grid item xs={12}>
                    <div
                        style={{
                            color: "#043B5C",
                            fontSize: "0.8rem",
                            fontWeight: "bold"
                        }}>
                        <span>Need a Liveizy Account? </span>
                        <Link to="/register" style={{ color: "#1461A2" }}>
                            Create an Account
                        </Link>
                    </div>
                </Grid>
            </form>
        </Grid>
    );
};

const Forgetpassword = () => {
    return (
        <AuthPageContainer>
            <LoginForm />
        </AuthPageContainer>
    );
};

export default Forgetpassword;
