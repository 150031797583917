import axios from "axios";

import {
  APARTMENT_ACTIONTYPES_CONSTANTS,
  PROPERTY_ACTIONTYPES_CONSTANTS
} from "../constants";
import { showNotifications } from "#/store/actions/notification.actions";
import { getAllProperties } from "#/store/actions/property.actions";
import { propertyServiceApi } from "#/apis/propertyServiceApi";

//------------------------------------------------------------------
//Variables and Helpers
//------------------------------------------------------------------
const {
  GET_PM_PO_TOTAL_APARTMENT_RENT_VALUE,

  GET_APARTMENT_ID_FAIL,
  GET_APARTMENT_ID_SUCCESS,
  GET_APARTMENT_ID_PENDING,

  CREATE_APARTMENT_FAIL,
  CREATE_APARTMENT_PENDING,
  CREATE_APARTMENT_SUCCESS,

  GET_APARTMENT_BY_ID_FAIL,
  GET_APARTMENT_BY_ID_SUCCESS,
  GET_APARTMENT_BY_ID_PENDING,

  UPDATE_APARTMENT_FAIL,
  UPDATE_APARTMENT_PENDING,
  UPDATE_APARTMENT_SUCCESS,

  GET_VACANT_APARTMENTS_SUCCESS,
  GET_VACANT_APARTMENTS_FAIL,
  GET_VACANT_APARTMENTS_PENDING,

  DELETE_APARTMENT_PENDING,
  DELETE_APARTMENT_SUCCESS,
  DELETE_APARTMENT_FAIL,

  FILTER_VACANT_APARTMENTS_FAIL,
  FILTER_VACANT_APARTMENTS_SUCCESS,
  FILTER_VACANT_APARTMENTS_PENDING,

  RESET_FILTER,

  ADVERTISE_APARTMENTS_PENDING,
  ADVERTISE_APARTMENTS_SUCCESS,
  ADVERTISE_APARTMENTS_FAIL
} = APARTMENT_ACTIONTYPES_CONSTANTS;

const { CLEAR_PROPERTY_INFO } = PROPERTY_ACTIONTYPES_CONSTANTS;

const BASE_URL = process.env.REACT_APP_PROPERTY_SERVICE_BASE_URL;

//------------------------------------------------------------------
//Get Property Id
//------------------------------------------------------------------
export const generateApartmentId = (propertyId) => async (dispatch) => {
  dispatch({
    type: GET_APARTMENT_ID_PENDING
  });

  try {
    const res = await axios.get(`${BASE_URL}/apartment/generateUniqueID/${propertyId}`);

    if (res.status === 200) {
      dispatch({
        type: GET_APARTMENT_ID_SUCCESS,
        payload: `${propertyId}-${res.data.payload}`
      });
    }
  } catch (error) {
    if (process.env.NODE_ENV !== "production") {
      dispatch(
        showNotifications(
          `Error occurred while generating apartment ID: ${error}, ${error.response.data.payload}`,
          "error"
        )
      );
    }

    dispatch({
      type: GET_APARTMENT_ID_FAIL,
      payload: error
    });
  }
};

//------------------------------------------------------------------
//Create Apartment
//------------------------------------------------------------------
export const createApartment = (payload, propertyId, cb) => async (dispatch) => {
  dispatch({
    type: CREATE_APARTMENT_PENDING
  });

  try {
    const res = await axios.post(
      `${BASE_URL}/apartment/store?propertyId=${propertyId}&type=apartment`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "ngrok-skip-browser-warning": "1"
        }
      }
    );

    if ([200, 201].includes(res.status)) {
      dispatch({
        type: CREATE_APARTMENT_SUCCESS,
        payload: res.data.payload[0],
        message: res.data.payload.message
      });
      cb();
    }
  } catch (error) {
    if (process.env.NODE_ENV !== "production") {
      dispatch(
        showNotifications(
          `Error occurred while creating apartment: ${error}, ${error.response.data.payload}`,
          "error"
        )
      );
    }

    dispatch({
      type: CREATE_APARTMENT_FAIL,
      payload: error
    });
  }
};

//------------------------------------------------------------------
//Clear the property info associated with this apartment
//------------------------------------------------------------------
export const clearPropertyInfo = () => async (dispatch) => {
  dispatch({
    type: CLEAR_PROPERTY_INFO
  });
};

//------------------------------------------------------------------
//Get Apartment by Id
//------------------------------------------------------------------
export const getApartmentById = (id) => async (dispatch) => {
  dispatch({ type: GET_APARTMENT_BY_ID_PENDING });
  try {
    const response = await axios.get(`${BASE_URL}/apartment/show/${id}?type=apartment`);

    if ([200, 201].includes(response.status)) {
      dispatch({
        type: GET_APARTMENT_BY_ID_SUCCESS,
        apartmentById: response.data.payload
      });
    }
  } catch (error) {
    if (process.env.NODE_ENV !== "production") {
      dispatch(
        showNotifications(
          `Error occurred while getting apartment by ID: ${error}, ${error.response.data.payload}`,
          "error"
        )
      );
    }

    dispatch({ type: GET_APARTMENT_BY_ID_FAIL });
  }
};

//------------------------------------------------------------------
//Get Apartment Schedules
//------------------------------------------------------------------
export const getApartmentSchedule = (id) => async (dispatch) => {
  dispatch({ type: GET_APARTMENT_BY_ID_PENDING });
  try {
    const response = await axios.get(`${BASE_URL}/apartment/show/${id}?type=apartment`);
    if ([200, 201].includes(response.status)) {
      dispatch({
        type: GET_APARTMENT_BY_ID_SUCCESS,
        apartmentById: response.data.payload
      });
    }
  } catch (error) {
    if (process.env.NODE_ENV !== "production") {
      dispatch(
        showNotifications(
          `Error occurred while getting apartment schedules: ${error}, ${error.response.data.payload}`,
          "error"
        )
      );
    }

    dispatch({ type: GET_APARTMENT_BY_ID_FAIL });
  }
};

export const updateApartment = (payload, propertyId, id, cb) => async (dispatch) => {
  dispatch({ type: UPDATE_APARTMENT_PENDING });

  try {
    const res = await axios.put(
      `${BASE_URL}/apartment/update/${id}?propertyId=${propertyId}&type=apartment`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "ngrok-skip-browser-warning": "1"
        }
      }
    );

    if ([200, 201].includes(res.status)) {
      dispatch({
        type: UPDATE_APARTMENT_SUCCESS,
        payload: res.data.payload
      });
      cb();
    }
  } catch (error) {
    if (process.env.NODE_ENV !== "production") {
      dispatch(
        showNotifications(error.response.data.message || "error occured", "error")
      );
    }

    dispatch({
      type: UPDATE_APARTMENT_FAIL,
      payload: error
    });
  }
};

//------------------------------------------------------------------
//Get Vacant Apartments
//------------------------------------------------------------------
export const getAllVacantApartments = () => async (dispatch) => {
  dispatch({ type: GET_VACANT_APARTMENTS_PENDING });
  try {
    const response = await axios.get(`${BASE_URL}/apartment/vacantApartments`);

    if ([200, 201].includes(response.status)) {
      dispatch({
        type: GET_VACANT_APARTMENTS_SUCCESS,
        payload: response.data.payload
      });
    }
  } catch (error) {
    if (process.env.NODE_ENV !== "production") {
      dispatch(
        showNotifications(
          `Error occurred while getting all vacant apartments: ${error}, ${error.response.data.payload}`,
          "error"
        )
      );
    }

    dispatch({ type: GET_VACANT_APARTMENTS_FAIL });
  }
};

// GET CHARGES IN AN APARTMENT
export const getCharges = () => (dispatch) => {};

export const deleteApartment = (id, propertyId, cb) => async (dispatch) => {
  dispatch({ type: DELETE_APARTMENT_PENDING });

  propertyServiceApi()
    .delete(`${BASE_URL}/apartment/delete/${id}?propertyId=${propertyId}&type=apartment`)
    .then((response) => {
      dispatch(showNotifications("Apartment deleted successfully", "success"));
      dispatch({
        type: DELETE_APARTMENT_SUCCESS,
        payload: response.data.payload
      });
      dispatch(getAllProperties());
      cb();
    })
    .catch((error) => {
      dispatch(
        showNotifications(error.response.data.message || "error occured", "error")
      );
    });
};

export const filterVacantApartments = (state, cb) => async (dispatch) => {
  dispatch({ type: FILTER_VACANT_APARTMENTS_PENDING });

  try {
    const response = await axios.get(
      `${BASE_URL}/apartment/vacantApartments?state=${state}`
    );

    if ([200].includes(response.status)) {
      dispatch({
        type: FILTER_VACANT_APARTMENTS_SUCCESS,
        payload: response.data.payload
      });
    }
  } catch (error) {
    if (process.env.NODE_ENV !== "production") {
      dispatch(
        showNotifications(
          `Error occurred while filtering apartments: ${error}, ${error.response.data.payload}`,
          "error"
        )
      );
    }

    dispatch({ type: FILTER_VACANT_APARTMENTS_FAIL, error });
  }
};

export const resetFilter = () => async (dispatch) => {
  dispatch({ type: RESET_FILTER });
};

export const advertiseApartment = (apartmentId, payload) => async (dispatch) => {
  dispatch({ type: ADVERTISE_APARTMENTS_PENDING });

  try {
    const response = await axios.patch(
      `${BASE_URL}/apartment/advertise/${apartmentId}`,
      payload
    );
    if ([200, 201].includes(response.status)) {
      dispatch({
        type: ADVERTISE_APARTMENTS_SUCCESS
        // payload: response.data.message
      });
      dispatch(getApartmentById(apartmentId));
      // dispatch(showNotifications(`${response.data.message}`, "success"));
      dispatch(showNotifications(`Checked for Advertisment`, "success"));
    }
  } catch (error) {
    dispatch({ type: ADVERTISE_APARTMENTS_FAIL, error });
    // dispatch(showNotifications(`${error.response.data.error}`, "error"));
  }
};

//---------------------------------------------------------
//NETWORK REQUESTS
//---------------------------------------------------------
export const fetchApartmentById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/apartment/show/${id}`);

    if ([200, 201].includes(response.status)) {
      return response.data.payload;
    }
  } catch (error) {
    // if (process.env.NODE_ENV !== "production") {
    //     dispatch(
    //         showNotifications(
    //             `Error occurred while getting apartment by ID: ${error}, ${error.response.data.payload}`,
    //             "error"
    //         )
    //     );
    // }
    return error.response.data.payload;
  }
};
export const getApartmentDetailsById = async (aid) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/bill/get_apartment_bills/${aid}?type=apartment`
    );

    if ([200, 201].includes(response.status)) {
      return response.data.payload;
    }
  } catch (error) {
    return error.response.data.payload;
  }
};
