import { logout } from "#/store/actions/auth.actions";
import store from "#/store/store";

const axios = require("axios");

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const authServiceApi = () => {
  const userData = JSON.parse(localStorage.getItem("data"));

  let token = userData ? userData.token : "";

  if (!token) token = "";

  const instance = axios.create({
    baseURL,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token
    }
  });

  instance.interceptors.request.use(
    (config) => {
      if (!config.headers.common.Authorization) {
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      // handle Unauthorized error
      if (error.response.status === 401) {
        store.dispatch(logout()); // dispatch the logout action
      } else {
        return Promise.reject(error);
      }
    }
  );

  return instance;
};

export const authServiceUrl = baseURL;
