import React from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { string, bool, func, oneOf, object } from "prop-types";

const CustomButton = ({
    buttonText,
    isLoading = false,
    type = "submit",
    fullWidth = false,
    size = "medium",
    disabled = false,
    onClick,
    className,
    style
}) => {
    return (
        <Button
            type={type}
            fullWidth={fullWidth}
            size={size}
            disabled={disabled}
            onClick={onClick}
            className={className}
            style={style}>
            {isLoading ? <CircularProgress size={24} color="inherit" /> : buttonText}
        </Button>
    );
};

CustomButton.propTypes = {
    buttonText: string,
    isLoading: bool,
    onClick: func,
    fullWidth: bool,
    size: oneOf(["medium", "large", "small"]),
    disabled: bool,
    type: string,
    style: object
};

export default CustomButton;
