import React, { useState } from "react";
import DetailsCardContainer, { DetailsContainer } from "#/components/cards/DetailsCardContainer.component";
import SubtitleAndSubtext from "#/components/ui-components/SubtitleAndSubtext.component";
import { Button, CircularProgress, Grid, Stack, styled } from "@mui/material";
import { Form, Formik } from "formik";
import FormikCustomInputField from "#/components/formik/formfields/FormikCustomInputField";
import { meansOfIdInitialValues } from "./formInitialValues";
import { useDispatch, useSelector } from "react-redux";
import { meansOfIdSchema } from "./validationSchema";
import SelectField from "#/components/formik/formfields/SelectField";
import { meansOfIdSubmit } from "./utils";
import { meansOfIdModel } from "./formModel";
import FormikCustomSelectField from "#/components/formik/formfields/FormikCustomSelectField";
import { meansOfIdOptions } from "../accountSettting.constants";
import ImageRectPreviwer from "#/components/ui-components/ImageRectPreviwer";
import FormikCustomImageRectUploader from "#/components/formik/formfields/FormikCustomImageRectUploader";

const IdBox = styled("div")`
    margin: 15px auto;
    padding: 15px;
    box-sizing: border-box;
    width: 200px;
    height: 100px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(#ffff, #ffff) padding-box, linear-gradient(to right, #e1f2ff, #10609f) border-box;
    border: 1px solid transparent;
    border-radius: 8px;
    gap: 10px;
    color: #10609f;
    box-shadow: 0px 1.27598px 14.6738px -3.82794px rgba(0, 0, 0, 0.2);
`;

const btnStyle = {
    background: "#4786FF",
    borderRadius: "5px",
    color: "#F4F5F7",
    border: "1px solid #4786FF",
    padding: "10px 30px",
    fontSize: "12px",
    fontFamily: "Open Sans-Semibold, sans-serif",
    width: "145px",

    "&:hover": {
        background: "#4786FF"
    }
};

const {
    formField: { meansOfId, meansOfIdFile, nameOfNextOfKin, contactOfNextOfKin }
} = meansOfIdModel;

const MeansOfIdentification = () => {
    const [editMode, setEditMode] = useState(false);
    const dispatch = useDispatch();

    const { otherInformation, updatingOtherInfo } = useSelector((state) => state.auth);

    // formik
    const initialValues = meansOfIdInitialValues(otherInformation);

    const handleFormSubmit = async (values, actions) => {
        if (updatingOtherInfo) return;
        meansOfIdSubmit(values, dispatch, setEditMode);
        actions.setSubmitting(false);
    };

    const handleEditMode = () => {
        setEditMode(!editMode);
    };

    return (
        <>
            {!editMode ? (
                <>
                    <DetailsCardContainer>
                        <DetailsContainer>
                            <SubtitleAndSubtext
                                loading={updatingOtherInfo}
                                sm={4}
                                lg={4}
                                subtitle={"Means Of ID"}
                                subtext={otherInformation?.means_of_id}
                            />

                            <SubtitleAndSubtext
                                loading={updatingOtherInfo}
                                sm={4}
                                lg={4}
                                subtitle={"Name Of Next of Kin"}
                                subtext={otherInformation?.name_of_next_of_kin}
                            />
                            <SubtitleAndSubtext
                                loading={updatingOtherInfo}
                                sm={4}
                                lg={4}
                                subtitle={"Contact Of Next of Kin"}
                                subtext={otherInformation?.contact_of_next_kin}
                            />
                        </DetailsContainer>
                    </DetailsCardContainer>
                    <ImageRectPreviwer src={otherInformation?.means_of_id_file} />

                    <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "60px" }}>
                        <Button
                            onClick={handleEditMode}
                            sx={{ width: "120px", background: "#10609F" }}
                            variant="contained">
                            Edit
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize={true}
                        validationSchema={meansOfIdSchema}
                        onSubmit={handleFormSubmit}>
                        {({ isSubmitting }) => (
                            <Form>
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <FormikCustomSelectField
                                            options={meansOfIdOptions}
                                            name={meansOfId.name}
                                            label={meansOfId.label}
                                            placeholder={meansOfId.placeHolder}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormikCustomInputField
                                            name={nameOfNextOfKin.name}
                                            label={nameOfNextOfKin.label}
                                            placeholder={nameOfNextOfKin.placeHolder}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormikCustomInputField
                                            name={contactOfNextOfKin.name}
                                            label={contactOfNextOfKin.label}
                                            placeholder={contactOfNextOfKin.placeHolder}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormikCustomImageRectUploader
                                            name={meansOfIdFile.name}
                                            label={meansOfIdFile.label}
                                        />
                                    </Grid>
                                </Grid>
                                <Stack direction={"row"} spacing={2} justifyContent="center">
                                    <Button
                                        type="button"
                                        // isLoading={updatingOtherInfo}
                                        sx={btnStyle}
                                        disabled={updatingOtherInfo}
                                        onClick={handleEditMode}
                                        style={{ background: "white", color: "blue" }}>
                                        Cancel
                                    </Button>

                                    <Button
                                        type="submit"
                                        disabled={updatingOtherInfo}
                                        // isLoading={updatingOtherInfo}
                                        sx={btnStyle}>
                                        {updatingOtherInfo ? <CircularProgress size={"small"} /> : "Update"}
                                    </Button>
                                </Stack>
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </>
    );
};

export default MeansOfIdentification;
