import React from "react";
import { Route, Redirect } from "react-router-dom";
import axios from "axios";

// axios.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     throw error;
//   }
// );

const AuthRouter = ({ component: Component, render, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            window.localStorage.getItem("data") ? (
                <div>
                    <Redirect to="/dashboard" />
                </div>
            ) : (
                <div>
                    <Component {...props} />
                </div>
            )
        }
    />
);

export default AuthRouter;
