import { REMINDER_CONSTANTS } from "../constants";

//------------------------------------------------------------------
//Variables and Helpers
//------------------------------------------------------------------
const {
    CREATE_PROPERTY_REMINDER_PENDING,
    CREATE_PROPERTY_REMINDER_SUCCESS,
    CREATE_PROPERTY_REMINDER_FAIL,
    CREATE_APARTMENT_REMINDER_PENDING,
    CREATE_APARTMENT_REMINDER_SUCCESS,
    CREATE_APARTMENT_REMINDER_FAIL,

    GET_INACTIVE_REMINDERS_FAIL,
    GET_INACTIVE_REMINDERS_PENDING,
    GET_INACTIVE_REMINDERS_SUCCESS,

    GET_ACTIVE_REMINDERS_FAIL,
    GET_ACTIVE_REMINDERS_PENDING,
    GET_ACTIVE_REMINDERS_SUCCESS,

    GET_DELIVERED_REMINDERS_FAIL,
    GET_DELIVERED_REMINDERS_PENDING,
    GET_DELIVERED_REMINDERS_SUCCESS,

    RENT_REMINDER_PAYMENT_FAIL,
    RENT_REMINDER_PAYMENT_PENDING,
    RENT_REMINDER_PAYMENT_SUCCESS,

    CHECK_REMINDERS_EXIST_FAIL,
    CHECK_REMINDERS_EXIST_SUCCESS,
    CHECK_REMINDERS_EXIST_PENDING,

    GET_SUBSCRIPTION_REMINDERS_SUMMARY_FAIL,
    GET_SUBSCRIPTION_REMINDERS_SUMMARY_PENDING,
    GET_SUBSCRIPTION_REMINDERS_SUMMARY_SUCCESS,

    GET_APARTMENTS_IN_PLAN_FAIL,
    GET_APARTMENTS_IN_PLAN_PENDING,
    GET_APARTMENTS_IN_PLAN_SUCCESS
} = REMINDER_CONSTANTS;

const initialState = {
    isSending: false,
    loadingSummary: false,
    paying: false,
    remindersExist: false,
    apartmentsInPlan: 0
};

const reminderReducer = (state = initialState, action) => {
    const { type, payload, message } = action;

    switch (type) {
        case CREATE_PROPERTY_REMINDER_PENDING:
            return {
                ...state,
                isSending: true
            };
        case CREATE_PROPERTY_REMINDER_SUCCESS:
            return {
                ...state,
                isSending: false
            };
        case CREATE_PROPERTY_REMINDER_FAIL:
            return {
                ...state,
                isSending: false
            };
        case CREATE_APARTMENT_REMINDER_PENDING:
            return {
                ...state,
                isSending: true
            };
        case CREATE_APARTMENT_REMINDER_SUCCESS:
            return {
                ...state,
                isSending: false
            };
        case CREATE_APARTMENT_REMINDER_FAIL:
            return {
                ...state,
                isSending: false
            };
        case GET_INACTIVE_REMINDERS_PENDING:
            return {
                ...state,
                loadingInactiveReminders: true
            };
        case GET_INACTIVE_REMINDERS_SUCCESS:
            return {
                ...state,
                inactiveReminders: payload,
                loadingInactiveReminders: false
            };
        case GET_INACTIVE_REMINDERS_FAIL:
            return {
                ...state,
                loadingInactiveReminders: false
            };
        case GET_ACTIVE_REMINDERS_PENDING:
            return {
                ...state,
                loadingReminders: true,
                loadingActiveReminders: true
            };
        case GET_ACTIVE_REMINDERS_SUCCESS:
            return {
                ...state,
                activeReminders: payload,
                loadingActiveReminders: false
            };
        case GET_ACTIVE_REMINDERS_FAIL:
            return {
                ...state,
                loadingActiveReminders: false
            };
        case GET_DELIVERED_REMINDERS_PENDING:
            return {
                ...state,
                loadingDeliveredReminders: true
            };
        case GET_DELIVERED_REMINDERS_SUCCESS:
            return {
                ...state,
                deliveredReminders: payload,
                loadingDeliveredReminders: false
            };
        case GET_DELIVERED_REMINDERS_FAIL:
            return {
                ...state,
                loadingDeliveredReminders: false
            };

        case RENT_REMINDER_PAYMENT_PENDING:
            return {
                ...state,
                paying: true
            };
        case RENT_REMINDER_PAYMENT_SUCCESS:
            return {
                ...state,
                paying: false
            };
        case RENT_REMINDER_PAYMENT_FAIL:
            return {
                ...state,
                paying: false
            };

        case CHECK_REMINDERS_EXIST_PENDING:
            return {
                ...state
            };
        case CHECK_REMINDERS_EXIST_SUCCESS:
            return {
                ...state,
                remindersExist: payload
            };
        case CHECK_REMINDERS_EXIST_FAIL:
            return {
                ...state,
                remindersExist: false
            };
        case GET_SUBSCRIPTION_REMINDERS_SUMMARY_PENDING:
            return {
                ...state,
                loadingSummary: true
            };
        case GET_SUBSCRIPTION_REMINDERS_SUMMARY_SUCCESS:
            return {
                ...state,
                ...payload.data,
                loadingSummary: false
            };
        case GET_SUBSCRIPTION_REMINDERS_SUMMARY_FAIL:
            return {
                ...state,
                loadingSummary: false
            };
        case GET_APARTMENTS_IN_PLAN_PENDING:
            return {
                ...state
            };
        case GET_APARTMENTS_IN_PLAN_SUCCESS:
            return {
                ...state,
                apartmentsInPlan: payload.length
            };
        case GET_APARTMENTS_IN_PLAN_FAIL:
            return {
                ...state
            };
        default:
            return state;
    }
};

export default reminderReducer;
