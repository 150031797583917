import React, { useEffect, useState } from "react";
import { Button, TextField, makeStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import NaijaStates from "naija-state-local-government";
import clsx from "clsx";
import { ReactComponent as Camera } from "../../../assets/svg/camera.svg";
import defaultAvatar from "../../../assets/images/default-avatar.png";
import "./personal-details.scss";
import ProgressBar from "../progressBar";
import { handleFileInput, handleFormInput, validate } from "#/lib/validation";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
    root: {
        "& .MuiOutlinedInput-root": {
            borderRadius: 12
        }
    },
    btn: {
        height: 50
    }
}));

const PersonalDetails = ({ setInDisplay, formValues, setFormValues }) => {
    const classes = useStyles();
    const history = useHistory();
    const fields = {
        photo: formValues.photo,
        first_name: formValues.first_name,
        last_name: formValues.last_name,
        other_names: formValues.other_names,
        email: formValues.email,
        state_of_origin: formValues.state_of_origin,
        date_of_birth: formValues.date_of_birth
    };

    const [enableSubmit, setEnableSubmit] = useState(false);

    useEffect(() => {
        validate(fields, setEnableSubmit);
    }, [formValues]);

    const handleNextPage = () => {
        setInDisplay(2);
    };

    const handleLastPage = () => {
        history.goBack();
    };

    return (
        <div className="personal-details-show-of-interest">
            <ProgressBar status1 />
            <div className="avatar-container">
                <div className="avatar">
                    <img
                        src={
                            (formValues.photo?.value instanceof File && URL.createObjectURL(formValues.photo?.value)) ||
                            formValues.photo?.value ||
                            defaultAvatar
                        }
                        alt="livezy show of interest"
                    />
                    <input
                        id="profile-avatar"
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                            const file = e.target.files[0];
                            handleFileInput("photo", file, 1000000, formValues, setFormValues);
                        }}
                    />
                    <label htmlFor="profile-avatar">
                        <Camera />
                    </label>
                </div>
                {!formValues.photo?.value && <div>NB: profile photo is required</div>}
                <div className="avatar-error">{formValues.photo.validation}</div>
            </div>
            <div className="form-container">
                <TextField
                    className={clsx(classes.root, "textfield-soi")}
                    label="First Name"
                    variant="outlined"
                    id="custom-css-outlined-input1"
                    value={formValues.first_name?.value}
                    error={!formValues.first_name.validation}
                    helperText={formValues.first_name.error}
                    onChange={(e) => {
                        const value = e.target?.value;
                        handleFormInput("first_name", value, /./gi, formValues, setFormValues, ["required", "name"]);
                    }}
                />
                <TextField
                    className={clsx(classes.root, "textfield-soi")}
                    label="Last Name"
                    variant="outlined"
                    id="custom-css-outlined-input2"
                    value={formValues.last_name?.value}
                    error={!formValues.last_name.validation}
                    helperText={formValues.last_name.error}
                    onChange={(e) => {
                        const value = e.target?.value;
                        handleFormInput("last_name", value, /.+/gi, formValues, setFormValues, ["required", "name"]);
                    }}
                />
                <TextField
                    className={clsx(classes.root, "textfield-soi")}
                    label="Other Names"
                    variant="outlined"
                    id="custom-css-outlined-input3"
                    value={formValues.other_names?.value}
                    error={!formValues.other_names.validation}
                    helperText={formValues.other_names.error}
                    onChange={(e) => {
                        const value = e.target?.value;
                        handleFormInput("other_names", value, /.+/gi, formValues, setFormValues, ["required", "name"]);
                    }}
                />
                <TextField
                    className={clsx(classes.root, "textfield-soi")}
                    label="Email"
                    variant="outlined"
                    id="custom-css-outlined-input4"
                    value={formValues.email?.value}
                    error={!formValues.email.validation}
                    helperText={formValues.email.error}
                    onChange={(e) => {
                        const value = e.target?.value;
                        handleFormInput("email", value, null, formValues, setFormValues, ["email"]);
                    }}
                />
                <Autocomplete
                    id="combo-box-demo"
                    className="textfield-soi"
                    options={NaijaStates.states()}
                    value={formValues.state_of_origin.value}
                    renderInput={(params) => (
                        <TextField className={classes.root} {...params} label="State of Origin" variant="outlined" />
                    )}
                    onChange={(e, value) => {
                        handleFormInput("state_of_origin", value, /./gi, formValues, setFormValues);
                    }}
                />
                <TextField
                    className={clsx(classes.root, "textfield-soi")}
                    type="date"
                    label="Date of Birth"
                    variant="outlined"
                    id="custom-css-outlined-input6"
                    InputLabelProps={{ shrink: true }}
                    value={formValues.date_of_birth?.value}
                    error={!formValues.date_of_birth.validation}
                    helperText={formValues.date_of_birth.error}
                    onChange={(e) => {
                        const value = e.target?.value;
                        handleFormInput("date_of_birth", value, /.+/gi, formValues, setFormValues);
                    }}
                />
            </div>
            <div className="btn-container">
                <Button className="btn-proceed" variant="outlined" color="primary" onClick={handleLastPage}>
                    BACK
                </Button>
                <Button
                    disabled={!enableSubmit}
                    className="btn-proceed"
                    variant="outlined"
                    color="primary"
                    onClick={handleNextPage}>
                    NEXT
                </Button>
            </div>
        </div>
    );
};

export default PersonalDetails;
