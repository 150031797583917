import Cookie from 'js-cookie'

export function getCookieValue(key) {
  return Cookie.get(key)
}

export function clearCookieValue(key) {
  return Cookie.remove(key)
}

export function saveCookie (key, value) {
  Cookie.set(key, value, {expires: 7})
} 