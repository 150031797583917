import React, { useState, memo, useEffect } from "react";
import { Autocomplete } from "@material-ui/lab";
import NaijaStates from "naija-state-local-government";
import { useCustomFiltersStyles } from "./customFilters.styles";
import { handleFormInput } from "#/lib/validation";
import { TextField } from "@material-ui/core";

const CustomFilters = ({ formValues, setFormValues }) => {
    const classes = useCustomFiltersStyles();

    const [lgas, setLgas] = useState(NaijaStates.lgas("Lagos").lgas);

    const floors = [
        "ground floor",
        "1st floor",
        "2nd floor",
        "3rd floor",
        "4th floor",
        "5th floor",
        "6th floor",
        "7th floor",
        "8th floor",
        "9th floor",
        "10th floor"
    ];

    const handleStateChange = (e, value) => {
        if (!value) return;
        if (value) {
            const newLgas = NaijaStates.lgas(value).lgas;
            setLgas(newLgas);
        }
        setFormValues({
            ...formValues,
            state: { value },
            lga: { value: "" },
            skip: { value: 0 }
        });
    };

    const handleFilterChange = (name, value) => {
        setFormValues({
            ...formValues,
            [name]: { value },
            skip: { value: 0 }
        });
    };

    const convertToNumber = (floor) => {
        if (floor === "ground floor") return 0;
        if (floor === "1st floor") return 1;
        if (floor === "2nd floor") return 2;
        if (floor === "3rd floor") return 3;
        if (floor === "4th floor") return 4;
        if (floor === "5th floor") return 5;
        if (floor === "6th floor") return 6;
        if (floor === "7th floor") return 7;
    };

    return (
        <>
            <div className={classes.root}>
                <div className="listing-filter-block">
                    <Autocomplete
                        className="form-fields"
                        options={NaijaStates.states()}
                        fullWidth
                        style={{ width: 200 }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select State"
                                variant="standard"
                                fullWidth
                                //error={!formValues.state.validation}
                                style={{ width: "100%", marginBottom: 20 }}
                            />
                        )}
                        value={formValues.state.value || "Lagos"}
                        onChange={handleStateChange}
                    />
                    <Autocomplete
                        className="form-fields"
                        options={lgas}
                        fullWidth
                        style={{ width: 200 }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select LGA"
                                variant="standard"
                                fullWidth
                                //error={!formValues.lga.validation}
                                style={{ width: "100%", marginBottom: 20 }}
                            />
                        )}
                        value={formValues.lga.value}
                        onChange={(e, value) => {
                            const accepted = value && value !== "null" ? value : "";
                            handleFilterChange("lga", accepted);
                        }}
                    />
                    <TextField
                        label="Minimum Price (&#8358;)"
                        variant="standard"
                        fullWidth
                        value={formValues.price.value}
                        //error={!formValues.price.validation}
                        style={{ width: 200, marginBottom: 20 }}
                        onChange={(e) => {
                            const accepted = e.target.value.replace(/[a-z\W]/gi, "");
                            handleFilterChange("price", accepted);
                        }}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                const accepted = e.target.value.replace(/[a-z\W]/gi, "");
                                handleFilterChange("price", accepted);
                            }
                        }}
                    />
                    <Autocomplete
                        className="form-fields"
                        options={floors}
                        fullWidth
                        style={{ width: 200 }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select Position"
                                variant="standard"
                                fullWidth
                                //error={!formValues.floor.validation}
                                style={{ width: "100%", marginBottom: 20 }}
                            />
                        )}
                        value={floors[formValues.floor.value]}
                        onChange={(e, value) => {
                            const accepted = value && value !== "null" ? convertToNumber(value) : "";
                            handleFilterChange("floor", accepted);
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default memo(CustomFilters);
