import { TextField } from "@mui/material";
import { useField } from "formik";
import React from "react";

const InputField = ({ name, type, ...otherProps }) => {
    const [field, meta] = useField(name);
    // console.log("value", name, field)

    const configTextField = {
        ...field,
        ...otherProps,
        fullWidth: true,
        variant: "outlined"
    };

    // check for errors
    if (meta && meta.touched && meta.error) {
        configTextField.error = true;
        configTextField.helperText = meta.error;
    }
    // props for numbers
    if (type && type === "number") {
        configTextField.inputProps = { inputMode: "numeric", pattern: "[0-9]*" };
    }

    return <TextField {...configTextField} size="small" />;
};

export default InputField;
