import {
  getProfileInformation,
  updateBusinessInformation
} from "#/store/actions/auth.actions";
import { showNotifications } from "#/store/actions/notification.actions";
import axios from "axios";

const configAuthService = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "X-Requested-With": "XMLHttpRequest"
  }
};

const postProfileImageToCloudinary = async (profileImage) => {
  try {
    const formData = new FormData();
    formData.append("file", profileImage);
    formData.append("upload_preset", "monthly_rent_applicants_profile_images");

    const data = await fetch("https://api.cloudinary.com/v1_1/dfz1vgrgv/image/upload", {
      method: "POST",
      body: formData
    })
      .then((response) => response.json())
      .catch((error) => {
        console.log(error); // do something else, like dispatch error message
      });
    return data.secure_url;
  } catch (error) {
    console.log(error); // do something else
  }
};

export const submitForm = async (values, dispatch, setEditMode) => {
  try {
    // let businessLogo;
    // if (typeof values?.logo === "string") {
    //     businessLogo = values?.logo;
    // } else {
    //     businessLogo = await postProfileImageToCloudinary(values?.logo);
    // }
    const businessInfo = {
      // logo: businessLogo,
      business_name: values?.businessName,
      business_phone: values?.businessPhone,
      company_email: values?.companyEmail,
      state: values?.state,
      lga: values?.lga,
      business_registration_number: values?.businessRegistrationNumber,
      business_registration_address: values?.businessRegistrationAddress
    };
    dispatch(updateBusinessInformation(businessInfo, () => setEditMode(false)));
  } catch (error) {
    dispatch(showNotifications("An Error Ocuured", "error"));
  }
};
