import axios from "axios";

import { showNotifications, alertSuccess, alertError } from "#/store/actions/notification.actions";
import { walletServiceApi } from "../../apis/izyPayApi";

import { APARTMENT_PAYMENTS, ESTATE_PAYMENTS } from "../constants";
import { propertyServiceApi } from "#/apis/propertyServiceApi";

const { PAY_ESTATE_FEES_PENDING, PAY_ESTATE_FEES_SUCCESS, PAY_ESTATE_FEES_FAIL } = ESTATE_PAYMENTS;

const {
    PAY_RENT_FAIL,
    PAY_RENT_PENDING,
    PAY_RENT_SUCCESS,

    PAY_APARTMENT_CHARGES_FAIL,
    PAY_APARTMENT_CHARGES_PENDING,
    PAY_APARTMENT_CHARGES_SUCCESS,

    RENEW_RENT_FAIL,
    RENEW_RENT_PENDING,
    RENEW_RENT_SUCCESS,

    RESET_PAYMENT_STORE_DETAILS,

    GET_RENT_RECORDS_FAIL,
    GET_RENT_RECORDS_PENDING,
    GET_RENT_RECORDS_SUCCESS
} = APARTMENT_PAYMENTS;
const BASE_URL = process.env.REACT_APP_PROPERTY_SERVICE_BASE_URL;

//Renew tenant rent
export const renewTenantRent = (body, cb = () => {}) => async (dispatch) => {
    dispatch({ type: RENEW_RENT_PENDING });
    try {
        const res = await axios.post(`${BASE_URL}/payments/part-payment`, body);
        dispatch({ type: RENEW_RENT_SUCCESS, payload: res.data });
        dispatch(showNotifications(`${res.data.message}`, "success"));
        localStorage.removeItem("RENEW_RENT");

        cb(true);
    } catch (error) {
        cb(false);
        dispatch({ type: RENEW_RENT_FAIL, payload: error });
        const errorMessage = error.response?.data.error || error.message;
        dispatch(showNotifications(errorMessage, "error"));
    }
};

//For a new tenant
export const payForRent = (payload, history) => async (dispatch) => {
    dispatch({ type: PAY_RENT_PENDING });

    try {
        await axios.post(`${BASE_URL}/payments/rent-and-charges`, payload);

        dispatch({ type: PAY_RENT_SUCCESS });
        dispatch(showNotifications("First rent successfully paid!!!", "success"));
        dispatch(showNotifications("Apartment charges successfully paid!!!", "success"));
        localStorage.removeItem("PAY_RENT_AND_CHARGES");

        return history.push("/dashboard");
    } catch (error) {
        dispatch(showNotifications(`${error?.response.data.error}`, "error"));

        dispatch({ type: PAY_RENT_FAIL, payload: error });
        dispatch({ type: PAY_APARTMENT_CHARGES_FAIL, payload: error });
    }
};

//For a new tenant
export const payForRentWithSpecta = (payload, payload2, history) => async (dispatch) => {
    try {
        const pwsResponse = await walletServiceApi().post("/user/izypay/specta/pay", payload);
        window.location.assign(pwsResponse.data.data.payUrl);
    } catch (error) {
        const { response, message } = error;
        const feedback = response?.data?.message || response?.data?.error || "pay with specta error";
        dispatch(showNotifications(feedback, "error"));

        dispatch({ type: PAY_RENT_FAIL, payload: error });
        dispatch({ type: PAY_APARTMENT_CHARGES_FAIL, payload: error });
    }
};

//Paying for apartment charges
export const payForApartmentCharges = (body, history) => async (dispatch) => {
    try {
        dispatch({ type: PAY_APARTMENT_CHARGES_PENDING });
        const res = await propertyServiceApi().post("/payments/charge-payment", body);
        dispatch({
            type: PAY_APARTMENT_CHARGES_SUCCESS,
            payload: res.data
        });
        localStorage.removeItem("PAY_CHARGES");
        dispatch(showNotifications(res.data.message, "success"));

        return history.push("/dashboard");
    } catch (error) {
        dispatch(showNotifications(`${error.response.data.error}`, "error"));

        dispatch({
            type: PAY_APARTMENT_CHARGES_FAIL,
            payload: error
        });
    }
};

export const payForEstateFees = (payload, cb) => async (dispatch) => {
    dispatch({ type: PAY_ESTATE_FEES_PENDING });
    propertyServiceApi()
        .post("/payments/estate-management/fees", payload)
        .then(() => {
            cb(true);
            localStorage.removeItem("ESM_PAY_FEES");
            dispatch({ type: PAY_ESTATE_FEES_SUCCESS });
            dispatch(alertSuccess("Payment for fees successfull!!", 10000));
        })
        .catch(({ response, message }) => {
            cb(false);
            dispatch({ type: PAY_ESTATE_FEES_FAIL });
            dispatch(alertError(response.data.error || message));
        });
};

//Clean up all the data
export const resetPaymentStoreDetails = () => async (dispatch) => {
    dispatch({
        type: RESET_PAYMENT_STORE_DETAILS
    });
};
