import React, { useState, memo, useEffect, useRef } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { useApartDetailsDropdownStyles } from "./apartmentDetailsDropdown.styles";

const ApartmentDetailsDropdown = ({ name, label, apartDetailsDropdownHelpers, error }) => {
    const { handleOnChangeInput, otherFacilities, mainFacilities, handleToggleCheck } = apartDetailsDropdownHelpers;

    //--------------------------------------------------------
    //Toggle display of dropdown
    //--------------------------------------------------------
    const [showDropDown, setShowDropdown] = useState(false);
    const anchorRef = useRef(null);

    const handleShowDropdown = (e) => {
        const { id } = e.target;
        if (id === "input-field" || id === "input-menu") {
            setShowDropdown(!showDropDown);
        }
    };

    const handleCloseDropdown = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setShowDropdown(false);
    };
    //--------------------------------------------------------
    //--------------------------------------------------------
    //--------------------------------------------------------

    const { bedrooms, bathrooms, sittingRooms, ensuites } = mainFacilities;

    const {
        prepaidElectricMeter,
        analogElectric,
        gas,
        water,
        internetAndCable,
        ac,
        pool,
        furnished,
        balcony,
        marble,
        tiles
    } = otherFacilities;

    const classes = useApartDetailsDropdownStyles(showDropDown);

    const ROOMS = [
        { label: "Bedrooms", value: bedrooms, name: "bedrooms" },
        { label: "Bathrooms", value: bathrooms, name: "bathrooms" },
        { label: "Sitting Rooms", value: sittingRooms, name: "sittingRooms" },
        { label: "Ensuites", value: ensuites, name: "ensuites" }
    ];

    const RENT_INCLUDES = [
        {
            name: "prepaidElectricMeter",
            displayValue: "Prepaid Electric Meter",
            checked: prepaidElectricMeter
        },
        {
            name: "analogElectric",
            displayValue: "Analog Electric",
            checked: analogElectric
        },
        {
            name: "gas",
            displayValue: "Gas",
            checked: gas
        },
        {
            name: "water",
            displayValue: "Borehole",
            checked: water
        },
        {
            name: "internetAndCable",
            displayValue: "Internet and Cable",
            checked: internetAndCable
        }
    ];

    const AMENITIES = [
        {
            name: "ac",
            displayValue: "A/C",
            checked: ac
        },
        {
            name: "pool",
            displayValue: "Pool",
            checked: pool
        },
        {
            name: "furnished",
            displayValue: "Furnished",
            checked: furnished
        },
        {
            name: "balcony",
            displayValue: "Balcony",
            checked: balcony
        },
        {
            name: "marble",
            displayValue: "Marble",
            checked: marble
        },
        {
            name: "tiles",
            displayValue: "Tiles",
            checked: tiles
        }
    ];

    const renderDropdown = () => {
        return (
            <div className={classes.apartmentDetailsDropdown}>
                <div className="section" onChange={handleOnChangeInput}>
                    {ROOMS.map((item) => {
                        const { label, name, value } = item;
                        return (
                            <div>
                                <label htmlFor="" className="section__title">
                                    {label}
                                </label>
                                <input type="text" className="section__input" name={name} value={value} />
                            </div>
                        );
                    })}
                </div>
                <div className="section">
                    <div>
                        <label htmlFor="" className="section__title">
                            Rent includes
                        </label>
                        <ul>
                            {RENT_INCLUDES.map((item) => {
                                const { name, displayValue, checked } = item;
                                return (
                                    <li key={name} className="checkbox">
                                        <input
                                            type="checkbox"
                                            name={name}
                                            checked={checked}
                                            className="checkbox__input"
                                            onChange={handleToggleCheck}
                                        />
                                        <label htmlFor="" className="checkbox__label">
                                            {displayValue}
                                        </label>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div>
                        <label htmlFor="" className="section__title">
                            Amenities
                        </label>
                        <ul>
                            {AMENITIES.map((item) => {
                                const { name, displayValue, checked } = item;
                                return (
                                    <li key={name} className="checkbox">
                                        <input
                                            type="checkbox"
                                            name={name}
                                            checked={checked}
                                            className="checkbox__input"
                                            onChange={handleToggleCheck}
                                        />
                                        <label htmlFor="" className="checkbox__label">
                                            {displayValue}
                                        </label>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={classes.inputField}>
            <label htmlFor={name}>{label}</label>
            <div className="input-wrapper" onClick={handleShowDropdown} ref={anchorRef}>
                <div id="input-field" className="input-wrapper__details" name={name}>
                    {/* {bedrooms && <span>beI{bedrooms}</span>}
                    {bathrooms && <span>baI{bathrooms}</span>}
                    {sittingRooms && <span>romI{sittingRooms}</span>}
                    {ensuites && <span>suiI{ensuites}</span>} */}
                    {/* {prepaidElectricMeter && <span>preI</span>}
                    {analogElectric && <span>anlI</span>}
                    {gas && <span>gasI</span>}
                    {water && <span>watI</span>}
                    {internetAndCable && <span>intI</span>}
                    {ac && <span>acI</span>}
                    {pool && <span>poI</span>}
                    {furnished && <span>furI</span>}
                    {balcony && <span>balI</span>}
                    {marble && <span>marbI</span>}
                    {tiles && <span>tilI</span>} */}
                </div>
                <div className="input-wrapper__menu">
                    <span id="input-menu" className="input-wrapper__menu__click-box"></span>
                </div>
                <ClickAwayListener onClickAway={handleCloseDropdown}>{renderDropdown()}</ClickAwayListener>
            </div>
            {error && (
                <div name={name} className={classes.errorMessage}>
                    Bedrooms,Bathrooms,Sitting Rooms and Ensuites are required{" "}
                </div>
            )}
        </div>
    );
};

export default memo(ApartmentDetailsDropdown);
