import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Radio from "@material-ui/core/Radio";
import React from "react";

export const CustomRadio = withStyles({
    root: {
        color: "#DEEDFF",
        "&$checked": {
            color: "#4786FF"
        }
    },
    checked: {}
})((props) => <Radio color="default" {...props} />);

export const roleStyles = makeStyles((theme) => ({
    root: {
        padding: "50px",

        "@media(max-width:999px)": {
            padding: "0px"
        }
    },

    header: {
        background: "#EEF4FB",
        borderRadius: "10px",
        marginBottom: "10px",

        "@media(min-width:768px)": {
            height: "280px"
        },

        "&  .header-content": {
            padding: "30px",

            "@media(min-width:768px)": {
                padding: "80px 80px 80px 60px"
            },

            "& h3": {
                color: "#043B5C",
                fontFamily: "Open Sans-bold, sans-serif",
                fontWeight: "bold",
                fontSize: "27px",
                marginBottom: "15px",

                "@media(max-width:768px)": {
                    fontSize: "1.3rem"
                }
            },

            "& p": {
                color: "#043B5C",
                fontFamily: "Open Sans, sans-serif",
                fontSize: "0.9rem",
                lineHeight: "24px",

                "@media(max-width:768px)": {
                    fontSize: "0.9rem"
                }
            }
        }
    },

    content: {
        width: "100%",
        padding: "20px",

        "@media(min-width:968px)": {
            width: "85%"
        },

        "@media(max-width:999px)": {
            backgroundColor: "#fff",
            borderRadius: "10px"
        },

        "& h6": {
            color: "#043B5C",
            fontFamily: "Open Sans, sans-serif",
            fontSize: ".9rem",

            "@media(max-width:768px)": {
                fontSize: "0.9rem"
            }
        },

        "& .btn": {
            marginTop: "40px",
            backgroundColor: "#4786FF",
            color: "#E8F0F9",
            textTransform: "none",
            borderRadius: "5px",
            color: "#F4F5F7",
            border: "1px solid #4786FF",
            padding: "10px 30px",
            fontSize: "12px",
            fontFamily: "Open Sans, sans-serif",
            fontFamily: "Open Sans-Semibold, sans-serif",

            "@media(max-width:768px)": {
                width: "100%"
            }
        },

        "& .content-container": {
            display: "grid",
            gridTemplateColumns: "repeat( auto-fit, minmax(200px, 1fr) )",
            gridGap: "20px 50px",
            marginTop: "40px",

            "& .rolesBox": {
                display: "flex",
                alignItems: "center",

                borderRadius: "5px",
                padding: "6px 20px",

                "& label": {
                    fontFamily: "Open Sans-Semibold",
                    color: "#043B5C",
                    margin: "0px",
                    fontSize: "0.9rem"
                }
            }
        }
    },

    btn: {},

    btn_upload: {
        background: "#4786FF",
        borderRadius: "5px",
        color: "#fff",
        border: "1px solid #4786FF",
        padding: "10px 30px",
        fontSize: "11px",
        fontFamily: "Open Sans-Semibold, sans-serif",

        "&:hover": {
            background: "#A61106",
            border: "1px solid #A61106"
        }
    },
    btn_save: {
        background: "#4786FF",
        borderRadius: "5px",
        color: "#F4F5F7",
        border: "1px solid #4786FF",
        padding: "10px 30px",
        fontSize: "12px",
        fontFamily: "Open Sans-Semibold, sans-serif",
        width: "145px",

        [theme.breakpoints.down("md")]: {
            width: "100%"
        }
    },

    inputBox: {
        [theme.breakpoints.down("md")]: {
            marginBottom: "20px"
        },
        "& label": {
            display: "block",
            color: "#043B5C7F",
            fontSize: "13px",
            fontFamily: "Open Sans-bold, sans-serif",
            fontWeight: "bold",
            textTransform: "uppercase",
            marginBottom: "15px",
            [theme.breakpoints.down("md")]: {
                marginBottom: "10px"
            }
        },

        "& input": {
            background: "#FFFFFF",
            border: "1px solid #043B5C54",
            borderRadius: "5px",
            // width: "300px",
            padding: "10px 10px",

            [theme.breakpoints.down("md")]: {
                width: "100% !important"
            }
        }
    },

    forms: {
        display: "flex",
        justifyContent: "space-between",
        // flexWrap: "wrap",
        marginBottom: "40px",

        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            marginBottom: "20px",
            flexWrap: "wrap"
        }
    }
}));
