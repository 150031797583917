import { styled } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";

export default function AssociationSidebar() {
  return (
    <Container>
      <NavsContainer>
        <NavItem exact to={"/association/dashboard"}>
          Dashboard
        </NavItem>
        <NavItem to={"/association/summary"}>Summary</NavItem>
        <NavItem to={"/association/members"}>Members</NavItem>
        <NavItem to={"/association/manage-roles"}>Manage Roles</NavItem>
        <NavItem to={"/association/financial"}>Financial</NavItem>
      </NavsContainer>
    </Container>
  );
}

const Container = styled("div")`
  height: 100vh;
  width: 300px;
  padding: 15px;
  padding-left: 40px;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: linear-gradient(
    47.51deg,
    #052137 0%,
    rgba(13, 46, 72, 0.88) 53.57%,
    rgba(5, 33, 55, 0.78) 99.68%,
    rgba(7, 36, 59, 0.827247) 100%
  );
  margin-left: -20px;
`;

const NavsContainer = styled("nav")`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;
  margin-top: 30px;
  color: white;
  text-transform: uppercase;
`;

const NavItem = styled(NavLink)`
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  font-family: Open Sans-Semibold, sans-serif;

  &.active {
    color: #043b5c;
    background-color: #ffff;
    color: #10609f;
    border-radius: 8px 0 0 8px;
    width: 121%;
    padding: 10px 0 10px 15px;
    margin-left: -15px;
  }
`;
