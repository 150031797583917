import React, { useEffect, useMemo, useState } from "react";
import { Grid } from "@material-ui/core";
import Plan from "../Plans/Plan";
import { walletServiceApi } from "#/apis/izyPayApi";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Modal from "#/shared/components/Modal/Modal";
import { _formatCurrency } from "#/lib/validation";
import { activateIzyInvest, getIzyPlan } from "#/store/actions/izyinvest.actions";
import { ValidateNuban } from "#/store/actions/izypay.actions";

//Had to use this hardcoded value as the fetch plans API pperforms unstable
const plans = [
  {
    id: "1a844e49-6e38-4c5e-a785-aa055add6770",
    plan: "PREMIUM",
    description: null,
    monthly_interest: 2.75,
    min_duration: 120,
    min_init_deposit: 50000,
    min_recur_deposit: 20000,
    active: 1
  },
  {
    id: "2bb01f68-1c13-460a-94e9-bc713c861514",
    plan: "FreshFoodMarket Invest",
    description: null,
    monthly_interest: 4.2,
    min_duration: 270,
    min_init_deposit: 250000,
    min_recur_deposit: 100000,
    active: 1
  },
  {
    id: "66402f4e-030a-4eb4-bfbd-8567abe46b52",
    plan: "GOLD",
    description: null,
    monthly_interest: 2,
    min_duration: 90,
    min_init_deposit: 30000,
    min_recur_deposit: 10000,
    active: 1
  },
  {
    id: "8288fc5b-baa1-4d43-a11b-61becc86001e",
    plan: "PLATINUM_PLUS",
    description: null,
    monthly_interest: 3.75,
    min_duration: 210,
    min_init_deposit: 150000,
    min_recur_deposit: 50000,
    active: 1
  }
];

const RightCol = () => {
  const [active, setActive] = useState("PREMIUM");
  const [days, setDays] = useState(120);
  const [modalState, setModalState] = useState(null);
  const [error, setError] = useState("");
  const [investPlans, setInvestPlans] = useState([]);
  const [bankDetails, setBankDetails] = useState({
    bank: {
      value: null,
      validation: true,
      error: ""
    },
    accountNumber: {
      value: "",
      validation: true,
      error: ""
    }
  });
  // const [accountName, setAccountName] = useState("");
  const [title, setTitle] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [payoutDate, setPayoutDate] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [resultDate, setResultDate] = useState(null);
  // useEffect(() => {},[open])

  let { loading, nubanDetails } = useSelector((store) => store.izypay);
  const store = useSelector((state) => state.paystack);
  const izypayStore = useSelector((store) => store.izypay);

  // const { register, watch, handleSubmit, setValue, errors, control, form } = useForm({});

  // const form = useForm();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm();
  const values = watch();

  const history = useHistory();

  const dispatch = useDispatch();

  const open = useMemo(() => modalState !== null, [modalState]);
  const toggleActive = (plan, days) => {
    if (open) return;
    setActive(plan);
    setDays(days);
  };

  // open modal
  const openModal = (newState) => {
    setModalState(newState);
    // setOpen(true);
  };

  // close Modal
  const closeModal = () => {
    setModalState(null);
    setFormErrors("");
    // setOpen(false);
  };

  const validateDate = (value) => {
    let date = Date.now();
    let requiredDays = days;
    const oneDay = 24 * 60 * 60 * 1000 * requiredDays;
    const selectedDay = new Date(value);
    const dDay = new Date(date + oneDay);
    if (selectedDay < dDay) {
      setError(`Payout Date Must be at least ${days} days ahead`);
    }
    if (selectedDay >= dDay || value === undefined) setError("");
  };

  const handleCalculate = (day) => {
    const currentDate = new Date();
    const newDate = new Date(currentDate.getTime() + day * 24 * 60 * 60 * 1000);
    //setResultDate(newDate.toISOString().slice(0, 10)); // Format: YYYY-MM-DD
    return newDate.toISOString().slice(0, 10);
  };

  const activatePlan = (data) => {
    if (!accountNumber || !bankCode || !payoutDate) {
      return setFormErrors({
        bankCode: !bankCode && "Bank is required",
        accountNumber: !accountNumber && "Account Number is required",
        payoutDate: !payoutDate && "Payout Date is required"
      });
    }

    dispatch(
      activateIzyInvest(
        {
          plan: modalState.plan,
          description: title,
          accountName,
          accountNumber,
          bank: bankCode,
          payoutDate
        },
        () => {
          history.push({ pathname: "/wallet", state: { openizyInvest: true } });
          // history.push("/wallet");
          closeModal();
        },
        (err) => {
          closeModal();
        }
      )
    );
  };

  // const handleInputChange = (e) => {
  //   e.preventDefault();
  //   const { value } = e.target;
  //   setValue(value);
  // };

  useEffect(() => {
    if (bankCode && accountNumber.length === 10) {
      // fetch name
      console.log({
        bankCode,
        accountNumber
      });
      dispatch(
        ValidateNuban(
          {
            bank_code: bankCode,
            account_number: accountNumber
          },
          () => {
            console.log("completed");
          }
        )
      );
    }
  }, [accountNumber, bankCode, accountName, dispatch]);

  //Get Plans From API
  useEffect(() => {
    // Fetch plans from API
    walletServiceApi()
      .get(`/user/invest/plans/list`)
      .then((response) => {
        const data = response.data;
        setInvestPlans(data); // Store data in state
        // localStorage.setItem("investPlans", JSON.stringify(data.data));
      })
      .catch((error) => {
        console.error("Error fetching data:");
        // Handle errors appropriately
      });
  }, []);

  useEffect(() => {
    if (izypayStore.nubanDetails && izypayStore.nubanDetails.accountName) {
      setAccountName(izypayStore.nubanDetails.accountName);
    }
  }, [izypayStore.nubanDetails]);

  return (
    <Grid container justify="center">
      <Grid
        item
        md={11}
        xs={12}
        style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <p className="tw-text-center md:tw-text-start tw-text-[#052137] tw-text-lg tw-font-semibold">
          Select any of the plans to start your fixed savings with izyInvest.
        </p>

        {investPlans?.data?.map((plan) => (
          <Plan
            isActive={active === plan.plan}
            onClick={toggleActive}
            key={plan.plan}
            plan={plan}
            index={plan.id}
            clicked={active}
            days={days}
            openModal={() => openModal(plan)}
            closeModal={closeModal}
            open={open}
          />
        ))}
      </Grid>

      {open && (
        <Modal
          buttonText="ACTIVATE"
          showBackButton
          backButtonText="BACK"
          backButtonAction={closeModal}
          asForm={true}
          formSubmit={handleSubmit(activatePlan)}
          width="892px"
          maxHeight="500px">
          <div className="tw-py-6">
            <p className="tw-text-center tw-text-2xl tw-font-bold tw-text-[#052137]">
              SET UP{" "}
              <span style={{ color: modalState.textColor }}>{modalState.plan}</span>
            </p>
            <div className="md:tw-bg-[#ffffff] md:tw-shadow-[0px_3.44px_22.36px_-7.74px_rgba(0,0,0,0.25)] tw-rounded-2xl tw-grid md:tw-grid-cols-4 tw-grid-cols-2 tw-gap-y-4 tw-gap-x-[21px] md:tw-gap-x-0 md:tw-gap-y-0 tw-items-center tw-justify-between tw-py-6 tw-px-0 md:tw-px-[37px] tw-mt-6">
              <div className="tw-flex tw-flex-col tw-py-6 tw-px-4 tw-rounded-[19px] tw-w-[153px] tw-gap-1 tw-bg-[#ffffff] md:tw-bg-transparent md:tw-shadow-none tw-shadow-[0px_4px_26px_-9px_rgba(0,0,0,0.25)]">
                <p className="tw-text-[#052137] tw-text-xs tw-font-semibold">
                  Monthly Interest
                </p>
                <p className="tw-text-[#25CC22] tw-font-bold tw-text-[10px]">
                  {modalState.monthly_interest}
                </p>
              </div>
              <div className="tw-flex tw-flex-col tw-py-6 tw-px-4 tw-rounded-[19px] tw-w-[153px] tw-gap-1 tw-bg-[#ffffff] md:tw-bg-transparent md:tw-shadow-none tw-shadow-[0px_4px_26px_-9px_rgba(0,0,0,0.25)]">
                <p className="tw-text-[#052137] tw-text-xs tw-font-semibold">
                  First Deposit
                </p>
                <p className="tw-text-[#052137] tw-font-bold tw-text-[10px]">
                  Not lower than {_formatCurrency(modalState.min_init_deposit)}
                </p>
              </div>
              <div className="tw-flex tw-flex-col tw-py-6 tw-px-4 tw-rounded-[19px] tw-w-[153px] tw-gap-1 tw-bg-[#ffffff] md:tw-bg-transparent md:tw-shadow-none tw-shadow-[0px_4px_26px_-9px_rgba(0,0,0,0.25)]">
                <p className="tw-text-[#052137] tw-text-xs tw-font-semibold">
                  Recurring Deposit
                </p>
                <p className="tw-text-[#052137] tw-font-bold tw-text-[10px]">
                  Not lower than {_formatCurrency(modalState.min_recur_deposit)}
                </p>
              </div>
              <div className="tw-flex tw-flex-col tw-py-6 tw-px-4 tw-rounded-[19px] tw-w-[153px] tw-gap-1 tw-bg-[#ffffff] md:tw-bg-transparent md:tw-shadow-none tw-shadow-[0px_4px_26px_-9px_rgba(0,0,0,0.25)]">
                <p className="tw-text-[#052137] tw-text-xs tw-font-semibold">
                  Fixed Tenure
                </p>
                <p className="tw-text-[#052137] tw-font-bold tw-text-[10px]">
                  Not Before {handleCalculate(modalState.min_duration)}
                </p>
              </div>
            </div>
            <div className="tw-grid tw-items-center tw-justify-center tw-mt-8 md:tw-mt-7">
              <label className="tw-text-[#052137b3] md:tw-text-lg tw-text-xs tw-font-semibold md:tw-font-normal md:tw-text-center tw-text-start">
                Plan Title
              </label>
              <input
                type="text"
                placeholder="Plan Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className={`tw-w-[319px] tw-bg-gradient-to-l tw-from-[rgba(0,0,0,0.04)] tw-to-[rgba(227,225,225,0.08)] md:tw-h-[70px] tw-h-[60px] tw-rounded-[12px] tw-border-[#DCDDDD] tw-border-[2px] tw-border-solid tw-text-[#052137b3] tw-pl-6 tw-outline-none tw-shadow-[0px_4px_24px_-1px_#FFF] focus:tw-border-[#10609f]`}
              />
            </div>
            <div className="tw-mt-[40px]">
              <p className="tw-text-center tw-text-[#000000] tw-text-base md:tw-text-2xl tw-font-semibold">
                Payout Details
              </p>

              <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-x-16">
                <div className="tw-grid tw-mt-8 md:tw-mt-7">
                  <label className="tw-text-[#052137b3] md:tw-text-lg tw-text-xs tw-font-semibold md:tw-font-normal">
                    Bank Name
                  </label>
                  <div
                    className={`tw-rounded-[12px] tw-px-6 tw-flex tw-items-center tw-shadow-[0px_4px_24px_-1px_#FFF] md:tw-h-[70px] tw-h-[60px] tw-border-[#DCDDDD] ${
                      formErrors.bankCode && "tw-border-[red]"
                    } tw-border-[2px] tw-border-solid tw-w-full md:tw-w-[319px] tw-bg-gradient-to-l tw-from-[rgba(0,0,0,0.04)] tw-to-[rgba(227,225,225,0.08)]`}>
                    <select
                      type="text"
                      placeholder="Select your bank"
                      defaultValue={"Select your bank"}
                      value={bankCode}
                      onChange={(e) => setBankCode(e.target.value)}
                      className="tw-text-[#052137b3] tw-bg-inherit tw-h-full tw-w-full tw-outline-none">
                      <option value="">Select a bank</option>
                      {store.banks.map((el) => (
                        <option value={el.bankId}>{el.bankName}</option>
                      ))}
                    </select>
                  </div>
                  {formErrors.bankCode && (
                    <span className="tw-text-[red] tw-text-[12px]">
                      {formErrors.bankCode}
                    </span>
                  )}
                </div>

                <div className="tw-grid tw-mt-8 md:tw-mt-7">
                  <label className="tw-text-[#052137b3] md:tw-text-lg tw-text-xs tw-font-semibold md:tw-font-normal">
                    Account Number
                  </label>
                  <input
                    maxLength={10}
                    type="tel"
                    placeholder="Account Number"
                    value={accountNumber}
                    onChange={(e) => setAccountNumber(e.target.value)}
                    onBlur={(e) => {
                      const value = e.target.value;

                      setFormErrors((prev) => ({
                        ...prev,
                        accountNumber:
                          value.length < 10 ? "Account Number must be 10 digits" : ""
                      }));
                    }}
                    className={`md:tw-w-[319px] tw-w-full tw-bg-gradient-to-l focus:tw-border-[#10609f] tw-from-[rgba(0,0,0,0.04)] tw-to-[rgba(227,225,225,0.08)] md:tw-h-[70px] tw-h-[60px] tw-rounded-[12px] tw-border-[#DCDDDD] ${
                      formErrors.accountNumber && "tw-border-[red]"
                    } tw-border-[2px] tw-border-solid tw-text-[#052137b3] tw-pl-6 tw-outline-none tw-shadow-[0px_4px_24px_-1px_#FFF]`}
                  />
                  {formErrors.accountNumber && (
                    <span className="tw-text-[red] tw-text-[12px]">
                      {formErrors.accountNumber}
                    </span>
                  )}
                </div>

                <div className="tw-grid tw-mt-8 md:tw-mt-7">
                  <label className="tw-text-[#052137b3] md:tw-text-lg tw-text-xs tw-font-semibold md:tw-font-normal">
                    Account Name
                  </label>
                  <input
                    type="text"
                    placeholder="Account Name"
                    value={izypayStore?.nubanDetails?.accountName}
                    onChange={(e) => setAccountName(e.target.value)}
                    className={`md:tw-w-[319px] tw-w-full tw-bg-gradient-to-l focus:tw-border-[#10609f] tw-from-[rgba(0,0,0,0.04)] tw-to-[rgba(227,225,225,0.08)] md:tw-h-[70px] tw-h-[60px] tw-rounded-[12px] tw-border-[#DCDDDD] ${
                      formErrors.accountName && "tw-border-[red]"
                    } tw-border-[2px] tw-border-solid tw-text-[#052137b3] tw-pl-6 tw-outline-none tw-shadow-[0px_4px_24px_-1px_#FFF]`}
                  />
                  {formErrors.accountName && (
                    <span className="tw-text-[red] tw-text-[12px]">
                      {formErrors.accountName}
                    </span>
                  )}
                  {izypayStore.loading && (
                    <span className="tw-text-[gray] tw-text-[12px]">
                      Fetching account name
                    </span>
                  )}
                </div>

                <div className="tw-grid tw-mt-8 md:tw-mt-7">
                  <label className="tw-text-[#052137b3] md:tw-text-lg tw-text-xs tw-font-semibold md:tw-font-normal">
                    Payout Date
                  </label>
                  <div
                    className={`tw-rounded-[12px] tw-px-6 tw-flex tw-items-center tw-shadow-[0px_4px_24px_-1px_#FFF] md:tw-h-[70px] tw-h-[60px] tw-border-[#DCDDDD] ${
                      formErrors.payoutDate && "tw-border-[red]"
                    } tw-border-[2px] tw-border-solid tw-w-full md:tw-w-[319px] tw-bg-gradient-to-l tw-from-[rgba(0,0,0,0.04)] tw-to-[rgba(227,225,225,0.08)]`}>
                    <input
                      type="date"
                      placeholder="Payout Date"
                      value={payoutDate}
                      onChange={(e) => setPayoutDate(e.target.value)}
                      className="tw-text-[#052137b3] tw-h-full tw-bg-inherit tw-w-full tw-outline-none"
                      disabled={loading}
                    />
                  </div>
                  {formErrors.payoutDate && (
                    <span className="tw-text-[red] tw-text-[12px]">
                      {formErrors.payoutDate}
                    </span>
                  )}
                  {error && <span className="tw-text-[red] tw-text-[12px]">{error}</span>}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </Grid>
  );
};
export default RightCol;
