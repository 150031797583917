const REQUIRED = "This field is Required";
const MOBILE_LENGTH_ERRORMSG =
  "Invalid Phone Number Format. e.g +2348012345678, 07012345678";

export default {
  formId: "bussinessInfoForm",
  formField: {
    // logo: {
    //     name: "logo",
    //     label: "Business Logo*",
    //     requiredErrorMsg: "Image is required",
    //     acceptedFileTypes: ["image/jpg", "image/png", "image/jpeg"],
    //     invalidFiletypeErrorMsg: "Unsupported format. File should be .jpg or .png or .jpeg"
    // },
    businessName: {
      name: "businessName",
      label: "Business Name*",
      requiredErrorMsg: "Business Name is required",
      placeHolder: "Business Name"
    },
    businessPhone: {
      name: "businessPhone",
      label: "Business Mobile Number",
      requiredErrorMsg: "Business Phone Number is required",
      invalidLengthErrorMsg: MOBILE_LENGTH_ERRORMSG,
      placeHolder: "Contact Number",
      regexTestError: "Mobile Number should be 11 digits"
    },
    companyEmail: {
      name: "companyEmail",
      label: "Business Email Address",
      requiredErrorMsg: "Business Email Address is required",
      invalidErrorMsg: "Email Address is not valid. e.g example@email.com",
      placeHolder: "Business Email Address"
    },
    state: {
      name: "state",
      label: "State of Origin",
      requiredErrorMsg: "State of Origin  is required",
      placeHolder: "State"
    },
    lga: {
      name: "lga",
      label: "Local Government Area",
      requiredErrorMsg: "Local Government Area is required",
      placeHolder: "Local Government Area"
    },
    businessRegistrationAddress: {
      name: "businessRegistrationAddress",
      label: "Business Registration Address",
      requiredErrorMsg: "Business Registration Address is required",
      placeHolder: "Business Registration Address"
    },
    businessRegistrationNumber: {
      name: "businessRegistrationNumber",
      label: "Business Registration Number",
      requiredErrorMsg: "Business Registration Number is required",
      placeHolder: "Business Registration Number"
    }
    // contactName: {
    //     name: "contactName",
    //     label: "Contact Name*",
    //     requiredErrorMsg: "Contact Name is required",
    //     placeHolder: "Contact Name"
    // },
    // contactNumber: {
    //     name: "contactNumber",
    //     label: "Contact Number*",
    //     requiredErrorMsg: "Contact Number is required",
    //     invalidLengthErrorMsg: MOBILE_LENGTH_ERRORMSG,
    //     placeHolder: "Contact Number",
    //     regexTestError: "Mobile Number should be 11 digits"
    // },
    // alternatecontactName: {
    //     name: "alternatecontactName",
    //     label: "Alternate Contact Name",
    //     placeHolder: "Alternate Contact Name"
    // },
    // alternatecontactNumber: {
    //     name: "alternatecontactNumber",
    //     label: "Alternate Contact Number",
    //     invalidLengthErrorMsg: MOBILE_LENGTH_ERRORMSG,
    //     placeHolder: "Alternate Contact Number",
    //     regexTestError: "Mobile Number should be 11 digits"
    // }
  }
};
