import React from "react";
import { CircularProgress, Button } from "@material-ui/core";

const ScheduleBox = ({
    apartment_id,
    apartmentSchedules,
    bookingInspection,
    successfullyBooked,
    classes,
    handleBookDate,
    handleRedirectToDashboard,
    booked
}) => {
    const schedules = Object.keys(apartmentSchedules)
        .filter((item) => apartmentSchedules[item].length)
        .map((item) => {
            return { day: item, time: apartmentSchedules[item] };
        });
    const availableSchedules = booked
        ? schedules.filter((item) => {
              return item.day !== booked.day;
          })
        : schedules;

    if (!bookingInspection & !successfullyBooked) {
        return (
            <div className={classes.availableTimeSlots}>
                {booked && (
                    <>
                        <h1>Current booking</h1>
                        <ul className="available-days">
                            <li>
                                <h4>{booked.day}</h4>
                                <h4>{booked.time}</h4>
                            </li>
                        </ul>
                    </>
                )}
                <h1>Pick a {booked ? "new " : ""}time slot</h1>
                <ul className="available-days">
                    {availableSchedules.length == 0 ? (
                        <li className="no-schedule-li" style={{ display: "block" }}>
                            <h4>No other time slots available</h4>
                            <br />
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => handleBookDate({ apartment_id }, booked)}
                                style={{ textTransform: "capitalize" }}>
                                Book Inspection
                            </Button>
                        </li>
                    ) : (
                        availableSchedules.map((item, idx) => {
                            const { day, time } = item;

                            return (
                                <li key={idx} onClick={() => handleBookDate(item, booked)}>
                                    <h4>{day}</h4>
                                    <h4>{time[0]}</h4>
                                </li>
                            );
                        })
                    )}
                </ul>
            </div>
        );
    } else if (bookingInspection & !successfullyBooked) {
        return (
            <div className={classes.bookedInspection}>
                <h1>Pick a time slot</h1>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        flex: "1",
                        width: "50%",
                        margin: "auto"
                    }}>
                    <CircularProgress size={30} className="icon" color="inherit" />
                </div>
            </div>
        );
    } else if (successfullyBooked) {
        return (
            <div className={classes.bookedInspection}>
                <h1>Booked Inspection</h1>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        flex: "1",
                        width: "50%",
                        margin: "auto"
                    }}>
                    <h2>Great !!!</h2>
                    <h3>a message has been sent to property manager</h3>
                    <button onClick={handleRedirectToDashboard}>Dashboard</button>
                </div>
            </div>
        );
    }
};

export default ScheduleBox;
