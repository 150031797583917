import React from "react";
import BankDetailTile from "./BankDetailTile";
import { Grid } from "@mui/material";

export default function BankAccountList({ bankAccountList, role, setRoleState }) {
  return (
    <Grid container columnSpacing={1}>
      {bankAccountList.map((bankDetail, idx) => (
        <Grid item xs={12} sm={6} key={idx}>
          <BankDetailTile
            bankDetail={bankDetail}
            role={role}
            setRoleState={setRoleState}
          />
        </Grid>
      ))}
    </Grid>
  );
}
