import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./Izyvest.scss";
import { Grid } from "@material-ui/core";
import RightCol from "./RightCol/rightCol";
import LeftCol from "./LeftCol/leftCol.jsx";

// import LeftCol from "./LeftCol/leftCol.jsx";
import Container from "./../../components/layout/container";
import CustomButton from "#/components/ui-components/button.component";
import { useStyles } from "../Wallet/leftCol/style";

const layoutStyle = makeStyles((theme) => ({
  margin: {
    paddingBottom: "5%",
    display: "flex",
    gap: "150px",
    [theme.breakpoints.down("xs")]: {
      width: "24rem"
    }
  },
  rightContainer: {
    marginTop: "100px",
    [theme.breakpoints.only("sm")]: {
      marginLeft: "7%",
      marginTop: "0px"
    }
  }
}));

const IzyVest = (props) => {
  const [step, setStep] = useState(1);
  const { history } = props;
  const classes = layoutStyle();
  // const classesM = useStyles();

  // const goizyPay = () => {
  //   props.history.push("/wallet");
  // };
  // const goizyInvest = () => {
  //   props.history.push({ pathname: "/wallet", state: { openizyInvest: true } });
  // };
  return (
    <>
      {/* <Grid item className={classes.btnmargin}>
        <button className={classesM.izyPayBtn} onClick={goizyPay}>
         
          izyPay
        </button>
        <button className={classesM.izyVestBtn} onClick={goizyInvest}>
          
          izyInvest
        </button>
      </Grid> */}
      <section className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2">
        {step === 1 ? (
          <div>
            <LeftCol history={history} />
          </div>
        ) : (
          <div className="tw-mt-0 md:tw-mt-28 md:tw-hidden tw-block">
            <RightCol />
          </div>
        )}

        <div className="tw-flex tw-gap-2 md:tw-gap-0 md:tw-hidden tw-items-center tw-justify-center">
          {step !== 1 && (
            <CustomButton
              style={{
                color: "#10609F",
                backgroundColor: "#ffffff",
                padding: "14px 47px",
                border: "3px solid #10609F",
                boxShadow: "0px 4px 21px -6px rgba(16, 96, 159, 0.25)",
                borderRadius: "8px"
              }}
              onClick={() => setStep(1)}
              buttonText="Back"
            />
          )}
          <CustomButton
            style={{
              color: "white",
              backgroundColor: "#10609F",
              padding: "17px 47px",
              boxShadow: "0px 4px 21px -6px rgba(16, 96, 159, 0.25)",
              borderRadius: "8px"
            }}
            onClick={() => setStep(2)}
            buttonText="Next"
          />
        </div>

        <div className="tw-mt-0 md:tw-mt-28 tw-hidden md:tw-block">
          <RightCol />
        </div>
      </section>
    </>
    // <Grid
    //   container
    //   item
    //   md={12}
    //   sm={12}
    //   xs={12}
    //   // style={{ gap: "150px" }}
    //   className={classes.margin}>
    //   <Grid container justifyContent="flex-end" item md={4} sm={4} xs={12}>
    //     <LeftCol history={history} />
    //   </Grid>
    //   <Grid item md sm={7} xs={12} className={classes.rightContainer}>
    //     <RightCol />
    //   </Grid>
    // </Grid>
  );
};

export default IzyVest;
