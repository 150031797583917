import React from "react";
import Notification from "../../assets/notification.svg";
import { useSelector } from "react-redux";
// Icons
import ClearAllIcon from "@material-ui/icons/ClearAll";
import { getFromLocalStorage } from "../utils/util";
import useMobile from "#/hooks/util-hooks/useMobile";
import useWindowSize from "react-use/lib/useWindowSize";
import { useLocation } from "react-router-dom";
import { logout } from "#/store/actions/auth.actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const Header = (props) => {
  const user = useSelector((state) => state.auth.user);
  const { isMobile } = useMobile();
  const windowSize = useWindowSize();
  const [headerName, setHeader] = React.useState("DASHBOARD");
  const { pathname } = useLocation();

  React.useEffect(() => {
    const headerName = getFromLocalStorage("active");
    if (headerName) {
      setHeader(headerName);
    }
  }, [pathname]);

  const { first_name } = user;

  const logout = () => {
    const { logout } = props;
    logout(true);
  };
  return (
    <div style={{}}>
      <div style={{ marginBottom: "50px" }}>
        <p>
          <img
            src="/assets/dashboard/toggle.svg"
            width="20px"
            id="sidebarCollapse"
            style={{
              fontSize: 40,
              color: "#043B5C",
              cursor: "pointer",
              zIndex: 99999,
              position: "absolute"
            }}
            onClick={() => props.clickToggle()}
          />
          <span style={{ marginLeft: "10px", position: "relative", left: "20px" }}>
            {headerName === null ? "DASHBOARD" : headerName}
          </span>
          {true && (
            <React.Fragment>
              {/* <span className="float-right" style={{ marginRight: "30px" }}>
                                {first_name}
                            </span> */}
              {/* windowSize.width > 768 */}
              <span onClick={() => logout()}>
                <img
                  src="/assets/dashboard/logout.svg"
                  width="20px"
                  className="float-right"
                  alt="notification svg"
                  style={{ marginRight: "10px", cursor: "pointer" }}
                />
              </span>
              <img
                src="/assets/dashboard/notify.svg"
                width="20px"
                className="float-right"
                alt="notification svg"
                style={{ marginRight: "30px" }}
              />
            </React.Fragment>
          )}
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  logout2: state.auth.logout,
  user: state.auth.user,
  role: state.auth.role
});
export default connect(mapStateToProps, { logout })(withRouter(Header));
