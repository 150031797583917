import React, { useState } from "react";
import { Button, Grid } from "@material-ui/core";
import avatar from "#/assets/images/tenant/default-avatar.png";
import CustomButton from "#/components/ui-components/button.component";
import { Styles } from "./style";
import letterbomb from "#/assets/settingsAssest/letterbomb.svg";
import setting from "#/assets/settingsAssest/settings.svg";
import Subscription from "./components/subscription.component";
import Setting from "./components/setting.component";

const RentReminder = () => {
    const [current, setCurrent] = useState("subscription");
    const classes = Styles();

    const showSettings = (e) => {
        setCurrent("settings");
    };
    const showSubscription = (e) => {
        setCurrent("subscription");
    };
    return (
        <div className={classes.root}>
            <section className={classes.header}>
                <div className="header__body">
                    <div className="header__body__left">
                        <p>Only pay for what you need</p>
                    </div>
                    <div className="header__body__right">
                        <img src={letterbomb} alt="tenants" />
                    </div>
                </div>
            </section>
            <>
                {current === "subscription" ? (
                    <Subscription showSettings={showSettings} />
                ) : (
                    <Setting showSubscription={showSubscription} />
                )}
            </>
        </div>
    );
};

export default RentReminder;
