export const copyTextToClipboard = (text, cb) => {
  if (!text) return;
  navigator.clipboard.writeText(text);
  cb();
};

export function toCalenderDate(rawDate) {
  let date = new Date(rawDate);
  return date.getFullYear() + "-" + parseInt(date.getMonth() + 1) + "-" + date.getDate();
}

export function renderDate(date) {
  const newDate = new Date(date);

  const options = { weekday: "long", month: "long", day: "numeric" };
  const formattedDate = newDate.toLocaleDateString("en-US", options);

  return formattedDate;
}

export function renderDate2(isoDateString) {
  function getOrdinalSuffix(day) {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  const date = new Date(isoDateString);

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  const dayOfWeek = daysOfWeek[date.getUTCDay()];
  const dayOfMonth = date.getUTCDate();
  const month = months[date.getUTCMonth()];
  const year = date.getFullYear();

  const ordinalSuffix = getOrdinalSuffix(dayOfMonth);
  const formattedDate = `${dayOfWeek}, ${dayOfMonth}${ordinalSuffix} ${month} ${year}`;

  return formattedDate;
}

export const formatPrice = (price) => {
  const numericPrice = parseFloat(price);

  if (price === null) {
    return "₦0.00";
  }

  if (!isNaN(numericPrice)) {
    const formattedPrice = new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN"
    }).format(numericPrice);

    return formattedPrice;
  }
  return price;
};
