import { Grid, styled } from "@mui/material";
import React from "react";
import "./style.css";

const Container = styled("div")`
  font-weight: 500;
  /* margin-bottom: 13px; */
  /* margin-right: 15px; */
  overflow-wrap: break-word;
  /* width: 160px; */
  /* background: red;
    border: 1px solid black; */

  /* @media (max-width: 600px) {
        width: 145px;
    } */
`;

const Subtitle = styled("p")`
  margin-bottom: 2px;
  color: #052137b2;
  font-size: 14px;

  @media (max-width: 600px) {
    font-size: 13px;
  }
`;

const SubtitleAndSubtext = ({
  loading,
  subtitle,
  subtext,
  style,
  xs = 6,
  sm = 4,
  lg = 3
}) => {
  return (
    <Grid item xs={xs} sm={sm} lg={lg}>
      <Container style={style}>
        <Subtitle>{subtitle}</Subtitle>
        {loading ? (
          <div className="skeleton skeleton-text"></div>
        ) : (
          <small className={loading ? "skeleton-text" : ""}>
            {subtext && subtext !== "₦NaN" ? subtext : <em>Nil</em>}
          </small>
        )}
      </Container>
    </Grid>
  );
};

export default SubtitleAndSubtext;
