import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button, Typography, Box, CircularProgress } from "@material-ui/core";
import { useStyles, BorderLinearProgress } from "./style";
import wallet from "../../../assets/images/wallet (1).svg";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../components/ui-components/button.component";
import GenModal from "../fund-wallet/GenModal";
import { TranfserForm } from "./modals/TransferForm";
import money from "#/assets/images/wallet/money (2).svg";
import { alertClear, showNotifications } from "#/store/actions/notification.actions";
import { ErrorDiv } from "#/components/errorDiv";
import { toCurrency } from "#/components/utils/util";
import FundWalletForm from "./modals/fundWallet";
import FundIzyInvestForm from "./modals/fundIzyInvestForm";
import ActivateBVN from "./modals/activateBvn";

export const IzyWallet = (props) => {
  const { loading } = useSelector((store) => store.izypay);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    balance,
    izyinvest,
    doShowizyInvest,
    showIzyinvest,
    hideIzyvest,
    closeSettings,
    openSettings,
    currentP,
    isVerified,
    windowLocataion,
    virtualAccount
  } = props;

  const hasVirtualAccount = () => virtualAccount?.account_number;

  const [state, setState] = useState({
    // open: false,
    submitting: false,
    openFund: false,
    izyinvestform: false,
    activateBvn: false,
    error: ""
    // showIzyinvest: false,
  });
  const [open, setOpen] = useState(false);

  // Show Transfer Modal
  const showModal = () => {
    if (!isVerified) {
      // setState({ error: "EMAIL NOT VERIFIED" });
      dispatch(showNotifications("Please Verify Your Email", "error"));
    } else {
      if (showIzyinvest) {
        openSettings();
      } else setOpen(true);
    }
  };
  const hideModal = () => {
    dispatch(alertClear());

    if (showIzyinvest) {
      closeSettings();
    } else setOpen(false);
  };

  // Show Fund wallet Modal
  const showFundModal = () => {
    if (!isVerified) {
      dispatch(showNotifications("Please Verify Your Email", "error"));
      // setState({ error: "EMAIL NOT VERIFIED" });
      return;
    } else {
      if (showIzyinvest) {
        openIzyinvest();
      } else setState({ openFund: true });
    }
  };

  // Show Activate BVN Modal
  const showBvnModal = () => {
    if (!isVerified) {
      dispatch(showNotifications("Please Verify Your Email", "error"));
    } else {
      if (showIzyinvest) {
        openIzyinvest();
      } else setState({ activateBvn: true });
    }
  };
  const hideFundModal = () => {
    setState({ openFund: false });
  };

  const closeBVNModal = () => {
    setState({ activateBvn: false });
  };

  const openIzyinvest = () => {
    setState({ izyinvestform: true });
  };
  const hideIzyinvest = () => {
    setState({ izyinvestform: false });
  };

  let displayData = {
    genBalance: balance,
    text: "izyPay",
    displayImg: wallet,
    fundBtnTxt: "FUND WALLET",
    whiteBtn: "TRANSFER",
    activateTxt: "ACTIVATE VIRTUAL ACCOUNT",
    izyinvestBtn: classes.izyVest,
    izyPayBtn: classes.izyPay
  };

  if (showIzyinvest) {
    displayData.genBalance = izyinvest;
    displayData.text = "izyInvest";
    displayData.displayImg = money;
    displayData.fundBtnTxt = "Fund izyInvest";
    displayData.whiteBtn = "SETTINGS";
    displayData.izyinvestBtn = classes.izyVestBtn;
    displayData.izyPayBtn = classes.izyPayBtn;
  }
  if (displayData.genBalance === undefined) {
    displayData.genBalance = "0";
  }

  return (
    <Grid container justify="center">
      <Grid item md={9} xs={11} className={classes.containerBorder}>
        {/* BUTTONS */}
        {/* <Grid item>
          <button
            variant="outlined"
            className={displayData.izyPayBtn}
            onClick={hideIzyvest}>
            izyPay
          </button>
          <button
            variant="outlined"
            className={displayData.izyinvestBtn}
            onClick={doShowizyInvest}>
            izyInvest
          </button>
        </Grid> */}

        {/* MODALS */}
        <GenModal open={open} title="Transfer">
          <TranfserForm handleClose={hideModal} />
        </GenModal>
        <GenModal open={state.openFund} title="Fund Wallet">
          <FundWalletForm handleClose={hideFundModal} windowLocataion={windowLocataion} />
        </GenModal>
        <GenModal
          open={state.izyinvestform}
          title="Fund izyInvest"
          handleClose={hideModal}>
          <FundIzyInvestForm handleClose={hideIzyinvest} planId={currentP} />
        </GenModal>
        <GenModal
          open={state.activateBvn}
          title="Virtual Bank Details Activation"
          handleClose={hideModal}>
          <ActivateBVN handleClose={closeBVNModal} />
        </GenModal>

        {/* content container */}
        {!showIzyinvest && (
          <Grid item className={classes.izy}>
            <div className={classes.flex}>
              <img
                src={displayData.displayImg}
                alt="wallet"
                className={classes.walletImg}
              />
              <Box fontWeight="fontWeightBold">
                <Typography style={{ fontWeight: "bold", color: "#043B5C" }}>
                  &nbsp;{displayData.text}
                </Typography>
              </Box>
            </div>
            <div>
              <Box fontWeight="fontWeightBold" m={1}>
                <Typography
                  component="div"
                  variant="h5"
                  style={{ color: "#332A7C", fontWeight: "bold" }}>
                  {loading ? (
                    <CircularProgress size={16} color="inherit" />
                  ) : (
                    toCurrency(displayData.genBalance)
                  )}
                </Typography>
              </Box>

              <BorderLinearProgress variant="determinate" value={50} />
            </div>
            {displayData.text === "izyPay" && (
              <div className={classes.flex} style={{ marginTop: "5%" }}>
                <div>
                  <Typography
                    style={{
                      fontWeight: "600",
                      color: "#043B5C",
                      fontSize: "16px"
                    }}>
                    Virtual Account
                  </Typography>

                  {!loading && (
                    <div>
                      {hasVirtualAccount() ? (
                        <div className={classes.virtualAccount}>
                          <div className="sub-text">
                            <p>Acc.No : {virtualAccount?.account_number}</p>
                            <p>Bank : {virtualAccount?.bank_name}</p>
                          </div>
                        </div>
                      ) : (
                        <CustomButton
                          variant="outlined"
                          fullWidth
                          className={classes.fundBtn}
                          style={{ padding: "5px 16px", marginTop: "5px" }}
                          buttonText="Generate Virtual Account"
                          onClick={showBvnModal}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </Grid>
        )}

        {/* buttons */}
        <Grid item>
          {/* {showIzyinvest && (
            <>
              <CustomButton // this condition hides the button on the main wallet dashboard but shows it to fund an izyinvest plan
                variant="outlined"
                fullWidth
                className={classes.fundBtn}
                onClick={showFundModal}
                buttonText={displayData.fundBtnTxt}
                style={{ textTransform: "none" }}
              />

              <CustomButton
                variant="outlined"
                fullWidth
                className={classes.transferBtn}
                buttonText={displayData.whiteBtn}
                onClick={showModal}
              />
            </>
          )} */}
          {!showIzyinvest && (
            <CustomButton
              variant="outlined"
              fullWidth
              className={classes.transferBtn}
              buttonText={displayData.whiteBtn}
              onClick={showModal}
            />
          )}
        </Grid>
        {state.error !== "" && state.error !== undefined && (
          <ErrorDiv>EMAIL NOT VERIFIED</ErrorDiv>
        )}
      </Grid>
    </Grid>
  );
};
