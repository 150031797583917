import React, { useState, useEffect } from "react";

import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

import {
  useStepperStyles,
  useColorlibStepIconStyles,
  ColorlibConnector
} from "./stepper.styles";

import HomeIcon from "#/assets/propertyPage/home.svg";
import ApartmentIcon from "#/assets/propertyPage/apartment.svg";

const StepIcon = (props) => {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <img src={HomeIcon} alt="home" />,
    2: <img src={ApartmentIcon} alt="apartment" />,
    3: <img src={HomeIcon} alt="apartment" />
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}>
      {icons[String(props.icon)]}
    </div>
  );
};

const CustomStepper = ({ currentStep }) => {
  const classes = useStepperStyles();
  const [activeStep, setActiveStep] = useState(currentStep);

  useEffect(() => {
    setActiveStep(currentStep);
  }, [currentStep]);

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        <Step>
          <ColorlibConnector />
          <StepLabel StepIconComponent={StepIcon}>Create Property</StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepIcon}>Create Apartment</StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepIcon}>Create Apartment Bills</StepLabel>
        </Step>
      </Stepper>
    </div>
  );
};

export default CustomStepper;
