import React, { useState } from "react";
import "./secondcolumn.scss";
import Switch from "react-switch";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import UpdateBankDetails from "./UpdateBankDetails";
import { styled } from "@mui/material";

const Secondcolumn = (props) => {
  const [checked, setChecked] = useState(false);
  const [show, setShow] = useState(false);
  const bankList = useSelector((state) => state.paystack.banks);

  const izyInvestPlansList = props.izyinvest.izyInvestPlan;
  const plan = izyInvestPlansList.find((plan) => plan.id === props.izyinvest.newPlanId);
  const bankName = bankList.find(
    (bank) => parseInt(bank?.bankCode) === parseInt(plan?.bank_id)
  ).bankName;

  const handleChange = (checked) => {
    setChecked(checked);
  };
  return (
    <React.Fragment>
      <Grid className="content" md={12} sm={12}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}>
          <span onClick={props.close} style={{ cursor: "pointer", width: "40%" }}>
            <ArrowBackIcon
              style={{
                position: "relative",
                bottom: "3px",
                color: "#043B5C"
              }}
            />
          </span>
          <h6 className="text-settings-color" style={{ color: "#043B5C" }}>
            Settings
          </h6>
        </div>
        <hr className="hrrr" />
        <div className="row px">
          <div className="col-sm-12 col-lg-4">
            <h6 className="text-cap">Bank Details</h6>
          </div>
          <div className="col-sm-12 col-lg-8">
            <div>
              <h6 className="wallet-bank-text">{plan.account_number}</h6>
              <p className="wallet-bank-text">{plan.account_name}</p>
              <p className="wallet-bank-text">{bankName}</p>
            </div>
          </div>
          <div className="col-sm-12 col-lg-12">
            <div
              style={{ cursor: "pointer", fontWeight: "bold" }}
              onClick={() => setShow(!show)}>
              {!show ? (
                <h6 className="right">Edit</h6>
              ) : (
                <h6 className="right" style={{ color: "red" }}>
                  Cancel
                </h6>
              )}
            </div>
          </div>
        </div>
        <FormDiv open={show}>
          <UpdateBankDetails planId={plan.id} />
        </FormDiv>
        <hr />
        <div className="row px">
          <div className="col-sm-12 col-lg-4">
            <h6 className="text-cap">Payout Date</h6>
          </div>
          <div className="col-sm-12 col-lg-4">
            <h6 className="wallet-bank-text">{props.izyinvest.payoutDate}</h6>
          </div>
          {/* <div className="col-sm-12 col-lg-4">
              <h6 className="right">Edit</h6>
            </div> */}
        </div>
        <hr />
        <div className="row px">
          <h6 className="text-cap" style={{ paddingLeft: "15px" }}>
            Extend Plan
          </h6>
        </div>
        <div>
          <Switch
            onChange={handleChange}
            checked={checked}
            height={20}
            width={45}
            onColor="#86d3ff"
            handleDiameter={25}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            className="react-switch"
          />
        </div>
        <p className="p-textsize">Extend the plan till next rent payment date</p>
        <hr />
        <div className="save-btn">
          <button className="btn btn-save spacing">Save changes</button>
        </div>
      </Grid>
    </React.Fragment>
  );
};

export default Secondcolumn;

const FormDiv = styled("div")`
  border-top: ${(props) => (props.open ? "2px solid black" : 0)};
  overflow: hidden;
  transition: all 0.8s ease-in;
  max-height: ${(props) => (props.open ? "300px" : 0)};
`;
