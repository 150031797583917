import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        "& > * + *": {
            marginLeft: theme.spacing(2)
        },
        overflow: "hidden",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        maxWidth: "unset",
        backgroundColor: "black",
        opacity: 0.7,
        zIndex: 20
    }
}));

function PageLoader() {
    const classes = useStyles();

    return (
        <div className="page-loader">
            <div className={classes.root}>
                <CircularProgress />
            </div>
        </div>
    );
}

export default PageLoader;
