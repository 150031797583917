import React, { useState, useEffect } from "react";
import { Card } from "@mui/material";
import { propertyServiceApi } from "../../../apis/propertyServiceApi";
import { checkIfPaymentWindow } from "#/lib/dateHelpers";
import { CircularProgress } from "@material-ui/core";

const PayFeeCard = ({ fee, member, selected, handleOnChange }) => {
    const { title, amount } = fee;

    const [lastFeeRecord, setLastFeeRecord] = useState(null);
    const [userAmount, setUserAmount] = useState(0);
    const [loading, setLoading] = useState(false);

    const nextDate = new Date(lastFeeRecord?.next_date);
    const date = nextDate.getDate();
    const month = nextDate.getMonth() + 1;
    const year = nextDate.getFullYear();
    const paymentWindow = lastFeeRecord ? checkIfPaymentWindow(fee.payment_term, nextDate) : true;

    useEffect(() => {
        fetchFeeRecord();
    }, []);

    const fetchFeeRecord = () => {
        const query = { params: { member: member._id, fee: fee._id, limit: 1 } };
        setLoading(true);
        propertyServiceApi()
            .get("/estate-management/fee-record", query)
            .then((data) => {
                setLastFeeRecord(data.data.payload[0]);
            })
            .catch((err) => {})
            .finally(() => {
                setLoading(false);
            });
    };

    if (loading)
        return (
            <Card className="payable-details">
                <CircularProgress size={20} />
            </Card>
        );

    return (
        <Card className="payable-details">
            <input
                type="checkbox"
                id={`custom-checkbox-${title}`}
                name={title}
                checked={selected[fee._id]}
                onChange={handleOnChange(fee._id, userAmount || lastFeeRecord?.amount_left || fee.amount)}
                disabled={!paymentWindow && !lastFeeRecord?.amount_left}
            />
            <p>
                <span>{title}</span>
                {lastFeeRecord && <span>{`${date}/${month}/${year}`}</span>}
            </p>
            <p>
                <span>Amount</span>
                <span>&#x20A6;{lastFeeRecord?.amount_left || amount}</span>
            </p>
            <p>
                <span>Enter Amount</span>
                <input
                    type="text"
                    placeholder="3,500"
                    disabled={(!paymentWindow && !lastFeeRecord?.amount_left) || selected[fee._id]}
                    value={userAmount || selected[fee._id] || ""}
                    onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9]/gi, "");
                        if (Number(value) > (lastFeeRecord?.amount_left || fee.amount))
                            return setUserAmount(lastFeeRecord?.amount_left || fee.amount);
                        if (Number(value) === 0) return setUserAmount(0);
                        setUserAmount(Number(value));
                    }}
                />
            </p>
        </Card>
    );
};

export default PayFeeCard;
