import { SubHeader } from "#/shared/components";
import React, { useState } from "react";
import TenantProfileCard from "./TenantProfileCard";
import NoTenantCard from "./NoTenantCard";
import CustomBtn from "#/components/buttons/CustomBtn";
import { styled } from "@mui/material";
import AddTenantModal from "#/components/apartmentForm/subcomponents/occupied/AddTenantModal";
import RemoveTenantModal from "./RemoveTenantModal";

const ApartmentTenant = ({ tenantLid, apartmentId }) => {
  const [addTenantModal, setAddTenantModal] = useState(false);
  const [removeTenantModal, setRemoveTenantModal] = useState(false);

  const handleAddTenantModal = () => setAddTenantModal(!addTenantModal);
  const handleRemoveTenantModal = () => setRemoveTenantModal(!removeTenantModal);

  return (
    <>
      {addTenantModal ? (
        <AddTenantModal setShowTenantForm={setAddTenantModal} apartmentId={apartmentId} />
      ) : null}
      {removeTenantModal ? (
        <RemoveTenantModal
          tenantLid={tenantLid}
          handleRemoveTenantModal={handleRemoveTenantModal}
          apartmentId={apartmentId}
        />
      ) : null}

      <div style={{ display: "grid", gap: "1rem" }}>
        <SubHeader
          title={"Tenant"}
          component={
            <>
              {renderActionBtn(tenantLid, handleAddTenantModal, handleRemoveTenantModal)}
            </>
          }
        />
        {tenantLid ? (
          <TenantProfileCard
            tenantLid={tenantLid}
            handleRemoveTenantModal={handleRemoveTenantModal}
          />
        ) : (
          <>
            <NoTenantCard handleAddTenantModal={handleAddTenantModal} />
          </>
        )}
      </div>
    </>
  );
};

export default ApartmentTenant;

const ActionBtn = styled(CustomBtn)`
  @media (max-width: 600px) {
    display: none;
  }
`;

const renderActionBtn = (tenantLid, handleAddTenantModal, handleRemoveTenantModal) => {
  if (tenantLid) {
    return <ActionBtn onClick={handleRemoveTenantModal}>Remove Tenant</ActionBtn>;
  }
  return <ActionBtn onClick={handleAddTenantModal}>Add Tenant</ActionBtn>;
};
