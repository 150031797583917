import React, { useState } from "react";
import { payForApartmentCharges } from "#/store/actions/payments.action";
import PaymentComponent from "./components/PaymentComponent";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { returnUserData } from "#/apis/propertyServiceApi";

const PaymentPageCharges = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const itemsToPay = localStorage.getItem("PAY_CHARGES");
    const items = JSON.parse(itemsToPay);
    const total = items?.items?.map((item) => Number(item.price)).reduce((a, b) => a + b, 0);

    const [, setSelectedMethod] = React.useState(null);
    const [amount, setAmount] = useState(total || 0);

    if (!itemsToPay) {
        history.push("/dashboard");
        return null;
    }

    const izyPayChargesPay = () => {
        const user = returnUserData();
        const payload = {
            apartment_id: items.apartment_id,
            liveizy_id: user.liveizy_id,
            charges: items.items.map((item) => item._id)
        };
        dispatch(payForApartmentCharges(payload, history));
    };

    return (
        <PaymentComponent
            amountReadonly={true}
            spectaAvailable={false}
            fullAmount={total}
            amount={amount}
            setAmount={setAmount}
            items={items}
            paymentMethod={"izypay"}
            setPaymentMethod={setSelectedMethod}
            onSubmit={izyPayChargesPay}
            hideSelectMethod
        />
    );
};

export default PaymentPageCharges;
