import CustomBtn from "#/components/buttons/CustomBtn";
import PaperContainer from "#/dashboard/components/paperContainer/Index";
import { alertError, alertSuccess } from "#/store/actions/notification.actions";
import { styled } from "@mui/material";
import React, { useRef, useState } from "react";
import { BsFillCloudUploadFill, BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { postAgreementStatementDocumentToCloudinary } from "../utils";
import { propertyServiceApi } from "#/apis/propertyServiceApi";
import LoadingModal from "#/components/loadingModal/LoadingModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { APP_ROUTES } from "#/constants/routes";

const MonthlyRentAgreement = () => {
  const fileRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  let uploadedDocument = fileRef.current?.files[0] || null;
  const [documentPreview, setDocumentPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");

  const submitAgreement = async () => {
    if (!uploadedDocument) return dispatch(alertError("Please Upload Signed Agreement"));
    try {
      setLoading(true);
      setLoadingText("Uploading Agreement Document");
      const agreementDocLink = await postAgreementStatementDocumentToCloudinary(
        uploadedDocument
      );
      setLoadingText("Submitting Application");
      await propertyServiceApi().patch("/application/rent-apply-status-stage-2", {
        agreement_doc: agreementDocLink
      });
      setLoading(false);
      dispatch(alertSuccess("Agreement Submitted"));
      history.push(APP_ROUTES.dashboard.main);
    } catch (error) {
      setLoading(false);
      dispatch(
        alertError(
          error.response.data?.error ||
            error.response.data?.error?.message ||
            "An Error Occurred"
        )
      );
    }
  };
  const onChangeInput = () => {
    setDocumentPreview(URL.createObjectURL(fileRef.current.files[0]));
  };

  return (
    <>
      {loading ? <LoadingModal text={loadingText} /> : null}
      <Container className="position-center-column">
        <h3 style={{ fontWeight: "bold", textAlign: "center", fontSize: "20px" }}>
          Sign Agreement
        </h3>
        <br />
        <p style={{ color: "#b83b3b", textAlign: "center" }}>
          Kindly fill and sign the agreement documents below by downloading it, then
          upload after it has been signed.
        </p>

        {/* Agreement document download */}
        <AgreementContainer>
          <BsFillFileEarmarkPdfFill
            style={{
              color: "#d43e36",
              fontSize: "50px",
              margin: "0 auto"
            }}
          />
          <p>Agreement Doc.pdf</p>
          <DLink
            target={"_blank"}
            href="https://res.cloudinary.com/dfz1vgrgv/image/upload/v1666212822/company_docs/izyRent-Monthly_rent_financing_Agreement_Document_qvx1s6.pdf">
            View and Download
          </DLink>
        </AgreementContainer>

        {/* Upload Document */}
        <div>
          <input
            hidden
            ref={fileRef}
            name={"agreement"}
            accept=".pdf"
            type={"file"}
            onChange={onChangeInput}
          />
          <UploadButton
            type="button"
            variant="contained"
            onClick={() => {
              fileRef.current.click();
            }}
            color="secondary">
            <BsFillCloudUploadFill fontSize={"26px"} />
            <p style={{ fontSize: "13px", color: "black", marginTop: "10px" }}>
              Upload Signed Agreement Document (.pdf)
            </p>
          </UploadButton>

          {documentPreview ? (
            <div style={{ textAlign: "center" }}>
              <DLink href={documentPreview} target="_blank">
                {uploadedDocument.name}
              </DLink>
            </div>
          ) : null}
        </div>

        <CustomBtn
          sx={{ width: "180px", margin: "20px auto 10px" }}
          onClick={submitAgreement}>
          Submit Agreement
        </CustomBtn>
      </Container>
    </>
  );
};

export default MonthlyRentAgreement;

const Container = styled("div")`
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  display: grid;
`;

const AgreementContainer = styled(PaperContainer)`
  display: grid;
  text-align: center;
  gap: 10px;
  margin: 35px auto 20px;
  p {
    font-size: 0.9rem;
  }
`;

const DLink = styled("a")`
  text-align: center;
  background: #10609f;
  border-radius: 8px;
  padding: 10px 15px;
  color: white;
  text-transform: capitalize;

  &:hover {
    background: #0e4d7d;
    color: #f7eeee;
  }
`;

const UploadButton = styled("button")`
  margin: 15px auto;
  padding: 15px;
  box-sizing: border-box;
  width: 250px;
  height: 150px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(#ffff, #ffff) padding-box,
    linear-gradient(to right, #e1f2ff, #10609f) border-box;
  border: 1px solid transparent;
  border-radius: 8px;
  gap: 10px;
  color: #10609f;
`;
