import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import avatar from "#/assets/images/tenant/default-avatar.png";
import collection from "#/assets/tenantdetails/collection.png";
import phone from "#/assets/tenantdetails/phone.png";
import rent from "#/assets/tenantdetails/rent.png";
import { format, addYears } from "date-fns";
import { toCurrency } from "#/components/utils/util";

const styles = (theme) => ({
    root: {
        color: "#043B5C"
    },
    header: {
        padding: "10px 30px",
        color: "#043B5C",
        "& h1": {
            fontSize: "24px",
            color: "inherit",
            [theme.breakpoints.down("640")]: {
                fontSize: 14
            }
        }
    },
    detailsContainer: {
        padding: "30px 23px",
        backgroundColor: "#EEF4FB",
        color: "#043B5C",
        "& .avater-name-container": {
            display: "flex"
        },
        "& .img-container": {
            marginRight: 15,
            "& img": {
                borderRadius: "100%",
                width: 100,
                height: 100
            }
        },
        "& .tenant-name": {
            fontSize: "18px",
            color: "inherit",
            [theme.breakpoints.down("640")]: {
                fontSize: 15
            }
        },
        "& .tenant-contact-text": {
            fontSize: "13px",
            color: "inherit",
            display: "block"
        },
        "& .phone-img": {
            paddingTop: "40px"
        },
        "& .content-wrapper": {
            paddingTop: "30px"
        }
    },
    collection: {
        padding: "20px 23px",
        color: "#043B5C",
        "& .title-text": {
            color: "inherit",
            fontSize: 18,
            marginBottom: 15
        },
        "& .summary-wrapper": {
            paddingTop: 20,
            marginLeft: "auto",
            [theme.breakpoints.down("900")]: {
                paddingTop: 13
            }
        },
        "& .collection-container": {
            display: "flex",
            "& .collection-img": {
                marginBottom: 10,
                "& img": {
                    width: 95,
                    height: 95
                }
            },
            "& .view-invoices-text": {
                fontSize: 14,
                color: "#4786FF"
            },
            "& .invoice-text": {
                display: "block",
                fontSize: 14,
                color: "inherit"
            }
        },
        "& .rent-wrapper": {
            paddingTop: 20,
            "& .rent-title": {
                fontSize: 18,
                color: "inherit"
            },
            "& .rent-img-container": {
                display: "flex",
                "& .rent-img": {
                    marginRight: 10,
                    marginTop: 10,
                    "& img": {
                        width: 110,
                        height: 93
                    }
                },
                "& .current-rent": {
                    fontSize: 14,
                    display: "block"
                }
            }
        },
        "& .rent-summary": {
            color: "inherit",
            fontSize: 14,
            fontWeight: 600
        }
    },
    history: {
        padding: "0px 20px 20px 20px",
        color: "#043B5C",
        "& .history-title": {
            color: "inherit",
            fontSize: 15,
            fontWeight: 600
        },
        "& .table-head-container": {
            backgroundColor: "#EEF4FB",
            "& .rent-detail": {
                color: "#043B5C",
                fontSize: 13,
                fontWeight: 600
            }
        },
        "& .rent": {
            color: "inherit",
            fontSize: 15,
            fontWeight: 600
        },
        "& .MuiTableCell-root": {
            border: 0
        }
    }
});

const TenantInformation = (props) => {
    const { tenantDetails, apartmentInfo } = props;

    // console.log(apartmentInfo, "THE APARTMENT INFO");

    if (!tenantDetails) {
        return <div className={props.classes.root}></div>;
    }

    if (!apartmentInfo) {
        return null;
    }
    const { rent_payment_date, price } = apartmentInfo;

    return (
        <div className={props.classes.root}>
            <header className={props.classes.header}>
                <Typography variant="h5" component="h2">
                    <b>Tenant Details</b>
                </Typography>
            </header>
            <Grid container className={props.classes.detailsContainer}>
                <Grid item xs={12} sm={7} md={7}>
                    <div className="avater-name-container">
                        <div className="img-container">
                            <img src={avatar} alt="tenants" />
                        </div>
                        <div className="content-wrapper">
                            <Typography className="tenant-name" variant="h5" component="h4">
                                <b>{tenantDetails?.fullname}</b>
                                <span className="tenant-contact-text">{tenantDetails?.email}</span>
                            </Typography>
                        </div>
                    </div>
                </Grid>
                {/* <Grid item xs={12} sm={3} md={3}>
                    <div className="content-wrapper">
                        <span className="tenant-contact-text">Tenant Since</span>
                        <Typography className="tenant-contact-text" variant="body1" component="p">
                            {tenantDetails && <b>{format(new Date(`${tenantDetails?.updatedAt}`), "dd MMMM yyyy")}</b>}
                        </Typography>
                    </div>
                </Grid> */}
                {tenantDetails?.phone_number && tenantDetails?.phone_number !== "null" && (
                    <Grid item xs={12} sm={2} md={2} style={{ display: "flex", alignItems: "center" }}>
                        <img src={phone} alt="tenant phone number" style={{ width: "20px", marginRight: "10px" }} />
                        {tenantDetails?.phone_number}
                    </Grid>
                )}
            </Grid>
            <Grid container className={props.classes.collection}>
                <Grid item xs={12} sm={6} md={5}>
                    <Typography className="title-text" variant="body1" component="p">
                        Collection
                    </Typography>
                    <div className="collection-container">
                        <div style={{ marginRight: "15px" }}>
                            <div className="collection-img">
                                <img src={collection} alt="collection" />
                            </div>
                            {/* <div>
                                <span className="view-invoices-text">view all invoices</span>
                            </div> */}
                        </div>
                        <div>
                            <span className="invoice-text">Current Invoices</span>
                            <Typography className="invoice-text" variant="body1" component="p">
                                <b>No Record</b>
                            </Typography>
                            <span className="invoice-text">Past Due invoices</span>
                            <span className="invoice-text">344444,4494994</span>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3} className="rent-wrapper">
                    <Typography className="rent-title" variant="body1" component="p">
                        Rent
                    </Typography>
                    <div className="rent-img-container">
                        <div className="rent-img">
                            <img src={rent} alt="rent details" />
                        </div>
                        <div>
                            <span className="current-rent">Current Rent</span>
                            <span className="current-rent">
                                <b>{toCurrency(price)}</b>
                            </span>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={3} className="summary-wrapper">
                    <Typography className="rent-summary" variant="body1" component="p">
                        Total Rent Collected <br />
                        <span>&#x20A6;20,000.00</span>
                    </Typography>
                    <Typography className="rent-summary" variant="body1" component="p">
                        Other Collected <br />
                        &#x20A6;5,000.00
                    </Typography>
                    <Typography className="rent-summary" variant="body1" component="p">
                        Total
                        <br />
                        &#x20A6;25,000.00
                    </Typography>
                </Grid>
            </Grid>
            <div className={props.classes.history}>
                <span className="history-title">Lease History</span>
                <TableContainer style={{ marginTop: "10px" }}>
                    <Table>
                        <TableHead className="table-head-container">
                            <TableRow>
                                <TableCell className="rent-detail">Monthly Rent</TableCell>
                                <TableCell className="rent-detail" align="center">
                                    Over due
                                </TableCell>
                                <TableCell className="rent-detail" align="center">
                                    Payment Date
                                </TableCell>
                                <TableCell className="rent-detail" align="center">
                                    Actual Paid Date
                                </TableCell>
                                <TableCell className="rent-detail" align="center">
                                    Days Remaining
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell className="rent">{toCurrency(price)}</TableCell>
                                <TableCell className="rent" align="center">
                                    -
                                </TableCell>
                                <TableCell className="rent" align="center">
                                    {rent_payment_date ? format(new Date(`${rent_payment_date}`), "dd MMMM yyyy") : "-"}
                                </TableCell>
                                <TableCell className="rent" align="center">
                                    -
                                </TableCell>
                                <TableCell className="rent" align="center">
                                    100 days remaining
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

export default withStyles(styles)(TenantInformation);
