import PaperContainer from "#/dashboard/components/paperContainer/Index";
import { Grid, styled } from "@mui/material";
import React from "react";
import SubtitleSubtext from "./SubtitleSubtext";
import { toCurrency } from "#/components/utils/util";

export default function ApartmentCard({ details }) {
  return (
    <Card>
      <Grid container spacing={1}>
        <SubtitleSubtext
          xs={5}
          sm={5}
          md={2.5}
          subtitle={"LAID"}
          subtext={details?.apartment_id}
        />
        <SubtitleSubtext
          xs={7}
          sm={7}
          md={4.5}
          subtitle={"Address"}
          subtext={
            details?.house_number ? (
              <>
                {details?.house_number} {details?.street} {details?.state} {details?.lga}{" "}
                {details?.lcda}
              </>
            ) : null
          }
        />
        <SubtitleSubtext
          xs={5}
          sm={5}
          md={2.5}
          subtitle={"Rent Tenure"}
          subtext={
            details?.payment_term ? (
              <span style={{ textTransform: "capitalize" }}>{details?.payment_term}</span>
            ) : null
          }
        />
        <SubtitleSubtext
          xs={7}
          sm={7}
          md={2.5}
          subtitle={"Rent Value"}
          subtext={`${toCurrency(details?.price)}`}
        />
      </Grid>
    </Card>
  );
}

const Card = styled(PaperContainer)`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 2rem;
  margin: 0.5rem 0;
`;
