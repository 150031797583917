import useFetch from "#/hooks/util-hooks/useFetch";
import { Modal } from "@material-ui/core";
import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";

const RenewalSuccessModal = ({ handleSetModalVisibility, liveizy_id }) => {
  const [view, setView] = useState(false);

  const { data: currentRenewal, loading } = useFetch(`/current-renewal/${liveizy_id}`, {
    errorMessage: "Error getting data"
  });

  const renderBody = () => {
    return (
      <>
        <div
          className="tw-pt-5 tw-pr-5 tw-flex tw-justify-end"
          onClick={handleSetModalVisibility}>
          <FaTimes color="#10609F" size={25} className="tw-cursor-pointer" />
        </div>
        <div className="content-center">
          <h2 style={{ textAlign: "center", marginBottom: "3rem" }} className="">
            <span className="tw-text-[1.7rem] tw-font-semibold">Rent Loan Renewal</span>
          </h2>
          <p>Your Apllication for the Renewal Process is successful</p>
          <div className="tw-flex tw-items-center tw-justify-center">
            <button
              type="submit"
              className="tw-uppercase tw-text-white tw-bg-[#10609F] tw-py-4 tw-px-16 tw-rounded-2xl"
              onClick={() => setView(true)}>
              {loading ? <CircularProgress /> : "View details"}
            </button>
          </div>
          {/* 
                    {view && (
                        <div className="tw-mt-16">
                            <div className="calc-details tw-flex tw-justify-between tw-items-center tw-mb-7 flexCol">
                                <p className="tw-text-[#052137]">Monthly Rent Amount</p>
                                <h2 className="px-3 py-3 tw-text-center tw-text-[17px] tw-font-semibold tw-rounded-lg wFull">
                                    {}
                                </h2>
                            </div>
                            <div className="calc-details tw-flex tw-justify-between tw-items-center tw-mb-7 flexCol">
                                <p className="tw-text-[#052137]">Refundable Security Deposit</p>
                                <h2 className="px-3 py-3 tw-text-center tw-text-[17px] tw-font-semibold tw-rounded-lg wFull">
                                    {}
                                </h2>
                            </div>
                            <div className="calc-details tw-flex tw-justify-between tw-items-center tw-mb-7 flexCol">
                                <p className="tw-text-[#052137]">Subscription fee</p>
                                <h2 className="px-3 py-3 tw-text-center tw-text-[17px] tw-font-semibold tw-rounded-lg wFull">
                                    {}
                                </h2>
                            </div>
                            <div className="calc-details tw-flex tw-justify-between tw-items-center tw-mb-7 flexCol">
                                <p className="tw-text-[#052137]">Contribution</p>
                                <h2 className="px-3 py-3 tw-text-center tw-text-[17px] tw-font-semibold tw-rounded-lg wFull">
                                    {}
                                </h2>
                            </div>
                        </div>
                    )} */}
        </div>
      </>
    );
  };

  return (
    <Modal
      isModalOpen={true}
      handleCloseModal={handleSetModalVisibility}
      hasHeader={false}
      style={{ maxWidth: "30rem", borderRadius: 10 }}>
      {renderBody()}
    </Modal>
  );
};

export default RenewalSuccessModal;
