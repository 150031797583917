import * as actionTypes from "../constants";

const {
    CREATE_APARTMENT_LIPMAN_PLAN_PENDING,
    CREATE_APARTMENT_LIPMAN_PLAN_SUCCESS,
    CREATE_APARTMENT_LIPMAN_PLAN_FAIL,
    CREATE_APARTMENT_LIREM_PLAN_PENDING,
    CREATE_APARTMENT_LIREM_PLAN_SUCCESS,
    CREATE_APARTMENT_LIREM_PLAN_FAIL
} = actionTypes.PLANS_CONSTANTS;

const initialState = {
    creatingPlan: false
};

const planReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case CREATE_APARTMENT_LIPMAN_PLAN_PENDING:
            return {
                ...state,
                creatingPlan: true
            };
        case CREATE_APARTMENT_LIPMAN_PLAN_SUCCESS:
            return {
                ...state,
                creatingPlan: false
            };
        case CREATE_APARTMENT_LIPMAN_PLAN_FAIL:
            return {
                ...state,
                creatingPlan: false
            };
        case CREATE_APARTMENT_LIREM_PLAN_PENDING:
            return {
                ...state,
                creatingPlan: true
            };
        case CREATE_APARTMENT_LIREM_PLAN_SUCCESS:
            return {
                ...state,
                creatingPlan: false
            };
        case CREATE_APARTMENT_LIREM_PLAN_FAIL:
            return {
                ...state,
                creatingPlan: false
            };

        default:
            return state;
    }
};

export default planReducer;
