import { styled, Typography } from "@mui/material";
import { useField, useFormikContext } from "formik";
import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { BsImageFill } from "react-icons/bs";

const UploadDiv = styled("div")`
    margin: 15px auto;
    padding: 15px;
    box-sizing: border-box;
    width: 250px;
    height: 150px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(#ffff, #ffff) padding-box, linear-gradient(to right, #e1f2ff, #10609f) border-box;
    border: 1px solid transparent;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 8px;
    gap: 10px;
    color: #10609f;
`;

const ImagePreview = styled("div")`
    margin: 15px auto;
    box-sizing: border-box;
    width: 250px;
    height: 150px;
    background: linear-gradient(#ffff, #ffff) padding-box, linear-gradient(to right, #e1f2ff, #10609f) border-box;
    border: 1px solid transparent;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 8px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const UploadButton = styled("button")`
    padding: 15px 25px;
    background: #10609f;
    color: white;
    font-size: 14px;
    text-transform: uppercase;
    margin-top: 10px;
    border-radius: 8px;
    text-align: center;
    max-width: 200px;
`;

const FormikCustomImageRectUploader = ({ name, label, ...otherProps }) => {
    const [field, meta] = useField(name);
    const { setFieldValue, touched, setTouched, values } = useFormikContext();
    const image = values[name];
    const fileRef = useRef(null);

    const [preview, setPreview] = useState("");
    useEffect(() => {
        if (image) {
            if (typeof image === "string") {
                setPreview(image);
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = () => {
                    setPreview(reader.result);
                };
            }
        }
    }, [image]);

    const handleOnChange = (event) => {
        // The idea of this logic is that, when `upload` button is clicked and the user tries to cancel
        // since the input has been clicked, the event returns and empty FILE object
        // because of the cancel.
        // this logic would update the formik state only if the input event returns a file object === 1
        if (image && event.target.files.length === 1) {
            setFieldValue(name, event.target.files[0]);
        } else if (!image && event.target.files.length === 1) {
            setFieldValue(name, event.target.files[0]);
        }
    };
    const configImageUploadField = {
        ...otherProps,
        accept: ".jpg, .png, .jpeg"
    };
    return (
        <>
            <input
                hidden
                ref={fileRef}
                name={name}
                type={"file"}
                onChange={handleOnChange}
                {...configImageUploadField}
            />
            <UploadDiv>
                <BsImageFill fontSize={"35px"} />
                <UploadButton
                    onClick={() => {
                        setTouched({ ...touched, [name]: true });
                        fileRef.current.click();
                    }}
                    type="button">
                    {label}
                </UploadButton>
            </UploadDiv>
            {meta && meta.touched && meta.error && (
                <span style={{ color: "red", fontSize: "14px", margin: "10px 30%", textAlign: "center" }}>
                    {meta.error}
                </span>
            )}

            {image && (
                <ImagePreview>
                    <img src={preview} alt="Id image" />
                </ImagePreview>
            )}
        </>
    );
};

export default FormikCustomImageRectUploader;
