import React, { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import SearchBar from "material-ui-search-bar";
// import { makeStyles } from '@material-ui/core/styles';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import { ThemeProvider } from "@material-ui/core/styles";

import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { StyledTableCell, StyledTableRow, useStyles, theme } from "./rightStyles";
import { toCurrency } from "../../../components/utils/util";

export const TransactionTable = (props) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <React.Fragment>
      <Grid item container sm md xs={12}>
        <Box display={{ xs: "none", sm: "block" }} style={{ width: "inherit", padding: "3% 0" }}>
          <Grid container style={{ marginBottom: "10%", alignItems: "baseline" }}>
            <Grid item sm md xs={12}>
              <ThemeProvider theme={theme}>
                <Typography style={{ fontWeight: "bold", fontSize: "20px" }}>WALLET TRANSACTIONS</Typography>
              </ThemeProvider>
            </Grid>
            <Grid item md={4} xs={12} sm>
              <SearchBar
                onChange={() => console.log("onChange")}
                onRequestSearch={() => console.log("onRequestSearch")}
                style={{ borderColor: "#043B5C75" }}
              />
            </Grid>
          </Grid>
          <Grid>
            <Paper>
              <TableContainer style={{ marginBottom: "5%" }}>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>
                        AMOUNT <span className={classes.dot}></span>
                      </StyledTableCell>
                      <StyledTableCell align="right">TYPE</StyledTableCell>
                      <StyledTableCell align="right" style={{ width: "50%" }}>
                        DESCRIPTIONS
                      </StyledTableCell>
                      <StyledTableCell align="right">DATE</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.transactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                      var localDate = new Date(row.created_at);
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell component="th" scope="row">
                            {toCurrency(row.amount)}
                          </StyledTableCell>
                          <StyledTableCell align="right">{row.type}</StyledTableCell>
                          <StyledTableCell align="right">{row.meta["details"]}</StyledTableCell>
                          <StyledTableCell align="right">{localDate.toDateString()}</StyledTableCell>
                          <StyledTableCell align="right">{row.protein}</StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={props.transactions.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </Box>

        {/* MObile view column */}

        <Box display={{ xs: "block", sm: "none" }} style={{ padding: "10% 0%" }}>
          <Grid container justify="center" style={{ alignItems: "flex-end", textAlign: "center" }}>
            <Grid container justify="space-between" item xs={12} style={{ marginBottom: "5%" }}>
              <Grid item xs={12}>
                <Box className={classes.walletText}>
                  <ThemeProvider theme={theme}>
                    <Typography style={{ fontWeight: "bold", fontSize: "20px" }}>WALLET TRANSACTIONS</Typography>
                  </ThemeProvider>
                </Box>

                <Box>
                  {/* <Typography>search</Typography> */}
                  <SearchBar
                    onChange={() => console.log("onChange")}
                    onRequestSearch={() => console.log("onRequestSearch")}
                    style={{
                      margin: "0 auto",
                      maxWidth: 800,
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container className={classes.disp}>
              {props.transactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                const localDate = new Date(row.created_at);
                return (
                  <Grid container key={index}>
                    <Grid item xs style={{ textAlign: "justify" }}>
                      <Box fontWeight="fontWeightBold" m={1}>
                        <Typography className={classes.typography1}>{toCurrency(row.amount)}</Typography>
                      </Box>
                      <Box>
                        <Typography className={classes.typographyN} style={{ width: "75%", textAlign: "center" }}>
                          {row.meta["details"]}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs className={classes.alignIzy}>
                      <Box fontWeight="fontWeightBold" m={1}>
                        <Typography className={classes.typography2} style={{ marginTop: "14%", marginBottom: "15%" }}>
                          {row.type}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className={classes.typographyN}>{localDate.toDateString()}</Typography>
                      </Box>
                    </Grid>
                    <hr className={classes.hr} style={{ margin: "0, 7% !important" }} />
                  </Grid>
                );
              })}
            </Grid>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={props.transactions.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Box>
      </Grid>
    </React.Fragment>
  );
};
