/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import "../node_modules/metismenujs/dist/metismenujs.css";
import "./App.css";
import "./assets/styles/main.scss";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core";
import { createBrowserHistory } from "history";
import { Provider, batch } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import store from "./store/store.js";

//Auth Routes
import Login from "#/pages/Auth/login/login";
import Register from "#/pages/Auth/register/register";
import Forgetpassword from "#/pages/Auth/Forgetpassword/Forgetpassword";
import ResetPassword from "#/pages/Auth/ResetPaword/ResetPassword";

import AlertComponent from "#/components/alert.component";
import AuthRouter from "#/routes/AuthRouter";
import { getIzyInvestPlansList, getIzyPlan } from "#/store/actions/izyinvest.actions";
import { Banks, getIzyPayBalance } from "#/store/actions/izypay.actions";
import { DashboardRoutes } from "./Root";
import { getSubscriptionRemindersSummary } from "./store/actions/reminder.action";
import { getTenantsApartment } from "./store/actions/tenant.actions";
import { getMyBookings, getApartmentsBooked } from "./store/actions/booking.actions";
import VerifyEmail from "./pages/Auth/VerifyEmail/VerifyEmail";
import ResendLink from "./pages/Auth/VerifyEmail/ResendLink";
import { getOtherInformation } from "./store/actions/auth.actions";

export const hashHistory = createBrowserHistory();

const queryClient = new QueryClient();

const ThemedApp = () => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#043B5C"
      },
      secondary: {
        main: "#C8222C"
      },
      faded: "rgba(25,161,177,0.05)",
      frontPrimary: "#19a1b1"
    },
    typography: {
      fontFamily: ["Open Sans"].join(",")
    }
  });

  const dispatch = useDispatch();
  const liveizy_id = useSelector((state) => state.auth.user.liveizy_id);
  const id = useSelector((state) => state.auth.user.id);
  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    //Only fire when user is logged in
    if (id && userData.is_email_verified) {
      batch(() => {
        dispatch(getIzyPlan(""));
        dispatch(getIzyInvestPlansList());
        dispatch(getIzyPayBalance());
        dispatch(getOtherInformation());
        dispatch(Banks());
        //dispatch(checkExistenceOfReminders(id));
        //dispatch(getTenantsUnderPropertyManager(liveizy_id));
        dispatch(getSubscriptionRemindersSummary(id));
        dispatch(getMyBookings(liveizy_id));
        dispatch(getApartmentsBooked(liveizy_id));
        dispatch(getTenantsApartment(liveizy_id));
      });
    }
  }, [id]);

  return (
    <div style={{ boxSizing: "border-box", margin: 0, padding: 0 }}>
      <MuiThemeProvider theme={theme}>
        <AlertComponent />
        <Router history={hashHistory}>
          <Switch>
            <AuthRouter exact path="/" component={Login} />
            <AuthRouter exact path="/register" component={Register} />
            <AuthRouter exact path="/Forgetpassword" component={Forgetpassword} />
            <AuthRouter exact path="/email/verify/:id" component={VerifyEmail} />
            <AuthRouter exact path="/resend/link" component={ResendLink} />
            <AuthRouter exact path="/reset-password" component={ResetPassword} />
            <DashboardRoutes />
          </Switch>
        </Router>
      </MuiThemeProvider>
    </div>
  );
};

export const App = () => {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemedApp />
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
