import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format, addYears } from "date-fns";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { CircularProgress } from "@material-ui/core";

import Button from "#/components/ui-components/button.component";
import OptionsDropdown from "../optionsDropdown/optionsDropdown";

import { useRentReminderStyles, BorderLinearProgress } from "./rentReminder.styles";
import { toCurrency } from "#/components/utils/util";

const RentReminder = ({
    inactiveReminders,
    activeReminders,
    deliveredReminders,
    loadingSummary,
    handlePayment,
    dateActivated,
    paying,
    apartmentsInPlan
}) => {
    //---------------------------------------------
    //Helper hooks
    //---------------------------------------------
    const classes = useRentReminderStyles();

    //Show loader when data has not finished loading
    if (loadingSummary) {
        return (
            <div
                style={{
                    height: "50vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                <CircularProgress size={50} className="icon" color="inherit" />
            </div>
        );
    }

    //---------------------------------------------
    //Helpers/Variables
    //---------------------------------------------
    const total = activeReminders + deliveredReminders;
    const progressValue = parseInt((deliveredReminders / total) * 100);

    return (
        <div className={classes.planDetailsContainer}>
            <header className={classes.planDetailsContainerHeader}>
                <h1>Plans Details</h1>

                <OptionsDropdown>Change Plans</OptionsDropdown>
            </header>
            <section className={classes.planDetailsContainerSummary}>
                <div className="section">
                    <h3>Total Apartment In Plan</h3>
                    <h1>{apartmentsInPlan}</h1>
                </div>
                <div className="section">
                    <h3>Reminders Available</h3>
                    <h1>{activeReminders}</h1>
                </div>
                <div className="section">
                    <h3>Reminders Sent</h3>
                    <h1>{deliveredReminders}</h1>
                </div>
                <div className="section">
                    <h3>Date Created</h3>
                    {/*<h1>{format(new Date(`${dateActivated}`), "dd MMMM yyyy")}</h1>*/}
                </div>

                <div className="section">
                    <h3>Expiration Date</h3>
                    <h1>{/*format(addYears(new Date(`${dateActivated}`), 1), "dd MMMM yyyy")*/}</h1>
                </div>
            </section>
            <section className={classes.plansUsage}>
                <h1>Plan Usage</h1>
                <h3 className="date">Date Created: {/*format(new Date(`${dateActivated}`), "dd MMMM yyyy")*/}</h3>
                <div className="linear-progress">
                    <div className="linear-progress__title">
                        <h3>Reminders Sent</h3>
                        <h3>
                            {deliveredReminders} of {total} Reminders
                        </h3>
                    </div>
                    <BorderLinearProgress variant="determinate" value={progressValue} />
                </div>
            </section>
            <section className={classes.subscriptionBenefits}>
                <h3 className="title">Subscription Benefits</h3>
                <ul className="benefits-list">
                    <li>
                        <CheckCircleIcon htmlColor="#4786FF" fontSize="small" />
                        Easy way to send reminders Tenants
                    </li>
                    <li>
                        <CheckCircleIcon htmlColor="#4786FF" fontSize="small" />
                        Keep your Tenants Informed
                    </li>
                </ul>
            </section>
            {inactiveReminders > 0 ? (
                <Button
                    buttonText={`Pay  ${toCurrency(200 * apartmentsInPlan)}`}
                    onClick={handlePayment}
                    isLoading={paying}
                    style={{
                        backgroundColor: "#4786FF",
                        color: "#E8F0F9",
                        textTransform: "none",
                        borderRadius: "5px",
                        color: "#F4F5F7",
                        border: "1px solid #4786FF",
                        padding: "7px 60px",
                        fontSize: "13px",
                        fontFamily: "Open Sans, sans-serif"
                    }}
                />
            ) : null}
        </div>
    );
};

export default memo(RentReminder);
