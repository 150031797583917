import formModel from "./formModel";
const {
  formField: {
    profileImage,
    lastName,
    firstName,
    otherName,
    gender,
    dateOfBirth,
    stateOfOrigin,
    lga,
    email,
    alternateEmail,
    mobileNumber,
    alternateMobileNumber
  }
} = formModel;

export default function (profileInfo, otherInformation) {
  const initialValues = {
    [profileImage.name]: profileInfo?.profile_image || null,
    [lastName.name]: profileInfo?.last_name || "",
    [firstName.name]: profileInfo?.first_name || "",
    [otherName.name]: otherInformation?.other_name || "",
    [gender.name]: profileInfo?.gender || "",
    [dateOfBirth.name]: profileInfo?.date_of_birth || "",
    [stateOfOrigin.name]: profileInfo?.state_of_origin || "",
    [lga.name]: profileInfo?.local_governement_area || "",
    [email.name]: profileInfo?.email || "",
    [alternateEmail.name]: profileInfo?.alt_email || "",
    [mobileNumber.name]: profileInfo?.phone || "",
    [alternateMobileNumber.name]: profileInfo?.alt_phone || ""
  };

  return initialValues;
}
