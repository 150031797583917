import { TextField, styled } from "@mui/material";
import { useField } from "formik";
import React from "react";

const DatePickerField = ({ name, ...otherProps }) => {
  const [field, meta] = useField(name);

  const configDatePickerField = {
    ...field,
    ...otherProps,
    type: "date",
    fullWidth: true,
    InputLabelProps: {
      shrink: true
    },
    variant: "outlined",
    format: "MM/yy",
    views: ["year", "month"],
    // minDate:new Date(),
    maxdate: new Date()
  };

  if (meta && meta.touched && meta.error) {
    configDatePickerField.error = true;
    configDatePickerField.helperText = meta.error;
  }
  return <DatePicker {...configDatePickerField} />;
};

export default DatePickerField;

const DatePicker = styled(TextField)`
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 2% 3%;
  }
`;
