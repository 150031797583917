import React, { useState, useEffect } from "react";
import { Button, TextField, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { handleFormInput, validate } from "#/lib/validation";
import "../personalDetails/personal-details.scss";
import ProgressBar from "../progressBar";

const useStyles = makeStyles(() => ({
    root: {
        "& .MuiOutlinedInput-root": {
            borderRadius: 12
        }
    }
}));

const ResidentialDetails = ({ setInDisplay, formValues, setFormValues, submitInterest }) => {
    const classes = useStyles();
    const fields = {
        current_address: formValues.current_address,
        current_rent_value: formValues.current_rent_value,
        name_of_landlord: formValues.name_of_landlord,
        phone_of_landlord: formValues.phone_of_landlord,
        name_of_pm: formValues.name_of_pm,
        phone_of_pm: formValues.phone_of_pm
    };

    const [enableSubmit, setEnableSubmit] = useState(false);

    useEffect(() => {
        if (formValues.current_address) validate(fields, setEnableSubmit);
    }, [formValues]);

    const handleNextPage = () => {
        submitInterest();
    };

    const handleLastPage = () => {
        setInDisplay(2);
    };

    return (
        <div className="personal-details-show-of-interest">
            <ProgressBar status1 status2 status3 />
            <div className="form-container form-container-b">
                <TextField
                    className={clsx(classes.root, "textfield-soi")}
                    label="Current Address"
                    variant="outlined"
                    id="custom-css-outlined-input"
                    value={formValues.current_address?.value}
                    error={!formValues.current_address?.validation}
                    helperText={formValues.current_address?.error}
                    onChange={(e) => {
                        const value = e.target?.value;
                        handleFormInput("current_address", value, /./gi, formValues, setFormValues, [
                            "required",
                            "address"
                        ]);
                    }}
                />
                <TextField
                    className={clsx(classes.root, "textfield-soi")}
                    label="Current Rent Value"
                    variant="outlined"
                    id="custom-css-outlined-input"
                    value={formValues.current_rent_value?.value}
                    error={!formValues.current_rent_value?.validation}
                    helperText={formValues.current_rent_value?.error}
                    onChange={(e) => {
                        const value = e.target?.value.replace(/[^\d]/gi, "");
                        handleFormInput("current_rent_value", value, /./gi, formValues, setFormValues, ["required"]);
                    }}
                />
                <TextField
                    className={clsx(classes.root, "textfield-soi")}
                    label="Name of Landlord"
                    variant="outlined"
                    id="custom-css-outlined-input"
                    value={formValues.name_of_landlord?.value}
                    error={!formValues.name_of_landlord?.validation}
                    helperText={formValues.name_of_landlord?.error}
                    onChange={(e) => {
                        const value = e.target?.value;
                        handleFormInput("name_of_landlord", value, /./gi, formValues, setFormValues, [
                            "required",
                            "text"
                        ]);
                    }}
                />
                <TextField
                    className={clsx(classes.root, "textfield-soi")}
                    label="Contact Number of Landlord"
                    variant="outlined"
                    id="custom-css-outlined-input"
                    value={formValues.phone_of_landlord?.value}
                    error={!formValues.phone_of_landlord?.validation}
                    helperText={formValues.phone_of_landlord?.error}
                    onChange={(e) => {
                        const value = e.target?.value.replace(/[^\d]/gi, "").slice(0, 11);
                        handleFormInput("phone_of_landlord", value, /./gi, formValues, setFormValues, ["required"]);
                    }}
                />
                <TextField
                    className={clsx(classes.root, "textfield-soi")}
                    label="Name of Property Manager"
                    variant="outlined"
                    id="custom-css-outlined-input"
                    value={formValues.name_of_pm?.value}
                    error={!formValues.name_of_pm?.validation}
                    helperText={formValues.name_of_pm?.error}
                    onChange={(e) => {
                        const value = e.target?.value;
                        handleFormInput("name_of_pm", value, /./gi, formValues, setFormValues, ["required", "text"]);
                    }}
                />
                <TextField
                    className={clsx(classes.root, "textfield-soi")}
                    label="Phone of Property Manager"
                    variant="outlined"
                    id="custom-css-outlined-input"
                    value={formValues.phone_of_pm?.value}
                    error={!formValues.phone_of_pm?.validation}
                    helperText={formValues.phone_of_pm?.error}
                    onChange={(e) => {
                        const value = e.target?.value.replace(/[^\d]/gi, "").slice(0, 11);
                        handleFormInput("phone_of_pm", value, /./gi, formValues, setFormValues, ["required"]);
                    }}
                />
            </div>
            <div className="btn-container">
                <Button className="btn-proceed" variant="outlined" color="primary" onClick={handleLastPage}>
                    BACK
                </Button>
                <Button
                    disabled={!enableSubmit}
                    className="btn-proceed"
                    variant="outlined"
                    color="primary"
                    onClick={handleNextPage}>
                    SUBMIT
                </Button>
            </div>
        </div>
    );
};

export default ResidentialDetails;
