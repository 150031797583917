import {
  GET_IZYPAY_BALANCE,
  GET_IZYPAY_BALANCE_FAIL,
  GET_IZYPAY_BALANCE_SUCCESS,
  START_TRANSFER,
  START_TRANSFER_SUCCESS,
  START_TRANSFER_FAIL,
  PAYSTACK_SUCCESS,
  CLOSE_TRANSFER_POPUP,
  VALIDATE_NUBAN,
  VALIDATE_NUBAN_SUCCESS,
  VALIDATE_NUBAN_FAIL,
  GENERATE_RENT_WALLET,
  GENERATE_RENT_WALLET_SUCCESS,
  GENERATE_RENT_WALLET_FAIL
} from "../constants";

const initialState = {
  balance: 0,
  izyPayWallet: {},
  commissionWallet: {},
  rentWallet: {},
  loading: false,
  rentWalletLoading: false,
  transactions: [],
  transfer: false,
  showPopup: false,
  paystack: false,
  nubanDetails: {}
};

const izypayReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_IZYPAY_BALANCE:
      return {
        ...state,
        loading: true
      };
    case GET_IZYPAY_BALANCE_SUCCESS:
      return {
        ...state,
        balance: action.payload,
        izyPayWallet: action.izyPayWallet,
        commissionWallet: action.commissionWallet,
        rentWallet: action.rentWallet,
        transactions: action.transactions,
        loading: false
      };
    case GET_IZYPAY_BALANCE_FAIL:
      return {
        ...state,
        loading: false
      };
    case START_TRANSFER:
      return {
        ...state,
        loading: true
      };
    case START_TRANSFER_SUCCESS:
      return {
        ...state,
        transfer: true
        // loading: false,
      };
    case START_TRANSFER_FAIL:
      return {
        ...state,
        transfer: false,
        loading: false
        // closePopup: false,
      };
    case CLOSE_TRANSFER_POPUP:
      return {
        ...state,
        showPopup: action.showPopup
      };
    case PAYSTACK_SUCCESS:
      return {
        ...state,
        loading: true
      };
    case VALIDATE_NUBAN:
      return {
        ...state,
        loading: true
      };
    case VALIDATE_NUBAN_SUCCESS:
      return {
        ...state,
        loading: false,
        nubanDetails: action.nuban
      };
    case VALIDATE_NUBAN_FAIL:
      return {
        ...state,
        loading: false
      };
    case GENERATE_RENT_WALLET:
      return {
        ...state,
        rentWalletLoading: true
      };
    case GENERATE_RENT_WALLET_SUCCESS:
      return {
        ...state,
        rentWalletLoading: false
      };
    case GENERATE_RENT_WALLET_FAIL:
      return {
        ...state,
        rentWalletLoading: false
      };

    default:
      return state;
  }
};

export default izypayReducer;
