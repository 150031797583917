import { Box, Checkbox, FormControlLabel, Grid, styled } from "@mui/material";
import React, { useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export default function SingleAccordion() {
  const [titleChecked, setTitleChecked] = useState(false);
  const [show, setShow] = useState(false);

  const handleShowAccordion = () => {
    setShow(!show);
  };
  return (
    <Wrapper>
      <Title onClick={handleShowAccordion} show={show}>
        <div>
          <input
            type="checkbox"
            id="roleTitle"
            name="roleTitle"
            checked={titleChecked}
            onChange={() => setTitleChecked(!titleChecked)}
          />
          <p for="roleTitle">Dashboard</p>
        </div>
        <TitleIcon show={show}>
          <KeyboardArrowUpIcon />
        </TitleIcon>
      </Title>
      <Content show={show}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              label="Select All"
              control={<Checkbox size="small" defaultChecked />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              label="Create/Edit Notification"
              control={<Checkbox size="small" />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              label="Create/Edit financial"
              control={<Checkbox size="small" defaultChecked />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              label="Add/Remove Members"
              control={<Checkbox size="small" defaultChecked />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel label="View Members" control={<Checkbox size="small" />} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              label="View financial"
              control={<Checkbox size="small" />}
            />
          </Grid>
        </Grid>
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border-radius: 12px;
  margin-bottom: 30px;
`;

const Title = styled("div")`
  height: 60px;
  padding: 20px 25px;
  display: flex;
  color: #052137cc;
  font-weight: bold;
  font-size: 18px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-bottom: ${(props) => (props.show ? "1px solid black" : "none")};

  div {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  input {
    width: 18px;
    height: 18px;
  }
`;

const Content = styled("div")`
  width: 100%;
  padding: ${(props) => (props.show ? "20px 25px" : "0 25px")};
  max-height: ${(props) => (props.show ? "auto" : 0)};
  overflow: hidden;
  transition: all 0.3s ease-in;
`;

const TitleIcon = styled("div")`
  transition: ease 0.3s;
  transform: ${(props) => (props.show ? "rotate(180deg)" : "none")};
`;
