import React, { useState, useEffect } from "react";
import { Button, TextField, makeStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import "../personalDetails/personal-details.scss";
import { handleFormInput, validate } from "#/lib/validation";
import ProgressBar from "../progressBar";

const useStyles = makeStyles(() => ({
    root: {
        "& .MuiOutlinedInput-root": {
            borderRadius: 12
        }
    }
}));

const JobDetails = ({ setInDisplay, formValues, setFormValues }) => {
    const classes = useStyles();
    const fields = {
        employment_status: formValues.employment_status,
        monthly_income: formValues.monthly_income,
        employer_name: formValues.employer_name,
        office_address: formValues.office_address,
        contact_person_name: formValues.contact_person_name,
        contact_person_number: formValues.contact_person_number
    };

    const [enableSubmit, setEnableSubmit] = useState(false);

    useEffect(() => {
        validate(fields, setEnableSubmit);
    }, [formValues]);

    const handleNextPage = () => {
        setInDisplay(3);
    };

    const handleLastPage = () => {
        setInDisplay(1);
    };

    return (
        <div className="personal-details-show-of-interest">
            <ProgressBar status1 status2 />
            <div className="form-container form-container-b">
                <Autocomplete
                    id="combo-box-demo"
                    className="textfield-soi"
                    value={formValues.employment_status.value}
                    options={["unemployed", "employed", "student"]}
                    renderInput={(params) => (
                        <TextField className={classes.root} {...params} label="Employment Status" variant="outlined" />
                    )}
                    onChange={(e, value) => {
                        handleFormInput("employment_status", value, /./gi, formValues, setFormValues);
                    }}
                />
                <Autocomplete
                    id="combo-box-demo"
                    className="textfield-soi"
                    value={formValues.monthly_income.value}
                    options={[
                        "0 - 250,000",
                        "250,000 - 500,000",
                        "500,000 - 750,000",
                        "750,000 - 1,000,000",
                        "Above 1,000,000"
                    ]}
                    renderInput={(params) => (
                        <TextField className={classes.root} {...params} label="Monthly Income" variant="outlined" />
                    )}
                    onChange={(e, value) => {
                        handleFormInput("monthly_income", value, /./gi, formValues, setFormValues);
                    }}
                />
                <TextField
                    className={clsx(classes.root, "textfield-soi")}
                    label="Name of Employer"
                    variant="outlined"
                    id="custom-css-outlined-input3"
                    value={formValues.employer_name?.value}
                    error={!formValues.employer_name.validation}
                    helperText={formValues.employer_name.error}
                    onChange={(e) => {
                        const value = e.target?.value;
                        handleFormInput("employer_name", value, /./gi, formValues, setFormValues, ["required", "text"]);
                    }}
                />
                <TextField
                    className={clsx(classes.root, "textfield-soi")}
                    label="Office Address"
                    variant="outlined"
                    id="custom-css-outlined-input4"
                    value={formValues.office_address?.value}
                    error={!formValues.office_address.validation}
                    helperText={formValues.office_address.error}
                    onChange={(e) => {
                        const value = e.target?.value;
                        handleFormInput("office_address", value, /./gi, formValues, setFormValues, [
                            "required",
                            "address"
                        ]);
                    }}
                />
                <TextField
                    className={clsx(classes.root, "textfield-soi")}
                    label="Contact Person Name"
                    variant="outlined"
                    id="custom-css-outlined-input5"
                    value={formValues.contact_person_name?.value}
                    error={!formValues.contact_person_name.validation}
                    helperText={formValues.contact_person_name.error}
                    onChange={(e) => {
                        const value = e.target?.value;
                        handleFormInput("contact_person_name", value, /./gi, formValues, setFormValues, [
                            "required",
                            "text"
                        ]);
                    }}
                />
                <TextField
                    className={clsx(classes.root, "textfield-soi")}
                    label="Contact Person Number"
                    placeholder="eg. 08032234444"
                    variant="outlined"
                    id="custom-css-outlined-input6"
                    value={formValues.contact_person_number?.value}
                    error={!formValues.contact_person_number.validation}
                    helperText={formValues.contact_person_number.error}
                    onChange={(e) => {
                        const value = e.target?.value.replace(/[^\d]/gi, "").slice(0, 11);
                        handleFormInput("contact_person_number", value, /./gi, formValues, setFormValues, ["required"]);
                    }}
                />
            </div>
            <div className="btn-container">
                <Button className="btn-proceed" variant="outlined" color="primary" onClick={handleLastPage}>
                    BACK
                </Button>
                <Button
                    disabled={!enableSubmit}
                    className="btn-proceed"
                    variant="outlined"
                    color="primary"
                    onClick={handleNextPage}>
                    NEXT
                </Button>
            </div>
        </div>
    );
};

export default JobDetails;
