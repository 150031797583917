import React from "react";
import "../../App.css";
import "./description.scss";

// icons
import BathtubOutlinedIcon from "@material-ui/icons/BathtubOutlined";
import CompareArrowsOutlinedIcon from "@material-ui/icons/CompareArrowsOutlined";

// images
import Location from "../../assets/images/tenant/location.svg";
import Bed from "../../assets/images/tenant/bed.svg";

class Description extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            // Booking description

            <React.Fragment>
                <p className="text-color" style={{ fontSize: "40px", fontWeight: "700" }}>
                    ₦20,000.00
                </p>
                <p className="text-color" style={{ fontWeight: "600", marginBottom: "0px" }}>
                    Chevron Drive Estate
                </p>
                <p className="text-color small-p">
                    <img src={Location} alt="location" style={{ width: "13px", height: "17px" }} /> 132 Chevron drive,
                    New york Nigeria
                </p>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-lg-4" style={{ paddingLeft: "4px" }}>
                            <p className="small-p">Bedrooms</p>
                            <p className="small-p">
                                4 <img src={Bed} alt="bed" style={{ width: "20px", height: "17px" }} />
                            </p>
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <p className="small-p mobile-p">Bathroom</p>
                            <p className="small-p mobile-p">
                                2 <BathtubOutlinedIcon />
                            </p>
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <p className="small-p">Area</p>
                            <p className="small-p">
                                4 <CompareArrowsOutlinedIcon />
                            </p>
                        </div>
                    </div>
                </div>
                <p className="text-color" style={{ fontWeight: "600", marginBottom: "4px" }}>
                    Description
                </p>
                <p className="text-color small-p" style={{ paddingBottom: "2px" }}>
                    I was part of the team that was entrusted to find the problem areas and redesign the experience for
                    the users of the most popular flagship product from Tazaa Inc.
                </p>
                <button className="btn btn-primary">Book Inspection</button>
            </React.Fragment>
        );
    }
}

export default Description;
