import { Grid } from '@material-ui/core';
import React from 'react';

const Secondcolumnlayout = (props) => {
  return (
    <React.Fragment>
      <Grid item xs={12} md={12} sm={12}>
        {/* <div className="col-sm-12 col-lg-8"> */}
        <div className="card">
          <div className="card-body">{props.children}</div>
        </div>
        {/* </div> */}
      </Grid>
    </React.Fragment>
  );
};

export default Secondcolumnlayout;
