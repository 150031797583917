export const OPTIONS_AVAILABILITY_OF_PROPERTY = [
    {
        mainValue: "select",
        displayValue: "Select"
    },
    {
        mainValue: "vacant",
        displayValue: "vacant"
    },
    {
        mainValue: "occupied",
        displayValue: "occupied"
    }
];

export const OPTIONS_PROPERTY_TYPE = [
    {
        mainValue: "selectType",
        displayValue: "Select Type"
    },
    {
        mainValue: "blocksOfFlat",
        displayValue: "Blocks of flat"
    },
    {
        mainValue: "bungalow",
        displayValue: "Bungalow"
    },
    {
        mainValue: "duplex",
        displayValue: "Duplex"
    },
    {
        mainValue: "others",
        displayValue: "Others"
    }
];

export const OPTIONS_PAYMENT_FREQUENCY = [
    {
        mainValue: "selectunit",
        displayValue: "Select Unit"
    },

    {
        mainValue: "monthly",
        displayValue: "Monthly"
    },
    {
        mainValue: "bi-annual",
        displayValue: "Bi-Annual"
    },
    {
        mainValue: "annual",
        displayValue: "Annual"
    },
    {
        mainValue: "quarterly",
        displayValue: "Quarterly"
    }
];

export const OPTIONS_DAYS = [
    {
        mainValue: "Pick a day",
        displayValue: "Pick a day"
    },
    {
        mainValue: "sunday",
        displayValue: "Sunday"
    },
    {
        mainValue: "monday",
        displayValue: "Monday"
    },
    {
        mainValue: "tuesday",
        displayValue: "Tuesday"
    },
    {
        mainValue: "wednesday",
        displayValue: "Wednesday"
    },
    {
        mainValue: "thursday",
        displayValue: "Thursday"
    },
    {
        mainValue: "friday",
        displayValue: "Friday"
    },
    {
        mainValue: "saturday",
        displayValue: "Saturday"
    }
];

export const PAYMENT_TERMS = [
    {
        mainValue: "select",
        displayValue: "select"
    },
    {
        mainValue: "once",
        displayValue: "Once"
    },
    {
        mainValue: "monthly",
        displayValue: "Monthly"
    },
    {
        mainValue: "quarterly",
        displayValue: "Quarterly"
    },
    {
        mainValue: "bi-annual",
        displayValue: "Bi-annual"
    },
    {
        mainValue: "annual",
        displayValue: "Annually"
    }
];

export const APARTMENT_POSITON = [
    {
        mainValue: "select",
        displayValue: "select"
    },
    {
        mainValue: "in-front",
        displayValue: "In-front"
    },
    {
        mainValue: "central",
        displayValue: "Central"
    },
    {
        mainValue: "behind",
        displayValue: "Behind"
    },
    {
        mainValue: "none",
        displayValue: "None"
    }
];

export const METHOD_OF_INVITATION = [
    {
        mainValue: "select",
        displayValue: "select"
    },
    {
        mainValue: "liveizy-id",
        displayValue: "Add by LID"
    },
    {
        mainValue: "bio-data",
        displayValue: "Add by Bio"
    }
];

export const APARTMENT_FLOORS = [
    {
        mainValue: "Select Floor",
        displayValue: "Select Floor"
    },
    {
        mainValue: "Ground Floor",
        displayValue: "Ground Floor"
    },
    {
        mainValue: "1st Floor",
        displayValue: "1st Floor"
    },
    {
        mainValue: "2nd Floor",
        displayValue: "2nd Floor"
    },
    {
        mainValue: "3rd Floor",
        displayValue: "3rd Floor"
    },
    {
        mainValue: "4th Floor",
        displayValue: "4th Floor"
    },
    {
        mainValue: "5th Floor",
        displayValue: "5th Floor"
    },
    {
        mainValue: "6th Floor",
        displayValue: "6th Floor"
    },
    {
        mainValue: "7th Floor",
        displayValue: "7th Floor"
    },
    {
        mainValue: "8th Floor",
        displayValue: "8th Floor"
    },
    {
        mainValue: "9th Floor",
        displayValue: "9th Floor"
    },
    {
        mainValue: "10th Floor",
        displayValue: "10th Floor"
    }
];
