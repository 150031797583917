import { Button } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";

import CurrentResidentDetails from "../UIcomponents/cards/CurrentResidentDetails";
import JobDetails from "../UIcomponents/cards/JobDetails";
import PersonalDetails from "../UIcomponents/cards/PersonalDetails";
import TitleUnderline from "../UIcomponents/others/TitleUnderline";
import ReferenceDetails from "../UIcomponents/cards/ReferenceDetails";

const profilePic =
  "https://images.unsplash.com/photo-1511367461989-f85a21fda167?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8cHJvZmlsZXxlbnwwfHwwfHw%3D&w=1000&q=80";

const ReviewForm = ({ setActiveStep }) => {
  const { values } = useFormikContext();
  const {
    profileImage,
    bvn,
    surname,
    firstName,
    otherNames,
    gender,
    dateOfBirth,
    stateOfOrigin,
    lga,
    email,
    alternateEmail,
    mobileNumber,
    alternateMobileNumber,
    meansOfId,
    meansOfIdFile,
    employmentStatus,
    nameOfEmployer,
    officeAddress,
    monthlyIncome,
    nameOfOfficeContactPerson,
    officeContactPersonNumber,
    homeAddress,
    laid,
    rentRange,
    rentValue,
    nameOfManager,
    contactOfManager,
    nameOfLandlord,
    contactOfLandlord,
    contactOfNextOfKin,
    contactOfreference,
    emailOfNextOfKin,
    nameOfNextOfKin,
    nameOfReference,
    nextOfKinHomeAddress
  } = values;
  return (
    <div style={{ width: "120%" }}>
      {/* Personal Details */}
      <TitleUnderline title="Personal Details" />
      <PersonalDetails
        bvn={bvn}
        profileImage={profileImage}
        surname={surname}
        firstName={firstName}
        otherName={otherNames}
        gender={gender}
        dateOfBirth={dateOfBirth}
        stateOfOrigin={stateOfOrigin}
        lga={lga}
        email={email}
        altEmail={alternateEmail}
        mobile={mobileNumber}
        altMobile={alternateMobileNumber}
        meansOfId={meansOfId}
        meansOfIdFile={meansOfIdFile}
      />
      <Button variant="contained" onClick={() => setActiveStep(0)}>
        Edit
      </Button>
      {/* Job Details */}
      <TitleUnderline title="Job Details" />
      <JobDetails
        employmentStatus={employmentStatus}
        officeContactPersonName={nameOfOfficeContactPerson}
        employerName={nameOfEmployer}
        officeContactPersonNumber={officeContactPersonNumber}
        officeAddress={officeAddress}
        monthlyIncome={monthlyIncome}
      />
      <Button variant="contained" onClick={() => setActiveStep(1)}>
        Edit
      </Button>

      {/* Current Resident Details */}
      <TitleUnderline title="Residential Details" />
      <CurrentResidentDetails
        homeAddress={homeAddress}
        nameOfManager={nameOfManager}
        rentRange={rentRange}
        rentValue={rentValue}
        contactOfManager={contactOfManager}
        nameOfLandlord={nameOfLandlord}
        laid={laid}
        contactOfLandlord={contactOfLandlord}
      />
      <Button variant="contained" onClick={() => setActiveStep(2)}>
        Edit
      </Button>

      {/* Reference Details */}
      <TitleUnderline title="Next of Kin & Reference Details" />
      <ReferenceDetails
        contactOfNextOfKin={contactOfNextOfKin}
        contactOfreference={contactOfreference}
        emailOfNextOfKin={emailOfNextOfKin}
        nameOfNextOfKin={nameOfNextOfKin}
        nameOfReference={nameOfReference}
        nextOfKinHomeAddress={nextOfKinHomeAddress}
      />
      <Button variant="contained" onClick={() => setActiveStep(3)}>
        Edit
      </Button>
    </div>
  );
};

export default ReviewForm;
