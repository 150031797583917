import { makeStyles } from "@material-ui/core/styles";

export const otherInfoStyles = makeStyles((theme) => ({
    root: {
        padding: "50px",
        height: "auto",
        minHeight: "100vh",
        "@media(max-width:999px)": {
            padding: "0px"
        }
    },

    formWrapper: {
        [theme.breakpoints.down("md")]: {
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            borderRadius: "10px",
            padding: "20px"
        }
    },

    inputLabel: {
        color: "#043B5C7F !important",
        textTransform: "uppercase !important",
        fontWeight: "bold !important",
        fontFamily: "Open Sans-bold, sans-serif !important"
    },

    formText: {
        marginBottom: "20px",
        "& p": {
            lineHeight: "1.2",
            width: "95%",
            color: "#06223982",

            [theme.breakpoints.down("md")]: {
                width: "100%"
            }
        }
    },

    header: {
        background: "#EEF4FB",
        borderRadius: "10px",
        marginBottom: "70px",

        [theme.breakpoints.down("md")]: {
            marginBottom: "30px"
        },

        "&  .header__body": {
            display: "flex",
            alignItems: "center",

            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                padding: "40px"
            },
            [theme.breakpoints.up("sm")]: {
                justifyContent: "space-around",
                height: "300px"
            },

            "&__left": {
                [theme.breakpoints.down("sm")]: {
                    order: 1,
                    display: "flex",
                    flexDirection: "column"
                },
                "& p": {
                    color: "#043B5C",
                    fontFamily: "Open Sans-bold, sans-serif",
                    fontWeight: "bold",
                    fontSize: "27px",
                    lineHeight: "1.3em",
                    marginBottom: "25px",
                    width: "200px",

                    [theme.breakpoints.down("sm")]: {
                        fontSize: "18px",
                        textAlign: "center",
                        width: "150px",
                        marginBottom: "15px",
                        fontFamily: "Open Sans-Semibold, sans-serif"
                    }
                }
            },

            "&__right": {
                [theme.breakpoints.down("sm")]: {
                    order: 0,
                    marginBottom: "20px"
                },
                "& img": {
                    borderRadius: "100%",
                    objectFit: "cover"
                }
            }
        }
    },

    btn_upload: {
        background: "#4786FF",
        borderRadius: "5px",
        color: "#fff",
        border: "1px solid #4786FF",
        padding: "10px 30px",
        fontSize: "11px",
        fontFamily: "Open Sans-Semibold, sans-serif",

        "&:hover": {
            background: "#A61106",
            border: "1px solid #A61106"
        }
    },
    btn_save: {
        background: "#4786FF",
        borderRadius: "5px",
        color: "#F4F5F7",
        border: "1px solid #4786FF",
        padding: "10px 30px",
        fontSize: "12px",
        fontFamily: "Open Sans-Semibold, sans-serif",
        width: "145px",

        "&:hover": {
            background: "#4786FF"
        },

        [theme.breakpoints.down("md")]: {
            width: "100%"
        }
    },

    forms: {
        display: "grid",
        gridTemplateColumns: "repeat( auto-fit, minmax(240px, 1fr) )",
        gridGap: "0px 10px",

        [theme.breakpoints.between("0", "992")]: {
            // padding: "25px 20px"
        }
    }
}));
