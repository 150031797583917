import { removeNonNumeric } from "#/shared/utils/charformat/digitFormart";

const { useState, useEffect } = require("react");

const RENTDESCRIPTION = "NR";

// This is hook for rental_cart_payment

const useRentalPaymentCart = (itemList) => {
  const [rentalPaymentCart, setRentalPaymentCart] = useState([]);
  const [partRent, setPartRent] = useState(0);

  const partRentNumberValue = Number(removeNonNumeric(partRent));

  const totalAmount = rentalPaymentCart.reduce((accumulator, currentBill) => {
    if (currentBill.description === RENTDESCRIPTION && partRentNumberValue > 0) {
      return partRentNumberValue + accumulator;
    }
    return accumulator + Number(currentBill.amount_pending);
  }, 0);

  const handleSelectItem = (item) => {
    if (!item._id) return;
    const itemExists = rentalPaymentCart.find((cartItem) => cartItem._id === item._id);
    if (itemExists) {
      const newrentalPaymentCart = rentalPaymentCart.filter(
        (cartItem) => cartItem._id !== itemExists._id
      );
      setRentalPaymentCart(newrentalPaymentCart);
    } else {
      setRentalPaymentCart((prevCart) => [...prevCart, { ...item, selected: true }]);
    }
  };

  useEffect(() => {
    if (itemList) {
      itemList?.forEach((item) => {
        if (item.selected) {
          setRentalPaymentCart((prevCart) => [...prevCart, item]);
        }
      });
    }
  }, []);
  return {
    rentalPaymentCart,
    handleSelectItem,
    partRent,
    setPartRent,
    totalAmount,
    partRentNumberValue
  };
};

export default useRentalPaymentCart;
