import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import DetailPopOver from "./DetailPopOver";

const useRowStyles = makeStyles((theme) => ({
    root: {
        cursor: "pointer",
        backgroundColor: "#d2e4f7",
        borderBottom: "3px solid #fff",
        "& > *": {
            borderBottom: "unset"
        },
        "& .icon-text-container": {
            display: "flex",
            "& .icon-expand": {
                paddingRight: 3
            }
        },
        "& .th-container-text": {
            fontSize: 11,
            color: "#032F4A"
        },
        "& .MuiTableCell-alignRight": {
            textAlign: "right !important"
        }
    },
    tbody: {
        "& .collaspe-tr": {
            borderBottom: "4px solid #d2e4f7 !important"
        },
        "& .collaspe-text": {
            fontSize: 11,
            color: "#0f224a",
            borderBottom: "0 !important"
        },
        "& .status": {
            borderRadius: 10,
            color: "#fff",
            width: 70,
            padding: "6px 3px !important"
        },
        "& .status-paid": {
            backgroundColor: "#2adbc6"
        },
        "& .not-paid": {
            backgroundColor: "#db3535"
        },
        "& #detail-icon": {
            cursor: "pointer"
        },
        "& .table-pad": {
            padding: "6px !important"
        }
    }
}));

const Row = (props) => {
    const { row } = props;
    const classes = useRowStyles();
    const [open, setOpen] = React.useState(false);
    const [popId, setPopId] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handlePopoverOpen = (event) => {
        // console.log(event.currentTarget.id)
        setPopId(event.currentTarget.id);
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setPopId(null);
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <TableRow className={classes.root} onClick={() => setOpen(!open)}>
                <TableCell id="container" component="th" scope="row">
                    <div className="icon-text-container">
                        <IconButton className="icon-expand" aria-label="expand row" size="small">
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                        <div>
                            <span style={{ color: "#032F4A" }}>
                                <b>{row.propertyName}</b>
                            </span>
                            <span className="th-container-text" style={{ display: "block" }}>
                                ({row.details})
                            </span>
                        </div>
                    </div>
                </TableCell>
                <TableCell className="th-container-text" align="right" colSpan={2}>
                    &#x20A6; {row.amount}
                </TableCell>
                <TableCell className="th-container-text" align="right">
                    &#x20A6; {row.paid}
                </TableCell>
                <TableCell className="th-container-text balance" align="right">
                    &#x20A6; {row.balance}
                </TableCell>
                <TableCell style={{ paddingRight: "10px" }} />
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Property name</TableCell>
                                        <TableCell align="center">Due on</TableCell>
                                        <TableCell align="center">Paid on</TableCell>
                                        <TableCell align="center">ID</TableCell>
                                        <TableCell align="center">Status</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        <TableCell align="center">Paid</TableCell>
                                        <TableCell className="balance" align="center">
                                            Balance
                                        </TableCell>
                                        <TableCell style={{ padding: "12px 6px" }} />
                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.tbody}>
                                    {row.incomeDetails.map((incomeRow) => (
                                        <TableRow key={incomeRow.id} className="collaspe-tr">
                                            <Link to="/income/invoice">
                                                <TableCell component="th" scope="row" className="collaspe-text">
                                                    {incomeRow.propertyName}
                                                </TableCell>
                                            </Link>
                                            <TableCell className="collaspe-text table-pad" align="center">
                                                {incomeRow.dueOn}
                                            </TableCell>
                                            <TableCell className="collaspe-text table-pad paid_on" align="center">
                                                {incomeRow.paidOn}
                                            </TableCell>
                                            <TableCell className="collaspe-text table-pad income_id" align="center">
                                                {incomeRow.id}
                                            </TableCell>
                                            <Box marginTop={2} marginBottom={2}>
                                                <TableCell
                                                    className={
                                                        incomeRow.status === "Paid" ||
                                                        incomeRow.status === "Partially Paid"
                                                            ? "collaspe-text status-paid status"
                                                            : "collaspe-text not-paid status"
                                                    }
                                                    align="center">
                                                    {incomeRow.status}
                                                </TableCell>
                                            </Box>
                                            <TableCell className="collaspe-text table-pad" align="center">
                                                &#x20A6; {incomeRow.amount}
                                            </TableCell>
                                            <TableCell className="collaspe-text table-pad" align="center">
                                                &#x20A6; {incomeRow.paid}
                                            </TableCell>
                                            <TableCell className="collaspe-text table-pad" align="center">
                                                &#x20A6; {incomeRow.balance}
                                            </TableCell>
                                            <TableCell id="detail-icon">
                                                <NavigateNextIcon
                                                    style={{ color: "#0f224a" }}
                                                    id={incomeRow.id}
                                                    className="table-pad"
                                                    onClick={handlePopoverOpen}
                                                />
                                                <DetailPopOver
                                                    onClose={handlePopoverClose}
                                                    popID={popId}
                                                    anchorE={anchorEl}
                                                    content={incomeRow.content}
                                                    id={incomeRow.id}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

export default Row;
