import { MenuItem, TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";
import React from "react";

const SelectField = ({ name, options, ...otherProps }) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);

    const handleChange = (event) => {
        const { value } = event.target;
        setFieldValue(name, value);
    };

    const configSelectField = {
        ...field,
        ...otherProps,
        select: true,
        fullWidth: true,
        variant: "outlined",
        onChange: handleChange
    };

    // check for error or touched
    if (meta && meta.touched && meta.error) {
        configSelectField.error = true;
        configSelectField.helperText = meta.error;
    }

    return (
        <TextField {...configSelectField} size="small">
            <MenuItem disabled value="">
                <em>Select an Option</em>
            </MenuItem>
            {options.map((option, index) => {
                return (
                    <MenuItem key={index} value={option.value}>
                        {option.label}
                    </MenuItem>
                );
            })}
        </TextField>
    );
};

export default SelectField;
