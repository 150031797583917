export const OPTIONS_GENDER = [
    {
        value: "male",
        label: "Male"
    },
    {
        value: "female",
        label: "Female"
    }
];

export const meansOfIdOptions = [
    {
        value: "National Identification Number (NIN)",
        label: "National Identification Number (NIN)"
    },
    {
        value: "Voter's Identification Number",
        label: "Voter's Identification Number"
    },
    {
        value: "International Passport",
        label: "International Passport"
    }
];
