import axios from "axios";
import * as actionTypes from "../constants";
import { alertError, showNotifications } from "./notification.actions";

const config = {
  baseURL: process.env.REACT_APP_WALLETSERVICE,
  headers: {
    "X-Requested-With": "XMLHttpRequest"
  }
};

export const getCandidates = () => (dispatch) => {
  dispatch({ type: actionTypes.referralConstant.GET_CANDIDATES_PENDING });
  axios
    .get("/user/referral/candidates", config)
    .then((response) => {
      dispatch({
        type: actionTypes.referralConstant.GET_CANDIDATES_SUCCESS,
        payload: response.data.data
      });
    })
    .catch((error) => {
      dispatch({ type: actionTypes.referralConstant.GET_CANDIDATES_FAILURE, error });
    });
};

export const getReferral = () => (dispatch) => {
  dispatch({ type: actionTypes.referralConstant.GET_MY_REFERRAL_PENDING });
  axios
    .get("/user/referral", config)
    .then((response) => {
      const { data } = response.data;
      dispatch({
        type: actionTypes.referralConstant.GET_MY_REFERRAL_SUCCESS,
        payload: data.referral
      });
    })
    .catch((error) => {
      dispatch({ type: actionTypes.referralConstant.GET_MY_REFERRAL_FAILURE, error });
    });
};

export const getBalance = () => (dispatch) => {
  dispatch({ type: actionTypes.referralConstant.GET_REFERRAL_BALANCE_PENDING });
  axios
    .get("/user/referral/balance", config)
    .then((response) => {
      const { data } = response.data;
      dispatch({
        type: actionTypes.referralConstant.GET_REFERRAL_BALANCE_SUCCESS,
        payload: data.balance
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.referralConstant.GET_REFERRAL_BALANCE_FAILURE,
        error
      });
    });
};

export const getAccountDetails = () => (dispatch) => {
  // config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  dispatch({ type: actionTypes.referralConstant.GET_ACCOUNT_DETAILS_PENDING });
  axios
    .get("/user/referral/account/details", config)
    .then((response) => {
      const { data } = response.data;
      dispatch({
        type: actionTypes.referralConstant.GET_ACCOUNT_DETAILS_SUCCESS,
        payload: data.details
      });
    })
    .catch((error) => {
      dispatch({ type: actionTypes.referralConstant.GET_ACCOUNT_DETAILS_FAILURE, error });
    });
};

export const sendInvitation = (data, cb) => (dispatch) => {
  dispatch({ type: actionTypes.referralConstant.SEND_INVITATION_PENDING });
  axios
    .post("/user/referral/send-invitation", data, config)
    .then((response) => {
      dispatch({
        type: actionTypes.referralConstant.SEND_INVITATION_SUCCESS,
        payload: response.data.data,
        message: response.data.message
      });
      dispatch(showNotifications(response.data.message, "success"));
      cb();
    })
    .catch((error) => {
      dispatch({ type: actionTypes.referralConstant.SEND_INVITATION_FAILURE, error });
      if (error.response?.data?.errors) {
        dispatch(showNotifications(error.response?.data?.errors?.email[0], "error"));
      } else if (error.response?.data?.data) {
        dispatch(showNotifications(error.response?.data?.data[0].email, "error"));
      } else if (error.response?.data?.message) {
        dispatch(showNotifications(error.response?.data?.message, "error"));
      }
    });
};

export const updateAccountDetails = (data, cb) => (dispatch) => {
  dispatch({ type: actionTypes.referralConstant.UPDATE_ACCOUNT_DETAILS_PENDING });

  axios
    .post("/user/referral/account/update", data, config)
    .then((response) => {
      dispatch({
        type: actionTypes.referralConstant.UPDATE_ACCOUNT_DETAILS_SUCCESS,
        payload: response.data.data.details,
        message: response.data.message
      });
      dispatch(showNotifications(response.data.message, "success"));
      cb();
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.referralConstant.UPDATE_ACCOUNT_DETAILS_FAILURE,
        error
      });
      if (error.response?.data?.errors?.account_number) {
        dispatch(
          showNotifications(error.response?.data?.errors?.account_number[0], "error")
        );
      } else if (error.response?.data?.errors?.account_name) {
        dispatch(
          showNotifications(error.response?.data?.errors?.account_name[0], "error")
        );
      } else if (error.response?.data?.message) {
        dispatch(showNotifications(error.response?.data?.message, "error"));
      }
    });
};
