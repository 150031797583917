import React, { memo, useRef } from "react";
import Carousel from "react-material-ui-carousel";

const ImageSlider = ({ classes, images }) => {
    const nodeRef = useRef();
    if (images.length === 0) {
        return <div className={classes.imageContainer}>No Images</div>;
    }
    return (
        <div className={classes.sliderContainer}>
            <div>
                <Carousel autoPlay={true} nodeRef={nodeRef} navButtonsAlwaysVisible={true} indicators={false}>
                    {images.map((item, i) => (
                        <div
                            className={classes.imageContainer}
                            style={{ backgroundImage: `url(${item || ""})` }}
                            key={i}
                            ref={nodeRef}></div>
                    ))}
                </Carousel>
            </div>
        </div>
    );
};

export default memo(ImageSlider);
