/* eslint-disable no-useless-constructor */
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import MetisMenu from "@metismenu/react";
// import "metismenujs/dist/metismenujs.css";
// images
import Logo from "#/assets/liveizylogos.png";
import LogoSmall from "#/assets/liveizy-logo-collasped.png";
import PersonOutlineSharpIcon from "@material-ui/icons/PersonOutlineSharp";
import { Avatar } from "@material-ui/core";

import Powericon from "#/assets/other images/kindpng_896875.png";
// import { generalSidebar, propertyManagerSidebar } from "./data";

import clsx from "clsx";
import { logout } from "#/store/actions/auth.actions";

import "./sidebar.styles.scss";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { setToLocalStorage, getFromLocalStorage } from "../utils/util";
import ImageLoader from "../ui-components/image-component";
import { withRouter } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import avatar from "#/assets/images/tenant/default-avatar.png";

import { GeneralSidebar, PropertyManagerSidebar } from "./sidebar-links";
import useMobile from "#/hooks/util-hooks/useMobile";
import { useLocation } from "react-router-dom";
import useWindowSize from "react-use/lib/useWindowSize";

const Sidebar = (props) => {
  const activeSide = getFromLocalStorage("active");
  const [active, setActive] = useState(
    () => getFromLocalStorage("active") || "DASHBOARD"
  );
  const { pathname } = useLocation();
  const sideRef = React.useRef();
  const { isMobile } = useMobile();
  const windowSize = useWindowSize();

  const logout = () => {
    const { logout } = props;
    logout(null, () => null);
  };

  const ActiveToggle = (data) => {
    // setActive(data);
    setToLocalStorage("active", data);
  };

  const sampleContainer = {
    maxHeight: "440px"
  };

  let Display = <GeneralSidebar ActiveToggle={ActiveToggle} />;

  //SideBar Links for Property Manager
  const { roles: role } = props.user;
  if (role !== undefined && role?.includes("Landlord")) {
    Display = <PropertyManagerSidebar ActiveToggle={ActiveToggle} />;
  }

  if ((role !== undefined && role?.includes("Agent")) || role?.includes("Landlord")) {
    Display = <PropertyManagerSidebar ActiveToggle={ActiveToggle} />;
  }

  const { first_name, last_name, liveizy_id, profile_image } = props.user;

  //---------------------------------------------------------------
  //This is to close the sidbar on mobile screen.
  //---------------------------------------------------------------
  React.useEffect(() => {
    function handler(event) {
      if (props.mobile === false) return;
      if (isMobile || (windowSize.width < 969 && props.mobile)) {
        console.log("Here are the props of mobile", props.mobile);
        if (!sideRef.current?.contains(event.target)) {
          //props.setMobileToggle(false); This blocks the opening of the sidebar on mobile
        }
      }
    }
    window.addEventListener("click", handler);

    //clean up
  }, [isMobile, props.mobile]);

  React.useEffect(() => {
    if (isMobile || windowSize.width < 969) {
      props.setMobileToggle(false);
    }
  }, [pathname]);

  return (
    // the zIndex bellow was set bcos of the zIndex set on the card wrapping tenants apartment
    <div id="side" style={{ zIndex: "11" }} ref={sideRef}>
      {/* Sidebar  */}
      <nav
        className={clsx("sidebar", {
          active: props.mobile === true
        })}>
        <div className="sidebar-header">
          <div className="text-center">
            <ImageLoader
              src={Logo}
              alt="liveizy_logo"
              className="logo logo-lg"
              style={{ maxWidth: "110px" }}
            />
            <ImageLoader
              src={LogoSmall}
              alt="liveizy_logo"
              className="logo logo-sm"
              style={{ maxWidth: "30px" }}
            />
          </div>
        </div>

        <div className="img-contain">
          {profile_image == null ? (
            <img src={avatar} alt="user" />
          ) : (
            <img
              src={profile_image}
              alt="tenants"
              className="rounded-full w-48 h-24 overflow-hidden text-gray-400  rounded-xl"
            />
          )}{" "}
        </div>
        <div className="user-info">
          <p className="text-center mt-10">
            {" "}
            LID - <span className="user-id text-center">{liveizy_id}</span>{" "}
          </p>
          <p className="text-center" style={{ marginBottom: "5px" }}>
            {" "}
            {`${first_name} ${last_name}`}
          </p>
        </div>

        {/**SideBar routes */}
        <div
          style={sampleContainer}
          options={{ suppressScrollX: true, wheelPropagation: false }}>
          <div className="md:pl-5 md:pr-2">
            <MetisMenu>{Display}</MetisMenu>
          </div>

          {/**SideBar routes */}
          {/* <button
                        className=" signout-button"
                        type="button"
                        onClick={logout}
                        style={{ marginTop: "10px", marginBottom: "50px" }}>
                        <img src={Powericon} alt="dashboard icon" className="sidebar-icon" />
                        <span> SIGN OUT </span>
                    </button> */}
        </div>
      </nav>

      {/* Page Content  */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  logout2: state.auth.logout,
  user: state.auth.user,
  role: state.auth.role
});
export default connect(mapStateToProps, { logout })(withRouter(Sidebar));
