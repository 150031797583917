import { propertyServiceApi, returnUserData } from "#/apis/propertyServiceApi";
import { alertError } from "#/store/actions/notification.actions";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import JobDetails from "./jobDetails";
import PersonalDetails from "./personalDetails";
import ResidentialDetails from "./residentialDetails";
import PageLoader from "../../components/ui-components/pageLoader";
import SuccessModal from "./successModal";
import { useParams } from "react-router-dom";

const initialValues = {
    photo: { value: "", validation: true, error: "" },
    first_name: { value: "", validation: true, error: "" },
    last_name: { value: "", validation: true, error: "" },
    other_names: { value: "", validation: true, error: "" },
    email: { value: "", validation: true, error: "" },
    state_of_origin: { value: "", validation: true, error: "" },
    date_of_birth: { value: "", validation: true, error: "" },
    employment_status: { value: "", validation: true, error: "" },
    monthly_income: { value: "", validation: true, error: "" },
    employer_name: { value: "", validation: true, error: "" },
    office_address: { value: "", validation: true, error: "" },
    contact_person_name: { value: "", validation: true, error: "" },
    contact_person_number: { value: "", validation: true, error: "" },
    current_address: { value: "", validation: true, error: "" },
    current_rent_value: { value: "", validation: true, error: "" },
    name_of_landlord: { value: "", validation: true, error: "" },
    phone_of_landlord: { value: "", validation: true, error: "" },
    name_of_pm: { value: "", validation: true, error: "" },
    phone_of_pm: { value: "", validation: true, error: "" }
};

const ShowOfInterest = () => {
    const { liveizy_id } = returnUserData();
    const { bookingId } = useParams();
    const dispatch = useDispatch();

    const setPrefilledValues = (data) => {
        setFormValues({
            photo: { value: data.photo || "", validation: true, error: "" },
            first_name: { value: data.first_name || "", validation: true, error: "" },
            last_name: { value: data.last_name || "", validation: true, error: "" },
            other_names: { value: data.other_names || "", validation: true, error: "" },
            email: { value: data.email || "", validation: true, error: "" },
            state_of_origin: { value: data.state_of_origin || "", validation: true, error: "" },
            date_of_birth: { value: data.date_of_birth.split("T")[0] || "", validation: true, error: "" },
            employment_status: { value: data.employment_status || "", validation: true, error: "" },
            monthly_income: { value: data.monthly_income || "", validation: true, error: "" },
            employer_name: { value: data.employer_name || "", validation: true, error: "" },
            office_address: { value: data.office_address || "", validation: true, error: "" },
            contact_person_name: { value: data.contact_person_name || "", validation: true, error: "" },
            contact_person_number: { value: data.contact_person_number || "", validation: true, error: "" },
            current_address: { value: data.current_address || "", validation: true, error: "" },
            current_rent_value: { value: data.current_rent_value || "", validation: true, error: "" },
            name_of_landlord: { value: data.name_of_landlord || "", validation: true, error: "" },
            phone_of_landlord: { value: data.phone_of_landlord || "", validation: true, error: "" },
            name_of_pm: { value: data.name_of_pm || "", validation: true, error: "" },
            phone_of_pm: { value: data.phone_of_pm || "", validation: true, error: "" }
        });
    };

    const [formValues, setFormValues] = useState(initialValues);
    const [inDisplay, setInDisplay] = useState(1);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        fetchShowOfInterest();
    }, []);

    const fetchShowOfInterest = () => {
        propertyServiceApi()
            .get("/tenant-profile", { params: { liveizy_id } })
            .then((data) => {
                const response = data.data?.payload[0];
                if (response) setPrefilledValues(response);
            })
            .catch(({ response, message }) => {
                // console.log("dfasfd");
                if (response.status === 404) return;
                dispatch(alertError(response?.data.error || message));
            });
    };

    const submitInterest = () => {
        const form = new FormData();

        form.append("booking_id", bookingId);
        if (formValues.photo.value instanceof File) form.append("photo", formValues.photo.value);
        else form.append("photo_url", formValues.photo.value);
        form.append("first_name", formValues.first_name.value);
        form.append("last_name", formValues.last_name.value);
        form.append("other_names", formValues.other_names.value);
        form.append("email", formValues.email.value);
        form.append("state_of_origin", formValues.state_of_origin.value);
        form.append("date_of_birth", formValues.date_of_birth.value);
        form.append("employment_status", formValues.employment_status.value);
        form.append("monthly_income", formValues.monthly_income.value);
        form.append("employer_name", formValues.employer_name.value);
        form.append("office_address", formValues.office_address.value);
        form.append("contact_person_name", formValues.contact_person_name.value);
        form.append("contact_person_number", formValues.contact_person_number.value);
        form.append("current_address", formValues.current_address.value);
        form.append("current_rent_value", formValues.current_rent_value.value);
        form.append("name_of_landlord", formValues.name_of_landlord.value);
        form.append("phone_of_landlord", formValues.phone_of_landlord.value);
        form.append("name_of_pm", formValues.name_of_pm.value);
        form.append("phone_of_pm", formValues.phone_of_pm.value);

        setSubmitLoading(true);
        propertyServiceApi()
            .post("/tenant-profile/show-of-interest", form)
            .then((data) => {
                setSuccess(true);
            })
            .catch(({ response, message }) => {
                dispatch(alertError(response?.data.error || message));
            })
            .finally(() => {
                setSubmitLoading(false);
            });
    };

    return (
        <div>
            {submitLoading && <PageLoader />}
            <SuccessModal open={success} setOpen={setSuccess} />
            {inDisplay === 1 && (
                <PersonalDetails setInDisplay={setInDisplay} formValues={formValues} setFormValues={setFormValues} />
            )}
            {inDisplay === 2 && (
                <JobDetails setInDisplay={setInDisplay} formValues={formValues} setFormValues={setFormValues} />
            )}
            {inDisplay === 3 && (
                <ResidentialDetails
                    setInDisplay={setInDisplay}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    submitInterest={submitInterest}
                />
            )}
        </div>
    );
};

export default ShowOfInterest;
