import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import { RiDeleteBin5Fill } from "react-icons/ri";
import PageTitle from "#/components/estateManagementComponents/page-title";
import BreadCrumbs from "#/components/estateManagementComponents/bread-crumbs";

const breadcrumbLinks = [
    { title: "dashboard", href: "/estate-management/dashboard" },
    { title: "summary", href: "/estate-management/summary", currentPage: true }
];

const Summary = (props) => {
    const classes = useStyles();

    const [formData, setFormData] = useState({
        title: "",
        description: "",
        date: "",
        time: ""
    });

    const [checkedState, setCheckedState] = useState(new Array(9).fill(false));

    const handleTitleChange = (event) => {
        setFormData({ ...formData, title: event.target.value });
    };

    const handleDateChange = (event) => {
        setFormData({ ...formData, title: event.target.value });
    };

    const handleTimeChange = (event) => {
        setFormData({ ...formData, title: event.target.value });
    };

    const handleDescriptionChange = (event) => {
        setFormData({ ...formData, description: event.target.value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    const handleOnChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) => (index === position ? !item : item));

        setCheckedState(updatedCheckedState);
    };

    return (
        <div className={classes.root}>
            <BreadCrumbs links={breadcrumbLinks} />
            <PageTitle title="summary" />
            <div className="members-description">
                <p>Landlord and Tenant Association Gbagada Phase 1</p>
            </div>
            <div className="member-info">
                <Card className="member-count">
                    <p>
                        <span>Total Member</span>
                        <span>54</span>
                    </p>
                    <p>
                        <span>Total Landlord</span>
                        <span>14</span>
                    </p>
                    <p>
                        <span>Total Tenants</span>
                        <span>40</span>
                    </p>
                </Card>
                <Card className="member-payment">
                    <p>
                        <span>Total Payable</span>
                        <span>Total Paid</span>
                    </p>
                </Card>
            </div>
            <form onSubmit={handleSubmit} className={classes.notificationForm}>
                <Card className="meeting-details">
                    <label className="title">
                        <span>Title</span>
                        <input type="text" value={formData.title} onChange={handleTitleChange} />
                    </label>
                    <input type="textarea" value={formData.description} onChange={handleDescriptionChange} />
                    <label className="meeting-schedule">
                        <input
                            type="radio"
                            value="Add Meeting Time"
                            checked={checkedState[0]}
                            onChange={() => handleOnChange(0)}
                        />
                        Add Meeting Time
                    </label>
                    <div className="date-time-setting">
                        <label>
                            <span>Select date</span>
                            <input type="text" value={formData.date} onChange={handleDateChange} />
                        </label>
                        <label>
                            <span>Select time</span>
                            <input type="text" value={formData.time} onChange={handleTimeChange} />
                        </label>
                    </div>
                    <label className="reminder-button">
                        <input
                            type="radio"
                            value="Set reminder"
                            checked={checkedState[1]}
                            onChange={() => handleOnChange(1)}
                        />
                        Set reminder <span className="reminder">(only 2 reminders max.)</span>
                    </label>
                    <div className="radio-section">
                        <label>
                            <input
                                type="radio"
                                value="24hrs before"
                                checked={checkedState[2]}
                                onChange={() => handleOnChange(2)}
                            />
                            24hrs before
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="2 day before"
                                checked={checkedState[3]}
                                onChange={() => handleOnChange(3)}
                            />
                            2 day before
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="60min before"
                                checked={checkedState[4]}
                                onChange={() => handleOnChange(4)}
                            />
                            60min before
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="Send to All"
                                checked={checkedState[5]}
                                onChange={() => handleOnChange(5)}
                            />
                            Send to All
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="Send to Exco"
                                checked={checkedState[6]}
                                onChange={() => handleOnChange(6)}
                            />
                            Send to Exco
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="Send to Landlord"
                                checked={checkedState[7]}
                                onChange={() => handleOnChange(7)}
                            />
                            Send to Landlord
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="Send to Tenant"
                                checked={checkedState[8]}
                                onChange={() => handleOnChange(8)}
                            />
                            Send to Tenant
                        </label>
                    </div>
                </Card>
                <input type="submit" value="Send" className="submit-btn" />
            </form>
            <h3 className="notification-section">Notification Sent</h3>
            <hr />
            <div className={classes.memberNotifcation}>
                <Card className="notifcation">
                    <p className="notifcation-title">
                        <span>Emergency meeting</span>
                        <span>12/10/2021, 12:30 P.M.</span>
                        <span>
                            <RiDeleteBin5Fill className="member_delete_icon" />
                        </span>
                    </p>
                    <p className="notifcation-message">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas fringilla, lectus ac porta
                        vestibulum, justo orci interdum turpis, id pellentesque risus diam in purus. Donec risus urna,
                        vulputate nec aliquam quis, cursus et tellus. Sed ullamcorper massa felis, sit amet mollis nibh
                        venenatis vitae. Morbi augue justo, molestie nec dignissim vel, gravida non massa.
                    </p>
                    <Link className="see-more-link" to={"http://#"} style={{ color: "blue" }}>
                        See more...
                    </Link>
                </Card>
            </div>
        </div>
    );
};

export default Summary;

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        padding: "0 5%",
        marginTop: 20,

        "& div": {
            width: "100%"
        },

        "& .notification-section": {
            fontSize: "20px",
            fontWeight: 800,
            marginTop: "20px",
            alignSelf: "start",
            marginBottom: 0
        },

        "& hr": {
            height: 1,
            width: "100%",
            backgroundColor: "#8c8b8b",
            border: "none",
            marginTop: 10
        },

        "& .back-arrow": {
            display: "flex",
            alignItems: "center",
            marginBottom: "30px",
            width: "100%",

            "& .back-arrow-icon": {
                fontSize: "24px"
            },

            "& .back-arrow-text": {
                fontSize: "18px",
                fontWeight: 600,
                marginLeft: "18px",
                marginRight: "310px"
            }
        },

        "& .members-description": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "25px 12px",
            backgroundColor: "#0961DB",
            marginBottom: "10px",
            width: "100%",

            "& p": {
                marginTop: "5px",
                fontSize: "18px",
                color: "#fff"
            }
        },

        "& .member-info": {
            backgroundColor: "#fff",
            borderRadius: "6px",
            padding: "10px 20px",
            width: "100%",

            "& .member-count": {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                backgroundColor: "#0961DB",
                borderRadius: "6px",
                padding: "10px 15px",
                marginBottom: "12px",

                "& p > span": {
                    display: "block",
                    color: "#fff",
                    fontSize: "16px",
                    textAlign: "center"
                },

                "& p": {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center"
                }
            },

            "& .member-payment": {
                backgroundColor: "#FA0912",
                borderRadius: "6px",
                padding: "10px 15px",
                marginBottom: "12px",

                "& p > span": {
                    display: "block",
                    color: "#fff",
                    fontSize: "16px"
                },

                "& p": {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center"
                }
            }
        }
    },

    notificationForm: {
        display: "flex",
        flexDirection: "column",
        marginTop: "15px",
        width: "100%",
        marginBottom: "40px",

        "& .submit-btn": {
            alignSelf: "end",
            borderRadius: 6,
            border: "none",
            backgroundColor: "#2b74d4",
            color: "#fff",
            outline: "none",
            fontWeight: 800,
            padding: "10px 50px",
            [theme.breakpoints.up("400")]: {
                marginTop: 0
            }
        },

        "& .meeting-details": {
            display: "flex",
            flexDirection: "column",
            borderRadius: "6px",
            width: "100%",
            marginBottom: "10px",
            padding: "13px 15px",

            "& label > input, label > span, input": {
                display: "block"
            },

            "& .title": {
                marginBottom: "8px",

                "& span": {
                    fontSize: "18px",
                    fontWeight: 600
                },

                "& input": {
                    marginTop: "5px"
                }
            },

            "& input[type=text]": {
                height: "40px"
            },

            "& input[type=textarea]": {
                height: "100px"
            },

            "& input[type=text], input[type=textarea]": {
                width: "100%",
                padding: "8px 16px",
                borderRadius: "6px",
                lineHeight: "25px",
                fontSize: 14,
                fontWeight: 500,
                border: "1px solid #CDD9ED",
                transition: "border .3s ease",
                "&::placeholder": {
                    color: "#CBD1DC"
                },
                "&:focus": {
                    outline: "none",
                    borderColor: "#275EFE"
                }
            },

            "& .meeting-schedule": {
                margin: "10px 0px",
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: 600,

                "& input": {
                    marginRight: "8px",
                    transform: "scale(1.2)"
                }
            },

            "& .date-time-setting": {
                display: "flex",
                justifyContent: "space-between",

                "& span": {
                    fontSize: "16px"
                },

                "& input": {
                    marginTop: "5px"
                }
            },

            "& .reminder-button": {
                margin: "10px 0px",
                fontSize: "16px",
                fontWeight: 600,
                display: "flex",
                alignItems: "center",

                "& input": {
                    marginRight: "10px",
                    transform: "scale(1.2)"
                },

                "& .reminder": {
                    fontSize: "14px",
                    fontStyle: "italic",
                    marginLeft: "4px"
                }
            },

            "& .radio-section": {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",

                "& label": {
                    display: "flex",
                    alignItems: "center",
                    width: "145px",
                    margin: "5px 0px",
                    fontSize: "16px"
                },

                "& input": {
                    marginRight: "10px",
                    transform: "scale(1.2)"
                }
            }
        }
    },

    memberNotifcation: {
        "& .notifcation": {
            padding: "10px",
            marginBottom: "40px"
        },

        "& .notifcation-title": {
            display: "flex",
            justifyContent: "space-between",

            "& span": {
                fontSize: "18px",
                fontWeight: 600
            }
        },

        "& .notifcation-message": {
            fontSize: "14px",
            marginTop: "10px",
            textAlign: "justify"
        },

        "& .see-more-link": {
            marginLeft: 350,
            fontWeight: 700
        }
    }
}));
