import { keyframes, styled } from "@mui/material";
import React from "react";

const loadingAnimation = keyframes`
    0%{
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
`;

const SkeletonText = styled("div")`
  background: rgba(130, 130, 130, 0.2);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, rgba(130, 130, 130, 0.2)),
    color-stop(18%, rgba(130, 130, 130, 0.3)),
    color-stop(33%, rgba(130, 130, 130, 0.2))
  );
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.2) 8%,
    rgba(130, 130, 130, 0.3) 18%,
    rgba(130, 130, 130, 0.2) 33%
  );
  background-size: 800px 100px;
  animation: ${loadingAnimation} 1.5s infinite ease-out;
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "1rem")};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0)};
`;

const SkeletonTypography = ({ width, height, marginBottom, isLoading, children }) => {
  if (isLoading) {
    return <SkeletonText width={width} height={height} marginBottom={marginBottom} />;
  }

  return <>{children}</>;
};

export default SkeletonTypography;
