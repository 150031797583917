import { copyTextToClipboard } from "#/shared/utils";
import { alertSuccess } from "#/store/actions/notification.actions";
import React from "react";
import { FaRegCopy } from "react-icons/fa";
import { useDispatch } from "react-redux";

const CopyToClipboardBtn = ({ text, alertMessage }) => {
  const dispatch = useDispatch();
  const alertCopied = () => dispatch(alertSuccess(alertMessage));
  return (
    <button
      onClick={() => copyTextToClipboard(text, alertCopied)}
      style={{
        cursor: "pointer",
        background: "transparent",
        padding: "5px",
        borderRadius: "50%"
      }}>
      <FaRegCopy />
    </button>
  );
};

export default CopyToClipboardBtn;
