import DetailsCardContainer, {
  DetailsContainer
} from "#/components/cards/DetailsCardContainer.component";
import SubtitleAndSubtext from "#/components/ui-components/SubtitleAndSubtext.component";
import { Button, CircularProgress, Grid, Stack } from "@mui/material";
import React, { useState } from "react";
import SelectField from "#/components/formik/formfields/SelectField";
import { employmentStatusModel } from "./formModel";
import FormikCustomInputField from "#/components/formik/formfields/FormikCustomInputField";
import { Form, Formik } from "formik";
import { employmentStatusInitialValues } from "./formInitialValues";
import { employmentStatusSchema } from "./validationSchema";
import { useDispatch, useSelector } from "react-redux";
import { otherInfoStyles } from "./otherInfoStyle";
import { employmentStatusSubmit } from "./utils";
import { toCurrency } from "#/components/utils/util";
import FormikCustomSelectField from "#/components/formik/formfields/FormikCustomSelectField";

const {
  formField: {
    employmentStatus,
    nameOfEmployer,
    officeAddress,
    monthlyIncome,
    nameOfOfficeContactPerson,
    officeContactPersonNumber
  }
} = employmentStatusModel;

const employmentOptions = [
  {
    value: "Employed",
    label: "Employed"
  },
  {
    value: "Self-Employed",
    label: "Self-Employed"
  }
];

const btnStyle = {
  background: "#4786FF",
  borderRadius: "5px",
  color: "#F4F5F7",
  border: "1px solid #4786FF",
  padding: "10px 30px",
  fontSize: "12px",
  width: "145px",

  "&:hover": {
    background: "#4786FF"
  }
};

const EmploymentStatus = () => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const { otherInformation, updatingOtherInfo } = useSelector((state) => state.auth);

  // Formik
  const initialValues = employmentStatusInitialValues(otherInformation);

  const handleFormSubmit = async (values, actions) => {
    if (updatingOtherInfo) return;
    await employmentStatusSubmit(values, dispatch, setEditMode);
    actions.setSubmitting(false);
  };
  const classes = otherInfoStyles();
  return (
    <>
      {!editMode ? (
        <>
          <DetailsCardContainer>
            <DetailsContainer>
              <SubtitleAndSubtext
                loading={updatingOtherInfo}
                subtitle={"Employment Status"}
                subtext={otherInformation?.employment_status}
              />
              <SubtitleAndSubtext
                loading={updatingOtherInfo}
                subtitle={"Name Of Employer"}
                subtext={otherInformation?.employer_name}
              />

              <SubtitleAndSubtext
                xs={12}
                sm={4}
                lg={6}
                loading={updatingOtherInfo}
                subtitle={"Office Address"}
                subtext={otherInformation?.office_address}
              />
              <SubtitleAndSubtext
                loading={updatingOtherInfo}
                subtitle={"Monthly Income"}
                subtext={toCurrency(otherInformation?.monthly_income)}
              />
              <SubtitleAndSubtext
                loading={updatingOtherInfo}
                subtitle={"Name of Office Contact Person"}
                subtext={otherInformation?.office_contact_person_name}
              />
              <SubtitleAndSubtext
                loading={updatingOtherInfo}
                subtitle={"Office Contact Person Number"}
                subtext={otherInformation?.office_contact_person_phone_number}
              />
            </DetailsContainer>
          </DetailsCardContainer>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginTop: "60px"
            }}>
            <Button
              onClick={handleEditMode}
              type="button"
              // isLoading={updatingOtherInfo}
              sx={btnStyle}>
              Edit
            </Button>
          </div>
        </>
      ) : (
        <>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={employmentStatusSchema}
            onSubmit={handleFormSubmit}>
            {({ isSubmitting }) => (
              <Form>
                <Grid container columnSpacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormikCustomSelectField
                      name={employmentStatus.name}
                      label={employmentStatus.label}
                      options={employmentOptions}
                      placeholder={employmentStatus.placeHolder}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikCustomInputField
                      name={nameOfEmployer.name}
                      label={nameOfEmployer.label}
                      placeholder={nameOfEmployer.placeHolder}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikCustomInputField
                      name={officeAddress.name}
                      label={officeAddress.label}
                      placeholder={officeAddress.placeHolder}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikCustomInputField
                      name={monthlyIncome.name}
                      label={monthlyIncome.label}
                      placeholder={monthlyIncome.placeHolder}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikCustomInputField
                      name={nameOfOfficeContactPerson.name}
                      label={nameOfOfficeContactPerson.label}
                      placeholder={nameOfOfficeContactPerson.placeHolder}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikCustomInputField
                      name={officeContactPersonNumber.name}
                      label={officeContactPersonNumber.label}
                      placeholder={officeContactPersonNumber.placeHolder}
                    />
                  </Grid>
                </Grid>
                <Stack direction={"row"} spacing={2} justifyContent="center">
                  <Button
                    type="button"
                    // isLoading={updatingOtherInfo}
                    sx={btnStyle}
                    disabled={updatingOtherInfo}
                    onClick={handleEditMode}
                    style={{ background: "white", color: "blue" }}>
                    Cancel
                  </Button>

                  <Button type="submit" disabled={updatingOtherInfo} sx={btnStyle}>
                    {updatingOtherInfo ? <CircularProgress size={"small"} /> : "Update"}
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default EmploymentStatus;
