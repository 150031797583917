import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { getDisplayedStatus } from "#/components/utils/util";

const styles = (theme) => ({
    root: {
        margin: 0,
        backgroundColor: "#EEF4FB",
        borderRadius: 5,
        paddingBottom: 20,
        marginBottom: 10,
        [`${theme.breakpoints.up("900")}`]: {
            height: "100%",
            minHeight: "100vh",
            marginBottom: 0
        },
        "& .status-container": {
            display: "flex",
            justifyContent: "space-between",
            padding: 15,
            cursor: "pointer",
            [theme.breakpoints.down("1025")]: {
                padding: "15px"
            }
        },
        "& .status-item": {
            borderRadius: "5px 0 0 5px",
            backgroundColor: "#fff",
            cursor: "pointer"
        },
        "& .changeColor": {
            backgroundColor: "black"
        },
        "& .status": {
            textDecoration: "none",
            padding: "8px 13px",
            borderRadius: 5,
            fontSize: 10,
            color: "#fff",
            border: 0,
            alignSelf: "flex-start",
            textTransform: "capitalize"
        },
        "& .review-status": {
            backgroundColor: "#1866e7"
        },
        "& .approved-status": {
            backgroundColor: "#2adbc6"
        },
        "& .declined-status": {
            backgroundColor: "#db3535"
        },
        "& .status-container-paragraph": {
            fontSize: 14,
            fontWeight: "bold",
            color: "#043B5C"
        }
    }
});

const ApplicationDetailsMenu = ({ tenantsRequests, handleSelectApplicant, classes, activeApplicant }) => {
    // const { status } = applicantBookingInfo;

    return (
        <div className={classes.root}>
            <div>
                <div className="status-container">
                    <Typography className="status-container-paragraph" variant="body1" component="p">
                        Applicant
                    </Typography>
                    <Typography className="status-container-paragraph" variant="body1" align="left" component="p">
                        Status
                    </Typography>
                </div>
                {tenantsRequests.map((item, idx) => {
                    const { status } = item;
                    let active = false;
                    return (
                        <div
                            key={idx}
                            className={
                                activeApplicant?._id === item._id ? "status-container status-item" : "status-container"
                            }
                            onClick={() => handleSelectApplicant(item)}>
                            <div>
                                <Typography className="status-container-paragraph" variant="body1" component="p">
                                    {item.first_name || "Okpele"} {item.last_name || "One"}
                                </Typography>
                                {/* <span style={{ fontSize: "12px", color: "#043B5C" }}>{userInfoFromLiveizyId.phone}</span> */}
                            </div>
                            <div
                                className={
                                    status === "pending"
                                        ? "review-status status"
                                        : status === "interested" || status === "approved"
                                        ? "approved-status status"
                                        : status === "declined"
                                        ? "declined-status status"
                                        : ""
                                }>
                                {getDisplayedStatus(status)}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default withStyles(styles)(ApplicationDetailsMenu);
