import { Grid } from "@mui/material";
import React from "react";

import InputField from "../UIcomponents/formfields/InputField";
// import CustomBlockCheckbox from "../UIcomponents/others/CustomBlockCheckbox";
// import { useFormikContext } from "formik";
import BasicSelect from "../UIcomponents/formfields/BasicSelect";
import ThousandSeparatorTextField from "../UIcomponents/formfields/ThousandSeparatorTextField";

const CurrentAddressDetailsForm = ({ fields, tenantDetails }) => {
  // const [checked, setChecked] = useState(false);
  // const { setFieldValue } = useFormikContext();
  const {
    homeAddress,
    rentRange,
    rentValue,
    nameOfManager,
    contactOfManager,
    nameOfLandlord,
    contactOfLandlord
    // laid
  } = fields;

  const {
    record
    // landlord, manager
  } = tenantDetails;
  // const apartmentAddress = `${record?.apartment_id?.house_number}, ${record?.apartment_id?.street}, ${record?.apartment_id?.other_address}, ${record?.apartment_id?.lga}, ${record?.apartment_id?.state}, ${record?.apartment_id?.country}`;

  // useEffect(() => {
  //   if (checked && record !== null) {
  //     setFieldValue(homeAddress.name, apartmentAddress);
  //     setFieldValue(laid.name, record?.apartment_id?.apartment_id);
  //     setFieldValue(
  //       nameOfManager.name,
  //       manager ? `${manager?.first_name} ${manager?.last_name}` : ""
  //     );
  //     setFieldValue(
  //       nameOfLandlord.name,
  //       landlord ? `${landlord?.first_name} ${landlord?.last_name}` : ""
  //     );
  //     setFieldValue(contactOfLandlord.name, landlord?.phone1);
  //     setFieldValue(contactOfManager.name, manager?.phone1);
  //   } else {
  //     setFieldValue(homeAddress.name, "");
  //     setFieldValue(laid.name, null);
  //     setFieldValue(nameOfManager.name, "");
  //     setFieldValue(nameOfLandlord.name, "");
  //     setFieldValue(contactOfLandlord.name, "");
  //     setFieldValue(contactOfManager.name, "");
  //   }
  // }, [checked]);
  return (
    <div>
      {/* {record !== null ? (
        <div style={{ margin: "15px 0" }}>
          <CustomBlockCheckbox
            label={"Use Current Home Address"}
            checked={checked}
            setChecked={setChecked}
          />
        </div>
      ) : null} */}
      <Grid container spacing={2}>
        {record ? (
          <>
            <Grid item xs={12} sm={6}>
              <InputField
                InputProps={{ readOnly: true }}
                name={homeAddress.name}
                label={homeAddress.label}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <ThousandSeparatorTextField
                readOnly={true}
                name={rentValue.name}
                label={rentValue.label}
                currency={"₦"}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputField
                InputProps={{ readOnly: true }}
                name={nameOfManager.name}
                label={nameOfManager.label}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField
                InputProps={{ readOnly: true }}
                name={contactOfManager.name}
                label={contactOfManager.label}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputField
                InputProps={{ readOnly: true }}
                name={nameOfLandlord.name}
                label={nameOfLandlord.label}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputField
                InputProps={{ readOnly: true }}
                name={contactOfLandlord.name}
                label={contactOfLandlord.label}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} sm={6}>
              <BasicSelect
                options={rentRangeOptions}
                name={rentRange.name}
                label={rentRange.label}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default CurrentAddressDetailsForm;

const rentRangeOptions = [
  "100k-200k",
  "201k-300k",
  "301k-400k",
  "401k-500k",
  "501k-600k",
  "601k-700k",
  "701k-800k",
  "800k-900k",
  "901k-1m",
  "1.1m-1.2m",
  "1.2m-1.3m",
  "1.3m-1.4m",
  "1.4m-1.5m",
  "1.5m-1.6m",
  "1.6m-1.7m",
  "1.7m-1.8m",
  "1.8m-1.9m",
  "1.9m-2m",
  "2.1m-2.2m",
  "2.2m-2.3m",
  "2.3m-2.4m",
  "2.4m-2.5m",
  "2.5m-2.6m",
  "2.6m-2.7m",
  "2.7m-2.8m",
  "2.8m-2.9m",
  "2.9m-3m",
  "3.1m-3.2m",
  "3.2m-3.3m",
  "3.3m-3.4m",
  "3.4m-3.5m",
  "3.5m-3.6m",
  "3.6m-3.7m",
  "3.7m-3.8m",
  "3.8m-3.9m",
  "3.9m-4m",
  "4.1m-4.2m",
  "4.2m-4.3m",
  "4.3m-4.4m",
  "4.4m-4.5m",
  "4.5m-4.6m",
  "4.6m-4.7m",
  "4.7m-4.8m",
  "4.8m-4.9m",
  "4.9m-5m"
];
