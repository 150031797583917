/**
 * compute next rent supplied with payment tenure and last rent date
 * @param {String} term rent tenure eg. monthly
 * @param {Date} date last rent date
 * @param {Number} days optional fo
 */
export const computeNextPaymentDate = (term, date, days) => {
    const lastPaymentDay = new Date(date).getTime();
    switch (term) {
        case "monthly":
            const thirtyDays = 2592000000; // 30 days in milliseconds (30 * 24 * 60 * 60 * 1000)
            return new Date(lastPaymentDay + thirtyDays);
        case "bi-annual":
            const sixMonths = 15724800000; // 182 days in milliseconds (182 * 24 * 60 * 60 * 1000)
            return new Date(lastPaymentDay + sixMonths);
        case "annual":
            const oneYear = 31536000000; // 365 days in milliseconds (365 * 24 * 60 * 60 * 1000)
            return new Date(lastPaymentDay + oneYear);
        case "quarterly":
            const threeMonths = 7776000000; // 90 days in milliseconds (90 * 24 * 60 * 60 * 1000)
            return new Date(lastPaymentDay + threeMonths);
        case "once":
            if (days) {
                const duration = Number(days) * 24 * 60 * 60 * 1000;
                return new Date(lastPaymentDay + duration);
            }
            const thousandYears = 31557600000000; // 365,000 days (1000 years) in milliseconds (1000 * 365.25 * 24 * 60 * 60 * 1000)
            return new Date(lastPaymentDay + thousandYears);
        default:
            return date;
    }
};

/**
 * compute next rent supplied with payment tenure and last rent date
 * @param {String} term rent tenure eg. monthly, bi-annual, annual, quarterly, once
 * @param {Date} date last rent date
 * @param {Number} days optional
 */
export const checkIfPaymentWindow = (term, date, days) => {
    const oneMonth = 30 * 24 * 60 * 60 * 1000;
    const sevenDays = 7 * 24 * 60 * 60 * 1000;
    const hundredYears = 3153600000000; // 36,500 days (100 years) in milliseconds (100 * 365 * 24 * 60 * 60 * 1000)
    switch (term) {
        case "monthly":
            return new Date(date) - new Date() <= sevenDays;
        case "bi-annual":
            return new Date(date) - new Date() <= oneMonth;
        case "annual":
            return new Date(date) - new Date() <= oneMonth;
        case "quarterly":
            return new Date(date) - new Date() <= oneMonth;
        case "once":
            if (days) {
                const duration = Number(days) * 24 * 60 * 60 * 1000;
                return new Date(date) - new Date() <= duration;
            }
            return new Date(date) - new Date() <= hundredYears;
        default:
            return false;
    }
};

export const returnNgDateFormat = (date) => {
    const newDate = new Date(date);
    const day = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();

    return `${day}/${month}/${year}`;
};
