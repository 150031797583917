import { makeStyles } from "@material-ui/core/styles";

export const useCustomCardStyles = makeStyles((theme) => ({
    duration: {
        position: "absolute",
        left: "-15px",
        top: "25px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "10",
        fontSize: 16,
        color: "#F4F5F7 !important",
        backgroundColor: "#4EBD65",
        borderRadius: 20,
        padding: "5px 22px",
        textTransform: "capitalize"
    },
    root: {
        boxShadow: "none",
        position: "relative",
        color: "#043B5C",
        fontFamily: "Open Sans",
        zIndex: "0",
        cursor: "pointer",
        overflow: "visible",
        borderRadius: "5px",
        width: "300px",
        margin: "20px",

        [theme.breakpoints.between("0", "768")]: {
            margin: "0px 0px 20px 0px",
            width: "100%"
        },

        "& *": {
            color: "#043B5C"
        },

        "& .overlay": {
            position: "absolute",
            display: "flex",
            transition: "all 0.5s ease",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(4, 58, 92, 0.759)",
            left: 0,
            minWidth: "100%",
            height: "100%",
            zIndex: "100",
            borderRadius: "5px",
            opacity: 0
        },

        "&:hover .overlay": {
            opacity: 1
        },

        "& .MuiCardContent-root": {
            padding: "20px 15px"
        }
    },

    apartmentId: {
        position: "absolute",
        right: 0,
        top: 156,
        backgroundColor: "#CCDEFF",
        color: "#043B5C",
        zIndex: 3,
        fontSize: 12,
        fontWeight: 500,
        padding: "0px 12px",
        height: "25px",
        display: "flex",
        alignItems: "center",
        borderRadius: "13px 0 0 13px",
    },

    image: {
        objectFit: "cover",
        borderRadius: "5px 5px 0px 0px",
        height: "170px",
        backgroundColor: "rgba(4, 58, 92, 0.759)"
    },

    propertyName: {
        fontSize: 15,
        fontWeight: 500
    },

    button: {
        width: "50%",
        height: "50px",
        borderStyle: "none",
        borderRadius: "20px",
        color: "#043b5c",
        fontSize: 20,
        fontFamily: "Open Sans",
        outline: "none",
        cursor: "pointer"
    },

    features: {
        fontSize: "12px",
        opacity: 0.5,
        fontWeight: 400
    },

    price: {
        fontSize: 16
    }
}));
