// import "../Styles/modal.scss";
import "../Modal/modal.scss";
// import { ReactComponent as CancelIcon } from "@common/Assests/Icons/cancel-icon.svg";
// import { ReactComponent as ArrowIcon } from "@common/Assests/Icons/back-icon.svg";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import useMediaQuery from "#/shared/hooks/useMediaQuery/useMediaQuery";
import Button from "../buttons/Button";

const Modal = (props) => {
  const [showModal, setShowModal] = useState(false);
  const isMobileView = useMediaQuery("(max-width: 767px)");

  const { allowMotion = true, smallModal = false, asForm = false } = props;

  useEffect(() => {
    setTimeout(() => {
      setShowModal(allowMotion);
    });
  }, []);

  useEffect(() => {
    document.body.classList.add("hide-scroll");
    document.addEventListener("keydown", closeOnEscape, false);
    return () => {
      document.body.classList.remove("hide-scroll");
      document.removeEventListener("keydown", closeOnEscape, false);
    };
  }, [showModal]);

  function closeOnEscape(event) {
    const callback = { Escape: props.closeModal }[event.key];
    if (!callback) return;
    props.closeOnEsc && callback?.();
  }

  function closeOnOverlayClick(event) {
    const element = event.target;
    if (element.classList.contains("app-modal-overlay") && props.closeOnOutsideClick) {
      if (props.closeMotion) {
        closeModalMotion();
      } else {
        props.closeModal?.();
      }
    }
  }

  function closeModalMotion() {
    setShowModal(false);
    setTimeout(() => {
      props.closeModal?.();
    }, 500);
  }

  const Component = asForm ? "form" : "div";

  return (
    <div
      className={`app-modal ${showModal ? "open" : props.closeMotion ? "close" : ""} ${
        isMobileView && !smallModal
          ? "fullscreen"
          : isMobileView && smallModal
          ? "mobilescreen"
          : ""
      } ${props.classNames ? props.classNames : ""}`}>
      <div
        className={`app-modal-overlay ${allowMotion ? "motion" : ""}`}
        onClick={closeOnOverlayClick}></div>
      <Component
        className={`app-modal-body ${allowMotion ? "motion" : ""} `}
        style={{
          width: props.width ? props.width : "",
          borderRadius: props.fullBorderRadius && !isMobileView ? "12px" : ""
        }}
        {...(asForm && { onSubmit: props.formSubmit })}>
        {props.header && (
          <div className="app-modal-body-header">
            {/* {props.goBack ? (
              <button
                className="close-icon disabled:cursor-not-allowed disabled:opacity-75"
                onClick={props.goBack}
                disabled={props.loading || props.buttonLoading}>
                <ArrowIcon width={"10px"} height={"10px"} />
              </button>
            ) : (
              <button
                className="close-icon disabled:cursor-not-allowed disabled:opacity-75"
                onClick={props.closeMotion ? closeModalMotion : props.closeModal}
                disabled={props.loading || props.buttonLoading}>
                <CancelIcon />
              </button>
            )} */}

            <p className="header-title">{props.header}</p>
          </div>
        )}

        <div
          className="app-modal-body-content"
          style={{
            width: props.width ? props.width : "",
            marginBottom: !props.buttonText ? "" : "",
            maxHeight:
              window.screen.width > 1124 && props.maxHeight ? props.maxHeight : "",
            minHeight:
              window.screen.width > 1124 && props.minHeight ? props.minHeight : "",
            padding: props.contentPadding ? props.contentPadding : ""
          }}>
          {props.children}
        </div>
        {props.buttonText && (
          <div className="app-modal-form-submit">
            <div className="form-buttons">
              {props.showBackButton && (
                <Button
                  type={props.buttonCancelType || "default"}
                  noMargin={true}
                  text={props.backButtonText}
                  loading={props.buttonCancelLoading}
                  onClick={() => props.backButtonAction?.()}
                  disabled={props.buttonLoading || props.buttonCancelDisabled}
                  width={props.btnWidth || "100%"}
                />
              )}

              <Button
                buttonType={"submit"}
                type={props.buttonType || "primary"}
                noMargin={true}
                text={props.buttonText}
                loading={props.buttonLoading}
                disabled={props.buttonDisabled}
                {...(!asForm && { onClick: props.buttonAction?.() })}
                width={props.btnWidth || "100%"}
              />
            </div>
          </div>
        )}
      </Component>
    </div>
  );
};

const modalRoot = document.getElementById("portal-root");

export const ModalPortal = (props) => {
  return <>{createPortal(<Modal {...props} />, modalRoot)}</>;
};

export default Modal;
