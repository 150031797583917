import {
  ACTIVATE_IZYINVEST_PLAN,
  ACTIVATE_IZYINVEST_PLAN_SUCCESS,
  ACTIVATE_IZYINVEST_PLAN_FAIL,
  GET_IZYPAY_PLAN,
  GET_IZYINVEST_BALANCE,
  GET_IZYINVEST_BALANCE_FAIL,
  GET_IZYINVEST_BALANCE_SUCCESS,
  GET_IZYINVEST_HISTORY,
  GET_IZYINVEST_HISTORY_FAIL,
  GET_IZYINVEST_HISTORY_SUCCESS,
  FUND_IZYVEST_WALLET_SUCCESS,
  FUND_IZYVEST_WALLET_FAIL,
  FUND_IZYVEST_WALLET,
  SAVE_CURRENT_PLANID,
  GET_IZYINVEST_PLANS_LIST,
  GET_IZYINVEST_PLANS_LIST_SUCCESS,
  GET_IZYINVEST_PLANS_LIST_FAIL
} from "../constants";

const initialState = {
  izyInvestPlan: [],
  loading: false,
  monthlyInterest: null,
  contributoryBalance: null,
  availableBalance: 0,
  closingBalance: null,
  expectedClosingBalance: null,
  dailyInterest: null,
  previousBalance: null,
  newBalance: null,
  fundIzyinvest: false,
  newPlanId: "",
  minimumDep: null,
  minimumFirstDeposit: null,
  accountNumber: null,
  bank: "",
  payoutDate: null,
  plansList: []
};

const izyinvestReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVATE_IZYINVEST_PLAN:
      return {
        ...state,
        loading: true
      };
    case ACTIVATE_IZYINVEST_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        newPlanId: action.newPlanId
      };
    case ACTIVATE_IZYINVEST_PLAN_FAIL:
      return {
        ...state,
        loading: false
      };

    case GET_IZYPAY_PLAN:
      return {
        ...state,
        // izyInvestPlan: null
        izyInvestPlan: action.izyInvestPlan,
        newPlanId: action.newPlanId,
        minimumDep: action.minimumDep
      };
    case GET_IZYINVEST_BALANCE:
      return {
        ...state,
        loading: true
      };
    case GET_IZYINVEST_BALANCE_SUCCESS:
      return {
        ...state,
        availableBalance: action.availableBalance,
        contributoryBalance: action.contributoryBalance,
        closingBalance: action.closingBalance,
        expectedClosingBalance: action.expectedClosingBalance,
        monthlyInterest: action.monthlyInterest,
        dailyInterest: action.dailyInterest,
        previousBalance: action.previousBalance,
        newBalance: action.newBalance,
        minimumFirstDeposit: action.minimumFirstDeposit,
        minimumDep: action.minimumDep,
        loading: false,
        accountNumber: action.accountNumber,
        bank: action.bank,
        payoutDate: action.payoutDate
      };
    case GET_IZYINVEST_BALANCE_FAIL:
      return {
        ...state,
        loading: false
      };
    case GET_IZYINVEST_HISTORY:
      return {
        ...state
        // loading: true,
      };
    case GET_IZYINVEST_HISTORY_SUCCESS:
      return {
        ...state,
        dailyInterest: action.dailyInterest,
        previousBalance: action.previousBalance,
        newBalance: action.newBalance
        // loading: false,
      };
    case GET_IZYINVEST_HISTORY_FAIL:
      return {
        ...state
        // loading: false,
      };
    case FUND_IZYVEST_WALLET:
      return {
        ...state,
        loading: true
      };
    case FUND_IZYVEST_WALLET_SUCCESS:
      return {
        ...state,
        fundIzyinvest: true,
        loading: false
      };
    case FUND_IZYVEST_WALLET_FAIL:
      return {
        ...state,
        fundIzyinvest: false,
        loading: false
      };
    case SAVE_CURRENT_PLANID:
      return {
        ...state,
        newPlanId: action.newPlanId,
        minimumDep: action.minimumDep
      };
    // case GET_IZYINVEST_PLANS_LIST:
    //   return {
    //     ...state,
    //   };
    case GET_IZYINVEST_PLANS_LIST_SUCCESS:
      return {
        ...state,
        plansList: action.payload
      };
    // case GET_IZYINVEST_PLANS_LIST_FAIL:
    //   return {
    //     ...state,
    //   };
    default:
      return state;
  }
};

export default izyinvestReducer;
