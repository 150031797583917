import usePost from "#/hooks/util-hooks/usePost";
import { Button, CircularProgress } from "@material-ui/core";
import { ErrorMessage, Formik } from "formik";
import React, { useState, useEffect } from "react";
import announce from "#/assets/images/announce.png";
import useFetch from "#/hooks/util-hooks/useFetch";
import { formatPrice } from "#/shared/utils/general";
import { useDispatch } from "react-redux";
import { alertError, alertSuccess } from "#/store/actions/notification.actions";
import { APP_ROUTES } from "#/constants/routes";

const PreferenceModal = ({ lid, apartmentID, refetch, setModal, history }) => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [selectedBill, setSelectedBill] = useState(null);
  const [value, setValue] = useState("");

  const initialValues = {
    lid,
    status: true,
    option: ""
  };

  const { loading, mutate } = usePost("/bill-preference", "post", {
    onSuccess: (data) => {
      setModal(false);
      dispatch(alertSuccess(data.message));
      refetch();
    },
    onError: (error) => {
      setModal(false);
      dispatch(alertError(error.message));
      refetch();
    }
  });

  const { data: billsData } = useFetch(
    apartmentID ? `/bill/get_apartment_bills/${apartmentID}` : null
  );

  useEffect(() => {
    if (billsData && value) {
      const bill = billsData?.data?.find((bill) => bill[value]);

      if (bill) {
        const paymentTerm = bill[value];
        if (paymentTerm?.active && paymentTerm.amount !== null) {
          setSelectedBill({
            bill,
            value: paymentTerm
          });
        } else {
          setSelectedBill({
            bill,
            value: null
          });
        }
      }
    }
  }, [billsData, value]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        mutate(values);
      }}>
      {({ values, handleSubmit, handleChange }) => (
        <form
          onSubmit={handleSubmit}
          className="billModal tw-h-[60%] tw-overflow-auto tw-bg-slate-50 tw-py-10 tw-px-14 tw-rounded">
          <div className="tw-w-full tw-flex tw-justify-center tw-items-center">
            <div className="tw-w-[30rem]">
              <img src={announce} alt="Announcement" className="tw-w-full" />
            </div>
          </div>
          <h1 className="tw-text-center tw-text-[2rem] tw-font-semibold tw-mb-12">
            Choose Preference
          </h1>

          <div className="tw-text-center tw-mb-10">
            <p className="tw-text-[1.3rem] tw-font-semibold">
              Do you know you can now pay your bills with any term you like!
            </p>
            <p className="tw-font-semibold">Do you want to proceed with this?...</p>
          </div>

          <div className="tw-flex tw-gap-7">
            <Button
              className="tw-flex-1"
              variant="contained"
              color="primary"
              onClick={() => setShow(true)}>
              Yes
            </Button>
            <Button
              className="tw-flex-1"
              variant="outlined"
              onClick={() => history.push(APP_ROUTES.dashboard.rentalpayments)}>
              No
            </Button>
          </div>

          {show && (
            <>
              <div className="tw-flex tw-justify-between tw-gap-5 tw-items-center tw-my-10">
                <div className="tw-flex tw-flex-1 tw-flex-col">
                  <label
                    htmlFor="type-select"
                    className="tw-text-[#05213770] tw-font-bold tw-mb-2">
                    Payment Term
                  </label>
                  <select
                    name="option"
                    value={values.option}
                    onChange={(e) => {
                      handleChange(e);
                      setValue(e.target.value);
                      setSelectedBill(null);
                    }}
                    className="tw-border tw-py-3 tw-px-3 tw-w-full tw-rounded-md tw-font-medium tw-text-x tw-text-primary tw-placeholder:text-primary tw-placeholder:font-medium"
                    id="type-select"
                    style={{ border: "solid 1px #062239" }}>
                    <option value="" disabled>
                      Select term
                    </option>
                    <option value="annual_payment">Annual</option>
                    <option value="bi_annual_payment">Bi-annual</option>
                    <option value="quaterly_payment">Quarterly</option>
                    <option value="monthly_payment">Monthly</option>
                    <option value="weekly_payment">Weekly</option>
                    <option value="day_payment">Daily</option>
                    <option value="hourly_payment">Hourly</option>
                    <option value="minute_payment">Minute</option>
                  </select>
                  <ErrorMessage
                    name="option"
                    component="div"
                    className="tw-text-[13px] tw-font-semibold tw-mt-1 tw-text-red-500"
                  />
                </div>
              </div>

              {selectedBill && (
                <div className="tw-bg-gray-100 tw-p-4 tw-mb-6 tw-rounded-md">
                  <h2 className="tw-text-xl tw-font-semibold">Bill Details</h2>

                  {selectedBill.value ? (
                    <>
                      <p className="tw-text-lg">Name: {selectedBill.bill.name}</p>
                      <p className="tw-text-lg">
                        Amount: {formatPrice(selectedBill.value.amount)}
                      </p>
                    </>
                  ) : (
                    <p className="tw-text-lg tw-text-red-500">
                      No setting for this payment term
                    </p>
                  )}
                </div>
              )}

              <button
                type="submit"
                className="tw-bg-blue-500 tw-text-white tw-px-4 tw-py-2 tw-rounded">
                {loading ? <CircularProgress /> : "Submit"}
              </button>
            </>
          )}
        </form>
      )}
    </Formik>
  );
};

export default PreferenceModal;
