import { useState } from "react";

const useApartDetailsDropdown = () => {
    const [otherFacilities, setOtherFacilities] = useState({
        prepaidElectricMeter: false,
        analogElectric: false,
        gas: false,
        water: false,
        internetAndCable: false,
        ac: false,
        pool: false,
        furnished: false,
        balcony: false,
        marble: false,
        tiles: false
    });

    const [mainFacilities, setMainFacilities] = useState({
        bedrooms: "",
        bathrooms: "",
        sittingRooms: "",
        ensuites: ""
    });

    const handleOnChangeInput = (e) => {
        const { name, value } = e.target;

        setMainFacilities((prevState) => {
            return {
                ...prevState,
                [name]: value
            };
        });
    };

    const handleToggleCheck = (e) => {
        const { name } = e.target;

        setOtherFacilities((prevState) => {
            return {
                ...prevState,
                [name]: !otherFacilities[name]
            };
        });
    };

    const resetFields = () => {
        setOtherFacilities({
            prepaidElectricMeter: false,
            analogElectric: false,
            gas: false,
            water: false,
            internetAndCable: false,
            ac: false,
            pool: false,
            furnished: false,
            balcony: false,
            marble: false,
            tiles: false
        });
        setMainFacilities({
            bedrooms: "",
            bathrooms: "",
            sittingRooms: "",
            ensuites: ""
        });
    };

    return {
        apartDetailsDropdownHelpers: {
            otherFacilities,
            handleOnChangeInput,
            resetFields,
            setOtherFacilities,
            setMainFacilities,
            handleToggleCheck,
            mainFacilities
        }
    };
};

export default useApartDetailsDropdown;
