import { toCurrency } from "#/components/utils/util";
import { styled } from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

// const COMPLETE = "complete";
const COMPLETE = "paid";
const RENTDESCRIPTION = "NR";

// Function to format date to day-month-year
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const RentalBillCard = ({ bill, handleSelectBill, preference }) => {
  const [showDetails, setShowDetails] = useState(false);

  const preferenceValue = preference?.data?.bill_preference;

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    bill.status !== "paid" && (
      <Card>
        <div>
          <PaymentStatus status={bill.status}>
            <LeftSection>
              <input
                type="checkbox"
                onChange={() => handleSelectBill(bill)}
                disabled={bill.is_selected || bill.status === "paid"}
                defaultChecked={bill.is_selected || bill.status === "paid" || false}
              />
              <small
                className={`${
                  bill.status === COMPLETE ? "tw-text-[#25CC22]" : "tw-text-[#c8202d]"
                }`}>
                {bill.status === COMPLETE ? "Paid" : "Pending"}
              </small>
            </LeftSection>
            <RightAlign>
              Payment Date <br />
              {formatDate(bill.payment_date) ?? "Not Set"}
            </RightAlign>
          </PaymentStatus>
        </div>
        <Bill>
          <BillTitle>{bill.name === RENTDESCRIPTION ? "RENT" : bill.name}</BillTitle>
          <BillAmount isNegative={bill.amount_pending > 0}>
            <Amount>
              {preferenceValue && bill.name !== "Monthly Rent" && bill[preferenceValue]
                ? bill[preferenceValue]?.amount == null
                  ? toCurrency(bill.amount)
                  : toCurrency(bill[preferenceValue]?.amount)
                : toCurrency(bill.amount)}
            </Amount>
            <Balance>
              Balance:{" "}
              <span
                className={`${
                  bill.status === COMPLETE ? "tw-text-[#25CC22]" : "tw-text-[#c8202d]"
                }`}>
                {preferenceValue && bill.name !== "Monthly Rent" && bill[preferenceValue]
                  ? bill[preferenceValue]?.amount == null
                    ? toCurrency(bill.amount)
                    : toCurrency(bill[preferenceValue]?.amount)
                  : toCurrency(bill.amount)}
              </span>
            </Balance>
          </BillAmount>
        </Bill>
        <ViewDetails onClick={toggleDetails}>
          <span
            className={`${
              bill.status === COMPLETE ? "tw-text-[#25CC22]" : "tw-text-[#c8202d]"
            }`}>
            View Details
          </span>
          <DropdownIcon rotate={showDetails ? "down" : "right"}>
            {showDetails ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
          </DropdownIcon>
        </ViewDetails>
        {showDetails && (
          <DetailsDropdown>
            <Details>
              <DetailsHeader>
                <span
                  className={`${
                    bill.status === COMPLETE ? "tw-text-[#25CC22]" : "tw-text-[#c8202d]"
                  }`}>
                  {bill.name}
                </span>
              </DetailsHeader>
              <DetialsContent>
                <ul>
                  <li>
                    Subscription Fee: {toCurrency(bill.subscription_fee) ?? toCurrency(0)}
                  </li>
                  <li>Security Deposit: {toCurrency(0)}</li>
                  <li>
                    {bill.payment_term === "monthly" ? "Monthly Payment" : "Payment"}:{" "}
                    {toCurrency(bill.amount)}
                  </li>
                  <li>Contribution: {toCurrency(0)}</li>
                </ul>
              </DetialsContent>
            </Details>
          </DetailsDropdown>
        )}
      </Card>
    )
  );
};

export default RentalBillCard;

const Card = styled("div")`
  min-width: 21rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.5rem;
  padding: 2rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 15px;

  @media (max-width: 600px) {
    min-width: unset;
    padding: 13px;
  }
`;

const Bill = styled("div")`
  color: #052137;
`;

const BillTitle = styled("p")`
  font-size: 1.4rem;
  font-weight: 600;

  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;

const BillAmount = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: 2px;
`;

const Amount = styled("p")`
  font-size: 1.3rem;
  font-weight: 600;

  @media (max-width: 600px) {
    font-size: 13px;
  }
`;

const Balance = styled("p")`
  color: rgba(5, 33, 55, 0.7);
  font-weight: 400;
  font-size: 14px;

  span {
    font-weight: 900;
  }

  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

const PaymentStatus = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`;

const LeftSection = styled("div")`
  display: flex;
  align-items: center;
  gap: 1rem;

  input {
    padding: 0;
    height: 1.3rem;
    width: 1.3rem;
    margin-bottom: 0;
    cursor: pointer;
    accent-color: #ff0000;
    box-shadow: 1px 1px 5px #ff000050;
  }

  small {
    font-weight: bold;
    font-size: 15px;
  }
`;

const RightAlign = styled("small")`
  text-align: right;
  display: block;
`;

const ViewDetails = styled("div")`
  margin-top: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 2s;

  @media (max-width: 600px) {
    font-size: 15px;
  }
`;

const DropdownIcon = styled("span")`
  margin-left: 0.5rem;
  transition: transform 0.3s ease;
  transform-origin: center;
  transform: rotate(${(props) => (props.rotate ? "0deg" : "-90deg")});
`;

const DetailsDropdown = styled("div")`
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: all 2s;
  border: 1px solid #d7d7d7;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

const Details = styled("div")`
  display: block;
  padding: 0.5rem 1rem;
`;

const DetailsHeader = styled("h3")`
  font-size: 1rem;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1rem;
  font-weight: 700;
`;

const DetialsContent = styled("div")`
  display: block;
`;
