import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const styles = {
    root: {
        '& .text-container': {
            color: '#fff !important',
            paddingLeft: 20,
            paddingTop: 10,
            paddingBottom: 10,
            marginBottom: 10,
            borderRadius: 10,
        },
        '& > div:nth-child(1)': {
            backgroundColor: '#101730',
        },
        '& > div:nth-child(2)': {
            backgroundColor: '#db3535',
        },
        '& > div:nth-child(3)': {
            backgroundColor: '#db7d2a',
        },
        '& > div:nth-child(4)': {
            backgroundColor: '#2adbc6',
        },
        '& > div:nth-child(5)': {
            backgroundColor: '#0b544b',
        },
        '& .span-text': {
            fontSize: 10,
            marginTop: 5,
            color: '#fff',
        },
        '& .paragraph': {
            color: '#fff',
            fontSize: '15px !important',
        }
    },
};

const AccountSummary = (props) => (
    <div className={props.classes.root}>
        <div className="text-container">
            <Typography className="paragraph" variant="body1"  component="p">
                &#x20A6; 200,000
            </Typography>
            <span className="span-text">Total Open Invoice</span>
        </div>
        <div className="text-container">
            <Typography className="paragraph" variant="body1"  component="p">
                &#x20A6; 50,000
            </Typography>
            <span className="span-text">Overdue Invoice</span>
        </div>
        <div className="text-container">
            <Typography className="paragraph" variant="body1"  component="p">
                &#x20A6; 50,000
            </Typography>
            <span className="span-text">Proccessing</span>
        </div>
        <div className="text-container">
            <Typography className="paragraph" variant="body1"  component="p">
                &#x20A6; 50,000
            </Typography>
            <span className="span-text">Partially Paid</span>
        </div>
        <div className="text-container">
            <Typography className="paragraph" variant="body1"  component="p">
                &#x20A6; 50,000
            </Typography>
            <span className="span-text">Paid</span>
        </div>
    </div>
)

export default withStyles(styles)(AccountSummary);
