import {
  LOAD_POPUP,
  ASSIGN_GENERAL_ROLE,
  ASSIGN_SERVICE_ROLES_SUCCESS,
  ASSIGN_SERVICE_ROLES_FAIL,
  ASSIGN_SERVICE_ROLES_PENDING
} from "../constants";

const initialState = {
  popup: false,
  role: "",
  loadingRole: false,
  user: []
};

const serviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_POPUP:
      return {
        ...state,
        popup: action.popup
      };
    case ASSIGN_GENERAL_ROLE:
      return {
        ...state,
        role: action.role
      };
    case ASSIGN_SERVICE_ROLES_PENDING:
      return {
        ...state,
        loadingRole: true
      };
    case ASSIGN_SERVICE_ROLES_SUCCESS:
      return {
        ...state,
        user: action.payload,
        message: action.message,
        loadingRole: false
      };
    case ASSIGN_SERVICE_ROLES_FAIL:
      return {
        ...state,
        loadingRole: false
      };

    default:
      return state;
  }
};

export default serviceReducer;
