import { Grid } from "@mui/material";
import React from "react";
import DocumentUploadField from "../UIcomponents/formfields/DocumentUploadField";

import InputField from "../UIcomponents/formfields/InputField";
import SelectField from "../UIcomponents/formfields/SelectField";
import DocumentPreview from "../UIcomponents/others/DocumentPreview";
import ThousandSeparatorTextField from "../UIcomponents/formfields/ThousandSeparatorTextField";
import { useFormikContext } from "formik";

const JobDetailsForm = ({ fields }) => {
  const {
    employmentStatus,
    nameOfEmployer,
    officeAddress,
    monthlyIncome,
    nameOfOfficeContactPerson,
    officeContactPersonNumber,
    accountStatementDoc
  } = fields;
  const { values } = useFormikContext();

  let employmentStatusOptions = ["Employed", "Self Employed"];
  return (
    <div>
      <Grid sx={{ marginTop: "20px" }} container spacing={2}>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={employmentStatus.name}
            label={employmentStatus.label}
            options={employmentStatusOptions}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={nameOfEmployer.name} label={nameOfEmployer.label} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={officeAddress.name} label={officeAddress.label} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ThousandSeparatorTextField
            name={monthlyIncome.name}
            label={monthlyIncome.label}
            currency={"₦"}
          />
        </Grid>

        {values[employmentStatus.name] !== "Self Employed" ? (
          <Grid item xs={12} sm={6}>
            <InputField
              name={nameOfOfficeContactPerson.name}
              label={nameOfOfficeContactPerson.label}
            />
          </Grid>
        ) : null}

        {values[employmentStatus.name] !== "Self Employed" ? (
          <Grid item xs={12} sm={6}>
            <InputField
              name={officeContactPersonNumber.name}
              label={officeContactPersonNumber.label}
            />
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <DocumentUploadField
            name={accountStatementDoc.name}
            label={accountStatementDoc.label}
          />
          <DocumentPreview />
        </Grid>
      </Grid>
    </div>
  );
};

export default JobDetailsForm;
