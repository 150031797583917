import { formatNigerianPhoneNumber } from "#/components/utils/util";
import {
  getOtherInformation,
  getProfileInformation,
  updateOtherInformation,
  updateUserProfile
} from "#/store/actions/auth.actions";
import { showNotifications } from "#/store/actions/notification.actions";
import axios from "axios";

const configAuthService = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "X-Requested-With": "XMLHttpRequest"
  }
};

const postProfileImageToCloudinary = async (profileImage) => {
  try {
    const formData = new FormData();
    formData.append("file", profileImage);
    formData.append("upload_preset", "monthly_rent_applicants_profile_images");

    const data = await fetch("https://api.cloudinary.com/v1_1/dfz1vgrgv/image/upload", {
      method: "POST",
      body: formData
    })
      .then((response) => response.json())
      .catch((error) => {
        console.log(error); // do something else, like dispatch error message
      });
    return data.secure_url;
  } catch (error) {
    console.log(error); // do something else
  }
};

export const submitForm = async (values, dispatch, setEditMode) => {
  try {
    // Profile info
    let profileImageUrl;
    if (typeof values?.profileImage === "string") {
      profileImageUrl = values?.profileImage;
    } else {
      profileImageUrl = await postProfileImageToCloudinary(values?.profileImage);
    }
    const profileInfo = {
      first_name: values?.firstName,
      gender: values?.gender,
      state_of_origin: values?.stateOfOrigin,
      phone: formatNigerianPhoneNumber(values?.mobileNumber),
      last_name: values?.lastName,
      other_name: values?.otherName,
      date_of_birth: values?.dateOfBirth,
      local_governement_area: values?.lga,
      alt_email: values?.alternateEmail,
      profile_image: profileImageUrl,
      alt_phone: formatNigerianPhoneNumber(values?.alternateMobileNumber)
    };
    dispatch(
      updateUserProfile(
        profileInfo,
        () => {},
        () => {
          dispatch(getProfileInformation());
          if (values?.otherName) {
            const data = {
              other_name: values?.otherName
            };
            dispatch(
              updateOtherInformation(data, () => {
                dispatch(getOtherInformation());
              })
            );
          }
          setEditMode(false);
        }
      )
    );
  } catch (error) {
    dispatch(showNotifications("An Error Ocuured", "error"));
  }
};
