import React from "react";

import { styled } from "@mui/material";

const Wrapper = styled("div")`
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;

const ProfilePictureWrapper = ({ children }) => {
    return <Wrapper>{children}</Wrapper>;
};

export default ProfilePictureWrapper;
