import { makeStyles } from "@material-ui/core/styles";

export const useCustomFiltersStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        padding: "10px 20px",
        alignItems: "center",
        justifyContent: "space-between",

        [theme.breakpoints.between("0", "992")]: {
            justifyContent: "space-between",
            flexWrap: "wrap",
            padding: "0px"
        }
    },

    btnSubmit: {
        backgroundColor: "#4786FF",
        color: "#fff",
        textTransform: "none",
        borderRadius: "5px",
        color: "#F4F5F7",
        border: "1px solid #4786FF",
        padding: "0px 60px",
        fontSize: "13px",
        fontFamily: "Open Sans, sans-serif",
        height: "46px",

        [theme.breakpoints.between("0", "640")]: {
            marginBottom: "30px"
        },

        "&:hover": {
            backgroundColor: "#4786FF",
            opacity: "0.5"
        }
    }
}));
