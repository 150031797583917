import { Box, styled } from "@mui/material";
import ProfilePictureWrapper from "#/components/ui-components/ProfilePictureWrapper";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import React from "react";
import SubHeadingUnderline from "#/association/components/SubHeadingUnderline";
import PaymentDetails from "../components/PaymentDetails";

export default function MemberDetails() {
  return (
    <>
      <Box>
        <Jumbotron>
          <ProfilePictureWrapper />
          <b>Cecila Johnson</b>
          <small>08094567894</small>
          <small>cecilajohnson@gmail.com</small>
        </Jumbotron>
        <Box marginTop={"30px"}>
          <SubHeadingUnderline text={"Payment Details"} icon={<NoteAddOutlinedIcon />} />
          <Box marginTop="30px">
            <PaymentDetails />
          </Box>
        </Box>
      </Box>
    </>
  );
}

const Jumbotron = styled("div")`
  width: 100%;
  height: 250px;
  color: white;
  background: linear-gradient(
    64.63deg,
    #052137 -1.41%,
    #c8202d 48.83%,
    rgba(7, 36, 59, 0.827247) 98.74%,
    rgba(5, 33, 55, 0.78) 98.75%
  );
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
