import { Grid, styled } from "@mui/material";
import { useFormikContext } from "formik";
import naijaStateLocalGovernment from "naija-state-local-government";
import React, { useEffect } from "react";
import DatePickerField from "../UIcomponents/formfields/DatePickerField";
import ImageUploadField from "../UIcomponents/formfields/ImageUploadField";
import InputField from "../UIcomponents/formfields/InputField";
import SelectField from "../UIcomponents/formfields/SelectField";
import ProfilePicLinkPreview from "../UIcomponents/others/ProfilePicLinkPreview";
import ProfilePicFilePreview from "../UIcomponents/others/ProfilePicFilePreview";
import ImageUploader from "../UIcomponents/formfields/ImageUploader";

const ProfilePictureAlign = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  flex-direction: column;
`;

const PersonalDetailsForm = ({ fields }) => {
  const {
    profileImage,
    bvn,
    surname,
    firstName,
    otherNames,
    gender,
    dateOfBirth,
    stateOfOrigin,
    lga,
    email,
    alternateEmail,
    mobileNumber,
    alternateMobileNumber,
    meansOfId,
    meansOfIdFile
  } = fields;

  const { setFieldValue, values, setTouched, touched } = useFormikContext();

  const meansOfIdOptions = [
    "National Identification Number (NIN)",
    "Voter's Identification Number",
    "International Passport"
  ];

  const genderOptions = ["male", "female"];
  // create state option list
  let stateOptions = [];
  naijaStateLocalGovernment.states().forEach((state) => {
    stateOptions.push(state);
  });

  // create lga options based on stateOfOrigin value
  let lgaOptions = [];
  const getLgaOptions = () => {
    if (values.stateOfOrigin) {
      let lgasList = naijaStateLocalGovernment.lgas(values.stateOfOrigin);
      lgasList.lgas.forEach((lga) => {
        lgaOptions.push(lga);
      });
    }
  };
  getLgaOptions();
  useEffect(() => {
    // this would reset the LGA field after the stateOfOrigin field has been changed
    if (!lgaOptions.includes(values.lga)) {
      setFieldValue(lga.name, "");
      setTouched({ ...touched, [lga.name]: false });
    }
  }, [values.stateOfOrigin]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ProfilePictureAlign>
            <div style={{ marginBottom: "10px" }}>
              {typeof values.profileImage === "string" ? (
                <ProfilePicLinkPreview profileImage={values.profileImage} />
              ) : (
                <ProfilePicFilePreview profileImage={values.profileImage} />
              )}
            </div>
            <small style={{ color: "#C8202D", textAlign: "center", marginTop: "-10px" }}>
              Passport format
            </small>
            <ImageUploadField name={profileImage.name} />
          </ProfilePictureAlign>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={bvn.name} label={bvn.label} type="number" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={surname.name} label={surname.label} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={firstName.name} label={firstName.label} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={otherNames.name} label={otherNames.label} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SelectField name={gender.name} label={gender.label} options={genderOptions} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePickerField name={dateOfBirth.name} label={dateOfBirth.label} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SelectField
            name={stateOfOrigin.name}
            label={stateOfOrigin.label}
            options={stateOptions}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={lga.name}
            label={lga.label}
            disabled={values.stateOfOrigin ? false : true}
            options={lgaOptions}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField
            InputProps={{ readOnly: true }}
            name={email.name}
            label={email.label}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={alternateEmail.name} label={alternateEmail.label} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={mobileNumber.name} label={mobileNumber.label} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={alternateMobileNumber.name}
            label={alternateMobileNumber.label}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={meansOfId.name}
            label={meansOfId.label}
            options={meansOfIdOptions}
          />
        </Grid>
        <Grid item xs={12}>
          <ImageUploader name={meansOfIdFile.name} label={meansOfIdFile.label} />
        </Grid>
      </Grid>
    </div>
  );
};

export default PersonalDetailsForm;
