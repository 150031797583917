import React, { useState, useEffect } from "react";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import logo from "../../assets/images/liveizy-logo.png";
import { propertyServiceApi } from "#/apis/propertyServiceApi";
import { Link } from "@material-ui/core";

const ReceiptPage = (props) => {
    const [receipt, setReceipt] = useState(null);

    useEffect(() => fetchReceipt(), []);

    const fetchReceipt = () => {
        propertyServiceApi()
            .get("/receipts/" + props.match.params.receipt_id)
            .then((data) => {
                setReceipt(data.data.data);
            })
            .catch((err) => {});
    };

    if (!receipt) return null;

    const { rentRecord, chargesRecord } = receipt;

    return (
        <div className="receipt-page-container">
            <div className="rpc-back-btn">
                <Link href="/dashboard">
                    <KeyboardBackspaceIcon />
                    <span className="rpc-back-btn-span">dashboard</span>
                </Link>
            </div>
            <div className="receipt-page-content">
                <div className="pgc-top-ribbon">
                    <img src={logo} alt="pay with liveizy" onClick={() => props.history.push("/dashboard")} />
                    <h3>RECEIPT</h3>
                </div>
                <div className="address-section">
                    <div className="company-address">
                        <p>Liveizy LTD.</p>
                        <p>9a church street</p>
                        <p>off hospital road</p>
                        <p>Gbagada, Lagos</p>
                    </div>
                    <div className="receipt-no">
                        <p>{new Date(receipt.paymentDate).toDateString()}</p>
                        <b>#{receipt?._id}</b>
                    </div>
                    <br />
                    <div>
                        <p>Tenant Liveizy ID: {receipt?.rentRecord?.customer}</p>
                        <p>Landlord Liveizy ID: {receipt?.rentRecord?.recipient}</p>
                        <p>Apartment ID: {receipt?.apartment}</p>
                    </div>
                </div>
                <div className="pgc-bottom-divs">
                    <div className="pgc-pay-method">
                        <table>
                            <thead></thead>
                            <tr>
                                <th>Description</th>
                                <th>Qty</th>
                                <th>Unit price (&#8358;)</th>
                                <th>Total (&#8358;)</th>
                            </tr>

                            {rentRecord && (
                                <tr>
                                    <td>Payment for rent</td>
                                    <td>{rentRecord?.quantity}</td>
                                    <td>{rentRecord?.price_per_unit.toLocaleString()}</td>
                                    <td>
                                        {(
                                            Number(rentRecord?.quantity) * Number(rentRecord?.price_per_unit)
                                        ).toLocaleString()}
                                    </td>
                                </tr>
                            )}
                            {chargesRecord.map((charge) => {
                                return (
                                    <tr>
                                        <td>Payment for {charge.name} (charge)</td>
                                        <td>1</td>
                                        <td>{Number(charge.amount).toLocaleString()}</td>
                                        <td>{Number(charge.amount).toLocaleString()}</td>
                                    </tr>
                                );
                            })}
                        </table>
                    </div>
                    <div className="subtotal-flex-container">
                        <div className="subtotal-divs">
                            <label>SUBTOTAL</label>
                            <div>{receipt.total.toLocaleString()}.00</div>
                        </div>
                        <div className="subtotal-divs">
                            <label>DISCOUNT</label>
                            <div>{receipt.discount}.00</div>
                        </div>
                        <div className="subtotal-divs">
                            <label>TOTAL</label>
                            <div>
                                <span>&#8358;</span> {(receipt.total - receipt.discount).toLocaleString()}.00
                            </div>
                        </div>
                    </div>
                    <div className="download-btn-container"></div>
                </div>
            </div>
        </div>
    );
};

export default ReceiptPage;
