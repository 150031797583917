import React from "react";
import { Grid } from "@material-ui/core";
import { loginStyles } from "./login.styles";
import { CustomInput } from "#/components/ui-components/input.component";
import { useForm } from "react-hook-form";
import Logo1 from "#/assets/images/liveizy-logo.png";
import CustomButton from "#/components/ui-components/button.component";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendLoginRequest } from "#/store/actions/auth.actions";
import { ErrorDiv } from "#/components/errorDiv";
import AuthPageContainer from "../AuthPageContainer";

const LoginForm = (props) => {
  const classes = loginStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const { register, watch, handleSubmit, setValue, errors } = useForm({
    mode: "onBlur"
  });
  const messages = useSelector((store) => store.notifications.msg);
  const values = watch();
  const [typing, setTyping] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value);

    //to show the visibilty toggle on the password input
    if (name === "password") {
      setTimeout(() => {
        setTyping(true);
      }, 500);
    }
  };

  //----------------------------------------------------------------
  // redirect to last active page if logged out for expired token
  //-----------------------------------------------------------------
  // const lastPage = () => {
  //   const lastVisited = localStorage.getItem("Last Visited");
  //   if (lastVisited) {
  //     const lastActive = JSON.parse(localStorage.getItem("Last Visited"));
  //     const { user } = JSON.parse(localStorage.getItem("data"));
  //     const { liveizy_id } = user;
  //     if (lastActive.liveizy_id === liveizy_id) {
  //       history.push(lastActive.pathname);
  //       localStorage.removeItem("Last Visited");
  //       return;
  //     } else if (lastActive.liveizy_id !== liveizy_id) {
  //       localStorage.removeItem("Last Visited");
  //       return;
  //     }
  //   } else return;
  // };

  const onFormSubmit = (vals) => {
    if (submitting) return;
    setSubmitting(true);
    dispatch(sendLoginRequest(vals, "", history, () => setSubmitting(false)));
  };

  return (
    <Grid container className={classes.formContainer}>
      <form className={classes.form} onSubmit={handleSubmit(onFormSubmit)}>
        <Grid
          item
          xs={12}
          style={{
            marginBottom: "2.5rem"
          }}>
          <div>
            <a href="https://liveizy.com/">
              <img
                src={Logo1}
                alt="logo"
                style={{
                  width: "6rem"
                }}
              />
            </a>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            marginBottom: "1.5rem"
          }}>
          <span
            style={{
              color: "#043B5C",
              fontSize: "1.2rem",
              fontWeight: "bold"
            }}>
            LOGIN
          </span>
        </Grid>
        <Grid item xs={12}>
          <CustomInput
            label="Email Address or Liveizy ID (LID)"
            name="email"
            error={errors.email && errors.email.message}
            customRef={register({
              required: "Please provide Your Email or Liveizy ID"
            })}
            value={values.email || ""}
            inputWidth="379px"
            inputHeight="50px"
            type="text"
            onChange={handleInputChange}
            placeholder="Your Email or Liveizy ID"
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInput
            label="Password"
            name="password"
            error={errors.password && "This field is required"}
            customRef={register({
              required: true
            })}
            value={values.password || ""}
            inputWidth="379px"
            typing={typing}
            inputHeight="50px"
            type="password"
            onChange={handleInputChange}
            placeholder="Password"
          />
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              color: "#1461A2",
              fontSize: "0.8rem",
              textAlign: "right",
              fontWeight: "bold"
            }}>
            {messages !== "" && <ErrorDiv>{messages}</ErrorDiv>}
            <Link to="/Forgetpassword" style={{ color: "#1461A2" }}>
              Forgot Password?
            </Link>
          </div>
        </Grid>
        <Grid item xs={12}>
          <CustomButton
            buttonText="Sign In"
            isLoading={submitting}
            style={{
              backgroundColor: "#4786FF",
              color: "#FFFFFF",
              width: "8rem",
              textTransform: "none",
              marginTop: "1.5rem",
              marginBottom: "1rem"
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              color: "#043B5C",
              fontSize: "0.8rem",
              fontWeight: "bold"
            }}>
            <span>Need a Liveizy Account? </span>
            <Link to="/register" style={{ color: "#1461A2" }}>
              Create an Account
            </Link>
          </div>
        </Grid>
      </form>
    </Grid>
  );
};

const Login = () => {
  return (
    <AuthPageContainer>
      <LoginForm />
    </AuthPageContainer>
  );
};

export default Login;
