import { makeStyles } from "@material-ui/core/styles";

export const usePopupStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#043B5C99"
  },

  container: {
    top: "-70%",
    marginTop: "100px",

    marginBottom: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "10px"
    }
  },

  root: {
    width: "650px",
    [theme.breakpoints.down("md")]: {
      width: "500px"
    },
    [theme.breakpoints.down("sm")]: {
      width: "350px"
    }
  },

  cardheader: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#043B5C",
    color: "#F4F5F7",
    padding: "40px 80px",
    [theme.breakpoints.down("sm")]: {
      padding: "40px 20px"
    },

    [theme.breakpoints.down("md")]: {
      padding: "40px 30px"
    },

    "& h6": {
      textTransform: "uppercase",
      fontSize: "18px",
      fontWeight: "700"
    },

    "& p": {
      fontSize: "14px"
    }
  },

  cardbody: {
    marginLeft: "70px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px !important",
      marginRight: "0px"
    },

    [theme.breakpoints.down("md")]: {
      marginLeft: "10px"
    }
  },

  cardGrid: {
    width: "calc(100% - 50px)",
    padding: "10px",
    margin: "10px 0 0 0",
    [theme.breakpoints.down("xs")]: {
      width: "calc(100% - 1px)"
    },
    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 1px)"
    }
  },

  btn: {
    position: "relative",
    paddingBottom: "10px",
    paddingTop: "20px",
    fontSize: "14px",
    color: "#043B5C",
    textTransform: "capitalize",
    backgroundColor: "#043B5C24",
    width: "220px",
    height: "80px",
    [theme.breakpoints.down("sm")]: {
      width: "150px",
      fontSize: "13px",
      lineHeight: "1.2"
    },
    fontWeight: "700"
  },

  sectionTitle: {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "10px",
    borderLeft: "4px solid #10609f",
    paddingLeft: "2px",
    textTransform: "Uppercase",
    lineHeight: 1.2
  },

  check: {
    position: "absolute",
    top: "-12%",
    right: "-5%"
  },

  spann: {
    color: "red",
    marginLeft: "-100px"
  },
  button: {
    padding: "12px 50px",
    backgroundColor: "#4786FF",
    borderColor: "#4786FF",
    color: "#F4F5F7",
    textTransform: "lowercase",
    fontSize: "13px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px"
    }
  },
  textPrimary: {
    color: "#043B5C",
    marginBottom: "20px",
    fontSize: "14px",

    marginLeft: "13px"
  },
  buttonX: {
    marginBottom: "30px",
    marginLeft: "13px"
  },
  icon: {
    fontSize: "40px",
    marginRight: "10px"
  },
  icons: {
    "& span": {
      display: "block",
      textAlign: "left",
      marginLeft: "10px",
      [theme.breakpoints.down("sm")]: {
        marginRight: "0px"
      }
    }
  }
}));
