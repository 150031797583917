import { CircularProgress, Grid, Hidden } from "@material-ui/core";
import React, { Fragment, useEffect, useRef, useCallback } from "react";
import ApartmentInfo from "./components/apartmentDetails/apartmentInfo";
import { Styles, TypographyText } from "./styles";
//import testImg1 from "#/assets/images/tenant/bed-bedroom-ceiling-chandelier-262048@2x.png";
import edit from "#/assets/images/edit.svg";
//import locationImg from "#/assets/images/location-pin (1).svg";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-material-ui-carousel";
import PerfectScrollbar from "react-perfect-scrollbar";
import useMobile from "#/hooks/util-hooks/useMobile";
import PageHeader from "#/components/propertycomponents/page-header";
import AddPropertyButton from "#/components/propertycomponents/button-component";
import Back from "#/components/propertycomponents/back-component";
import CustomButton from "#/components/ui-components/button.component";
import Summary from "./components/summary-component";
import { advertiseApartment } from "#/store/actions/apartment.actions";
import { getPropertyById } from "#/store/actions/property.actions";
import { returnUserData } from "#/apis/propertyServiceApi";

const PropertyApartments = (props) => {
    const { property_id } = props.match.params;
    const { liveizy_id } = returnUserData();
    const query = new URLSearchParams(props.location.search);
    //----------------------------------------------------------------------------------------
    //Other Helpers
    //----------------------------------------------------------------------------------------
    const { history } = props;
    const sampleContainer = {
        maxHeight: "30rem",
        display: "inherit"
    };
    //----------------------------------------------------------------------------------------
    //Helper hooks
    //----------------------------------------------------------------------------------------
    const dispatch = useDispatch();
    const { isMobile } = useMobile();
    const nodeRef = useRef();
    const classes = Styles();

    //----------------------------------------------------------------------------------------
    //Store
    //----------------------------------------------------------------------------------------
    const { propertyById, loading } = useSelector((store) => store.property);
    const { advertising, apartmentById } = useSelector((store) => store.apartment);
    const loadingCharges = useSelector((store) => store.charges.loading);

    //----------------------------------------------------------------------------------------
    //State
    //----------------------------------------------------------------------------------------
    const [status, setStatus] = React.useState("summary");
    const [aptId, setAptId] = React.useState("");
    const [imgInfo, setImgInfo] = React.useState("");

    //----------------------------------------------------------------------------------------
    //Helper functions
    //----------------------------------------------------------------------------------------

    const handleAdvertiseApartment = (apartmentId) => {
        const payload = { advert_status: true };
        dispatch(advertiseApartment(apartmentId, payload));
    };

    //Get image info
    const getImgInfo = () => {
        let info = "";
        switch (imgInfo) {
            case "":
                info = "Property";
                break;
            default:
                info = "Apartment";
        }
        return info;
    };

    //Get image
    const allImages =
        status === "summary"
            ? propertyById?.property_images?.map((image) => image) || []
            : propertyById?.apartment[status]?.apartment_images.map((image) => image) || [];

    //Fire when user clicks on edit property
    const handleStartEditingProperty = useCallback(() => {
        history.push({ pathname: `/property-edit/${property_id}` });
    }, [property_id]);

    //----------------------------------------------------------------------------------------
    //Side Effects
    //----------------------------------------------------------------------------------------
    useEffect(() => {
        if (propertyById && propertyById._id === property_id) return;
        dispatch(getPropertyById(property_id, "charges", "tenant"));
    }, []);

    useEffect(() => {
        const apartmentId = query.get("apartmentId");
        const index = propertyById?.apartment?.findIndex((apt) => apt._id === apartmentId);
        if (apartmentId && index >= 0) {
            setStatus(index);
            setAptId(apartmentId);
            setImgInfo(apartmentId);
        }
    }, [propertyById]);

    if (propertyById && propertyById.owner !== liveizy_id && propertyById.property_manager !== liveizy_id && !propertyById.otherManagers.includes(liveizy_id)) {
        history.push("/property/list");
        return null;
    }

    return (
        <Fragment>
            <Grid className={classes.containerHead}>
                {/* --------------------------------------------------------------------------------- */}
                {/* HEADER */}
                {/* --------------------------------------------------------------------------------- */}
                <Grid container direction="row" justifyContent="space-between" alignItems="center" className="sub-head">
                    <Grid item xs={10} md={7} lg={10}>
                        <Hidden mdDown>
                            <PageHeader>
                                <h3>PROPERTY DETAILS</h3>
                            </PageHeader>
                        </Hidden>
                        <Hidden mdUp>
                            <div style={{ display: "inline-block" }}>
                                <Back />
                            </div>
                        </Hidden>
                    </Grid>
                    <Grid item xs={2} md={3} lg={2}>
                        <Hidden lgUp>
                            <AddPropertyButton history={history} text={"ADD PROPERTY"} />
                        </Hidden>
                    </Grid>
                </Grid>

                {/* --------------------------------------------------------------------------------- */}
                {/* BODY */}
                {/* --------------------------------------------------------------------------------- */}
                {!loading && propertyById ? (
                    <div className={classes.root}>
                        <div className="contain">
                            <Grid container>
                                <Grid container className={classes.settingsHead}>
                                    {/* ------------------------------- */}
                                    {/* Back Button */}
                                    {/* ------------------------------- */}
                                    <Hidden mdDown>
                                        <Grid item lg={3} className="column1">
                                            <TypographyText variant="body2">
                                                <Back redirectUrl="/property/list" />
                                            </TypographyText>
                                        </Grid>
                                    </Hidden>

                                    {/* ------------------------------- */}
                                    {/* Image Section, (both the image and its details) */}
                                    {/* ------------------------------- */}
                                    <Grid item md={12} lg={3}>
                                        <div className="carousel-body">
                                            <div className="img">
                                                {
                                                    <Carousel autoPlay={true} nodeRef={nodeRef}>
                                                        {allImages.map((item, i) => (
                                                            <img
                                                                key={i}
                                                                src={item}
                                                                alt="img"
                                                                className="imgDiv"
                                                                ref={nodeRef}
                                                            />
                                                        ))}
                                                    </Carousel>
                                                }
                                            </div>
                                            <Hidden mdDown>
                                                <span>
                                                    <p className="image-text">
                                                        {getImgInfo()} {imgInfo} Images
                                                    </p>
                                                </span>
                                            </Hidden>
                                        </div>
                                    </Grid>

                                    {/* ------------------------------- */}
                                    {/* Edit the Property */}
                                    {/* ------------------------------- */}
                                    <Grid item md={12} lg={4} className={classes.mobileAddr}>
                                        <div className="property-details-section">
                                            <Hidden lgUp>
                                                <div></div>
                                                {/* Would be uncommented when map is integrated */}
                                                {/* <img src={locationImg} alt="" /> */}
                                            </Hidden>
                                            <div className="property-details">
                                                <p className="sub-text-header">{propertyById.title}</p>
                                                <p className="sub-text">
                                                    {propertyById.house_number} {propertyById.street}{" "}
                                                    {propertyById.state} {propertyById.country}
                                                </p>
                                            </div>
                                            <Hidden lgUp>
                                                <img
                                                    src={edit}
                                                    alt=""
                                                    onClick={handleStartEditingProperty}
                                                    style={{ cursor: "pointer" }}
                                                />
                                            </Hidden>
                                        </div>
                                    </Grid>
                                    <Hidden mdDown>
                                        <Grid item md className={classes.btnGrid}>
                                            <CustomButton
                                                type="button"
                                                buttonText="Edit Property"
                                                style={{
                                                    backgroundColor: "#4786FF",
                                                    color: "#E8F0F9",
                                                    textTransform: "none",
                                                    borderRadius: "5px",
                                                    color: "#F4F5F7",
                                                    border: "1px solid #4786FF",
                                                    padding: "8px 27px",
                                                    fontSize: "12px",
                                                    fontFamily: "Open Sans, sans-serif"
                                                }}
                                                onClick={handleStartEditingProperty}
                                            />
                                        </Grid>
                                    </Hidden>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={12} sm={12} md={3}>
                                        {/* ------------------------------- */}
                                        {/* Summary and Apartment List NAVIGATION (i.e Left column) */}
                                        {/* ------------------------------- */}
                                        <div className={classes.settingsBox}>
                                            <div className="settings">
                                                <PerfectScrollbar
                                                    style={sampleContainer}
                                                    options={{
                                                        suppressScrollX: !isMobile,
                                                        suppressScrollY: isMobile,
                                                        wheelPropagation: false
                                                    }}>
                                                    <div
                                                        className={
                                                            status === "summary"
                                                                ? "settings__data active"
                                                                : "settings__data"
                                                        }
                                                        onClick={() => {
                                                            setStatus("summary");
                                                            setImgInfo("");
                                                        }}>
                                                        <p>Summary</p>
                                                        <Hidden smDown>
                                                            <ArrowForwardIosIcon className={classes.settingsArrow} />
                                                        </Hidden>
                                                    </div>
                                                    {propertyById.apartment.map((data, i) => {
                                                        return (
                                                            <div
                                                                key={i}
                                                                className={
                                                                    status === i
                                                                        ? "settings__data active"
                                                                        : "settings__data"
                                                                }
                                                                onClick={() => {
                                                                    const apartmentId = data.apartment_id;
                                                                    setStatus(i);
                                                                    setAptId(apartmentId);
                                                                    setImgInfo(apartmentId);
                                                                }}>
                                                                <p>Apartment {data.apartment_id}</p>
                                                                <Hidden smDown>
                                                                    <ArrowForwardIosIcon
                                                                        className={classes.settingsArrow}
                                                                    />
                                                                </Hidden>
                                                            </div>
                                                        );
                                                    })}
                                                </PerfectScrollbar>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={9}>
                                        {/* ------------------------------- */}
                                        {/* Summary and Apartment List CONTENT (i.e Right column) */}
                                        {/* ------------------------------- */}
                                        <div className={classes.settingDisplay}>
                                            <div className="setting__display">
                                                {status === "summary" ? (
                                                    <Summary property={propertyById} />
                                                ) : (
                                                    <ApartmentInfo
                                                        property={propertyById}
                                                        index={status}
                                                        setStatus={setStatus}
                                                        handleAdvertiseApartment={handleAdvertiseApartment}
                                                        id={aptId}
                                                        loadingCharges={loadingCharges}
                                                        advertising={advertising}
                                                        checkingApartmentId={apartmentById}
                                                        propertyId={property_id}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                ) : (
                    <div className={classes.loader}>
                        <CircularProgress
                            size={90}
                            className="icon"
                            // style={{ height: 90 }}
                            color="inherit"
                        />
                    </div>
                )}
            </Grid>
        </Fragment>
    );
};

export default PropertyApartments;
