import { makeStyles } from "@material-ui/core/styles";

export const usePropertyDetailsStyles = makeStyles((theme) => ({
    header: {
        marginBottom: 30,
        display: "flex",
        justifyContent: "space-between",

        [theme.breakpoints.between("0", "700")]: {
            flexDirection: "column"
        },

        "& h1": {
            color: "#043B5C",
            fontWeight: 600,
            fontSize: 30,

            [theme.breakpoints.between("0", "700")]: {
                fontSize: 20
            }
        },

        "& button": {
            backgroundColor: "#4786FF",
            color: "#fff",
            border: 0,
            borderRadius: 5,
            padding: "10px 30px",

            [theme.breakpoints.between("0", "700")]: {
                padding: 5,
                borderRadius: "50%",
                alignSelf: "flex-end"
            },

            "& span": {
                marginTop: 2,
                marginLeft: 5,

                [theme.breakpoints.between("0", "700")]: {
                    display: "none"
                }
            }
        }
    },
    innerListingContainer: {
        display: "flex",
        maxWidth: "1465px",
        backgroundColor: "white",
        padding: "25px 26px",
        borderRadius: "15px",
        marginBottom: "100px",

        [theme.breakpoints.between("0", "700")]: {
            flexDirection: "column",
            overflowX: "scroll",
            padding: "25px 15px",
            marginBottom: "20px"
        },

        "& *": {
            color: "#043B5C"
        }
    },
    imagesContainer: {
        width: "60%",
        marginRight: "54px",
        maxWidth: "770px",

        [theme.breakpoints.between("0", "700")]: {
            width: "100%"
        },
        [theme.breakpoints.between("700", "1100")]: {
            width: "55%",
            marginRight: "34px"
        }
    },
    imagesBig: {
        width: "99%",
        marginBottom: "46px",

        [theme.breakpoints.between("0", "700")]: {
            width: "100%",
            marginBottom: "15px"
        },

        [theme.breakpoints.between("700", "1100")]: {
            width: "100%"
        },

        "& img": {
            width: "100%",
            height: 413,
            objectFit: "cover",
            borderRadius: "10px",

            [theme.breakpoints.between("0", "430")]: {
                height: 200
            },

            [theme.breakpoints.between("430", "600")]: {
                height: 300
            },

            [theme.breakpoints.between("600", "700")]: {
                height: 400
            },

            [theme.breakpoints.between("700", "1100")]: {
                height: 360
            }
        }
    },
    smallImagesDesktopView: {
        width: "100%",
        maxWidth: "770px",
        marginBottom: "30px",
        display: "flex",
        justifyContent: "space-between",

        [theme.breakpoints.between("700", "1100")]: {
            minWidth: "100%",
            overflowX: "scroll"
        },

        [theme.breakpoints.between("0", "700")]: {
            display: "none",
            width: "100%",
            overflowX: "scroll",
            justifyContent: "flex-start"
        },

        "& img": {
            width: "95%",
            maxWidth: "245px",
            height: 154,
            borderRadius: "10px",
            objectFit: "cover",

            [theme.breakpoints.between("700", "1100")]: {
                width: "95%",
                height: "100%"
            },

            [theme.breakpoints.between("0", "700")]: {
                width: "calc(100vw/3)",
                marginRight: 5,
                height: "100%"
            }
        }
    },
    smallImagesMobileView: {
        marginBottom: "30px",
        justifyContent: "space-between",
        display: "none",
        width: "100%",
        maxWidth: "770px",

        [theme.breakpoints.between("700", "1100")]: {
            minWidth: "100%",
            overflowX: "scroll"
        },

        [theme.breakpoints.between("0", "700")]: {
            width: "100%",
            display: "flex",
            overflowX: "scroll",
            justifyContent: "flex-start"
        },

        "& img": {
            width: "95%",
            maxWidth: "245px",
            height: 154,
            borderRadius: "10px",
            objectFit: "cover",

            [theme.breakpoints.between("700", "1100")]: {
                width: "95%",
                height: "100%"
            },

            [theme.breakpoints.between("0", "700")]: {
                width: "calc(100vw/3)",
                marginRight: 5,
                height: "100%"
            }
        }
    },
    seeMore: {
        width: "calc(100vw/3)",
        height: "100%",
        borderRadius: "10px",
        padding: "15px 10px",
        backgroundColor: "#F4F5F7"
    },
    details: {
        maxWidth: 491,
        width: "30%",

        [theme.breakpoints.between("0", "700")]: {
            width: "100%",
            maxWidth: "100%"
        },

        [theme.breakpoints.between("700", "1100")]: {
            width: 300
        },

        "& h1": {
            marginTop: 20,
            marginBottom: 15,
            fontWeight: 600
        },
        "& h4": {
            fontSize: 20,
            margin: 0
        },
        "& h3": {
            fontSize: 24,
            marginTop: 40
        },
        "& p": {
            width: "100%",
            fontSize: 16,
            marginBottom: 10,
            fontWeight: "normal"
        }
    },
    location: {
        display: "flex",
        marginTop: 10,
        marginBottom: 20,
        alignItems: "center",

        "& p": {
            margin: 0,
            fontSize: "14px",
            fontWeight: 200
        }
    },
    features: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "20px"
    },
    feature: {
        width: 112,

        "& .facility": {
            fontWeight: 600,
            fontSize: 15,
            color: "#043B5C96"
        },

        "& .facility-count": {
            display: "flex",
            alignItems: "baseline",

            "& h4": {
                marginRight: 10,
                fontWeight: 600
            },

            "& .img-wrapper": {
                width: "30px",

                "& img": {
                    width: "100%"
                }
            }
        }
    },
    btnMore: {
        color: "#4786FF",
        backgroundColor: "transparent",
        border: 0,
        fontWeight: 600,
        padding: 0
    },
    description: {
        marginBottom: "30px",

        "& h3": {
            fontWeight: 600
        },

        "& p": {
            fontWeight: 300,
            fontSize: 14,
            lineHeight: "18px"
        }
    },
    button: {
        color: "#F4F5F7",
        backgroundColor: "#4786FF",
        borderStyle: "none",
        borderRadius: "5px",
        padding: "7px 45px",
        fontSize: "12px"
    }
}));
