import React, { useCallback, useState, memo, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { CircularProgress } from "@material-ui/core";
//import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import ArrowDropdown from "#/components/customDropdowns/arrowDropdown/arrowDropdown";
import { convertGmtToAmOrPm, convertAmOrPmToGmt } from "#/components/utils/util";
import { OPTIONS_DAYS } from "#/components/apartmentForm/apartmentForm.constants";
import useArrowDropdown from "#/hooks/propertyservice-hooks/useArrowDropdown";
import {
    createSchedules,
    updateApartmentSchedule,
    getApartmentSchedules,
    deleteApartmentSchedule
} from "#/store/actions/schedules.action";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    }
}));

const Vacant = ({ classes, apartmentId, propertyId }) => {
    const accordionClasses = useStyles();
    //------------------------------------------------------------------
    //Helper hooks
    //------------------------------------------------------------------
    const dispatch = useDispatch();
    const { creating, updating, deleting, loading, apartmentSchedules } = useSelector((state) => state.schedules);

    //Custom hook
    const {
        dropdownOptions: sdDo,
        selectedOption: dayOfWeek,
        showDropDown: sdSd,
        handleToggleDropdown: sdHtd,
        handleSetSelectedOption: sdHsso,
        setSelectedOption
    } = useArrowDropdown(OPTIONS_DAYS);

    //------------------------------------------------------------------
    //State
    //------------------------------------------------------------------
    const [schedule, setSchedule] = useState({
        startTime: "",
        endTime: ""
    });
    const { startTime, endTime } = schedule;
    const [errors, setErrors] = useState({
        startTime: false,
        endTime: false,
        dayOfWeek: false
    });
    const [isEditing, setIsEditing] = useState(false);

    //------------------------------------------------------------------
    //Function Declarations
    //------------------------------------------------------------------
    //Set the schedule time
    const handleSetScheduleTime = useCallback((e) => {
        const { name, value } = e.target;

        setSchedule((prevState) => {
            return {
                ...prevState,
                [name]: value
            };
        });
    }, []);

    //Setting errors
    const handleSetErrors = useCallback((field) => {
        setErrors((prevState) => {
            return {
                ...prevState,
                [field]: true
            };
        });
    }, []);

    //removing errors(NOTE: this was not merged into the handleSetErrors function because of some edge cases)
    const handleRemoveErrors = useCallback((field) => {
        setErrors((prevState) => {
            return {
                ...prevState,
                [field]: false
            };
        });
    }, []);

    const handleStartEdit = useCallback(
        (day) => {
            const selectedDay = OPTIONS_DAYS.filter((item) => {
                return item.mainValue === day;
            });

            let times = apartmentSchedules[day][0].split("-");

            const start = convertAmOrPmToGmt(times[0].trim());

            const end = convertAmOrPmToGmt(times[1].trim());

            setSchedule({ startTime: start, endTime: end });
            setSelectedOption(selectedDay[0]);
            setIsEditing(true);
        },
        [apartmentSchedules]
    );

    //Submit a schedule
    const handleAddSchedule = useCallback(
        (e, isUpdating = false, isDeleting = false) => {
            e.preventDefault();

            const scheduleDate = dayOfWeek.mainValue;

            if (scheduleDate === "Pick a day") {
                return handleSetErrors("dayOfWeek");
            }

            if (startTime === "") {
                return handleSetErrors("startTime");
            }

            if (endTime === "") {
                return handleSetErrors("endTime");
            }

            const scheduleDuration = `${convertGmtToAmOrPm(startTime)} - ${convertGmtToAmOrPm(endTime)}`;

            let payload = {
                apartment_id: apartmentId,
                day: scheduleDate,
                period: [scheduleDuration]
            };

            if (isUpdating && !isDeleting) {
                dispatch(
                    updateApartmentSchedule(payload, () => {
                        dispatch(getApartmentSchedules(apartmentId));
                        setSchedule({
                            startTime: "",
                            endTime: ""
                        });
                        setSelectedOption(OPTIONS_DAYS[0]);
                        setIsEditing(false);
                    })
                );
            } else if (!isUpdating && isDeleting) {
                dispatch(
                    deleteApartmentSchedule(payload, () => {
                        dispatch(getApartmentSchedules(apartmentId));
                        setSchedule({
                            startTime: "",
                            endTime: ""
                        });
                        setSelectedOption(OPTIONS_DAYS[0]);
                        setIsEditing(false);
                    })
                );
            } else if (!isUpdating && !isDeleting) {
                dispatch(
                    createSchedules(payload, () => {
                        setSchedule({
                            startTime: "",
                            endTime: ""
                        });
                        setSelectedOption(OPTIONS_DAYS[0]);
                    })
                );
            }
        },
        [startTime, endTime, dispatch, apartmentId, propertyId, dayOfWeek.mainValue]
    );

    useEffect(() => {
        if (dayOfWeek.mainValue !== "Pick a day") {
            handleRemoveErrors("dayOfWeek");
        }

        if (startTime !== "") {
            handleRemoveErrors("startTime");
        }

        if (endTime !== "") {
            handleRemoveErrors("endTime");
        }
    }, [startTime, endTime, dayOfWeek.mainValue]);

    return (
        <div className={accordionClasses.root}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <p style={{ color: "#043b5c" }} className={classes.heading}>
                        Inspection schedules
                    </p>
                </AccordionSummary>
                <AccordionDetails>
                    <section className={classes.schedules}>
                        <div className="schedule-inputs">
                            {/* Schedule date */}
                            <ArrowDropdown
                                name="scheduleDate"
                                label="Add Schedule Date"
                                placeholder="Pick a day"
                                options={sdDo}
                                selectedOption={dayOfWeek}
                                showDropDown={sdSd}
                                handleShowDropdown={sdHtd}
                                handleSetSelectedOption={sdHsso}
                                error={errors.dayOfWeek}
                            />

                            {/* Schedule Time */}
                            <div className={classes.scheduleTimeWrapper}>
                                <label htmlFor="">Add Schedule Time</label>
                                <div className="time-wrapper">
                                    <TextField
                                        id="time"
                                        type="time"
                                        name="startTime"
                                        defaultValue={startTime}
                                        value={startTime}
                                        onChange={handleSetScheduleTime}
                                    />
                                    <span> - </span>
                                    <TextField
                                        id="time"
                                        type="time"
                                        name="endTime"
                                        defaultValue={endTime}
                                        value={endTime}
                                        onChange={handleSetScheduleTime}
                                    />
                                </div>
                                {errors.startTime && <div className={classes.errorMessage}>Field is required</div>}
                                {errors.endTime && <div className={classes.errorMessage}>Field is required</div>}
                            </div>
                            {isEditing ? (
                                <>
                                    <button
                                        type="submit"
                                        className={classes.btnBlue}
                                        onClick={(e) => handleAddSchedule(e, true)}
                                        style={{ marginRight: "10px" }}
                                        disabled={loading}>
                                        {updating && loading ? (
                                            <CircularProgress size={15} color="inherit" />
                                        ) : (
                                            "Update"
                                        )}
                                    </button>
                                    <button
                                        type="submit"
                                        className={classes.btnRed}
                                        onClick={(e) => handleAddSchedule(e, false, true)}
                                        disabled={loading}>
                                        {deleting && loading ? (
                                            <CircularProgress size={15} color="inherit" />
                                        ) : (
                                            "Delete"
                                        )}
                                    </button>
                                </>
                            ) : (
                                <button
                                    type="submit"
                                    className={classes.btnRed}
                                    onClick={handleAddSchedule}
                                    disabled={loading}>
                                    {loading && creating ? <CircularProgress size={15} color="inherit" /> : "Add"}
                                </button>
                            )}
                        </div>

                        {/* Schedule Box */}
                        <div className="schedule-box">
                            <h4>Schedule Dates</h4>
                            {apartmentSchedules &&
                                Object.keys(apartmentSchedules).map((schedule, i) => {
                                    if (apartmentSchedules[schedule]?.length)
                                        return (
                                            <div className="schedule" key={i} onClick={() => handleStartEdit(schedule)}>
                                                <div className="day">{schedule}</div>
                                                <div>
                                                    {apartmentSchedules[schedule]?.map((item) => (
                                                        <div className="time">{item}</div>
                                                    ))}
                                                </div>
                                                {/*<div style={{}}>
                                            <DeleteOutlinedIcon fontSize="small" />
                                        </div>*/}
                                            </div>
                                        );
                                })}
                        </div>
                    </section>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default memo(Vacant);
