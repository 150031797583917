import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

export const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5
    },
    colorPrimary: {
        backgroundColor: "#F4F5F7"
    },
    bar: {
        borderRadius: 5,
        backgroundColor: "#21A11C"
    }
}))(LinearProgress);

export const useRentReminderStyles = makeStyles((theme) => ({
    planDetailsContainer: {
        padding: "50px 30px",

        "@media(max-width:768px)": {
            backgroundColor: "#EEF4FB",
            padding: "30px 15px"
        },

        "& .MuiButton-root.MuiButton-text": {
            display: "block",
            marginLeft: "auto",
            marginTop: "40px",

            "@media(max-width:768px)": {
                width: "100%"
            }
        }
    },

    planDetailsContainerHeader: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "50px",

        "@media(max-width:768px)": {
            display: "none"
        },

        "& h1": {
            textTransform: "uppercase",
            fontWeight: "800"
        }
    },

    planDetailsContainerSummary: {
        display: "flex",
        flexWrap: "wrap",
        marginBottom: "20px",

        "@media(max-width:768px)": {
            display: "grid",
            gridTemplateColumns: "1fr 1fr"
        },

        "& .section": {
            marginRight: "20px",
            marginBottom: "20px",

            "& h3": {
                color: "#06223968",
                marginBottom: "15px"
            }
        }
    },

    plansUsage: {
        backgroundColor: "#fff",
        borderRadius: "10px",
        padding: "40px",
        marginBottom: "40px",

        "@media(max-width:768px)": {
            padding: "40px 20px"
        },

        "& h1": {
            marginBottom: "10px !important"
        },

        "& h3": {
            color: "#06223968"
        },

        "& .date": {
            marginBottom: "40px"
        },

        "& .linear-progress": {
            "&__title": {
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "15px"
            }
        }
    },

    subscriptionBenefits: {
        "& .title": {
            textTransform: "uppercase",
            fontWeight: "800",
            marginBottom: "20px"
        },

        "& .benefits-list": {
            listStyle: "none",
            paddingLeft: "10px",

            "& li": {
                display: "flex",
                alignItems: "center",
                marginBottom: "12px",
                color: "#043B5C",
                fontSize: "16px",

                "@media(max-width:768px)": {
                    fontSize: "14px"
                },

                "& svg": {
                    marginRight: "10px"
                }
            }
        }
    }
}));
