const REQUIRED = "This field is Required";
const MOBILE_LENGTH_ERRORMSG =
  "Invalid Phone Number Format. e.g +2348012345678, 07012345678";

export default {
  formId: "profileInfoForm",
  formField: {
    profileImage: {
      name: "profileImage",
      label: "Profile Image",
      requiredErrorMsg: "Profile Image is required",
      acceptedFileTypes: ["image/jpg", "image/png", "image/jpeg"],
      invalidFiletypeErrorMsg: "Unsupported format. File should be .jpg or .png or .jpeg"
    },
    lastName: {
      name: "lastName",
      label: "Last Name*",
      requiredErrorMsg: "Last Name is required"
    },
    firstName: {
      name: "firstName",
      label: "First Name*",
      requiredErrorMsg: "First Name is required"
    },
    otherName: {
      name: "otherName",
      label: "Other Name"
    },
    gender: {
      name: "gender",
      label: "Gender*",
      requiredErrorMsg: "Please select your gender"
    },
    dateOfBirth: {
      name: "dateOfBirth",
      label: "Date of Birth*",
      requiredErrorMsg: "Date of Birth is required",
      maxDateErrorMsg: "Please select a valid birth date."
    },
    stateOfOrigin: {
      name: "stateOfOrigin",
      label: "State of Origin*",
      requiredErrorMsg: "State of Origin  is required"
    },
    lga: {
      name: "lga",
      label: "Local Government Area of Origin*",
      requiredErrorMsg: "Local Government Area is required"
    },
    email: {
      name: "email",
      label: "Email Address",
      requiredErrorMsg: REQUIRED,
      invalidErrorMsg: "Email Address is not valid. e.g example@email.com"
    },
    alternateEmail: {
      name: "alternateEmail",
      label: "Alternate Email (Optional)",
      invalidErrorMsg: "Email Address is not valid. e.g example@email.com"
    },
    mobileNumber: {
      name: "mobileNumber",
      label: "Mobile number*",
      requiredErrorMsg: "Mobile Number is required",
      invalidLengthErrorMsg: MOBILE_LENGTH_ERRORMSG
    },
    alternateMobileNumber: {
      name: "alternateMobileNumber",
      label: "Alternate Mobile Number (Optional)",
      invalidLengthErrorMsg: MOBILE_LENGTH_ERRORMSG
    }
  }
};
