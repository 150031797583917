import React, { Fragment, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import avatar from "#/assets/images/tenant/default-avatar.png";
import phone from "#/assets/tenantdetails/phone.png";

const styles = (theme) => ({
    tenantsLists: {
        "& .tenant-card-wrapper": {
            padding: 15,
            marginBottom: 10,
            cursor: "pointer"
        },
        "& .tenant-container": {
            display: "flex",
            justifyContent: "space-between",
            color: "#043B5C",
            "& .tenant-contact": {
                display: "flex",
                color: "#043B5C",
                "& .img-container": {
                    marginRight: 6,
                    "& img": {
                        borderRadius: "100%",
                        width: 70,
                        height: 70
                    }
                },
                "& .tenant-name": {
                    color: "inherit",
                    fontSize: 16,
                    fontWeight: 600
                },
                "& .address-amount": {
                    display: "block",
                    fontSize: 12,
                    color: "inherit"
                }
            },
            "& .amount": {
                color: "inherit",
                fontSize: 14
            }
        }
    },
    contactWrapper: {
        color: "#043B5C",
        "& .back-icon": {
            backgroundColor: "#fff",
            padding: 15,
            "& .icon": {
                color: "inherit"
            }
        },
        "& .avater-name-container": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: "#EEF4FB",
            padding: 40,
            "& .img-container": {
                "& img": {
                    borderRadius: "100%",
                    width: 100,
                    height: 100
                },
                marginBottom: 10
            },
            "& .content-wrapper": {
                textAlign: "center",
                "& .tenant-name": {
                    fontSize: 20,
                    fontWeight: 700,
                    marginBottom: 10,
                    color: "inherit"
                },
                "& .tenant-contact-text": {
                    fontSize: 15,
                    color: "inherit",
                    marginBottom: 10,
                    display: "block",
                    fontWeight: 600
                }
            }
        }
    },
    leaseWrapper: {
        marginTop: 20,
        marginBottom: 20,
        padding: 18,
        color: "#043B5C",
        "& .lease-title": {
            fontSize: 18,
            fontWeight: 700,
            color: "inherit",
            paddingTop: "10px",
            paddingBottom: "10px"
        },
        "& .lease-details": {
            display: "flex",
            justifyContent: "space-between",
            "& span": {
                display: "block"
            },
            "& .current-lease-bold": {
                fontSize: 16,
                fontWeight: 600,
                color: "inherit",
                marginBottom: 3
            },
            "& .current-lease-light": {
                fontSize: 12,
                fontWeight: 600,
                color: "gray !important",
                marginBottom: 3
            },
            "& .text-align": {
                textAlign: "right"
            },
            "& .distance": {
                marginBottom: 8
            },
            "& .rent": {
                fontSize: 14,
                marginBottom: 3
            }
        }
    },
    Collection: {
        marginBottom: 20,
        "& .collection-details": {
            padding: 18,
            marginBottom: 8,
            color: "#043B5C",
            "& .collection-title": {
                padding: "15px 0",
                fontSize: 18,
                fontWeight: 700,
                color: "inherit"
            },
            "& .invoice": {
                fontSize: 15,
                fontWeight: 700,
                color: "inherit"
            },
            "& .past-invoices": {
                marginBottom: 5
            },
            "& span": {
                display: "block"
            },
            "& .invoice-record": {
                display: "flex",
                justifyContent: "space-between"
            },
            "& .invoice-record-text": {
                fontSize: 11,
                marginBottom: 4
            }
        },
        "& .invoice-btn": {
            textAlign: "center",
            color: "#fff",
            padding: 15,
            backgroundColor: "#4786FF",
            textTransform: "capitalize"
        }
    },
    History: {
        padding: 18,
        color: "#043B5C",
        "& .history-title": {
            padding: "15px 0",
            fontSize: 18,
            fontWeight: 700,
            color: "inherit"
        },
        "& span": {
            display: "block",
            fontSize: 14,
            color: "inherit",
            marginBottom: 5,
            "&:last-child": {
                marginBottom: 0
            }
        }
    }
});

const TenantInformationMobileView = (props) => {
    const { tenantLists, handleClick, tenant } = props;
    const [currentId, setCurrentId] = useState(0);
    const handleOnClick = (id) => {
        setCurrentId(id);
        handleClick(id);
    };
    const handleClickOnIcon = () => setCurrentId(0);
    return (
        <div>
            {currentId === 0 ? (
                <div className={props.classes.tenantsLists}>
                    {tenantLists.map((tenant) => (
                        <Card className="tenant-card-wrapper" onClick={() => handleOnClick(tenant.id)}>
                            <div className="tenant-container">
                                <div className="tenant-contact">
                                    <div className="img-container">
                                        <img src={avatar} alt="tenants" />
                                    </div>
                                    <div>
                                        <Typography className="tenant-name" variant="h5" component="h4">
                                            <b>{tenant.fullname}</b>
                                        </Typography>
                                        <span className="address-amount">Craven Drive Ibadan road</span>
                                        <span className="address-amount">Ekiti State</span>
                                        <span className="address-amount">Paid:9383737</span>
                                    </div>
                                </div>
                                <div>
                                    <span className="amount">&#x20A6;17,000</span>
                                </div>
                            </div>
                        </Card>
                    ))}
                </div>
            ) : (
                <Fragment>
                    <div className={props.classes.contactWrapper}>
                        <div className="back-icon">
                            <span onClick={handleClickOnIcon}>
                                <ChevronLeftIcon className="icon" />
                            </span>
                        </div>
                        <div className="avater-name-container">
                            <div className="img-container">
                                <img src={avatar} alt="tenants" />
                            </div>
                            <div className="content-wrapper">
                                <Typography className="tenant-name" variant="h5" component="h4">
                                    <b>{tenant.name}</b>
                                </Typography>
                                <span className="tenant-contact-text">Olekakelechi.ok@gmail.com</span>
                                <span className="tenant-contact-text">Tenant:Jun 28,2020</span>
                            </div>
                            <div className="phone-img">
                                <img src={phone} alt="tenant phone number" />
                            </div>
                        </div>
                    </div>
                    <Card className={props.classes.leaseWrapper}>
                        <Typography className="lease-title" variant="body1" component="p">
                            Current lease
                        </Typography>
                        <div className="lease-details">
                            <div>
                                <span className="current-lease-bold">{tenant.name}</span>
                                <span className="current-lease-light distance">Icreate Solution</span>
                                <span className="current-lease-light">Start Date</span>
                                <span className="current-lease-bold">Jun 18,2020</span>
                            </div>
                            <div>
                                <span className="rent">Current Rent</span>
                                <span className="current-lease-bold text-align distance">4000</span>
                                <span className="current-lease-light text-align">End Date</span>
                                <span className="current-lease-bold text-align">Quartely</span>
                            </div>
                        </div>
                    </Card>
                    <div className={props.classes.Collection}>
                        <Card className="collection-details">
                            <Typography className="collection-title" variant="body1" component="p">
                                Collection
                            </Typography>
                            <div style={{ marginBottom: "10px" }}>
                                <Typography className="invoice" variant="body1" component="p">
                                    Current Invoives
                                </Typography>
                                <Typography className="invoice" variant="body1" component="p">
                                    NO Record
                                </Typography>
                            </div>
                            <div className="past-invoices">
                                <Typography className="invoice" variant="body1" component="p">
                                    Past Due Invoices
                                </Typography>
                                <div className="invoice-record">
                                    <span className="invoice-record-text">344444,4494994 by 109 days</span>
                                    <span className="invoice-record-text">&#x20A6;17,000</span>
                                </div>
                                <div className="invoice-record">
                                    <span className="invoice-record-text">344444,4494994 by 55 days</span>
                                    <span className="invoice-record-text">&#x20A6;17,000</span>
                                </div>
                            </div>
                            <div>
                                <Typography className="invoice" variant="body1" component="p">
                                    Rent Collection
                                </Typography>
                                <div className="invoice-record">
                                    <span className="invoice-record-text">Total Rent Collected</span>
                                    <span className="invoice-record-text">&#x20A6;17,000</span>
                                </div>
                                <div className="invoice-record">
                                    <span className="invoice-record-text">Other Collection</span>
                                    <span className="invoice-record-text">&#x20A6;17,000</span>
                                </div>
                                <div className="invoice-record">
                                    <span className="invoice">Total</span>
                                    <span className="invoice-record-text">&#x20A6;17,000</span>
                                </div>
                            </div>
                        </Card>
                        <Button variant="contained" className="invoice-btn" fullWidth>
                            View all Invoice
                        </Button>
                    </div>
                    <Card className={props.classes.History}>
                        <Typography className="history-title" variant="body1" component="p">
                            Lease History
                        </Typography>
                        <span>Start Date Jun 16,2020</span>
                        <span>End Date Jun 16,2020</span>
                        <span>20 days remaining</span>
                    </Card>
                </Fragment>
            )}
        </div>
    );
};

export default withStyles(styles)(TenantInformationMobileView);
