import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { toCurrency } from "#/components/utils/util";

const GradientPaper = styled(Paper)`
  color: white;
  padding: 10px;
  background: linear-gradient(
    70.17deg,
    #052137 7.39%,
    rgba(13, 46, 72, 0.88) 56.82%,
    rgba(5, 33, 55, 0.78) 99.37%,
    rgba(7, 36, 59, 0.827247) 99.66%
  );
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.action.focus,
    color: "#ffff",
    fontWeight: "bold",
    fontSize: 15
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: 0,
    color: "#ffff"
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.selectedOpacity
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}));

function createData(name, calories, fat, carbs) {
  return { name, calories, fat, carbs };
}

const rows = [
  createData("Security, March 21", 159, 6.0, 24, 4.0),
  createData("Security, March 2021", 237, 9.0, 37, 4.3),
  createData("Security, March 2021", 262, 16.0, 24, 6.0),
  createData("Rent, June 2021", 305, 3.7, 67, 4.3)
];

export default function PayoutHistoryTable() {
  return (
    <TableContainer component={GradientPaper}>
      <Table aria-label="customized table">
        <TableHead sx={{ borderBottom: 1 }}>
          <TableRow>
            <StyledTableCell>Amount</StyledTableCell>
            <StyledTableCell align="right">Commission</StyledTableCell>
            <StyledTableCell align="right">Date Paid</StyledTableCell>
            <StyledTableCell align="right">Bank Details</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {toCurrency(row.calories)}
              </StyledTableCell>
              <StyledTableCell align="right">{toCurrency(row.fat)}</StyledTableCell>
              <StyledTableCell align="right">{"21-03-2023"}</StyledTableCell>
              <StyledTableCell align="right">
                <small>Access Bank</small>
                <br />
                <small>0045678909</small>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
