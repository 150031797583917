import useFetch from "#/hooks/util-hooks/useFetch";
import React, { useState } from "react";
import { Button, CircularProgress, styled } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { AiOutlineDown } from "react-icons/ai";

const ExternalTeams = () => {
  const { data, loading } = useFetch(`/myteams`);
  const [openPermissions, setOpenPermissions] = useState(false);

  const handleNavigate = (propertyId) => {
    window.open(`/property-list/property-apartment/${propertyId}`, "_blank");
  };

  if (loading)
    return (
      <div className="tw-flex mtop-8 tw-justify-center tw-items-center">
        <CircularProgress />
      </div>
    );

  return (
    <>
      <h1 className="tw-text-[2rem] tw-font-semibold tw-text-[#062239] tw-my-7">
        Teams I Belong To
      </h1>

      {!loading && data?.data.length === 0 ? (
        <h3 style={{ marginTop: "3rem" }}>
          This user doesn't belong to any external teams!
        </h3>
      ) : (
        data?.data?.map((team) => (
          <AccordionDiv key={team._id}>
            <AccSummary
              expandIcon={<AiOutlineDown />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <h1 className="tw-text-[1rem] tw-font-semibold">
                {team?.business?.legal_name}
              </h1>
            </AccSummary>
            <AccordionDetails>
              <h1 className="tw-my-3 tw-text-[1rem] tw-font-semibold">Properties</h1>
              {team?.propertyInfo.length === 0 && <p>No Property infomation</p>}
              {team?.propertyInfo?.map((property) => (
                <AccordionDiv key={property._id}>
                  <AccSummary
                    expandIcon={<AiOutlineDown />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <div className="tw-flex tw-items-center tw-justify-between tw-w-full">
                      <h1>Property ID: {property?.property_id}</h1>
                      <div className="tw-flex tw-items-center tw-gap-5">
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          onClick={() => setOpenPermissions(true)}>
                          View Permissions
                        </Button>
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          onClick={() => handleNavigate(property?.property_id)}>
                          View Property
                        </Button>
                      </div>
                    </div>
                  </AccSummary>
                  <AccordionDetails style={{ marginTop: "1rem" }}>
                    {openPermissions ? (
                      <>
                        {property?.permissions.map((perm) => (
                          <div
                            className="tw-flex tw-items-center tw-justify-between tw-bg-white tw-shadow tw-px-5 tw-py-5 tw-my-5 tw-rounded-md"
                            key={perm.name}>
                            <h1 className="tw-capitalize">
                              <span className="tw-font-semibold">Name: </span>
                              {perm?.name}
                            </h1>
                            <ul className="tw-flex tw-gap-7">
                              <span className="tw-font-semibold">Permissions:</span>
                              {perm?.permissions.map((p) => (
                                <li className="tw-capitalize" key={p}>
                                  {p}
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </>
                    ) : (
                      <p>No Permissions here</p>
                    )}
                  </AccordionDetails>
                </AccordionDiv>
              ))}
            </AccordionDetails>
          </AccordionDiv>
        ))
      )}
    </>
  );
};

export default ExternalTeams;

const AccordionDiv = styled(Accordion)`
  margin: 20px auto;
  width: 100%;
  padding: 0px;
  height: auto;
  font-size: 14px;
`;

const AccSummary = styled(AccordionSummary)`
  width: 100%;
  padding: 10px;
  height: 70px;
  margin: auto 0;
  background: white;
  box-shadow: 0px 4.2992px 31.1692px -8.59841px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`;
