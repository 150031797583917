import React from "react";
import Layout from "./layout";

const Container = (props) => {
  return (
    <React.Fragment>
      <Layout>{props.children}</Layout>
    </React.Fragment>
  );
};

export default Container;
