import { Grid } from "@mui/material";
import React from "react";
import InputField from "../UIcomponents/formfields/InputField";

const ReferenceForm = ({ fields }) => {
  const {
    contactOfNextOfKin,
    contactOfreference,
    emailOfNextOfKin,
    nameOfNextOfKin,
    nameOfReference,
    nextOfKinHomeAddress
  } = fields;
  return (
    <div>
      <Grid sx={{ marginTop: "20px" }} container spacing={2}>
        <Grid item xs={12} sm={6}>
          <InputField name={nameOfNextOfKin.name} label={nameOfNextOfKin.label} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={contactOfNextOfKin.name} label={contactOfNextOfKin.label} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={emailOfNextOfKin.name} label={emailOfNextOfKin.label} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={nextOfKinHomeAddress.name}
            label={nextOfKinHomeAddress.label}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={nameOfReference.name} label={nameOfReference.label} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField name={contactOfreference.name} label={contactOfreference.label} />
        </Grid>
      </Grid>
    </div>
  );
};

export default ReferenceForm;
