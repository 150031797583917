import { useState } from "react";

const useAddressDropdown = () => {
    //--------------------------------------------------------------------------------
    //States
    //--------------------------------------------------------------------------------
    const [address, setAddress] = useState({
        houseNumber: "",
        line: "",
        state: "",
        lga: "",
        lcda: "",
        otherAddress: ""
    });
    const [errors, setErrors] = useState({
        houseNumber: false,
        line: false,
        state: false,
        lga: false,
        lcda: false
    });

    //--------------------------------------------------------------------------------
    //Handle updating of address fields
    //--------------------------------------------------------------------------------
    const handleOnChangeAddress = (e) => {
        let { value, name } = e.target;

        //Numeric validation for housenumber
        if (name === "houseNumber") {
            value = value.match(/^[\w.-]+$/g) || []; //allow only digits, NOTE: match could return null hence the empty array second option
            value = value.join("");
        }

        setAddress((prevState) => {
            return {
                ...prevState,
                [name]: value
            };
        });
    };

    //--------------------------------------------------------------------------------
    //Validate Input
    //--------------------------------------------------------------------------------
    const handleOnBlur = (e) => {
        const { value, name } = e.target;

        if (value.length === 0) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    [name]: true
                };
            });
        } else {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    [name]: false
                };
            });
        }
    };

    return {
        addressDropdownHelpers: {
            address,
            errors,
            handleOnBlur,
            handleOnChangeAddress,
            setAddress
        }
    };
};

export default useAddressDropdown;
