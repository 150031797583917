import React from "react";
import { makeStyles } from "@material-ui/core";

const styles = makeStyles((theme) => ({
    pageTitle: {
        margin: "0 auto 40px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        "& h3": {
            fontSize: "20px",
            fontWeight: 600,
            textTransform: "uppercase"
        }
    }
}));

const PageTitle = ({ title }) => {
    const classes = styles();

    return (
        <div className={classes.pageTitle}>
            <h3 className="bank-details-header">{title}</h3>
        </div>
    );
};

export default PageTitle;
