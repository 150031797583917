import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { CircularProgress, styled } from "@mui/material";
import CustomBtn from "#/components/buttons/CustomBtn";
import { propertyServiceApi } from "#/apis/propertyServiceApi";
import FlexCenter from "#/components/flexboxes/FlexCenter";
import { selectRentWallet } from "#/store/actions/izypay.actions";
import GenerateVBAModal from "./GenerateVBAModal";
import CopyToClipboardBtn from "#/shared/components/buttons/CopyToClipboardBtn";

const RentWallet = ({ totalPayable, reference, fullData }) => {
  const rentWallet = useSelector(selectRentWallet);
  const [showVBAModal, setShowVBAModal] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentAccountData, setPaymentAccountData] = useState(null);
  const [newAccountData, setNewAccountData] = useState(null);

  const handleSetVBAModalVisibility = () => {
    setShowVBAModal(!showVBAModal);
  };

  const handlePaymentModalVisibility = () => {
    setIsPaymentModalOpen(!isPaymentModalOpen);
  };

  useEffect(() => {
    let isMounted = true;

    const fetchInvoiceData = async () => {
      if (!fullData || !fullData?._id) return;

      try {
        const response = await propertyServiceApi().get(
          `/invoice/get_invoice/${fullData?._id}`
        );
        if (isMounted) {
          setInvoiceData(response.data);
        }
      } catch (error) {
        if (isMounted) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchInvoiceData();

    return () => {
      isMounted = false;
    };
  }, [fullData, fullData?._id]);

  useEffect(() => {
    let isMounted = true;

    const fetchPaymentAccountData = async () => {
      if (!isPaymentModalOpen || !invoiceData) return;
      setIsLoading(true);

      try {
        const response = await propertyServiceApi().get(
          `/invoice/getInvoice-admin?invoice_ref=${fullData.invoice_ref}`
        );
        const existingAccountData = response.data.data.data;

        if (existingAccountData && isMounted) {
          setPaymentAccountData(existingAccountData);
        } else {
          const newAccountResponse = fullData.unpaid_bills.some(
            (bill) => bill.is_loan_rent && bill.is_disburse
          )
            ? await propertyServiceApi().post(
                `/invoice/register-loan-invoice/${fullData._id}`
              )
            : await propertyServiceApi().post(
                `/invoice/register-invoice/${fullData._id}`
              );

          if (isMounted) {
            setNewAccountData(newAccountResponse.data.data);
          }
        }
      } catch (error) {
        if (isMounted) {
          console.error("Error fetching payment account data:", error);
        }

        try {
          const newAccountResponse = fullData.unpaid_bills.some(
            (bill) => bill.is_loan_rent && bill.is_disburse
          )
            ? await propertyServiceApi().post(
                `/invoice/register-loan-invoice/${fullData._id}`
              )
            : await propertyServiceApi().post(
                `/invoice/register-invoice/${fullData._id}`
              );

          if (isMounted) {
            setNewAccountData(newAccountResponse.data.data);
          }
        } catch (error) {
          if (isMounted) {
            console.error("Error creating payment account:", error);
          }
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchPaymentAccountData();

    return () => {
      isMounted = false;
    };
  }, [isPaymentModalOpen, invoiceData, fullData]);

  const renderDisplay = () => {
    if (rentWallet?.wallet.status === "pnd") {
      console.log("Activate LR");
      return (
        <div
          style={{
            textAlign: "center",
            marginTop: "2rem",
            textTransform: "uppercase",
            color: "#10609F",
            textDecoration: "underline"
          }}>
          <a
            href={rentWallet?.wallet?.validation_url}
            target="_blank"
            rel="noopener noreferrer">
            Activate LR Account
          </a>
        </div>
      );
    }

    if (invoiceData) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
          }}>
          <BankDetails>
            {isLoading ? (
              <>
                <p>Fetching one time payment info...</p>
                <FlexCenter>
                  <CircularProgress />
                </FlexCenter>
              </>
            ) : (
              <>
                {paymentAccountData ? (
                  <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
                    <p>Bank: {paymentAccountData?.account?.bank} Microfinance</p>
                    <p>Account Name: {paymentAccountData?.account?.name}</p>
                    <p>Account Number: {paymentAccountData?.account?.number}</p>
                    <p>
                      Payment Narration:{" "}
                      <span style={{ color: "#10609f" }}>{reference}</span>
                    </p>
                  </div>
                ) : (
                  <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
                    <p>Bank: {newAccountData?.account?.bank} Microfinance</p>
                    <p>Account Name: {newAccountData?.account?.name}</p>
                    <div className="tw-flex tw-items-center tw-gap-3">
                      <p>Account Number: {newAccountData?.account?.number}</p>
                      <CopyToClipboardBtn
                        text={newAccountData?.account?.number}
                        alertMessage={"Account number copied"}
                      />
                    </div>
                    <p>
                      Payment Narration:{" "}
                      <span style={{ color: "#10609f" }}>{reference}</span>
                    </p>
                  </div>
                )}
              </>
            )}
          </BankDetails>
          {paymentAccountData ? (
            <p className="tw-text-[15px] tw-text-[#C8202D] tw-font-medium tw-my-3">
              Copy & add Ref ID to transfer description when crediting wallet
            </p>
          ) : (
            <p className="tw-text-[15px] tw-text-[#C8202D] tw-font-medium tw-my-3">
              Click Pay Now button only when you are ready to make your Payment
            </p>
          )}
          <CustomBtn
            sx={{ fontSize: "0.80rem", width: "20rem", marginTop: "10px" }}
            onClick={handlePaymentModalVisibility}>
            {paymentAccountData ? "Refresh payment data" : "Pay Now"}
          </CustomBtn>
        </div>
      );
    }
  };

  return (
    <>
      {showVBAModal && (
        <GenerateVBAModal
          openModal={showVBAModal}
          handleSetModalVisibility={handleSetVBAModalVisibility}
        />
      )}
      {renderDisplay()}
    </>
  );
};

export default RentWallet;

const BankDetails = styled("div")`
  flex: 1.4;
  text-align: center;
`;
