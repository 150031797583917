import React, { useState } from "react";
import { propertyServiceApi } from "#/apis/propertyServiceApi";
import { useFormik } from "formik";
import Modal from "#/components/modal/modal.component";
import { useStyles } from "../../../TenantDashboard/components/MonthlyRentLoanModal/style";
import { formatPrice } from "#/shared/utils/general";
import { styled } from "@mui/material";
import { FaTimes } from "react-icons/fa";

const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");
const removeLeadingZero = (num) => num.toString().replace(/^0+/, "");

const MonthlyRentCalculator = ({ handleSetModalVisibility }) => {
  const classes = useStyles();
  const [rentValue, setRentValue] = useState("");
  const [contributionValue, setContributionValue] = useState("");
  const [formValue, setFormValue] = useState("");
  const [loading, setLoading] = useState("");

  const handleInputChange = (event, setValue) =>
    setValue(addCommas(removeNonNumeric(removeLeadingZero(event.target.value))));

  const formik = useFormik({
    initialValues: {
      price: "",
      tenant_contribution: ""
    },
    onSubmit: (values) => {
      setLoading(true);

      const cleanedValues = {
        price: Number(removeNonNumeric(values.price)),
        tenant_contribution: Number(removeNonNumeric(values.tenant_contribution))
      };

      propertyServiceApi()
        .post("/loan-calculate", cleanedValues)
        .then((data) => {
          setFormValue(data.data.payload);
          setLoading(false);
        })
        .catch(function (error) {
          // console.log("Fetching loan values failed");
          return Promise.reject(error);
        });
    }
  });

  const renderBody = () => {
    return (
      <>
        <div
          className="tw-pt-7 tw-pr-7 tw-flex tw-justify-end"
          onClick={handleSetModalVisibility}>
          <FaTimes color="#10609F" size={25} className="tw-cursor-pointer" />
        </div>
        <div className="content-center">
          <h2
            style={{ textAlign: "center", marginBottom: "3rem" }}
            className={classes.modalHeader}>
            <span className="tw-text-[1.7rem] tw-font-semibold">
              Calculate Monthly Rent
            </span>
          </h2>

          <form noValidate onSubmit={formik.handleSubmit}>
            <Input
              onChange={(e) => {
                formik.handleChange(e);
                handleInputChange(e, setRentValue);
              }}
              label={"Enter Annual Rent Amount"}
              type={"text"}
              name={"price"}
              placeholder={""}
              value={rentValue}
              required
            />
            <Input
              onChange={(e) => {
                formik.handleChange(e);
                handleInputChange(e, setContributionValue);
              }}
              label={"Contribution"}
              type={"text"}
              name={"tenant_contribution"}
              placeholder={""}
              value={contributionValue}
              required
            />
            <p className="tw-text-[#C8202D]">
              <span className="tw-font-bold">Note:</span> Contribution is the amount of
              the rent you would be paying. Leave it at zero if you want us to finance the
              total rent.
            </p>

            {formValue && (
              <div className="tw-mt-16">
                <div className="calc-details tw-flex tw-justify-between tw-items-center tw-mb-7 flexCol">
                  <p className="tw-text-[#052137]">Monthly Rent Amount</p>
                  <h2 className="px-3 py-3 tw-text-center tw-text-[17px] tw-font-semibold tw-rounded-lg wFull">
                    {formatPrice(formValue?.monthly_payment)}
                  </h2>
                </div>
                <div className="calc-details tw-flex tw-justify-between tw-items-center tw-mb-7 flexCol">
                  <p className="tw-text-[#052137]">Refundable Security Deposit</p>
                  <h2 className="px-3 py-3 tw-text-center tw-text-[17px] tw-font-semibold tw-rounded-lg wFull">
                    {formatPrice(formValue?.security_deposit)}
                  </h2>
                </div>
                <div className="calc-details tw-flex tw-justify-between tw-items-center tw-mb-7 flexCol">
                  <p className="tw-text-[#052137]">Subscription fee</p>
                  <h2 className="px-3 py-3 tw-text-center tw-text-[17px] tw-font-semibold tw-rounded-lg wFull">
                    {formatPrice(formValue?.subscription_fee)}
                  </h2>
                </div>
                <div className="calc-details tw-flex tw-justify-between tw-items-center tw-mb-7 flexCol">
                  <p className="tw-text-[#052137]">Contribution</p>
                  <h2 className="px-3 py-3 tw-text-center tw-text-[17px] tw-font-semibold tw-rounded-lg wFull">
                    {formatPrice(formValue?.tenant_contribution)}
                  </h2>
                </div>

                <div className="calc-total tw-text-center tw-mt-16 tw-w-[80%] tw-mx-auto wFull">
                  <h1 className="tw-text-[#05213799 ] tw-font-black tw-text-[23px] tw-mb-5">
                    Total First payment
                  </h1>
                  <h1
                    className="tw-text-[#052137] tw-text-[22px] p-4 tw-rounded-3xl wFull"
                    style={{ boxShadow: "1px 1px 1rem #00000020" }}>
                    {formatPrice(formValue?.total_first_payment)}
                  </h1>
                  <p className="tw-text-[#10609F] tw-mt-2">
                    Recurring monthly payment:
                    <span className="tw-font-bold">
                      {formatPrice(formValue?.monthly_payment)}
                    </span>
                  </p>
                </div>
              </div>
            )}

            <div className="btn-wrapper tw-mt-28">
              <div className="tw-flex tw-items-center tw-justify-center">
                <button
                  type="submit"
                  className="tw-uppercase tw-text-white tw-bg-[#10609F] tw-py-4 tw-px-16 tw-rounded-2xl">
                  {loading ? "Calculating..." : "Calculate"}
                </button>
              </div>
            </div>
          </form>

          <br />
        </div>
      </>
    );
  };

  return (
    <Modal
      isModalOpen={true}
      handleCloseModal={handleSetModalVisibility}
      hasHeader={false}
      style={{ maxWidth: "50rem", borderRadius: 10 }}>
      {renderBody()}
    </Modal>
  );
};

export default MonthlyRentCalculator;

function Input({ label, type, name, placeholder, required, value, onChange, disabled }) {
  return (
    <>
      {" "}
      <div className="tw-flex tw-flex-col tw-mb-7">
        <label htmlFor={name} className="tw-text-[#05213794] tw-font-semibold">
          {label}
        </label>
        <InputWrapper>
          <p>₦</p>
          <InputFile
            id={name}
            onChange={onChange}
            name={name}
            type={type}
            autoComplete="off"
            required={required}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
          />
        </InputWrapper>
      </div>
    </>
  );
}

const InputFile = styled("input")`
  border: none;
  width: 100%;
  color: black;
  background: transparent;

  :focus {
    outline: none;
  }
`;

const InputWrapper = styled("div")`
  display: flex;
  padding: 10px;
  gap: 5px;
  align-items: center;
  width: 100%;
  height: 60px;
  font-size: 15px;
  background: linear-gradient(
        103deg,
        rgba(245, 245, 245, 1) 0%,
        rgba(242, 243, 244, 1) 50%,
        rgba(251, 251, 251, 1) 100%
      )
      padding-box,
    linear-gradient(
        103deg,
        rgba(223, 224, 224, 1) 0%,
        rgba(242, 243, 244, 1) 50%,
        rgba(219, 219, 219, 1) 100%
      )
      border-box;
  border: 2px solid transparent;
  border-radius: 10px;
`;
