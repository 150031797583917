import { formatNigerianPhoneNumber } from "#/components/utils/util";
import {
  getOtherInformation,
  updateOtherInformation
} from "#/store/actions/auth.actions";
import { showNotifications } from "#/store/actions/notification.actions";

const configAuthService = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "X-Requested-With": "XMLHttpRequest"
  }
};

const postProfileImageToCloudinary = async (profileImage) => {
  try {
    const formData = new FormData();
    formData.append("file", profileImage);
    formData.append("upload_preset", "monthly_rent_applicants_profile_images");

    const data = await fetch("https://api.cloudinary.com/v1_1/dfz1vgrgv/image/upload", {
      method: "POST",
      body: formData
    })
      .then((response) => response.json())
      .catch((error) => {
        console.log(error); // do something else, like dispatch error message
      });
    return data.secure_url;
  } catch (error) {
    console.log(error); // do something else
  }
};

export const employmentStatusSubmit = async (values, dispatch, setEditMode) => {
  const employmentInfo = {
    employer_name: values?.nameOfEmployer,
    employment_status: values?.employmentStatus,
    office_address: values?.officeAddress,
    office_contact_person_name: values?.nameOfOfficeContactPerson,
    office_contact_person_phone_number: formatNigerianPhoneNumber(
      values?.officeContactPersonNumber
    ),
    monthly_income: values?.monthlyIncome
  };
  dispatch(updateOtherInformation(employmentInfo, () => setEditMode(false)));
  dispatch(getOtherInformation());
};

export const tenancyInfoSubmit = async (values, dispatch, setEditMode) => {
  const tenancyInfo = {
    current_home_address: values?.currentHomeAddress,
    manager_name: values?.nameOfManager,
    landlord_name: values?.nameOfLandlord,
    current_rent_value: values?.currentRentValue,
    landlord_contact: formatNigerianPhoneNumber(values?.contactOfLandlord),
    manager_contact: formatNigerianPhoneNumber(values?.contactOfManager)
  };
  dispatch(
    updateOtherInformation(tenancyInfo, () => {
      dispatch(getOtherInformation());
      setEditMode(false);
    })
  );
  dispatch(getOtherInformation());
};

export const meansOfIdSubmit = async (values, dispatch, setEditMode) => {
  const meansOfIdFile = await postProfileImageToCloudinary(values?.meansOfIdFile);
  const data = {
    means_of_id: values?.meansOfId,
    means_of_id_file: meansOfIdFile,
    contact_of_next_kin: formatNigerianPhoneNumber(values?.contactOfNextOfKin),
    name_of_next_of_kin: values?.nameOfNextOfKin
  };
  dispatch(
    updateOtherInformation(data, () => {
      dispatch(getOtherInformation());
      setEditMode(false);
    })
  );
};
