import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { string, bool, func } from "prop-types";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import clsx from "clsx";

export const CustomInput = ({
    name,
    error,
    customRef,
    value,
    inputWidth = "180px",
    inputHeight = "50px",
    type = "text",
    disabled = false,
    typing = false,
    label,
    onChange,
    onBlur,
    variant,
    placeholder = "",
    defaultValue
}) => {
    const useStyles = makeStyles({
        root: {
            width: "100%",
            height: inputHeight,
            minHeight: "2rem",
            minWidth: "8rem",
            border: "none",
            borderRadius: "10px",
            backgroundColor: "#FFFFFF",
            fontSize: "0.9rem",
            color: "#043B5C",
            fontWeight: "bold",
            paddingLeft: "1rem"
        },
        label: {
            color: "#043B5C",
            fontSize: "0.7rem"
        },
        inputBox: {
            marginBottom: "0.2rem",
            position: "relative",

            "& > i": {
                position: "absolute",
                right: "20px",
                bottom: "15px",
                cursor: "pointer"
            }
        }
    });

    const [view, setView] = useState(true);
    const [passType, setPassType] = useState("");

    const toggleView = () => {
        setView((prev) => !prev);

        if (view) {
            setPassType("text");
        } else {
            setPassType("password");
        }
    };

    useEffect(() => {
        const passwordType = type === "password" ? "password" : "";
        setPassType(passwordType);
    }, [type]);

    const classes = useStyles();

    return (
        <div>
            <div className={classes.label}>
                <label>{label}</label>
            </div>
            <div className={classes.inputBox}>
                <input
                    name={name}
                    disabled={disabled}
                    className={classes.root}
                    ref={customRef}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    type={type === "password" ? passType : type}
                    value={value}
                    onChange={onChange}
                />
                {typing
                    ? type === "password" && (
                          <i onClick={toggleView}>
                              {view === true ? (
                                  <VisibilityOutlinedIcon fontSize="small" />
                              ) : (
                                  <VisibilityOffOutlinedIcon fontSize="small" />
                              )}
                          </i>
                      )
                    : ""}
            </div>
            <div>
                {error && (
                    <p
                        id={`${name}-component-error-text`}
                        style={{
                            fontSize: "0.8rem",
                            color: "red"
                        }}>
                        {error}
                    </p>
                )}
            </div>
        </div>
    );
};

CustomInput.propTypes = {
    name: string,
    disabled: bool,
    type: string,
    error: string,
    customRef: func,
    value: string,
    label: string,
    inputWidth: string,
    inptHeight: string,
    onChange: func,
    variant: string
};

export default CustomInput;
