import axios from "axios";
import { showNotifications } from "#/store/actions/notification.actions";
import { PLANS_CONSTANTS } from "../constants";

const {
    CREATE_APARTMENT_LIPMAN_PLAN_PENDING,
    CREATE_APARTMENT_LIPMAN_PLAN_SUCCESS,
    CREATE_APARTMENT_LIPMAN_PLAN_FAIL,
    CREATE_APARTMENT_LIREM_PLAN_PENDING,
    CREATE_APARTMENT_LIREM_PLAN_SUCCESS,
    CREATE_APARTMENT_LIREM_PLAN_FAIL
} = PLANS_CONSTANTS;

const BASE_URL = process.env.REACT_APP_PROPERTY_SERVICE_BASE_URL;

export const createApartmentLiPManPlan = (payload, cb) => async (dispatch) => {
    dispatch({ type: CREATE_APARTMENT_LIPMAN_PLAN_PENDING });

    try {
        const response = await axios.post(`${BASE_URL}/liveizy-plan-module/plans/apartment/multiple`, payload);
        if ([200, 201].includes(response.status)) {
            dispatch({
                type: CREATE_APARTMENT_LIPMAN_PLAN_SUCCESS,
                payload: response.data.payload
            });
            dispatch(showNotifications(`Apartments Added to LiPMan Plan`, "success"));
            cb();
        }
    } catch (error) {
        console.log("lipman error", error);
        dispatch({ type: CREATE_APARTMENT_LIPMAN_PLAN_FAIL, error });
        dispatch(showNotifications(`${error.response.data.error}`, "error"));
    }
};

export const createApartmentLiReMPlan = (payload, cb) => async (dispatch) => {
    dispatch({ type: CREATE_APARTMENT_LIREM_PLAN_PENDING });

    try {
        const response = await axios.post(`${BASE_URL}/liveizy-plan-module/plans/apartment/multiple`, payload);
        if ([200, 201].includes(response.status)) {
            dispatch({
                type: CREATE_APARTMENT_LIREM_PLAN_SUCCESS,
                payload: response.data.payload
            });
            dispatch(showNotifications(`Apartments Added to Lirem Plan`, "success"));
            cb();
        }
    } catch (error) {
        dispatch({ type: CREATE_APARTMENT_LIREM_PLAN_FAIL, error });
        dispatch(showNotifications(`${error.response.data.error}`, "error"));
    }
};
