import React from "react";
import { BiSearch } from "react-icons/bi";
import { FiEdit3 } from "react-icons/fi";

import loading from "#/assets/loading.png";
import onion from "#/assets/images/onion.jpeg";
import bellPepper from "#/assets/images/bell-pepper.jpeg";
import redPepper from "#/assets/images/red-pepper.jpeg";

const IzyMart = () => {
  return (
    <div className="tw-w-[95%] tw-mx-auto tw-my-20">
      <div className="tw-w-1/2 tw-mx-auto tw-flex tw-flex-col tw-items-center tw-justify-center">
        <img src={loading} alt="Loader" className="tw-mb-7" />
        <h1 className="tw-text-[2rem] tw-text-[#05213780] tw-mb-14 tw-font-bold tw-text-center">
          MARKETPLACE COMING SOON
        </h1>
      </div>
      <div className="tw-border-[4px] tw-border-[#10609F] tw-rounded-xl tw-flex tw-justify-between tw-w-full">
        <div className="tw-flex tw-items-center tw-gap-5 tw-pl-10 tw-w-full">
          <FiEdit3 size={20} color="#C8202D" />
          <input
            type="text"
            placeholder="Search for food by name (e.g tomato, beans, rice)"
            className="tw-text-[#5A5A5A] pad tw-text-baseTwo tw-font-medium tw-w-full tw-placeholder:font-semibold tw-placeholder:text-[#5A5A5A] tw-placeholder:text-x"
          />
        </div>
        <Button className="tw-py-5 btn-one" icon={<BiSearch size={23} />} text="Search" />
      </div>
      <Button
        className="tw-py-4 tw-w-full tw-my-3 btn-two"
        icon={<BiSearch size={23} />}
        text="Search"
      />

      <div className="tw-grid tw-grid-cols-[repeat(auto-fit,minmax(400px,1fr))] tw-gap-5 tw-my-20">
        <MarketCard img={onion} />
        <MarketCard img={bellPepper} />
        <MarketCard img={redPepper} />
        <MarketCard img={onion} />
        <MarketCard img={bellPepper} />
        <MarketCard img={redPepper} />
      </div>
    </div>
  );
};

export default IzyMart;

const MarketCard = ({ img }) => {
  return (
    <div className="tw-shadow-md tw-rounded-[1.7rem]">
      <div className="tw-bg-slate-200 tw-rounded-[1.7rem]">
        <img src={img} alt="item" />
      </div>
      <div className="tw-content tw-p-10">
        <h1 className="tw-text-[1.5rem] tw-text-[#052137] tw-font-bold tw-mb-2">
          Onions - Spices
        </h1>
        <p className="tw-text-[15px] tw-leading-relaxed">
          Lorem ipsum dolor sit amet consectetur. Pharetra at hac diam dolor et justo
          pretium. Interdum felis congue pellentesque commodo amet aliquam tincidunt.
          Ultricies pellentesque risus id.
        </p>
        <div className="tw-my-5">
          <div className="tw-flex tw-justify-between tw-items-center">
            <div className="tw-text-x tw-text-[#052137]">
              <p className="tw-mb-5">
                Available Today: <span className="tw-font-bold">20 KG</span>
              </p>
              <p>
                Price: <span className="tw-font-bold">N500 per KG</span>
              </p>
            </div>
            <div className="tw-border-[3px] tw-border-[#10609F] tw-flex tw-flex-col tw-gap-3 tw-py-1 tw-px-5 tw-rounded-3xl">
              <label htmlFor="weight" className="tw-text-[1rem]">
                Weight (KG)
              </label>
              <input
                value={5}
                type="number"
                name="weight"
                id="weight"
                className="tw-text-x"
              />
            </div>
          </div>
          <div className="tw-flex tw-justify-between tw-mt-3">
            &nbsp;
            <p className="tw-text-x tw-text-[#052137]">
              Price: <span className="tw-font-bold">N2,500</span>
            </p>
          </div>
        </div>
        <div className="tw-flex tw-justify-between tw-items-center tw-mt-10">
          <h3 className="tw-text-[#C8202D] tw-text-[1rem] tw-font-bold">
            Order ends in 3hrs 4min
          </h3>
          <Button className="tw-rounded-2xl" size="tw-text-base" text="ADD TO CART" />
        </div>
      </div>
    </div>
  );
};

const Button = ({ className, text, icon, size = "tw-text-base" }) => {
  return (
    <button
      className={`${
        className || null
      } tw-bg-[#10609F] tw-rounded-lg tw-py-5 tw-px-10 tw-text-white tw-flex tw-items-center tw-justify-center ${
        icon && `tw-gap-5`
      }`}>
      {icon}
      <p className={`tw-uppercase ${size}`}>{text}</p>
    </button>
  );
};
