import React, { memo, useEffect } from "react";
import { useMultipleImages } from "./multipleImagesUploader.style";
import cancelIcon from "#/assets/propertyPage/cancel.svg";

const MultipleImages = ({
    multipleImagesHelpers,
    header = "Property Images",
    isForApartment = false,
    previouslyUploadedImages = [],
    isForEdit = false,
    handleRemovePreviouslyUploadedImage
}) => {
    const classes = useMultipleImages();

    const {
        allMultipleImagesPreview,
        handleSelectImages,
        handleSelectMoreImages,
        handleRemoveImage,
        imgPickerRef
    } = multipleImagesHelpers;

    useEffect(() => {
        //Perform a clean up when component unmounts
        return () => {
            allMultipleImagesPreview.forEach((o) => {
                !!o.imagePreview && URL.revokeObjectURL(o.imagePreview);
            });
        };
    }, [allMultipleImagesPreview]);

    return (
        <div className={classes.root}>
            <label htmlFor="propertyImages">{header}</label>
            {previouslyUploadedImages.length > 0 ? (
                <div className={classes.previousImagesWrapper}>
                    {previouslyUploadedImages.map((item, i) => {
                        return (
                            <div className={classes.previousImage} key={i} style={{ backgroundImage: `url(${item})` }}>
                                <div className="cancel-icon">
                                    <img
                                        src={cancelIcon}
                                        alt="cancel"
                                        onClick={() => handleRemovePreviouslyUploadedImage(i)}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : null}
            {!isForApartment && !isForEdit && <h6>Select LPM for this Property</h6>}
            <section className={classes.inputContainer}>
                <input
                    name="propertyImages"
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    multiple
                    ref={imgPickerRef}
                    onChange={handleSelectImages}
                />
                <button type="button" className={classes.btnRed} onClick={handleSelectMoreImages}>
                    Add more Images
                </button>
            </section>
            <section className={classes.imagesContainer}>
                {allMultipleImagesPreview.map((item, i) => {
                    const { file, imagePreview } = item;
                    return (
                        <div className="image" key={`${file?.name} + ${i}`}>
                            <div className="cancel-icon">
                                <img src={cancelIcon} alt="cancel" onClick={() => handleRemoveImage(file?.name)} />
                            </div>
                            <div className="image__photo" style={{ backgroundImage: `url(${imagePreview})` }}></div>
                            <h5 className="image__title">{file?.name}</h5>
                            <h6 className="image__size">{`${(file?.size / 1000000).toFixed(2)}MB`}</h6>
                        </div>
                    );
                })}
            </section>
        </div>
    );
};

export default memo(MultipleImages);
