import React, { useState } from "react";
import { propertyServiceApi } from "#/apis/propertyServiceApi";
import { useFormik } from "formik";
import Modal from "#/components/modal/modal.component";
import { CircularProgress, styled } from "@mui/material";
import { FaTimes } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { alertSuccess } from "#/store/actions/notification.actions";

const is_new = [
  {
    label: "No",
    value: false
  },
  {
    label: "Yes",
    value: true
  }
];

const rentRangeOptions = [
  "100k-200k",
  "201k-300k",
  "301k-400k",
  "401k-500k",
  "501k-600k",
  "601k-700k",
  "701k-800k",
  "800k-900k",
  "901k-1m",
  "1.1m-1.2m",
  "1.2m-1.3m",
  "1.3m-1.4m",
  "1.4m-1.5m",
  "1.5m-1.6m",
  "1.6m-1.7m",
  "1.7m-1.8m",
  "1.8m-1.9m",
  "1.9m-2m",
  "2.1m-2.2m",
  "2.2m-2.3m",
  "2.3m-2.4m",
  "2.4m-2.5m",
  "2.5m-2.6m",
  "2.6m-2.7m",
  "2.7m-2.8m",
  "2.8m-2.9m",
  "2.9m-3m",
  "3.1m-3.2m",
  "3.2m-3.3m",
  "3.3m-3.4m",
  "3.4m-3.5m",
  "3.5m-3.6m",
  "3.6m-3.7m",
  "3.7m-3.8m",
  "3.8m-3.9m",
  "3.9m-4m",
  "4.1m-4.2m",
  "4.2m-4.3m",
  "4.3m-4.4m",
  "4.4m-4.5m",
  "4.5m-4.6m",
  "4.6m-4.7m",
  "4.7m-4.8m",
  "4.8m-4.9m",
  "4.9m-5m"
];

const LoanRenewalModal = ({ details, handleSetModalVisibility, refetch, retry }) => {
  const dispatch = useDispatch();

  const initialValues = {
    is_new_apartment: null,
    apartment_rent_range: "",
    apartment_id: details.apartment_id,
    current_rent_value: details.price
  };

  const [loading, setLoading] = useState("");

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      setLoading(true);

      const formattedValues = {
        ...values,
        is_new_apartment: values.is_new_apartment === "true"
      };
      propertyServiceApi()
        .post(`/renewal-application${retry ? "?type=retry" : ""}`, formattedValues)
        .then((data) => {
          setLoading(false);
          handleSetModalVisibility();
          dispatch(alertSuccess(data?.data?.message));
          refetch();
        })
        .catch(function (error) {
          // console.log("Fetching loan values failed");
          return Promise.reject(error);
        });
    }
  });

  const renderBody = () => {
    return (
      <>
        <div
          className="tw-pt-7 tw-pr-7 tw-flex tw-justify-end"
          onClick={handleSetModalVisibility}>
          <FaTimes color="#10609F" size={25} className="tw-cursor-pointer" />
        </div>
        <div className="content-center">
          <h2 style={{ textAlign: "center", marginBottom: "3rem" }} className="">
            <span className="tw-text-[1.7rem] tw-font-semibold">Rent Loan Renewal</span>
          </h2>

          <form noValidate onSubmit={formik.handleSubmit}>
            <Input
              label={"Is this a new apartment"}
              name={"is_new_apartment"}
              options={is_new}
              formik={formik}
            />
            {formik.values.is_new_apartment === "true" && (
              <Input
                label={"Apartment Rent Range"}
                name={"apartment_rent_range"}
                options={rentRangeOptions.map((option) => ({
                  label: option,
                  value: option
                }))}
                formik={formik}
              />
            )}
            <div className="btn-wrapper tw-mt-16">
              <div className="tw-flex tw-items-center tw-justify-center">
                <button
                  type="submit"
                  className="tw-uppercase tw-text-white tw-bg-[#10609F] tw-py-4 tw-px-16 tw-rounded-2xl">
                  {loading ? <CircularProgress /> : "submit"}
                </button>
              </div>
            </div>
          </form>

          <br />
        </div>
      </>
    );
  };

  return (
    <Modal
      isModalOpen={true}
      handleCloseModal={handleSetModalVisibility}
      hasHeader={false}
      style={{ maxWidth: "50rem", borderRadius: 10 }}>
      {renderBody()}
    </Modal>
  );
};

export default LoanRenewalModal;

function Input({ label, name, options, formik }) {
  return (
    <div className="tw-flex tw-flex-col tw-mb-7">
      <label htmlFor={name} className="tw-text-[#05213794] tw-font-semibold">
        {label}
      </label>
      <InputWrapper>
        <select
          className="tw-w-full tw-bg-transparent tw-text-[17px] tw-outline-none"
          id={name}
          name={name}
          value={formik.values[name]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}>
          <option value="" label="Select" />
          {options.map((el) => (
            <option key={el.label} value={el.value}>
              {el.label}
            </option>
          ))}
        </select>
      </InputWrapper>
    </div>
  );
}

const InputWrapper = styled("div")`
  display: flex;
  padding: 10px;
  gap: 5px;
  align-items: center;
  width: 100%;
  height: 60px;
  font-size: 15px;
  background: linear-gradient(
        103deg,
        rgba(245, 245, 245, 1) 0%,
        rgba(242, 243, 244, 1) 50%,
        rgba(251, 251, 251, 1) 100%
      )
      padding-box,
    linear-gradient(
        103deg,
        rgba(223, 224, 224, 1) 0%,
        rgba(242, 243, 244, 1) 50%,
        rgba(219, 219, 219, 1) 100%
      )
      border-box;
  border: 2px solid transparent;
  border-radius: 10px;
`;
