import { APARTMENT_ACTIONTYPES_CONSTANTS } from "../constants";

//------------------------------------------------------------------
//Variables and Helpers
//------------------------------------------------------------------
const {
  GET_PM_PO_TOTAL_APARTMENT_RENT_VALUE,
  GET_PM_PO_TOTAL_NUM_APARTMENTS,

  GET_APARTMENT_ID_FAIL,
  GET_APARTMENT_ID_SUCCESS,
  GET_APARTMENT_ID_PENDING,

  CREATE_APARTMENT_FAIL,
  CREATE_APARTMENT_PENDING,
  CREATE_APARTMENT_SUCCESS,

  GET_APARTMENT_BY_ID_FAIL,
  GET_APARTMENT_BY_ID_PENDING,
  GET_APARTMENT_BY_ID_SUCCESS,

  UPDATE_APARTMENT_FAIL,
  UPDATE_APARTMENT_SUCCESS,
  UPDATE_APARTMENT_PENDING,

  GET_VACANT_APARTMENTS_FAIL,
  GET_VACANT_APARTMENTS_PENDING,
  GET_VACANT_APARTMENTS_SUCCESS,

  FILTER_VACANT_APARTMENTS_FAIL,
  FILTER_VACANT_APARTMENTS_SUCCESS,
  FILTER_VACANT_APARTMENTS_PENDING,

  RESET_FILTER,

  ADVERTISE_APARTMENTS_PENDING,
  ADVERTISE_APARTMENTS_SUCCESS,
  ADVERTISE_APARTMENTS_FAIL
} = APARTMENT_ACTIONTYPES_CONSTANTS;

const initialState = {
  apartmentId: "",
  loading: false,
  creatingApartment: false,
  updatingApartment: false,
  creatingApartmentErr: null,
  allApartmentInfo: [],
  apartmentById: null,
  allVacantApartments: [],
  gettingVacantApartments: false,
  filtering: false,
  showFilteredResult: false,
  filteredVacantApartments: [],
  advertising: false,
  totalRentValue: null
};

const apartmentReducer = (state = initialState, action) => {
  const { type, payload, message } = action;

  switch (type) {
    case GET_PM_PO_TOTAL_APARTMENT_RENT_VALUE:
      return {
        ...state,
        totalRentValue: payload
      };
    case GET_PM_PO_TOTAL_NUM_APARTMENTS:
      return {
        ...state,
        totalNumApartments: payload
      };
    case GET_APARTMENT_ID_PENDING:
      return {
        ...state
      };
    case GET_APARTMENT_ID_SUCCESS:
      return {
        ...state,
        apartmentId: payload
      };
    case GET_APARTMENT_ID_FAIL:
      return {
        ...state
      };
    case CREATE_APARTMENT_PENDING:
      return {
        ...state,
        creatingApartment: true
      };
    case CREATE_APARTMENT_SUCCESS:
      return {
        ...state,
        creatingApartment: false,
        creatingApartmentErr: null,
        allApartmentInfo: payload,
        message: message
      };
    case CREATE_APARTMENT_FAIL:
      return {
        ...state,
        creatingApartment: false,
        creatingApartmentErr: payload,
        allApartmentInfo: null
      };
    case GET_APARTMENT_BY_ID_PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_APARTMENT_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        apartmentById: action.apartmentById
      };
    }
    case GET_APARTMENT_BY_ID_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case UPDATE_APARTMENT_PENDING:
      return {
        ...state,
        updatingApartment: true
      };
    case UPDATE_APARTMENT_SUCCESS:
      return {
        ...state,
        updatingApartment: false
      };
    case UPDATE_APARTMENT_FAIL:
      return {
        ...state,
        updatingApartment: false
      };
    case GET_VACANT_APARTMENTS_PENDING:
      return {
        ...state,
        gettingVacantApartments: true
      };
    case GET_VACANT_APARTMENTS_SUCCESS:
      return {
        ...state,
        gettingVacantApartments: false,
        allVacantApartments: payload
      };
    case GET_VACANT_APARTMENTS_FAIL:
      return {
        ...state,
        gettingVacantApartments: false
      };
    case FILTER_VACANT_APARTMENTS_PENDING:
      return {
        ...state,
        filtering: true,
        showFilteredResult: false
      };
    case FILTER_VACANT_APARTMENTS_SUCCESS:
      return {
        ...state,
        filtering: false,
        showFilteredResult: true,
        filteredVacantApartments: payload
      };
    case FILTER_VACANT_APARTMENTS_FAIL:
      return {
        ...state,
        filtering: false,
        showFilteredResult: false
      };
    case RESET_FILTER:
      return {
        ...state,
        showFilteredResult: false,
        filteredVacantApartments: []
      };

    case ADVERTISE_APARTMENTS_PENDING:
      return {
        ...state,
        advertising: true
      };
    case ADVERTISE_APARTMENTS_SUCCESS:
      return {
        ...state,
        advertising: false
      };
    case ADVERTISE_APARTMENTS_FAIL:
      return {
        ...state,
        advertising: false
      };
    default:
      return state;
  }
};

export default apartmentReducer;
