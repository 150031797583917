import { authServiceApi } from "#/apis/authServiceApi";
import { walletServiceApi } from "#/apis/izyPayApi";
import CustomBtn from "#/components/buttons/CustomBtn";
import CustomInputAndLabelField from "#/components/customInputFields/CustomInputAndLabelField";
import LoadingModal from "#/components/loadingModal/LoadingModal";
import { alertError } from "#/store/actions/notification.actions";
import { Dialog, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

const GenerateVBAModal = ({ openModal, handleSetModalVisibility }) => {
  const dispatch = useDispatch();
  const { liveizy_id } = useSelector((state) => state.auth.user);
  const [bvn, setBvn] = useState("");
  const [bvnError, setBvnError] = useState("");
  const [dob, setDob] = useState("");
  const [dobError, setDobError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");

  const onSubmit = async () => {
    if (!bvn) return setBvnError("Please provide your BVN");
    if (bvn.length !== 11 || !/^\d+$/.test(bvn))
      return setBvnError("Your BVN should be 11 digits");
    if (!dob) return setDobError("Please provide your Date Of Birth");

    setLoading(true);
    try {
      setLoadingText("Updating Profile Info");
      await authServiceApi().put("/user/profile", { bvn, date_of_birth: dob });
      setLoadingText("Generating Virtual Bank Account...");
      await walletServiceApi().post(
        `/user/izypay/account/reserve/rent?user_liveizy=${liveizy_id}`
      );
    } catch (error) {
      setLoading(false);
      dispatch(
        alertError(error.response.data?.message || "An Error occurred. Try again")
      );
    } finally {
      handleSetModalVisibility();
    }
  };

  useEffect(() => {
    if (bvnError && bvn.length === 11) setBvnError(false);
  }, [bvn, bvnError]);

  useEffect(() => {
    if (dobError) setDobError(false);
  }, [dob]);

  return (
    <>
      <Dialog open={openModal} onClose={handleSetModalVisibility}>
        <CloseText onClick={handleSetModalVisibility}>Close</CloseText>
        <Wrapper>
          <InfoText>
            This virtual Bank Account (VBA) will be used only for all your rental payments
            including and not limited to rent payment, charges, fees, repairs, etc.
            <br />
            <br />
            <span>
              Note: your VBA is not tied to this apartment, it is unique to you and will
              be available for use when you decide to move to a new apartment.
            </span>
            <br />
            <br />
            Please, provide the accurate information below to generate your VBA.
          </InfoText>

          <InputSection>
            <CustomInputAndLabelField
              value={bvn}
              type={"text"}
              label={"BVN"}
              error={bvnError}
              onChange={(event) => setBvn(removeNonNumeric(event.target.value))}
            />
            <CustomInputAndLabelField
              value={dob}
              type={"date"}
              label={"Date of Birth"}
              error={dobError}
              onChange={(event) => setDob(event.target.value)}
            />
          </InputSection>

          <CustomBtn onClick={onSubmit}>Submit</CustomBtn>
        </Wrapper>
      </Dialog>
      {loading ? <LoadingModal text={loadingText} /> : null}
    </>
  );
};

export default GenerateVBAModal;

const Wrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  padding: 1.5rem;
  gap: 0.8rem;
  background: #fff;
`;

const InfoText = styled("em")`
  font-size: 0.8rem;
  color: #10609f;
  span {
    color: #c8202d;
  }
`;

const InputSection = styled("section")`
  display: grid;
  gap: 0.7rem;
  margin-bottom: 0.7rem;
`;

const CloseText = styled("p")`
  color: #10609f;
  font-size: 0.75rem;
  font-weight: 600;
  text-decoration-line: underline;
  cursor: pointer;
  text-transform: uppercase;
  text-align: end;
  padding: 5px;
  margin: 10px 10px 1px auto;
  border-radius: 8px;
  transition: all 0.3s ease-in;
  :hover {
    background: #cfe2f0;
  }
`;
