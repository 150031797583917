import React, { useState, useCallback, useEffect } from "react";
import { profileStyles } from "./profileStyle";
// import CustomInputField from "#/components/ui-components/customInput.component";
// import ArrowDropdown from "#/components/customDropdowns/arrowDropdown/arrowDropdown";
// import useArrowDropdown from "#/hooks/propertyservice-hooks/useArrowDropdown";
import { OPTIONS_GENDER } from "../accountSettting.constants";
import NaijaStates from "naija-state-local-government";
// import { useForm } from "react-hook-form";
import Button from "#/components/ui-components/button.component";
import { useDispatch, useSelector } from "react-redux";
import { getProfileInformation } from "#/store/actions/auth.actions";
import DetailsCardContainer, {
  DetailsContainer
} from "#/components/cards/DetailsCardContainer.component";
import SubtitleAndSubtext from "#/components/ui-components/SubtitleAndSubtext.component";
// import ProfilePicAndButton from "#/components/ui-components/ProfilePicAndButton";
import { Grid, Stack } from "@mui/material";

import { Formik, Form, useFormikContext } from "formik";
import formModel from "./formModel";
import InputField from "#/components/formik/formfields/InputField";
import SelectField from "#/components/formik/formfields/SelectField";
import DatePickerField from "#/components/formik/formfields/DatePickerField";
import formInitialValues from "./formInitialValues";
import validationSchema from "./validationSchema";
import naijaStateLocalGovernment from "naija-state-local-government";
import ProfilePicUpload from "#/components/formik/formfields/ProfilePicUpload";
import { submitForm } from "./utils";
import { showNotifications } from "#/store/actions/notification.actions";
import { useHistory } from "react-router-dom";
import ProfilePictureWrapper from "#/components/ui-components/ProfilePictureWrapper";

const {
  formField: {
    profileImage,
    lastName,
    firstName,
    otherName,
    gender,
    dateOfBirth,
    stateOfOrigin,
    lga,
    email,
    alternateEmail,
    mobileNumber,
    alternateMobileNumber
  }
} = formModel;

const ProfileInformation = () => {
  const history = useHistory();
  const { updatingInfo, profileInformation, otherInformation } = useSelector(
    (state) => state.auth
  );
  const initialValues = formInitialValues(profileInformation, otherInformation);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfileInformation());
  }, []);

  //Validating Phone Number
  const validatePhoneNo = (value) => {
    // let values = value.replace(/[^0-9]*/g, "");
    // if (value.length > 11 || value !== values) return "Should be a phone number";

    value = value.match(/\d/g) || []; //allow only digits, NOTE: match could return null hence the empty array second option
    value = value.join("");
    if (value.length > 11) return "This no be phone number NAAH";
    if (value.length < 11) return "Please enter a 11 digits";
  };

  const onFormSubmit = (vals) => {
    // const data = {
    //     first_name,
    //     last_name,
    //     state_of_origin: stateselectedOption.mainValue,
    //     gender: selectedOption.mainValue,
    //     ...rest
    // };
    // console.log(data);
    // dispatch(updateProfileInformation(data));
  };

  async function handleSubmit(values, actions) {
    try {
      // post form data
      await submitForm(values, dispatch, setEditMode);
      actions.setSubmitting(false);
      history.push("/account-setting/profile");
    } catch (error) {
      dispatch(showNotifications("An error ocurred", "error"));
      history.push("/account-setting/profile");
    }
  }

  const classes = profileStyles();

  const [editMode, setEditMode] = useState(false);
  const handleEditMode = (e) => {
    e.preventDefault();
    setEditMode(!editMode);
  };

  return (
    <div className={classes.root}>
      {!editMode ? (
        <>
          <DetailsCardContainer style={{ width: "auto" }}>
            <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
              <ProfilePictureWrapper src={profileInformation?.profile_image} />
            </div>
            <DetailsContainer>
              <SubtitleAndSubtext
                loading={updatingInfo}
                subtext={profileInformation?.first_name}
                subtitle={"First Name"}
              />
              <SubtitleAndSubtext
                loading={updatingInfo}
                subtext={profileInformation?.last_name}
                subtitle={"Last Name"}
              />
              <SubtitleAndSubtext
                loading={updatingInfo}
                subtext={otherInformation?.other_name}
                subtitle={"Other Name"}
              />
              <SubtitleAndSubtext
                loading={updatingInfo}
                subtext={profileInformation?.gender}
                subtitle={"Gender"}
              />
              <SubtitleAndSubtext
                loading={updatingInfo}
                subtext={profileInformation?.date_of_birth}
                subtitle={"Date Of Birth"}
              />

              <SubtitleAndSubtext
                loading={updatingInfo}
                subtext={profileInformation?.email}
                subtitle={"Email"}
              />
              <SubtitleAndSubtext
                loading={updatingInfo}
                subtext={profileInformation?.phone}
                subtitle={"Mobile Number"}
              />
              <SubtitleAndSubtext
                loading={updatingInfo}
                subtext={profileInformation?.state_of_origin}
                subtitle={"State Of Origin"}
              />
              <SubtitleAndSubtext
                loading={updatingInfo}
                subtext={profileInformation?.local_governement_area}
                subtitle={"Local Government Area"}
              />
            </DetailsContainer>
          </DetailsCardContainer>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginTop: "60px"
            }}>
            <Button
              type="button"
              buttonText="Edit Profile Details"
              onClick={handleEditMode}
              style={{ background: "#10609F", color: "white" }}
            />
          </div>
        </>
      ) : (
        <>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
              <Form>
                <ProfileForm />
                <div className={classes.formText}>
                  <p>
                    Liveizy is an online community, designed to ensure ease of living for
                    its members. Please, note all information provided are protected and
                    can only be shared by you to a 3rd party
                  </p>
                </div>
                <Stack direction={"row"} spacing={2} justifyContent="center">
                  <Button
                    type="submit"
                    buttonText="Cancel"
                    isLoading={updatingInfo}
                    className={classes.btn_save}
                    onClick={handleEditMode}
                    style={{ background: "white", color: "blue" }}
                  />

                  <Button
                    type="submit"
                    buttonText="Update"
                    isLoading={updatingInfo}
                    className={classes.btn_save}
                  />
                </Stack>
              </Form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
};

const ProfileForm = () => {
  //Create states dropdown
  let ALL_STATES = [];
  NaijaStates.states().forEach((state) => {
    const data = {
      value: state,
      label: state
    };
    ALL_STATES.push(data);
  });
  const { setFieldValue, values, setTouched, touched } = useFormikContext();

  // create lga options based on stateOfOrigin value
  let lgaOptions = [];
  const getLgaOptions = () => {
    if (values.stateOfOrigin) {
      let lgasList = naijaStateLocalGovernment.lgas(values.stateOfOrigin);
      lgasList.lgas.forEach((lga) => {
        const data = {
          value: lga,
          label: lga
        };
        lgaOptions.push(data);
      });
    }
  };
  getLgaOptions();
  useEffect(() => {
    // this would reset the LGA field after the stateOfOrigin field has been changed
    if (!lgaOptions.includes(values.lga)) {
      setFieldValue(lga.name, "");
      setTouched({ ...touched, [lga.name]: false });
    }
  }, [values.stateOfOrigin]);
  const { updatingInfo, profileInformation } = useSelector((state) => state.auth);

  useEffect(() => {
    if (profileInformation?.local_governement_area) {
      setFieldValue(lga.name, profileInformation?.local_governement_area);
    }
  }, []);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <ProfilePicUpload
          name={profileImage.name}
          alt={profileImage.invalidFiletypeErrorMsg}
          src={values?.profileImage}
        />
      </div>
      <Grid container spacing={2} marginY={2}>
        <Grid item xs={12} sm={6}>
          <InputField
            name={firstName.name}
            label={firstName.label}
            placeholder={firstName.label}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={lastName.name}
            label={lastName.label}
            placeholder={lastName.label}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={otherName.name}
            label={otherName.label}
            placeholder={otherName.label}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={gender.name}
            label={gender.label}
            placeholder={gender.label}
            options={OPTIONS_GENDER}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            disabled
            InputProps={{ readOnly: true }}
            name={email.name}
            label={email.label}
            placeholder="Email Address"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField
            name={mobileNumber.name}
            label={mobileNumber.label}
            placeholder={mobileNumber.label}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={alternateEmail.name}
            label={alternateEmail.label}
            placeholder={alternateEmail.label}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={alternateMobileNumber.name}
            label={alternateMobileNumber.label}
            placeholder={alternateMobileNumber.label}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={stateOfOrigin.name}
            label={stateOfOrigin.label}
            placeholder={stateOfOrigin.label}
            options={ALL_STATES}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={lga.name}
            disabled={values.stateOfOrigin ? false : true}
            label={lga.label}
            placeholder={lga.label}
            options={lgaOptions}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePickerField name={dateOfBirth.name} label={dateOfBirth.label} />
        </Grid>
      </Grid>
    </>
  );
};

export default ProfileInformation;
