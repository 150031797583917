import { InputAdornment, TextField } from "@mui/material";
import { useField } from "formik";
import React from "react";
import NumericFormat from "react-number-format";

const ThousandSeparatorTextField = ({ name, currency, readOnly, ...otherProps }) => {
  const [field, meta] = useField(name);

  const configTextField = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: "outlined",
    InputProps: {
      readOnly,
      inputComponent: NumericFormatCustom,
      startAdornment: <InputAdornment position="start">{currency}</InputAdornment>
    }
  };

  // check for errors
  if (meta && meta.touched && meta.error) {
    configTextField.error = true;
    configTextField.helperText = meta.error;
  }

  return <TextField {...configTextField} size="small" />;
};

export default ThousandSeparatorTextField;

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      valueIsNumericString
    />
  );
});
