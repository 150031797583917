import React from "react";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import { ReactComponent as RightArrow } from "../../../assets/svg/right-arrow.svg";
import "./progress-bar.scss";

const ProgressBar = ({ status1, status2, status3 }) => {
    return (
        <div className="progress-bar-show-of-interest">
            <div>
                <div className={status1 ? "check-mark" : "check-mark-outlined check-mark"}>
                    <CheckRoundedIcon fontSize="small" style={{ color: status1 ? "#ffffff" : "#052137" }} />
                </div>
                <span>PERSONAL DETAILS</span>
                <RightArrow />
            </div>
            <div>
                <div className={status2 ? "check-mark" : "check-mark-outlined check-mark"}>
                    <CheckRoundedIcon fontSize="small" style={{ color: status2 ? "#ffffff" : "#052137" }} />
                </div>
                <span>JOB DETAILS</span>
                <RightArrow />
            </div>
            <div>
                <div className={status3 ? "check-mark" : "check-mark-outlined check-mark"}>
                    <CheckRoundedIcon fontSize="small" style={{ color: status3 ? "#ffffff" : "#052137" }} />
                </div>
                <span>CURRENT RESIDENCE</span>
            </div>
        </div>
    );
};

export default ProgressBar;
