import React, { Fragment, useState } from "react";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, CircularProgress, Grid } from "@material-ui/core";
import { authServiceApi } from "#/apis/authServiceApi";
import { getProfileInformation } from "#/store/actions/auth.actions";
import { useDispatch } from "react-redux";
const EmailAlert = ({ email }) => {
  const Styles = makeStyles((theme) => ({
    emailVerify: {
      display: "flex",
      justifyContent: "space-between",
      border: "1px solid #45B29D",
      borderRadius: "3px",
      backgroundColor: "#74D5C226",
      padding: "10px 50px 10px 50px",
      marginBottom: "30px",
      fontSize: "17px",
      fontWeight: "bold",

      [theme.breakpoints.down("sm")]: {
        padding: "10px 10px 10px 10px"
      },

      "& .text-one": {
        color: "#043B5C",

        [theme.breakpoints.down("sm")]: {
          fontSize: "13px"
        },

        "& .sub-text": {
          color: "#E41A1A",
          textDecoration: "underline",
          cursor: "pointer"
        },
        "& .sub-text2": {
          color: "green",
          textDecoration: "underline",
          cursor: "pointer"
        }
      },

      "& .text-two": {
        color: "#043B5C",
        fontSize: "15px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "13px"
        }
      },

      "& .spinner": {
        margin: "0 auto"
      }
    }
  }));

  const [emailSent, setEmailSent] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const dispatch = useDispatch();
  const ResendEmail = async () => {
    setIsSubmitting(true);
    try {
      const { data } = await authServiceApi().post(
        "/user/auth/email/resend-verification",
        { email }
      );
      setEmailSent(data.message);
    } catch (error) {
      setErrorMessage("Something went wrong. Please try again");
    } finally {
      setIsSubmitting(false);
    }
  };
  const fetchProfile = () => {
    dispatch(getProfileInformation()).then(() => {
      window.location.reload();
    });
  };

  const classes = Styles();
  return (
    <Fragment>
      <Slide direction="down" in={true} timeout={1500} mountOnEnter unmountOnExit>
        <span className={classes.emailVerify}>
          <Fragment>
            {!isSubmitting && !(emailSent || errorMessage) && (
              <Box>
                <span className="text-one">
                  Please visit your email and verify or click{" "}
                  <Button variant="contained" onClick={ResendEmail} color="primary">
                    Resend
                  </Button>
                </span>
                <Box>
                  <span className="text-two">
                    If you already verified your email, please click here to update this
                    page{" "}
                    <Button onClick={fetchProfile} variant="contained" color="primary">
                      Already Verified
                    </Button>
                  </span>
                </Box>
              </Box>
            )}

            {isSubmitting && (
              <CircularProgress size={25} color="inherit" className="spinner" />
            )}

            {!isSubmitting && emailSent && <span>{emailSent}</span>}

            {!isSubmitting && errorMessage && <span>{errorMessage}</span>}
          </Fragment>
        </span>
      </Slide>
    </Fragment>
  );
};

export default EmailAlert;
