import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
    root: {
        backgroundColor: "#EEF4FB",
        borderRadius: 5,
        marginBottom: 10,
        [`${theme.breakpoints.up("900")}`]: {
            height: "100vh",
            marginBottom: 0,
            overflow: "auto"
        },
        "& .contact-container, .contact-wrapper": {
            padding: 15
        },
        "& .contact-container": {
            display: "flex"
        },
        "& .contact-text, .contact-wrapper-text": {
            fontWeight: "semi-bold",
            fontSize: 15,
            color: "#043B5C",
            lineSpacing: 40
        },
        "& .contact-wrapper-text": {
            cursor: "pointer"
        },
        "& .contact-text:first-child": {
            marginRight: 25
        },
        "& .contact-wrapper": {
            cursor: "pointer",

            "& .contact-address-text": {
                fontWeight: "bold",
                fontSize: 13,
                color: "#043B5C",
                lineSpacing: 40
            },

            "&:hover": {
                backgroundColor: "#fff"
            }
        },
        "& .active": {
            borderRadius: 5,
            backgroundColor: "#fff !important"
        }
    }
});

const TenantDetailsMenu = (props) => {
    const { tenants, id, handleSelectTenant } = props;

    return (
        <div className={props.classes.root}>
            <div className="contact-container">
                <Typography className="contact-text" variant="body1" component="p">
                    Name
                </Typography>
                <Typography className="contact-text" variant="body1" component="p">
                    Address
                </Typography>
            </div>
            {tenants.map((ele) => {
                if (!ele.fullname) return null;
                return (
                    <div
                        // className={parseInt( ? "contact-wrapper active" : "contact-wrapper"}
                        className="contact-wrapper"
                        onClick={() => handleSelectTenant(ele)}>
                        <div>
                            <Typography id={ele.id} className="contact-wrapper-text" variant="body1" component="p">
                                {ele.fullname}
                            </Typography>
                        </div>
                        {/* <div>
                            <Typography className="contact-address-text" variant="body1" component="p">
                                {ele.email}
                            </Typography>
                        </div>
                        {ele.phone_number !== "null" && (
                            <div>
                                <Typography className="contact-address-text" variant="body1" component="p">
                                    {ele.phone_number}
                                </Typography>
                            </div>
                        )} */}
                    </div>
                );
            })}
        </div>
    );
};

export default withStyles(styles)(TenantDetailsMenu);
