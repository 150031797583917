import {
  GET_IZYPAY_BALANCE,
  GET_IZYPAY_BALANCE_FAIL,
  GET_IZYPAY_BALANCE_SUCCESS,
  START_TRANSFER_FAIL,
  START_TRANSFER,
  START_TRANSFER_SUCCESS,
  CLOSE_TRANSFER_POPUP,
  PAYSTACK_SUCCESS,
  GET_BANKS_SUCCESS,
  GET_BANKS_FAIL,
  VALIDATE_NUBAN,
  VALIDATE_NUBAN_SUCCESS,
  VALIDATE_NUBAN_FAIL,
  GENERATE_RENT_WALLET,
  GENERATE_RENT_WALLET_SUCCESS,
  GENERATE_RENT_WALLET_FAIL
} from "../constants";
import axios from "axios";
import { alertClear, alertError, showNotifications } from "./notification.actions";
import { walletServiceApi } from "#/apis/izyPayApi";

const config = {
  baseURL: process.env.REACT_APP_WALLETSERVICE,
  headers: {
    "X-Requested-With": "XMLHttpRequest"
  }
};

export const getIzyPayBalance = () => async (dispatch) => {
  dispatch({ type: GET_IZYPAY_BALANCE });
  try {
    await axios.get("/user/izypay/balance", config).then((res) => {
      if (res.data.status) {
        const data = res.data.data;

        const izyPayWallet = data.find((wallet) => wallet.wallet_type_id === 1);
        const commissionWallet = data.find((wallet) => wallet.wallet_type_id === 2);
        const rentWallet = data.find((wallet) => wallet.wallet_type_id === 3);

        dispatch({
          type: GET_IZYPAY_BALANCE_SUCCESS,
          payload: res.data.data,
          izyPayWallet,
          commissionWallet,
          rentWallet,
          transactions: res.data.data.transactions
        });
      }
    });
  } catch (error) {
    let data = window.localStorage.getItem("data");
    if (data === null || data === undefined) {
      return;
    } else {
      dispatch({ type: GET_IZYPAY_BALANCE_FAIL, error });
      if (!error.request?.response) {
        // dispatch(showNotifications("Network Error"));
      } else {
        const err = JSON.parse(error.request?.response);
        if (!err.success) {
          // dispatch(showNotifications(err.message));
        }
      }
    }
  }
};

// export const getWalletBalance = () => async (dispatch) => {
//   dispatch({ type: GET_IZYPAY_BALANCE });
//   try {
//     await axios.get("/user/izypay/balance", config).then((res) => {
//       if (res.data.status) {
//         const data = res.data.data;
//         const izyPayWallet = data.find((wallet) => wallet.wallet_type_id === 1);
//         const commissionWallet = data.find((wallet) => wallet.wallet_type_id === 2);
//         const rentWallet = data.find((wallet) => wallet.wallet_type.id === 3);
//         dispatch({
//           type: GET_IZYPAY_BALANCE_SUCCESS,
//           payload: res.data.data,
//           izyPayWallet,
//           commissionWallet,
//           rentWallet,
//           transactions: res.data.data.transactions
//         });
//       }
//     });
//   } catch (error) {
//     let data = window.localStorage.getItem("data");
//     if (data === null || data === undefined) {
//       return;
//     } else {
//       dispatch({ type: GET_IZYPAY_BALANCE_FAIL, error });
//       if (!error.request?.response) {
//         // dispatch(showNotifications("Network Error"));
//       } else {
//         const err = JSON.parse(error.request?.response);
//         if (!err.success) {
//           // dispatch(showNotifications(err.message));
//         }
//       }
//     }
//   }
// };

export const doTransfer = (data, cb) => async (dispatch) => {
  dispatch({ type: START_TRANSFER });
  try {
    await axios.post("/user/izypay/transfer", data, config).then((res) => {
      if (res.data.status) {
        dispatch({
          type: START_TRANSFER_SUCCESS,
          transfer: true
        });
        dispatch(showNotifications(res.data.message, "success"));
        dispatch(getIzyPayBalance());
      }
      cb();
    });
  } catch (error) {
    dispatch({ type: START_TRANSFER_FAIL });
    if (error.response.data.data === null && error.response.data.message) {
      dispatch(alertError(error.response.data.message));
    } else if (error.response.data !== null || error.response.data !== undefined) {
      const errors = error.response.data.data;
      errors.forEach((error) => {
        Object.entries(error).forEach(([key, value]) => {
          dispatch(alertError(value));
        });
      });
    }
  }
};

export const handlePopup = (falsy, cb) => (dispatch) => {
  dispatch({
    type: CLOSE_TRANSFER_POPUP,
    showPopup: falsy
  });
};

export const verifyPayment = (ref, cb) => async (dispatch) => {
  dispatch({
    type: PAYSTACK_SUCCESS
    // paystack: true,
  });
  await axios.get(`/user/izypay/verify/transaction/${ref}`, config).then((res) => {
    dispatch(showNotifications(res.data.message, "success"));
    dispatch(getIzyPayBalance());
  });
  cb();
};

export const Banks = () => async (dispatch) => {
  try {
    await axios.get("/user/banks", config).then((res) => {
      let data = [];
      if (res.data.status) {
        res.data.data.banks.forEach((item) => {
          data.push({
            bankName: item.name,
            bankCode: item.code,
            bankId: item.id
          });
        });
        dispatch({
          type: GET_BANKS_SUCCESS,
          banks: data,
          banksInNigeria: res.data.data.banks
        });
      }
    });
  } catch (error) {
    dispatch({ type: GET_BANKS_FAIL });
  }
};

export const ValidateNuban = (data, cb) => async (dispatch) => {
  try {
    dispatch({ type: VALIDATE_NUBAN });
    await axios
      .get(`/user/validate/nuban/${data.account_number}/bank/${data.bank_code}`, config)
      .then((res) => {
        if (res.data.status) {
          dispatch({ type: VALIDATE_NUBAN_SUCCESS, nuban: res.data.data });
          // dispatch(showNotifications(res.data.message, 'success'));
          cb();
        }
      });
  } catch (error) {
    dispatch({ type: VALIDATE_NUBAN_FAIL, error });
    cb();
    dispatch(alertError(error?.response?.data?.message));
    setTimeout(() => {
      dispatch(alertClear());
    }, 3000);
  }
};

export const generateVirtualRentWallet = (liveizyId, cb) => async (dispatch) => {
  dispatch({ type: GENERATE_RENT_WALLET });
  try {
    const res = await walletServiceApi().post(
      `/user/izypay/account/reserve/rent?user_liveizy=${liveizyId}`
    );
    dispatch({ type: GENERATE_RENT_WALLET_SUCCESS });
    dispatch(
      showNotifications(res.data.message || "Virtual Account Generated.", "success")
    );
    dispatch(getIzyPayBalance());
    cb();
  } catch (error) {
    dispatch({ type: GENERATE_RENT_WALLET_FAIL });
    dispatch(alertError(error.response?.data?.message || "An Error occurred. Try again"));
  }
};

export const selectIzyPayBalance = (state) => state.izypay?.izyPayWallet?.balance;

export const selectIzyPayTransactions = (state) =>
  state.izypay?.izyPayWallet?.transactions || [];

export const selectVirtualAccount = (state) => {
  const izyPayData = state.izypay?.izyPayWallet;
  if (izyPayData?.account_number) {
    const virtualAccount = {
      account_number: izyPayData.account_number,
      account_name: izyPayData.account_name,
      bank_name: izyPayData.bank.name,
      bank_code: izyPayData.bank.id
    };
    return virtualAccount;
  }
  return null;
};

export const selectRentLoanVirtualAccount = (state) => state.izypay?.rentWallet;

export const selectRentWallet = (state) => {
  const wallet = state?.izypay?.rentWallet;
  if (wallet?.account_number) {
    const rentWallet = {
      accountNumber: wallet?.account_number,
      accountName: wallet?.account_name,
      balance: wallet?.balance,
      bankName: wallet?.bank,
      bankCode: wallet?.bank_id,
      transactions: wallet?.transactions,
      walletType: wallet?.wallet_type,
      wallet: wallet
    };
    return rentWallet;
  }
  return null;
};

export const selectLoadingWallet = (state) => state.izypay?.loading;
export const selectLoadingRentWallet = (state) => state.izypay?.rentWalletLoading;
