import CustomInputAndLabelField from "#/components/customInputFields/CustomInputAndLabelField";
import { thousandSeparator } from "#/shared/utils";
import { styled } from "@mui/material";
import React from "react";

const ContributionView = ({ contribution, setContribution }) => {
  return (
    <Wrapper>
      <h4>
        If you would like to pay part of the rent , please input the amount you would be
        paying as your contribution to the rent payment. <br />
        If you want us to finance the full rent, please click next
      </h4>

      <div style={{ maxWidth: "280px", textAlign: "left" }}>
        <CustomInputAndLabelField
          label={"Contribution"}
          icon={"₦"}
          value={contribution}
          onChange={(event) => setContribution(thousandSeparator(event.target.value))}
        />
      </div>
    </Wrapper>
  );
};

export default ContributionView;

const Wrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  padding: 0.75rem;

  h4 {
    max-width: 85ch;
    width: 100%;
    color: #052137;
    text-align: center;
    font-size: 0.95rem;
    font-weight: 600;
  }
`;
