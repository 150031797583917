import { nigerianPhoneNumberPattern } from "#/components/utils/util";
import * as Yup from "yup";

import { currentTenancyInfo, employmentStatusModel, meansOfIdModel } from "./formModel";
const {
  formField: {
    employmentStatus,
    nameOfEmployer,
    officeAddress,
    monthlyIncome,
    nameOfOfficeContactPerson,
    officeContactPersonNumber
  }
} = employmentStatusModel;

const TYPE_NUMBER_ERROR_MSG = "Must be a Number.";
const TYPE_DATE_ERROR_MSG = "Must be a date type.";

const mobileNumberLengthCheck = (value) => {
  return !isNaN(value) && `${value}`.length >= 10;
};

const fileTypeCheck = (value, typesArray) => {
  if (typeof value === "string") {
    return /^https?:\/\/.+\.(jpg|jpeg|png)$/.test(value);
  }
  return !value || (value && typesArray.includes(value?.type));
};

const documentfileTypeCheck = (value, typesArray) => {
  if (typeof value === "string") {
    return /^https?:\/\/.+\.(pdf|docx)$/.test(value);
  }
  return !value || (value && typesArray.includes(value?.type));
};

export const employmentStatusSchema = Yup.object().shape({
  [employmentStatus.name]: Yup.string().required(`${employmentStatus.requiredErrorMsg}`),
  [nameOfEmployer.name]: Yup.string().required(`${nameOfEmployer.requiredErrorMsg}`),
  [officeAddress.name]: Yup.string().required(`${officeAddress.requiredErrorMsg}`),
  [monthlyIncome.name]: Yup.number()
    .typeError(TYPE_NUMBER_ERROR_MSG)
    .required(`${monthlyIncome.requiredErrorMsg}`),
  [nameOfOfficeContactPerson.name]: Yup.string().required(
    `${nameOfOfficeContactPerson.requiredErrorMsg}`
  ),
  [officeContactPersonNumber.name]: Yup.string()
    .required(`${officeContactPersonNumber.requiredErrorMsg}`)
    .matches(
      nigerianPhoneNumberPattern,
      `${officeContactPersonNumber.invalidLengthErrorMsg}`
    )
});

const {
  formField: {
    currentHomeAddress,
    currentRentValue,
    nameOfManager,
    contactOfManager,
    nameOfLandlord,
    contactOfLandlord
  }
} = currentTenancyInfo;

export const currentTenancyInfoSchema = Yup.object().shape({
  [currentHomeAddress.name]: Yup.string().required(
    `${currentHomeAddress.requiredErrorMsg}`
  ),
  [currentRentValue.name]: Yup.number()
    .typeError(TYPE_NUMBER_ERROR_MSG)
    .required(`${currentRentValue.requiredErrorMsg}`),
  [nameOfManager.name]: Yup.string().required(`${nameOfManager.requiredErrorMsg}`),
  [contactOfManager.name]: Yup.string()
    .required(`${contactOfManager.requiredErrorMsg}`)
    .matches(nigerianPhoneNumberPattern, `${contactOfManager.invalidLengthErrorMsg}`),
  [nameOfLandlord.name]: Yup.string().required(`${nameOfLandlord.requiredErrorMsg}`),
  [contactOfLandlord.name]: Yup.string()
    .required(`${contactOfLandlord.requiredErrorMsg}`)
    .matches(nigerianPhoneNumberPattern, `${contactOfLandlord.invalidLengthErrorMsg}`)
});

const {
  formField: { meansOfId, meansOfIdFile, nameOfNextOfKin, contactOfNextOfKin }
} = meansOfIdModel;

export const meansOfIdSchema = Yup.object().shape({
  [meansOfId.name]: Yup.string().required(`${meansOfId.requiredErrorMsg}`),
  [meansOfIdFile.name]: Yup.mixed()
    .nullable()
    .required(`${meansOfIdFile.requiredErrorMsg}`)
    .test("File_type", `${meansOfIdFile.invalidFiletypeErrorMsg}`, (value) =>
      fileTypeCheck(value, meansOfIdFile.acceptedFileTypes)
    ),
  [nameOfNextOfKin.name]: Yup.string().required(`${nameOfNextOfKin.requiredErrorMsg}`),
  [contactOfNextOfKin.name]: Yup.string()
    .required(`${contactOfNextOfKin.requiredErrorMsg}`)
    .matches(nigerianPhoneNumberPattern, `${contactOfNextOfKin.invalidLengthErrorMsg}`)
});
