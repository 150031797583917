import React from "react";
import { styled } from "@mui/material";

const SubtitleSubtext = ({ title, text }) => {
  return (
    <Box>
      <small>{title}</small>
      <h2>{text}</h2>
    </Box>
  );
};

export default SubtitleSubtext;

const Box = styled("div")`
  small {
  font-size: 80%;
  }
  h2 {
  font-size: 28px;
  font-weight: 700;
  }

  @media (max-width: 500px) {
   small {
  font-size: 70%;
  }
  h2 {
  font-size: 16px;
  font-weight: 700;
  }
  @media (max-width: 400px) {
   small {
  font-size: 60%;
  }
  h2 {
  font-size: 14px;
  font-weight: 700;
  }
}
 
`;
