import { useState, useCallback, useRef, useEffect } from "react";

const useAddMultipleImages = () => {
    const [allMultipleImages, setAllMultipleImages] = useState();
    const [allMultipleImagesPreview, setAllMultipleImagesPreview] = useState([]);

    const imgPickerRef = useRef(); //To connect Input field to "add more images" button

    const handleSelectImages = useCallback(
        (e) => {
            setAllMultipleImages(e.target.files);
            const _files = Object.values(e.target.files).map((file) => {
                const imagePreview = URL.createObjectURL(file);
                return {
                    file,
                    imagePreview
                };
            });

            setAllMultipleImagesPreview([...allMultipleImagesPreview, ..._files]);
        },
        [allMultipleImagesPreview]
    );

    //----------------------------------------------------------------------------
    //Same as handleSrlectImages only that, this is triggered by clicking a button
    //----------------------------------------------------------------------------
    const handleSelectMoreImages = useCallback(() => {
        imgPickerRef.current.click();
    }, []);

    const handleRemoveImage = useCallback(
        (name) => {
            const remainingImages = allMultipleImagesPreview.filter((item) => {
                return item.file.name !== name;
            });

            setAllMultipleImagesPreview(remainingImages);
        },
        [allMultipleImagesPreview]
    );

    return {
        addMultipleImagesHelpers: {
            allMultipleImages,
            allMultipleImagesPreview,
            imgPickerRef,
            handleSelectImages,
            handleSelectMoreImages,
            handleRemoveImage,
            setAllMultipleImagesPreview,
            setAllMultipleImages
        }
    };
};

export default useAddMultipleImages;
