import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import useWindowSize from "react-use/lib/useWindowSize";

import { useInnerListingStyles } from "./innerListing.style";

import BackComponent from "#/components/propertycomponents/back-component";

import CustomCard from "#/pages/Listing/components/customCard/customCard.component";

import RightSection from "./components/rightSection/rightSection";
import ImageSlider from "./components/imageSlider/ImageSlider";
import PayForApartment from "./components/payment/PayForApartment";

import { getApartmentCharges } from "#/store/actions/charges.actions";
import { getApartmentSchedules } from "#/store/actions/schedules.action";
import { propertyServiceApi } from "#/apis/propertyServiceApi";
import { authServiceApi } from "#/apis/authServiceApi";

const InnerListing = ({ location }) => {
  //-------------------------------------------------------------
  //HELPER HOOKS
  //-------------------------------------------------------------
  const classes = useInnerListingStyles();
  const windowSize = useWindowSize();
  const dispatch = useDispatch();
  const { apartment_id } = useParams();

  //-------------------------------------------------------------
  //STORE
  //-------------------------------------------------------------
  const user = useSelector((state) => state.auth.user);
  const { charges, loading: loadingCharges } = useSelector((store) => store.charges);
  const { apartmentSchedules } = useSelector((state) => state.schedules);
  const allVacantApartments = useSelector((state) => state.apartment.allVacantApartments);
  const paymentState = useSelector((state) => state.payments);

  //-------------------------------------------------------------
  //STATE
  //-------------------------------------------------------------
  const [apartment, setApartment] = useState(null);
  const [apartmentLoading, setApartmentLoading] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [propManDetails, setPropManDetails] = useState(null);

  useEffect(() => fetchApartmentById(apartment_id), []);

  useEffect(() => {
    if (!apartment) return;
    dispatch(getApartmentCharges(apartment_id));
    dispatch(getApartmentSchedules(apartment_id));
  }, [apartment]);

  const fetchApartmentById = (id) => {
    setApartmentLoading(true);
    propertyServiceApi()
      .get("/apartment/show/" + id)
      .then((data) => {
        const { property_manager, owner } = data.data.payload;
        setApartmentLoading(false);
        setApartment(data.data.payload);
        fetchAgent(property_manager ? property_manager : owner);
      })
      .catch((err) => {
        setApartmentLoading(false);
      });
  };

  const fetchAgent = (prop_man) => {
    if (!prop_man) return;
    authServiceApi()
      .get("/user/validate/liveizy/" + prop_man)
      .then((data) => setPropManDetails(data.data.data))
      .catch((err) => console.log("profile error", err));
  };

  const handlePaymentModal = (trueOrFalse) => {
    setShowPaymentModal(trueOrFalse);
  };

  if (apartmentLoading || paymentState.paying) {
    return (
      <div
        style={{
          height: "50vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
        <CircularProgress size={50} className="icon" color="inherit" />
      </div>
    );
  }

  //-------------------------------------------------------------
  //WHEN ALL DATA HAS LOADED, DO ALL THESE
  //-------------------------------------------------------------
  if (!apartment) return null;

  const { apartment_images, extras, extra_description } = apartment;

  return (
    <React.Fragment>
      {showPaymentModal && (
        <PayForApartment
          handleSetModalVisibility={handlePaymentModal}
          apartmentDetails={apartment}
          apartmentCharges={charges}
        />
      )}
      <div style={{ display: "flex", marginBottom: "20px" }}>
        <BackComponent />
      </div>

      <div className={classes.innerListingContainer}>
        <div className="main-body">
          <section>
            {/* ----------------------------------------- */}
            {/* Image Container */}
            {/* ----------------------------------------- */}

            <ImageSlider images={apartment_images} classes={classes} />

            {/* ----------------------------------------- */}
            {/* MOBILE APARTMENT DETAILS */}
            {/* ----------------------------------------- */}
            {windowSize.width < 992 && apartment && (
              <RightSection
                classes={classes}
                data={apartment}
                apartmentSchedules={apartmentSchedules}
                dispatch={dispatch}
                user={user}
                windowSize={windowSize}
                booked={location.state?.booked || null}
                handleSetModalVisibility={handlePaymentModal}
              />
            )}

            {/* ----------------------------------------- */}
            {/* Desctiption */}
            {/* ----------------------------------------- */}
            <div className={classes.apartmentDescription}>
              <div className={classes.apartmentHeaderAndContent}>
                <h1 className="big-bold" style={{ marginBottom: "15px" }}>
                  Other Description
                </h1>
                <p className="description">{extra_description}</p>
              </div>
            </div>
            <div className={classes.apartmentDescription}>
              <div className={classes.apartmentHeaderAndContent}>
                <h1 className="big-bold" style={{ marginBottom: "15px" }}>
                  Agent Details
                </h1>
                <p className="description">Agent Number: {propManDetails?.phone}</p>
                <p className="description">Agent Email: {propManDetails?.email}</p>
              </div>
            </div>

            {/* ----------------------------------------- */}
            {/* Apartment Charges */}
            {/* ----------------------------------------- */}
            <div className={classes.apartmentCharges}>
              <h1 className="charges-title">Apartment Charges</h1>
              <div className="charges-wrapper">
                {charges.length > 0 ? (
                  charges.map((item, i) => {
                    const { amount, name, payment_term } = item;
                    return (
                      <div className={classes.chargeCard} key={i}>
                        <div className="left-column">
                          <h3 className="charge-name">{name}</h3>
                          <h4 className="charge-term">{payment_term}</h4>
                        </div>
                        <h4 className="charge-amount">{`#${amount}`}</h4>
                      </div>
                    );
                  })
                ) : (
                  <p className="no-charges">No Charges</p>
                )}
              </div>
            </div>

            {/* ----------------------------------------- */}
            {/* Apartment Amenities */}
            {/* ----------------------------------------- */}
            <div className={classes.apartmentAmenities}>
              <h1 className="amenities-title">Amenities</h1>
              <div className="amenitites-wrapper">
                {extras && extras.length > 0 ? (
                  extras.map((item) => {
                    return (
                      <div className={classes.amenitiesCard} key={item}>
                        {item}
                      </div>
                    );
                  })
                ) : (
                  <p className="no-amenities">No Extra amenitites</p>
                )}
              </div>
            </div>
          </section>
          <section>
            {windowSize.width >= 992 && apartment && (
              <RightSection
                classes={classes}
                data={apartment}
                apartmentSchedules={apartmentSchedules}
                dispatch={dispatch}
                user={user}
                windowSize={windowSize}
                booked={location.state?.booked || null}
                handleSetModalVisibility={handlePaymentModal}
              />
            )}
          </section>
        </div>
      </div>

      <h1
        style={{
          fontSize: "18px",
          margin: "50px 0px 20px 0px",
          fontFamily: "Open Sans-bold"
        }}>
        3 Results
      </h1>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {allVacantApartments.slice(0, 3).map((apartment) => (
          <CustomCard key={apartment._id} data={apartment} />
        ))}
      </div>
    </React.Fragment>
  );
};

export default InnerListing;
